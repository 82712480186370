import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
// import "cordova-plugin-purchase";
import { Capacitor } from "@capacitor/core";
import { CustomerInfo, LOG_LEVEL, PURCHASES_ERROR_CODE, Purchases, PurchasesEntitlementInfo } from "@revenuecat/purchases-capacitor";
import { BehaviorSubject } from "rxjs";
import { SUB_OPTIONS_NO_ADS } from "../constants";
import { SubOption } from "../models/sale";
import { User } from "../models/user";
import { AnalyticsService } from "./analytics.service";
import { NotificationService } from "./notification.service";
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: "root",
})
export class SubscriptionIosService {
  iosProducts = ["com.tv.monthlifebuddy", "com.tv.6monthlifebuddy", "com.tv.12monthlifebuddy"];
  androidProducts = ["com.tv.12monthlb", "com.tv.6monthlb", "com.tv.monthlb"];
  // private store: CdvPurchase.Store;
  // private state: State;

  _products: BehaviorSubject<SubOption[]> = new BehaviorSubject<SubOption[]>(SUB_OPTIONS_NO_ADS);
  _activeSubscription: BehaviorSubject<{ id: string } & Partial<PurchasesEntitlementInfo>> = new BehaviorSubject<{ id: string }>(null);
  _updateWaitingStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  constructor(
    private platform: Platform,
    private notificationServ: NotificationService,
    private analyticsServ: AnalyticsService,
    private storageServ: StorageService
  ) {
    this.platform.ready().then(async () => {
      if (Capacitor.getPlatform() == "web") {
        return;
      }
      await Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG }); // Enable to get debug logs
      await Purchases.configure({
        apiKey: this.platform.is("ios") ? "appl_qyUPXMXULqCHmBjfBRlRnlzyixo" : "goog_PNHuYSNKBrnXoWrOrBGXXbubyyc",
      });

      await Purchases.addCustomerInfoUpdateListener((customerInfo) => {
        console.log("Customer INfo changes: ", customerInfo);
        this.checkEntitlements(customerInfo);
        // handle any changes to customerInfo
      });

      await this.getProducts();
    });
  }

  async subscribe(productId: string) {
    if (Capacitor.getPlatform() == "web") {
      return;
    }
    try {
      const products = await Purchases.getProducts({
        productIdentifiers: this.platform.is("ios") ? this.iosProducts : this.androidProducts,
      });

      const foundIndex = products.products.findIndex(
        (p) => (this.platform.is("ios") ? p.identifier : p.defaultOption.productId) === productId
      );
      if (foundIndex != -1) {
        const purchaseResult = await Purchases.purchaseStoreProduct({ product: products.products[foundIndex] });
        this.checkEntitlements(purchaseResult.customerInfo, true);
      }
    } catch (error: any) {
      if (
        error.code === PURCHASES_ERROR_CODE.PURCHASE_CANCELLED_ERROR ||
        error.code === PURCHASES_ERROR_CODE.PRODUCT_ALREADY_PURCHASED_ERROR
      ) {
        // Purchase cancelled
      } else {
        await this.notificationServ.showError(`Error ${error.code}: ${error.message}`);
        // Error making purchase
      }
    }
    this._updateWaitingStatus.next(false);
  }

  async checkEntitlements(customerInfo: CustomerInfo, logEvent = false) {
    if (Capacitor.getPlatform() == "web") {
      return;
    }
    if (typeof customerInfo.entitlements.active["pro"] !== "undefined") {
      const productId = customerInfo.entitlements.active["pro"].productIdentifier;
      this._activeSubscription.next({ ...customerInfo.entitlements.active["pro"], id: productId });
      if (logEvent) {
        const user: User = await this.storageServ.get("StoredUser");
        if (user.gender == "male") {
          this.analyticsServ.logEvent("in_app_purchase_custom", { product_id: productId });
        }
      }
    } else {
      this._activeSubscription.next(null);
    }
  }

  async restorePurchases() {
    if (Capacitor.getPlatform() == "web") {
      return;
    }
    const customerInfo = await Purchases.restorePurchases();
    this.checkEntitlements(customerInfo.customerInfo);
  }

  async manageSubscription() {
    if (Capacitor.getPlatform() == "web") {
      return;
    }
    const customerInfo = await Purchases.getCustomerInfo();
    const pro = customerInfo.customerInfo.entitlements.active["pro"];
    if (pro && pro.store == "STRIPE") {
      return false;
    } else {
      window.open(customerInfo.customerInfo.managementURL, "_blank");
      return true;
    }
  }

  async getProducts() {
    if (Capacitor.getPlatform() == "web") {
      return;
    }
    const mappedProducts: SubOption[] = this._products.value;

    const products = await Purchases.getProducts({
      productIdentifiers: this.platform.is("ios") ? this.iosProducts : this.androidProducts,
    });
    products.products.forEach((p) => {
      const foundIndex = mappedProducts.findIndex(
        (mp) => (this.platform.is("ios") ? mp.iosName : mp.name) == (this.platform.is("ios") ? p.identifier : p.defaultOption.productId)
      );
      const monthlyIndex = mappedProducts.findIndex((iap) => iap.time == "1" && iap.period == "month");
      const monthlyProductIndex = products.products.findIndex(
        (product) =>
          (this.platform.is("ios") ? product.identifier : product.defaultOption.productId) ==
          (this.platform.is("ios") ? mappedProducts[monthlyIndex].iosName : mappedProducts[monthlyIndex].name)
      ); //
      if (foundIndex != -1) {
        const cost = p.price;
        const monthlyCost = cost / Number(mappedProducts[foundIndex].time);
        const baseMonthly = products.products[monthlyProductIndex].price;

        mappedProducts[foundIndex] = {
          ...mappedProducts[foundIndex],
          currencyCode: p.currencyCode,
          cost: cost.toFixed(2),
          finalCost: mappedProducts[foundIndex].time == "1" ? "" : monthlyCost.toFixed(2),
          discount: Math.round(100 - (monthlyCost / baseMonthly) * 100),
        };
      }
    });
    this._products.next(mappedProducts);
  }

  async checkCanFreeTrial() {
    if (Capacitor.getPlatform() == "web") {
      return;
    }
    const products = await Purchases.getProducts({
      productIdentifiers: this.platform.is("ios") ? this.iosProducts : this.androidProducts,
    });
    return products.products[0].subscriptionOptions.length > 1; // Android Only
  }
}
