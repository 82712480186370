import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Capacitor } from "@capacitor/core";
import { Keyboard } from "@capacitor/keyboard";
import { LoadingController, ModalController, NavController, Platform } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { AnalyticsService } from "src/app/services/analytics.service";
import { HelperService } from "src/app/services/helper.service";
import { TranslateConfigService } from "src/app/services/translate-config.service";
import { environment } from "src/environments/environment";
import { AuthService } from "../../services/auth.service";
import { NotificationService } from "../../services/notification.service";
import { LoginWithEmailPage } from "../login-with-email/login-with-email.page";

@Component({
  selector: "app-sign-up",
  templateUrl: "./sign-up.page.html",
  styleUrls: ["./sign-up.page.scss"],
})
export class SignUpPage implements OnChanges, OnInit {
  @Input() isAnonymous = false;
  @Input() email = "";
  @Input() pageTitle = "sign_up";

  @Output() auth = new EventEmitter<boolean>();

  isWeb = Capacitor.getPlatform() == "web";

  authForm: UntypedFormGroup;
  showHidePassword: boolean;
  language: any;

  backBtnSub: Subscription;

  constructor(
    private navCtrl: NavController,
    private authService: AuthService,
    private notificationService: NotificationService,
    private loadingController: LoadingController,
    private helperServ: HelperService,
    private platform: Platform,
    private translate: TranslateService,
    private translateConfigService: TranslateConfigService,
    private analyticsServ: AnalyticsService,
    private modalCtrl: ModalController,
    private cdRef: ChangeDetectorRef
  ) {
    this.language = this.translateConfigService.getCurrentLang();
    const user = this.authService.userService.getSyncUserOnce();
    this.showHidePassword = true;

    this.backBtnSub = this.platform.backButton.subscribeWithPriority(300, (processNextHandler) => {
      this.backBtnSub.unsubscribe();
      this.close();
    });
  }

  ngOnInit(): void {
    this.authForm = new UntypedFormGroup({
      firstName: new UntypedFormControl("", [Validators.required, Validators.minLength(1)]),
      lastName: new UntypedFormControl("", [Validators.required, Validators.minLength(1)]),
      // tslint:disable-next-line:max-line-length
      email: new UntypedFormControl({ value: this.email, disabled: this.email != "" }, [
        Validators.required,
        Validators.pattern(
          /[a-z0-9!#$%&'*+/=?^_{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
        ),
      ]),
      password: new UntypedFormControl("", [Validators.required, Validators.minLength(6)]),
      confirmPassword: new UntypedFormControl("", [Validators.required, Validators.minLength(6)]),
    });

    this.authForm.valueChanges.subscribe((form) => {
      if (form.password !== form.confirmPassword) {
        this.getControls("confirmPassword").setErrors({ notSame: true });
      } else {
        this.getControls("confirmPassword").setErrors(null);
      }
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.email && changes.email.currentValue && this.authForm) {
      this.authForm.get("email").setValue(changes.email.currentValue);
      this.cdRef.detectChanges();
    }
  }

  ionViewWillEnter() {
    // this.userService.updateStatusBarAndBottomBar("303561");
    if (this.platform.is("capacitor") && this.platform.is("ios")) {
      Keyboard.setAccessoryBarVisible({ isVisible: false });
    }
    // this.keyboard.hideFormAccessoryBar(true);
  }

  ionViewWillLeave() {
    this.authForm.reset();
    this.backBtnSub.unsubscribe();
    if (this.platform.is("capacitor") && this.platform.is("ios")) {
      Keyboard.setAccessoryBarVisible({ isVisible: true });
    }
    // this.keyboard.hideFormAccessoryBar(false);
  }

  private validation(data: { firstName: string; lastName: string; email: string; password: string; confirmPassword: string }) {
    if (!this.isAnonymous && data.firstName.trim() === "") {
      return "Please enter first name.";
    }
    if (!this.isAnonymous && data.lastName.trim() === "") {
      return "Please enter last name.";
    }
    if (data.email === "") {
      return "Please enter email address";
    }
    // tslint:disable-next-line:max-line-length
    const regex =
      /[a-z0-9!#$%&'*+/=?^_{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    const re = new RegExp(regex);
    if (!re.test(data.email)) {
      return "Please enter correct email address";
    }
    if (data.password === "") {
      return "Please enter password";
    }
    if (data.password.length < 6) {
      return "Password must be at least 6 character long.";
    }
    if (data.confirmPassword === "" && data.confirmPassword.length === 0) {
      return "Please enter confirm password";
    }
    if (data.password !== data.confirmPassword) {
      return "Password and confirm password not matches";
    }
    return null;
  }

  toggleShowHidePassword() {
    this.showHidePassword = !this.showHidePassword;
  }

  getControls(name: string) {
    return this.authForm.get(name);
  }

  async onSubmit() {
    const data = this.authForm.value;

    const response = this.validation({ ...data, email: this.authForm.get("email").value });

    if (response) {
      this.notificationService.showError(response);
      return;
    }
    const loading = await this.loadingController.create();
    await loading.present();

    try {
      if (Capacitor.getPlatform() == "web") {
        await this.attachPassword();
        await this.loadingController.dismiss();
      } else {
        if (this.isAnonymous) {
          if (await this.authService.linkEmailPassWithAnonymousUser(data.email, data.password)) {
            await this.notificationService.presentLinkAccountToast();
          }
        } else {
          if (await this.authService.register(data)) {
            await this.analyticsServ.logEvent("login_page_finished", { auth_method: "email", onboarding: false, signup: true });
          } else {
            await this.analyticsServ.logEvent("login_page_finished", { auth_method: "email", onboarding: true, signup: true });
          }
        }
        await this.loadingController.dismiss();
        return this.close(true);
      }
    } catch (error) {
      let message = this.helperServ.mapErrorCode(error);
      this.notificationService.showError(message).then((res) => {
        console.log("Res from alert: ", res);
        if (res.role == "login") {
          this.openLogin();
        }
      });
      await this.loadingController.dismiss();
    }
  }

  async attachPassword() {
    const response = await fetch(
      environment.production
        ? "https://us-central1-lifebuddy-3a29a.cloudfunctions.net/attachpassword"
        : "http://127.0.0.1:5001/lifebuddy-3a29a/us-central1/attachpassword",
      {
        method: "POST",
        body: JSON.stringify({
          ...this.authForm.value,
          email: this.authForm.get("email").value,
        }),
      }
    ).catch((e) => {
      console.error("Error: ", e);
      return null;
    });

    const responseData = await response.json();
    if (responseData.success) {
      await this.authService
        .login(this.authForm.get("email").value, this.authForm.value.password)
        .then((res) => {
          localStorage.setItem("onboarding_status", "{}");
          this.auth.emit(true);
        })
        .catch((error) => {
          let message = this.helperServ.mapErrorCode(error);
          this.notificationService.showError(message);
        });
    } else {
      await this.notificationService.showError("There was an error creating your account, please contact support");
    }
  }

  async openLogin() {
    const modal = await this.modalCtrl.create({
      component: LoginWithEmailPage,
      componentProps: {
        routeHome: false,
      },
    });

    await modal.present();

    modal.onDidDismiss().then((res) => {
      console.log("Login w email: ", res);
      if (res.data) {
        //  this.close();
        this.close(true);
        //  this.auth.emit(true);
      }
    });
  }

  close(data?: any) {
    if (Capacitor.getPlatform() == "web") {
      this.auth.emit(true);
    } else {
      return this.modalCtrl.dismiss(data);
    }
  }
}
