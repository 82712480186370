import { Capacitor } from "@capacitor/core";
import { Difficulty } from "./enums/Difficulty";
import { OnboardingPageKeys } from "./enums/Onboarding";
//import { OnboardingBG } from "./enums/Onboarding";
import sixmonthly from "src/assets/pricingTables/6monthly.json";
import monthly from "src/assets/pricingTables/monthly.json";
import yearly from "src/assets/pricingTables/yearly.json";
import { SubOption } from "./models/sale";
import { RecItem } from "./services/helper.service";

export const DEFAULT_LANGUAGE = "English";
export const LANGUAGE_LIST = [
  { name: "Afrikaans", value: "af" },
  { name: "Albanian", value: "sq" },
  { name: "Amharic", value: "am" },
  { name: "Arabic", value: "ar" },
  { name: "Armenian", value: "hy" },
  { name: "Azerbaijani", value: "az" },
  { name: "Bangla", value: "bn" },
  { name: "Basque", value: "eu" },
  { name: "Belarusian", value: "be" },
  { name: "Bulgarian", value: "bg" },
  { name: "Burmese", value: "my" },
  { name: "Catalan", value: "ca" },
  { name: "Chinese (Simplified)", value: "zh-Hans" },
  { name: "Chinese (Traditional)", value: "zh-Hant" },
  { name: "Croatian", value: "hr" },
  { name: "Czech", value: "cs" },
  { name: "Danish", value: "da" },
  { name: "Dutch", value: "nl" },
  { name: "English", value: "en" },
  { name: "Estonian", value: "et" },
  { name: "Filipino", value: "fil" },
  { name: "Finnish", value: "fi" },
  { name: "French", value: "fr" },
  { name: "Galician", value: "gl" },
  { name: "Georgian", value: "ka" },
  { name: "German", value: "de" },
  { name: "Greek", value: "el" },
  { name: "Gujarati", value: "gu" },
  { name: "Hebrew", value: "he" },
  { name: "Hindi", value: "hi" },
  { name: "Hungarian", value: "hu" },
  { name: "Icelandic", value: "is" },
  { name: "Indonesian", value: "id" },
  { name: "Italian", value: "it" },
  { name: "Japanese", value: "ja" },
  { name: "Kannada", value: "kn" },
  { name: "Kazakh", value: "kk" },
  { name: "Khmer", value: "km" },
  { name: "Korean", value: "ko" },
  { name: "Kyrgyz", value: "ky" },
  { name: "Lao", value: "lo" },
  { name: "Latvian", value: "lv" },
  { name: "Lithuanian", value: "lt" },
  { name: "Macedonian", value: "mk" },
  { name: "Malay", value: "ms" },
  { name: "Malayalam", value: "ml" },
  { name: "Marathi", value: "mr" },
  { name: "Mongolian", value: "mn" },
  { name: "Nepali", value: "ne" },
  { name: "Norwegian", value: "no" },
  { name: "Persian", value: "fa" },
  { name: "Polish", value: "pl" },
  { name: "Portuguese", value: "pt" },
  { name: "Punjabi", value: "pa" },
  { name: "Romanian", value: "ro" },
  { name: "Russian", value: "ru" },
  { name: "Serbian", value: "sr" },
  { name: "Sinhala", value: "si" },
  { name: "Slovak", value: "sk" },
  { name: "Slovenian", value: "sl" },
  { name: "Spanish", value: "es" },
  { name: "Swahili", value: "sw" },
  { name: "Swedish", value: "sv" },
  { name: "Tamil", value: "ta" },
  { name: "Telugu", value: "te" },
  { name: "Thai", value: "th" },
  { name: "Turkish", value: "tr" },
  { name: "Ukrainian", value: "uk" },
  { name: "Urdu", value: "ur" },
  { name: "Vietnamese", value: "vi" },
  { name: "Zulu", value: "zu" },
];

export const DEFAULT_WEEKLY_DAY = "Monday 11:59 PM";
export const WEEKLY_DAY_LIST = [
  "Monday 11:59 PM",
  "Tuesday 11:59 PM",
  "Wednesday 11:59 PM",
  "Thursday 11:59 PM",
  "Friday 11:59 PM",
  "Saturday 11:59 PM",
  "Sunday 11:59 PM",
];

export enum AndroidSubscriptionProducts {
  "com.tv.yearlyads" = "com.tv.yearlyads",
  "com.tv.halfyearlyads" = "com.tv.halfyearlyads",
  "com.tv.monthads" = "com.tv.monthads",

  "com.tv.12monthlb" = "com.tv.12monthlb",
  "com.tv.6monthlb" = "com.tv.6monthlb",
  "com.tv.monthlb" = "com.tv.monthlb",

  /* Old Subscriptions */
  "com.tv.year" = "com.tv.year",
  "com.tv.halfyear" = "com.tv.halfyear",
  "com.tv.monthnoads" = "com.tv.monthnoads",

  //----New subscriptions---//
  "com.tv.year.lowprice" = "com.tv.year.lowprice",
  "com.tv.threemonthlynoads.lowprice" = "com.tv.threemonthlynoads.lowprice",
  "com.tv.month.lowprice" = "com.tv.month.lowprice",
  "com.tv.yearlyads.lowprice" = "com.tv.yearlyads.lowprice",
  "com.tv.threemonthlywithads.lowprice" = "com.tv.threemonthlywithads.lowprice",

  //----Sale subscriptions---//
  "com.tv.yearlynoads72" = "com.tv.yearlynoads72",
  // "com.tv.onemonth" = "com.tv.onemonth", // 85% off
}

export const DifficultySettings = {
  [Difficulty.Beginner]: {
    restCount: 7,
    numOfVideos: 8,
    restTime: 1,
  },
  [Difficulty.Intermediate]: {
    restCount: 9,
    numOfVideos: 10,
    restTime: 1,
  },
  [Difficulty.Advanced]: {
    restCount: 11,
    numOfVideos: 12,
    restTime: 1,
  },
  WarmUpCoolDown: {
    restCount: 0,
    numOfVideos: 2,
    restTime: 0,
  },
  chest: {
    [Difficulty.Beginner]: {
      restCount: 4,
      numOfVideos: 5,
      restTime: 1,
    },
    [Difficulty.Intermediate]: {
      restCount: 5,
      numOfVideos: 6,
      restTime: 1,
    },
    [Difficulty.Advanced]: {
      restCount: 6,
      numOfVideos: 7,
      restTime: 1,
    },
  },
  biceps: {
    [Difficulty.Beginner]: {
      restCount: 5,
      numOfVideos: 6,
      restTime: 1,
    },
    [Difficulty.Intermediate]: {
      restCount: 6,
      numOfVideos: 7,
      restTime: 1,
    },
    [Difficulty.Advanced]: {
      restCount: 7,
      numOfVideos: 8,
      restTime: 1,
    },
  },
  abs: {
    [Difficulty.Beginner]: {
      restCount: 3,
      numOfVideos: 4,
      restTime: 1,
    },
    [Difficulty.Intermediate]: {
      restCount: 5,
      numOfVideos: 6,
      restTime: 1,
    },
    [Difficulty.Advanced]: {
      restCount: 7,
      numOfVideos: 8,
      restTime: 1,
    },
  },
  triceps: {
    [Difficulty.Beginner]: {
      restCount: 5,
      numOfVideos: 6,
      restTime: 1,
    },
    [Difficulty.Intermediate]: {
      restCount: 6,
      numOfVideos: 7,
      restTime: 1,
    },
    [Difficulty.Advanced]: {
      restCount: 7,
      numOfVideos: 8,
      restTime: 1,
    },
  },
  shoulders: {
    [Difficulty.Beginner]: {
      restCount: 4,
      numOfVideos: 5,
      restTime: 1,
    },
    [Difficulty.Intermediate]: {
      restCount: 5,
      numOfVideos: 6,
      restTime: 1,
    },
    [Difficulty.Advanced]: {
      restCount: 6,
      numOfVideos: 7,
      restTime: 1,
    },
  },
  legs: {
    [Difficulty.Beginner]: {
      restCount: 4,
      numOfVideos: 5,
      restTime: 1,
    },
    [Difficulty.Intermediate]: {
      restCount: 5,
      numOfVideos: 6,
      restTime: 1,
    },
    [Difficulty.Advanced]: {
      restCount: 6,
      numOfVideos: 7,
      restTime: 1,
    },
  },
  back: {
    [Difficulty.Beginner]: {
      restCount: 4,
      numOfVideos: 5,
      restTime: 1,
    },
    [Difficulty.Intermediate]: {
      restCount: 5,
      numOfVideos: 6,
      restTime: 1,
    },
    [Difficulty.Advanced]: {
      restCount: 6,
      numOfVideos: 7,
      restTime: 1,
    },
  },
  forearms: {
    [Difficulty.Beginner]: {
      restCount: 4,
      numOfVideos: 5,
      restTime: 1,
    },
    [Difficulty.Intermediate]: {
      restCount: 5,
      numOfVideos: 6,
      restTime: 1,
    },
    [Difficulty.Advanced]: {
      restCount: 6,
      numOfVideos: 7,
      restTime: 1,
    },
  },
  "total body": {
    [Difficulty.Beginner]: {
      restCount: 5,
      numOfVideos: 6,
      restTime: 1,
    },
    [Difficulty.Intermediate]: {
      restCount: 6,
      numOfVideos: 7,
      restTime: 1,
    },
    [Difficulty.Advanced]: {
      restCount: 7,
      numOfVideos: 8,
      restTime: 1,
    },
  },
  cardio: {
    [Difficulty.Beginner]: {
      restCount: 0,
      numOfVideos: 5,
      restTime: 0,
    },
    [Difficulty.Intermediate]: {
      restCount: 0,
      numOfVideos: 6,
      restTime: 0,
    },
    [Difficulty.Advanced]: {
      restCount: 0,
      numOfVideos: 7,
      restTime: 0,
    },
  },
  hiit: {
    [Difficulty.Beginner]: {
      restCount: 0,
      numOfVideos: 5,
      restTime: 0,
    },
    [Difficulty.Intermediate]: {
      restCount: 0,
      numOfVideos: 6,
      restTime: 0,
    },
    [Difficulty.Advanced]: {
      restCount: 6,
      numOfVideos: 7,
      restTime: 0,
    },
  },
  "lower body stretch": {
    ["WarmUpCoolDown"]: {
      restCount: 0,
      numOfVideos: 2,
      restTime: 0,
    },
    [Difficulty.Beginner]: {
      restCount: 0,
      numOfVideos: 6,
      restTime: 0,
    },
    [Difficulty.Intermediate]: {
      restCount: 0,
      numOfVideos: 7,
      restTime: 0,
    },
    [Difficulty.Advanced]: {
      restCount: 0,
      numOfVideos: 8,
      restTime: 0,
    },
  },
  "upper body stretch": {
    ["WarmUpCoolDown"]: {
      restCount: 0,
      numOfVideos: 2,
      restTime: 0,
    },
    [Difficulty.Beginner]: {
      restCount: 0,
      numOfVideos: 6,
      restTime: 0,
    },
    [Difficulty.Intermediate]: {
      restCount: 0,
      numOfVideos: 7,
      restTime: 0,
    },
    [Difficulty.Advanced]: {
      restCount: 0,
      numOfVideos: 8,
      restTime: 0,
    },
  },
  planks: {
    [Difficulty.Beginner]: {
      restCount: 3,
      numOfVideos: 4,
      restTime: 1,
    },
    [Difficulty.Intermediate]: {
      restCount: 5,
      numOfVideos: 6,
      restTime: 1,
    },
    [Difficulty.Advanced]: {
      restCount: 7,
      numOfVideos: 8,
      restTime: 1,
    },
  },
};

export const ACTIVITIES = [
  {
    name: "Basal Metabolic Rate (BMR)",
    shortName: "BMR",
    id: 0,
  },
  {
    name: "Sedentary - little or no exercise",
    shortName: "Sedentary",
    id: 1,
  },
  {
    name: "Lightly Active - exercise/sports 1-3 times/week",
    shortName: "Lightly Active",
    id: 2,
  },
  {
    name: "Moderately Active - exercise/sports 3-5 times/week",
    shortName: "Moderately Active",
    id: 3,
  },
  {
    name: "Very_Active - hard exercise/sports 6-7 times/week",
    shortName: "Very Active",
    id: 4,
  },
  {
    name: "Extra Active - very hard exercise/sports or physical job",
    shortName: "Extra Active",
    id: 5,
  },
];

export const EquipmentSettings = {
  dumbbells: {
    name: "Dumbbells",
    details: "A few sets of dumbbells ranging from 5 to 30 Lbs",
  },
  chair: {
    name: "Chair",
    details: "Keep an armless chair with strong back support on standby.",
  },
  // carpet: {
  //   name: "Yoga mat",
  //   details: "Keep a carpet or an exercise mat on standby.",
  // },
  // wall: {
  //   name: "Wall",
  //   details: "Support of a wall is needed to perform some of these exercises.",
  // },
};

export const ALL_VIDEOS_INFO = [
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Hammer W Raise",
    is_free: true,
    primary_muscle: "Traps, Rhomboids, Erector Spinae",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rear Shoulders, Teres Major, Teres Minor, Latissimus Dorsi, Infraspinatus",
    subcategory_name: "back",
    tertiary_muscle: "Lower Back",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_Hammer_W_Raise_1.jpg?alt=media&token=a37fde42-9319-4965-ad89-77ce3d4bd920",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_Hammer_W_Raise_1.mp4?alt=media&token=0e75697c-5b88-4733-a769-e8c3b90ab41c",
    video_id: "LnB_Hammer_W_Raise_1",
    video_length: 0.08,
    video_number: "77",
    size: 605699,
    hints: "LnB_Hammer_W_Raise_1_hints",
    common_mistakes: "LnB_Hammer_W_Raise_1_common_mistakes",
    breathing: "LnB_Hammer_W_Raise_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    is_other_side: 0,
    name: "Concentrated Hammer Curls",
    is_free: false,
    primary_muscle: "Biceps, Brachialis",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Forearm",
    subcategory_name: "biceps",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(L)%20Concentrated%20Hammer%20Curls.jpg?alt=media&token=bc22447a-026e-4aad-a2b8-afd80d12964b",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fnew-videos%2F(L)%20Concentrated%20Hammer%20Curls.mp4?alt=media&token=8a17bdf6-4ae9-469c-af61-6faa38002d5d",
    video_id: "CnB_Concentrated_Hammer_Curls_1",
    video_length: 0.1,
    video_number: "25",
    size: 636635,
    hints: "CnB_Concentrated_Hammer_Curls_1_hints",
    common_mistakes: "CnB_Concentrated_Hammer_Curls_1_common_mistakes",
    breathing: "CnB_Concentrated_Hammer_Curls_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Skull Crushers",
    is_free: true,
    primary_muscle: "Triceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Forearm, Shoulders",
    subcategory_name: "triceps",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FTnS_Skull_Crushers_1.jpg?alt=media&token=a3068dfd-268c-46d7-9228-80453fcb8cbe",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FTnS_Skull_Crushers_1.mp4?alt=media&token=8aaa079c-dc63-42bd-bb7a-d9ce003d4ac2",
    video_id: "TnS_Skull_Crushers_1",
    video_length: 0.1,
    video_number: "31",
    size: 680105,
    hints: "TnS_Skull_Crushers_1_hints",
    common_mistakes: "TnS_Skull_Crushers_1_common_mistakes",
    breathing: "TnS_Skull_Crushers_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Valley Press",
    is_free: true,
    primary_muscle: "Front Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Chest, Latissimus Dorsi, Middle Shoulders, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Rear Shoulders, Traps, Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FTnS_Valley_Press_2.jpg?alt=media&token=6610ad10-9552-46a3-b712-efbe1d5d2f15",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FTnS_Valley_Press_2.mp4?alt=media&token=1654235d-944f-4370-ac98-f8260d319e00",
    video_id: "TnS_Valley_Press_2",
    video_length: 0.07,
    video_number: "60",
    size: 475563,
    hints: "TnS_Valley_Press_2_hints",
    common_mistakes: "TnS_Valley_Press_2_common_mistakes",
    breathing: "TnS_Valley_Press_2_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "External Rotation",
    is_free: false,
    primary_muscle: "Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Traps, Chest, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FTnS_External_Rotation_3.jpg?alt=media&token=bdcabdd1-6071-4947-b24e-9f20e6dfcc27",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FTnS_External_Rotation_3.mp4?alt=media&token=777ca1b2-1723-4b7e-bcb1-4f1b2d63fda1",
    video_id: "TnS_External_Rotation_3",
    video_length: 0.12,
    video_number: "88",
    size: 730162,
    hints: "TnS_External_Rotation_3_hints",
    common_mistakes: "TnS_External_Rotation_3_common_mistakes",
    breathing: "TnS_External_Rotation_3_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Single Dumbbell Front Raise",
    is_free: true,
    primary_muscle: "Front Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Upper Chest, Middle Shoulders, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Rear Shoulders, Traps, Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FTnS_Single_Dumbbell_Front_Raise_4.jpg?alt=media&token=547e5646-55ed-4573-9484-56ace3a4be4e",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FTnS_Single_Dumbbell_Front_Raise_4.mp4?alt=media&token=717e5dbd-b407-4633-81b5-63827db8c687",
    video_id: "TnS_Single_Dumbbell_Front_Raise_4",
    video_length: 0.08,
    video_number: "56",
    size: 614323,
    hints: "TnS_Single_Dumbbell_Front_Raise_4_hints",
    common_mistakes: "TnS_Single_Dumbbell_Front_Raise_4_common_mistakes",
    breathing: "TnS_Single_Dumbbell_Front_Raise_4_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Wide Decline Push Ups",
    is_free: false,
    primary_muscle: "Upper Chest",
    rep_range: "10-20",
    secondary_muscle: "Middle Chest, Lower Chest, Shoulders, Triceps, Core, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Wide_Decline_Push_Ups_2.jpg?alt=media&token=b3aa9c72-7acb-4027-a9c1-1e6372520379",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "Chair",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Wide_Decline_Push_Ups_2.mp4?alt=media&token=007d85e3-181f-4515-b5e9-055862d1d9b4",
    video_id: "CnB_Wide_Decline_Push_Ups_2",
    video_length: 0.07,
    video_number: "134",
    size: 511456,
    hints: "CnB_Wide_Decline_Push_Ups_2_hints",
    common_mistakes: "CnB_Wide_Decline_Push_Ups_2_common_mistakes",
    breathing: "CnB_Wide_Decline_Push_Ups_2_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Seated Hammer Curls",
    is_free: true,
    primary_muscle: "Biceps, Brachialis",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Forearm",
    subcategory_name: "biceps",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSeated%20Hammer%20Curls.jpg?alt=media&token=8e39e3de-4e9b-4de0-98b3-79f6698738e1",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fnew-videos%2FSeated%20Hammer%20Curls.mp4?alt=media&token=1b278807-02a6-400f-b832-4106248cee0d",
    video_id: "CnB_Seated_Hammer_Curls_3",
    video_length: 0.08,
    video_number: "20",
    size: 573381,
    hints: "CnB_Seated_Hammer_Curls_3_hints",
    common_mistakes: "CnB_Seated_Hammer_Curls_3_common_mistakes",
    breathing: "CnB_Seated_Hammer_Curls_3_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Jumping Decline Push Ups",
    is_free: false,
    primary_muscle: "Upper Chest",
    rep_range: "10-20",
    secondary_muscle: "Middle Chest, Lower Chest, Shoulders, Triceps, Core, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Jumping_Decline_Push_Ups_4.jpg?alt=media&token=35195eea-3551-4788-913d-11c73534501c",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "Chair",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Jumping_Decline_Push_Ups_4.mp4?alt=media&token=23b27364-8645-4dd6-9ffc-04a25897e499",
    video_id: "CnB_Jumping_Decline_Push_Ups_4",
    video_length: 0.07,
    video_number: "135",
    size: 445951,
    hints: "CnB_Jumping_Decline_Push_Ups_4_hints",
    common_mistakes: "CnB_Jumping_Decline_Push_Ups_4_common_mistakes",
    breathing: "CnB_Jumping_Decline_Push_Ups_4_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Crunchy Frog",
    is_free: false,
    primary_muscle: "Abs",
    rep_range: "Time Based",
    secondary_muscle: "Obliques, Hip Flexors",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FAbs_Crunchy_Frog_1.jpg?alt=media&token=12cd6054-04ff-4b1e-b116-2efb25f0c0da",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FAbs_Crunchy_Frog_1.mp4?alt=media&token=58428fba-a746-4d45-a477-2d09a9e855a0",
    video_id: "Abs_Crunchy_Frog_1",
    video_length: 0.07,
    video_number: "46",
    size: 497718,
    hints: "Abs_Crunchy_Frog_1_hints",
    common_mistakes: "Abs_Crunchy_Frog_1_common_mistakes",
    breathing: "Abs_Crunchy_Frog_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Alternating Explosive Push Ups",
    is_free: false,
    primary_muscle: "Middle Chest",
    rep_range: "8-20",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Triceps, Core, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Alternating_Explosive_Push_Ups_5.jpg?alt=media&token=944cdb8d-50bb-4d46-83f3-5cf0d46acfb3",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Alternating_Explosive_Push_Ups_5.mp4?alt=media&token=1b3e0e56-20a6-43fc-91e9-5291969eb128",
    video_id: "CnB_Alternating_Explosive_Push_Ups_5",
    video_length: 0.08,
    video_number: "12",
    size: 611916,
    hints: "CnB_Alternating_Explosive_Push_Ups_5_hints",
    common_mistakes: "CnB_Alternating_Explosive_Push_Ups_5_common_mistakes",
    breathing: "CnB_Alternating_Explosive_Push_Ups_5_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Small Scissors",
    is_free: true,
    primary_muscle: "Lower Abs",
    rep_range: "Time Based",
    secondary_muscle: "Upper Abs, Obliques, Hip Flexors",
    tertiary_muscle: "Quads",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FAbs_Small_Scissors_2.jpg?alt=media&token=98858d54-438b-439f-a08b-85f86da5e089",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FAbs_Small_Scissors_2.mp4?alt=media&token=44addcf5-43ba-4053-8162-713557e7591c",
    video_id: "Abs_Small_Scissors_2",
    video_length: 0.05,
    video_number: "29",
    size: 403201,
    hints: "Abs_Small_Scissors_2_hints",
    common_mistakes: "Abs_Small_Scissors_2_common_mistakes",
    breathing: "Abs_Small_Scissors_2_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Up Right Row",
    is_free: true,
    primary_muscle: "Traps, Rhomboids, Middle Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Front Shoulders, Rear Shoulders, Rhomboids, Teres Minor, Infraspinatus, Biceps",
    subcategory_name: "back",
    tertiary_muscle: "Teres Major",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_Up_Right_Row_2.jpg?alt=media&token=71e3a05d-c897-4b8a-ae9b-52a3d5b7da1c",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_Up_Right_Row_2.mp4?alt=media&token=6ed18260-d48b-40b6-9376-4a61b4a29917",
    video_id: "LnB_Up_Right_Row_2",
    video_length: 0.07,
    video_number: "93",
    size: 506912,
    hints: "LnB_Up_Right_Row_2_hints",
    common_mistakes: "LnB_Up_Right_Row_2_common_mistakes",
    breathing: "LnB_Up_Right_Row_2_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Seated Arnold Presses",
    is_free: true,
    primary_muscle: "Front Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Chest, Traps, Middle Shoulders, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Rear Shoulders, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSeated%20Arnold%20Press.jpg?alt=media&token=92765bac-925b-454a-8cd5-f1730cec3ccd",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fnew-videos%2FSeated%20Arnold%20Press.mp4?alt=media&token=9aa90669-6c59-4a20-b694-fdddb6301968",
    video_id: "TnS_Seated_Arnold_Presses_5",
    video_length: 0.08,
    video_number: "68",
    size: 607078,
    hints: "TnS_Seated_Arnold_Presses_5_hints",
    common_mistakes: "TnS_Seated_Arnold_Presses_5_common_mistakes",
    breathing: "TnS_Seated_Arnold_Presses_5_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Reverse Grip Kickbacks",
    is_free: true,
    primary_muscle: "Triceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rear Shoulders",
    subcategory_name: "triceps",
    tertiary_muscle: "Latissimus Dorsi, Traps, Rhomboids",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FTnS_Reverse_Grip_Kickbacks_6.jpg?alt=media&token=644ce6a4-978b-484a-af0b-4c0ddbfc02e0",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FTnS_Reverse_Grip_Kickbacks_6.mp4?alt=media&token=080e3111-d864-496f-8214-c8f5caebb3fe",
    video_id: "TnS_Reverse_Grip_Kickbacks_6",
    video_length: 0.08,
    video_number: "42",
    size: 539401,
    hints: "TnS_Reverse_Grip_Kickbacks_6_hints",
    common_mistakes: "TnS_Reverse_Grip_Kickbacks_6_common_mistakes",
    breathing: "TnS_Reverse_Grip_Kickbacks_6_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "2 Slow 2 Fast Push Ups",
    is_free: false,
    primary_muscle: "Middle Chest",
    rep_range: "8-12",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Triceps, Core, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_2Slow2Fast_Push_Ups_6.jpg?alt=media&token=a74fe63e-4457-4286-b009-a5026746b2f8",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_2Slow2Fast_Push_Ups_6.mp4?alt=media&token=17cc51b7-1e8a-4d35-8ad3-14b3c181c1ac",
    video_id: "CnB_2Slow2Fast_Push_Ups_6",
    video_length: 0.2,
    video_number: "19",
    size: 1331172,
    hints: "CnB_2Slow2Fast_Push_Ups_6_hints",
    common_mistakes: "CnB_2Slow2Fast_Push_Ups_6_common_mistakes",
    breathing: "CnB_2Slow2Fast_Push_Ups_6_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Reverse Grip Curls",
    is_free: false,
    primary_muscle: "Biceps (Long Head), Brachialis, Forearm",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Biceps (Short Head)",
    subcategory_name: "biceps",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Reverse_Grip_Curls_7.jpg?alt=media&token=fe52c9f5-b294-437b-9271-adacba6173ab",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Reverse_Grip_Curls_7.mp4?alt=media&token=ffe4884a-cd1f-4b75-b07c-8ca4114a0aa7",
    video_id: "CnB_Reverse_Grip_Curls_7",
    video_length: 0.08,
    video_number: "65",
    size: 599942,
    hints: "CnB_Reverse_Grip_Curls_7_hints",
    common_mistakes: "CnB_Reverse_Grip_Curls_7_common_mistakes",
    breathing: "CnB_Reverse_Grip_Curls_7_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "V Ups",
    is_free: false,
    primary_muscle: "Abs",
    rep_range: "Time Based",
    secondary_muscle:
      "Obliques, Core, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FAbs_V_Ups_3.jpg?alt=media&token=e018c7f5-0643-4c0a-8b2c-e580f7d1b653",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FAbs_V_Ups_3.mp4?alt=media&token=45e3e65a-9ca9-4326-b96d-cf9e101fe639",
    video_id: "Abs_V_Ups_3",
    video_length: 0.1,
    video_number: "7",
    size: 731046,
    hints: "Abs_V_Ups_3_hints",
    common_mistakes: "Abs_V_Ups_3_common_mistakes",
    breathing: "Abs_V_Ups_3_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Deadlifts",
    is_free: false,
    primary_muscle: "Quads, Glutes, Erector Spinae",
    rep_range: "15-20",
    secondary_muscle: "Hamstrings, Adductors, Traps, Lower Back, Latissimus Dorsi",
    subcategory_name: "legs",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_Deadlifts_3.jpg?alt=media&token=d298e2d8-dda6-4cc1-9671-bcce51610b56",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_Deadlifts_3.mp4?alt=media&token=3f14704d-3dc4-45cc-bc40-6df7dfabf3d0",
    video_id: "LnB_Deadlifts_3",
    video_length: 0.05,
    video_number: "99",
    size: 394219,
    hints: "LnB_Deadlifts_3_hints",
    common_mistakes: "LnB_Deadlifts_3_common_mistakes",
    breathing: "LnB_Deadlifts_3_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    is_other_side: 0,
    name: "Lawnmower",
    is_free: false,
    primary_muscle: "Traps, Rhomboids, Erector Spinae",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rear Shoulders, Teres Major, Teres Minor, Latissimus Dorsi, Infraspinatus, Biceps",
    subcategory_name: "back",
    tertiary_muscle: "Middle Shoulders, Front Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_Lawnmower_4.jpg?alt=media&token=be3517c6-af03-4767-b55c-f48b68502e9d",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_Lawnmower_4.mp4?alt=media&token=a6d799c5-4b36-443f-a488-ab055abbc75c",
    video_id: "LnB_Lawnmower_4",
    video_length: 0.07,
    video_number: "82",
    size: 483883,
    hints: "LnB_Lawnmower_4_hints",
    common_mistakes: "LnB_Lawnmower_4_common_mistakes",
    breathing: "LnB_Lawnmower_4_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    is_other_side: 0,
    name: "One Leg Squats",
    is_free: false,
    primary_muscle: "Quads, Glutes",
    rep_range: "5-10",
    secondary_muscle: "Hamstrings, Adductors",
    subcategory_name: "legs",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_One_Leg_Squats_5.jpg?alt=media&token=efc92a60-0527-4dbe-8029-2e8582ca21d5",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_One_Leg_Squats_5.mp4?alt=media&token=436fc308-e247-44aa-b316-9b3de19c96db",
    video_id: "LnB_One_Leg_Squats_5",
    video_length: 0.1,
    video_number: "116(1)",
    size: 705032,
    hints: "LnB_One_Leg_Squats_5_hints",
    common_mistakes: "LnB_One_Leg_Squats_5_common_mistakes",
    breathing: "LnB_One_Leg_Squats_5_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Crunch",
    is_free: true,
    primary_muscle: "Upper Abs",
    rep_range: "Time Based",
    secondary_muscle: "Lower Abs, Obliques",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FAbs_Crunch_4.jpg?alt=media&token=47ec303f-0d84-4d47-ad90-9b8220284a57",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FAbs_Crunch_4.mp4?alt=media&token=6ad405fb-02e0-46fd-8b76-3d472ef6db9f",
    video_id: "Abs_Crunch_4",
    video_length: 0.08,
    video_number: "17",
    size: 635510,
    hints: "Abs_Crunch_4_hints",
    common_mistakes: "Abs_Crunch_4_common_mistakes",
    breathing: "Abs_Crunch_4_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Straight Curl",
    is_free: true,
    primary_muscle: "Biceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Brachialis, Forearm",
    subcategory_name: "biceps",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Straight_Curl_8.jpg?alt=media&token=d40bc84b-981c-4cb1-ab38-25e7cce82da7",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Straight_Curl_8.mp4?alt=media&token=5735b922-42a7-4758-9570-86aadd810c5f",
    video_id: "CnB_Straight_Curl_8",
    video_length: 0.08,
    video_number: "66",
    size: 580443,
    hints: "CnB_Straight_Curl_8_hints",
    common_mistakes: "CnB_Straight_Curl_8_common_mistakes",
    breathing: "CnB_Straight_Curl_8_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Lateral Raise And Shoulder Extensions",
    is_free: false,
    primary_muscle: "Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Traps, Chest, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FTnS_Lateral_Raise_And_Shoulder_Extensions_7.jpg?alt=media&token=dfcb1c49-ee14-4f18-881b-d54e5aecfdf1",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FTnS_Lateral_Raise_And_Shoulder_Extensions_7.mp4?alt=media&token=fafafe49-c8f1-4e6e-8cda-9c4415010e66",
    video_id: "TnS_Lateral_Raise_And_Shoulder_Extensions_7",
    video_length: 0.08,
    video_number: "74",
    size: 561004,
    hints: "TnS_Lateral_Raise_And_Shoulder_Extensions_7_hints",
    common_mistakes: "TnS_Lateral_Raise_And_Shoulder_Extensions_7_common_mistakes",
    breathing: "TnS_Lateral_Raise_And_Shoulder_Extensions_7_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Weighted Squats",
    is_free: true,
    primary_muscle: "Quads, Glutes",
    rep_range: "15-20",
    secondary_muscle: "Hamstrings Adductors, Erector Spinae",
    subcategory_name: "legs",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_Weighted_Squats_6.jpg?alt=media&token=a71a9bad-d3c6-4e80-b141-31d780712821",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_Weighted_Squats_6.mp4?alt=media&token=9d0ea8b6-9e02-4baa-903c-b90673161391",
    video_id: "LnB_Weighted_Squats_6",
    video_length: 0.1,
    video_number: "107",
    size: 629371,
    hints: "LnB_Weighted_Squats_6_hints",
    common_mistakes: "LnB_Weighted_Squats_6_common_mistakes",
    breathing: "LnB_Weighted_Squats_6_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Standard Push Ups",
    is_free: false,
    primary_muscle: "Middle Chest",
    rep_range: "10-20",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Triceps, Core, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Standard_Push_Ups_9.jpg?alt=media&token=b7d30ab0-6acc-463f-837e-231b4e6d2978",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Standard_Push_Ups_9.mp4?alt=media&token=8e666590-0cc2-4997-8f37-fb5381552869",
    video_id: "CnB_Standard_Push_Ups_9",
    video_length: 0.07,
    video_number: "3",
    size: 503732,
    hints: "CnB_Standard_Push_Ups_9_hints",
    common_mistakes: "CnB_Standard_Push_Ups_9_common_mistakes",
    breathing: "CnB_Standard_Push_Ups_9_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Touch The Sky Hold",
    is_free: false,
    primary_muscle: "Abs",
    rep_range: "Time Based",
    secondary_muscle: "Obliques",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FAbs_Touch_The_Sky_Hold_5.jpg?alt=media&token=3ff0a459-487d-4c6f-a2d8-6a6f1afb1996",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FAbs_Touch_The_Sky_Hold_5.mp4?alt=media&token=550d3d35-503f-42d3-9299-5f9a2b160935",
    video_id: "Abs_Touch_The_Sky_Hold_5",
    video_length: 0.05,
    video_number: "52",
    size: 378801,
    hints: "Abs_Touch_The_Sky_Hold_5_hints",
    common_mistakes: "Abs_Touch_The_Sky_Hold_5_common_mistakes",
    breathing: "Abs_Touch_The_Sky_Hold_5_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Seated Alternating Shoulder Press",
    is_free: false,
    primary_muscle: "Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Traps, Chest, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSeated%20Alternating%20Shoulder%20Press-0.jpg?alt=media&token=7a33d614-45c3-42f6-9a66-5ef302851906",
    time: "75 seconds",
    time_to_play: 1.25,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fnew-videos%2FSeated%20Alternating%20Shoulder%20Press.mp4?alt=media&token=5811160e-3035-4477-8fe1-7fea88f45129",
    video_id: "TnS_Seated_Alternating_Shoulder_Press_8",
    video_length: 0.05,
    video_number: "98",
    size: 420357,
    hints: "TnS_Seated_Alternating_Shoulder_Press_8_hints",
    common_mistakes: "TnS_Seated_Alternating_Shoulder_Press_8_common_mistakes",
    breathing: "TnS_Seated_Alternating_Shoulder_Press_8_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Seated Shoulder Press",
    is_free: false,
    primary_muscle: "Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Traps, Chest, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSeated%20Shoulder%20Press.jpg?alt=media&token=06d6fc95-eb16-429a-b995-7996a10b19d5",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fnew-videos%2FSeated%20Shoulder%20Press.mp4?alt=media&token=e6f35543-045b-4dfc-a850-5e7d40fbc808",
    video_id: "TnS_Seated_Shoulder_Press_9",
    video_length: 0.1,
    video_number: "97",
    size: 643151,
    hints: "TnS_Seated_Shoulder_Press_9_hints",
    common_mistakes: "TnS_Seated_Shoulder_Press_9_common_mistakes",
    breathing: "TnS_Seated_Shoulder_Press_9_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Slow Mo Throw",
    is_free: false,
    primary_muscle: "Front Shoulders, Rear Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Latissimus Dorsi, Middle Shoulders, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Traps, Chest",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSlow%20Mo%20Throw.jpg?alt=media&token=27c1a2b0-3986-497d-9522-b15b68e8d83d",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fnew-videos%2FSlow%20Mo%20Throw.mp4?alt=media&token=c42353f4-fbf6-4c56-9829-2b4be906cc5b",
    video_id: "TnS_Slow_Mo_Throw_10",
    video_length: 0.1,
    video_number: "61",
    size: 680418,
    hints: "TnS_Slow_Mo_Throw_10_hints",
    common_mistakes: "TnS_Slow_Mo_Throw_10_common_mistakes",
    breathing: "TnS_Slow_Mo_Throw_10_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Military Push Ups",
    is_free: true,
    primary_muscle: "Middle Chest",
    rep_range: "10-20",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Triceps, Core, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Military_Push_Ups_10.jpg?alt=media&token=a4f64c58-e4e4-46d6-9325-dc9f35274e0b",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Military_Push_Ups_10.mp4?alt=media&token=ed0c92e6-6120-4474-ae3d-ed3c6d67eb3e",
    video_id: "CnB_Military_Push_Ups_10",
    video_length: 0.08,
    video_number: "7",
    size: 539305,
    hints: "CnB_Military_Push_Ups_10_hints",
    common_mistakes: "CnB_Military_Push_Ups_10_common_mistakes",
    breathing: "CnB_Military_Push_Ups_10_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Tuck Crunch",
    is_free: false,
    primary_muscle: "Abs",
    rep_range: "Time Based",
    secondary_muscle: "Obliques",
    tertiary_muscle: "Quads",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FAbs_Tuck_Crunch_6.jpg?alt=media&token=5ef4a59c-754f-4f0f-bec8-7ec384060be5",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FAbs_Tuck_Crunch_6.mp4?alt=media&token=a0729a2f-a30b-4c4d-81e7-f01e8c4a5277",
    video_id: "Abs_Tuck_Crunch_6",
    video_length: 0.07,
    video_number: "19",
    size: 527154,
    hints: "Abs_Tuck_Crunch_6_hints",
    common_mistakes: "Abs_Tuck_Crunch_6_common_mistakes",
    breathing: "Abs_Tuck_Crunch_6_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Front Presses",
    is_free: false,
    primary_muscle: "Front Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Chest, Traps, Middle Shoulders, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Rear Shoulders, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FFront%20Presses.jpg?alt=media&token=a30212d7-88b8-430c-8c8d-e36e3a0ed983",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fnew-videos%2FFront%20Presses.mp4?alt=media&token=faf9b8ae-2543-49d4-a921-c8bf06d55f3e",
    video_id: "TnS_Front_Presses_11",
    video_length: 0.08,
    video_number: "65",
    size: 601240,
    hints: "TnS_Front_Presses_11_hints",
    common_mistakes: "TnS_Front_Presses_11_common_mistakes",
    breathing: "TnS_Front_Presses_11_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Reverse Grip Press",
    is_free: true,
    primary_muscle: "Middle Chest, Triceps",
    rep_range: "20-30",
    secondary_muscle: "Upper Chest, Lower Chest, Front Shoulders, Serratus Anterior",
    subcategory_name: "triceps",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Biceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FTnS_Reverse_Grip_Press_12.jpg?alt=media&token=12ea1caf-d20d-4927-8193-440685d0cb64",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FTnS_Reverse_Grip_Press_12.mp4?alt=media&token=f8fc4295-c6f1-4a58-b207-a97c24eaec53",
    video_id: "TnS_Reverse_Grip_Press_12",
    video_length: 0.08,
    video_number: "40",
    size: 513115,
    hints: "TnS_Reverse_Grip_Press_12_hints",
    common_mistakes: "TnS_Reverse_Grip_Press_12_common_mistakes",
    breathing: "TnS_Reverse_Grip_Press_12_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    is_other_side: 0,
    name: "Single Arm Lateral Raise",
    is_free: false,
    primary_muscle: "Middle Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Front Shoulders, Traps, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Rear Shoulders, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(L)%20Single%20Arm%20Lateral%20Raise.jpg?alt=media&token=335009e2-9798-4b4e-a9ad-158aef6decae",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fnew-videos%2F(L)%20Single%20Arm%20Lateral%20Raise.mp4?alt=media&token=159d23b1-a9bf-4534-9fd5-7b7102328135",
    video_id: "TnS_Single_Arm_Lateral_Raise_13",
    video_length: 0.08,
    video_number: "78",
    size: 559936,
    hints: "TnS_Single_Arm_Lateral_Raise_13_hints",
    common_mistakes: "TnS_Single_Arm_Lateral_Raise_13_common_mistakes",
    breathing: "TnS_Single_Arm_Lateral_Raise_13_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Wide Leg Sit Ups",
    is_free: false,
    primary_muscle: "Upper Abs, Obliques",
    rep_range: "Time Based",
    secondary_muscle: "Lower Abs, Obliques",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FAbs_Wide_Leg_Sit_Ups_7.jpg?alt=media&token=7e16e24a-8d0a-40ce-867b-2b871e5e2804",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FAbs_Wide_Leg_Sit_Ups_7.mp4?alt=media&token=f506e5ac-cb53-4fe4-82a5-db9175a00c07",
    video_id: "Abs_Wide_Leg_Sit_Ups_7",
    video_length: 0.12,
    video_number: "47",
    size: 833455,
    hints: "Abs_Wide_Leg_Sit_Ups_7_hints",
    common_mistakes: "Abs_Wide_Leg_Sit_Ups_7_common_mistakes",
    breathing: "Abs_Wide_Leg_Sit_Ups_7_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "V Hold",
    is_free: false,
    primary_muscle: "Abs",
    rep_range: "Time Based",
    secondary_muscle: "Obliques, Hip Flexors",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FAbs_V_Hold_8.jpg?alt=media&token=8782c6d2-2497-4c69-b3ad-0232fb8325c2",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FAbs_V_Hold_8.mp4?alt=media&token=5df90368-12ac-4c6d-9160-131d87b0532c",
    video_id: "Abs_V_Hold_8",
    video_length: 0.07,
    video_number: "33",
    size: 442068,
    hints: "Abs_V_Hold_8_hints",
    common_mistakes: "Abs_V_Hold_8_common_mistakes",
    breathing: "Abs_V_Hold_8_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    is_other_side: 0,
    name: "One Leg Wall Squat",
    is_free: false,
    primary_muscle: "Quads, Glutes",
    rep_range: "Time Based",
    secondary_muscle: "Hamstrings, Adductors",
    subcategory_name: "legs",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_One_Leg_Wall_Squat_8.jpg?alt=media&token=da6387bd-3628-4c6c-b149-babc2bafa0bc",
    time: "45 seconds",
    time_to_play: 0.5,
    tools: "Wall",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_One_Leg_Wall_Squat_8.mp4?alt=media&token=5936258b-2422-4c60-bbc2-44aeef46625e",
    video_id: "LnB_One_Leg_Wall_Squat_8",
    video_length: 0.07,
    video_number: "126",
    size: 420128,
    hints: "LnB_One_Leg_Wall_Squat_8_hints",
    common_mistakes: "LnB_One_Leg_Wall_Squat_8_common_mistakes",
    breathing: "LnB_One_Leg_Wall_Squat_8_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Flip Kickbacks",
    is_free: false,
    primary_muscle: "Triceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rear Shoulders",
    subcategory_name: "triceps",
    tertiary_muscle: "Latissimus Dorsi, Traps, Rhomboids",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FTnS_Flip_Kickbacks_14.jpg?alt=media&token=c682e59c-df30-4c0d-8f15-6332ce011bde",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FTnS_Flip_Kickbacks_14.mp4?alt=media&token=a9a18977-a806-4480-970e-09bea774fb70",
    video_id: "TnS_Flip_Kickbacks_14",
    video_length: 0.12,
    video_number: "36",
    size: 777402,
    hints: "TnS_Flip_Kickbacks_14_hints",
    common_mistakes: "TnS_Flip_Kickbacks_14_common_mistakes",
    breathing: "TnS_Flip_Kickbacks_14_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Zottman Curl",
    is_free: false,
    primary_muscle: "Biceps, Brachialis, Forearm",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Shoulders",
    subcategory_name: "biceps",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Zottman_Curl_11.jpg?alt=media&token=da9d9b96-ffef-416d-8dba-f21ab00d74b3",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Zottman_Curl_11.mp4?alt=media&token=e5af3631-19ee-44b7-b4f3-5d554eb3426b",
    video_id: "CnB_Zottman_Curl_11",
    video_length: 0.12,
    video_number: "73",
    size: 790972,
    hints: "CnB_Zottman_Curl_11_hints",
    common_mistakes: "CnB_Zottman_Curl_11_common_mistakes",
    breathing: "CnB_Zottman_Curl_11_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "2 Hold Decline Push Ups",
    is_free: true,
    primary_muscle: "Upper Chest",
    rep_range: "10-20",
    secondary_muscle: "Middle Chest, Lower Chest, Shoulders, Triceps, Core, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_2_Hold_Decline_Push_Ups_12.jpg?alt=media&token=5dc04891-30b6-4829-90b6-66f698c9c221",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "Chair",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_2_Hold_Decline_Push_Ups_12.mp4?alt=media&token=7995bd93-35d9-4787-8384-5298beb1d29c",
    video_id: "CnB_2_Hold_Decline_Push_Ups_12",
    video_length: 0.07,
    video_number: "137",
    size: 443788,
    hints: "CnB_2_Hold_Decline_Push_Ups_12_hints",
    common_mistakes: "CnB_2_Hold_Decline_Push_Ups_12_common_mistakes",
    breathing: "CnB_2_Hold_Decline_Push_Ups_12_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Popeye Curls",
    is_free: true,
    primary_muscle: "Biceps (Long Head)",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Biceps (Short Head), Brachialis, Forearm",
    subcategory_name: "biceps",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Popeye_Curls_13.jpg?alt=media&token=01ab92d0-66fd-4c61-9bf1-f0d72d02744c",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Popeye_Curls_13.mp4?alt=media&token=9a6f4864-b24b-4447-8778-df1204da9670",
    video_id: "CnB_Popeye_Curls_13",
    video_length: 0.08,
    video_number: "44",
    size: 530817,
    hints: "CnB_Popeye_Curls_13_hints",
    common_mistakes: "CnB_Popeye_Curls_13_common_mistakes",
    breathing: "CnB_Popeye_Curls_13_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Bridge Burner",
    is_free: false,
    primary_muscle: "Abs",
    rep_range: "Time Based",
    secondary_muscle: "Obliques",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FAbs_Bridge_Burner_9.jpg?alt=media&token=51a40d0a-e135-4798-a161-3fb6b6f48b01",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FAbs_Bridge_Burner_9.mp4?alt=media&token=70bbdcab-54dd-48cc-9de4-1d062bba567b",
    video_id: "Abs_Bridge_Burner_9",
    video_length: 0.13,
    video_number: "31",
    size: 875201,
    hints: "Abs_Bridge_Burner_9_hints",
    common_mistakes: "Abs_Bridge_Burner_9_common_mistakes",
    breathing: "Abs_Bridge_Burner_9_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Seated Rear Delt Raises",
    is_free: false,
    primary_muscle: "Rear Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Middle Shoulders, Traps, Rhomboids, Infraspinatus, Teres Minor",
    subcategory_name: "shoulders",
    tertiary_muscle: "Front Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSeated%20Rear%20Delt%20Raises.jpg?alt=media&token=2ac332b8-510b-4b71-b2c2-b38467a4b910",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fnew-videos%2FSeated%20Rear%20Delt%20Raises.mp4?alt=media&token=01effb24-9975-4a3f-9507-c4cb9d087592",
    video_id: "TnS_Seated_Rear_Delt_Raises_15",
    video_length: 0.12,
    video_number: "87",
    size: 750979,
    hints: "TnS_Seated_Rear_Delt_Raises_15_hints",
    common_mistakes: "TnS_Seated_Rear_Delt_Raises_15_common_mistakes",
    breathing: "TnS_Seated_Rear_Delt_Raises_15_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Tricep Press",
    is_free: false,
    primary_muscle: "Triceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Forearm, Shoulders",
    subcategory_name: "triceps",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FTnS_Tricep_Press_16.jpg?alt=media&token=8e8e3845-a5a0-473d-8c79-9482a76db56b",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FTnS_Tricep_Press_16.mp4?alt=media&token=48a586c2-4117-46e6-b53c-8c020ee90a25",
    video_id: "TnS_Tricep_Press_16",
    video_length: 0.1,
    video_number: "4",
    size: 718646,
    hints: "TnS_Tricep_Press_16_hints",
    common_mistakes: "TnS_Tricep_Press_16_common_mistakes",
    breathing: "TnS_Tricep_Press_16_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "In and Out Push ups",
    is_free: true,
    primary_muscle: "Middle Chest",
    rep_range: "10-20",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Triceps, Core, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_In_and_Out_Push_ups_14.jpg?alt=media&token=43d19b10-2365-4107-9bd5-b19bc6bb487f",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_In_and_Out_Push_ups_14.mp4?alt=media&token=124cd10c-1294-4369-9d20-76974ad87cdf",
    video_id: "CnB_In_and_Out_Push_ups_14",
    video_length: 0.12,
    video_number: "4",
    size: 819003,
    hints: "CnB_In_and_Out_Push_ups_14_hints",
    common_mistakes: "CnB_In_and_Out_Push_ups_14_common_mistakes",
    breathing: "CnB_In_and_Out_Push_ups_14_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Decline Push Ups",
    is_free: false,
    primary_muscle: "Upper Chest",
    rep_range: "10-20",
    secondary_muscle: "Middle Chest, Lower Chest, Shoulders, Triceps, Core, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Decline_Push_Ups_15.jpg?alt=media&token=3e2726bd-7732-467d-b3c6-ec61dfda257b",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "Chair",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Decline_Push_Ups_15.mp4?alt=media&token=cdfa3bed-038c-4c98-9776-45b29efd0788",
    video_id: "CnB_Decline_Push_Ups_15",
    video_length: 0.07,
    video_number: "133",
    size: 436570,
    hints: "CnB_Decline_Push_Ups_15_hints",
    common_mistakes: "CnB_Decline_Push_Ups_15_common_mistakes",
    breathing: "CnB_Decline_Push_Ups_15_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Curls",
    is_free: true,
    primary_muscle: "Biceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Brachialis, Forearm",
    subcategory_name: "biceps",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Curls_16.jpg?alt=media&token=6ae3438b-c438-4f9f-8cee-f22540607061",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Curls_16.mp4?alt=media&token=bed6fbf7-1a19-4c52-8542-10f14b1d1378",
    video_id: "CnB_Curls_16",
    video_length: 0.08,
    video_number: "45",
    size: 552916,
    hints: "CnB_Curls_16_hints",
    common_mistakes: "CnB_Curls_16_common_mistakes",
    breathing: "CnB_Curls_16_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    is_other_side: 0,
    name: "Single Arm Pull Overs",
    is_free: false,
    primary_muscle: "Latissimus Dorsi",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rhomboids, Rear Shoulders, Teres Major, Teres Minor, Infraspinatus, Chest, Traps",
    subcategory_name: "back",
    tertiary_muscle: "Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_Single_Arm_Pull_Overs_9.jpg?alt=media&token=dbcc1621-86e6-4814-935c-51bbd66cc107",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_Single_Arm_Pull_Overs_9.mp4?alt=media&token=7d75dbe0-95e0-4763-8394-7d77e6deef68",
    video_id: "LnB_Single_Arm_Pull_Overs_1",
    video_length: 0.07,
    video_number: "41(2)",
    size: 488170,
    hints: "LnB_Single_Arm_Pull_Overs_1_hints",
    common_mistakes: "LnB_Single_Arm_Pull_Overs_1_common_mistakes",
    breathing: "LnB_Single_Arm_Pull_Overs_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Chair Dips",
    is_free: true,
    primary_muscle: "Triceps",
    rep_range: "20-30",
    secondary_muscle: "Front Shoulders, Chest, Latissimus Dorsi",
    subcategory_name: "triceps",
    tertiary_muscle: "Biceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FChair%20Dips.jpg?alt=media&token=c46cf90d-06e7-4848-b48b-879eeb8da586",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fnew-videos%2FChair%20Dips.mp4?alt=media&token=ef5dee0b-2151-4b50-a578-2b0b9113178a",
    video_id: "TnS_Dips_17",
    video_length: 0.07,
    video_number: "14",
    size: 422850,
    hints: "TnS_Dips_17_hints",
    common_mistakes: "TnS_Dips_17_common_mistakes",
    breathing: "TnS_Dips_17_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Roman Twist",
    is_free: false,
    primary_muscle: "Upper Abs, Obliques",
    rep_range: "Time Based",
    secondary_muscle: "Lower Abs",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FAbs_Roman_Twist_10.jpg?alt=media&token=55e96403-d4bc-4363-b9d0-3ec8a718ca53",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FAbs_Roman_Twist_10.mp4?alt=media&token=c825890f-88c0-480f-aeed-1169c1cd40e9",
    video_id: "Abs_Roman_Twist_10",
    video_length: 0.08,
    video_number: "11",
    size: 554340,
    hints: "Abs_Roman_Twist_10_hints",
    common_mistakes: "Abs_Roman_Twist_10_common_mistakes",
    breathing: "Abs_Roman_Twist_10_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Corkscrew Decline Push Ups",
    is_free: false,
    primary_muscle: "Upper Chest",
    rep_range: "8-20",
    secondary_muscle: "Middle Chest, Lower Chest, Shoulders, Triceps, Core, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Corkscrew_Decline_Push_Ups_17.jpg?alt=media&token=2beaf85a-5120-4e8d-877d-c42998527a9f",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "Chair",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Corkscrew_Decline_Push_Ups_17.mp4?alt=media&token=e7bc67d9-5b42-4a17-8743-4a4e1c59fe46",
    video_id: "CnB_Corkscrew_Decline_Push_Ups_17",
    video_length: 0.07,
    video_number: "143",
    size: 495068,
    hints: "CnB_Corkscrew_Decline_Push_Ups_17_hints",
    common_mistakes: "CnB_Corkscrew_Decline_Push_Ups_17_common_mistakes",
    breathing: "CnB_Corkscrew_Decline_Push_Ups_17_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Levered Plank",
    is_free: false,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Abs",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FAbs_Levered_Plank_11.jpg?alt=media&token=ecac83ce-639c-4b93-b68c-d663586a33ea",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FAbs_Levered_Plank_11.mp4?alt=media&token=8b5dfa8d-947b-4686-ac0d-c78fa6cef4e3",
    video_id: "Abs_Levered_Plank_11",
    video_length: 0.05,
    video_number: "34",
    size: 397664,
    hints: "Abs_Levered_Plank_11_hints",
    common_mistakes: "Abs_Levered_Plank_11_common_mistakes",
    breathing: "Abs_Levered_Plank_11_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Double Elbow Out Lawnmower",
    is_free: false,
    primary_muscle: "Traps, Rhomboids ",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rear Shoulders, Levator Scapulae",
    subcategory_name: "back",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_Double_Elbow_Out_Lawnmower_10.jpg?alt=media&token=aba38a38-c52d-4c95-ad93-2081c74e9327",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_Double_Elbow_Out_Lawnmower_10.mp4?alt=media&token=bfc23c89-7b31-494a-8c2c-d9cfd5a5af79",
    video_id: "LnB_Double_Elbow_Out_Lawnmower_10",
    video_length: 0.07,
    video_number: "84",
    size: 480002,
    hints: "LnB_Double_Elbow_Out_Lawnmower_10_hints",
    common_mistakes: "LnB_Double_Elbow_Out_Lawnmower_10_common_mistakes",
    breathing: "LnB_Double_Elbow_Out_Lawnmower_10_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Row Boats",
    is_free: false,
    primary_muscle: "Abs",
    rep_range: "Time Based",
    secondary_muscle: "Obliques, Hip Flexors",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FAbs_Row_Boats_12.jpg?alt=media&token=2cd0b264-9798-4fe1-a4b0-6e9f7f18db88",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FAbs_Row_Boats_12.mp4?alt=media&token=86e364ff-5381-430b-b0d8-2dc41d077fbf",
    video_id: "Abs_Row_Boats_12",
    video_length: 0.1,
    video_number: "44",
    size: 660679,
    hints: "Abs_Row_Boats_12_hints",
    common_mistakes: "Abs_Row_Boats_12_common_mistakes",
    breathing: "Abs_Row_Boats_12_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Sumo Deadlift",
    is_free: true,
    primary_muscle: "Quads, Glutes, Erector Spinae",
    rep_range: "15-20",
    secondary_muscle: "Hamstrings, Adductors, Traps, Lower Back, Latissimus Dorsi",
    subcategory_name: "legs",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_Sumo_Deadlift_11.jpg?alt=media&token=e1b3edc5-810f-48f9-ac3c-1a46b75f6b0d",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_Sumo_Deadlift_11.mp4?alt=media&token=e54b7098-0505-469c-b044-6dea14f1b8d3",
    video_id: "LnB_Sumo_Deadlift_11",
    video_length: 0.07,
    video_number: "104",
    size: 519976,
    hints: "LnB_Sumo_Deadlift_11_hints",
    common_mistakes: "LnB_Sumo_Deadlift_11_common_mistakes",
    breathing: "LnB_Sumo_Deadlift_11_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Rear Delt Raises",
    is_free: true,
    primary_muscle: "Rear Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Middle Shoulders, Traps, Rhomboids, Infraspinatus, Teres Minor",
    subcategory_name: "shoulders",
    tertiary_muscle: "Front Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FTnS_Rear_Delt_Raises_18.jpg?alt=media&token=fb00ea08-0c1e-4fd6-8dc5-873a3b6fb865",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FTnS_Rear_Delt_Raises_18.mp4?alt=media&token=4a8de1fd-8f61-42b2-89ac-3cfb21f098f1",
    video_id: "TnS_Rear_Delt_Raises_18",
    video_length: 0.1,
    video_number: "85",
    size: 718770,
    hints: "TnS_Rear_Delt_Raises_18_hints",
    common_mistakes: "TnS_Rear_Delt_Raises_18_common_mistakes",
    breathing: "TnS_Rear_Delt_Raises_18_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Alternating Lateral Raises",
    is_free: false,
    primary_muscle: "Rear Shoulders, Middle Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Traps, Rhomboids, Infraspinatus, Teres Minor",
    subcategory_name: "shoulders",
    tertiary_muscle: "Front Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FAlternating%20Lateral%20Raises.jpg?alt=media&token=eca34e7e-9fd9-4302-97f8-ac23154bbebb",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fnew-videos%2FAlternating%20Lateral%20Raises.mp4?alt=media&token=fea40c1e-466f-4d68-8f19-1c5b50527fce",
    video_id: "TnS_Alternating_Lateral_Raises_19",
    video_length: 0.1,
    video_number: "93",
    size: 661832,
    hints: "TnS_Alternating_Lateral_Raises_19_hints",
    common_mistakes: "TnS_Alternating_Lateral_Raises_19_common_mistakes",
    breathing: "TnS_Alternating_Lateral_Raises_19_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    is_other_side: 1,
    name: "One Leg Wall Squat",
    is_free: false,
    primary_muscle: "Quads, Glutes",
    rep_range: "Time Based",
    secondary_muscle: "Hamstrings, Adductors",
    subcategory_name: "legs",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_One_Leg_Wall_Squat_12.jpg?alt=media&token=5d587208-a40b-45f4-acd7-ca27c518fd4a",
    time: "45 seconds",
    time_to_play: 0.5,
    tools: "Wall",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_One_Leg_Wall_Squat_12.mp4?alt=media&token=940b55e9-a929-446a-958b-6545034b2ebc",
    video_id: "LnB_One_Leg_Wall_Squat_12",
    video_length: 0.05,
    video_number: "128",
    size: 378776,
    hints: "LnB_One_Leg_Wall_Squat_12_hints",
    common_mistakes: "LnB_One_Leg_Wall_Squat_12_common_mistakes",
    breathing: "LnB_One_Leg_Wall_Squat_12_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Alternating Lateral Lunges",
    is_free: false,
    primary_muscle: "Adductors, Quads, Glutes",
    rep_range: "10-16",
    secondary_muscle: "Hamstrings",
    subcategory_name: "legs",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_Alternating_Lateral_Lunges_13.jpg?alt=media&token=5a23d26b-8507-4e34-bbeb-bba13d2cdfcf",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_Alternating_Lateral_Lunges_13.mp4?alt=media&token=1c730dea-bed6-49c8-9125-8a8ad6de8802",
    video_id: "LnB_Alternating_Lateral_Lunges_13",
    video_length: 0.1,
    video_number: "115",
    size: 683717,
    hints: "LnB_Alternating_Lateral_Lunges_13_hints",
    common_mistakes: "LnB_Alternating_Lateral_Lunges_13_common_mistakes",
    breathing: "LnB_Alternating_Lateral_Lunges_13_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Crucifix Push Ups",
    is_free: false,
    primary_muscle: "Middle Chest",
    rep_range: "10-20",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Triceps, Core, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Crucifix_Push_Ups_18.jpg?alt=media&token=3d6c58c2-97cd-438e-b820-b4decdb07a8e",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Crucifix_Push_Ups_18.mp4?alt=media&token=03921caf-5e83-462d-b379-ab184c93d6ed",
    video_id: "CnB_Crucifix_Push_Ups_18",
    video_length: 0.08,
    video_number: "18",
    size: 596349,
    hints: "CnB_Crucifix_Push_Ups_18_hints",
    common_mistakes: "CnB_Crucifix_Push_Ups_18_common_mistakes",
    breathing: "CnB_Crucifix_Push_Ups_18_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    is_other_side: 0,
    name: "Single Dumbbell Skull Crushers",
    is_free: false,
    primary_muscle: "Triceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Forearm, Shoulders",
    subcategory_name: "triceps",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FTnS_Single_Dumbbell_Skull_Crushers_20.jpg?alt=media&token=a64fea47-6854-4ed0-bd4f-ccbd64e33753",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FTnS_Single_Dumbbell_Skull_Crushers_20.mp4?alt=media&token=0da7ddc5-880d-49fa-aa0d-7c39926461ca",
    video_id: "TnS_Single_Dumbbell_Skull_Crushers_20",
    video_length: 0.08,
    video_number: "18",
    size: 529270,
    hints: "TnS_Single_Dumbbell_Skull_Crushers_20_hints",
    common_mistakes: "TnS_Single_Dumbbell_Skull_Crushers_20_common_mistakes",
    breathing: "TnS_Single_Dumbbell_Skull_Crushers_20_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Circle Reverse Push Ups",
    is_free: true,
    primary_muscle: "Middle Chest",
    rep_range: "8-20",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Triceps, Core, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Circle_Reverse_Push_Ups_19.jpg?alt=media&token=37a787f2-326d-4474-84a5-8df35d9816d4",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Circle_Reverse_Push_Ups_19.mp4?alt=media&token=f7c0f03c-19c7-45bf-85f8-6377dc44313c",
    video_id: "CnB_Circle_Reverse_Push_Ups_19",
    video_length: 0.1,
    video_number: "20",
    size: 644948,
    hints: "CnB_Circle_Reverse_Push_Ups_19_hints",
    common_mistakes: "CnB_Circle_Reverse_Push_Ups_19_common_mistakes",
    breathing: "CnB_Circle_Reverse_Push_Ups_19_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Sky Touches",
    is_free: false,
    primary_muscle: "Upper Abs",
    rep_range: "Time Based",
    secondary_muscle: "Lower Abs, Obliques",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FAbs_Sky_Touches_13.jpg?alt=media&token=4b88c9b2-2d3d-43d9-87ba-0c65fa78cd36",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FAbs_Sky_Touches_13.mp4?alt=media&token=a5e94fdb-d724-48e2-9a27-1ef3d966cb6f",
    video_id: "Abs_Sky_Touches_13",
    video_length: 0.08,
    video_number: "43",
    size: 604532,
    hints: "Abs_Sky_Touches_13_hints",
    common_mistakes: "Abs_Sky_Touches_13_common_mistakes",
    breathing: "Abs_Sky_Touches_13_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Hammer Kickbacks",
    is_free: false,
    primary_muscle: "Triceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rear Shoulders",
    subcategory_name: "triceps",
    tertiary_muscle: "Latissimus Dorsi, Traps, Rhomboids",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FTnS_Hammer_Kickbacks_21.jpg?alt=media&token=4294a7cc-2a62-47d8-a224-7c9e16c7a85d",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FTnS_Hammer_Kickbacks_21.mp4?alt=media&token=afab1822-18c3-4a4f-a884-57bdaa2a526f",
    video_id: "TnS_Hammer_Kickbacks_21",
    video_length: 0.05,
    video_number: "48",
    size: 411057,
    hints: "TnS_Hammer_Kickbacks_21_hints",
    common_mistakes: "TnS_Hammer_Kickbacks_21_common_mistakes",
    breathing: "TnS_Hammer_Kickbacks_21_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    is_other_side: 1,
    name: "Single Behind Lateral Raise",
    is_free: false,
    primary_muscle: "Middle Shoulders, Rear Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Front Shoulders, Traps, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(R)%20Single%20Behind%20Lateral%20Raise.jpg?alt=media&token=bbd70895-947b-44ff-a87d-da8528b8a6df",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fnew-videos%2F(R)%20Single%20Behind%20Lateral%20Raise.mp4?alt=media&token=c190a493-4945-4e5c-a7bb-1c7dba9b3e98",
    video_id: "TnS_Single_Behind_Lateral_Raise_22",
    video_length: 0.08,
    video_number: "83",
    size: 625236,
    hints: "TnS_Single_Behind_Lateral_Raise_22_hints",
    common_mistakes: "TnS_Single_Behind_Lateral_Raise_22_common_mistakes",
    breathing: "TnS_Single_Behind_Lateral_Raise_22_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Goblet Squat",
    is_free: true,
    primary_muscle: "Quads, Glutes",
    rep_range: "15-20",
    secondary_muscle: "Hamstrings, Adductors",
    subcategory_name: "legs",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_Goblet_Squat_14.jpg?alt=media&token=e71301ef-320b-4c92-b2ca-64c395c99b5f",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_Goblet_Squat_14.mp4?alt=media&token=2eda83b3-4e84-45a1-a324-8d0294264b0a",
    video_id: "LnB_Goblet_Squat_14",
    video_length: 0.08,
    video_number: "98",
    size: 559749,
    hints: "LnB_Goblet_Squat_14_hints",
    common_mistakes: "LnB_Goblet_Squat_14_common_mistakes",
    breathing: "LnB_Goblet_Squat_14_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Weighted Circles Reverse",
    is_free: false,
    primary_muscle: "Shoulders",
    rep_range: "Time Based",
    secondary_muscle: "Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Traps, Chest, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FTnS_Weighted_Circles_Reverse_23.jpg?alt=media&token=50254d4a-7988-4433-9887-2ffeab3c4e77",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FTnS_Weighted_Circles_Reverse_23.mp4?alt=media&token=17eca5ff-7d59-4a5d-8041-ef89ddfa7aa5",
    video_id: "TnS_Weighted_Circles_Reverse_23",
    video_length: 0.07,
    video_number: "72",
    size: 506539,
    hints: "TnS_Weighted_Circles_Reverse_23_hints",
    common_mistakes: "TnS_Weighted_Circles_Reverse_23_common_mistakes",
    breathing: "TnS_Weighted_Circles_Reverse_23_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Front Shrugs",
    is_free: false,
    primary_muscle: "Traps, Rhomboids",
    rep_range: "Time Based",
    secondary_muscle: "Rear Shoulders, Levator Scapulae, Serratus Anterior",
    subcategory_name: "back",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_Front_Shrugs_15.jpg?alt=media&token=8c73056f-7683-4179-b0e0-e6ad2bc34d2e",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_Front_Shrugs_15.mp4?alt=media&token=caf0c124-3fad-4df2-8739-3d28c4baa0f6",
    video_id: "LnB_Front_Shrugs_15",
    video_length: 0.07,
    video_number: "67",
    size: 504352,
    hints: "LnB_Front_Shrugs_15_hints",
    common_mistakes: "LnB_Front_Shrugs_15_common_mistakes",
    breathing: "LnB_Front_Shrugs_15_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Side Sky Touches",
    is_free: true,
    primary_muscle: "Upper Abs, Obliques",
    rep_range: "Time Based",
    secondary_muscle: "Lower Abs",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FAbs_Side_Sky_Touches_14.jpg?alt=media&token=d9c223df-e804-4ddb-991b-5a518f6819d9",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FAbs_Side_Sky_Touches_14.mp4?alt=media&token=f323b4cd-6889-4c5e-9c05-645bbb538fbc",
    video_id: "Abs_Side_Sky_Touches_14",
    video_length: 0.05,
    video_number: "40",
    size: 397171,
    hints: "Abs_Side_Sky_Touches_14_hints",
    common_mistakes: "Abs_Side_Sky_Touches_14_common_mistakes",
    breathing: "Abs_Side_Sky_Touches_14_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Crouching Curls",
    is_free: false,
    primary_muscle: "Biceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Brachialis, Forearm, Lower Body",
    subcategory_name: "biceps",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Crouching_Curls_20.jpg?alt=media&token=221b4b82-26c1-4811-afb2-38f063be4616",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Crouching_Curls_20.mp4?alt=media&token=c2dd8c1c-b5a3-4838-8182-8701eebed7ea",
    video_id: "CnB_Crouching_Curls_20",
    video_length: 0.08,
    video_number: "53",
    size: 534051,
    hints: "CnB_Crouching_Curls_20_hints",
    common_mistakes: "CnB_Crouching_Curls_20_common_mistakes",
    breathing: "CnB_Crouching_Curls_20_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    is_other_side: 0,
    name: "Step Up",
    is_free: false,
    primary_muscle: "Quads, Hamstrings, Glutes",
    rep_range: "10-15",
    secondary_muscle: "Adductors",
    subcategory_name: "legs",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(L)%20Step%20Up.jpg?alt=media&token=b2f37f98-d1a7-4259-a722-27c1e8151859",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fnew-videos%2F(L)%20Step%20Up.mp4?alt=media&token=b40e9fcb-82a9-40b9-892f-148352b1ee2e",
    video_id: "LnB_Step_Up_16",
    video_length: 0.1,
    video_number: "33",
    size: 639750,
    hints: "LnB_Step_Up_16_hints",
    common_mistakes: "LnB_Step_Up_16_common_mistakes",
    breathing: "LnB_Step_Up_16_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    is_other_side: 0,
    name: "Concentrated Curls",
    is_free: false,
    primary_muscle: "Biceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Brachialis, Forearm",
    subcategory_name: "biceps",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(L)%20Concentrated%20Curls.jpg?alt=media&token=ae71e310-1e77-45c8-a7d1-0b401a6b9b78",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fnew-videos%2F(L)%20Concentrated%20Curls.mp4?alt=media&token=85699ef5-1f4e-4a49-b515-586de79e4059",
    video_id: "CnB_Concentrated_Curls_21",
    video_length: 0.07,
    video_number: "22",
    size: 434278,
    hints: "CnB_Concentrated_Curls_21_hints",
    common_mistakes: "CnB_Concentrated_Curls_21_common_mistakes",
    breathing: "CnB_Concentrated_Curls_21_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Corkscrew Curls",
    is_free: true,
    primary_muscle: "Biceps (Long Head)",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Biceps (Short Head), Brachialis, Forearm",
    subcategory_name: "biceps",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Corkscrew_Curls_22.jpg?alt=media&token=a33cd151-4e70-47c4-966b-f0cb128ba571",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Corkscrew_Curls_22.mp4?alt=media&token=7bf33448-7822-4a35-8239-a9508249ce51",
    video_id: "CnB_Corkscrew_Curls_22",
    video_length: 0.08,
    video_number: "62",
    size: 526980,
    hints: "CnB_Corkscrew_Curls_22_hints",
    common_mistakes: "CnB_Corkscrew_Curls_22_common_mistakes",
    breathing: "CnB_Corkscrew_Curls_22_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Hip Raises",
    is_free: false,
    primary_muscle: "Lower Abs",
    rep_range: "Time Based",
    secondary_muscle: "Upper Abs, Obliques, Hip Flexors",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FAbs_Hip_Raises_15.jpg?alt=media&token=c0f40e06-12b3-4462-a599-4e159906718c",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FAbs_Hip_Raises_15.mp4?alt=media&token=c8dd9528-5695-4523-b1f4-050befe1d33a",
    video_id: "Abs_Hip_Raises_15",
    video_length: 0.08,
    video_number: "24",
    size: 642195,
    hints: "Abs_Hip_Raises_15_hints",
    common_mistakes: "Abs_Hip_Raises_15_common_mistakes",
    breathing: "Abs_Hip_Raises_15_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Front Raise",
    is_free: true,
    primary_muscle: "Front Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Upper Chest, Middle Shoulders, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Rear Shoulders, Traps, Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FTnS_Front_Raise_24.jpg?alt=media&token=be2dab99-df8d-4265-9b3d-79dcaae3831e",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FTnS_Front_Raise_24.mp4?alt=media&token=5acf68df-148d-4cea-91fd-9f44899960e0",
    video_id: "TnS_Front_Raise_24",
    video_length: 0.05,
    video_number: "57",
    size: 376422,
    hints: "TnS_Front_Raise_24_hints",
    common_mistakes: "TnS_Front_Raise_24_common_mistakes",
    breathing: "TnS_Front_Raise_24_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Triangle Push Ups",
    is_free: false,
    primary_muscle: "Middle Chest",
    rep_range: "8-20",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Triceps, Core, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Triangle_Push_Ups_23.jpg?alt=media&token=ca4aa277-78c3-4734-b932-4e162b2dc916",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Triangle_Push_Ups_23.mp4?alt=media&token=38bfc8b7-c0ea-4e22-b161-d3c7a73cf808",
    video_id: "CnB_Triangle_Push_Ups_23",
    video_length: 0.07,
    video_number: "5",
    size: 505552,
    hints: "CnB_Triangle_Push_Ups_23_hints",
    common_mistakes: "CnB_Triangle_Push_Ups_23_common_mistakes",
    breathing: "CnB_Triangle_Push_Ups_23_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Close Grip Row",
    is_free: false,
    primary_muscle: "Traps, Rhomboids, Erector Spinae",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rear Shoulders, Teres Major, Teres Minor, Latissimus Dorsi,Infraspinatus,Biceps",
    subcategory_name: "back",
    tertiary_muscle: "Middle Shoulders, Front Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_Close_Grip_Row_17.jpg?alt=media&token=8dac78f1-9851-403b-a418-280f42206930",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_Close_Grip_Row_17.mp4?alt=media&token=82e86851-95b8-45d3-b4f0-00672dd406c5",
    video_id: "LnB_Close_Grip_Row_17",
    video_length: 0.07,
    video_number: "87",
    size: 464874,
    hints: "LnB_Close_Grip_Row_17_hints",
    common_mistakes: "LnB_Close_Grip_Row_17_common_mistakes",
    breathing: "LnB_Close_Grip_Row_17_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Writer Decline Push Ups",
    is_free: false,
    primary_muscle: "Upper Chest",
    rep_range: "10-20",
    secondary_muscle: "Middle Chest, Lower Chest, Shoulders, Triceps, Core, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Writer_Decline_Push_Ups_24.jpg?alt=media&token=6995ba7a-219b-4202-9981-289a91581953",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "Chair",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Writer_Decline_Push_Ups_24.mp4?alt=media&token=1e9a9552-4c33-486b-a935-b5a1584c6520",
    video_id: "CnB_Writer_Decline_Push_Ups_24",
    video_length: 0.07,
    video_number: "132",
    size: 460435,
    hints: "CnB_Writer_Decline_Push_Ups_24_hints",
    common_mistakes: "CnB_Writer_Decline_Push_Ups_24_common_mistakes",
    breathing: "CnB_Writer_Decline_Push_Ups_24_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Double Dumbbell Extensions",
    is_free: false,
    primary_muscle: "Triceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Forearm, Shoulders",
    subcategory_name: "triceps",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FTnS_Double_Dumbbell_Extensions_25.jpg?alt=media&token=aad61980-8567-4d54-a167-32facf9b89e1",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FTnS_Double_Dumbbell_Extensions_25.mp4?alt=media&token=1b211720-9904-4164-9ed1-10c8793f56c5",
    video_id: "TnS_Double_Dumbbell_Extensions_25",
    video_length: 0.1,
    video_number: "51",
    size: 704786,
    hints: "TnS_Double_Dumbbell_Extensions_25_hints",
    common_mistakes: "TnS_Double_Dumbbell_Extensions_25_common_mistakes",
    breathing: "TnS_Double_Dumbbell_Extensions_25_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Flys",
    is_free: false,
    primary_muscle: "Middle Chest",
    rep_range: "15-25",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Triceps, Core, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Biceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Middle_Chest_25.jpg?alt=media&token=b7d37a62-b9c3-478b-be7c-e65054378892",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Middle_Chest_25.mp4?alt=media&token=a03c8582-953d-49f7-b29b-4137dd1a9083",
    video_id: "CnB_Middle_Chest_25",
    video_length: 0.07,
    video_number: "36",
    size: 497010,
    hints: "CnB_Middle_Chest_25_hints",
    common_mistakes: "CnB_Middle_Chest_25_common_mistakes",
    breathing: "CnB_Middle_Chest_25_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Squat Walk",
    is_free: false,
    primary_muscle: "Quads, Glutes",
    rep_range: "Time Based",
    secondary_muscle: "Hamstrings, Adductors",
    subcategory_name: "legs",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_Squat_Walk_18.jpg?alt=media&token=00f9b3a0-2693-4e57-b2e7-cce424f08b15",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_Squat_Walk_18.mp4?alt=media&token=af9e0fa0-dd71-4f33-890a-3e18ff2c357f",
    video_id: "LnB_Squat_Walk_18",
    video_length: 0.13,
    video_number: "117",
    size: 839338,
    hints: "LnB_Squat_Walk_18_hints",
    common_mistakes: "LnB_Squat_Walk_18_common_mistakes",
    breathing: "LnB_Squat_Walk_18_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Close Grip Press",
    is_free: true,
    primary_muscle: "Middle Chest, Triceps",
    rep_range: "20-30",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Serratus Anterior",
    subcategory_name: "triceps",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FTnS_Close_Grip_Press_26.jpg?alt=media&token=1ff80b83-2d20-4f04-a453-ef8a64be5c65",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FTnS_Close_Grip_Press_26.mp4?alt=media&token=cdc31424-c16a-4d05-82f9-a758550c4c19",
    video_id: "TnS_Close_Grip_Press_26",
    video_length: 0.08,
    video_number: "29",
    size: 540031,
    hints: "TnS_Close_Grip_Press_26_hints",
    common_mistakes: "TnS_Close_Grip_Press_26_common_mistakes",
    breathing: "TnS_Close_Grip_Press_26_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Out Push Ups",
    is_free: false,
    primary_muscle: "Middle Chest",
    rep_range: "10-20",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Triceps, Core, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Out_Push_Ups_26.jpg?alt=media&token=9c4bb3d7-196c-41e2-9a41-f22819cbbd2c",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Out_Push_Ups_26.mp4?alt=media&token=4bb36499-6fe9-4955-8f5d-800f2ae52e5b",
    video_id: "CnB_Out_Push_Ups_26",
    video_length: 0.08,
    video_number: "21",
    size: 558914,
    hints: "CnB_Out_Push_Ups_26_hints",
    common_mistakes: "CnB_Out_Push_Ups_26_common_mistakes",
    breathing: "CnB_Out_Push_Ups_26_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Archer Push Ups",
    is_free: false,
    primary_muscle: "Middle Chest",
    rep_range: "8-20",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Triceps, Core, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Archer_Push_Ups_27.jpg?alt=media&token=e7704c91-ed85-44d3-8d4a-212970c1b959",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Archer_Push_Ups_27.mp4?alt=media&token=4de781ae-f536-4fdc-a407-bf0d19c6ec2e",
    video_id: "CnB_Archer_Push_Ups_27",
    video_length: 0.13,
    video_number: "11",
    size: 836378,
    hints: "CnB_Archer_Push_Ups_27_hints",
    common_mistakes: "CnB_Archer_Push_Ups_27_common_mistakes",
    breathing: "CnB_Archer_Push_Ups_27_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Alternating Rows",
    is_free: true,
    primary_muscle: "Latissimus Dorsi, Rhomboids, Traps, Erector Spinae",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rear Shoulders, Teres Major, Teres Minor, Infraspinatus, Biceps",
    subcategory_name: "back",
    tertiary_muscle: "Lower Back, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_Rows_19.jpg?alt=media&token=38c8d162-3f78-4200-9e09-6844c7c0843c",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_Rows_19.mp4?alt=media&token=f71c4c95-786c-4b90-a4a7-a20f7d072231",
    video_id: "LnB_Rows_19",
    video_length: 0.07,
    video_number: "60",
    size: 475965,
    hints: "LnB_Rows_19_hints",
    common_mistakes: "LnB_Rows_19_common_mistakes",
    breathing: "LnB_Rows_19_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    is_three_video: 1,
    group_name: "Calves",
    name: "Out Calf Raises",
    is_free: false,
    primary_muscle: "Outer Calves",
    rep_range: "15-20",
    secondary_muscle: "Inner Calves",
    subcategory_name: "legs",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_Out_Calf_Raises_20.jpg?alt=media&token=0725bced-2492-4ea9-a49d-42555f3384d8",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_Out_Calf_Raises_20.mp4?alt=media&token=dfc2b3cd-5aa0-4c3f-93a9-2ab0fbceea0e",
    video_id: "LnB_Out_Calf_Raises_20",
    video_length: 0.1,
    video_number: "122",
    size: 663747,
    hints: "LnB_Out_Calf_Raises_20_hints",
    common_mistakes: "LnB_Out_Calf_Raises_20_common_mistakes",
    breathing: "LnB_Out_Calf_Raises_20_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Backstabbers",
    is_free: true,
    primary_muscle: "Triceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Forearm, Shoulders",
    subcategory_name: "triceps",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FTnS_Backstabbers_28.jpg?alt=media&token=824063a3-4605-4e76-ac13-2114a2e4a4aa",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FTnS_Backstabbers_28.mp4?alt=media&token=bd891b73-11af-4107-9c36-9adb56ea8232",
    video_id: "TnS_Backstabbers_28",
    video_length: 0.08,
    video_number: "34",
    size: 589552,
    hints: "TnS_Backstabbers_28_hints",
    common_mistakes: "TnS_Backstabbers_28_common_mistakes",
    breathing: "TnS_Backstabbers_28_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    is_other_side: 1,
    name: "Single Hammer Kickbacks",
    is_free: false,
    primary_muscle: "Triceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rear Shoulders",
    subcategory_name: "triceps",
    tertiary_muscle: "Latissimus Dorsi, Traps, Rhomboids",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FTnS_Single_Hammer_Kickbacks_29.jpg?alt=media&token=66e1b01e-3c89-46a0-8995-6c0fdee86a3a",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FTnS_Single_Hammer_Kickbacks_29.mp4?alt=media&token=6f7c944a-41d2-4559-9f4e-21e04be05508",
    video_id: "TnS_Single_Hammer_Kickbacks_29",
    video_length: 0.1,
    video_number: "27",
    size: 667462,
    hints: "TnS_Single_Hammer_Kickbacks_29_hints",
    common_mistakes: "TnS_Single_Hammer_Kickbacks_29_common_mistakes",
    breathing: "TnS_Single_Hammer_Kickbacks_29_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Weighted Angel Of Death",
    is_free: false,
    primary_muscle: "Erector Spinae, Lower Back",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle:
      "Rhomboids, Teres Major, Teres Minor, Infraspinatus, Traps, Shoulders, Latissimus Dorsi, Hamstrings, Gluteus Maximus, Adductors",
    subcategory_name: "back",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_Weighted_Angel_Of_Death_21.jpg?alt=media&token=e4265d68-832b-45f0-bf14-38fb5581ef59",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_Weighted_Angel_Of_Death_21.mp4?alt=media&token=10d45ef1-0181-4769-b470-34798b0f8370",
    video_id: "LnB_Weighted_Angel_Of_Death_21",
    video_length: 0.07,
    video_number: "52",
    size: 486509,
    hints: "LnB_Weighted_Angel_Of_Death_21_hints",
    common_mistakes: "LnB_Weighted_Angel_Of_Death_21_common_mistakes",
    breathing: "LnB_Weighted_Angel_Of_Death_21_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "T Push Ups",
    is_free: false,
    primary_muscle: "Middle Chest",
    rep_range: "6-10",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Triceps, Core, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_T_Push_Ups_28.jpg?alt=media&token=806dc033-ac40-4af0-8ac8-71aaaf03eef6",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_T_Push_Ups_28.mp4?alt=media&token=57d2c297-d17c-4016-aa46-3f556fb1a3fb",
    video_id: "CnB_T_Push_Ups_28",
    video_length: 0.15,
    video_number: "9",
    size: 1006016,
    hints: "CnB_T_Push_Ups_28_hints",
    common_mistakes: "CnB_T_Push_Ups_28_common_mistakes",
    breathing: "CnB_T_Push_Ups_28_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    is_other_side: 0,
    name: "Single Behind Lateral Raise",
    is_free: false,
    primary_muscle: "Middle Shoulders, Rear Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Front Shoulders, Traps, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(L)%20Single%20Behind%20Lateral%20Raise.jpg?alt=media&token=6fd3c15d-ab6d-4e3b-ac42-ec80aa283f56",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fnew-videos%2F(L)%20Single%20Behind%20Lateral%20Raise.mp4?alt=media&token=15b06372-46da-413c-bea1-c269a66332f2",
    video_id: "TnS_Single_Behind_Lateral_Raise_30",
    video_length: 0.08,
    video_number: "82",
    size: 601422,
    hints: "TnS_Single_Behind_Lateral_Raise_30_hints",
    common_mistakes: "TnS_Single_Behind_Lateral_Raise_30_common_mistakes",
    breathing: "TnS_Single_Behind_Lateral_Raise_30_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    is_other_side: 1,
    name: "Single Dumbbell Skull Crushers",
    is_free: false,
    primary_muscle: "Triceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Forearm, Shoulders",
    subcategory_name: "triceps",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FTnS_Single_Dumbbell_Skull_Crushers_31.jpg?alt=media&token=a1a7e33c-bea7-4e49-916e-8498e058ff21",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FTnS_Single_Dumbbell_Skull_Crushers_31.mp4?alt=media&token=852388dd-ce5d-472f-9dee-0bb644df416c",
    video_id: "TnS_Single_Dumbbell_Skull_Crushers_31",
    video_length: 0.08,
    video_number: "17",
    size: 551357,
    hints: "TnS_Single_Dumbbell_Skull_Crushers_31_hints",
    common_mistakes: "TnS_Single_Dumbbell_Skull_Crushers_31_common_mistakes",
    breathing: "TnS_Single_Dumbbell_Skull_Crushers_31_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Curl Up Hammer Down",
    is_free: true,
    primary_muscle: "Biceps, Brachialis",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Forearm",
    subcategory_name: "biceps",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Curl_Up_Hammer_Down_29.jpg?alt=media&token=417f696f-70f1-4650-8ac1-b670578fe845",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Curl_Up_Hammer_Down_29.mp4?alt=media&token=2e649eb4-c25c-4c55-b68e-4b327d5e5057",
    video_id: "CnB_Curl_Up_Hammer_Down_29",
    video_length: 0.05,
    video_number: "39",
    size: 395125,
    hints: "CnB_Curl_Up_Hammer_Down_29_hints",
    common_mistakes: "CnB_Curl_Up_Hammer_Down_29_common_mistakes",
    breathing: "CnB_Curl_Up_Hammer_Down_29_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Switch Lunges",
    is_free: false,
    primary_muscle: "Quads, Glutes, Hamstrings",
    rep_range: "10-16",
    secondary_muscle: "Adductors",
    subcategory_name: "legs",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_Switch_Lunges_22.jpg?alt=media&token=75a01700-27a4-4b5b-ba0a-488eb91e7d3d",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_Switch_Lunges_22.mp4?alt=media&token=1a5e1929-642b-4c2e-9cff-027603c957e2",
    video_id: "LnB_Switch_Lunges_22",
    video_length: 0.07,
    video_number: "111",
    size: 513687,
    hints: "LnB_Switch_Lunges_22_hints",
    common_mistakes: "LnB_Switch_Lunges_22_common_mistakes",
    breathing: "LnB_Switch_Lunges_22_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Leg Lift Toe Touch",
    is_free: false,
    primary_muscle: "Abs",
    rep_range: "Time Based",
    secondary_muscle: "Obliques, Hip Flexors",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FAbs_Leg_Lift_Toe_Touch_16.jpg?alt=media&token=7de7ab3a-7829-4eab-836a-9d7aa63286c4",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FAbs_Leg_Lift_Toe_Touch_16.mp4?alt=media&token=64d25479-9295-452a-b587-d1ce8d8852f5",
    video_id: "Abs_Leg_Lift_Toe_Touch_16",
    video_length: 0.08,
    video_number: "10",
    size: 598141,
    hints: "Abs_Leg_Lift_Toe_Touch_16_hints",
    common_mistakes: "Abs_Leg_Lift_Toe_Touch_16_common_mistakes",
    breathing: "Abs_Leg_Lift_Toe_Touch_16_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    is_other_side: 1,
    name: "Concentrated Curls",
    is_free: false,
    primary_muscle: "Biceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Brachialis, Forearm",
    subcategory_name: "biceps",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(R)%20Concentration%20Curls.jpg?alt=media&token=d7cb863f-9e4c-4606-ab88-20c07670944b",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fnew-videos%2F(R)%20Concentration%20Curls.mp4?alt=media&token=bbeb7d62-5adf-46a9-90ae-02656c511096",
    video_id: "CnB_Concentrated_Curls_30",
    video_length: 0.08,
    video_number: "23",
    size: 532083,
    hints: "CnB_Concentrated_Curls_30_hints",
    common_mistakes: "CnB_Concentrated_Curls_30_common_mistakes",
    breathing: "CnB_Concentrated_Curls_30_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Diamond Push Ups",
    is_free: true,
    primary_muscle: "Middle Chest",
    rep_range: "10-20",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Triceps, Core, Serratus Anterior",
    subcategory_name: "triceps",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FTnS_Diamond_Push_Ups_32.jpg?alt=media&token=b305e749-90f4-4dba-92c9-725a342cd7e0",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FTnS_Diamond_Push_Ups_32.mp4?alt=media&token=96a90513-92d2-42e2-ab81-aff826a10de9",
    video_id: "TnS_Diamond_Push_Ups_32",
    video_length: 0.05,
    video_number: "8",
    size: 415852,
    hints: "TnS_Diamond_Push_Ups_32_hints",
    common_mistakes: "TnS_Diamond_Push_Ups_32_common_mistakes",
    breathing: "TnS_Diamond_Push_Ups_32_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Fast Push Ups",
    is_free: false,
    primary_muscle: "Middle Chest",
    rep_range: "10-20",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Triceps, Core, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Fast_Push_Ups_31.jpg?alt=media&token=ace35085-15bc-40ed-bc99-6757853e52cc",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Fast_Push_Ups_31.mp4?alt=media&token=32974aab-c777-45d4-8236-28d5cfc9dc33",
    video_id: "CnB_Fast_Push_Ups_31",
    video_length: 0.07,
    video_number: "22",
    size: 474578,
    hints: "CnB_Fast_Push_Ups_31_hints",
    common_mistakes: "CnB_Fast_Push_Ups_31_common_mistakes",
    breathing: "CnB_Fast_Push_Ups_31_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Squat Back Lunge",
    is_free: false,
    primary_muscle: "Quads, Glutes, Hamstrings",
    rep_range: "3-4",
    secondary_muscle: "Adductors",
    subcategory_name: "legs",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_Squat_Back_Lunge_23.jpg?alt=media&token=b1fa70da-e20a-4944-9613-9e28b50a15e8",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_Squat_Back_Lunge_23.mp4?alt=media&token=55d42df5-e781-4e1b-bfe4-86cec5f6d7ca",
    video_id: "LnB_Squat_Back_Lunge_23",
    video_length: 0.12,
    video_number: "112",
    size: 727872,
    hints: "LnB_Squat_Back_Lunge_23_hints",
    common_mistakes: "LnB_Squat_Back_Lunge_23_common_mistakes",
    breathing: "LnB_Squat_Back_Lunge_23_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    is_other_side: 0,
    name: "One Arm Concentration Curls",
    is_free: false,
    primary_muscle: "Biceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Brachialis, Forearm, Lower Body",
    subcategory_name: "biceps",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_One_Arm_Concentration_Curls_32.jpg?alt=media&token=a4291550-d9d8-44cc-b841-3ad17c7a1970",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_One_Arm_Concentration_Curls_32.mp4?alt=media&token=ca34edc6-0efc-4315-bfbd-3aed56cdad09",
    video_id: "CnB_One_Arm_Concentration_Curls_32",
    video_length: 0.07,
    video_number: "56",
    size: 496194,
    hints: "CnB_One_Arm_Concentration_Curls_32_hints",
    common_mistakes: "CnB_One_Arm_Concentration_Curls_32_common_mistakes",
    breathing: "CnB_One_Arm_Concentration_Curls_32_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Leg Crunches",
    is_free: false,
    primary_muscle: "Abs",
    rep_range: "Time Based",
    secondary_muscle: "Obliques, Hip Flexors",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FAbs_Leg_Crunches_17.jpg?alt=media&token=5b72eaf5-6225-4020-aec7-935a5e101b8e",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FAbs_Leg_Crunches_17.mp4?alt=media&token=4a51b664-de9a-43c5-ad7c-f4ba70d12b2e",
    video_id: "Abs_Leg_Crunches_17",
    video_length: 0.07,
    video_number: "35",
    size: 477213,
    hints: "Abs_Leg_Crunches_17_hints",
    common_mistakes: "Abs_Leg_Crunches_17_common_mistakes",
    breathing: "Abs_Leg_Crunches_17_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Jump Squat",
    is_free: true,
    primary_muscle: "Quads, Hamstrings, Glutes",
    rep_range: "15-20",
    secondary_muscle: "Adductors, Erector Spinae",
    subcategory_name: "legs",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_Jump_Squat_24.jpg?alt=media&token=3abe24f0-dff0-486e-b5b4-72a9868ec94e",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_Jump_Squat_24.mp4?alt=media&token=eb1baf02-09e8-4b59-9697-4c00462a8cee",
    video_id: "LnB_Jump_Squat_24",
    video_length: 0.05,
    video_number: "102",
    size: 417879,
    hints: "LnB_Jump_Squat_24_hints",
    common_mistakes: "LnB_Jump_Squat_24_common_mistakes",
    breathing: "LnB_Jump_Squat_24_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Mid Crunchers",
    is_free: true,
    primary_muscle: "Abs",
    rep_range: "Time Based",
    secondary_muscle: "Obliques, Hip Flexors",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FAbs_Mid_Crunchers_18.jpg?alt=media&token=b7cfb759-08c4-4fa2-a6dd-06bf2b21567b",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FAbs_Mid_Crunchers_18.mp4?alt=media&token=1882b75c-6e3b-4229-bc7e-437866ab7038",
    video_id: "Abs_Mid_Crunchers_18",
    video_length: 0.07,
    video_number: "38",
    size: 537017,
    hints: "Abs_Mid_Crunchers_18_hints",
    common_mistakes: "Abs_Mid_Crunchers_18_common_mistakes",
    breathing: "Abs_Mid_Crunchers_18_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    is_other_side: 0,
    name: "Elbow Out Lawnmowers",
    is_free: false,
    primary_muscle: "Latissimus Dorsi, Rhomboids, Traps, Erector Spinae",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rear Shoulders, Teres Major, Teres Minor, Infraspinatus, Biceps",
    subcategory_name: "back",
    tertiary_muscle: "Lower Back",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_Elbow_Out_Lawnmowers_25.jpg?alt=media&token=07fc42e3-db57-4a74-87f3-d62d355da2fe",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_Elbow_Out_Lawnmowers_25.mp4?alt=media&token=1cdcbc20-5258-469a-bdb0-9db2fe34a4c3",
    video_id: "LnB_Elbow_Out_Lawnmowers_25",
    video_length: 0.1,
    video_number: "54",
    size: 629998,
    hints: "LnB_Elbow_Out_Lawnmowers_25_hints",
    common_mistakes: "LnB_Elbow_Out_Lawnmowers_25_common_mistakes",
    breathing: "LnB_Elbow_Out_Lawnmowers_25_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Hollow Body Alternating Knee Raise",
    is_free: false,
    primary_muscle: "Abs",
    rep_range: "Time Based",
    secondary_muscle: "Obliques, Hip Flexors",
    tertiary_muscle: "Quads",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FAbs_Hollow_Body_Alternate_Knee_Raise_19.jpg?alt=media&token=c6e11dbc-42be-4ca2-a25b-51d26f2d15b2",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FAbs_Hollow_Body_Alternate_Knee_Raise_19.mp4?alt=media&token=774f588c-74e1-4432-ad3e-8b858499386b",
    video_id: "Abs_Hollow_Body_Alternate_Knee_Raise_19",
    video_length: 0.1,
    video_number: "22",
    size: 748593,
    hints: "Abs_Hollow_Body_Alternate_Knee_Raise_19_hints",
    common_mistakes: "Abs_Hollow_Body_Alternate_Knee_Raise_19_common_mistakes",
    breathing: "Abs_Hollow_Body_Alternate_Knee_Raise_19_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    is_other_side: 1,
    name: "Single Arm Pull Overs",
    is_free: false,
    primary_muscle: "Latissimus Dorsi",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rhomboids, Rear Shoulders, Teres Major, Teres Minor, Infraspinatus, Chest, Traps",
    subcategory_name: "back",
    tertiary_muscle: "Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_Single_Arm_Pull_Overs_26.jpg?alt=media&token=a881cc75-bd3e-4a9b-8701-0d25e1b0fa71",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_Single_Arm_Pull_Overs_26.mp4?alt=media&token=4bc5bfc4-8680-4b8a-be67-16e9d715a51a",
    video_id: "LnB_Single_Arm_Pull_Overs_26",
    video_length: 0.08,
    video_number: "42",
    size: 567536,
    hints: "LnB_Single_Arm_Pull_Overs_26_hints",
    common_mistakes: "LnB_Single_Arm_Pull_Overs_26_common_mistakes",
    breathing: "LnB_Single_Arm_Pull_Overs_26_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    is_three_video: 3,
    group_name: "Calves",
    name: "In Calf Raises",
    is_free: false,
    primary_muscle: "Inner Calves",
    rep_range: "15-20",
    secondary_muscle: "Outer Calves",
    subcategory_name: "legs",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_In_Calf_Raises_27.jpg?alt=media&token=a0751b37-cdd1-47e3-9ba3-86bf2df8d7d5",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_In_Calf_Raises_27.mp4?alt=media&token=496da628-de9b-44e8-9ac1-ef60eeef1f62",
    video_id: "LnB_In_Calf_Raises_27",
    video_length: 0.08,
    video_number: "120",
    size: 605555,
    hints: "LnB_In_Calf_Raises_27_hints",
    common_mistakes: "LnB_In_Calf_Raises_27_common_mistakes",
    breathing: "LnB_In_Calf_Raises_27_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Single Dumbbell Extensions",
    is_free: true,
    primary_muscle: "Triceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Forearm, Shoulders",
    subcategory_name: "triceps",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FTnS_Single_Dumbbell_Extensions_34.jpg?alt=media&token=0746dade-dc71-4144-acb3-2727f3cfea27",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FTnS_Single_Dumbbell_Extensions_34.mp4?alt=media&token=e502b00e-a99d-421b-9231-c2f50b6959a4",
    video_id: "TnS_Single_Dumbbell_Extensions_34",
    video_length: 0.08,
    video_number: "50",
    size: 529616,
    hints: "TnS_Single_Dumbbell_Extensions_34_hints",
    common_mistakes: "TnS_Single_Dumbbell_Extensions_34_common_mistakes",
    breathing: "TnS_Single_Dumbbell_Extensions_34_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    is_other_side: 0,
    name: "Seated Concentrated Curls",
    is_free: false,
    primary_muscle: "Biceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Brachialis, Forearm",
    subcategory_name: "biceps",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(L)%20Seated%20Concentrated%20Curls.jpg?alt=media&token=d57455ba-5d40-4757-99a9-84863a8e61e8",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fnew-videos%2F(L)%20Seated%20Concentrated%20Curls.mp4?alt=media&token=42430024-2599-4300-b588-500fac91c4f0",
    video_id: "CnB_Seated_Concentrated_Curls_33",
    video_length: 0.08,
    video_number: "15",
    size: 554674,
    hints: "CnB_Seated_Concentrated_Curls_33_hints",
    common_mistakes: "CnB_Seated_Concentrated_Curls_33_common_mistakes",
    breathing: "CnB_Seated_Concentrated_Curls_33_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Bicycles",
    is_free: true,
    primary_muscle: "Abs",
    rep_range: "Time Based",
    secondary_muscle: "Obliques, Hip Flexors",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FAbs_Bicycles_20.jpg?alt=media&token=2792fa48-6d54-47c9-8688-107e325a1f05",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FAbs_Bicycles_20.mp4?alt=media&token=40803986-4aa0-4d71-99d0-5167aa93f50f",
    video_id: "Abs_Bicycles_20",
    video_length: 0.07,
    video_number: "6",
    size: 521446,
    hints: "Abs_Bicycles_20_hints",
    common_mistakes: "Abs_Bicycles_20_common_mistakes",
    breathing: "Abs_Bicycles_20_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Hammer Rear Delt Raises",
    is_free: true,
    primary_muscle: "Rear Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Middle Shoulders, Traps, Rhomboids, Infraspinatus, Teres Minor",
    subcategory_name: "shoulders",
    tertiary_muscle: "Front Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FTnS_Hammer_Rear_Delt_Raises_35.jpg?alt=media&token=c042ccd8-1a7f-4de1-a464-f878d6b14513",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FTnS_Hammer_Rear_Delt_Raises_35.mp4?alt=media&token=55f58b52-02b9-4562-bd59-16b0fe3ad329",
    video_id: "TnS_Hammer_Rear_Delt_Raises_35",
    video_length: 0.12,
    video_number: "86",
    size: 766428,
    hints: "TnS_Hammer_Rear_Delt_Raises_35_hints",
    common_mistakes: "TnS_Hammer_Rear_Delt_Raises_35_common_mistakes",
    breathing: "TnS_Hammer_Rear_Delt_Raises_35_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Rotating Lateral Raise",
    is_free: false,
    primary_muscle: "Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Traps, Chest, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FRotating%20Lateral%20Raise.jpg?alt=media&token=fd9e747e-2404-44f6-b355-93611bc4b139",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fnew-videos%2FRotating%20Lateral%20Raise.mp4?alt=media&token=348ae77e-a714-4356-a23b-20f662761bd7",
    video_id: "TnS_Rotating_Lateral_Raise_36",
    video_length: 0.07,
    video_number: "99",
    size: 479581,
    hints: "TnS_Rotating_Lateral_Raise_36_hints",
    common_mistakes: "TnS_Rotating_Lateral_Raise_36_common_mistakes",
    breathing: "TnS_Rotating_Lateral_Raise_36_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    is_other_side: 1,
    name: "Split Squats",
    is_free: false,
    primary_muscle: "Quads, Glutes",
    rep_range: "10-15",
    secondary_muscle: "Hamstrings Adductors, Erector Spinae",
    subcategory_name: "legs",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_Split_Squats_28.jpg?alt=media&token=5b74090f-9d7a-4fb1-abda-2a843c6e17cb",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_Split_Squats_28.mp4?alt=media&token=d92de447-1bb9-439f-8cd5-9a3a47b6fdf5",
    video_id: "LnB_Split_Squats_28",
    video_length: 0.07,
    video_number: "106",
    size: 452377,
    hints: "LnB_Split_Squats_28_hints",
    common_mistakes: "LnB_Split_Squats_28_common_mistakes",
    breathing: "LnB_Split_Squats_28_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    is_other_side: 0,
    name: "Single Hammer Kickbacks",
    is_free: false,
    primary_muscle: "Triceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rear Shoulders",
    subcategory_name: "triceps",
    tertiary_muscle: "Latissimus Dorsi, Traps, Rhomboids",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FTnS_Single_Hammer_Kickbacks_37.jpg?alt=media&token=3d943117-c075-47df-a709-38fde22be7f5",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FTnS_Single_Hammer_Kickbacks_37.mp4?alt=media&token=9951b748-c134-427c-9083-b6855d9142ac",
    video_id: "TnS_Single_Hammer_Kickbacks_37",
    video_length: 0.1,
    video_number: "26",
    size: 701571,
    hints: "TnS_Single_Hammer_Kickbacks_37_hints",
    common_mistakes: "TnS_Single_Hammer_Kickbacks_37_common_mistakes",
    breathing: "TnS_Single_Hammer_Kickbacks_37_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    is_other_side: 1,
    name: "One Arm Concentration Curls",
    is_free: false,
    primary_muscle: "Biceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Brachialis, Forearm, Lower Body",
    subcategory_name: "biceps",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_One_Arm_Concentration_Curls_34.jpg?alt=media&token=1dd62994-1f1e-4a85-a243-afd68ed6e821",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_One_Arm_Concentration_Curls_34.mp4?alt=media&token=d9361bd2-73aa-4667-b854-0824daa646dd",
    video_id: "CnB_One_Arm_Concentration_Curls_34",
    video_length: 0.08,
    video_number: "57",
    size: 524335,
    hints: "CnB_One_Arm_Concentration_Curls_34_hints",
    common_mistakes: "CnB_One_Arm_Concentration_Curls_34_common_mistakes",
    breathing: "CnB_One_Arm_Concentration_Curls_34_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Shoulder Tap Push Ups",
    is_free: false,
    primary_muscle: "Middle Chest",
    rep_range: "8-20",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Triceps, Core, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Shoulder_Tap_Push_Ups_35.jpg?alt=media&token=caafe2ec-41a5-43d5-b716-12c263efa6ec",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Shoulder_Tap_Push_Ups_35.mp4?alt=media&token=53fd5dbb-2ddc-4566-9037-5149aa729f1e",
    video_id: "CnB_Shoulder_Tap_Push_Ups_35",
    video_length: 0.1,
    video_number: "7(2)",
    size: 645179,
    hints: "CnB_Shoulder_Tap_Push_Ups_35_hints",
    common_mistakes: "CnB_Shoulder_Tap_Push_Ups_35_common_mistakes",
    breathing: "CnB_Shoulder_Tap_Push_Ups_35_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Lateral Raise",
    is_free: true,
    primary_muscle: "Middle Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Front Shoulders, Rear Shoulders, Traps, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FTnS_Lateral_Raise_38.jpg?alt=media&token=a3d7eb92-9344-4a99-92f4-e6995882d3ba",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FTnS_Lateral_Raise_38.mp4?alt=media&token=1824cd69-92e6-4c21-b78f-5ef4b724b422",
    video_id: "TnS_Lateral_Raise_38",
    video_length: 0.07,
    video_number: "81",
    size: 520173,
    hints: "TnS_Lateral_Raise_38_hints",
    common_mistakes: "TnS_Lateral_Raise_38_common_mistakes",
    breathing: "TnS_Lateral_Raise_38_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Seated Regular Rear Delt Raises",
    is_free: false,
    primary_muscle: "Rear Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Middle Shoulders, Traps, Rhomboids, Infraspinatus, Teres Minor",
    subcategory_name: "shoulders",
    tertiary_muscle: "Front Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSeated%20Regular%20Rear%20Delt%20Raise.jpg?alt=media&token=9f3bb723-4c16-4753-88e4-ed24eeccdd85",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fnew-videos%2FSeated%20Regular%20Rear%20Delt%20Raise.mp4?alt=media&token=4ff531e9-f3d5-4291-af9a-869fd7db3379",
    video_id: "TnS_Seated_RegularRearDeltRaises_39",
    video_length: 0.05,
    video_number: "91",
    size: 367048,
    hints: "TnS_Seated_RegularRearDeltRaises_39_hints",
    common_mistakes: "TnS_Seated_RegularRearDeltRaises_39_common_mistakes",
    breathing: "TnS_Seated_RegularRearDeltRaises_39_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Side Leg Raises",
    is_free: false,
    primary_muscle: "Abs, Obliques",
    rep_range: "Time Based",
    secondary_muscle: "Hip Flexors",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FAbs_Side_Leg_Raises_21.jpg?alt=media&token=45b49cb4-eb95-40ec-aa51-58de8e26c15d",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FAbs_Side_Leg_Raises_21.mp4?alt=media&token=b6efb83c-66f1-4dd1-931f-153ab4798ddb",
    video_id: "Abs_Side_Leg_Raises_21",
    video_length: 0.05,
    video_number: "42",
    size: 417303,
    hints: "Abs_Side_Leg_Raises_21_hints",
    common_mistakes: "Abs_Side_Leg_Raises_21_common_mistakes",
    breathing: "Abs_Side_Leg_Raises_21_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Reverse Press Up Regular Press Down",
    is_free: false,
    primary_muscle: "Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Traps, Chest, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FReverse%20Press%20Up%20Regular%20Press%20Down.jpg?alt=media&token=bbeab810-a9a4-4fc0-856f-53759028ab69",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fnew-videos%2FReverse%20Press%20Up%20Regular%20Press%20Down.mp4?alt=media&token=5c9b0e0e-465c-4189-9f1a-dd4790f6fad1",
    video_id: "TnS_ReversePressUpRegularPressDown_40",
    video_length: 0.12,
    video_number: "92",
    size: 743917,
    hints: "TnS_ReversePressUpRegularPressDown_40_hints",
    common_mistakes: "TnS_ReversePressUpRegularPressDown_40_common_mistakes",
    breathing: "TnS_ReversePressUpRegularPressDown_40_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Seated Hammer Rear Delt Raises",
    is_free: false,
    primary_muscle: "Rear Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Middle Shoulders, Traps, Rhomboids, Infraspinatus, Teres Minor",
    subcategory_name: "shoulders",
    tertiary_muscle: "Front Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSeated%20Hammer%20Rear%20Delt%20Raise.jpg?alt=media&token=2657f5ea-4a85-4b33-ba6e-ca1d05ae1d9c",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fnew-videos%2FSeated%20Hammer%20Rear%20Delt%20Raise.mp4?alt=media&token=2b074b2d-f39a-47c9-b439-32c2f4bf28b3",
    video_id: "TnS_Seated_Hammer_Rear_Delt_Raises_41",
    video_length: 0.1,
    video_number: "90",
    size: 628148,
    hints: "TnS_Seated_Hammer_Rear_Delt_Raises_41_hints",
    common_mistakes: "TnS_Seated_Hammer_Rear_Delt_Raises_41_common_mistakes",
    breathing: "TnS_Seated_Hammer_Rear_Delt_Raises_41_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    is_other_side: 1,
    name: "Elbow Out Lawnmowers",
    is_free: false,
    primary_muscle: "Latissimus Dorsi, Rhomboids, Traps, Erector Spinae",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rear Shoulders, Teres Major, Teres Minor, Infraspinatus, Biceps",
    subcategory_name: "back",
    tertiary_muscle: "Lower Back",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_Elbow_Out_Lawnmowers_29.jpg?alt=media&token=d46a0e7e-acca-4fb6-a1ab-e1b4f53a196b",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_Elbow_Out_Lawnmowers_29.mp4?alt=media&token=a9bac04b-3ca1-49da-a2fb-6e372915b66f",
    video_id: "LnB_Elbow_Out_Lawnmowers_29",
    video_length: 0.08,
    video_number: "55",
    size: 597334,
    hints: "LnB_Elbow_Out_Lawnmowers_29_hints",
    common_mistakes: "LnB_Elbow_Out_Lawnmowers_29_common_mistakes",
    breathing: "LnB_Elbow_Out_Lawnmowers_29_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "In And Out Hammer Curls",
    is_free: true,
    primary_muscle: "Biceps, Brachialis",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Forearm",
    subcategory_name: "biceps",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_In_And_Out_Hammer_Curls_36.jpg?alt=media&token=0d8a5980-0b20-44d6-80d3-f0007f3922b0",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_In_And_Out_Hammer_Curls_36.mp4?alt=media&token=6362b639-27af-4618-9d74-aab915dd3e81",
    video_id: "CnB_In_And_Out_Hammer_Curls_36",
    video_length: 0.07,
    video_number: "63",
    size: 464243,
    hints: "CnB_In_And_Out_Hammer_Curls_36_hints",
    common_mistakes: "CnB_In_And_Out_Hammer_Curls_36_common_mistakes",
    breathing: "CnB_In_And_Out_Hammer_Curls_36_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    is_other_side: 1,
    name: "Seated Concentrated Curls",
    is_free: false,
    primary_muscle: "Biceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Brachialis, Forearm",
    subcategory_name: "biceps",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(R)%20Seated%20Concentrated%20Curls.jpg?alt=media&token=9b897518-862e-4fef-aa6a-904449d38c27",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fnew-videos%2F(R)%20Seated%20Concentrated%20Curls.mp4?alt=media&token=b1d57b10-a5ca-4523-a723-14e51ef965f7",
    video_id: "CnB_Seated_Concentrated_Curls_37",
    video_length: 0.1,
    video_number: "16",
    size: 665820,
    hints: "CnB_Seated_Concentrated_Curls_37_hints",
    common_mistakes: "CnB_Seated_Concentrated_Curls_37_common_mistakes",
    breathing: "CnB_Seated_Concentrated_Curls_37_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Calf Raise Squats",
    is_free: false,
    primary_muscle: "Quads, Glutes, Calves",
    rep_range: "15-20",
    secondary_muscle: "Hamstrings, Adductors, Erector Spinae",
    subcategory_name: "legs",
    tertiary_muscle: "Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_Calf_Raise_Squats_31.jpg?alt=media&token=561a2d6e-eb8d-48e1-9e35-113f52755d92",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_Calf_Raise_Squats_31.mp4?alt=media&token=b488b15d-e685-4efe-a8ba-63f405f50e1e",
    video_id: "LnB_Calf_Raise_Squats_31",
    video_length: 0.1,
    video_number: "103",
    size: 710803,
    hints: "LnB_Calf_Raise_Squats_31_hints",
    common_mistakes: "LnB_Calf_Raise_Squats_31_common_mistakes",
    breathing: "LnB_Calf_Raise_Squats_31_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Shoulder Press",
    is_free: true,
    primary_muscle: "Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Traps, Chest, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FTnS_Shoulder_Press_42.jpg?alt=media&token=870ac34b-4e79-47c1-8eb0-a8bcfc5af90c",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FTnS_Shoulder_Press_42.mp4?alt=media&token=8438f498-256b-4f8f-9186-916a14b4e50e",
    video_id: "TnS_Shoulder_Press_42",
    video_length: 0.1,
    video_number: "103",
    size: 1343153,
    hints: "TnS_Shoulder_Press_42_hints",
    common_mistakes: "TnS_Shoulder_Press_42_common_mistakes",
    breathing: "TnS_Shoulder_Press_42_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Wide Push Ups",
    is_free: false,
    primary_muscle: "Middle Chest",
    rep_range: "10-20",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Triceps, Core, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Wide_Push_Ups_38.jpg?alt=media&token=08f9f1c5-29f7-4c2f-87ba-d6941e29de11",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Wide_Push_Ups_38.mp4?alt=media&token=86549744-eb31-499b-a1ff-b88a42f7f20c",
    video_id: "CnB_Wide_Push_Ups_38",
    video_length: 0.08,
    video_number: "16",
    size: 575984,
    hints: "CnB_Wide_Push_Ups_38_hints",
    common_mistakes: "CnB_Wide_Push_Ups_38_common_mistakes",
    breathing: "CnB_Wide_Push_Ups_38_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "W Raise",
    is_free: false,
    primary_muscle: "Traps, Rhomboids, Erector Spinae",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rear Shoulders, Teres Major, Teres Minor, Latissimus Dorsi Infraspinatus",
    subcategory_name: "back",
    tertiary_muscle: "Lower Back",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_W_Raise_32.jpg?alt=media&token=4c29d748-e95f-49fa-9819-852d21e8feec",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_W_Raise_32.mp4?alt=media&token=d7d485f6-e645-443a-86a6-ab2e87f13da8",
    video_id: "LnB_W_Raise_32",
    video_length: 0.08,
    video_number: "78",
    size: 562285,
    hints: "LnB_W_Raise_32_hints",
    common_mistakes: "LnB_W_Raise_32_common_mistakes",
    breathing: "LnB_W_Raise_32_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Half Way Rear Delt Raises",
    is_free: false,
    primary_muscle: "Rear Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Middle Shoulders, Traps, Rhomboids, Infraspinatus, Teres Minor",
    subcategory_name: "shoulders",
    tertiary_muscle: "Front Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FTnS_HalfWayRearDeltRaises_43.jpg?alt=media&token=fabaa908-f702-4587-b6c8-a00e15e502c8",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FTnS_HalfWayRearDeltRaises_43.mp4?alt=media&token=5cc0d532-ae19-4fd7-8626-fabdf0d8d2ac",
    video_id: "TnS_HalfWayRearDeltRaises_43",
    video_length: 0.08,
    video_number: "89",
    size: 547871,
    hints: "TnS_HalfWayRearDeltRaises_43_hints",
    common_mistakes: "TnS_HalfWayRearDeltRaises_43_common_mistakes",
    breathing: "TnS_HalfWayRearDeltRaises_43_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Shrug Holds",
    is_free: false,
    primary_muscle: "Traps, Rhomboids ",
    rep_range: "Time Based",
    secondary_muscle: "Rear Shoulders, Levator Scapulae",
    subcategory_name: "back",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_Shrug_Holds_33.jpg?alt=media&token=87ab0ca5-1d3b-4bd4-ab54-9fc21fae706b",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_Shrug_Holds_33.mp4?alt=media&token=f6824952-516d-42c5-9fd6-b2344f23b23e",
    video_id: "LnB_Shrug_Holds_33",
    video_length: 0.08,
    video_number: "69",
    size: 600781,
    hints: "LnB_Shrug_Holds_33_hints",
    common_mistakes: "LnB_Shrug_Holds_33_common_mistakes",
    breathing: "LnB_Shrug_Holds_33_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Knee Tuck Crunch",
    is_free: false,
    primary_muscle: "Upper Abs",
    rep_range: "Time Based",
    secondary_muscle: "Lower Abs, Obliques, Hip Flexors",
    tertiary_muscle: "Quads",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FAbs_Knee_Tuck_Crunch_22.jpg?alt=media&token=70b65a36-5762-4427-883a-769e0ce7d69a",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FAbs_Knee_Tuck_Crunch_22.mp4?alt=media&token=70738134-86d0-44a1-a43d-977ed889e026",
    video_id: "Abs_Knee_Tuck_Crunch_22",
    video_length: 0.05,
    video_number: "3",
    size: 422328,
    hints: "Abs_Knee_Tuck_Crunch_22_hints",
    common_mistakes: "Abs_Knee_Tuck_Crunch_22_common_mistakes",
    breathing: "Abs_Knee_Tuck_Crunch_22_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Laterals Traps",
    is_free: false,
    primary_muscle: "Traps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rhomboids, Teres Major, Teres Minor, Infraspinatus, Latissimus Dorsi, Shoulders",
    subcategory_name: "back",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_Laterals_Traps_34.jpg?alt=media&token=bd8a2499-b951-4f4d-995a-7c096d359f2d",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_Laterals_Traps_34.mp4?alt=media&token=963cb299-390f-4d7b-9017-fe55a7773323",
    video_id: "LnB_Laterals_Traps_34",
    video_length: 0.07,
    video_number: "35",
    size: 445530,
    hints: "LnB_Laterals_Traps_34_hints",
    common_mistakes: "LnB_Laterals_Traps_34_common_mistakes",
    breathing: "LnB_Laterals_Traps_34_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Zottman Curl Hammer Down",
    is_free: false,
    primary_muscle: "Biceps, Brachialis, Forearm",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Shoulders",
    subcategory_name: "biceps",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Zottman_Curl_Hammer_Down_39.jpg?alt=media&token=23989362-5c77-49c0-abf3-fc2414972d89",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Zottman_Curl_Hammer_Down_39.mp4?alt=media&token=81dd4a21-828d-4186-bc14-d4ea42bb6e42",
    video_id: "CnB_Zottman_Curl_Hammer_Down_39",
    video_length: 0.07,
    video_number: "76",
    size: 432169,
    hints: "CnB_Zottman_Curl_Hammer_Down_39_hints",
    common_mistakes: "CnB_Zottman_Curl_Hammer_Down_39_common_mistakes",
    breathing: "CnB_Zottman_Curl_Hammer_Down_39_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    is_other_side: 1,
    name: "Step Up",
    is_free: false,
    primary_muscle: "Quads, Hamstrings, Glutes",
    rep_range: "10-15",
    secondary_muscle: "Adductors",
    subcategory_name: "legs",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(R)%20Step%20Up.jpg?alt=media&token=38058043-4e6d-405a-a065-98ed37ae8558",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fnew-videos%2F(R)%20Step%20Up.mp4?alt=media&token=5c79b566-cd05-434d-83f2-026c7c5ba513",
    video_id: "LnB_Step_Up_35",
    video_length: 0.1,
    video_number: "34",
    size: 670168,
    hints: "LnB_Step_Up_35_hints",
    common_mistakes: "LnB_Step_Up_35_common_mistakes",
    breathing: "LnB_Step_Up_35_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Seated Bent Over Back Flys",
    is_free: true,
    primary_muscle: "Traps, Rhomboids, Rear Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Teres Major, Teres Minor, Infraspinatus",
    subcategory_name: "back",
    tertiary_muscle: "Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSeated%20Bent%20Over%20Back%20Flys.jpg?alt=media&token=45a16fec-530a-4bb0-9d16-65af97b884bc",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fnew-videos%2FSeated%20Bent%20Over%20Back%20Flys.mp4?alt=media&token=201291ae-b4de-477a-971b-91aacdbabfd5",
    video_id: "LnB_Seated_Bent_Over_Back_Flys_36",
    video_length: 0.08,
    video_number: "17",
    size: 529934,
    hints: "LnB_Seated_Bent_Over_Back_Flys_36_hints",
    common_mistakes: "LnB_Seated_Bent_Over_Back_Flys_36_common_mistakes",
    breathing: "LnB_Seated_Bent_Over_Back_Flys_36_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Fingers Out Push Ups",
    is_free: false,
    primary_muscle: "Middle Chest",
    rep_range: "10-20",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Triceps, Core, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Fingers_Out_Push_Ups_40.jpg?alt=media&token=b2945113-d536-4421-8f77-e052ad686b2c",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Fingers_Out_Push_Ups_40.mp4?alt=media&token=97e1cf03-2c75-4450-9280-6db7abfe8c36",
    video_id: "CnB_Fingers_Out_Push_Ups_40",
    video_length: 0.05,
    video_number: "13",
    size: 408464,
    hints: "CnB_Fingers_Out_Push_Ups_40_hints",
    common_mistakes: "CnB_Fingers_Out_Push_Ups_40_common_mistakes",
    breathing: "CnB_Fingers_Out_Push_Ups_40_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    is_other_side: 1,
    name: "Concentrated Side Raises",
    is_free: false,
    primary_muscle: "Middle Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Front Shoulders, Traps, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Rear Shoulders, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FTnS_Concentrated_Side_Raises_44.jpg?alt=media&token=26bd79a5-0e89-43b5-83dd-c1a6f9e44783",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FTnS_Concentrated_Side_Raises_44.mp4?alt=media&token=efae32a3-93b2-42b4-91a8-4de433ca7e58",
    video_id: "TnS_Concentrated_Side_Raises_44",
    video_length: 0.07,
    video_number: "76",
    size: 419215,
    hints: "TnS_Concentrated_Side_Raises_44_hints",
    common_mistakes: "TnS_Concentrated_Side_Raises_44_common_mistakes",
    breathing: "TnS_Concentrated_Side_Raises_44_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Popeye Hammer Curls",
    is_free: false,
    primary_muscle: "Biceps, Brachialis",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Forearm",
    subcategory_name: "biceps",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Popeye_Hammer_Curls_41.jpg?alt=media&token=992042cd-d37d-4f5b-ad17-2607ce8ab51b",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Popeye_Hammer_Curls_41.mp4?alt=media&token=e50823e5-7a80-4171-84cd-5184f0a3020e",
    video_id: "CnB_Popeye_Hammer_Curls_41",
    video_length: 0.08,
    video_number: "43",
    size: 575511,
    hints: "CnB_Popeye_Hammer_Curls_41_hints",
    common_mistakes: "CnB_Popeye_Hammer_Curls_41_common_mistakes",
    breathing: "CnB_Popeye_Hammer_Curls_41_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Leg Lifts",
    is_free: false,
    primary_muscle: "Lower Abs",
    rep_range: "Time Based",
    secondary_muscle: "Upper Abs, Obliques, Hip Flexors",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FAbs_Leg_Lifts_23.jpg?alt=media&token=3d7e4ea2-d647-4426-bab7-010c94586794",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FAbs_Leg_Lifts_23.mp4?alt=media&token=f2ec389c-2116-4344-b998-070954867b27",
    video_id: "Abs_Leg_Lifts_23",
    video_length: 0.1,
    video_number: "1",
    size: 737248,
    hints: "Abs_Leg_Lifts_23_hints",
    common_mistakes: "Abs_Leg_Lifts_23_common_mistakes",
    breathing: "Abs_Leg_Lifts_23_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Seated Lateral Raise",
    is_free: false,
    primary_muscle: "Middle Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Front Shoulders, Rear Shoulders, Traps, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSeated%20Lateral%20Raise.jpg?alt=media&token=6c8089da-b019-4667-903a-476fa244815d",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fnew-videos%2FSeated%20Lateral%20Raise.mp4?alt=media&token=9d304d14-55af-47bc-ad6a-cca681ba31aa",
    video_id: "TnS_Seated_Lateral_Raise_45",
    video_length: 0.1,
    video_number: "80",
    size: 680635,
    hints: "TnS_Seated_Lateral_Raise_45_hints",
    common_mistakes: "TnS_Seated_Lateral_Raise_45_common_mistakes",
    breathing: "TnS_Seated_Lateral_Raise_45_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    is_other_side: 1,
    name: "Seated Single Tricep Extensions",
    is_free: false,
    primary_muscle: "Triceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Forearm, Shoulders",
    subcategory_name: "triceps",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FTnS_Seated_Single_Tricep_Extensions_46.jpg?alt=media&token=5d4362aa-6c77-491d-92eb-518745a3ad97",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FTnS_Seated_Single_Tricep_Extensions_46.mp4?alt=media&token=7dd362b9-85d0-4af4-954e-4914d4346014",
    video_id: "TnS_Seated_Single_Tricep_Extensions_46",
    video_length: 0.07,
    video_number: "10",
    size: 509451,
    hints: "TnS_Seated_Single_Tricep_Extensions_46_hints",
    common_mistakes: "TnS_Seated_Single_Tricep_Extensions_46_common_mistakes",
    breathing: "TnS_Seated_Single_Tricep_Extensions_46_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Out Curls",
    is_free: true,
    primary_muscle: "Biceps (Short Head)",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Biceps (Long Head), Brachialis, Forearm",
    subcategory_name: "biceps",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Out_Curls_42.jpg?alt=media&token=eb9421b5-ddbd-4799-8dd5-be542e26debd",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Out_Curls_42.mp4?alt=media&token=048258f6-8aca-489b-8bce-145994f49328",
    video_id: "CnB_Out_Curls_42",
    video_length: 0.07,
    video_number: "59",
    size: 502288,
    hints: "CnB_Out_Curls_42_hints",
    common_mistakes: "CnB_Out_Curls_42_common_mistakes",
    breathing: "CnB_Out_Curls_42_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    is_other_side: 1,
    name: "Lawnmower",
    is_free: false,
    primary_muscle: "Traps, Rhomboids, Erector Spinae",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rear Shoulders, Teres Major, Teres Minor, Latissimus Dorsi, Infraspinatus, Biceps",
    subcategory_name: "back",
    tertiary_muscle: "Middle Shoulders, Front Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_Lawnmower_37.jpg?alt=media&token=dff771c3-5457-49a5-8fe9-1250fc1a1f29",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_Lawnmower_37.mp4?alt=media&token=e281da66-bec6-4bb2-84bd-9ab492c0d0cb",
    video_id: "LnB_Lawnmower_37",
    video_length: 0.08,
    video_number: "83",
    size: 518408,
    hints: "LnB_Lawnmower_37_hints",
    common_mistakes: "LnB_Lawnmower_37_common_mistakes",
    breathing: "LnB_Lawnmower_37_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Bent Over Rear Delt Raises",
    is_free: false,
    primary_muscle: "Rear Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Middle Shoulders, Traps, Rhomboids, Infraspinatus, Teres Minor",
    subcategory_name: "shoulders",
    tertiary_muscle: "Front Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FTnS_Bent_Over_Rear_Delt_Raises_47.jpg?alt=media&token=2c5adda4-8da6-489b-8cfb-aadb7be44b42",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FTnS_Bent_Over_Rear_Delt_Raises_47.mp4?alt=media&token=c9772fe9-016e-4f49-8772-098d3c9bef0d",
    video_id: "TnS_Bent_Over_Rear_Delt_Raises_47",
    video_length: 0.07,
    video_number: "84",
    size: 440728,
    hints: "TnS_Bent_Over_Rear_Delt_Raises_47_hints",
    common_mistakes: "TnS_Bent_Over_Rear_Delt_Raises_47_common_mistakes",
    breathing: "TnS_Bent_Over_Rear_Delt_Raises_47_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    is_other_side: 1,
    name: "Single Arm Lateral Raise",
    is_free: false,
    primary_muscle: "Middle Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Front Shoulders, Traps, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Rear Shoulders, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(R)%20Single%20Arm%20Lateral%20Raise.jpg?alt=media&token=0283c848-4d33-4614-8a6e-e971e50e4798",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fnew-videos%2F(R)%20Single%20Arm%20Lateral%20Raise.mp4?alt=media&token=2cfd6c81-cf36-4681-b70e-1276073031aa",
    video_id: "TnS_Single_Arm_Lateral_Raise_48",
    video_length: 0.08,
    video_number: "79",
    size: 580460,
    hints: "TnS_Single_Arm_Lateral_Raise_48_hints",
    common_mistakes: "TnS_Single_Arm_Lateral_Raise_48_common_mistakes",
    breathing: "TnS_Single_Arm_Lateral_Raise_48_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Weighted Superman",
    is_free: false,
    primary_muscle: "Erector Spinae, Lower Back",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle:
      "Rhomboids, Teres Major, Teres Minor, Infraspinatus, Traps, Shoulders, Latissimus Dorsi, Hamstrings, Gluteus Maximus, Adductors",
    subcategory_name: "back",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_Weighted_Superman_38.jpg?alt=media&token=bfcbe289-8a08-484b-ab94-1715509ca098",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_Weighted_Superman_38.mp4?alt=media&token=8a9c28a9-3d26-4707-baab-b3512198338a",
    video_id: "LnB_Weighted_Superman_38",
    video_length: 0.05,
    video_number: "44",
    size: 390813,
    hints: "LnB_Weighted_Superman_38_hints",
    common_mistakes: "LnB_Weighted_Superman_38_common_mistakes",
    breathing: "LnB_Weighted_Superman_38_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Slow Mo Leg Lowers",
    is_free: false,
    primary_muscle: "Lower Abs",
    rep_range: "Time Based",
    secondary_muscle: "Upper Abs, Obliques, Hip Flexors",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FAbs_Slow_Mo_Leg_Lowers_24.jpg?alt=media&token=e063456a-92e8-40d7-96f2-67a7d01d7b2f",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FAbs_Slow_Mo_Leg_Lowers_24.mp4?alt=media&token=a020aa51-3fb2-4daa-ac1b-11a6a77e2749",
    video_id: "Abs_Slow_Mo_Leg_Lowers_24",
    video_length: 0.13,
    video_number: "53",
    size: 865574,
    hints: "Abs_Slow_Mo_Leg_Lowers_24_hints",
    common_mistakes: "Abs_Slow_Mo_Leg_Lowers_24_common_mistakes",
    breathing: "Abs_Slow_Mo_Leg_Lowers_24_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Mechanic Change Decline Push Ups",
    is_free: false,
    primary_muscle: "Upper Chest",
    rep_range: "6-14",
    secondary_muscle: "Middle Chest, Lower Chest, Shoulders, Triceps, Core, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Mechanic_Change_Decline_Push_Ups_43.jpg?alt=media&token=e3085835-9fe2-49dc-9e34-754ebdb1445a",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "Chair",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Mechanic_Change_Decline_Push_Ups_43.mp4?alt=media&token=1d384caf-e537-4ed7-8fc0-2ed2a33dc476",
    video_id: "CnB_Mechanic_Change_Decline_Push_Ups_43",
    video_length: 0.17,
    video_number: "142",
    size: 2013195,
    hints: "CnB_Mechanic_Change_Decline_Push_Ups_43_hints",
    common_mistakes: "CnB_Mechanic_Change_Decline_Push_Ups_43_common_mistakes",
    breathing: "CnB_Mechanic_Change_Decline_Push_Ups_43_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Seated Bikes",
    is_free: false,
    primary_muscle: "Upper Abs",
    rep_range: "Time Based",
    secondary_muscle: "Lower Abs, Obliques, Hip Flexors",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FAbs_Seated_Bikes_25.jpg?alt=media&token=3144b015-f127-43d0-b51c-dff857450102",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FAbs_Seated_Bikes_25.mp4?alt=media&token=477aceef-42fc-4d20-9a4d-76e97c1f5175",
    video_id: "Abs_Seated_Bikes_25",
    video_length: 0.08,
    video_number: "36",
    size: 610581,
    hints: "Abs_Seated_Bikes_25_hints",
    common_mistakes: "Abs_Seated_Bikes_25_common_mistakes",
    breathing: "Abs_Seated_Bikes_25_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Seated Alternating Curls",
    is_free: false,
    primary_muscle: "Biceps (Short Head)",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Biceps (Long Head), Brachialis, Forearm",
    subcategory_name: "biceps",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSeated%20Alternating%20Curls.jpg?alt=media&token=0e55b1da-2495-42b5-a945-5f9f799d9832",
    time: "75 seconds",
    time_to_play: 1.25,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fnew-videos%2FSeated%20Alternating%20Curls.mp4?alt=media&token=f63bebc5-0a26-4d47-9547-87b9c3c9feb0",
    video_id: "CnB_Seated_Alternating_Curls_44",
    video_length: 0.07,
    video_number: "18",
    size: 438942,
    hints: "CnB_Seated_Alternating_Curls_44_hints",
    common_mistakes: "CnB_Seated_Alternating_Curls_44_common_mistakes",
    breathing: "CnB_Seated_Alternating_Curls_44_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Shoulder Extensions",
    is_free: false,
    primary_muscle: "Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Traps, Chest, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FTnS_Shoulder_Extensions_49.jpg?alt=media&token=3b78e612-4e97-4ec1-b882-dde39d328667",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FTnS_Shoulder_Extensions_49.mp4?alt=media&token=b02cb4dd-9b27-496e-9b21-384366678732",
    video_id: "TnS_Shoulder_Extensions_49",
    video_length: 0.1,
    video_number: "63",
    size: 691453,
    hints: "TnS_Shoulder_Extensions_49_hints",
    common_mistakes: "TnS_Shoulder_Extensions_49_common_mistakes",
    breathing: "TnS_Shoulder_Extensions_49_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Side to Side Push Ups",
    is_free: false,
    primary_muscle: "Middle Chest",
    rep_range: "4-14",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Triceps, Core, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Side_to_Side_Push_Ups_45.jpg?alt=media&token=4842a689-75e6-4fca-b991-4e72ee902308",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Side_to_Side_Push_Ups_45.mp4?alt=media&token=d3f905db-6515-47c0-af02-56f966320923",
    video_id: "CnB_Side_to_Side_Push_Ups_45",
    video_length: 0.08,
    video_number: "17",
    size: 583835,
    hints: "CnB_Side_to_Side_Push_Ups_45_hints",
    common_mistakes: "CnB_Side_to_Side_Push_Ups_45_common_mistakes",
    breathing: "CnB_Side_to_Side_Push_Ups_45_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Chest Press",
    is_free: false,
    primary_muscle: "Middle Chest",
    rep_range: "15-25",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Triceps, Core, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Chest_Press_46.jpg?alt=media&token=da84503e-62f1-46dd-b344-57a45730e856",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Chest_Press_46.mp4?alt=media&token=808b145c-ab08-4f25-8af4-f389da1608f0",
    video_id: "CnB_Chest_Press_46",
    video_length: 0.08,
    video_number: "33",
    size: 568632,
    hints: "CnB_Chest_Press_46_hints",
    common_mistakes: "CnB_Chest_Press_46_common_mistakes",
    breathing: "CnB_Chest_Press_46_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Seated Alternating Hammer Curls",
    is_free: false,
    primary_muscle: "Biceps, Brachialis",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Forearm",
    subcategory_name: "biceps",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSeated%20Alternating%20Hammer%20Curls.jpg?alt=media&token=a7abdb50-283a-4f23-9ec2-658a0dc58cb1",
    time: "75 seconds",
    time_to_play: 1.25,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fnew-videos%2FSeated%20Alternating%20Hammer%20Curls.mp4?alt=media&token=fa77d930-ee76-4f6e-8804-57fe0d7fe65f",
    video_id: "CnB_Seated_Alternating_Hammer_Curls_47",
    video_length: 0.07,
    video_number: "21",
    size: 472113,
    hints: "CnB_Seated_Alternating_Hammer_Curls_47_hints",
    common_mistakes: "CnB_Seated_Alternating_Hammer_Curls_47_common_mistakes",
    breathing: "CnB_Seated_Alternating_Hammer_Curls_47_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    is_other_side: 0,
    name: "Split Squats",
    is_free: false,
    primary_muscle: "Quads, Glutes",
    rep_range: "10-15",
    secondary_muscle: "Hamstrings Adductors, Erector Spinae",
    subcategory_name: "legs",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_Split_Squats_39.jpg?alt=media&token=97d47a3d-d4a9-4c14-a3da-85f48f798636",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_Split_Squats_39.mp4?alt=media&token=c95d566d-d602-4d69-9cb6-d5017d708d3e",
    video_id: "LnB_Split_Squats_39",
    video_length: 0.05,
    video_number: "105",
    size: 412626,
    hints: "LnB_Split_Squats_39_hints",
    common_mistakes: "LnB_Split_Squats_39_common_mistakes",
    breathing: "LnB_Split_Squats_39_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Skull Crushers And A Frame Press",
    is_free: false,
    primary_muscle: "Middle Chest, Triceps",
    rep_range: "15-25",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Forearm, Serratus Anterior",
    subcategory_name: "triceps",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FTnS_SkullCrushersAndAFramePress_50.jpg?alt=media&token=43acefdc-f5cb-414d-ad60-c662117d080b",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FTnS_SkullCrushersAndAFramePress_50.mp4?alt=media&token=89a65c20-f9b0-4078-8b4f-c3db51509ff2",
    video_id: "TnS_SkullCrushersAndAFramePress_50",
    video_length: 0.1,
    video_number: "45",
    size: 689116,
    hints: "TnS_SkullCrushersAndAFramePress_50_hints",
    common_mistakes: "TnS_SkullCrushersAndAFramePress_50_common_mistakes",
    breathing: "TnS_SkullCrushersAndAFramePress_50_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Typewriter Push ups",
    is_free: false,
    primary_muscle: "Middle Chest",
    rep_range: "10-15",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Triceps, Core, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Writer_Push_ups_48.jpg?alt=media&token=ab3f17a7-3e6b-4005-95f7-819cc42ed3a8",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Writer_Push_ups_48.mp4?alt=media&token=e664d9de-63be-458b-abd3-c4778a0ec144",
    video_id: "CnB_Writer_Push_ups_48",
    video_length: 0.07,
    video_number: "11(2)",
    size: 485716,
    hints: "CnB_Writer_Push_ups_48_hints",
    common_mistakes: "CnB_Writer_Push_ups_48_common_mistakes",
    breathing: "CnB_Writer_Push_ups_48_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Seated Curls",
    is_free: true,
    primary_muscle: "Biceps (Short Head)",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Biceps (Long Head), Brachialis, Forearm",
    subcategory_name: "biceps",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSeated%20Curls.jpg?alt=media&token=10c702a9-86f1-4046-9f48-e1bfd1cbbc17",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fnew-videos%2FSeated%20Curls.mp4?alt=media&token=39b68a6e-2a95-43d5-9fd0-a7f168a5849e",
    video_id: "CnB_Seated_Curls_49",
    video_length: 0.12,
    video_number: "19",
    size: 726707,
    hints: "CnB_Seated_Curls_49_hints",
    common_mistakes: "CnB_Seated_Curls_49_common_mistakes",
    breathing: "CnB_Seated_Curls_49_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Rocket Alternating Rows",
    is_free: false,
    primary_muscle: "Latissimus Dorsi, Rhomboids, Traps, Erector Spinae",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rear Shoulders, Teres Major, Teres Minor, Infraspinatus, Biceps",
    subcategory_name: "back",
    tertiary_muscle: "Lower Back, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_Rocket_Rows_40.jpg?alt=media&token=a214ce36-a4bd-4572-b458-51e687acbabc",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_Rocket_Rows_40.mp4?alt=media&token=58ca53b4-5e2d-4a0e-b576-0335421ee742",
    video_id: "LnB_Rocket_Rows_40",
    video_length: 0.1,
    video_number: "41",
    size: 703084,
    hints: "LnB_Rocket_Rows_40_hints",
    common_mistakes: "LnB_Rocket_Rows_40_common_mistakes",
    breathing: "LnB_Rocket_Rows_40_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Goblet Squat Pulses",
    is_free: false,
    primary_muscle: "Quads, Glutes",
    rep_range: "Time Based",
    secondary_muscle: "Hamstrings, Adductors",
    subcategory_name: "legs",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_Goblet_Squat_Pulses_41.jpg?alt=media&token=77578a1a-fc2a-4292-8964-bf1fa113921e",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_Goblet_Squat_Pulses_41.mp4?alt=media&token=f8ac5d68-c94b-4572-9363-6d758a3c1278",
    video_id: "LnB_Goblet_Squat_Pulses_41",
    video_length: 0.07,
    video_number: "110",
    size: 475660,
    hints: "LnB_Goblet_Squat_Pulses_41_hints",
    common_mistakes: "LnB_Goblet_Squat_Pulses_41_common_mistakes",
    breathing: "LnB_Goblet_Squat_Pulses_41_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Double Dumbbell Pull Overs",
    is_free: false,
    primary_muscle: "Latissimus Dorsi",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rhomboids, Rear Shoulders, Teres Major, Teres Minor, Infraspinatus, Chest, Traps",
    subcategory_name: "back",
    tertiary_muscle: "Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_Double_Dumbbell_Pull_Overs_42.jpg?alt=media&token=c44378ce-e375-408b-b0d3-626ead0a8039",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_Double_Dumbbell_Pull_Overs_42.mp4?alt=media&token=48615148-79b5-44e5-b438-95b60531a3cb",
    video_id: "LnB_Double_Dumbbell_Pull_Overs_42",
    video_length: 0.12,
    video_number: "43",
    size: 782273,
    hints: "LnB_Double_Dumbbell_Pull_Overs_42_hints",
    common_mistakes: "LnB_Double_Dumbbell_Pull_Overs_42_common_mistakes",
    breathing: "LnB_Double_Dumbbell_Pull_Overs_42_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "V Up And Roll Up",
    is_free: false,
    primary_muscle: "Abs",
    rep_range: "Time Based",
    secondary_muscle:
      "Obliques, Core, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FAbs_VUpAndRollUp_26.jpg?alt=media&token=84eaf91a-c874-4da2-bb3d-148c8b7adb77",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FAbs_VUpAndRollUp_26.mp4?alt=media&token=285087d8-8335-49e3-bbff-4fa8024fb651",
    video_id: "Abs_VUpAndRollUp_26",
    video_length: 0.1,
    video_number: "49",
    size: 744802,
    hints: "Abs_VUpAndRollUp_26_hints",
    common_mistakes: "Abs_VUpAndRollUp_26_common_mistakes",
    breathing: "Abs_VUpAndRollUp_26_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Climber Push Ups",
    is_free: false,
    primary_muscle: "Middle Chest",
    rep_range: "8-12",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Triceps, Core, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Climber_Push_Ups_50.jpg?alt=media&token=69d194c7-e49e-452a-8846-51a398d33efa",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Climber_Push_Ups_50.mp4?alt=media&token=89a9b06c-2861-4b8f-aedc-e416b7a37fe6",
    video_id: "CnB_Climber_Push_Ups_50",
    video_length: 0.1,
    video_number: "23",
    size: 705145,
    hints: "CnB_Climber_Push_Ups_50_hints",
    common_mistakes: "CnB_Climber_Push_Ups_50_common_mistakes",
    breathing: "CnB_Climber_Push_Ups_50_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Starfish Crunch Rotate",
    is_free: false,
    primary_muscle: "Upper Abs, Obliques",
    rep_range: "Time Based",
    secondary_muscle: "Lower Abs",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FAbs_Starfish_Crunch_Rotate_27.jpg?alt=media&token=1f9fb42e-f19e-421d-8a03-e0d2103c0521",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FAbs_Starfish_Crunch_Rotate_27.mp4?alt=media&token=bef70d8f-69d2-41ae-8d48-7cafc189702a",
    video_id: "Abs_Starfish_Crunch_Rotate_27",
    video_length: 0.08,
    video_number: "13",
    size: 624666,
    hints: "Abs_Starfish_Crunch_Rotate_27_hints",
    common_mistakes: "Abs_Starfish_Crunch_Rotate_27_common_mistakes",
    breathing: "Abs_Starfish_Crunch_Rotate_27_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Preacher Curls",
    is_free: false,
    primary_muscle: "Biceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Brachialis, Forearm, Lower Body",
    subcategory_name: "biceps",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Preacher_Curls_51.jpg?alt=media&token=64ec88f0-5441-4111-8366-d9a70a9540b6",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Preacher_Curls_51.mp4?alt=media&token=93176862-4114-4b23-a023-2e2af21077c4",
    video_id: "CnB_Preacher_Curls_1",
    video_length: 0.1,
    video_number: "40",
    size: 678787,
    hints: "CnB_Preacher_Curls_1_hints",
    common_mistakes: "CnB_Preacher_Curls_1_common_mistakes",
    breathing: "CnB_Preacher_Curls_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Touch Touch Push Ups",
    is_free: false,
    primary_muscle: "Middle Chest",
    rep_range: "8-20",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Triceps, Core, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Touch_Touch_Push_Ups_52.jpg?alt=media&token=68453f81-5f33-4c86-8504-fe9315eb6106",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Touch_Touch_Push_Ups_52.mp4?alt=media&token=33518541-9717-428a-b3a3-3e7ddcea78f4",
    video_id: "CnB_Touch_Touch_Push_Ups_52",
    video_length: 0.08,
    video_number: "15",
    size: 623443,
    hints: "CnB_Touch_Touch_Push_Ups_52_hints",
    common_mistakes: "CnB_Touch_Touch_Push_Ups_52_common_mistakes",
    breathing: "CnB_Touch_Touch_Push_Ups_52_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Drag Curls",
    is_free: false,
    primary_muscle: "Biceps (Long Head)",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Biceps (Short Head), Brachialis, Forearm",
    subcategory_name: "biceps",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Drag_Curls_53.jpg?alt=media&token=d3177603-f31a-48d2-8b93-c06f7813cf88",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Drag_Curls_53.mp4?alt=media&token=439dd27e-845e-42e6-bd09-53088429997e",
    video_id: "CnB_Drag_Curls_53",
    video_length: 0.07,
    video_number: "64",
    size: 513263,
    hints: "CnB_Drag_Curls_53_hints",
    common_mistakes: "CnB_Drag_Curls_53_common_mistakes",
    breathing: "CnB_Drag_Curls_53_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Dumbbell Skull Crushers",
    is_free: false,
    primary_muscle: "Triceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Forearm, Shoulders",
    subcategory_name: "triceps",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FTnS_Dumbbell_Skull_Crushers_51.jpg?alt=media&token=0b9db9fe-e1fa-44b5-8be1-3d5b4ee56839",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FTnS_Dumbbell_Skull_Crushers_51.mp4?alt=media&token=8a0d2e37-ee5f-4baf-bfa8-415fce53fc5d",
    video_id: "TnS_Dumbbell_Skull_Crushers_51",
    video_length: 0.1,
    video_number: "49",
    size: 679307,
    hints: "TnS_Dumbbell_Skull_Crushers_51_hints",
    common_mistakes: "TnS_Dumbbell_Skull_Crushers_51_common_mistakes",
    breathing: "TnS_Dumbbell_Skull_Crushers_51_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Rolling Extension",
    is_free: false,
    primary_muscle: "Triceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Forearm, Shoulders",
    subcategory_name: "triceps",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FTnS_Rolling_Extension_52.jpg?alt=media&token=230e5b9e-7071-457a-8b7c-d4289cc7c799",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FTnS_Rolling_Extension_52.mp4?alt=media&token=92a58807-8c69-4fd7-ac63-31c15a3e2531",
    video_id: "TnS_Rolling_Extension_52",
    video_length: 0.15,
    video_number: "46",
    size: 948011,
    hints: "TnS_Rolling_Extension_52_hints",
    common_mistakes: "TnS_Rolling_Extension_52_common_mistakes",
    breathing: "TnS_Rolling_Extension_52_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "A Frame Presses",
    is_free: true,
    primary_muscle: "Middle Chest, Triceps",
    rep_range: "20-30",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Serratus Anterior",
    subcategory_name: "triceps",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FTnS_A_Frame_Presses_53.jpg?alt=media&token=5e72d3bd-613b-406c-a553-143e952d8e7e",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FTnS_A_Frame_Presses_53.mp4?alt=media&token=9b9bb437-ea75-4035-957a-30c0f3f664f0",
    video_id: "TnS_A_Frame_Presses_53",
    video_length: 0.08,
    video_number: "32",
    size: 562801,
    hints: "TnS_A_Frame_Presses_53_hints",
    common_mistakes: "TnS_A_Frame_Presses_53_common_mistakes",
    breathing: "TnS_A_Frame_Presses_53_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    is_other_side: 1,
    name: "Single Tricep Extensions",
    is_free: false,
    primary_muscle: "Triceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Forearm, Shoulders",
    subcategory_name: "triceps",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FTnS_Single_Tricep_Extensions_54.jpg?alt=media&token=4e7b7a7c-1225-4861-8090-f899f80fae68",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FTnS_Single_Tricep_Extensions_54.mp4?alt=media&token=635a7b92-dcea-4602-ba7f-4ea54434c60b",
    video_id: "TnS_Single_Tricep_Extensions_54",
    video_length: 0.08,
    video_number: "7",
    size: 546312,
    hints: "TnS_Single_Tricep_Extensions_54_hints",
    common_mistakes: "TnS_Single_Tricep_Extensions_54_common_mistakes",
    breathing: "TnS_Single_Tricep_Extensions_54_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Iron Butterfly",
    is_free: false,
    primary_muscle: "Abs",
    rep_range: "Time Based",
    secondary_muscle: "Obliques",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FAbs_Iron_Butterfly_28.jpg?alt=media&token=194c8767-6d95-49b0-a64a-1b229783c92c",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FAbs_Iron_Butterfly_28.mp4?alt=media&token=daec8c37-5836-4416-865e-84c8bad0b462",
    video_id: "Abs_Iron_Butterfly_28",
    video_length: 0.05,
    video_number: "8",
    size: 517485,
    hints: "Abs_Iron_Butterfly_28_hints",
    common_mistakes: "Abs_Iron_Butterfly_28_common_mistakes",
    breathing: "Abs_Iron_Butterfly_28_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Fly and Press",
    is_free: false,
    primary_muscle: "Middle Chest",
    rep_range: "16-26",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Triceps, Core, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Biceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Fly_and_Press_54.jpg?alt=media&token=d7433acd-91b2-4eb3-a937-c7706a4bcc5c",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Fly_and_Press_54.mp4?alt=media&token=0d0966ed-0e50-405f-994b-c32e22c52aa3",
    video_id: "CnB_Fly_and_Press_54",
    video_length: 0.1,
    video_number: "51",
    size: 511150,
    hints: "CnB_Fly_and_Press_54_hints",
    common_mistakes: "CnB_Fly_and_Press_54_common_mistakes",
    breathing: "CnB_Fly_and_Press_54_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Standing Upward Fly",
    is_free: false,
    primary_muscle: "Front Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Chest, Middle Shoulders, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Rear Shoulders, Traps, Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FTnS_Standing_Upward_Fly_55.jpg?alt=media&token=f489f1ba-0cec-4ddc-a9e2-62c5abd766f8",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FTnS_Standing_Upward_Fly_55.mp4?alt=media&token=6a066557-f0f6-4051-8272-cabfd1b5e9d9",
    video_id: "TnS_Standing_Upward_Fly_55",
    video_length: 0.1,
    video_number: "58",
    size: 648047,
    hints: "TnS_Standing_Upward_Fly_55_hints",
    common_mistakes: "TnS_Standing_Upward_Fly_55_common_mistakes",
    breathing: "TnS_Standing_Upward_Fly_55_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Dumbbell Press",
    is_free: true,
    primary_muscle: "Middle Chest",
    rep_range: "15-25",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Triceps, Core, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Dumbbell_Press_55.jpg?alt=media&token=079a3a80-496c-4772-be20-662d903ba9dc",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Dumbbell_Press_55.mp4?alt=media&token=2d2fba74-65d5-4c92-80e9-3321b1628346",
    video_id: "CnB_Dumbbell_Press_55",
    video_length: 0.05,
    video_number: "37",
    size: 383863,
    hints: "CnB_Dumbbell_Press_55_hints",
    common_mistakes: "CnB_Dumbbell_Press_55_common_mistakes",
    breathing: "CnB_Dumbbell_Press_55_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Hammer Front And Side Raise",
    is_free: false,
    primary_muscle: "Front Shoulders, Rear Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Chest, Latissimus Dorsi, Middle Shoulders, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Traps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FTnS_Hammer_Front_And_Side_Raise_56.jpg?alt=media&token=c6e4f9f2-562d-4256-9d6e-f43c2f4681cf",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FTnS_Hammer_Front_And_Side_Raise_56.mp4?alt=media&token=b90f9e88-1484-48fd-a27e-99cde651df25",
    video_id: "TnS_Hammer_Front_And_Side_Raise_56",
    video_length: 0.08,
    video_number: "62",
    size: 595273,
    hints: "TnS_Hammer_Front_And_Side_Raise_56_hints",
    common_mistakes: "TnS_Hammer_Front_And_Side_Raise_56_common_mistakes",
    breathing: "TnS_Hammer_Front_And_Side_Raise_56_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Toe Crunches",
    is_free: false,
    primary_muscle: "Abs",
    rep_range: "Time Based",
    secondary_muscle: "Obliques, Hip Flexors",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FAbs_Toe_Crunches_29.jpg?alt=media&token=bb6ed58d-314a-418c-9383-ed70f0b41b1b",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FAbs_Toe_Crunches_29.mp4?alt=media&token=b7f0ab40-d7d1-466f-b55f-53d8b4cd4425",
    video_id: "Abs_Toe_Crunches_29",
    video_length: 0.08,
    video_number: "32",
    size: 553861,
    hints: "Abs_Toe_Crunches_29_hints",
    common_mistakes: "Abs_Toe_Crunches_29_common_mistakes",
    breathing: "Abs_Toe_Crunches_29_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Alternating Rows And Double Elbow Out Lawnmowers",
    is_free: false,
    primary_muscle: "Traps, Rhomboids, Erector Spinae",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rear Shoulders, Teres Major, Teres Minor, Latissimus Dorsi, Infraspinatus, Biceps",
    subcategory_name: "back",
    tertiary_muscle: "Lower Back, Middle Shoulders, Front Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_RowsAndDoubleElbowOutLawnmowers_43.jpg?alt=media&token=7c4facae-b342-448d-b751-7b6a0767994e",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_RowsAndDoubleElbowOutLawnmowers_43.mp4?alt=media&token=9bfee5e6-07e1-4e69-b156-c3111e1f6845",
    video_id: "LnB_RowsAndDoubleElbowOutLawnmowers_43",
    video_length: 0.1,
    video_number: "86",
    size: 676931,
    hints: "LnB_RowsAndDoubleElbowOutLawnmowers_43_hints",
    common_mistakes: "LnB_RowsAndDoubleElbowOutLawnmowers_43_common_mistakes",
    breathing: "LnB_RowsAndDoubleElbowOutLawnmowers_43_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    is_other_side: 0,
    name: "Single Tricep Extensions",
    is_free: false,
    primary_muscle: "Triceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Forearm, Shoulders",
    subcategory_name: "triceps",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FTnS_Single_Tricep_Extensions_57.jpg?alt=media&token=58a205ea-d149-4610-8d32-9c39f90b9117",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FTnS_Single_Tricep_Extensions_57.mp4?alt=media&token=dc22fa45-9288-4602-bd93-1a8039f9ab76",
    video_id: "TnS_Single_Tricep_Extensions_57",
    video_length: 0.07,
    video_number: "5",
    size: 519056,
    hints: "TnS_Single_Tricep_Extensions_57_hints",
    common_mistakes: "TnS_Single_Tricep_Extensions_57_common_mistakes",
    breathing: "TnS_Single_Tricep_Extensions_57_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Bottom Up Push ups",
    is_free: false,
    primary_muscle: "Middle Chest",
    rep_range: "10-20",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Triceps, Core, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Bottom_Up_Push_ups_56.jpg?alt=media&token=1cfb1e29-47ed-42cc-9dd5-1e293b32c783",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Bottom_Up_Push_ups_56.mp4?alt=media&token=d181cbd6-1768-4a1b-af73-32a7db775253",
    video_id: "CnB_Bottom_Up_Push_ups_56",
    video_length: 0.07,
    video_number: "4(2)",
    size: 418786,
    hints: "CnB_Bottom_Up_Push_ups_56_hints",
    common_mistakes: "CnB_Bottom_Up_Push_ups_56_common_mistakes",
    breathing: "CnB_Bottom_Up_Push_ups_56_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Floor Fly Push Ups",
    is_free: false,
    primary_muscle: "Middle Chest",
    rep_range: "8-12",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Triceps, Core, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Floor_Fly_Push_Ups_57.jpg?alt=media&token=1de73271-ad51-4e0a-8723-c3e6ac191c4e",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Floor_Fly_Push_Ups_57.mp4?alt=media&token=9556796c-03da-43a1-a1fd-0a27df7378b7",
    video_id: "CnB_Floor_Fly_Push_Ups_57",
    video_length: 0.12,
    video_number: "14",
    size: 831213,
    hints: "CnB_Floor_Fly_Push_Ups_57_hints",
    common_mistakes: "CnB_Floor_Fly_Push_Ups_57_common_mistakes",
    breathing: "CnB_Floor_Fly_Push_Ups_57_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Scissor Kicks",
    is_free: false,
    primary_muscle: "Lower Abs",
    rep_range: "Time Based",
    secondary_muscle: "Upper Abs, Obliques, Hip Flexors",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FAbs_Scissor_Kicks_30.jpg?alt=media&token=d17b1ee7-807a-46d3-bc4c-110fbba402d5",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FAbs_Scissor_Kicks_30.mp4?alt=media&token=6da9694f-51d8-48bc-bf66-314a3d243916",
    video_id: "Abs_Scissor_Kicks_30",
    video_length: 0.05,
    video_number: "37",
    size: 382188,
    hints: "Abs_Scissor_Kicks_30_hints",
    common_mistakes: "Abs_Scissor_Kicks_30_common_mistakes",
    breathing: "Abs_Scissor_Kicks_30_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "High Boy Lawnmowers",
    is_free: false,
    primary_muscle: "Traps, Rhomboids, Erector Spinae",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rear Shoulders, Teres Major, Teres Minor, Latissimus Dorsi, Infraspinatus, Biceps",
    subcategory_name: "back",
    tertiary_muscle: "Lower Back, Middle Shoulders, Front Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_High_Boy_Lawnmowers_44.jpg?alt=media&token=c674afda-74d7-41c3-a88a-c674759cbcc0",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_High_Boy_Lawnmowers_44.mp4?alt=media&token=9eb03b0b-8dd7-4954-a0c7-e6af4e351322",
    video_id: "LnB_High_Boy_Lawnmowers_44",
    video_length: 0.07,
    video_number: "79",
    size: 512745,
    hints: "LnB_High_Boy_Lawnmowers_44_hints",
    common_mistakes: "LnB_High_Boy_Lawnmowers_44_common_mistakes",
    breathing: "LnB_High_Boy_Lawnmowers_44_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Back Lunges",
    is_free: false,
    primary_muscle: "Quads, Glutes, Hamstrings",
    rep_range: "10-20",
    secondary_muscle: "Adductors, Erector Spinae",
    subcategory_name: "legs",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_Back_Lunges_45.jpg?alt=media&token=8ed37d31-1bbe-40e4-a2e7-f5f21ce01df8",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_Back_Lunges_45.mp4?alt=media&token=adb93477-f363-41be-bb3e-3efb96bcac7e",
    video_id: "LnB_Back_Lunges_45",
    video_length: 0.07,
    video_number: "109",
    size: 453992,
    hints: "LnB_Back_Lunges_45_hints",
    common_mistakes: "LnB_Back_Lunges_45_common_mistakes",
    breathing: "LnB_Back_Lunges_45_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Hindu Push Ups",
    is_free: false,
    primary_muscle: "Chest",
    rep_range: "10-20",
    secondary_muscle:
      "Shoulders, Triceps, Core, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "chest",
    tertiary_muscle: "Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Hindu_Push_Ups_58.jpg?alt=media&token=85bf93ea-9b44-4f45-ae38-4961492f78e6",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Hindu_Push_Ups_58.mp4?alt=media&token=81673953-7166-4d79-9a40-dc5d9c1fb442",
    video_id: "CnB_Hindu_Push_Ups_58",
    video_length: 0.07,
    video_number: "10",
    size: 486771,
    hints: "CnB_Hindu_Push_Ups_58_hints",
    common_mistakes: "CnB_Hindu_Push_Ups_58_common_mistakes",
    breathing: "CnB_Hindu_Push_Ups_58_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Reverse Grip Rows",
    is_free: true,
    primary_muscle: "Latissimus Dorsi, Rhomboids, Traps, Erector Spinae",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rear Shoulders, Teres Major, Teres Minor, Infraspinatus, Biceps",
    subcategory_name: "back",
    tertiary_muscle: "Lower Back, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_Reverse_Grip_Rows_46.jpg?alt=media&token=a114d8b0-cc85-4372-b639-ee19d77da3ee",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_Reverse_Grip_Rows_46.mp4?alt=media&token=dce88f2a-18de-49d2-9124-ec87f687c955",
    video_id: "LnB_Reverse_Grip_Rows_46",
    video_length: 0.08,
    video_number: "58",
    size: 561033,
    hints: "LnB_Reverse_Grip_Rows_46_hints",
    common_mistakes: "LnB_Reverse_Grip_Rows_46_common_mistakes",
    breathing: "LnB_Reverse_Grip_Rows_46_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Clapper Push Ups",
    is_free: false,
    primary_muscle: "Middle Chest",
    rep_range: "10-20",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Triceps, Core, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Clapper_Push_Ups_59.jpg?alt=media&token=b0803ede-42cb-444b-ab37-20278407e156",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Clapper_Push_Ups_59.mp4?alt=media&token=d73fa315-a004-4ff5-8b33-04b3505a8589",
    video_id: "CnB_Clapper_Push_Ups_59",
    video_length: 0.08,
    video_number: "6",
    size: 591747,
    hints: "CnB_Clapper_Push_Ups_59_hints",
    common_mistakes: "CnB_Clapper_Push_Ups_59_common_mistakes",
    breathing: "CnB_Clapper_Push_Ups_59_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    is_other_side: 0,
    name: "Concentrated Side Raises",
    is_free: false,
    primary_muscle: "Middle Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Front Shoulders, Traps, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Rear Shoulders, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FTnS_Concentrated_Side_Raises_58.jpg?alt=media&token=a1b66605-29f8-427c-b7a2-9e926ce551b4",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FTnS_Concentrated_Side_Raises_58.mp4?alt=media&token=85bf5f2b-9278-44f4-a49d-395b8889120b",
    video_id: "TnS_Concentrated_Side_Raises_58",
    video_length: 0.05,
    video_number: "75",
    size: 368259,
    hints: "TnS_Concentrated_Side_Raises_58_hints",
    common_mistakes: "TnS_Concentrated_Side_Raises_58_common_mistakes",
    breathing: "TnS_Concentrated_Side_Raises_58_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Slow Negative Push ups",
    is_free: false,
    primary_muscle: "Middle Chest",
    rep_range: "6-12",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Triceps, Core, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Slow_Negative_Push_ups_60.jpg?alt=media&token=4e7f2818-0d0e-4753-8f7c-e07083cda9c7",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Slow_Negative_Push_ups_60.mp4?alt=media&token=4c3299f3-edc5-42a8-bfeb-5b92ca2819b5",
    video_id: "CnB_Slow_Negative_Push_ups_60",
    video_length: 0.08,
    video_number: "5(2)",
    size: 531131,
    hints: "CnB_Slow_Negative_Push_ups_60_hints",
    common_mistakes: "CnB_Slow_Negative_Push_ups_60_common_mistakes",
    breathing: "CnB_Slow_Negative_Push_ups_60_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    is_other_side: 1,
    name: "Concentrated Hammer Curls",
    is_free: false,
    primary_muscle: "Biceps, Brachialis",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Forearm",
    subcategory_name: "biceps",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(R)%20Concentrated%20Hammer%20Curls.jpg?alt=media&token=49384221-ea14-4a4b-a8cf-155bb68934d9",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fnew-videos%2F(R)%20Concentrated%20Hammer%20Curls.mp4?alt=media&token=68bc4bf4-adae-4508-ae77-3c9ccfdb9355",
    video_id: "CnB_Concentrated_Hammer_Curls_61",
    video_length: 0.07,
    video_number: "28",
    size: 484479,
    hints: "CnB_Concentrated_Hammer_Curls_61_hints",
    common_mistakes: "CnB_Concentrated_Hammer_Curls_61_common_mistakes",
    breathing: "CnB_Concentrated_Hammer_Curls_61_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Slow Decline Press",
    is_free: false,
    primary_muscle: "Upper Chest",
    rep_range: "6-12",
    secondary_muscle: "Middle Chest, Lower Chest, Shoulders, Triceps, Core, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Slow_Decline_Press_62.jpg?alt=media&token=fb73b6ec-eae4-4b1a-ae52-7505f8bbbf29",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "Chair",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Slow_Decline_Press_62.mp4?alt=media&token=189067dc-c406-4663-aa3c-cde84185cbac",
    video_id: "CnB_Slow_Decline_Press_62",
    video_length: 0.1,
    video_number: "131",
    size: 628686,
    hints: "CnB_Slow_Decline_Press_62_hints",
    common_mistakes: "CnB_Slow_Decline_Press_62_common_mistakes",
    breathing: "CnB_Slow_Decline_Press_62_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    is_three_video: 2,
    group_name: "Calves",
    name: "Calf Raises",
    is_free: false,
    primary_muscle: "Calves",
    rep_range: "15-20",
    secondary_muscle: "",
    subcategory_name: "legs",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_Calf_Raises_47.jpg?alt=media&token=2e84f140-ca02-4690-817a-6c7ef22574f9",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_Calf_Raises_47.mp4?alt=media&token=7ecd7e90-3cd3-4596-af76-7cc556524efb",
    video_id: "LnB_Calf_Raises_47",
    video_length: 0.1,
    video_number: "121",
    size: 701795,
    hints: "LnB_Calf_Raises_47_hints",
    common_mistakes: "LnB_Calf_Raises_47_common_mistakes",
    breathing: "LnB_Calf_Raises_47_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Rows",
    is_free: false,
    primary_muscle: "Latissimus Dorsi, Rhomboids, Traps, Erector Spinae",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rear Shoulders, Teres Major, Teres Minor, Infraspinatus, Biceps",
    subcategory_name: "back",
    tertiary_muscle: "Lower Back, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_Weighted_Wall_Squats_48.jpg?alt=media&token=05e7ec95-1730-4ecb-a872-4ad02bfc2c0c",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_Weighted_Wall_Squats_48.mp4?alt=media&token=11efcb29-5490-4902-a804-bd5570c83aed",
    video_id: "LnB_Weighted_Wall_Squats_48",
    video_length: 0.03,
    video_number: "61",
    size: 294015,
    hints: "LnB_Weighted_Wall_Squats_48_hints",
    common_mistakes: "LnB_Weighted_Wall_Squats_48_common_mistakes",
    breathing: "LnB_Weighted_Wall_Squats_48_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Pull Overs",
    is_free: false,
    primary_muscle: "Latissimus Dorsi",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rhomboids, Rear Shoulders, Teres Major, Teres Minor, Infraspinatus, Chest, Traps",
    subcategory_name: "back",
    tertiary_muscle: "Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_Pull_Overs_49.jpg?alt=media&token=ed337075-9d42-4d45-8bff-34fded0ead42",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_Pull_Overs_49.mp4?alt=media&token=0894933f-41df-4d6a-a69e-e753f4fe9374",
    video_id: "LnB_Pull_Overs_49",
    video_length: 0.08,
    video_number: "35(2)",
    size: 534069,
    hints: "LnB_Pull_Overs_49_hints",
    common_mistakes: "LnB_Pull_Overs_49_common_mistakes",
    breathing: "LnB_Pull_Overs_49_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Alligator Push Ups",
    is_free: false,
    primary_muscle: "Middle Chest",
    rep_range: "8-12",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Triceps, Core, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Alligator_Push_Ups_63.jpg?alt=media&token=0756227f-b90e-4303-8d0f-3f34fa7b354a",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Alligator_Push_Ups_63.mp4?alt=media&token=63fde405-f777-415a-a76c-e337e243826f",
    video_id: "CnB_Alligator_Push_Ups_63",
    video_length: 0.17,
    video_number: "10(2)",
    size: 579733,
    hints: "CnB_Alligator_Push_Ups_63_hints",
    common_mistakes: "CnB_Alligator_Push_Ups_63_common_mistakes",
    breathing: "CnB_Alligator_Push_Ups_63_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    is_other_side: 1,
    name: "One Leg Squats",
    is_free: false,
    primary_muscle: "Quads, Glutes",
    rep_range: "5-10",
    secondary_muscle: "Hamstrings, Adductors",
    subcategory_name: "legs",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_One_Leg_Squats_50.jpg?alt=media&token=9aae1c77-bc9b-42fb-864a-88fd675a1ad1",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_One_Leg_Squats_50.mp4?alt=media&token=2bdc70ee-4a60-4a26-b168-e4ecc28738fe",
    video_id: "LnB_One_Leg_Squats_50",
    video_length: 0.08,
    video_number: "116(2)",
    size: 599586,
    hints: "LnB_One_Leg_Squats_50_hints",
    common_mistakes: "LnB_One_Leg_Squats_50_common_mistakes",
    breathing: "LnB_One_Leg_Squats_50_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "In And Out Curls",
    is_free: true,
    primary_muscle: "Biceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Brachialis, Forearm",
    subcategory_name: "biceps",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_In_And_Out_Curls_64.jpg?alt=media&token=2fd30688-1f46-4caf-9895-0d4a9698d30c",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_In_And_Out_Curls_64.mp4?alt=media&token=21942790-fcef-4f40-918a-2013a9844ec3",
    video_id: "CnB_In_And_Out_Curls_64",
    video_length: 0.05,
    video_number: "37",
    size: 654645,
    hints: "CnB_In_And_Out_Curls_64_hints",
    common_mistakes: "CnB_In_And_Out_Curls_64_common_mistakes",
    breathing: "CnB_In_And_Out_Curls_64_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Front Squats",
    is_free: true,
    primary_muscle: "Quads, Glutes",
    rep_range: "15-20",
    secondary_muscle: "Hamstrings Adductors, Erector Spinae",
    subcategory_name: "legs",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_Front_Squats_51.jpg?alt=media&token=629a689a-4aa1-422d-b4a5-b90a8cf8b807",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_Front_Squats_51.mp4?alt=media&token=83835955-4a9c-4563-a40b-777c598ca974",
    video_id: "LnB_Front_Squats_51",
    video_length: 0.08,
    video_number: "108",
    size: 619560,
    hints: "LnB_Front_Squats_51_hints",
    common_mistakes: "LnB_Front_Squats_51_common_mistakes",
    breathing: "LnB_Front_Squats_51_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Hammer Curls",
    is_free: true,
    primary_muscle: "Biceps, Brachialis",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Forearm",
    subcategory_name: "biceps",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Hammer_Curls_65.jpg?alt=media&token=395aeda6-4e67-433a-986b-1fbbb31e08b9",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Hammer_Curls_65.mp4?alt=media&token=40163035-baaf-4907-898e-a50d563899fe",
    video_id: "CnB_Hammer_Curls_65",
    video_length: 0.08,
    video_number: "49",
    size: 524468,
    hints: "CnB_Hammer_Curls_65_hints",
    common_mistakes: "CnB_Hammer_Curls_65_common_mistakes",
    breathing: "CnB_Hammer_Curls_65_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Diamond Hold Push Ups",
    is_free: false,
    primary_muscle: "Middle Chest",
    rep_range: "6-12",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Triceps, Core, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Diamond_Hold_Push_Ups_66.jpg?alt=media&token=0f4056fc-9904-43ed-9db8-9017548215c5",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Diamond_Hold_Push_Ups_66.mp4?alt=media&token=75effbdd-8d1a-4bb4-8a18-39520dde10dd",
    video_id: "CnB_Diamond_Hold_Push_Ups_66",
    video_length: 0.08,
    video_number: "24",
    size: 584797,
    hints: "CnB_Diamond_Hold_Push_Ups_66_hints",
    common_mistakes: "CnB_Diamond_Hold_Push_Ups_66_common_mistakes",
    breathing: "CnB_Diamond_Hold_Push_Ups_66_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Banana To Boat",
    is_free: false,
    primary_muscle: "Abs",
    rep_range: "Time Based",
    secondary_muscle: "Obliques, Hip Flexors",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FAbs_BananaToBoat_31.jpg?alt=media&token=c5af7a2c-a465-4858-baac-618745539b1e",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FAbs_BananaToBoat_31.mp4?alt=media&token=690ddf09-e17e-4481-a26c-93f64af583b4",
    video_id: "Abs_BananaToBoat_31",
    video_length: 0.05,
    video_number: "54",
    size: 380302,
    hints: "Abs_BananaToBoat_31_hints",
    common_mistakes: "Abs_BananaToBoat_31_common_mistakes",
    breathing: "Abs_BananaToBoat_31_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "High Boy Alternating Rows",
    is_free: false,
    primary_muscle: "Traps, Rhomboids, Erector Spinae",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rear Shoulders, Teres Major, Teres Minor, Latissimus Dorsi, Infraspinatus, Biceps",
    subcategory_name: "back",
    tertiary_muscle: "Lower Back, Middle Shoulders, Front Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_High_Boy_Rows_52.jpg?alt=media&token=6313d743-229d-44cf-a23f-0fa8e070d052",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_High_Boy_Rows_52.mp4?alt=media&token=a566aacf-8a58-4c63-ab21-1ad9ada9f1f5",
    video_id: "LnB_High_Boy_Rows_52",
    video_length: 0.08,
    video_number: "91",
    size: 544040,
    hints: "LnB_High_Boy_Rows_52_hints",
    common_mistakes: "LnB_High_Boy_Rows_52_common_mistakes",
    breathing: "LnB_High_Boy_Rows_52_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Decline Diamond Push Ups",
    is_free: false,
    primary_muscle: "Middle Chest",
    rep_range: "10-20",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Triceps, Core, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Decline_Diamond_Push_Ups_67.jpg?alt=media&token=ab53a40c-d6d4-4ab4-896b-426bb39e0e38",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "Chair",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Decline_Diamond_Push_Ups_67.mp4?alt=media&token=abf41aaf-9d89-404a-b83b-243eae9c577b",
    video_id: "CnB_Decline_Diamond_Push_Ups_67",
    video_length: 0.1,
    video_number: "25",
    size: 386190,
    hints: "CnB_Decline_Diamond_Push_Ups_67_hints",
    common_mistakes: "CnB_Decline_Diamond_Push_Ups_67_common_mistakes",
    breathing: "CnB_Decline_Diamond_Push_Ups_67_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Knuckle Decline Push Ups",
    is_free: false,
    primary_muscle: "Upper Chest",
    rep_range: "10-20",
    secondary_muscle: "Middle Chest, Lower Chest, Shoulders, Triceps, Core, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Knuckle_Decline_Push_Ups_68.jpg?alt=media&token=758a9389-8339-43d9-afcd-99a4e7b843e9",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "Chair",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Knuckle_Decline_Push_Ups_68.mp4?alt=media&token=2f26365d-4826-4144-b9d2-d1a8c85c3194",
    video_id: "CnB_Knuckle_Decline_Push_Ups_68",
    video_length: 0.05,
    video_number: "138",
    size: 327709,
    hints: "CnB_Knuckle_Decline_Push_Ups_68_hints",
    common_mistakes: "CnB_Knuckle_Decline_Push_Ups_68_common_mistakes",
    breathing: "CnB_Knuckle_Decline_Push_Ups_68_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Tate Press",
    is_free: false,
    primary_muscle: "Triceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Forearm, Shoulders",
    subcategory_name: "triceps",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FTnS_Tate_Press_59.jpg?alt=media&token=f7f2246e-df09-4389-966b-b27b8fe1be09",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FTnS_Tate_Press_59.mp4?alt=media&token=c526e066-a46b-4c4d-b2f4-091e7f345523",
    video_id: "TnS_Tate_Press_59",
    video_length: 0.05,
    video_number: "39",
    size: 361366,
    hints: "TnS_Tate_Press_59_hints",
    common_mistakes: "TnS_Tate_Press_59_common_mistakes",
    breathing: "TnS_Tate_Press_59_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Reverse Grip Push Ups",
    is_free: true,
    primary_muscle: "Middle Chest",
    rep_range: "10-20",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Triceps, Core, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Reverse_Grip_Push_Ups_69.jpg?alt=media&token=c28db002-59c1-4811-b6d9-29238874e41f",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Reverse_Grip_Push_Ups_69.mp4?alt=media&token=48aa440f-175d-4a3e-b709-799ff39753d6",
    video_id: "CnB_Reverse_Grip_Push_Ups_69",
    video_length: 0.08,
    video_number: "6(2)",
    size: 599325,
    hints: "CnB_Reverse_Grip_Push_Ups_69_hints",
    common_mistakes: "CnB_Reverse_Grip_Push_Ups_69_common_mistakes",
    breathing: "CnB_Reverse_Grip_Push_Ups_69_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Shrugs",
    is_free: false,
    primary_muscle: "Traps, Rhomboids",
    rep_range: "Time Based",
    secondary_muscle: "Rear Shoulders, Levator Scapulae",
    subcategory_name: "back",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_Shrugs_53.jpg?alt=media&token=a22aef98-04b4-4220-a8bb-951882c113cb",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_Shrugs_53.mp4?alt=media&token=42b0c3a1-94e2-4fec-9c75-043329f33603",
    video_id: "LnB_Shrugs_53",
    video_length: 0.08,
    video_number: "68",
    size: 622698,
    hints: "LnB_Shrugs_53_hints",
    common_mistakes: "LnB_Shrugs_53_common_mistakes",
    breathing: "LnB_Shrugs_53_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Static Curls",
    is_free: false,
    primary_muscle: "Biceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Brachialis, Forearm",
    subcategory_name: "biceps",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Static_Curls_70.jpg?alt=media&token=43b36242-3697-4ab1-93fe-530db0dcf173",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Static_Curls_70.mp4?alt=media&token=456a4702-03bf-4c17-a925-d3ed91f59a87",
    video_id: "CnB_Static_Curls_70",
    video_length: 0.1,
    video_number: "70",
    size: 678622,
    hints: "CnB_Static_Curls_70_hints",
    common_mistakes: "CnB_Static_Curls_70_common_mistakes",
    breathing: "CnB_Static_Curls_70_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Pour Flys",
    is_free: false,
    primary_muscle: "Front Shoulders, Middle Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Traps, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Rear Shoulders, Latissimus Dorsi, Chest",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FTnS_Pour_Flys_60.jpg?alt=media&token=6e11799f-216a-46bf-8ee9-670f70b55545",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FTnS_Pour_Flys_60.mp4?alt=media&token=7c049178-35c4-45b7-bd3b-712d39d44013",
    video_id: "TnS_Pour_Flys_60",
    video_length: 0.07,
    video_number: "73",
    size: 462288,
    hints: "TnS_Pour_Flys_60_hints",
    common_mistakes: "TnS_Pour_Flys_60_common_mistakes",
    breathing: "TnS_Pour_Flys_60_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Alternating Curls",
    is_free: true,
    primary_muscle: "Biceps (Short Head)",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Biceps (Long Head), Brachialis, Forearm",
    subcategory_name: "biceps",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Alternating_Curls_72.jpg?alt=media&token=2021de0e-65f8-47b6-87f0-ad1c1c9f44a7",
    time: "75 seconds",
    time_to_play: 1.25,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Alternating_Curls_71.mp4?alt=media&token=a3c777cc-0825-4a00-a589-97c9750cca89",
    video_id: "CnB_Alternating_Curls_71",
    video_length: 0.05,
    video_number: "38",
    size: 418646,
    hints: "CnB_Alternating_Curls_71_hints",
    common_mistakes: "CnB_Alternating_Curls_71_common_mistakes",
    breathing: "CnB_Alternating_Curls_71_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Alternating Lunge",
    is_free: false,
    primary_muscle: "Quads, Hamstrings, Glutes",
    rep_range: "10-16",
    secondary_muscle: "Adductors",
    subcategory_name: "legs",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_Alternating_Lunge_54.jpg?alt=media&token=f99d2171-6e77-4130-bbfa-0ab99cee2b10",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_Alternating_Lunge_54.mp4?alt=media&token=1aefb547-f5c2-46b7-af68-38193d208d72",
    video_id: "LnB_Alternating_Lunge_54",
    video_length: 0.08,
    video_number: "119",
    size: 568467,
    hints: "LnB_Alternating_Lunge_54_hints",
    common_mistakes: "LnB_Alternating_Lunge_54_common_mistakes",
    breathing: "LnB_Alternating_Lunge_54_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    is_other_side: 0,
    name: "Seated Single Tricep Extensions",
    is_free: false,
    primary_muscle: "Triceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Forearm, Shoulders",
    subcategory_name: "triceps",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FTnS_Seated_Single_Tricep_Extensions_61.jpg?alt=media&token=42a24da2-0841-452c-a7fb-de8b9b551656",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FTnS_Seated_Single_Tricep_Extensions_61.mp4?alt=media&token=dac01862-3ae9-4ac6-93f0-7ab54354d334",
    video_id: "TnS_Seated_Single_Tricep_Extensions_61",
    video_length: 0.07,
    video_number: "11",
    size: 478427,
    hints: "TnS_Seated_Single_Tricep_Extensions_61_hints",
    common_mistakes: "TnS_Seated_Single_Tricep_Extensions_61_common_mistakes",
    breathing: "TnS_Seated_Single_Tricep_Extensions_61_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Weighted Wall Squats",
    is_free: false,
    primary_muscle: "Quads, Glutes",
    rep_range: "Time Based",
    secondary_muscle: "Hamstrings, Adductors",
    subcategory_name: "legs",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FLnB_Weighted_Wall_Squats_55.jpg?alt=media&token=6ad72d64-7ad7-4ec5-aa78-d99820898808",
    time: "60 seconds",
    time_to_play: 0.75,
    tools: "Dumbbells Wall",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FLnB_Weighted_Wall_Squats_55.mp4?alt=media&token=c0ab4fd9-d1cd-47b6-b7c2-27cde50b320b",
    video_id: "LnB_Weighted_Wall_Squats_55",
    video_length: 0.08,
    video_number: "129",
    size: 519888,
    hints: "LnB_Weighted_Wall_Squats_55_hints",
    common_mistakes: "LnB_Weighted_Wall_Squats_55_common_mistakes",
    breathing: "LnB_Weighted_Wall_Squats_55_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Circle Reverse Decline Push Ups",
    is_free: true,
    primary_muscle: "Upper Chest",
    rep_range: "8-20",
    secondary_muscle: "Middle Chest, Lower Chest, Shoulders, Triceps, Core, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Circle_Reverse_Decline_Push_Ups_72.jpg?alt=media&token=061a9272-d406-4955-a87b-c2c2e470540c",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "Chair",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Circle_Reverse_Decline_Push_Ups_72.mp4?alt=media&token=2a6781a6-84d9-4ef6-8306-b48151c549b8",
    video_id: "CnB_Circle_Reverse_Decline_Push_Ups_72",
    video_length: 0.1,
    video_number: "136",
    size: 705545,
    hints: "CnB_Circle_Reverse_Decline_Push_Ups_72_hints",
    common_mistakes: "CnB_Circle_Reverse_Decline_Push_Ups_72_common_mistakes",
    breathing: "CnB_Circle_Reverse_Decline_Push_Ups_72_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Seated Alternating Arnold Presses",
    is_free: false,
    primary_muscle: "Front Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Chest, Traps, Middle Shoulders, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Rear Shoulders, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSeated%20Alternating%20Arnold%20Presses.jpg?alt=media&token=19ebbbed-3b4b-4bb4-9a65-b390688f8990",
    time: "75 seconds",
    time_to_play: 1.25,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fnew-videos%2FSeated%20Alternating%20Arnold%20Presses.mp4?alt=media&token=d0e764ab-e5e5-4ebf-8662-7285ceddaf6b",
    video_id: "TnS_Seated_Alternating_Arnold_Presses_62",
    video_length: 0.07,
    video_number: "67",
    size: 486713,
    hints: "TnS_Seated_Alternating_Arnold_Presses_62_hints",
    common_mistakes: "TnS_Seated_Alternating_Arnold_Presses_62_common_mistakes",
    breathing: "TnS_Seated_Alternating_Arnold_Presses_62_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Alternating Hammer Curls",
    is_free: false,
    primary_muscle: "Biceps, Brachialis",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Forearm",
    subcategory_name: "biceps",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FCnB_Alternating_Hammer_Curls_73.jpg?alt=media&token=73418f22-d9bf-4d28-b537-3dcb78772d7a",
    time: "75 seconds",
    time_to_play: 1.25,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Alternating_Hammer_Curls_73.mp4?alt=media&token=7a97f9ab-81be-411a-b653-78a5ae402d19",
    video_id: "CnB_Alternating_Hammer_Curls_73",
    video_length: 0.1,
    video_number: "75",
    size: 630109,
    hints: "CnB_Alternating_Hammer_Curls_73_hints",
    common_mistakes: "CnB_Alternating_Hammer_Curls_73_common_mistakes",
    breathing: "CnB_Alternating_Hammer_Curls_73_breathing",
  },
  {
    category_id: 5,
    category_name: "total body",
    name: "Bicep & Hammer Curls Into Squats",
    is_free: true,
    primary_muscle: "Biceps, Brachialis, Quads, Glutes",
    rep_range: "5-10",
    secondary_muscle: "Forearm, Hamstrings, Adductors, Erector Spinae",
    subcategory_name: "total body",
    tertiary_muscle: "Shoulders, Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FBicep%20%26%20Hammer%20Curls%20Into%20Squats.jpg?alt=media&token=a3df20ec-ad6d-4bea-a463-bb14e8a85625",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FBicep%20%26%20Hammer%20Curls%20Into%20Squats.mp4?alt=media&token=9613e9ec-a193-4bd1-8741-4b277a69c061",
    video_id: "CnB_Bicep_Hammer_Curls_Into_Squats",
    video_length: 0.1,
    video_number: "144",
    size: 3393107,
    hints: "CnB_Bicep_Hammer_Curls_Into_Squats_hints",
    common_mistakes: "CnB_Bicep_Hammer_Curls_Into_Squats_common_mistakes",
    breathing: "CnB_Bicep_Hammer_Curls_Into_Squats_breathing",
  },
  {
    category_id: 5,
    category_name: "total body",
    is_other_side: 0,
    name: "Row Into Fly",
    is_free: false,
    primary_muscle: "Latissimus Dorsi, Rhomboids, Traps, Erector Spinae, Upper Chest, Middle Chest, Front Shoulders",
    rep_range: "5-10",
    secondary_muscle: "Rear Shoulders, Teres Major, Teres Minor, Infraspinatus, Biceps, Lower Chest, Serratus Anterior, Core",
    subcategory_name: "total body",
    tertiary_muscle: "Lower Back, Lower Body, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(L)%20Row%20Into%20Fly-0.jpg?alt=media&token=50c0cea5-6ab1-453e-b810-d085d70facd3",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2F(L)%20Row%20Into%20Fly.mp4?alt=media&token=24ed3708-91a0-4e98-af42-a985079c13de",
    video_id: "CnB_Row_Into_Fly_1",
    video_length: 0.1,
    video_number: "145",
    size: 1274355,
    hints: "CnB_Row_Into_Fly_1_hints",
    common_mistakes: "CnB_Row_Into_Fly_1_common_mistakes",
    breathing: "CnB_Row_Into_Fly_1_breathing",
  },
  {
    category_id: 5,
    category_name: "total body",
    is_other_side: 1,
    name: "Row Into Fly",
    is_free: false,
    primary_muscle: "Latissimus Dorsi, Rhomboids, Traps, Erector Spinae, Upper Chest, Middle Chest, Front Shoulders",
    rep_range: "5-10",
    secondary_muscle: "Rear Shoulders, Teres Major, Teres Minor, Infraspinatus, Biceps, Lower Chest, Serratus Anterior, Core",
    subcategory_name: "total body",
    tertiary_muscle: "Lower Back, Lower Body, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(R)%20Row%20Into%20Fly-0.jpg?alt=media&token=b7779bb3-d8d1-436b-a4c6-38041f82b64b",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2F(R)%20Row%20Into%20Fly.mp4?alt=media&token=02a8e51b-a799-4700-be1d-a24d55a15dc4",
    video_id: "CnB_Row_Into_Fly_2",
    video_length: 0.1,
    video_number: "146",
    size: 1411598,
    hints: "CnB_Row_Into_Fly_2_hints",
    common_mistakes: "CnB_Row_Into_Fly_2_common_mistakes",
    breathing: "CnB_Row_Into_Fly_2_breathing",
  },
  {
    category_id: 5,
    category_name: "total body",
    is_other_side: 0,
    name: "Row Into Tricep Extension",
    is_free: false,
    primary_muscle: "Latissimus Dorsi, Rhomboids, Traps, Erector Spinae, Triceps",
    rep_range: "5-10",
    secondary_muscle: "Rear Shoulders, Teres Major, Teres Minor, Infraspinatus, Biceps",
    subcategory_name: "total body",
    tertiary_muscle: "Lower Back, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(L)%20Row%20Into%20Tricep%20Extension-0.jpg?alt=media&token=a985c1df-363a-4728-874d-c4e5b7ba79af",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2F(L)%20Row%20Into%20Tricep%20Extension.mp4?alt=media&token=bd819f37-4466-438a-a4f5-bdc671178d9f",
    video_id: "CnB_Row_Into_Tricep_Extension_1",
    video_length: 0.1,
    video_number: "147",
    size: 1755085,
    hints: "CnB_Row_Into_Tricep_Extension_1_hints",
    common_mistakes: "CnB_Row_Into_Tricep_Extension_1_common_mistakes",
    breathing: "CnB_Row_Into_Tricep_Extension_1_breathing",
  },
  {
    category_id: 5,
    category_name: "total body",
    is_other_side: 1,
    name: "Row Into Tricep Extension",
    is_free: false,
    primary_muscle: "Latissimus Dorsi, Rhomboids, Traps, Erector Spinae, Triceps",
    rep_range: "5-10",
    secondary_muscle: "Rear Shoulders, Teres Major, Teres Minor, Infraspinatus, Biceps",
    subcategory_name: "total body",
    tertiary_muscle: "Lower Back, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(R)%20Row%20Into%20Tricep%20Extension-0.jpg?alt=media&token=e343c5b6-dc2c-4bbe-87a8-5db2f3eca584",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2F(R)%20Row%20Into%20Tricep%20Extension.mp4?alt=media&token=7f8dd414-5bcd-496c-aead-75f53237be09",
    video_id: "CnB_Row_Into_Tricep_Extension_2",
    video_length: 0.1,
    video_number: "148",
    size: 1730053,
    hints: "CnB_Row_Into_Tricep_Extension_2_hints",
    common_mistakes: "CnB_Row_Into_Tricep_Extension_2_common_mistakes",
    breathing: "CnB_Row_Into_Tricep_Extension_2_breathing",
  },
  {
    category_id: 5,
    category_name: "total body",
    name: "RDL-Row & Flys",
    is_free: false,
    primary_muscle: "Latissimus Dorsi, Rhomboids, Traps, Erector Spinae, Quads, Glutes",
    rep_range: "5-10",
    secondary_muscle:
      "Rear Shoulders, Teres Major, Teres Minor, Infraspinatus, Biceps, Hamstrings, Adductors, Levator Scapulae, Lower Back",
    subcategory_name: "total body",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FRDL-Row%20%26%20Flys-0.jpg?alt=media&token=4cba51d7-7a0d-43bc-8d45-b3dd5e03b63e",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FRDL-Row%20%26%20Flys.mp4?alt=media&token=62671cd6-1c29-443e-9982-bc2d2c1ee2c2",
    video_id: "CnB_RDL-Row_Flys_2",
    video_length: 0.1,
    video_number: "149",
    size: 2063537,
    hints: "CnB_RDL-Row_Flys_2_hints",
    common_mistakes: "CnB_RDL-Row_Flys_2_common_mistakes",
    breathing: "CnB_RDL-Row_Flys_2_breathing",
  },
  {
    category_id: 5,
    category_name: "total body",
    is_other_side: 0,
    name: "Lat Raise Into Chest Fly",
    is_free: false,
    primary_muscle: "Middle Shoulders, Upper Chest, Middle Chest, Front Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rear Shoulders, Traps, Serratus Anterior, Core",
    subcategory_name: "total body",
    tertiary_muscle: "Latissimus Dorsi, Lower Chest, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(L)%20Lat%20Raise%20Into%20Chest%20Fly-0.jpg?alt=media&token=2cc602c7-ca9d-453a-a1b2-3d62c929aa25",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2F(L)%20Lat%20Raise%20Into%20Chest%20Fly.mp4?alt=media&token=c167ede9-f9dd-4b15-b5a7-63fb8fed8b1b",
    video_id: "CnB_Lat_Raise_Into_Chest_Fly_1",
    video_length: 0.1,
    video_number: "150",
    size: 1731138,
    hints: "CnB_Lat_Raise_Into_Chest_Fly_1_hints",
    common_mistakes: "CnB_Lat_Raise_Into_Chest_Fly_1_common_mistakes",
    breathing: "CnB_Lat_Raise_Into_Chest_Fly_1_breathing",
  },
  {
    category_id: 5,
    category_name: "total body",
    is_other_side: 1,
    name: "Lat Raise Into Chest Fly",
    is_free: false,
    primary_muscle: "Middle Shoulders, Upper Chest, Middle Chest, Front Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rear Shoulders, Traps, Serratus Anterior, Core",
    subcategory_name: "total body",
    tertiary_muscle: "Latissimus Dorsi, Lower Chest, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(R)%20Lat%20Raise%20Into%20Chest%20Fly-0.jpg?alt=media&token=4b18e9c6-1e0f-49e7-bbcd-4c29060ffb81",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2F(R)%20Lat%20Raise%20Into%20Chest%20Fly.mp4?alt=media&token=1f1c0d00-23de-440b-8514-497e159ebab1",
    video_id: "CnB_Lat_Raise_Into_Chest_Fly_2",
    video_length: 0.1,
    video_number: "151",
    size: 1720809,
    hints: "CnB_Lat_Raise_Into_Chest_Fly_2_hints",
    common_mistakes: "CnB_Lat_Raise_Into_Chest_Fly_2_common_mistakes",
    breathing: "CnB_Lat_Raise_Into_Chest_Fly_2_breathing",
  },
  {
    category_id: 5,
    category_name: "total body",
    name: "Back Fly Into Double Curl",
    is_free: false,
    primary_muscle: "Traps, Rhomboids, Rear Shoulders, Biceps",
    rep_range: "5-10",
    secondary_muscle: "Teres Major, Teres Minor, Infraspinatus, Brachialis, Forearm",
    subcategory_name: "total body",
    tertiary_muscle: "Latissimus Dorsi, Front Shoulder, Middle Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FBack%20Fly%20Into%20Double%20Curl-0.jpg?alt=media&token=c131a2db-c5ae-4d56-a73e-a5fade824eb6",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FBack%20Fly%20Into%20Double%20Curl.mp4?alt=media&token=04352e08-d7f4-4524-94d8-3cfeb2be551d",
    video_id: "CnB_Back_Fly_Into_Double_Curl_1",
    video_length: 0.1,
    video_number: "152",
    size: 3585334,
    hints: "CnB_Back_Fly_Into_Double_Curl_1_hints",
    common_mistakes: "CnB_Back_Fly_Into_Double_Curl_1_common_mistakes",
    breathing: "CnB_Back_Fly_Into_Double_Curl_1_breathing",
  },
  {
    category_id: 5,
    category_name: "forearms",
    name: "180 Degree Rotations",
    is_free: true,
    primary_muscle: "Forearm",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Shoulders",
    subcategory_name: "forearms",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F180%20Degree%20Rotations-0.jpg?alt=media&token=d28bf801-eeb4-4e14-8711-3fa4c26f4f9a",
    time: "45 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2F180%20Degree%20Rotations.mp4?alt=media&token=0714bbbd-01cb-41e9-a1ba-e8630a7f26b0",
    video_id: "CnB_180_Degree_Rotations_1",
    video_length: 0.1,
    video_number: "154",
    size: 1245684,
    hints: "CnB_180_Degree_Rotations_1_hints",
    common_mistakes: "CnB_180_Degree_Rotations_1_common_mistakes",
    breathing: "CnB_180_Degree_Rotations_1_breathing",
  },
  {
    category_id: 5,
    category_name: "total body",
    name: "Bicep Curl Into Back Row",
    is_free: false,
    primary_muscle: "Biceps, Latissimus Dorsi, Rhomboids, Traps, Erector Spinae",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Brachialis, Forearm, Rear Shoulders, Teres Major, Teres Minor, Infraspinatus",
    subcategory_name: "total body",
    tertiary_muscle: "Front Shoulders, Middle Shoulders, Lower Body, Lower Back",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FBicep%20Curl%20Into%20Back%20Row-0.jpg?alt=media&token=7d960805-5cbd-4a2b-97bf-862de8f55591",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FBicep%20Curl%20Into%20Back%20Row.mp4?alt=media&token=e22dbf9a-fbaf-419d-be0f-251320c982c1",
    video_id: "CnB_Bicep_Curl_Into_Back_Row_1",
    video_length: 0.1,
    video_number: "155",
    size: 2176708,
    hints: "CnB_Bicep_Curl_Into_Back_Row_1_hints",
    common_mistakes: "CnB_Bicep_Curl_Into_Back_Row_1_common_mistakes",
    breathing: "CnB_Bicep_Curl_Into_Back_Row_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    is_other_side: 0,
    name: "Single Curl In & Out",
    is_free: false,
    primary_muscle: "Biceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Brachialis, Forearm",
    subcategory_name: "biceps",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(L)%20Single%20Curl%20In%20%26%20Out-0.jpg?alt=media&token=085c580f-53bb-48de-8046-eea7aeabc715",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fbiceps%2F(L)%20Single%20Curl%20In%20%26%20Out.mp4?alt=media&token=e8904211-8072-4d5d-ab8a-75e10a3b2cce",
    video_id: "CnB_Single_Curl_In_Out_1",
    video_length: 0.1,
    video_number: "156",
    size: 2079491,
    hints: "CnB_Single_Curl_In_Out_1_hints",
    common_mistakes: "CnB_Single_Curl_In_Out_1_common_mistakes",
    breathing: "CnB_Single_Curl_In_Out_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    is_other_side: 1,
    name: "Single Curl In & Out",
    is_free: false,
    primary_muscle: "Biceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Brachialis, Forearm",
    subcategory_name: "biceps",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(R)%20Single%20Curl%20In%20%26%20Out-0.jpg?alt=media&token=5ff020d2-f2c8-40b9-b215-078e456cc985",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fbiceps%2F(R)%20Single%20Curl%20In%20%26%20Out.mp4?alt=media&token=7fc9aa74-9fda-4927-b3c9-41e03f58be39",
    video_id: "CnB_Single_Curl_In_Out_2",
    video_length: 0.1,
    video_number: "157",
    size: 2156894,
    hints: "CnB_Single_Curl_In_Out_2_hints",
    common_mistakes: "CnB_Single_Curl_In_Out_2_common_mistakes",
    breathing: "CnB_Single_Curl_In_Out_2_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Curl Up Reverse Curl Down",
    is_free: false,
    primary_muscle: "Biceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Brachialis, Forearm",
    subcategory_name: "biceps",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FCurl%20Up%20Reverse%20Curl%20Down-0.jpg?alt=media&token=4b64dc0c-7597-47ed-92c1-f3e055fbb56a",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fbiceps%2FCurl%20Up%20Reverse%20Curl%20Down.mp4?alt=media&token=6b9e9177-31fb-445d-83f9-97eaf8df13b9",
    video_id: "CnB_Curl_Up_Reverse_Curl_Down_1",
    video_length: 0.1,
    video_number: "158",
    size: 1506096,
    hints: "CnB_Curl_Up_Reverse_Curl_Down_1_hints",
    common_mistakes: "CnB_Curl_Up_Reverse_Curl_Down_1_common_mistakes",
    breathing: "CnB_Curl_Up_Reverse_Curl_Down_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Double Bicep Curl 3 Second Negative",
    is_free: true,
    primary_muscle: "Biceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Brachialis",
    subcategory_name: "biceps",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FDouble%20Bicep%20Curl%203%20Second%20Negative-0.jpg?alt=media&token=7b798a95-908d-46fb-8b08-640b6b89a648",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fbiceps%2FDouble%20Bicep%20Curl%203%20Second%20Negative.mp4?alt=media&token=ce7b0464-07cf-4f9c-b8db-8c0571b9689b",
    video_id: "CnB_Double_Bicep_Curl_3_Second_Negative_1",
    video_length: 0.1,
    video_number: "159",
    size: 1673677,
    hints: "CnB_Double_Bicep_Curl_3_Second_Negative_1_hints",
    common_mistakes: "CnB_Double_Bicep_Curl_3_Second_Negative_1_common_mistakes",
    breathing: "CnB_Double_Bicep_Curl_3_Second_Negative_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Hammer Curl Into Regular Curl",
    is_free: false,
    primary_muscle: "Biceps, Brachialis",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Forearm",
    subcategory_name: "biceps",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FHammer%20Curl%20Into%20Regular%20Curl-0.jpg?alt=media&token=b0c625f6-fddb-4b4f-8b57-30272c890c4f",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fbiceps%2FHammer%20Curl%20Into%20Regular%20Curl.mp4?alt=media&token=8605f9bc-7d84-4f91-9b92-3859861c4cd1",
    video_id: "CnB_Hammer_Curl_Into_Regular_Curl_1",
    video_length: 0.1,
    video_number: "160",
    size: 1862319,
    hints: "CnB_Hammer_Curl_Into_Regular_Curl_1_hints",
    common_mistakes: "CnB_Hammer_Curl_Into_Regular_Curl_1_common_mistakes",
    breathing: "CnB_Hammer_Curl_Into_Regular_Curl_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Wide Curl Into Hammer Curl",
    is_free: true,
    primary_muscle: "Biceps, Brachialis",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Forearm",
    subcategory_name: "biceps",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FWide%20Curl%20Into%20Hammer%20Curl-0.jpg?alt=media&token=3c4b096f-c902-48a9-b107-9cb9632d94bf",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fbiceps%2FWide%20Curl%20Into%20Hammer%20Curl.mp4?alt=media&token=d0e367b6-4c32-4ec7-9a62-74463b703043",
    video_id: "CnB_Wide_Curl_Into_Hammer_Cur_1",
    video_length: 0.1,
    video_number: "161",
    size: 2454863,
    hints: "CnB_Wide_Curl_Into_Hammer_Cur_1_hints",
    common_mistakes: "CnB_Wide_Curl_Into_Hammer_Cur_1_common_mistakes",
    breathing: "CnB_Wide_Curl_Into_Hammer_Cur_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Alternating Curl Hold",
    is_free: false,
    primary_muscle: "Biceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Brachialis, Forearm",
    subcategory_name: "biceps",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FAlternating%20Curl%20Hold-0.jpg?alt=media&token=10023ed9-a015-4e75-b8ad-10b7f6869352",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fbiceps%2FAlternating%20Curl%20Hold.mp4?alt=media&token=18d59dad-0557-4da6-b24c-816ea5cee043",
    video_id: "CnB_Alternating_Curl_Hold_1",
    video_length: 0.1,
    video_number: "162",
    size: 1393310,
    hints: "CnB_Alternating_Curl_Hold_1_hints",
    common_mistakes: "CnB_Alternating_Curl_Hold_1_common_mistakes",
    breathing: "CnB_Alternating_Curl_Hold_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Single Hammer Curl Into Double",
    is_free: false,
    primary_muscle: "Biceps, Brachialis",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Forearm",
    subcategory_name: "biceps",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSingle%20Hammer%20Curl%20Into%20Double-0.jpg?alt=media&token=a524d83f-a17b-4b50-b649-099dc661f563",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fbiceps%2FSingle%20Hammer%20Curl%20Into%20Double.mp4?alt=media&token=7deecd0b-01f5-46e5-a9cb-91df48728107",
    video_id: "CnB_Single_Hammer_Curl_Into_Double_1",
    video_length: 0.1,
    video_number: "163",
    size: 1776458,
    hints: "CnB_Single_Hammer_Curl_Into_Double_1_hints",
    common_mistakes: "CnB_Single_Hammer_Curl_Into_Double_1_common_mistakes",
    breathing: "CnB_Single_Hammer_Curl_Into_Double_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Inward Curl",
    is_free: false,
    primary_muscle: "Biceps, Brachialis",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Forearm",
    subcategory_name: "biceps",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FInward%20Curl-0.jpg?alt=media&token=1c7d62c9-c105-42db-aabb-cf7b76b1d050",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fbiceps%2FInward%20Curl.mp4?alt=media&token=de8ba496-74c3-4bd8-8915-addadf347ce3",
    video_id: "CnB_Inward_Curl_1",
    video_length: 0.1,
    video_number: "164",
    size: 1525219,
    hints: "CnB_Inward_Curl_1_hints",
    common_mistakes: "CnB_Inward_Curl_1_common_mistakes",
    breathing: "CnB_Inward_Curl_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Rotating Curl",
    is_free: false,
    primary_muscle: "Biceps, Brachialis",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Forearm",
    subcategory_name: "biceps",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FRotating%20Curl-0.jpg?alt=media&token=f00ff01e-f1a3-4bbb-990c-101d69d1045a",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fbiceps%2FRotating%20Curl.mp4?alt=media&token=e9cf217a-b791-44fd-b97d-8dfe005ebbb6",
    video_id: "CnB_Rotating_Curl_1",
    video_length: 0.1,
    video_number: "165",
    size: 2029743,
    hints: "CnB_Rotating_Curl_1_hints",
    common_mistakes: "CnB_Rotating_Curl_1_common_mistakes",
    breathing: "CnB_Rotating_Curl_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Switch Curls",
    is_free: false,
    primary_muscle: "Biceps, Brachialis",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Forearm",
    subcategory_name: "biceps",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSwitch%20Curls-0.jpg?alt=media&token=cf6375fe-0a70-4fc5-9e46-a1f1974c3e14",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fbiceps%2FSwitch%20Curls.mp4?alt=media&token=1c06a98f-d481-48ee-9941-f01512bd4de4",
    video_id: "CnB_Switch_Curls_1",
    video_length: 0.1,
    video_number: "166",
    size: 1780754,
    hints: "CnB_Switch_Curls_1_hints",
    common_mistakes: "CnB_Switch_Curls_1_common_mistakes",
    breathing: "CnB_Switch_Curls_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Waiter's Curl",
    is_free: false,
    primary_muscle: "Biceps, Brachialis",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Forearm",
    subcategory_name: "biceps",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FWaiter's%20Curl-0.jpg?alt=media&token=446acf72-510a-47a3-af07-119d665d2e4e",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fbiceps%2FWaiter's%20Curl.mp4?alt=media&token=17245b43-566c-4518-ba0f-73b7e570e830",
    video_id: "CnB_Waiters_Curl_1",
    video_length: 0.1,
    video_number: "167",
    size: 1907120,
    hints: "CnB_Waiters_Curl_1_hints",
    common_mistakes: "CnB_Waiters_Curl_1_common_mistakes",
    breathing: "CnB_Waiters_Curl_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Switching Curls",
    is_free: false,
    primary_muscle: "Biceps, Brachialis",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Forearm",
    subcategory_name: "biceps",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSwitching%20Curls-0.jpg?alt=media&token=2bea19f9-a4d1-4989-b438-d60cd5b14d2a",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fbiceps%2FSwitching%20Curls.mp4?alt=media&token=baecb75a-b837-4b2a-a408-3056b1af111b",
    video_id: "CnB_Switching_Curls_1",
    video_length: 0.1,
    video_number: "168",
    size: 1993123,
    hints: "CnB_Switching_Curls_1_hints",
    common_mistakes: "CnB_Switching_Curls_1_common_mistakes",
    breathing: "CnB_Switching_Curls_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Seated Concentrated Hammer Curls",
    is_free: true,
    primary_muscle: "Biceps, Brachialis",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Forearm",
    subcategory_name: "biceps",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSeated%20Concentrated%20Hammer%20Curls-0.jpg?alt=media&token=d3945b03-10c9-430d-9502-0ed77df97362",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fbiceps%2FSeated%20Concentrated%20Hammer%20Curls.mp4?alt=media&token=be1d210c-6aa8-4db4-b6bb-f18d1e7bc8a7",
    video_id: "CnB_Seated_Concentrated_Hammer_Curls_1",
    video_length: 0.1,
    video_number: "169",
    size: 985657,
    hints: "CnB_Seated_Concentrated_Hammer_Curls_1_hints",
    common_mistakes: "CnB_Seated_Concentrated_Hammer_Curls_1_common_mistakes",
    breathing: "CnB_Seated_Concentrated_Hammer_Curls_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Seated Curl Plus",
    is_free: false,
    primary_muscle: "Biceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Brachialis, Forearm",
    subcategory_name: "biceps",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSeated%20Curl%20Plus-0.jpg?alt=media&token=a7c58d64-3f5a-44b1-ba25-7e61da2b6fd2",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fbiceps%2FSeated%20Curl%20Plus.mp4?alt=media&token=ea50230f-b8b5-42db-8bb8-90fd20f126df",
    video_id: "CnB_Seated_Curl_Plus_1",
    video_length: 0.1,
    video_number: "170",
    size: 1353480,
    hints: "CnB_Seated_Curl_Plus_1_hints",
    common_mistakes: "CnB_Seated_Curl_Plus_1_common_mistakes",
    breathing: "CnB_Seated_Curl_Plus_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Wall Curls",
    is_free: false,
    primary_muscle: "Biceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Brachialis, Forearm",
    subcategory_name: "biceps",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FWall%20Curls-0.jpg?alt=media&token=72ad9168-6b57-45ed-afc1-e0d81d014b8d",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells Wall",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fbiceps%2FWall%20Curls.mp4?alt=media&token=8b291aca-7dab-48d0-840c-26875679902f",
    video_id: "CnB_Wall_Curls_1",
    video_length: 0.1,
    video_number: "171",
    size: 1468567,
    hints: "CnB_Wall_Curls_1_hints",
    common_mistakes: "CnB_Wall_Curls_1_common_mistakes",
    breathing: "CnB_Wall_Curls_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Seated Rotating Curl",
    is_free: true,
    primary_muscle: "Biceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Brachialis, Forearm",
    subcategory_name: "biceps",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSeated%20Rotating%20Curl-0.jpg?alt=media&token=ed3070ed-6755-4d65-b95c-060219838a7f",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fbiceps%2FSeated%20Rotating%20Curl.mp4?alt=media&token=31b2bc18-ba1e-4617-bbd1-b52a6870f423",
    video_id: "CnB_Seated_Rotating_Curl_1",
    video_length: 0.1,
    video_number: "172",
    size: 1687605,
    hints: "CnB_Seated_Rotating_Curl_1_hints",
    common_mistakes: "CnB_Seated_Rotating_Curl_1_common_mistakes",
    breathing: "CnB_Seated_Rotating_Curl_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Single Arm Kickback",
    is_other_side: 0,
    is_free: false,
    primary_muscle: "Triceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rear Shoulders",
    subcategory_name: "triceps",
    tertiary_muscle: "Latissimus Dorsi, Traps, Rhomboids",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(L)%20Single%20Arm%20Kickback-0.jpg?alt=media&token=e72a3ed7-bfa7-41a0-9c6c-32f2079590a6",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftriceps%2F(L)%20Single%20Arm%20Kickback.mp4?alt=media&token=a554ec8d-5170-45f0-9cab-2762173abd00",
    video_id: "TnS_Single_Arm_Kickback_1",
    video_length: 0.1,
    video_number: "173",
    size: 1349937,
    hints: "TnS_Single_Arm_Kickback_1_hints",
    common_mistakes: "TnS_Single_Arm_Kickback_1_common_mistakes",
    breathing: "TnS_Single_Arm_Kickback_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Single Arm Kickback",
    is_other_side: 1,
    is_free: false,
    primary_muscle: "Triceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rear Shoulders",
    subcategory_name: "triceps",
    tertiary_muscle: "Latissimus Dorsi, Traps, Rhomboids",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftriceps%2F(R)%20Single%20Arm%20Kickback.mp4?alt=media&token=47395ca3-cf82-4767-be8a-942f00bf2559",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftriceps%2F(L)%20Single%20Arm%20Kickback.mp4?alt=media&token=a554ec8d-5170-45f0-9cab-2762173abd00",
    video_id: "TnS_Single_Arm_Kickback_2",
    video_length: 0.1,
    video_number: "174",
    size: 1399561,
    hints: "TnS_Single_Arm_Kickback_2_hints",
    common_mistakes: "TnS_Single_Arm_Kickback_2_common_mistakes",
    breathing: "TnS_Single_Arm_Kickback_2_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Outward Tricep Extension",
    is_free: false,
    primary_muscle: "Triceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rear Shoulders",
    subcategory_name: "triceps",
    tertiary_muscle: "Latissimus Dorsi, Traps, Rhomboids",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FOutward%20Tricep%20Extension-0.jpg?alt=media&token=5f85352e-5229-40b8-8c1c-6a05e53dd217",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftriceps%2FOutward%20Tricep%20Extension.mp4?alt=media&token=89fc41ba-dd47-4e22-bfd4-861f26fe48ab",
    video_id: "TnS_Outward_Tricep_Extension_1",
    video_length: 0.1,
    video_number: "175",
    size: 1190293,
    hints: "TnS_Outward_Tricep_Extension_1_hints",
    common_mistakes: "TnS_Outward_Tricep_Extension_1_common_mistakes",
    breathing: "TnS_Outward_Tricep_Extension_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Single Into Double Tricep Extension",
    is_free: true,
    primary_muscle: "Triceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rear Shoulders",
    subcategory_name: "triceps",
    tertiary_muscle: "Latissimus Dorsi, Traps, Rhomboids",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSingle%20Into%20Double%20Tricep%20Extension-0.jpg?alt=media&token=9af67f24-f5a6-4ead-926b-226807211199",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftriceps%2FSingle%20Into%20Double%20Tricep%20Extension.mp4?alt=media&token=91487864-1fba-4728-876f-69bb93534641",
    video_id: "TnS_Single_Into_Double_Tricep_Extension_1",
    video_length: 0.1,
    video_number: "176",
    size: 1810322,
    hints: "TnS_Single_Into_Double_Tricep_Extension_1_hints",
    common_mistakes: "TnS_Single_Into_Double_Tricep_Extension_1_common_mistakes",
    breathing: "TnS_Single_Into_Double_Tricep_Extension_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "In & Out Tricep Extensions",
    is_free: false,
    primary_muscle: "Triceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rear Shoulders",
    subcategory_name: "triceps",
    tertiary_muscle: "Latissimus Dorsi, Traps, Rhomboids",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FIn%20%26%20Out%20Tricep%20Extensions-0.jpg?alt=media&token=250812d8-02d0-4d1b-8137-d9879436be5c",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftriceps%2FIn%20%26%20Out%20Tricep%20Extensions.mp4?alt=media&token=4dcc47f2-5e50-4993-bbd8-a76588bfae74",
    video_id: "TnS_In_Out_Tricep_Extensions_1",
    video_length: 0.1,
    video_number: "177",
    size: 1449238,
    hints: "TnS_In_Out_Tricep_Extensions_1_hints",
    common_mistakes: "TnS_In_Out_Tricep_Extensions_1_common_mistakes",
    breathing: "TnS_In_Out_Tricep_Extensions_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Seated Single Into Double Tricep Extension",
    is_free: false,
    primary_muscle: "Triceps",
    rep_range: "5-10",
    secondary_muscle: "Forearm, Shoulders",
    subcategory_name: "triceps",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSeated%20Single%20Into%20Double%20Tricep%20Extension-0.jpg?alt=media&token=c2f06ad9-7bfa-493e-98c0-daca73b8fbae",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftriceps%2FSeated%20Single%20Into%20Double%20Tricep%20Extension.mp4?alt=media&token=8171d1f9-0e08-40fc-b601-b1ccd6169bde",
    video_id: "TnS_Seated_Single_Into_Double_Tricep_Extension_1",
    video_length: 0.1,
    video_number: "178",
    size: 3527706,
    hints: "TnS_Seated_Single_Into_Double_Tricep_Extension_1_hints",
    common_mistakes: "TnS_Seated_Single_Into_Double_Tricep_Extension_1_common_mistakes",
    breathing: "TnS_Seated_Single_Into_Double_Tricep_Extension_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Backstabber Into Double Close Press",
    is_free: true,
    primary_muscle: "Triceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Front Shoulders, Chest, Latissimus Dorsi",
    subcategory_name: "triceps",
    tertiary_muscle: "Biceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FBackstabber%20Into%20Double%20Close%20Press-0.jpg?alt=media&token=8d4a5e66-9523-4197-839a-b1cdffac30d3",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftriceps%2FBackstabber%20Into%20Double%20Close%20Press.mp4?alt=media&token=a108dafa-61b7-4eaa-8d8e-c01cffe3ad3a",
    video_id: "TnS_Backstabber_Into_Double_Close_Press_1",
    video_length: 0.1,
    video_number: "179",
    size: 3583837,
    hints: "TnS_Backstabber_Into_Double_Close_Press_1_hints",
    common_mistakes: "TnS_Backstabber_Into_Double_Close_Press_1_common_mistakes",
    breathing: "TnS_Backstabber_Into_Double_Close_Press_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Seated Dips",
    is_free: true,
    primary_muscle: "Triceps",
    rep_range: "10-20",
    secondary_muscle: "Front Shoulders, Chest, Latissimus Dorsi",
    subcategory_name: "triceps",
    tertiary_muscle: "Biceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSeated%20Dips-0.jpg?alt=media&token=c2601b3e-5cc1-43af-87cb-617a9e69dc5c",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftriceps%2FSeated%20Dips.mp4?alt=media&token=6b79e56d-4a2e-4e9c-9d2f-7235218b6d8d",
    video_id: "TnS_Seated_Dips_1",
    video_length: 0.1,
    video_number: "180",
    size: 1570499,
    hints: "TnS_Seated_Dips_1_hints",
    common_mistakes: "TnS_Seated_Dips_1_common_mistakes",
    breathing: "TnS_Seated_Dips_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Inner Hand Push Ups",
    is_free: false,
    primary_muscle: "Triceps, Middle Chest",
    rep_range: "10-20",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Core, Serratus Anterior",
    subcategory_name: "triceps",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FInner%20Hand%20Push%20Ups-0.jpg?alt=media&token=956f524e-5765-4f3b-9a42-c5f50e6f4e36",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftriceps%2FInner%20Hand%20Push%20Ups.mp4?alt=media&token=a467f352-d99b-48ea-a806-064c2874e7da",
    video_id: "TnS_Inner_Hand_Push_Ups_1",
    video_length: 0.1,
    video_number: "181",
    size: 1047527,
    hints: "TnS_Inner_Hand_Push_Ups_1_hints",
    common_mistakes: "TnS_Inner_Hand_Push_Ups_1_common_mistakes",
    breathing: "TnS_Inner_Hand_Push_Ups_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Slow Negative Skull Crushers",
    is_free: true,
    primary_muscle: "Triceps",
    rep_range: "5-10",
    secondary_muscle: "Forearm, Shoulders",
    subcategory_name: "triceps",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSlow%20Negative%20Skull%20Crushers-0.jpg?alt=media&token=33b9c08b-c02c-40f6-a70a-e9075b40f026",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftriceps%2FSlow%20Negative%20Skull%20Crushers.mp4?alt=media&token=a45f93b8-4257-4909-a4ca-50be804c59e7",
    video_id: "TnS_Slow_Negative_Skull_Crushers_1",
    video_length: 0.1,
    video_number: "182",
    size: 2585986,
    hints: "TnS_Slow_Negative_Skull_Crushers_1_hints",
    common_mistakes: "TnS_Slow_Negative_Skull_Crushers_1_common_mistakes",
    breathing: "TnS_Slow_Negative_Skull_Crushers_1_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Wide Legged V-Ups",
    is_free: false,
    primary_muscle: "Abs",
    rep_range: "Time Based",
    secondary_muscle: "Obliques Hip Flexors",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FWide%20Legged%20V-Ups-0.jpg?alt=media&token=edf75281-6522-41cd-96ae-6c85dbd73af6",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fabs%2FWide%20Legged%20V-Ups.mp4?alt=media&token=d4f80e3d-c452-48a2-95b9-6e8b01bd8e92",
    video_id: "Abs_Wide_Legged_V_Ups_1",
    video_length: 0.07,
    video_number: "183",
    size: 1589193,
    hints: "Abs_Wide_Legged_V_Ups_1_hints",
    common_mistakes: "Abs_Wide_Legged_V_Ups_1_common_mistakes",
    breathing: "Abs_Wide_Legged_V_Ups_1_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Alternating Reverse Side Crunch",
    is_free: false,
    primary_muscle: "Obliques, Abs",
    rep_range: "Time Based",
    secondary_muscle: "Hip Flexors",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FAlternating%20Reverse%20Side%20Crunch-0.jpg?alt=media&token=f68fd9f6-c415-4d5a-b99d-2cb9c1cd8328",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fabs%2FAlternating%20Reverse%20Side%20Crunch.mp4?alt=media&token=4e7e106f-d438-43d6-89bb-461049147029",
    video_id: "Abs_Alternating_Reverse_Side_Crunch_1",
    video_length: 0.07,
    video_number: "184",
    size: 2627041,
    hints: "Abs_Alternating_Reverse_Side_Crunch_1_hints",
    common_mistakes: "Abs_Alternating_Reverse_Side_Crunch_1_common_mistakes",
    breathing: "Abs_Alternating_Reverse_Side_Crunch_1_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Elevated Penguins",
    is_free: true,
    primary_muscle: "Obliques, Abs",
    rep_range: "Time Based",
    secondary_muscle: "Hip Flexors",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FElevated%20Penguins-0.jpg?alt=media&token=4573a0dd-1b54-4684-8063-a39032bd36a7",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fabs%2FElevated%20Penguins.mp4?alt=media&token=0165ce7e-b48d-4d10-81d5-a0159656c501",
    video_id: "Abs_Elevated_Penguins_1",
    video_length: 0.07,
    video_number: "185",
    size: 1625135,
    hints: "Abs_Elevated_Penguins_1_hints",
    common_mistakes: "Abs_Elevated_Penguins_1_common_mistakes",
    breathing: "Abs_Elevated_Penguins_1_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Alternating Elbow Towards Hip",
    is_free: true,
    primary_muscle: "Upper Abs, Obliques",
    rep_range: "Time Based",
    secondary_muscle: "Lower Abs",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FAlternating%20Elbow%20Towards%20Hip-0.jpg?alt=media&token=fbb2e5e9-d4c1-4f3b-8305-31c31096bac9",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fabs%2FAlternating%20Elbow%20Towards%20Hip.mp4?alt=media&token=2b04d103-63a0-4cd2-8cbb-707ad8ec4da9",
    video_id: "Abs_Alternating_Elbow_Towards_Hip_1",
    video_length: 0.07,
    video_number: "186",
    size: 1732734,
    hints: "Abs_Alternating_Elbow_Towards_Hip_1_hints",
    common_mistakes: "Abs_Alternating_Elbow_Towards_Hip_1_common_mistakes",
    breathing: "Abs_Alternating_Elbow_Towards_Hip_1_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Psycho Crunches",
    is_free: true,
    primary_muscle: "Abs",
    rep_range: "Time Based",
    secondary_muscle: "Obliques, Hip Flexors",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FPsycho%20Crunches-0.jpg?alt=media&token=9e7eba6a-0268-4e60-b9f1-a37b6fca3c16",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fabs%2FPsycho%20Crunches.mp4?alt=media&token=7164b281-df61-40e4-a9c1-b2a54b01dbfb",
    video_id: "Abs_Psycho_Crunches_1",
    video_length: 0.07,
    video_number: "187",
    size: 1961441,
    hints: "Abs_Psycho_Crunches_1_hints",
    common_mistakes: "Abs_Psycho_Crunches_1_common_mistakes",
    breathing: "Abs_Psycho_Crunches_1_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Single Into Double Jackknives",
    is_free: false,
    primary_muscle: "Abs",
    rep_range: "Time Based",
    secondary_muscle: "Obliques Hip Flexors",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSingle%20Into%20Double%20Jackknives-0.jpg?alt=media&token=65fd468b-dae2-407f-9480-4f1677eee908",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fabs%2FSingle%20Into%20Double%20Jackknives.mp4?alt=media&token=875fe966-5d11-401c-914e-53233c60b877",
    video_id: "Abs_Single_Into_Double_Jackknives_1",
    video_length: 0.07,
    video_number: "188",
    size: 3358072,
    hints: "Abs_Single_Into_Double_Jackknives_1_hints",
    common_mistakes: "Abs_Single_Into_Double_Jackknives_1_common_mistakes",
    breathing: "Abs_Single_Into_Double_Jackknives_1_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Leg Lift Into Hip Raise",
    is_free: false,
    primary_muscle: "Lower Abs",
    rep_range: "Time Based",
    secondary_muscle: "Upper Abs, Obliques, Hip Flexors",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FLeg%20Lift%20Into%20Hip%20Raise-0.jpg?alt=media&token=f35f5907-38a7-4ee9-9ac2-667823ebb1b9",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fabs%2FLeg%20Lift%20Into%20Hip%20Raise.mp4?alt=media&token=3a4b39f0-8498-4e45-889f-e038acc049ca",
    video_id: "Abs_Leg_Lift_Into_Hip_Raise_1",
    video_length: 0.07,
    video_number: "189",
    size: 2249915,
    hints: "Abs_Leg_Lift_Into_Hip_Raise_1_hints",
    common_mistakes: "Abs_Leg_Lift_Into_Hip_Raise_1_common_mistakes",
    breathing: "Abs_Leg_Lift_Into_Hip_Raise_1_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Ab Crunch Knees Up Into Ab Dive Legs Down",
    is_free: false,
    primary_muscle: "Abs",
    rep_range: "Time Based",
    secondary_muscle: "Obliques, Hip Flexors",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FAb%20Crunch%20Knees%20Up%20Into%20Ab%20Dive%20Legs%20Down-0.jpg?alt=media&token=93d7d0bc-d7f0-43b8-bb67-217f98869f0f",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fabs%2FAb%20Crunch%20Knees%20Up%20Into%20Ab%20Dive%20Legs%20Down.mp4?alt=media&token=df9b9fbe-6c70-4a78-aa5c-7a4021736a28",
    video_id: "Abs_Ab_Crunch_Knees_Up_Into_Ab_Dive_Legs_Down_1",
    video_length: 0.07,
    video_number: "190",
    size: 2706845,
    hints: "Abs_Ab_Crunch_Knees_Up_Into_Ab_Dive_Legs_Down_1_hints",
    common_mistakes: "Abs_Ab_Crunch_Knees_Up_Into_Ab_Dive_Legs_Down_1_common_mistakes",
    breathing: "Abs_Ab_Crunch_Knees_Up_Into_Ab_Dive_Legs_Down_1_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Leg Lift Windshield Wipers",
    is_free: false,
    primary_muscle: "Obliques, Abs",
    rep_range: "Time Based",
    secondary_muscle: "Hip Flexors",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FLeg%20Lift%20Windshield%20Wipers-0.jpg?alt=media&token=d70e8069-2e57-4557-8ebd-f290c277d560",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fabs%2FLeg%20Lift%20Windshield%20Wipers.mp4?alt=media&token=39994d67-3270-40ae-afc9-d41d19dc6c33",
    video_id: "Abs_Leg_Lift_Windshield_Wipers_1",
    video_length: 0.07,
    video_number: "191",
    size: 3856251,
    hints: "Abs_Leg_Lift_Windshield_Wipers_1_hints",
    common_mistakes: "Abs_Leg_Lift_Windshield_Wipers_1_common_mistakes",
    breathing: "Abs_Leg_Lift_Windshield_Wipers_1_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Alternating Elbow Towards Knee",
    is_free: false,
    primary_muscle: "Obliques, Upper Abs",
    rep_range: "Time Based",
    secondary_muscle: "Lower Abs",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FAlternating%20Elbow%20Towards%20Knee-0.jpg?alt=media&token=68e568ef-7058-453c-8afc-69c110d0aa50",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fabs%2FAlternating%20Elbow%20Towards%20Knee.mp4?alt=media&token=4299ea05-c7b4-4123-9206-88ee691d60d9",
    video_id: "Abs_Alternating_Elbow_Towards_Knee_1",
    video_length: 0.07,
    video_number: "192",
    size: 2112290,
    hints: "Abs_Alternating_Elbow_Towards_Knee_1_hints",
    common_mistakes: "Abs_Alternating_Elbow_Towards_Knee_1_common_mistakes",
    breathing: "Abs_Alternating_Elbow_Towards_Knee_1_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "90 Degree Sit Up To Toes",
    is_free: false,
    primary_muscle: "Abs",
    rep_range: "Time Based",
    secondary_muscle: "Obliques, Hip Flexors",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F90%20Degree%20Sit%20Up%20To%20Toes-0.jpg?alt=media&token=c38ad16e-cd1b-4d96-a350-aee8aa50d671",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fabs%2F90%20Degree%20Sit%20Up%20To%20Toes.mp4?alt=media&token=66787e50-e5e3-4906-aaf6-f24a70114144",
    video_id: "Abs_90_Degree_Sit_Up_To_Toes_1",
    video_length: 0.07,
    video_number: "193",
    size: 1976603,
    hints: "Abs_90_Degree_Sit_Up_To_Toes_1_hints",
    common_mistakes: "Abs_90_Degree_Sit_Up_To_Toes_1_common_mistakes",
    breathing: "Abs_90_Degree_Sit_Up_To_Toes_1_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Hip Overs",
    is_free: false,
    primary_muscle: "Lower Abs",
    rep_range: "Time Based",
    secondary_muscle: "Upper Abs, Obliques, Hip Flexors",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FHip%20Overs-0.jpg?alt=media&token=9d06d1bb-1357-4bb4-92b1-605da63f91a0",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fabs%2FHip%20Overs.mp4?alt=media&token=ed7f47bb-fd19-4652-87f7-8e95af193cd3",
    video_id: "Abs_Hip_Overs_1",
    video_length: 0.07,
    video_number: "194",
    size: 1119968,
    hints: "Abs_Hip_Overs_1_hints",
    common_mistakes: "Abs_Hip_Overs_1_common_mistakes",
    breathing: "Abs_Hip_Overs_1_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Ab Crunch 90 Degree Knees",
    is_free: true,
    primary_muscle: "Abs",
    rep_range: "Time Based",
    secondary_muscle: "Obliques, Hip Flexors",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FAb%20Crunch%2090%20Degree%20Knees-0.jpg?alt=media&token=06385593-0bd7-4790-afcd-8cb3f03da3d1",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fabs%2FAb%20Crunch%2090%20Degree%20Knees.mp4?alt=media&token=ab7deb46-e5f2-4b45-adb5-4463fff0533c",
    video_id: "Abs_Ab_Crunch_90_Degree_Knees_1",
    video_length: 0.07,
    video_number: "195",
    size: 893013,
    hints: "Abs_Ab_Crunch_90_Degree_Knees_1_hints",
    common_mistakes: "Abs_Ab_Crunch_90_Degree_Knees_1_common_mistakes",
    breathing: "Abs_Ab_Crunch_90_Degree_Knees_1_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Alternating Upper Circle Crunches",
    is_free: false,
    primary_muscle: "Upper Abs, Obliques",
    rep_range: "Time Based",
    secondary_muscle: "Lower Abs",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FAlternating%20Upper%20Circle%20Crunches-0.jpg?alt=media&token=7b7c931a-ef7f-43cc-b4f3-3b561f5d954d",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fabs%2FAlternating%20Upper%20Circle%20Crunches.mp4?alt=media&token=ad961d28-70b8-4e09-bf5b-e29c4c2656bb",
    video_id: "Abs_Alternating_Upper_Circle_Crunches_1",
    video_length: 0.07,
    video_number: "196",
    size: 3219983,
    hints: "Abs_Alternating_Upper_Circle_Crunches_1_hints",
    common_mistakes: "Abs_Alternating_Upper_Circle_Crunches_1_common_mistakes",
    breathing: "Abs_Alternating_Upper_Circle_Crunches_1_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Ab Hold",
    is_free: true,
    primary_muscle: "Abs",
    rep_range: "Time Based",
    secondary_muscle: "Obliques, Hip Flexors",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FAb%20Hold-0.jpg?alt=media&token=5a6c52fe-9289-4dde-a551-f7604943e1f2",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fabs%2FAb%20Hold.mp4?alt=media&token=bb47515f-367c-41af-92ab-ecbbe83d502a",
    video_id: "Abs_Ab_Hold_1",
    video_length: 0.07,
    video_number: "197",
    size: 664192,
    hints: "Abs_Ab_Hold_1_hints",
    common_mistakes: "Abs_Ab_Hold_1_common_mistakes",
    breathing: "Abs_Ab_Hold_1_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Leg Openers",
    is_free: false,
    primary_muscle: "Abs, Hip Flexors",
    rep_range: "Time Based",
    secondary_muscle: "Obliques",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FLeg%20Openers-0.jpg?alt=media&token=3d8ee46a-d4cc-4dc8-bc05-dcb67c7fdad7",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fabs%2FLeg%20Openers.mp4?alt=media&token=2cb2b5c5-5f31-4d6d-a9a3-3f4e0525b0de",
    video_id: "Abs_Leg_Openers_1",
    video_length: 0.07,
    video_number: "198",
    size: 2191723,
    hints: "Abs_Leg_Openers_1_hints",
    common_mistakes: "Abs_Leg_Openers_1_common_mistakes",
    breathing: "Abs_Leg_Openers_1_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Flutter Kick",
    is_free: false,
    primary_muscle: "Abs",
    rep_range: "Time Based",
    secondary_muscle: "Obliques, Hip Flexors",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FFlutter%20Kick-0.jpg?alt=media&token=1a9e75f6-c115-4ec7-a0e8-1de6b6210c72",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fabs%2FFlutter%20Kick.mp4?alt=media&token=76d4960e-2b98-47ec-b2c8-bc2ddf2c5300",
    video_id: "Abs_Flutter_Kick_1",
    video_length: 0.07,
    video_number: "199",
    size: 2152319,
    hints: "Abs_Flutter_Kick_1_hints",
    common_mistakes: "Abs_Flutter_Kick_1_common_mistakes",
    breathing: "Abs_Flutter_Kick_1_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Rocker",
    is_free: false,
    primary_muscle: "Abs",
    rep_range: "Time Based",
    secondary_muscle: "Obliques, Hip Flexors",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FRocker-0.jpg?alt=media&token=fe0e4401-f467-4c97-bf1d-26eb9d19e64b",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fabs%2FRocker.mp4?alt=media&token=563f11f6-e15c-4632-921b-01c3384a7273",
    video_id: "Abs_Rocker_1",
    video_length: 0.07,
    video_number: "200",
    size: 2571199,
    hints: "Abs_Rocker_1_hints",
    common_mistakes: "Abs_Rocker_1_common_mistakes",
    breathing: "Abs_Rocker_1_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "V-Dives",
    is_free: false,
    primary_muscle: "Upper Abs",
    rep_range: "Time Based",
    secondary_muscle: "Lower Abs, Obliques",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FV-Dives-0.jpg?alt=media&token=2f366eb7-c856-4472-90bd-9ec53034d738",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fabs%2FV-Dives.mp4?alt=media&token=85c34fcd-c038-44b6-b4c9-77fa2ecca138",
    video_id: "Abs_V_Dives_1",
    video_length: 0.07,
    video_number: "201",
    size: 1878518,
    hints: "Abs_V_Dives_1_hints",
    common_mistakes: "Abs_V_Dives_1_common_mistakes",
    breathing: "Abs_V_Dives_1_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Hundred Pike",
    is_free: true,
    primary_muscle: "Abs",
    rep_range: "Time Based",
    secondary_muscle: "Obliques, Hip Flexors",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FHundred%20Pike-0.jpg?alt=media&token=512d1c5d-b351-42de-85f1-52d59158c508",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fabs%2FHundred%20Pike.mp4?alt=media&token=6ec0a6d5-24f3-478c-b9a6-dc656b2fad68",
    video_id: "Abs_Hundred_Pike_1",
    video_length: 0.07,
    video_number: "202",
    size: 1176337,
    hints: "Abs_Hundred_Pike_1_hints",
    common_mistakes: "Abs_Hundred_Pike_1_common_mistakes",
    breathing: "Abs_Hundred_Pike_1_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Jackknives",
    is_free: false,
    primary_muscle: "Abs",
    rep_range: "Time Based",
    secondary_muscle: "Obliques, Hip Flexors",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FJackknives-0.jpg?alt=media&token=63cf7a73-0bd8-4856-b581-5d8a2aa73a67",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fabs%2FJackknives.mp4?alt=media&token=c6e6a0a1-6216-4fac-9708-61ccde98776a",
    video_id: "Abs_Jackknives_1",
    video_length: 0.07,
    video_number: "203",
    size: 2220726,
    hints: "Abs_Jackknives_1_hints",
    common_mistakes: "Abs_Jackknives_1_common_mistakes",
    breathing: "Abs_Jackknives_1_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Single Into Double Leg Lower",
    is_free: false,
    primary_muscle: "Lower Abs",
    rep_range: "Time Based",
    secondary_muscle: "Upper Abs, Obliques, Hip Flexors",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSingle%20Into%20Double%20Leg%20Lower-0.jpg?alt=media&token=f1314f1d-3a92-47ce-b027-6e3ae06cd237",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fabs%2FSingle%20Into%20Double%20Leg%20Lower.mp4?alt=media&token=5af055f3-8f01-4d6c-9f7d-12b9e1890ad5",
    video_id: "Abs_Single_Into_Double_Leg_Lower_1",
    video_length: 0.07,
    video_number: "204",
    size: 3553068,
    hints: "Abs_Single_Into_Double_Leg_Lower_1_hints",
    common_mistakes: "Abs_Single_Into_Double_Leg_Lower_1_common_mistakes",
    breathing: "Abs_Single_Into_Double_Leg_Lower_1_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Ab Rollups",
    is_free: false,
    primary_muscle: "Upper Abs",
    rep_range: "Time Based",
    secondary_muscle: "Lower Abs, Obliques",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FAb%20Rollups-0.jpg?alt=media&token=b3608f64-1a75-4e5b-b4da-60c482d709d8",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fabs%2FAb%20Rollups.mp4?alt=media&token=e94c35a0-9dba-4bc5-b001-ecd7d859c467",
    video_id: "Abs_Ab_Rollups_1",
    video_length: 0.07,
    video_number: "205",
    size: 3794651,
    hints: "Abs_Ab_Rollups_1_hints",
    common_mistakes: "Abs_Ab_Rollups_1_common_mistakes",
    breathing: "Abs_Ab_Rollups_1_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "The Kobe Crunch",
    is_free: false,
    primary_muscle: "Abs",
    rep_range: "Time Based",
    secondary_muscle: "Obliques, Hip Flexors",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FThe%20Kobe%20Crunch-0.jpg?alt=media&token=317581d6-8ec1-45e3-9d3e-9b9e045d4e86",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fabs%2FThe%20Kobe%20Crunch.mp4?alt=media&token=c65da558-9ac2-42af-8304-94774a2a9f32",
    video_id: "Abs_The_Kobe_Crunch_1",
    video_length: 0.07,
    video_number: "206",
    size: 2498303,
    hints: "Abs_The_Kobe_Crunch_1_hints",
    common_mistakes: "Abs_The_Kobe_Crunch_1_common_mistakes",
    breathing: "Abs_The_Kobe_Crunch_1_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Heel Touches",
    is_free: false,
    primary_muscle: "Upper Abs",
    rep_range: "Time Based",
    secondary_muscle: "Lower Abs, Obliques",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FHeel%20Touches-0.jpg?alt=media&token=6c9fc2bb-6ac4-45cf-bb6a-2085c38a235f",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fabs%2FHeel%20Touches.mp4?alt=media&token=1bd5ad08-90fd-4691-80b7-15f2c7b93fac",
    video_id: "Abs_Heel_Touches_1",
    video_length: 0.07,
    video_number: "207",
    size: 1525043,
    hints: "Abs_Heel_Touches_1_hints",
    common_mistakes: "Abs_Heel_Touches_1_common_mistakes",
    breathing: "Abs_Heel_Touches_1_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Scissor Kicks Into Jackknives",
    is_free: false,
    primary_muscle: "Abs",
    rep_range: "Time Based",
    secondary_muscle: "Obliques, Hip Flexors",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FScissor%20Kicks%20Into%20Jackknives-0.jpg?alt=media&token=4c0f2d6f-6b1a-4ca6-a8d5-2bb2fc2b3f68",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fabs%2FScissor%20Kicks%20Into%20Jackknives.mp4?alt=media&token=6a708815-77a8-4372-ba52-e3790348d6cb",
    video_id: "Abs_Scissor_Kicks_Into_Jackknives_1",
    video_length: 0.07,
    video_number: "208",
    size: 2099955,
    hints: "Abs_Scissor_Kicks_Into_Jackknives_1_hints",
    common_mistakes: "Abs_Scissor_Kicks_Into_Jackknives_1_common_mistakes",
    breathing: "Abs_Scissor_Kicks_Into_Jackknives_1_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "3-Way Leg Crunch",
    is_free: false,
    primary_muscle: "Obliques, Abs",
    rep_range: "Time Based",
    secondary_muscle: "Hip Flexors",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F3-Way%20Leg%20Crunch-0.jpg?alt=media&token=b71bc21a-a1b1-4752-8f48-9e2c70bd192a",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fabs%2F3-Way%20Leg%20Crunch.mp4?alt=media&token=4edd64c0-622d-46fc-bd74-83799589068f",
    video_id: "Abs_3_Way_Leg_Crunch_1",
    video_length: 0.07,
    video_number: "209",
    size: 6580601,
    hints: "Abs_3_Way_Leg_Crunch_1_hints",
    common_mistakes: "Abs_3_Way_Leg_Crunch_1_common_mistakes",
    breathing: "Abs_3_Way_Leg_Crunch_1_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "V-Dive Into Heel Touch",
    is_free: false,
    primary_muscle: "Upper Abs",
    rep_range: "Time Based",
    secondary_muscle: "Lower Abs, Obliques, Hip Flexors",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FV-Dive%20Into%20Heel%20Touch-0.jpg?alt=media&token=d3055787-ef60-487d-b5f9-26eca1006880",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fabs%2FV-Dive%20Into%20Heel%20Touch.mp4?alt=media&token=f4062d22-0684-4cfe-bd47-0fbf919ace23",
    video_id: "Abs_V_Dive_Into_Heel_Touch_1",
    video_length: 0.07,
    video_number: "210",
    size: 1784550,
    hints: "Abs_V_Dive_Into_Heel_Touch_1_hints",
    common_mistakes: "Abs_V_Dive_Into_Heel_Touch_1_common_mistakes",
    breathing: "Abs_V_Dive_Into_Heel_Touch_1_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Alternating Corkscrew",
    is_free: false,
    primary_muscle: "Obliques, Lower Ab",
    rep_range: "Time Based",
    secondary_muscle: "Upper Abs, Hip Flexors",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FAlternating%20Corkscrew-0.jpg?alt=media&token=ee22501a-ab51-4f9e-a66e-d4296c6a6645",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fabs%2FAlternating%20Corkscrew.mp4?alt=media&token=9d9768b7-df55-41d3-bcd7-3eb7e9321403",
    video_id: "Abs_Alternating_Corkscrew_1",
    video_length: 0.07,
    video_number: "211",
    size: 2949332,
    hints: "Abs_Alternating_Corkscrew_1_hints",
    common_mistakes: "Abs_Alternating_Corkscrew_1_common_mistakes",
    breathing: "Abs_Alternating_Corkscrew_1_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Ab Crunch Into Touch The Sky",
    is_free: false,
    primary_muscle: "Upper Abs",
    rep_range: "Time Based",
    secondary_muscle: "Lower Abs, Obliques",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FAb%20Crunch%20Into%20Touch%20The%20Sky-0.jpg?alt=media&token=f1be3177-4dd8-42e8-90f7-a88b4c26387b",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fabs%2FAb%20Crunch%20Into%20Touch%20The%20Sky.mp4?alt=media&token=59d9cd99-bada-4817-bd8f-98c014a4b57c",
    video_id: "Abs_Ab_Crunch_Into_Touch_The_Sky_1",
    video_length: 0.07,
    video_number: "212",
    size: 2315915,
    hints: "Abs_Ab_Crunch_Into_Touch_The_Sky_1_hints",
    common_mistakes: "Abs_Ab_Crunch_Into_Touch_The_Sky_1_common_mistakes",
    breathing: "Abs_Ab_Crunch_Into_Touch_The_Sky_1_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Clap Crunch",
    is_free: false,
    primary_muscle: "Abs",
    rep_range: "Time Based",
    secondary_muscle: "Obliques Hip Flexors",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FClap%20Crunch-0.jpg?alt=media&token=43c5606e-9c49-464f-9287-e4e1c0908d60",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fabs%2FClap%20Crunch.mp4?alt=media&token=b74dfd68-5197-4bbf-bfab-ab0eb249d8bf",
    video_id: "Abs_Clap_Crunch_1",
    video_length: 0.07,
    video_number: "213",
    size: 1728850,
    hints: "Abs_Clap_Crunch_1_hints",
    common_mistakes: "Abs_Clap_Crunch_1_common_mistakes",
    breathing: "Abs_Clap_Crunch_1_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Windshield Wiper",
    is_free: false,
    primary_muscle: "Obliques, Abs",
    rep_range: "Time Based",
    secondary_muscle: "Hip Flexors",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FWindshield%20Wiper-0.jpg?alt=media&token=8c6fad0d-7711-4cc1-9948-13e9385df42e",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fabs%2FWindshield%20Wiper.mp4?alt=media&token=5ca897e4-7087-4d7e-b2d7-457d0e0c709d",
    video_id: "Abs_Windshield_Wiper_1",
    video_length: 0.07,
    video_number: "214",
    size: 3929142,
    hints: "Abs_Windshield_Wiper_1_hints",
    common_mistakes: "Abs_Windshield_Wiper_1_common_mistakes",
    breathing: "Abs_Windshield_Wiper_1_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Alternating Single Leg Crunches",
    is_free: false,
    primary_muscle: "Abs",
    rep_range: "Time Based",
    secondary_muscle: "Obliques, Hip Flexors",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FAlternating%20Single%20Leg%20Crunches-0.jpg?alt=media&token=2e0ed6d5-06b2-4cd7-b421-0af647dc06de",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fabs%2FAlternating%20Single%20Leg%20Crunches.mp4?alt=media&token=d634496c-b34a-4ef3-801b-ea14cbe9fc7c",
    video_id: "Abs_Alternating_Single_Leg_Crunches_1",
    video_length: 0.07,
    video_number: "215",
    size: 3118151,
    hints: "Abs_Alternating_Single_Leg_Crunches_1_hints",
    common_mistakes: "Abs_Alternating_Single_Leg_Crunches_1_common_mistakes",
    breathing: "Abs_Alternating_Single_Leg_Crunches_1_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Low Bicycle",
    is_free: true,
    primary_muscle: "Abs",
    rep_range: "Time Based",
    secondary_muscle: "Obliques, Hip Flexors",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FLow%20Bicycle-0.jpg?alt=media&token=64561d31-750b-4278-b154-f9852f14dd96",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fabs%2FLow%20Bicycle.mp4?alt=media&token=07f5f85f-8a96-4a7d-9598-e93b4716bb0b",
    video_id: "Abs_Low_Bicycle_1",
    video_length: 0.07,
    video_number: "216",
    size: 2301895,
    hints: "Abs_Low_Bicycle_1_hints",
    common_mistakes: "Abs_Low_Bicycle_1_common_mistakes",
    breathing: "Abs_Low_Bicycle_1_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "X-Ups",
    is_free: false,
    primary_muscle: "Abs",
    rep_range: "Time Based",
    secondary_muscle: "Obliques, Hip Flexors",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FX-Ups-0.jpg?alt=media&token=bd229d1f-7dc2-45fd-aa1f-d7c10207036c",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fabs%2FX-Ups.mp4?alt=media&token=01d10cf4-7da4-4fc2-b861-6f124c9fd15b",
    video_id: "Abs_X_Ups_1",
    video_length: 0.07,
    video_number: "217",
    size: 1455283,
    hints: "Abs_X_Ups_1_hints",
    common_mistakes: "Abs_X_Ups_1_common_mistakes",
    breathing: "Abs_X_Ups_1_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Single Jackknives",
    is_free: false,
    primary_muscle: "Abs",
    rep_range: "Time Based",
    secondary_muscle: "Obliques, Hip Flexors",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSingle%20Jackknives-0.jpg?alt=media&token=04819962-cb94-465e-baec-8ed417481705",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fabs%2FSingle%20Jackknives.mp4?alt=media&token=886c8fea-3981-4578-bb41-9fb96bc4edba",
    video_id: "Abs_Single_Jackknives_1",
    video_length: 0.07,
    video_number: "218",
    size: 2051015,
    hints: "Abs_Single_Jackknives_1_hints",
    common_mistakes: "Abs_Single_Jackknives_1_common_mistakes",
    breathing: "Abs_Single_Jackknives_1_breathing",
  },
  {
    category_id: 2,
    category_name: "abs",
    subcategory_name: "abs",
    name: "Alternating Toe Tap",
    is_free: false,
    primary_muscle: "Obliques, Abs",
    rep_range: "Time Based",
    secondary_muscle: "Hip Flexors",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FAlternating%20Toe%20Tap-0.jpg?alt=media&token=bd4b06f5-03d8-452e-8723-3e5dc73d82c9",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fabs%2FAlternating%20Toe%20Tap.mp4?alt=media&token=f0acaf04-7472-46ef-a271-53b14008ff9b",
    video_id: "Abs_Alternating_Toe_Tap_1",
    video_length: 0.07,
    video_number: "219",
    size: 3225427,
    hints: "Abs_Alternating_Toe_Tap_1_hints",
    common_mistakes: "Abs_Alternating_Toe_Tap_1_common_mistakes",
    breathing: "Abs_Alternating_Toe_Tap_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Twisting Floor Press",
    is_free: false,
    primary_muscle: "Middle Chest",
    rep_range: "10-20",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Triceps, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FTwisting%20Floor%20Press-0.jpg?alt=media&token=1567925e-448d-4165-b70d-1997203cecdb",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fchest%2FTwisting%20Floor%20Press.mp4?alt=media&token=83fae04e-7281-494d-ba7b-152b812ced57",
    video_id: "CnB_Twisting_Floor_Press_1",
    video_length: 0.07,
    video_number: "220",
    size: 1484391,
    hints: "CnB_Twisting_Floor_Press_1_hints",
    common_mistakes: "CnB_Twisting_Floor_Press_1_common_mistakes",
    breathing: "CnB_Twisting_Floor_Press_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Pec Crushers",
    is_free: false,
    primary_muscle: "Upper Chest, Middle Chest",
    rep_range: "10-20",
    secondary_muscle: "Lower Chest, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FPec%20Crushers-0.jpg?alt=media&token=cd2b9595-36bd-4978-ad15-d9b39935d52a",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fchest%2FPec%20Crushers.mp4?alt=media&token=fe6d0ee6-166a-4b3c-9718-3db446627984",
    video_id: "CnB_Pec_Crushers_1",
    video_length: 0.07,
    video_number: "221",
    size: 1018717,
    hints: "CnB_Pec_Crushers_1_hints",
    common_mistakes: "CnB_Pec_Crushers_1_common_mistakes",
    breathing: "CnB_Pec_Crushers_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Y Press Bring In",
    is_free: false,
    primary_muscle: "Middle Chest",
    rep_range: "10-20",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Triceps, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FY%20Press%20Bring%20In-0.jpg?alt=media&token=31206417-36ca-4f9e-aa51-95c3d0aba1bc",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fchest%2FY%20Press%20Bring%20In.mp4?alt=media&token=7e503fb5-3dcf-4092-a06c-4d9165350cf3",
    video_id: "CnB_Y_Press_Bring_In_1",
    video_length: 0.07,
    video_number: "222",
    size: 1497535,
    hints: "CnB_Y_Press_Bring_In_1_hints",
    common_mistakes: "CnB_Y_Press_Bring_In_1_common_mistakes",
    breathing: "CnB_Y_Press_Bring_In_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Close Negative Into Wide Fly",
    is_free: false,
    primary_muscle: "Middle Chest, Triceps",
    rep_range: "10-20",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Biceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FClose%20Negative%20Into%20Wide%20Fly-0.jpg?alt=media&token=22a4cbe2-0f50-4e70-a031-e1ac899c0450",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fchest%2FClose%20Negative%20Into%20Wide%20Fly.mp4?alt=media&token=76f90732-e83d-43b5-af76-1d066138f575",
    video_id: "CnB_Close_Negative_Into_Wide_Fly_1",
    video_length: 0.07,
    video_number: "223",
    size: 232362,
    hints: "CnB_Close_Negative_Into_Wide_Fly_1_hints",
    common_mistakes: "CnB_Close_Negative_Into_Wide_Fly_1_common_mistakes",
    breathing: "CnB_Close_Negative_Into_Wide_Fly_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Wall Push Offs",
    is_free: false,
    primary_muscle: "Middle Chest",
    rep_range: "10-20",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Triceps, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FWall%20Push%20Offs-0.jpg?alt=media&token=b5243de6-0f89-4a41-8568-f0150506a687",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Wall",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fchest%2FWall%20Push%20Offs.mp4?alt=media&token=9b0e8591-2112-4ff4-be4e-43e948f508b3",
    video_id: "CnB_Wall_Push_Offs_1",
    video_length: 0.07,
    video_number: "224",
    size: 1434761,
    hints: "CnB_Wall_Push_Offs_1_hints",
    common_mistakes: "CnB_Wall_Push_Offs_1_common_mistakes",
    breathing: "CnB_Wall_Push_Offs_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Low Single Dumbbell Press",
    is_free: false,
    primary_muscle: "Middle Chest",
    rep_range: "10-20",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Triceps, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FLow%20Single%20Dumbbell%20Press-0.jpg?alt=media&token=e40f623b-bd4a-4b97-9c3f-874b58a64d79",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fchest%2FLow%20Single%20Dumbbell%20Press.mp4?alt=media&token=3be097d6-df1e-4288-85aa-9be40f9b5ae5",
    video_id: "CnB_Low_Single_Dumbbell_Press_1",
    video_length: 0.07,
    video_number: "225",
    size: 949972,
    hints: "CnB_Low_Single_Dumbbell_Press_1_hints",
    common_mistakes: "CnB_Low_Single_Dumbbell_Press_1_common_mistakes",
    breathing: "CnB_Low_Single_Dumbbell_Press_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Alternating Chest Fly",
    is_free: false,
    primary_muscle: "Middle Chest",
    rep_range: "10-20",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Biceps, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FAlternating%20Chest%20Fly-0.jpg?alt=media&token=b1806730-b787-4cde-ae73-cd001257ecc5",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fchest%2FAlternating%20Chest%20Fly.mp4?alt=media&token=13c55549-c075-4f06-8520-b85268d7cd61",
    video_id: "CnB_Alternating_Chest_Fly_1",
    video_length: 0.07,
    video_number: "226",
    size: 2995003,
    hints: "CnB_Alternating_Chest_Fly_1_hints",
    common_mistakes: "CnB_Alternating_Chest_Fly_1_common_mistakes",
    breathing: "CnB_Alternating_Chest_Fly_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Alternating Reverse Press",
    is_free: false,
    primary_muscle: "Upper Chest",
    rep_range: "10-20",
    secondary_muscle: "Middle Chest Chest, Lower Chest, Shoulders, Triceps, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Biceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FAlternating%20Reverse%20Press-0.jpg?alt=media&token=04024715-9406-4821-b341-81609518d9cd",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fchest%2FAlternating%20Reverse%20Press.mp4?alt=media&token=1b86ea7c-1e33-4fd5-935e-5e168123830d",
    video_id: "CnB_Alternating_Reverse_Press_1",
    video_length: 0.07,
    video_number: "227",
    size: 1359927,
    hints: "CnB_Alternating_Reverse_Press_1_hints",
    common_mistakes: "CnB_Alternating_Reverse_Press_1_common_mistakes",
    breathing: "CnB_Alternating_Reverse_Press_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Decline Chest Fly",
    is_free: true,
    primary_muscle: "Lower Chest",
    rep_range: "10-20",
    secondary_muscle: "Upper Chest, Middle Chest, Shoulders, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Biceps, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FDecline%20Chest%20Fly-0.jpg?alt=media&token=4a34f9a3-2d4e-4887-9f51-00bd86da0bfb",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fchest%2FDecline%20Chest%20Fly.mp4?alt=media&token=baaaae85-6c37-44a7-9545-be22792fab69",
    video_id: "CnB_Decline_Chest_Fly_1",
    video_length: 0.07,
    video_number: "228",
    size: 2014500,
    hints: "CnB_Decline_Chest_Fly_1_hints",
    common_mistakes: "CnB_Decline_Chest_Fly_1_common_mistakes",
    breathing: "CnB_Decline_Chest_Fly_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Decline Chest Press",
    is_free: true,
    primary_muscle: "Lower Chest",
    rep_range: "10-20",
    secondary_muscle: "Upper Chest, Middle Chest, Shoulders, Triceps, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FDecline%20Chest%20Press-0.jpg?alt=media&token=ecf957dc-6ed1-4690-a219-ec43c4994687",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fchest%2FDecline%20Chest%20Press.mp4?alt=media&token=66fddf1c-26ff-421f-bc73-cb688932fa47",
    video_id: "CnB_Decline_Chest_Press_1",
    video_length: 0.07,
    video_number: "229",
    size: 930164,
    hints: "CnB_Decline_Chest_Press_1_hints",
    common_mistakes: "CnB_Decline_Chest_Press_1_common_mistakes",
    breathing: "CnB_Decline_Chest_Press_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Decline Close Grip Press",
    is_free: false,
    primary_muscle: "Lower Chest, Triceps",
    rep_range: "10-20",
    secondary_muscle: "Upper Chest, Middle Chest, Shoulders, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FDecline%20Close%20Grip%20Press-0.jpg?alt=media&token=3d4429f7-ff8b-47b7-9cd0-c31c26363944",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fchest%2FDecline%20Close%20Grip%20Press.mp4?alt=media&token=bbf8ed7d-ec88-47b1-915a-41b2e2ff04e2",
    video_id: "CnB_Decline_Close_Grip_Press_1",
    video_length: 0.07,
    video_number: "230",
    size: 842352,
    hints: "CnB_Decline_Close_Grip_Press_1_hints",
    common_mistakes: "CnB_Decline_Close_Grip_Press_1_common_mistakes",
    breathing: "CnB_Decline_Close_Grip_Press_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Decline Fly",
    is_free: false,
    primary_muscle: "Lower Chest",
    rep_range: "10-20",
    secondary_muscle: "Upper Chest, Middle Chest, Shoulders, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Biceps, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FDecline%20Fly-0.jpg?alt=media&token=4348eeb3-5589-4355-a0c8-186ce0f9422c",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fchest%2FDecline%20Fly.mp4?alt=media&token=cdc3f3b2-1fdb-435e-9c95-d24b65c582e2",
    video_id: "CnB_Decline_Fly_1",
    video_length: 0.07,
    video_number: "231",
    size: 1599475,
    hints: "CnB_Decline_Fly_1_hints",
    common_mistakes: "CnB_Decline_Fly_1_common_mistakes",
    breathing: "CnB_Decline_Fly_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Standing Alternating Chest Fly",
    is_free: true,
    primary_muscle: "Upper Chest, Front Shoulders, Middle Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Middle Chest, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle: "Lower Chest, Rear Shoulders, Traps, Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FStanding%20Alternating%20Chest%20Fly-0.jpg?alt=media&token=3f8f206f-5705-4c5c-bf2d-56e9aedfb1c7",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fchest%2FStanding%20Alternating%20Chest%20Fly.mp4?alt=media&token=7f5e8674-80ce-4c5b-8ec3-eb9f42a82acb",
    video_id: "CnB_Standing_Alternating_Chest_Fly_1",
    video_length: 0.07,
    video_number: "232",
    size: 1599475,
    hints: "CnB_Standing_Alternating_Chest_Fly_1_hints",
    common_mistakes: "CnB_Standing_Alternating_Chest_Fly_1_common_mistakes",
    breathing: "CnB_Standing_Alternating_Chest_Fly_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Elbow Fly",
    is_free: false,
    primary_muscle: "Lower Chest",
    rep_range: "10-20",
    secondary_muscle: "Middle Chest, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle: "Upper Chest, Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FElbow%20Fly-0.jpg?alt=media&token=763468e9-ec54-4a47-95c1-62d6096abd5b",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fchest%2FElbow%20Fly.mp4?alt=media&token=df74e1fc-05fb-4fff-a70a-d300cbbc974c",
    video_id: "CnB_Elbow_Fly_1",
    video_length: 0.07,
    video_number: "233",
    size: 1264905,
    hints: "CnB_Elbow_Fly_1_hints",
    common_mistakes: "CnB_Elbow_Fly_1_common_mistakes",
    breathing: "CnB_Elbow_Fly_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Fly Chest Press",
    is_free: false,
    primary_muscle: "Middle Chest",
    rep_range: "10-20",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Triceps, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Biceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FFly%20Chest%20Press-0.jpg?alt=media&token=13746f2f-452a-4434-91be-0dd97dddbb38",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fchest%2FFly%20Chest%20Press.mp4?alt=media&token=d2667fd7-ac33-4516-bcd6-243b3dd41afc",
    video_id: "CnB_Fly_Chest_Press_1",
    video_length: 0.07,
    video_number: "234",
    size: 1452883,
    hints: "CnB_Fly_Chest_Press_1_hints",
    common_mistakes: "CnB_Fly_Chest_Press_1_common_mistakes",
    breathing: "CnB_Fly_Chest_Press_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Lying Upward Fly",
    is_free: false,
    primary_muscle: "Upper Chest",
    rep_range: "10-20",
    secondary_muscle: "Middle Chest, Shoulders, Triceps, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Lower Chest, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FLying%20Upward%20Fly-0.jpg?alt=media&token=0019be94-e2ca-44b8-8773-3d319dd51a80",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fchest%2FLying%20Upward%20Fly.mp4?alt=media&token=389097e8-6ef2-4104-9ad9-500607e09a4f",
    video_id: "CnB_Lying_Upward_Fly_1",
    video_length: 0.07,
    video_number: "235",
    size: 1618320,
    hints: "CnB_Lying_Upward_Fly_1_hints",
    common_mistakes: "CnB_Lying_Upward_Fly_1_common_mistakes",
    breathing: "CnB_Lying_Upward_Fly_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Seated Chest Fly",
    is_free: false,
    primary_muscle: "Upper Chest, Front Shoulders, Middle Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Middle Chest, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle: "Lower Chest, Rear Shoulders, Traps, Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSeated%20Chest%20Fly-0.jpg?alt=media&token=dcc57a8d-3516-4dc8-b648-7eb28a56282e",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fchest%2FSeated%20Chest%20Fly.mp4?alt=media&token=6b1f991a-789e-459a-8168-306069a475f1",
    video_id: "CnB_Seated_Chest_Fly_1",
    video_length: 0.07,
    video_number: "236",
    size: 1493491,
    hints: "CnB_Seated_Chest_Fly_1_hints",
    common_mistakes: "CnB_Seated_Chest_Fly_1_common_mistakes",
    breathing: "CnB_Seated_Chest_Fly_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Half Way Rotating Reverse Press",
    is_free: false,
    primary_muscle: "Upper Chest",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Middle Chest, Shoulders, Triceps, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Lower Chest, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FHalf%20Way%20Rotating%20Reverse%20Press-0.jpg?alt=media&token=9fc8cfac-1f27-48fc-93e5-e7a064d54527",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fchest%2FHalf%20Way%20Rotating%20Reverse%20Press.mp4?alt=media&token=f124bb52-93c6-4244-8ff2-74802983bd6f",
    video_id: "CnB_Half_Way_Rotating_Reverse_Press_1",
    video_length: 0.07,
    video_number: "237",
    size: 2185481,
    hints: "CnB_Half_Way_Rotating_Reverse_Press_1_hints",
    common_mistakes: "CnB_Half_Way_Rotating_Reverse_Press_1_common_mistakes",
    breathing: "CnB_Half_Way_Rotating_Reverse_Press_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Decline Close Grip Press Into Fly",
    is_free: false,
    primary_muscle: "Lower Chest, Triceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Middle Chest, Upper Chest, Shoulders, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FDecline%20Close%20Grip%20Press%20Into%20Fly-0.jpg?alt=media&token=6c4af4fe-939b-4f6f-b2b8-10ecd8e972ab",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fchest%2FDecline%20Close%20Grip%20Press%20Into%20Fly.mp4?alt=media&token=bed37044-8ffd-43c5-9aa8-2b3f69929ac1",
    video_id: "CnB_Decline_Close_Grip_Press_Into_Fly_1",
    video_length: 0.07,
    video_number: "238",
    size: 2197083,
    hints: "CnB_Decline_Close_Grip_Press_Into_Fly_1_hints",
    common_mistakes: "CnB_Decline_Close_Grip_Press_Into_Fly_1_common_mistakes",
    breathing: "CnB_Decline_Close_Grip_Press_Into_Fly_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Incline pushup",
    is_free: false,
    primary_muscle: "Lower Chest",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Upper Chest, Middle Chest, Shoulders, Triceps, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FIncline%20pushup-0.jpg?alt=media&token=4df2ede7-fcc5-4afd-ba9a-5521dd14d6f4",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "Chair",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fchest%2FIncline%20pushup.mp4?alt=media&token=030fdf8d-1643-4b18-8732-5c1953482bae",
    video_id: "CnB_Incline_pushup_1",
    video_length: 0.07,
    video_number: "239",
    size: 850082,
    hints: "CnB_Incline_pushup_1_hints",
    common_mistakes: "CnB_Incline_pushup_1_common_mistakes",
    breathing: "CnB_Incline_pushup_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Floor Fly Thumbs In & Out",
    is_free: false,
    primary_muscle: "Middle Chest",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Biceps, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FFloor%20Fly%20Thumbs%20In%20%26%20Out-0.jpg?alt=media&token=27b1d9b9-ac0e-4169-9f3f-e46ce4e00d0d",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fchest%2FFloor%20Fly%20Thumbs%20In%20%26%20Out.mp4?alt=media&token=e36a5838-a076-424e-8282-d38c57cb9f9a",
    video_id: "CnB_Floor_Fly_Thumbs_In_Out_1",
    video_length: 0.07,
    video_number: "240",
    size: 3545646,
    hints: "CnB_Floor_Fly_Thumbs_In_Out_1_hints",
    common_mistakes: "CnB_Floor_Fly_Thumbs_In_Out_1_common_mistakes",
    breathing: "CnB_Floor_Fly_Thumbs_In_Out_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Reverse Chest Press",
    is_free: false,
    primary_muscle: "Upper Chest",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Middle Chest, Shoulders, Triceps, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Lower Chest, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FReverse%20Chest%20Press-0.jpg?alt=media&token=9580fd43-fdf5-4873-bf95-416eac53238d",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fchest%2FReverse%20Chest%20Press.mp4?alt=media&token=a2a31610-be9e-4cf0-b650-6b12c02c316b",
    video_id: "CnB_Reverse_Chest_Press_1",
    video_length: 0.07,
    video_number: "241",
    size: 870631,
    hints: "CnB_Reverse_Chest_Press_1_hints",
    common_mistakes: "CnB_Reverse_Chest_Press_1_common_mistakes",
    breathing: "CnB_Reverse_Chest_Press_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Reverse Press Into Basic Press",
    is_free: false,
    primary_muscle: "Upper Chest, Middle Chest",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Lower Chest, Shoulders, Triceps, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FReverse%20Press%20Into%20Basic%20Press-0.jpg?alt=media&token=52226a62-b844-4a93-ba61-3afc33ed41e2",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fchest%2FReverse%20Press%20Into%20Basic%20Press.mp4?alt=media&token=d1c9f9c6-a8cd-462c-8414-385524576d79",
    video_id: "CnB_Reverse_Press_Into_Basic_Press_1",
    video_length: 0.07,
    video_number: "242",
    size: 2285272,
    hints: "CnB_Reverse_Press_Into_Basic_Press_1_hints",
    common_mistakes: "CnB_Reverse_Press_Into_Basic_Press_1_common_mistakes",
    breathing: "CnB_Reverse_Press_Into_Basic_Press_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Hammer Fly",
    is_free: false,
    primary_muscle: "Middle Chest",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Biceps, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FHammer%20Fly-0.jpg?alt=media&token=692e6afb-ddd1-4c21-b1c9-fccb8b727a7d",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fchest%2FHammer%20Fly.mp4?alt=media&token=9006497e-53de-439e-94d6-94e653bb3ec3",
    video_id: "CnB_Hammer_Fly_1",
    video_length: 0.07,
    video_number: "243",
    size: 1488429,
    hints: "CnB_Hammer_Fly_1_hints",
    common_mistakes: "CnB_Hammer_Fly_1_common_mistakes",
    breathing: "CnB_Hammer_Fly_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Close Chest Press Into Hammer Fly",
    is_free: false,
    primary_muscle: "Middle Chest, Triceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Biceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FClose%20Chest%20Press%20Into%20Hammer%20Fly-0.jpg?alt=media&token=e7cc3678-e1c1-4764-bf0d-b95bfac55168",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fchest%2FClose%20Chest%20Press%20Into%20Hammer%20Fly.mp4?alt=media&token=a7af1cf6-d862-4761-ad49-4b088a91ae73",
    video_id: "CnB_Close_Chest_Press_Into_Hammer_Fly_1",
    video_length: 0.07,
    video_number: "244",
    size: 2487962,
    hints: "CnB_Close_Chest_Press_Into_Hammer_Fly_1_hints",
    common_mistakes: "CnB_Close_Chest_Press_Into_Hammer_Fly_1_common_mistakes",
    breathing: "CnB_Close_Chest_Press_Into_Hammer_Fly_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Chest Press Into Slow Negative Fly",
    is_free: true,
    primary_muscle: "Middle Chest",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Triceps, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Biceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FChest%20Press%20Into%20Slow%20Negative%20Fly-0.jpg?alt=media&token=b8c55367-4826-419b-aa50-0fb4344036ee",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fchest%2FChest%20Press%20Into%20Slow%20Negative%20Fly.mp4?alt=media&token=89463a9c-bbaa-4cac-ad8a-a277fe68df95",
    video_id: "CnB_Chest_Press_Into_Slow_Negative_Fly_1",
    video_length: 0.07,
    video_number: "245",
    size: 2131020,
    hints: "CnB_Chest_Press_Into_Slow_Negative_Fly_1_hints",
    common_mistakes: "CnB_Chest_Press_Into_Slow_Negative_Fly_1_common_mistakes",
    breathing: "CnB_Chest_Press_Into_Slow_Negative_Fly_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Standing Hammer Chest Fly",
    is_free: false,
    primary_muscle: "Upper Chest, Front Shoulders, Middle Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Middle Chest, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle: "Lower Chest, Rear Shoulders, Traps, Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FStanding%20Hammer%20Chest%20Fly-0.jpg?alt=media&token=ea2a0a95-e02d-4061-8418-06d4d9c776e9",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fchest%2FStanding%20Hammer%20Chest%20Fly.mp4?alt=media&token=3713a4ca-c5d6-4818-b7c9-08562cb161af",
    video_id: "CnB_Standing_Hammer_Chest_Fly_1",
    video_length: 0.07,
    video_number: "246",
    size: 1566321,
    hints: "CnB_Standing_Hammer_Chest_Fly_1_hints",
    common_mistakes: "CnB_Standing_Hammer_Chest_Fly_1_common_mistakes",
    breathing: "CnB_Standing_Hammer_Chest_Fly_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Double Single Hammer Press Into Hammer Press",
    is_free: false,
    primary_muscle: "Middle Chest",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Triceps, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FDouble%20Single%20Hammer%20Press%20Into%20Hammer%20Press-0.jpg?alt=media&token=1e43a5c3-dbf1-4fc5-a82e-53d18ea600c4",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fchest%2FDouble%20Single%20Hammer%20Press%20Into%20Hammer%20Press.mp4?alt=media&token=58b9796b-91c2-4353-b51c-d0034772fb77",
    video_id: "CnB_Double_Single_Hammer_Press_Into_Hammer_Press_1",
    video_length: 0.07,
    video_number: "247",
    size: 2753292,
    hints: "CnB_Double_Single_Hammer_Press_Into_Hammer_Press_1_hints",
    common_mistakes: "CnB_Double_Single_Hammer_Press_Into_Hammer_Press_1_common_mistakes",
    breathing: "CnB_Double_Single_Hammer_Press_Into_Hammer_Press_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Y Press",
    is_free: true,
    primary_muscle: "Middle Chest",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Triceps, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FY%20Press-0.jpg?alt=media&token=ed4b39f8-ce97-450e-8ee1-d65fe066c349",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fchest%2FY%20Press.mp4?alt=media&token=4cf55c69-9527-4400-b2b3-32bd27c93e4d",
    video_id: "CnB_Y_Press_1",
    video_length: 0.07,
    video_number: "248",
    size: 841779,
    hints: "CnB_Y_Press_1_hints",
    common_mistakes: "CnB_Y_Press_1_common_mistakes",
    breathing: "CnB_Y_Press_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Rotating Reverse Press",
    is_free: false,
    primary_muscle: "Upper Chest",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Middle Chest, Shoulders, Triceps, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Lower Chest, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FRotating%20Reverse%20Press%20(1).jpeg?alt=media&token=dd15d7cb-b293-48c9-b6a5-4d4d3584eb30",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fchest%2FRotating%20Reverse%20Press.mp4?alt=media&token=86b09336-d540-4c4c-89fb-4b89e1b22e82",
    video_id: "CnB_Rotating_Reverse_Press_1",
    video_length: 0.07,
    video_number: "249",
    size: 841779,
    hints: "CnB_Rotating_Reverse_Press_1_hints",
    common_mistakes: "CnB_Rotating_Reverse_Press_1_common_mistakes",
    breathing: "CnB_Rotating_Reverse_Press_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Wide Reverse Grip Press",
    is_free: true,
    primary_muscle: "Upper Chest",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Middle Chest, Shoulders, Triceps, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Lower Chest, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FWide%20Reverse%20Grip%20Press-0.jpg?alt=media&token=e9e7501c-0f9e-4220-8773-c1fa8fc20dab",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fchest%2FWide%20Reverse%20Grip%20Press.mp4?alt=media&token=ea64789e-2bdd-4cbf-9fc8-9ea3df71d313",
    video_id: "CnB_Wide_Reverse_Grip_Press_1",
    video_length: 0.07,
    video_number: "250",
    size: 1049636,
    hints: "CnB_Wide_Reverse_Grip_Press_1_hints",
    common_mistakes: "CnB_Wide_Reverse_Grip_Press_1_common_mistakes",
    breathing: "CnB_Wide_Reverse_Grip_Press_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Single Dumbbell Chest Press",
    is_free: false,
    primary_muscle: "Middle Chest",
    rep_range: "10-12",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Triceps, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSingle%20Dumbbell%20Chest%20Press-0.jpg?alt=media&token=a7aeeb67-bf76-4c60-a246-958b20242bec",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fchest%2FSingle%20Dumbbell%20Chest%20Press.mp4?alt=media&token=a8ed276f-92f7-46fd-9d6f-be7634703bb2",
    video_id: "CnB_Single_Dumbbell_Chest_Press_1",
    video_length: 0.07,
    video_number: "251",
    size: 978522,
    hints: "CnB_Single_Dumbbell_Chest_Press_1_hints",
    common_mistakes: "CnB_Single_Dumbbell_Chest_Press_1_common_mistakes",
    breathing: "CnB_Single_Dumbbell_Chest_Press_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Standing Chest Fly",
    is_free: false,
    primary_muscle: "Upper Chest, Front Shoulders, Middle Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Middle Chest, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle: "Lower Chest, Rear Shoulders, Traps, Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FStanding%20Chest%20Fly-0.jpg?alt=media&token=0d55134c-0745-4bd7-bf0e-4d0906f5040e",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fchest%2FStanding%20Chest%20Fly.mp4?alt=media&token=2e8727da-766b-4f7d-b0e7-e6ee75f071bc",
    video_id: "CnB_Standing_Chest_Fly_1",
    video_length: 0.07,
    video_number: "252",
    size: 1105562,
    hints: "CnB_Standing_Chest_Fly_1_hints",
    common_mistakes: "CnB_Standing_Chest_Fly_1_common_mistakes",
    breathing: "CnB_Standing_Chest_Fly_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Chest Press Into Close Chest Press",
    is_free: false,
    primary_muscle: "Middle Chest, Triceps",
    rep_range: "10-20",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FChest%20Press%20Into%20Close%20Chest%20Press-0.jpg?alt=media&token=4a96674e-5bba-4a7f-8a95-75942f39be57",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fchest%2FChest%20Press%20Into%20Close%20Chest%20Press.mp4?alt=media&token=4db8adc5-5e9f-47e8-8d4d-b789da70a0bd",
    video_id: "CnB_Chest_Press_Into_Close_Chest_Press_1",
    video_length: 0.07,
    video_number: "253",
    size: 2312770,
    hints: "CnB_Chest_Press_Into_Close_Chest_Press_1_hints",
    common_mistakes: "CnB_Chest_Press_Into_Close_Chest_Press_1_common_mistakes",
    breathing: "CnB_Chest_Press_Into_Close_Chest_Press_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Decline Close Grip Floor Press",
    is_free: false,
    primary_muscle: "Lower Chest",
    rep_range: "10-20",
    secondary_muscle: "Upper Chest, Middle Chest, Shoulders, Triceps, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FDecline%20Close%20Grip%20Floor%20Press-0.jpg?alt=media&token=93545ea8-7e3e-4c5a-b277-0a00c9706054",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fchest%2FDecline%20Close%20Grip%20Floor%20Press.mp4?alt=media&token=c72f92e3-df38-4c7c-97aa-d2b278335f6a",
    video_id: "CnB_Decline_Close_Grip_Floor_Press_1",
    video_length: 0.07,
    video_number: "254",
    size: 1147438,
    hints: "CnB_Decline_Close_Grip_Floor_Press_1_hints",
    common_mistakes: "CnB_Decline_Close_Grip_Floor_Press_1_common_mistakes",
    breathing: "CnB_Decline_Close_Grip_Floor_Press_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Double Reverse Grip Press Into Fly",
    is_free: false,
    primary_muscle: "Upper Chest, Middle Chest",
    rep_range: "5-10",
    secondary_muscle: "Lower Chest, Shoulders, Triceps, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Biceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FDouble%20Reverse%20Grip%20Press%20Into%20Fly-0.jpg?alt=media&token=41326e5f-c4ef-427b-b3fb-bac844880022",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fchest%2FDouble%20Reverse%20Grip%20Press%20Into%20Fly.mp4?alt=media&token=7fc9b26c-405b-4778-874f-c061dedeae2e",
    video_id: "CnB_Double_Reverse_Grip_Press_Into_Fly_1",
    video_length: 0.07,
    video_number: "255",
    size: 3015485,
    hints: "CnB_Double_Reverse_Grip_Press_Into_Fly_1_hints",
    common_mistakes: "CnB_Double_Reverse_Grip_Press_Into_Fly_1_common_mistakes",
    breathing: "CnB_Double_Reverse_Grip_Press_Into_Fly_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Slow Negative Chest Fly Press",
    is_free: true,
    primary_muscle: "Middle Chest",
    rep_range: "10-20",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Biceps, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSlow%20Negative%20Chest%20Fly%20Press-0.jpg?alt=media&token=6e327ccc-92d3-4a80-bdc1-d5d5a022f9b7",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fchest%2FSlow%20Negative%20Chest%20Fly%20Press.mp4?alt=media&token=fdc742ee-ebeb-46c8-a0c9-9b50dd06ce06",
    video_id: "CnB_Slow_Negative_Chest_Fly_Press_1",
    video_length: 0.07,
    video_number: "256",
    size: 1899119,
    hints: "CnB_Slow_Negative_Chest_Fly_Press_1_hints",
    common_mistakes: "CnB_Slow_Negative_Chest_Fly_Press_1_common_mistakes",
    breathing: "CnB_Slow_Negative_Chest_Fly_Press_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Slow Negative Close Chest Press",
    is_free: false,
    primary_muscle: "Middle Chest",
    rep_range: "10-20",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Biceps, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSlow%20Negative%20Close%20Chest%20Press%20(1).jpeg?alt=media&token=d4ee0780-49e4-4a53-8537-1d28507ae1f5",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fchest%2FSlow%20Negative%20Close%20Chest%20Press.mp4?alt=media&token=e80a459e-ad06-495e-9d1b-22a9895e4198",
    video_id: "CnB_Slow_Negative_Close_Chest_Press_1",
    video_length: 0.07,
    video_number: "257",
    size: 1899119,
    hints: "CnB_Slow_Negative_Close_Chest_Press_1_hints",
    common_mistakes: "CnB_Slow_Negative_Close_Chest_Press_1_common_mistakes",
    breathing: "CnB_Slow_Negative_Close_Chest_Press_1_breathing",
  },
  {
    category_id: 1,
    category_name: "chest & biceps",
    name: "Wall Push Up",
    is_free: false,
    primary_muscle: "Middle Chest",
    rep_range: "10-20",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Triceps, Serratus Anterior",
    subcategory_name: "chest",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FWall%20Push%20Up-0.jpg?alt=media&token=4d230403-8384-4c31-9c2e-22cb54e22c03",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fchest%2FWall%20Push%20Up.mp4?alt=media&token=fc33368f-9cd6-4e99-b9a3-d4d4b5c104bc",
    video_id: "CnB_Wall_Push_Up_1",
    video_length: 0.07,
    video_number: "258",
    size: 1338621,
    hints: "CnB_Wall_Push_Up_1_hints",
    common_mistakes: "CnB_Wall_Push_Up_1_common_mistakes",
    breathing: "CnB_Wall_Push_Up_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Kneeling Calf Raise",
    is_free: false,
    primary_muscle: "Calves",
    is_other_side: 0,
    rep_range: "8-20",
    secondary_muscle: "Hamstrings, Adductors, Traps, Lower Back, Latissimus Dorsi",
    subcategory_name: "legs",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(L)%20Kneeling%20Calf%20Raise-0.jpg?alt=media&token=ff57265b-af8f-4c34-a1f9-324b36a32784",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2F(L)%20Kneeling%20Calf%20Raise.mp4?alt=media&token=0251c4aa-3d0c-4208-83fa-68fc4cab6b86",
    video_id: "LnB_Kneeling_Calf_Raise_1",
    video_length: 0.05,
    video_number: "259",
    size: 761580,
    hints: "LnB_Kneeling_Calf_Raise_1_hints",
    common_mistakes: "LnB_Kneeling_Calf_Raise_1_common_mistakes",
    breathing: "LnB_Kneeling_Calf_Raise_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Kneeling Calf Raise",
    is_free: false,
    primary_muscle: "Calves",
    is_other_side: 1,
    rep_range: "8-20",
    secondary_muscle: "Hamstrings, Adductors, Traps, Lower Back, Latissimus Dorsi",
    subcategory_name: "legs",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(R)%20Kneeling%20Calf%20Raise-0.jpg?alt=media&token=0b816dce-7295-411c-9760-635bae3ea44b",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2F(R)%20Kneeling%20Calf%20Raise.mp4?alt=media&token=ee886636-161a-4965-9699-f4137fb49953",
    video_id: "LnB_Kneeling_Calf_Raise_2",
    video_length: 0.05,
    video_number: "260",
    size: 810556,
    hints: "LnB_Kneeling_Calf_Raise_2_hints",
    common_mistakes: "LnB_Kneeling_Calf_Raise_2_common_mistakes",
    breathing: "LnB_Kneeling_Calf_Raise_2_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Glute Bridge",
    is_free: false,
    primary_muscle: "Glutes, Hamstrings, Core",
    rep_range: "10-20",
    secondary_muscle: "Adductors",
    subcategory_name: "legs",
    tertiary_muscle: "Calves, Quads",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FGlute%20Bridge%20(Legs)%20(1).jpeg?alt=media&token=55f667d9-d89c-4d75-bb5c-7601456307ef",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2FGlute%20Bridge.mp4?alt=media&token=16f028cf-3d66-4dc5-baf4-44f195e23fe4",
    video_id: "LnB_Glute_Bridge_1",
    video_length: 0.05,
    video_number: "261",
    size: 1299831,
    hints: "LnB_Glute_Bridge_1_hints",
    common_mistakes: "LnB_Glute_Bridge_1_common_mistakes",
    breathing: "LnB_Glute_Bridge_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Bulgarian Split Squat",
    is_other_side: 0,
    is_free: false,
    primary_muscle: "Quads, Glutes, Calves",
    rep_range: "8-16",
    secondary_muscle: "Hamstrings, Adductors, Erector Spinae",
    subcategory_name: "legs",
    tertiary_muscle: "Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(L)%20Bulgarian%20Split%20Squat-0.jpg?alt=media&token=834f422b-3b76-460b-9031-b1aca002fb08",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2F(L)%20Bulgarian%20Split%20Squat.mp4?alt=media&token=fb8908ed-061c-4b54-aa06-25e49e4926a7",
    video_id: "LnB_Bulgarian_Split_Squat_1",
    video_length: 0.05,
    video_number: "262",
    size: 1104011,
    hints: "LnB_Bulgarian_Split_Squat_1_hints",
    common_mistakes: "LnB_Bulgarian_Split_Squat_1_common_mistakes",
    breathing: "LnB_Bulgarian_Split_Squat_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Bulgarian Split Squat",
    is_other_side: 1,
    is_free: false,
    primary_muscle: "Quads, Glutes, Calves",
    rep_range: "8-16",
    secondary_muscle: "Hamstrings, Adductors, Erector Spinae",
    subcategory_name: "legs",
    tertiary_muscle: "Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(R)%20Bulgarian%20Split%20Squat-0.jpg?alt=media&token=1ded211d-a3e2-4c3f-9bdf-7ce57449df2e",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2F(R)%20Bulgarian%20Split%20Squat.mp4?alt=media&token=eb78506f-e12d-41d9-aace-d104e784ef6b",
    video_id: "LnB_Bulgarian_Split_Squat_2",
    video_length: 0.05,
    video_number: "263",
    size: 1318480,
    hints: "LnB_Bulgarian_Split_Squat_2_hints",
    common_mistakes: "LnB_Bulgarian_Split_Squat_2_common_mistakes",
    breathing: "LnB_Bulgarian_Split_Squat_2_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "RDL Into Squat",
    is_free: false,
    primary_muscle: "Quads, Glutes, Erector Spinae",
    rep_range: "8-20",
    secondary_muscle: "Hamstrings, Adductors, Traps, Lower Back, Latissimus Dorsi",
    subcategory_name: "legs",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FRDL%20Into%20Squat-0.jpg?alt=media&token=15a9dd27-15b7-4cbb-bdf0-7df283eefed5",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2FRDL%20Into%20Squat.mp4?alt=media&token=b527ad58-d922-4de1-80bd-b39d9e7dea93",
    video_id: "LnB_RDL_Into_Squat_1",
    video_length: 0.05,
    video_number: "264",
    size: 2567124,
    hints: "LnB_RDL_Into_Squat_1_hints",
    common_mistakes: "LnB_RDL_Into_Squat_1_common_mistakes",
    breathing: "LnB_RDL_Into_Squat_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Step Up From Ground",
    is_free: false,
    primary_muscle: "Quads, Hamstrings, Glutes",
    rep_range: "5-10",
    secondary_muscle: "Adductors, Calves",
    subcategory_name: "legs",
    tertiary_muscle: "Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FStep%20Up%20From%20Ground-0.jpg?alt=media&token=da186cd7-a2fd-440a-ba7f-6a4e8eec585d",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2FStep%20Up%20From%20Ground.mp4?alt=media&token=d0e7b465-7ef2-4785-a31b-24ae3fa102ca",
    video_id: "LnB_Step_Up_From_Ground_1",
    video_length: 0.05,
    video_number: "265",
    size: 4498149,
    hints: "LnB_Step_Up_From_Ground_1_hints",
    common_mistakes: "LnB_Step_Up_From_Ground_1_common_mistakes",
    breathing: "LnB_Step_Up_From_Ground_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Romanian Deadlift",
    is_free: false,
    primary_muscle: "Quads, Glutes, Erector Spinae",
    rep_range: "10–20",
    secondary_muscle: "Hamstrings, Adductors, Traps, Lower Back, Latissimus Dorsi",
    subcategory_name: "legs",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FRomanian%20Deadlift-0.jpg?alt=media&token=d0feecbb-54ad-4e29-959d-f375808e60a6",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2FRomanian%20Deadlift.mp4?alt=media&token=a3b2febb-cb6a-459c-92d1-b7fd58f0c4d9",
    video_id: "LnB_Romanian_Deadlift_1",
    video_length: 0.05,
    video_number: "266",
    size: 1340155,
    hints: "LnB_Romanian_Deadlift_1_hints",
    common_mistakes: "LnB_Romanian_Deadlift_1_common_mistakes",
    breathing: "LnB_Romanian_Deadlift_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Romanian Deadlift Into Calf Raise",
    is_free: false,
    primary_muscle: "Quads, Glutes, Erector Spinae, Calves",
    rep_range: "8-20",
    secondary_muscle: "Hamstrings, Adductors, Traps, Lower Back, Latissimus Dorsi",
    subcategory_name: "legs",
    tertiary_muscle: "Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FRomanian%20Deadlift%20Into%20Calf%20Raise-0.jpg?alt=media&token=57a7d502-b25f-4917-b61b-571f2fa270ac",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2FRomanian%20Deadlift%20Into%20Calf%20Raise.mp4?alt=media&token=8a42c11f-5c96-421a-9853-ba306ad95352",
    video_id: "LnB_Romanian_Deadlift_Into_Calf_Raise_1",
    video_length: 0.05,
    video_number: "267",
    size: 2001899,
    hints: "LnB_Romanian_Deadlift_Into_Calf_Raise_1_hints",
    common_mistakes: "LnB_Romanian_Deadlift_Into_Calf_Raise_1_common_mistakes",
    breathing: "LnB_Romanian_Deadlift_Into_Calf_Raise_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Close Squat Into Wide Squat",
    is_free: true,
    primary_muscle: "Quads, Glutes, Adductors, Hip Flexors",
    rep_range: "8-20",
    secondary_muscle: "Hamstrings, Erector Spinae",
    subcategory_name: "legs",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FClose%20Squat%20Into%20Wide%20Squat-0.jpg?alt=media&token=68684b42-d12c-4dd1-ac13-9ff96f4066ef",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2FClose%20Squat%20Into%20Wide%20Squat.mp4?alt=media&token=da03d75a-2e85-4aa8-9343-fe759634c22f",
    video_id: "LnB_Close_Squat_Into_Wide_Squat_1",
    video_length: 0.05,
    video_number: "268",
    size: 2981206,
    hints: "LnB_Close_Squat_Into_Wide_Squat_1_hints",
    common_mistakes: "LnB_Close_Squat_Into_Wide_Squat_1_common_mistakes",
    breathing: "LnB_Close_Squat_Into_Wide_Squat_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Air Squat",
    is_free: true,
    primary_muscle: "Quads, Glutes",
    rep_range: "10-20",
    secondary_muscle: "Hamstrings, Adductors, Erector Spinae",
    subcategory_name: "legs",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FAir%20Squat-0.jpg?alt=media&token=b899c244-a73d-457c-9024-19fe9d793eaf",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2FAir%20Squat.mp4?alt=media&token=0ef2951b-a3a9-4f17-af78-cc48f108fa49",
    video_id: "LnB_Air_Squat_1",
    video_length: 0.05,
    video_number: "269",
    size: 1081400,
    hints: "LnB_Air_Squat_1_hints",
    common_mistakes: "LnB_Air_Squat_1_common_mistakes",
    breathing: "LnB_Air_Squat_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Squat Into Side To Side Lunge",
    is_free: true,
    primary_muscle: "Quads, Glutes, Adductors, Hip Flexors",
    rep_range: "5-10",
    secondary_muscle: "Hamstrings, Erector Spinae",
    subcategory_name: "legs",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSquat%20Into%20Side%20To%20Side%20Lunge-0.jpg?alt=media&token=ee3bb1c4-2c03-42f6-b37d-1f0d22c4b98b",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2FSquat%20Into%20Side%20To%20Side%20Lunge.mp4?alt=media&token=1437a7ef-8d9a-4e34-ae22-2956f67f53cc",
    video_id: "LnB_Squat_Into_Side_To_Side_Lunge_1",
    video_length: 0.05,
    video_number: "271",
    size: 4025673,
    hints: "LnB_Squat_Into_Side_To_Side_Lunge_1_hints",
    common_mistakes: "LnB_Squat_Into_Side_To_Side_Lunge_1_common_mistakes",
    breathing: "LnB_Squat_Into_Side_To_Side_Lunge_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Squat Into Knee Kick",
    is_free: false,
    primary_muscle: "Quads, Glutes, Calves",
    rep_range: "8-20",
    secondary_muscle: "Hamstrings, Adductors, Erector Spinae",
    subcategory_name: "legs",
    tertiary_muscle: "Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSquat%20Into%20Knee%20Kick-0.jpg?alt=media&token=a0d58bff-d040-48b8-867f-e07da5f1b4b1",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2FSquat%20Into%20Knee%20Kick.mp4?alt=media&token=47c5a5d4-eb0a-4d2a-bb0c-7adbca880819",
    video_id: "LnB_Squat_Into_Knee_Kick_1",
    video_length: 0.05,
    video_number: "273",
    size: 2139697,
    hints: "LnB_Squat_Into_Knee_Kick_1_hints",
    common_mistakes: "LnB_Squat_Into_Knee_Kick_1_common_mistakes",
    breathing: "LnB_Squat_Into_Knee_Kick_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Single Dumbbell RDL",
    is_free: false,
    primary_muscle: "Quads, Glutes, Erector Spinae",
    rep_range: "10-20",
    secondary_muscle: "Hamstrings, Adductors, Traps, Lower Back, Latissimus Dorsi",
    subcategory_name: "legs",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSingle%20Dumbbell%20RDL-0.jpg?alt=media&token=48580f80-5e93-4d66-af7d-f727e4a97f48",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2FSingle%20Dumbbell%20RDL.mp4?alt=media&token=a6773f2c-2251-4781-b7e5-39b9bedcdc8a",
    video_id: "LnB_Single_Dumbbell_RDL_1",
    video_length: 0.05,
    video_number: "274",
    size: 1612201,
    hints: "LnB_Single_Dumbbell_RDL_1_hints",
    common_mistakes: "LnB_Single_Dumbbell_RDL_1_common_mistakes",
    breathing: "LnB_Single_Dumbbell_RDL_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Seated Calf Raise",
    is_free: false,
    primary_muscle: "Calves",
    rep_range: "10-20",
    secondary_muscle: "",
    subcategory_name: "legs",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSeated%20Calf%20Raise-0.jpg?alt=media&token=4b275358-cf97-47c5-8402-c96a385f7df3",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2FSeated%20Calf%20Raise.mp4?alt=media&token=c715fb83-4147-4393-be7a-0fe6d97482a8",
    video_id: "LnB_Seated_Calf_Raise_1",
    video_length: 0.05,
    video_number: "275",
    size: 763401,
    hints: "LnB_Seated_Calf_Raise_1_hints",
    common_mistakes: "LnB_Seated_Calf_Raise_1_common_mistakes",
    breathing: "LnB_Seated_Calf_Raise_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Seated Squats",
    is_free: false,
    primary_muscle: "Quads, Glutes",
    rep_range: "8-20",
    secondary_muscle: "Hamstrings, Adductors, Erector Spinae",
    subcategory_name: "legs",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSeated%20Squats-0.jpg?alt=media&token=d12e61f1-3222-421d-83f9-4411413d2f42",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2FSeated%20Squats.mp4?alt=media&token=e4ba9732-7055-46f5-908d-2f58a2ed9dce",
    video_id: "LnB_Seated_Squats_1",
    video_length: 0.05,
    video_number: "276",
    size: 1254370,
    hints: "LnB_Seated_Squats_1_hints",
    common_mistakes: "LnB_Seated_Squats_1_common_mistakes",
    breathing: "LnB_Seated_Squats_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Squat Into Pulse Squat",
    is_free: false,
    primary_muscle: "Quads, Glutes, Adductors, Hip Flexors",
    rep_range: "8-20",
    secondary_muscle: "Hamstrings, Erector Spinae",
    subcategory_name: "legs",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSquat%20Into%20Pulse%20Squat-0.jpg?alt=media&token=99a537ee-3fda-4844-acdb-f32c6c6bb813",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2FSquat%20Into%20Pulse%20Squat.mp4?alt=media&token=06d98504-cd9f-4deb-932b-f1a99d0957ed",
    video_id: "LnB_Squat_Into_Pulse_Squat_1",
    video_length: 0.05,
    video_number: "277",
    size: 2384778,
    hints: "LnB_Squat_Into_Pulse_Squat_1_hints",
    common_mistakes: "LnB_Squat_Into_Pulse_Squat_1_common_mistakes",
    breathing: "LnB_Squat_Into_Pulse_Squat_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Toes In And Out Squat",
    is_free: false,
    primary_muscle: "Quads, Glutes, Adductors, Hip Flexors",
    rep_range: "8-20",
    secondary_muscle: "Hamstrings, Erector Spinae",
    subcategory_name: "legs",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FToes%20In%20And%20Out%20Squat-0.jpg?alt=media&token=5e5a0792-303d-4885-863b-88f7a90e12f3",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2FToes%20In%20And%20Out%20Squat.mp4?alt=media&token=4f1d487f-0e10-415c-b838-ac298e9e08bc",
    video_id: "LnB_Toes_In_And_Out_Squat_1",
    video_length: 0.05,
    video_number: "278",
    size: 2899179,
    hints: "LnB_Toes_In_And_Out_Squat_1_hints",
    common_mistakes: "LnB_Toes_In_And_Out_Squat_1_common_mistakes",
    breathing: "LnB_Toes_In_And_Out_Squat_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Wide Stance Squat",
    is_free: false,
    primary_muscle: "Quads, Glutes, Adductors, Hip Flexors",
    rep_range: "8-20",
    secondary_muscle: "Hamstrings, Erector Spinae",
    subcategory_name: "legs",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FWide%20Stance%20Squat-0.jpg?alt=media&token=f462fadd-0f1c-4b34-8372-4e1a20b17af9",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2FWide%20Stance%20Squat.mp4?alt=media&token=21150cd9-6584-4c39-8c0d-a5376be5ecda",
    video_id: "LnB_Wide_Stance_Squat_1",
    video_length: 0.05,
    video_number: "279",
    size: 874495,
    hints: "LnB_Wide_Stance_Squat_1_hints",
    common_mistakes: "LnB_Wide_Stance_Squat_1_common_mistakes",
    breathing: "LnB_Wide_Stance_Squat_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Lunge Hold",
    is_other_side: 0,
    is_free: false,
    primary_muscle: "Quads, Hamstrings, Glutes",
    rep_range: "8-20",
    secondary_muscle: "Adductors",
    subcategory_name: "legs",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(L)%20Lunge%20Hold-0.jpg?alt=media&token=a770d9a4-0dfa-46d5-bc28-5a9d55175856",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2F(L)%20Lunge%20Hold.mp4?alt=media&token=b5903d96-205c-4bf1-8a3f-6d0308a46e32",
    video_id: "LnB_Lunge_Hold_1",
    video_length: 0.05,
    video_number: "280",
    size: 457556,
    hints: "LnB_Lunge_Hold_1_hints",
    common_mistakes: "LnB_Lunge_Hold_1_common_mistakes",
    breathing: "LnB_Lunge_Hold_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Lunge Hold",
    is_other_side: 1,
    is_free: false,
    primary_muscle: "Quads, Hamstrings, Glutes",
    rep_range: "8-20",
    secondary_muscle: "Adductors",
    subcategory_name: "legs",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(R)%20Lunge%20Hold-0.jpg?alt=media&token=31d7895a-2eb0-4cdb-b7f3-a8fa3a382977",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2F(R)%20Lunge%20Hold.mp4?alt=media&token=006d1623-e071-4e02-bc3b-daac19e1f8fe",
    video_id: "LnB_Lunge_Hold_2",
    video_length: 0.05,
    video_number: "281",
    size: 509069,
    hints: "LnB_Lunge_Hold_2_hints",
    common_mistakes: "LnB_Lunge_Hold_2_common_mistakes",
    breathing: "LnB_Lunge_Hold_2_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Plyo Split Squat",
    is_other_side: 0,
    is_free: false,
    primary_muscle: "Quads, Glutes, Hamstrings, Calves",
    rep_range: "8-20",
    secondary_muscle: "Adductors, Erector Spinae",
    subcategory_name: "legs",
    tertiary_muscle: "Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(L)%20Plyo%20Split%20Squat-0.jpg?alt=media&token=cadc9c6e-4802-476e-a8ea-6c9514b867ba",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2F(L)%20Plyo%20Split%20Squat.mp4?alt=media&token=bc683575-1c55-4920-9b77-9a3fbbbf05d8",
    video_id: "LnB_Plyo_Split_Squat_1",
    video_length: 0.05,
    video_number: "282",
    size: 2060718,
    hints: "LnB_Plyo_Split_Squat_1_hints",
    common_mistakes: "LnB_Plyo_Split_Squat_1_common_mistakes",
    breathing: "LnB_Plyo_Split_Squat_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Plyo Split Squat",
    is_other_side: 1,
    is_free: false,
    primary_muscle: "Quads, Glutes, Hamstrings, Calves",
    rep_range: "8-20",
    secondary_muscle: "Adductors, Erector Spinae",
    subcategory_name: "legs",
    tertiary_muscle: "Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(R)%20Plyo%20Split%20Squat-0.jpg?alt=media&token=63184d2a-95fe-42d9-99a7-8e476a058cc9",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2F(R)%20Plyo%20Split%20Squat.mp4?alt=media&token=ee57b30b-ef42-40c0-8f4c-9223a14d94dc",
    video_id: "LnB_Plyo_Split_Squat_2",
    video_length: 0.05,
    video_number: "283",
    size: 1942840,
    hints: "LnB_Plyo_Split_Squat_2_hints",
    common_mistakes: "LnB_Plyo_Split_Squat_2_common_mistakes",
    breathing: "LnB_Plyo_Split_Squat_2_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Front Squat Into RDL",
    is_free: false,
    primary_muscle: "Quads, Glutes, Erector Spinae",
    rep_range: "8-20",
    secondary_muscle: "Hamstrings, Adductors, Traps, Lower Back, Latissimus Dorsi",
    subcategory_name: "legs",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FFront%20Squat%20Into%20RDL-0.jpg?alt=media&token=15302b41-2ebf-42d8-9f35-a0abbc73f724",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2FFront%20Squat%20Into%20RDL.mp4?alt=media&token=11466748-e179-4d4e-8e73-57310587f08c",
    video_id: "LnB_Front_Squat_Into_RDL_1",
    video_length: 0.05,
    video_number: "284",
    size: 2714810,
    hints: "LnB_Front_Squat_Into_RDL_1_hints",
    common_mistakes: "LnB_Front_Squat_Into_RDL_1_common_mistakes",
    breathing: "LnB_Front_Squat_Into_RDL_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Plie Squat Arm Circles",
    is_free: false,
    primary_muscle: "Quads, Glutes, Shoulders",
    rep_range: "8-20",
    secondary_muscle: "Hamstrings, Adductors, Erector Spinae",
    subcategory_name: "legs",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FPlie%20Squat%20Arm%20Circles-0.jpg?alt=media&token=d91320e4-14f8-459e-bf3f-80a2f7a9899e",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2FPlie%20Squat%20Arm%20Circles.mp4?alt=media&token=ce64af7c-0b1c-4258-b3ca-c55c3350e229",
    video_id: "LnB_Plie_Squat_Arm_Circles_1",
    video_length: 0.05,
    video_number: "285",
    size: 1430151,
    hints: "LnB_Plie_Squat_Arm_Circles_1_hints",
    common_mistakes: "LnB_Plie_Squat_Arm_Circles_1_common_mistakes",
    breathing: "LnB_Plie_Squat_Arm_Circles_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "180 Jump Squat",
    is_free: false,
    primary_muscle: "Quads, Hamstrings, Glutes, Adductors, Calves",
    rep_range: "8-20",
    secondary_muscle: "Erector Spinae",
    subcategory_name: "legs",
    tertiary_muscle: "Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F180%20Jump%20Squat-0.jpg?alt=media&token=078a409f-141e-4e37-a0b8-1cb9e0cb65ee",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2F180%20Jump%20Squat.mp4?alt=media&token=0e621524-dd5d-458d-9b51-da5859400f33",
    video_id: "LnB_180_Jump_Squat_1",
    video_length: 0.05,
    video_number: "286",
    size: 2121845,
    hints: "LnB_180_Jump_Squat_1_hints",
    common_mistakes: "LnB_180_Jump_Squat_1_common_mistakes",
    breathing: "LnB_180_Jump_Squat_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Box Squat Jump",
    is_free: false,
    primary_muscle: "Quads, Hamstrings, Glutes, Calves",
    rep_range: "8-20",
    secondary_muscle: "Adductors, Erector Spinae",
    subcategory_name: "legs",
    tertiary_muscle: "Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FBox%20Squat%20Jump-0.jpg?alt=media&token=3a78898a-a772-4cab-b562-a5f0fc7b66dd",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2FBox%20Squat%20Jump.mp4?alt=media&token=d875d633-d331-43ed-9326-093778b65db1",
    video_id: "LnB_Box_Squat_Jump_1",
    video_length: 0.05,
    video_number: "287",
    size: 994972,
    hints: "LnB_Box_Squat_Jump_1_hints",
    common_mistakes: "LnB_Box_Squat_Jump_1_common_mistakes",
    breathing: "LnB_Box_Squat_Jump_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Slow Negative Floor Tap Squat",
    is_free: true,
    primary_muscle: "Quads, Adductors, Hip Flexors Glutes",
    rep_range: "8-20",
    secondary_muscle: "Hamstrings, Erector Spinae",
    subcategory_name: "legs",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSlow%20Negative%20Floor%20Tap%20Squat-0.jpg?alt=media&token=346d7ed0-a32f-4820-a35c-d3ebc6b0e1b4",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2FSlow%20Negative%20Floor%20Tap%20Squat.mp4?alt=media&token=74d03074-9725-447e-9860-602a7a31e0ad",
    video_id: "LnB_Slow_Negative_Floor_Tap_Squat_1",
    video_length: 0.05,
    video_number: "288",
    size: 2606913,
    hints: "LnB_Slow_Negative_Floor_Tap_Squat_1_hints",
    common_mistakes: "LnB_Slow_Negative_Floor_Tap_Squat_1_common_mistakes",
    breathing: "LnB_Slow_Negative_Floor_Tap_Squat_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Butt Kicker Jump Squat",
    is_free: false,
    primary_muscle: "Quads, Hamstrings, Glutes, Calves",
    rep_range: "8-20",
    secondary_muscle: "Adductors, Erector Spinae",
    subcategory_name: "legs",
    tertiary_muscle: "Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FButt%20Kicker%20Jump%20Squat-0.jpg?alt=media&token=e81bffe0-8da0-41f4-a0b6-42cd9380e717",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2FButt%20Kicker%20Jump%20Squat.mp4?alt=media&token=05381ffa-c285-4063-b401-00c39263c48a",
    video_id: "LnB_Butt_Kicker_Jump_Squat_1",
    video_length: 0.05,
    video_number: "289",
    size: 920108,
    hints: "LnB_Butt_Kicker_Jump_Squat_1_hints",
    common_mistakes: "LnB_Butt_Kicker_Jump_Squat_1_common_mistakes",
    breathing: "LnB_Butt_Kicker_Jump_Squat_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Squat Knee To Elbow",
    is_free: false,
    primary_muscle: "Quads, Glutes, Hip Flexors, Adductors",
    rep_range: "8-20",
    secondary_muscle: "Hamstrings, Erector Spinae",
    subcategory_name: "legs",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSquat%20Knee%20To%20Elbow-0.jpg?alt=media&token=5747a9c2-f99d-4d72-ad71-336eb281f7f7",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2FSquat%20Knee%20To%20Elbow.mp4?alt=media&token=e6c11055-7913-4250-945b-8d32930f3fa0",
    video_id: "LnB_Squat_Knee_To_Elbow_1",
    video_length: 0.05,
    video_number: "290",
    size: 2315693,
    hints: "LnB_Squat_Knee_To_Elbow_1_hints",
    common_mistakes: "LnB_Squat_Knee_To_Elbow_1_common_mistakes",
    breathing: "LnB_Squat_Knee_To_Elbow_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Squat & Reach Hop",
    is_free: false,
    primary_muscle: "Quads, Glutes, Hamstrings, Shoulders, Calves",
    rep_range: "8-20",
    secondary_muscle: "Adductors, Erector Spinae",
    subcategory_name: "legs",
    tertiary_muscle: "Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSquat%20%26%20Reach%20Hop-0.jpg?alt=media&token=50a77c68-2254-429b-9658-ea23c5a07d98",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2FSquat%20%26%20Reach%20Hop.mp4?alt=media&token=9b08bf8c-5f91-43c9-a74d-5b5ce5774ee7",
    video_id: "LnB_Squat_Reach_Hop_1",
    video_length: 0.05,
    video_number: "291",
    size: 1017280,
    hints: "LnB_Squat_Reach_Hop_1_hints",
    common_mistakes: "LnB_Squat_Reach_Hop_1_common_mistakes",
    breathing: "LnB_Squat_Reach_Hop_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Lateral Squat Jump",
    is_free: false,
    primary_muscle: "Quads, Glutes, Hamstrings, Adductors, Calves",
    rep_range: "8-20",
    secondary_muscle: "Erector Spinae",
    subcategory_name: "legs",
    tertiary_muscle: "Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FLateral%20Squat%20Jump-0.jpg?alt=media&token=cb388e44-09af-429b-8f4a-aa00575ce5c0",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2FLateral%20Squat%20Jump.mp4?alt=media&token=ddd3b917-fd98-428d-b08d-6680eccdeaf4",
    video_id: "LnB_Lateral_Squat_Jump_1",
    video_length: 0.05,
    video_number: "292",
    size: 2768786,
    hints: "LnB_Lateral_Squat_Jump_1_hints",
    common_mistakes: "LnB_Lateral_Squat_Jump_1_common_mistakes",
    breathing: "LnB_Lateral_Squat_Jump_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Squat To High Knee Jumps",
    is_free: false,
    primary_muscle: "Quads, Glutes, Adductors, Hip Flexor, Hamstrings, Calves",
    rep_range: "8-20",
    secondary_muscle: "Erector Spinae",
    subcategory_name: "legs",
    tertiary_muscle: "Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSquat%20To%20High%20Knee%20Jumps-0.jpg?alt=media&token=d488c1ff-2073-4651-9922-ea24d11d661d",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2FSquat%20To%20High%20Knee%20Jumps.mp4?alt=media&token=92285622-d030-41e2-9625-92cfe7f59719",
    video_id: "LnB_Squat_To_High_Knee_Jumps_1",
    video_length: 0.05,
    video_number: "293",
    size: 1650553,
    hints: "LnB_Squat_To_High_Knee_Jumps_1_hints",
    common_mistakes: "LnB_Squat_To_High_Knee_Jumps_1_common_mistakes",
    breathing: "LnB_Squat_To_High_Knee_Jumps_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Squat Jacks",
    is_free: false,
    primary_muscle: "Quads, Glutes, Adductors, Hip Flexor, Hamstrings, Calves",
    rep_range: "8-20",
    secondary_muscle: "Erector Spinae",
    subcategory_name: "legs",
    tertiary_muscle: "Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSquat_Jacks_Legs-0.jpeg?alt=media&token=084e9efc-6102-46af-be5c-8e75c60778d8",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2FSquat%20Jacks.mp4?alt=media&token=9b14c8b7-cf19-49ab-b44b-c8dd33d4dbc0",
    video_id: "LnB_Squat_Jacks_1",
    video_length: 0.05,
    video_number: "294",
    size: 3107729,
    hints: "LnB_Squat_Jacks_1_hints",
    common_mistakes: "LnB_Squat_Jacks_1_common_mistakes",
    breathing: "LnB_Squat_Jacks_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Sumo Squat Variations",
    is_free: false,
    primary_muscle: "Quads, Glutes, Adductors, Hip Flexors",
    rep_range: "5-10",
    secondary_muscle: "Hamstrings, Erector Spinae, Calves",
    subcategory_name: "legs",
    tertiary_muscle: "Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSumo%20Squat%20Variations-0.jpg?alt=media&token=25a2aa7a-b4e0-4d19-93f0-87d1179533e6",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2FSumo%20Squat%20Variations.mp4?alt=media&token=ac0c11d6-86de-4f15-accd-2a1dacd97832",
    video_id: "LnB_Sumo_Squat_Variations_1",
    video_length: 0.05,
    video_number: "295",
    size: 3104093,
    hints: "LnB_Sumo_Squat_Variations_1_hints",
    common_mistakes: "LnB_Sumo_Squat_Variations_1_common_mistakes",
    breathing: "LnB_Sumo_Squat_Variations_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Frog Jumps",
    is_free: false,
    primary_muscle: "Quads, Glutes, Adductors, Hip Flexors",
    rep_range: "8-20",
    secondary_muscle: "Erector Spinae",
    subcategory_name: "legs",
    tertiary_muscle: "Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FFrog%20Jumps-0.jpg?alt=media&token=30b09d55-c521-4e47-bb17-fd864472148c",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2FFrog%20Jumps.mp4?alt=media&token=bc06f3ac-9bd5-445b-9f87-7c2886205230",
    video_id: "LnB_Frog_Jumps_1",
    video_length: 0.05,
    video_number: "296",
    size: 2541835,
    hints: "LnB_Frog_Jumps_1_hints",
    common_mistakes: "LnB_Frog_Jumps_1_common_mistakes",
    breathing: "LnB_Frog_Jumps_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Squat & Reach",
    is_free: false,
    primary_muscle: "Quads, Glutes, Shoulders",
    rep_range: "8-20",
    secondary_muscle: "Hamstrings, Adductors, Erector Spinae",
    subcategory_name: "legs",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSquat%20%26%20Reach-0.jpg?alt=media&token=5c634b43-0866-4837-ad30-85cabdb74899",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2FSquat%20%26%20Reach.mp4?alt=media&token=59dbc29d-3edb-465f-a0f6-75da1c57d39a",
    video_id: "LnB_Squat_Reach_1",
    video_length: 0.05,
    video_number: "297",
    size: 1983115,
    hints: "LnB_Squat_Reach_1_hints",
    common_mistakes: "LnB_Squat_Reach_1_common_mistakes",
    breathing: "LnB_Squat_Reach_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Narrow Pulsing Front Squat",
    is_free: true,
    primary_muscle: "Quads, Glutes, Adductors, Hip Flexors",
    rep_range: "8-20",
    secondary_muscle: "Hamstrings, Erector Spinae",
    subcategory_name: "legs",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FNarrow%20Pulsing%20Front%20Squat-0.jpg?alt=media&token=37f25f19-8fea-4574-8919-80f14ba2d5ca",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2FNarrow%20Pulsing%20Front%20Squat.mp4?alt=media&token=124e1e76-ab27-45ff-a35d-05549c20824e",
    video_id: "LnB_Narrow_Pulsing_Front_Squat_1",
    video_length: 0.05,
    video_number: "298",
    size: 2663849,
    hints: "LnB_Narrow_Pulsing_Front_Squat_1_hints",
    common_mistakes: "LnB_Narrow_Pulsing_Front_Squat_1_common_mistakes",
    breathing: "LnB_Narrow_Pulsing_Front_Squat_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Hop Into Front Squat",
    is_free: false,
    primary_muscle: "Quads, Glutes, Adductors, Hip Flexors, Hamstrings, Calves",
    rep_range: "8-20",
    secondary_muscle: "Erector Spinae",
    subcategory_name: "legs",
    tertiary_muscle: "Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FHop%20Into%20Front%20Squat-0.jpg?alt=media&token=1ffb546d-eec0-4087-aab4-566bbc7e427b",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2FHop%20Into%20Front%20Squat.mp4?alt=media&token=e96440e8-afd2-495d-be98-02e1e4bbda2a",
    video_id: "LnB_Hop_Into_Front_Squat_1",
    video_length: 0.05,
    video_number: "299",
    size: 1321934,
    hints: "LnB_Hop_Into_Front_Squat_1_hints",
    common_mistakes: "LnB_Hop_Into_Front_Squat_1_common_mistakes",
    breathing: "LnB_Hop_Into_Front_Squat_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Air Calf Raise",
    is_free: false,
    primary_muscle: "Calves",
    rep_range: "10-20",
    secondary_muscle: "Erector Spinae",
    subcategory_name: "legs",
    tertiary_muscle: "Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FAir%20Calf%20Raise-0.jpg?alt=media&token=1151fd7c-af06-4401-b529-ed49b43db361",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2FAir%20Calf%20Raise.mp4?alt=media&token=15e70c94-5a31-4707-befe-608fcfaceb0b",
    video_id: "LnB_Air_Calf_Raise_1",
    video_length: 0.05,
    video_number: "300",
    size: 1685589,
    hints: "LnB_Air_Calf_Raise_1_hints",
    common_mistakes: "LnB_Air_Calf_Raise_1_common_mistakes",
    breathing: "LnB_Air_Calf_Raise_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Squat Hops",
    is_free: false,
    primary_muscle: "Quads, Hamstrings, Glutes, Calves, Hip Flexors",
    rep_range: "8-20",
    secondary_muscle: "Adductors, Erector Spinae",
    subcategory_name: "legs",
    tertiary_muscle: "Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSquat%20Hops-0.jpg?alt=media&token=185f34af-e96b-4a93-974d-7a28b5ba8530",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2FSquat%20Hops.mp4?alt=media&token=c2377f7e-3bda-453d-b9c9-8dbd6d1f2895",
    video_id: "LnB_Squat_Hops_1",
    video_length: 0.05,
    video_number: "301",
    size: 1609861,
    hints: "LnB_Squat_Hops_1_hints",
    common_mistakes: "LnB_Squat_Hops_1_common_mistakes",
    breathing: "LnB_Squat_Hops_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Side To Side Lunge",
    is_free: false,
    primary_muscle: "Quads, Glutes, Adductors, Hip Flexors",
    rep_range: "8-20",
    secondary_muscle: "Hamstrings, Erector Spinae",
    subcategory_name: "legs",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSide%20To%20Side%20Lunge-0.jpg?alt=media&token=6a59d0f9-cb5f-4e00-9075-302a3ab91082",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2FSide%20To%20Side%20Lunge.mp4?alt=media&token=6b72d658-8ab4-4048-b640-ce3bd97df69e",
    video_id: "LnB_Side_To_Side_Lunge_1",
    video_length: 0.05,
    video_number: "302",
    size: 2407459,
    hints: "LnB_Side_To_Side_Lunge_1_hints",
    common_mistakes: "LnB_Side_To_Side_Lunge_1_common_mistakes",
    breathing: "LnB_Side_To_Side_Lunge_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Narrow Squat",
    is_free: false,
    primary_muscle: "Quads, Glutes",
    rep_range: "8-20",
    secondary_muscle: "Hamstrings, Adductors, Erector Spinae",
    subcategory_name: "legs",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FNarrow%20Squat-0.jpg?alt=media&token=58d0d9ae-80be-4cc0-be78-c2332b277408",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2FNarrow%20Squat.mp4?alt=media&token=f3b50b14-11a5-44c1-a4f5-1804cf26d6ec",
    video_id: "LnB_Narrow_Squat_1",
    video_length: 0.05,
    video_number: "303",
    size: 2030155,
    hints: "LnB_Narrow_Squat_1_hints",
    common_mistakes: "LnB_Narrow_Squat_1_common_mistakes",
    breathing: "LnB_Narrow_Squat_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Rocking Calf Raise",
    is_free: false,
    primary_muscle: "Calves",
    rep_range: "8-20",
    secondary_muscle: "",
    subcategory_name: "legs",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FRocking%20Calf%20Raise-0.jpg?alt=media&token=b94dd3e0-ffe9-41fe-b2a7-9b10163c8016",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2FRocking%20Calf%20Raise.mp4?alt=media&token=7cad39cc-d494-46f1-837b-ab3060a1fcce",
    video_id: "LnB_Rocking_Calf_Raise_1",
    video_length: 0.05,
    video_number: "304",
    size: 2417838,
    hints: "LnB_Rocking_Calf_Raise_1_hints",
    common_mistakes: "LnB_Rocking_Calf_Raise_1_common_mistakes",
    breathing: "LnB_Rocking_Calf_Raise_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Single Leg Calf Raise",
    is_free: false,
    primary_muscle: "Calves",
    rep_range: "8-20",
    secondary_muscle: "",
    subcategory_name: "legs",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSingle%20Leg%20Calf%20Raise-0.jpg?alt=media&token=ec7fb520-ae1e-462f-97fb-24c2d18e3498",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2FSingle%20Leg%20Calf%20Raise.mp4?alt=media&token=9cd24e12-fd90-4876-9fc5-5da15c9eafa1",
    video_id: "LnB_Single_Leg_Calf_Raise_1",
    video_length: 0.05,
    video_number: "305",
    size: 1625396,
    hints: "LnB_Single_Leg_Calf_Raise_1_hints",
    common_mistakes: "LnB_Single_Leg_Calf_Raise_1_common_mistakes",
    breathing: "LnB_Single_Leg_Calf_Raise_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Squat Hold",
    is_free: false,
    primary_muscle: "Quads, Glutes, Adductors, Hip Flexors",
    rep_range: "Time Based",
    secondary_muscle: "Hamstrings, Erector Spinae",
    subcategory_name: "legs",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSquat%20Hold-0.jpg?alt=media&token=47668d21-ef64-48a1-b37e-0251f91ca2b5",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2FSquat%20Hold.mp4?alt=media&token=e637d378-647d-4e8a-bdad-f40fb47de014",
    video_id: "LnB_Squat_Hold_1",
    video_length: 0.05,
    video_number: "306",
    size: 758874,
    hints: "LnB_Squat_Hold_1_hints",
    common_mistakes: "LnB_Squat_Hold_1_common_mistakes",
    breathing: "LnB_Squat_Hold_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Squat Side Kick",
    is_free: false,
    primary_muscle: "Quads, Glutes, Adductors, Hip Flexors",
    rep_range: "8-20",
    secondary_muscle: "Hamstrings, Erector Spinae, Calves",
    subcategory_name: "legs",
    tertiary_muscle: "Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSquat%20Side%20Kick-0.jpg?alt=media&token=41485b66-f3cf-4671-8357-4580dd5a8d40",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2FSquat%20Side%20Kick.mp4?alt=media&token=ba4a99e8-6e2a-4ec7-be43-b636598fb0b0",
    video_id: "LnB_Squat_Side_Kick_1",
    video_length: 0.05,
    video_number: "307",
    size: 2435502,
    hints: "LnB_Squat_Side_Kick_1_hints",
    common_mistakes: "LnB_Squat_Side_Kick_1_common_mistakes",
    breathing: "LnB_Squat_Side_Kick_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Squat Star",
    is_free: false,
    primary_muscle: "Quads, Glutes, Adductors, Hip Flexor, Hamstrings, Calves",
    rep_range: "8-20",
    secondary_muscle: "Erector Spinae",
    subcategory_name: "legs",
    tertiary_muscle: "Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSquat%20Star-0.jpg?alt=media&token=4e838152-8dbf-4793-abfc-a74d0300558f",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2FSquat%20Star.mp4?alt=media&token=0ff22d39-053a-489c-a9c5-8d1824734b7b",
    video_id: "LnB_Squat_Side_Kick_1",
    video_length: 0.05,
    video_number: "308",
    size: 3052216,
    hints: "LnB_Squat_Side_Kick_1_hints",
    common_mistakes: "LnB_Squat_Side_Kick_1_common_mistakes",
    breathing: "LnB_Squat_Side_Kick_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Side Lunge Touchdown",
    is_free: true,
    primary_muscle: "Quads, Glutes, Adductors, Hip Flexors",
    rep_range: "8-20",
    secondary_muscle: "Hamstrings Calves Erector Spinae",
    subcategory_name: "legs",
    tertiary_muscle: "Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSide%20Lunge%20Touchdown-0.jpg?alt=media&token=47d56c78-4358-4e84-ae2a-238ba00cfb0c",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2FSide%20Lunge%20Touchdown.mp4?alt=media&token=1bafa93d-e347-4014-8967-db47f3ea7482",
    video_id: "LnB_Side_Lunge_Touchdown_1",
    video_length: 0.05,
    video_number: "309",
    size: 3148991,
    hints: "LnB_Side_Lunge_Touchdown_1_hints",
    common_mistakes: "LnB_Side_Lunge_Touchdown_1_common_mistakes",
    breathing: "LnB_Side_Lunge_Touchdown_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Wall Squat Walk",
    is_free: false,
    primary_muscle: "Quads, Glutes, Adductors, Hip Flexors",
    rep_range: "8-20",
    secondary_muscle: "Hamstrings Calves Erector Spinae",
    subcategory_name: "legs",
    tertiary_muscle: "Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FWall%20Squat%20Walk-0.jpg?alt=media&token=85532059-37d3-42fb-b6ac-493f11544143",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Wall",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2FWall%20Squat%20Walk.mp4?alt=media&token=ff679443-0f0f-4edd-b690-39433d4df179",
    video_id: "LnB_Wall_Squat_Walk_1",
    video_length: 0.05,
    video_number: "310",
    size: 2024433,
    hints: "LnB_Wall_Squat_Walk_1_hints",
    common_mistakes: "LnB_Wall_Squat_Walk_1_common_mistakes",
    breathing: "LnB_Wall_Squat_Walk_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Low Lat Row",
    is_other_side: 0,
    is_free: false,
    primary_muscle:
      "Latissimus Dorsi, Rhomboids, Traps, Erector Spinae, Rear Shoulders, Teres Major, Teres Minor, Infraspinatus, Lower Back",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Biceps, Middle Shoulders",
    subcategory_name: "back",
    tertiary_muscle: "Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(L)%20Low%20Lat%20Row-0.jpg?alt=media&token=f76c8c75-35d3-4962-82cd-d63e1e62859f",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2F(L)%20Low%20Lat%20Row.mp4?alt=media&token=4c110a59-dc95-4a9c-8173-121ae6696def",
    video_id: "LnB_Low_Lat_Row_1",
    video_length: 0.08,
    video_number: "311",
    size: 857409,
    hints: "LnB_Low_Lat_Row_1_hints",
    common_mistakes: "LnB_Low_Lat_Row_1_common_mistakes",
    breathing: "LnB_Low_Lat_Row_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Low Lat Row",
    is_other_side: 1,
    is_free: false,
    primary_muscle:
      "Latissimus Dorsi, Rhomboids, Traps, Erector Spinae, Rear Shoulders, Teres Major, Teres Minor, Infraspinatus, Lower Back",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Biceps, Middle Shoulders",
    subcategory_name: "back",
    tertiary_muscle: "Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(R)%20Low%20Lat%20Row-0.jpg?alt=media&token=de963fb2-cba3-4bef-8e28-360836b32afc",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2F(R)%20Low%20Lat%20Row.mp4?alt=media&token=f97a1d59-ce10-48a4-8011-ca9ed50b964e",
    video_id: "LnB_Low_Lat_Row_2",
    video_length: 0.08,
    video_number: "312",
    size: 944741,
    hints: "LnB_Low_Lat_Row_2_hints",
    common_mistakes: "LnB_Low_Lat_Row_2_common_mistakes",
    breathing: "LnB_Low_Lat_Row_2_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Reverse Shoulder Press",
    is_free: false,
    primary_muscle: "Latissimus Dorsi, Rhomboids, Traps, Erector Spinae, Shoulders, Teres Major, Teres Minor, Infraspinatus",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Biceps",
    subcategory_name: "back",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FReverse%20Shoulder%20Press-0.jpg?alt=media&token=0a8699ff-944e-4420-a03a-81ed219b4b5a",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2FReverse%20Shoulder%20Press.mp4?alt=media&token=fae42b1e-0128-40c4-a136-20c961265668",
    video_id: "LnB_Reverse_Shoulder_Press_1",
    video_length: 0.08,
    video_number: "313",
    size: 760557,
    hints: "LnB_Reverse_Shoulder_Press_1_hints",
    common_mistakes: "LnB_Reverse_Shoulder_Press_1_common_mistakes",
    breathing: "LnB_Reverse_Shoulder_Press_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Single Arm Fly",
    is_other_side: 0,
    is_free: false,
    primary_muscle: "Traps, Rhomboids, Rear Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Teres Major, Teres Minor, Infraspinatus",
    subcategory_name: "back",
    tertiary_muscle: "Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(L)%20Single%20Arm%20Fly-0.jpg?alt=media&token=f36a1151-0581-4d85-98f8-18f90c21ea34",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2F(L)%20Single%20Arm%20Fly.mp4?alt=media&token=97e8f4b4-72a3-4425-8ded-9ad359ba874d",
    video_id: "LnB_Single_Arm_Fly_1",
    video_length: 0.08,
    video_number: "314",
    size: 794832,
    hints: "LnB_Single_Arm_Fly_1_hints",
    common_mistakes: "LnB_Single_Arm_Fly_1_common_mistakes",
    breathing: "LnB_Single_Arm_Fly_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Single Arm Fly",
    is_other_side: 1,
    is_free: false,
    primary_muscle: "Traps, Rhomboids, Rear Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Teres Major, Teres Minor, Infraspinatus",
    subcategory_name: "back",
    tertiary_muscle: "Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(R)%20SIngle%20Arm%20Fly-0.jpg?alt=media&token=654272cf-c2e5-4f84-82e0-b8954d6a8344",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2F(R)%20SIngle%20Arm%20Fly.mp4?alt=media&token=da0c22f6-3931-4381-a9df-50a3f151801c",
    video_id: "LnB_Single_Arm_Fly_2",
    video_length: 0.08,
    video_number: "315",
    size: 705057,
    hints: "LnB_Single_Arm_Fly_2_hints",
    common_mistakes: "LnB_Single_Arm_Fly_2_common_mistakes",
    breathing: "LnB_Single_Arm_Fly_2_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Single Arm Pull Over",
    is_other_side: 0,
    is_free: false,
    primary_muscle: "Latissimus Dorsi",
    rep_range: "5-10",
    secondary_muscle: "Rhomboids, Rear Shoulders, Teres Major, Teres Minor, Infraspinatus, Chest, Traps",
    subcategory_name: "back",
    tertiary_muscle: "Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(L)%20Single%20Arm%20Pull%20Over-0.jpg?alt=media&token=f0c73315-da8b-427f-985b-4997313091b2",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2F(L)%20Single%20Arm%20Pull%20Over.mp4?alt=media&token=1a2d06d2-8d87-4a39-b2a9-fbdd169cce3b",
    video_id: "LnB_Single_Arm_Pull_Over_1",
    video_length: 0.08,
    video_number: "316",
    size: 2404846,
    hints: "LnB_Single_Arm_Pull_Over_1_hints",
    common_mistakes: "LnB_Single_Arm_Pull_Over_1_common_mistakes",
    breathing: "LnB_Single_Arm_Pull_Over_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Single Arm Pull Over",
    is_other_side: 1,
    is_free: false,
    primary_muscle: "Latissimus Dorsi",
    rep_range: "5-10",
    secondary_muscle: "Rhomboids, Rear Shoulders, Teres Major, Teres Minor, Infraspinatus, Chest, Traps",
    subcategory_name: "back",
    tertiary_muscle: "Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(R)%20Single%20Arm%20Pull%20Over-0.jpg?alt=media&token=27394259-b706-487e-b0d1-766ac247dc23",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2F(R)%20Single%20Arm%20Pull%20Over.mp4?alt=media&token=1fae3eb6-6181-4811-a362-1b8774200b18",
    video_id: "LnB_Single_Arm_Pull_Over_2",
    video_length: 0.08,
    video_number: "317",
    size: 2290931,
    hints: "LnB_Single_Arm_Pull_Over_2_hints",
    common_mistakes: "LnB_Single_Arm_Pull_Over_2_common_mistakes",
    breathing: "LnB_Single_Arm_Pull_Over_2_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Peggers",
    is_free: false,
    primary_muscle: "Latissimus Dorsi, Rhomboids, Traps, Erector Spinae, Shoulders, Teres Major, Teres Minor, Infraspinatus",
    rep_range: "5-10",
    secondary_muscle: "Rhomboids, Rear Shoulders, Teres Major, Teres Minor, Infraspinatus, Chest, Traps",
    subcategory_name: "back",
    tertiary_muscle: "Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FPeggers-0.jpg?alt=media&token=b7c8db9a-b24c-4752-bbcc-45d5a1ec5761",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2FPeggers.mp4?alt=media&token=ca659736-d10a-4895-8f2f-620d05872b10",
    video_id: "LnB_Peggers_1",
    video_length: 0.08,
    video_number: "318",
    size: 2558988,
    hints: "LnB_Peggers_1_hints",
    common_mistakes: "LnB_Peggers_1_common_mistakes",
    breathing: "LnB_Peggers_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Side Row Into Side Raise",
    is_free: false,
    primary_muscle:
      "Latissimus Dorsi, Rhomboids, Traps, Erector Spinae, Middle Shoulders, Rear Shoulders, Teres Major, Teres Minor, Infraspinatus",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Front Shoulders, Biceps",
    subcategory_name: "back",
    tertiary_muscle: "Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSide%20Row%20Into%20Side%20Raise-0.jpg?alt=media&token=31dc3299-a6b0-4846-b611-c628e9434d7a",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2FSide%20Row%20Into%20Side%20Raise.mp4?alt=media&token=2af2fda7-9dae-4cb4-b2fe-be26cf7b6628",
    video_id: "LnB_Side_Row_Into_Side_Raise_1",
    video_length: 0.08,
    video_number: "319",
    size: 1410816,
    hints: "LnB_Side_Row_Into_Side_Raise_1_hints",
    common_mistakes: "LnB_Side_Row_Into_Side_Raise_1_common_mistakes",
    breathing: "LnB_Side_Row_Into_Side_Raise_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Close Into Wide Row",
    is_free: false,
    primary_muscle: "Traps, Rhomboids, Erector Spinae, Latissimus Dorsi",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rear Shoulders, Teres Major, Teres Minor, Infraspinatus, Biceps",
    subcategory_name: "back",
    tertiary_muscle: "Middle Shoulders, Front Shoulders, Lower Back, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FClose%20Into%20Wide%20Row-0.jpg?alt=media&token=6d3f1c82-6f8f-4058-a6b4-0c159163ad99",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2FClose%20Into%20Wide%20Row.mp4?alt=media&token=022ac156-ab0b-407a-8ac7-1ad670596e1c",
    video_id: "LnB_Close_Into_Wide_Row_1",
    video_length: 0.08,
    video_number: "320",
    size: 1719413,
    hints: "LnB_Close_Into_Wide_Row_1_hints",
    common_mistakes: "LnB_Close_Into_Wide_Row_1_common_mistakes",
    breathing: "LnB_Close_Into_Wide_Row_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Floppy Diver",
    is_free: false,
    primary_muscle: "Traps,Rhomboids, Erector Spinae, Teres Major, Teres Minor, Infraspinatus",
    rep_range: "10-20",
    secondary_muscle: "Latissimus Dorsi, Shoulders",
    subcategory_name: "back",
    tertiary_muscle: "Middle Shoulders, Front Shoulders, Lower Back, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FFloppy%20Diver-0.jpg?alt=media&token=e6a71af3-a18d-40ed-bc58-cbeba8bb3d9f",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2FFloppy%20Diver.mp4?alt=media&token=3d204aa4-cba0-4d3b-a3e7-cf961bae98a0",
    video_id: "LnB_Floppy_Diver_1",
    video_length: 0.08,
    video_number: "321",
    size: 1172246,
    hints: "LnB_Floppy_Diver_1_hints",
    common_mistakes: "LnB_Floppy_Diver_1_common_mistakes",
    breathing: "LnB_Floppy_Diver_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Leaning Front Raise Into Rear Fly",
    is_free: true,
    primary_muscle: "Rhomboids, Traps, Erector Spinae, Shoulders, Teres Major, Teres Minor, Infraspinatus",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Latissimus Dorsi, Lower Back",
    subcategory_name: "back",
    tertiary_muscle: "Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FLeaning%20Front%20Raise%20Into%20Rear%20Fly-0.jpg?alt=media&token=06dcffae-3bda-4b02-9337-fcc74e2d2d3b",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FLeaning%20Front%20Raise%20Into%20Rear%20Fly.mp4?alt=media&token=2f1f171e-c521-405f-9c1c-5ead2508af5f",
    video_id: "LnB_Leaning_Front_Raise_Into_Rear_Fly_1",
    video_length: 0.08,
    video_number: "322",
    size: 2940977,
    hints: "LnB_Leaning_Front_Raise_Into_Rear_Fly_1_hints",
    common_mistakes: "LnB_Leaning_Front_Raise_Into_Rear_Fly_1_common_mistakes",
    breathing: "LnB_Leaning_Front_Raise_Into_Rear_Fly_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Closed Leaning Front Trap Raise",
    is_free: false,
    primary_muscle: "Rhomboids, Traps, Erector Spinae, Front Shoulders, Teres Major, Teres Minor, Infraspinatus",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Latissimus Dorsi, Lower Back, Middle Shoulders",
    subcategory_name: "back",
    tertiary_muscle: "Lower Body, Rear Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FClosed%20Leaning%20Front%20Trap%20Raise-0.jpg?alt=media&token=90ede1f9-be92-4378-9255-d9c4301a1555",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2FClosed%20Leaning%20Front%20Trap%20Raise.mp4?alt=media&token=21db6229-6c0c-4c04-926e-fdcb98839cd5",
    video_id: "LnB_Closed_Leaning_Front_Trap_Raise_1",
    video_length: 0.08,
    video_number: "323",
    size: 1049870,
    hints: "LnB_Closed_Leaning_Front_Trap_Raise_1_hints",
    common_mistakes: "LnB_Closed_Leaning_Front_Trap_Raise_1_common_mistakes",
    breathing: "LnB_Closed_Leaning_Front_Trap_Raise_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Side Arm Raise",
    is_free: true,
    primary_muscle: "Rhomboids, Traps, Erector Spinae, Rear Shoulders, Teres Major, Teres Minor, Infraspinatus",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Latissimus Dorsi, Front Shoulders, Middle Shoulders",
    subcategory_name: "back",
    tertiary_muscle: "Lower Body, Rear Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSide%20Arm%20Raise-0.jpg?alt=media&token=eae0f8e9-b1c1-4445-8c05-dfeacc3bcac4",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2FSide%20Arm%20Raise.mp4?alt=media&token=69d95084-8734-4119-834c-f1a8d9b153cc",
    video_id: "LnB_Side_Arm_Raise_1",
    video_length: 0.08,
    video_number: "324",
    size: 1453590,
    hints: "LnB_Side_Arm_Raise_1_hints",
    common_mistakes: "LnB_Side_Arm_Raise_1_common_mistakes",
    breathing: "LnB_Side_Arm_Raise_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Supinated Row Into Delt Fly",
    is_free: false,
    primary_muscle: "Rhomboids, Traps, Erector Spinae, Rear Shoulders, Teres Major, Teres Minor, Infraspinatus, Latissimus Dorsi",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Biceps, Front Shoulders, Middle Shoulders",
    subcategory_name: "back",
    tertiary_muscle: "Lower Back, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSupinated%20Row%20Into%20Delt%20Fly-0.jpg?alt=media&token=16761a22-c232-4ffd-9707-3de9f57c21ea",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2FSupinated%20Row%20Into%20Delt%20Fly.mp4?alt=media&token=14c5a259-5634-4c38-9895-2884a217d1b1",
    video_id: "LnB_Supinated_Row_Into_Delt_Fly_1",
    video_length: 0.08,
    video_number: "325",
    size: 2163245,
    hints: "LnB_Supinated_Row_Into_Delt_Fly_1_hints",
    common_mistakes: "LnB_Supinated_Row_Into_Delt_Fly_1_common_mistakes",
    breathing: "LnB_Supinated_Row_Into_Delt_Fly_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Leaning Single Rear Delt Extension Into Delt Fly",
    is_free: true,
    primary_muscle: "Rhomboids, Traps, Erector Spinae, Middle Shoulders, Rear Shoulders, Teres Major, Teres Minor, Infraspinatus",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Latissimus Dorsi",
    subcategory_name: "back",
    tertiary_muscle: "Front Shoulder, Lower Back, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FLeaning%20Single%20Rear%20Delt%20Extension%20Into%20Delt%20Fly-0.jpg?alt=media&token=7be84035-575b-4943-8ade-e19082f32fe2",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2FLeaning%20Single%20Rear%20Delt%20Extension%20Into%20Delt%20Fly.mp4?alt=media&token=4a64674b-f058-450a-a322-8c4cc51be3de",
    video_id: "LnB_Leaning_Single_Rear_Delt_Extension_Into_Delt_Fly_1",
    video_length: 0.08,
    video_number: "326",
    size: 1803455,
    hints: "LnB_Leaning_Single_Rear_Delt_Extension_Into_Delt_Fly_1_hints",
    common_mistakes: "LnB_Leaning_Single_Rear_Delt_Extension_Into_Delt_Fly_1_common_mistakes",
    breathing: "LnB_Leaning_Single_Rear_Delt_Extension_Into_Delt_Fly_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Prone X",
    is_free: false,
    primary_muscle: "Rhomboids, Traps, Erector Spinae, Shoulders, Teres Major, Teres Minor, Infraspinatus",
    rep_range: "8-16",
    secondary_muscle: "Latissimus Dorsi",
    subcategory_name: "back",
    tertiary_muscle: "Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FProne%20X-0.jpg?alt=media&token=dbfead2c-bd9c-47a4-a1f7-2851ff5eecbb",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2FProne%20X.mp4?alt=media&token=87f84096-285f-4774-b735-e590595035ac",
    video_id: "LnB_Prone_X_1",
    video_length: 0.08,
    video_number: "327",
    size: 2018301,
    hints: "LnB_Prone_X_1_hints",
    common_mistakes: "LnB_Prone_X_1_common_mistakes",
    breathing: "LnB_Prone_X_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Supinated Row Into Row",
    is_free: false,
    primary_muscle: "Latissimus Dorsi, Rhomboids, Traps, Erector Spinae, Rear Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Middle Shoulders, Teres Major, Teres Minor, Infraspinatus, Biceps",
    subcategory_name: "back",
    tertiary_muscle: "Front Shoulder, Lower Back, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSupinated%20Row%20Into%20Row-0.jpg?alt=media&token=23f6afb6-7379-4e7e-8dd8-e841d6f9ec99",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2FSupinated%20Row%20Into%20Row.mp4?alt=media&token=1f1c2c3f-43a1-440c-b770-793fad3c78f6",
    video_id: "LnB_Supinated_Row_Into_Row_1",
    video_length: 0.08,
    video_number: "328",
    size: 1810602,
    hints: "LnB_Supinated_Row_Into_Row_1_hints",
    common_mistakes: "LnB_Supinated_Row_Into_Row_1_common_mistakes",
    breathing: "LnB_Supinated_Row_Into_Row_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Side Row",
    is_free: true,
    primary_muscle: "Middle Shoulders, Rear Shoulders, Rhomboids, Teres Major, Teres Minor, Infraspinatus, Traps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Latissimus Dorsi, Biceps",
    subcategory_name: "back",
    tertiary_muscle: "Front Shoulder",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSide%20Row-0.jpg?alt=media&token=f236dfae-8f1c-4556-b15a-3313aad3c239",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2FSide%20Row.mp4?alt=media&token=5f4a9b64-a814-4621-904c-af6589df4128",
    video_id: "LnB_Side_Row_1",
    video_length: 0.08,
    video_number: "329",
    size: 1542260,
    hints: "LnB_Side_Row_1_hints",
    common_mistakes: "LnB_Side_Row_1_common_mistakes",
    breathing: "LnB_Side_Row_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Overhead Shrug",
    is_free: false,
    primary_muscle: "Traps, Rhomboids, Teres Major, Teres Minor, Infraspinatus",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Latissimus Dorsi",
    subcategory_name: "back",
    tertiary_muscle: "Front Shoulder",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FOverhead%20Shrug-0.jpg?alt=media&token=2cdca90b-686b-48de-a246-16459520663b",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2FOverhead%20Shrug.mp4?alt=media&token=036af8d2-db4f-418c-a70e-e7228779856f",
    video_id: "LnB_Overhead_Shrug_1",
    video_length: 0.08,
    video_number: "330",
    size: 1165186,
    hints: "LnB_Overhead_Shrug_1_hints",
    common_mistakes: "LnB_Overhead_Shrug_1_common_mistakes",
    breathing: "LnB_Overhead_Shrug_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Overhead Clap Shrug",
    is_free: false,
    primary_muscle: "Traps, Rhomboids, Teres Major, Teres Minor, Infraspinatus, Front Shoulders, Middle Shoulders",
    rep_range: "8-16",
    secondary_muscle: "Latissimus Dorsi",
    subcategory_name: "back",
    tertiary_muscle: "Rear Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FOverhead%20Clap%20Shrug-0.jpg?alt=media&token=6efb67e3-d5fa-45c7-accf-428fd313a0d1",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2FOverhead%20Clap%20Shrug.mp4?alt=media&token=3b21ad94-19ed-46d8-9523-5866e89aed66",
    video_id: "LnB_Overhead_Clap_Shrug_1",
    video_length: 0.08,
    video_number: "331",
    size: 2009471,
    hints: "LnB_Overhead_Clap_Shrug_1_hints",
    common_mistakes: "LnB_Overhead_Clap_Shrug_1_common_mistakes",
    breathing: "LnB_Overhead_Clap_Shrug_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Flappers",
    is_free: false,
    primary_muscle: "Latissimus Dorsi, Rear Shoulders, Teres Major, Teres Minor, Infraspinatus, Traps",
    rep_range: "10-20",
    secondary_muscle: "Middle Shoulders",
    subcategory_name: "back",
    tertiary_muscle: "Front Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FFlappers-0.jpg?alt=media&token=1d36ee64-0797-4eff-aaa9-e48f15d998f1",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2FFlappers.mp4?alt=media&token=b1d7a35d-4c0a-4036-89fd-4ea0f7d7cd10",
    video_id: "LnB_Flappers_1",
    video_length: 0.08,
    video_number: "332",
    size: 1789620,
    hints: "LnB_Flappers_1_hints",
    common_mistakes: "LnB_Flappers_1_common_mistakes",
    breathing: "LnB_Flappers_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Seated Rear Fly",
    is_free: true,
    primary_muscle: "Middle Shoulders, Rear Shoulders, Teres Major, Teres Minor, Infraspinatus, Traps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Front Shoulders",
    subcategory_name: "back",
    tertiary_muscle: "Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSeated%20Rear%20Fly-0.jpg?alt=media&token=8add4f78-54fc-4f09-90e8-8571e68a1b77",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2FSeated%20Rear%20Fly.mp4?alt=media&token=70047c50-9f66-429d-b0a8-8c84dd779755",
    video_id: "LnB_Seated_Rear_Fly_1",
    video_length: 0.08,
    video_number: "333",
    size: 914630,
    hints: "LnB_Seated_Rear_Fly_1_hints",
    common_mistakes: "LnB_Seated_Rear_Fly_1_common_mistakes",
    breathing: "LnB_Seated_Rear_Fly_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Lying Close Downward Row",
    is_free: false,
    primary_muscle: "Traps, Shoulders, Teres Major, Teres Minor, Infraspinatus",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Latissimus Dorsi",
    subcategory_name: "back",
    tertiary_muscle: "Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FLying%20Close%20Downward%20Row-0.jpg?alt=media&token=0b61af6b-9258-4fca-97af-3b26836ef547",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2FLying%20Close%20Downward%20Row.mp4?alt=media&token=1aac5092-fed3-4cb7-8e9f-033359b4c41c",
    video_id: "LnB_Lying_Close_Downward_Row_1",
    video_length: 0.08,
    video_number: "334",
    size: 1340361,
    hints: "LnB_Lying_Close_Downward_Row_1_hints",
    common_mistakes: "LnB_Lying_Close_Downward_Row_1_common_mistakes",
    breathing: "LnB_Lying_Close_Downward_Row_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Leaning Lat Row",
    is_free: true,
    primary_muscle: "Latissimus Dorsi, Teres Major, Teres Minor, Infraspinatus, Rear Shoulders, Erector Spinae",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Traps, Middle Shoulders",
    subcategory_name: "back",
    tertiary_muscle: "Front Shoulders, Lower Back, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FLeaning%20Lat%20Row-0.jpg?alt=media&token=f24f2da6-8196-4cbc-bde2-2e546613df89",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2FLeaning%20Lat%20Row.mp4?alt=media&token=d45babd7-19a9-4cad-b674-d2a768422ccd",
    video_id: "LnB_Leaning_Lat_Row_1",
    video_length: 0.08,
    video_number: "335",
    size: 2134141,
    hints: "LnB_Leaning_Lat_Row_1_hints",
    common_mistakes: "LnB_Leaning_Lat_Row_1_common_mistakes",
    breathing: "LnB_Leaning_Lat_Row_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Reacher",
    is_free: false,
    primary_muscle: "Traps, Shoulders, Teres Major, Teres Minor, Infraspinatus",
    rep_range: "10-20",
    secondary_muscle: "Latissimus Dorsi",
    subcategory_name: "back",
    tertiary_muscle: "Front Shoulders, Lower Back, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FReacher-0.jpg?alt=media&token=6ff9f108-52d2-4f6e-9ace-0e2350ca13f7",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2FReacher.mp4?alt=media&token=f37826f5-74d8-4189-98c9-439cf7c7a989",
    video_id: "LnB_Reacher_1",
    video_length: 0.08,
    video_number: "336",
    size: 1702137,
    hints: "LnB_Reacher_1_hints",
    common_mistakes: "LnB_Reacher_1_common_mistakes",
    breathing: "LnB_Reacher_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Rolling Shrug",
    is_free: false,
    primary_muscle: "Traps, Rhomboids, Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Levator Scapulae",
    subcategory_name: "back",
    tertiary_muscle: "Front Shoulders, Lower Back, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FRolling%20Shrug-0.jpg?alt=media&token=7d56e343-25fd-4adb-a0e7-2c1d8c766f19",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2FRolling%20Shrug.mp4?alt=media&token=5a9aff90-0fa9-4818-9665-efebac98d079",
    video_id: "LnB_Rolling_Shrug_1",
    video_length: 0.08,
    video_number: "337",
    size: 1211835,
    hints: "LnB_Rolling_Shrug_1_hints",
    common_mistakes: "LnB_Rolling_Shrug_1_common_mistakes",
    breathing: "LnB_Rolling_Shrug_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Reverse Side & Front Shrugs",
    is_free: false,
    primary_muscle: "Traps, Rhomboids, Rear Shoulders",
    rep_range: "5-10",
    secondary_muscle: "Levator Scapulae",
    subcategory_name: "back",
    tertiary_muscle: "Front Shoulders, Lower Back, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FReverse%20Side%20%26%20Front%20Shrugs-0.jpg?alt=media&token=002e7ebb-efdc-42c5-b2bf-3a10dcd1f8b9",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2FReverse%20Side%20%26%20Front%20Shrugs.mp4?alt=media&token=aaabf168-f392-4b1c-b9de-6dc18b029df4",
    video_id: "LnB_Reverse_Side_Front_Shrugs_1",
    video_length: 0.08,
    video_number: "338",
    size: 2813751,
    hints: "LnB_Reverse_Side_Front_Shrugs_1_hints",
    common_mistakes: "LnB_Reverse_Side_Front_Shrugs_1_common_mistakes",
    breathing: "LnB_Reverse_Side_Front_Shrugs_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Trap Raise",
    is_free: true,
    primary_muscle: "Traps, Front Shoulder",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rhomboids, Teres Major, Teres Minor, Infraspinatus, Latissimus Dorsi, Middle Shoulders, Rear Shoulders",
    subcategory_name: "back",
    tertiary_muscle: "Front Shoulders, Lower Back, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FTrap%20Raise-0.jpg?alt=media&token=1a3f902b-a609-4793-a2ef-285cfe84f7ab",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2FTrap%20Raise.mp4?alt=media&token=0d3936fd-50fd-4a1b-ad07-34d83f6dd496",
    video_id: "LnB_Trap_Raise_1",
    video_length: 0.08,
    video_number: "339",
    size: 972944,
    hints: "LnB_Trap_Raise_1_hints",
    common_mistakes: "LnB_Trap_Raise_1_common_mistakes",
    breathing: "LnB_Trap_Raise_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Leaning Rear Delt Extension Into Delt Fly",
    is_free: false,
    primary_muscle: "Rhomboids, Traps, Erector Spinae, Middle Shoulders, Rear Shoulders, Teres Major, Teres Minor, Infraspinatus",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Latissimus Dorsi",
    subcategory_name: "back",
    tertiary_muscle: "Front Shoulder, Lower Back, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FLeaning%20Rear%20Delt%20Extension%20Into%20Delt%20Fly-0.jpg?alt=media&token=3255b367-b7de-44d2-80dc-0c2111fc439d",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2FLeaning%20Rear%20Delt%20Extension%20Into%20Delt%20Fly.mp4?alt=media&token=cb39f08a-ad93-4f6c-a8b0-3bb517747acf",
    video_id: "LnB_Leaning_Rear_Delt_Extension_Into_Delt_Fly_1",
    video_length: 0.08,
    video_number: "340",
    size: 1508656,
    hints: "LnB_Leaning_Rear_Delt_Extension_Into_Delt_Fly_1_hints",
    common_mistakes: "LnB_Leaning_Rear_Delt_Extension_Into_Delt_Fly_1_common_mistakes",
    breathing: "LnB_Leaning_Rear_Delt_Extension_Into_Delt_Fly_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Y Row",
    is_free: false,
    primary_muscle:
      "Rhomboids, Latissimus Dorsi, Erector Spinae, Middle Shoulders, Rear Shoulders, Teres Major, Teres Minor, Infraspinatus",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Traps",
    subcategory_name: "back",
    tertiary_muscle: "Front Shoulders, Lower Back, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FY%20Row-0.jpg?alt=media&token=de5530dc-5eff-40a1-98d4-9a367cac29a0",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2FY%20Row.mp4?alt=media&token=e0ef6170-7289-4378-af48-3c90fda7a4ec",
    video_id: "LnB_Y_Row_1",
    video_length: 0.08,
    video_number: "341",
    size: 1072388,
    hints: "LnB_Y_Row_1_hints",
    common_mistakes: "LnB_Y_Row_1_common_mistakes",
    breathing: "LnB_Y_Row_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Low To High Back Row",
    is_free: false,
    primary_muscle: "Rhomboids, Latissimus Dorsi, Erector Spinae, Middle Shoulders, Rear Shoulders, Infraspinatus, Lower Back, Traps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Teres Major, Teres Minor, Biceps",
    subcategory_name: "back",
    tertiary_muscle: "Lower Body, Front Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FLow%20To%20High%20Back%20Row-0.jpg?alt=media&token=6c9d25cd-87a0-4c76-b739-f834bc72a404",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2FLow%20To%20High%20Back%20Row.mp4?alt=media&token=2d082498-66fa-4b13-a5a3-958dc853c938",
    video_id: "LnB_Low_To_High_Back_Row_1",
    video_length: 0.08,
    video_number: "342",
    size: 907768,
    hints: "LnB_Low_To_High_Back_Row_1_hints",
    common_mistakes: "LnB_Low_To_High_Back_Row_1_common_mistakes",
    breathing: "LnB_Low_To_High_Back_Row_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Front Shrug Into Rear Delt Fly",
    is_free: false,
    primary_muscle: "Middle Shoulders, Rear Shoulders, Teres Major, Teres Minor, Infraspinatus, Traps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Front Shoulders",
    subcategory_name: "back",
    tertiary_muscle: "Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FFront%20Shrug%20Into%20Rear%20Delt%20Fly-0.jpg?alt=media&token=0f29826c-2830-48cd-8e6a-bb62aa540a9d",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2FFront%20Shrug%20Into%20Rear%20Delt%20Fly.mp4?alt=media&token=9ed400c1-d44d-4ef3-980b-94a2f012875e",
    video_id: "LnB_Front_Shrug_Into_Rear_Delt_Fly_1",
    video_length: 0.08,
    video_number: "343",
    size: 2344829,
    hints: "LnB_Front_Shrug_Into_Rear_Delt_Fly_1_hints",
    common_mistakes: "LnB_Front_Shrug_Into_Rear_Delt_Fly_1_common_mistakes",
    breathing: "LnB_Front_Shrug_Into_Rear_Delt_Fly_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Low Row",
    is_free: false,
    primary_muscle: "Latissimus Dorsi, Rhomboids, Traps, Erector Spinae",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rear Shoulders, Teres Major, Teres Minor, Infraspinatus, Biceps",
    subcategory_name: "back",
    tertiary_muscle: "Lower Back, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FLow%20Row-0.jpg?alt=media&token=d2e12fca-4555-49ac-9d39-7d39a4a6d66e",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2FLow%20Row.mp4?alt=media&token=de4e4166-a4e6-47d3-9eb6-792855169dc6",
    video_id: "LnB_Low_Row_1",
    video_length: 0.08,
    video_number: "344",
    size: 787897,
    hints: "LnB_Low_Row_1_hints",
    common_mistakes: "LnB_Low_Row_1_common_mistakes",
    breathing: "LnB_Low_Row_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Single Dumbbell Row",
    is_free: false,
    primary_muscle: "Latissimus Dorsi, Rhomboids, Traps, Erector Spinae",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rear Shoulders, Teres Major, Teres Minor, Infraspinatus, Biceps",
    subcategory_name: "back",
    tertiary_muscle: "Lower Back, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSingle%20Dumbbell%20Row-0.jpg?alt=media&token=22111ad0-f3bb-491b-a49f-3eeeed2cd1a8",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2FSingle%20Dumbbell%20Row.mp4?alt=media&token=e4017c90-db54-4fbb-8909-5c746cc1b126",
    video_id: "LnB_Single_Dumbbell_Row_1",
    video_length: 0.08,
    video_number: "345",
    size: 713234,
    hints: "LnB_Single_Dumbbell_Row_1_hints",
    common_mistakes: "LnB_Single_Dumbbell_Row_1_common_mistakes",
    breathing: "LnB_Single_Dumbbell_Row_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Hammer Pull Over",
    is_free: false,
    primary_muscle: "Latissimus Dorsi",
    rep_range: "5-10",
    secondary_muscle: "Rhomboids, Rear Shoulders, Teres Major, Teres Minor, Infraspinatus, Chest, Traps",
    subcategory_name: "back",
    tertiary_muscle: "Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FHammer%20Pull%20Over-0.jpg?alt=media&token=3360fbfc-2985-4dc8-9a02-1e60a540ba6f",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2FHammer%20Pull%20Over.mp4?alt=media&token=19e1e555-97bb-48cd-a826-49d991814c76",
    video_id: "LnB_Hammer_Pull_Over_1",
    video_length: 0.08,
    video_number: "346",
    size: 2351765,
    hints: "LnB_Hammer_Pull_Over_1_hints",
    common_mistakes: "LnB_Hammer_Pull_Over_1_common_mistakes",
    breathing: "LnB_Hammer_Pull_Over_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Pulse Row",
    is_free: false,
    primary_muscle: "Latissimus Dorsi, Rear Shoulders, Rhomboids Teres Major, Teres Minor, Infraspinatus, Traps",
    rep_range: "10-20",
    secondary_muscle: "Middle Shoulders",
    subcategory_name: "back",
    tertiary_muscle: "Front Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FPulse%20Row-0.jpg?alt=media&token=6454bf4f-759f-4fc1-b923-bcda7a54800d",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2FPulse%20Row.mp4?alt=media&token=49e55e1f-6df6-4f5c-a477-0aeabf9a28fb",
    video_id: "LnB_Pulse_Row_1",
    video_length: 0.08,
    video_number: "347",
    size: 1835383,
    hints: "LnB_Pulse_Row_1_hints",
    common_mistakes: "LnB_Pulse_Row_1_common_mistakes",
    breathing: "LnB_Pulse_Row_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Pull Behind Shrug",
    is_free: false,
    primary_muscle: "Traps, Rhomboids",
    rep_range: "10-20",
    secondary_muscle: "Rear, Shoulders, Levator Scapulae",
    subcategory_name: "back",
    tertiary_muscle: "Front Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FPull%20Behind%20Shrug-0.jpg?alt=media&token=189afbc1-187e-457a-babb-0df362406b16",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2FPull%20Behind%20Shrug.mp4?alt=media&token=538b11a6-940c-4acd-ae48-2e9c9d89b7e3",
    video_id: "LnB_Pull_Behind_Shrug_1",
    video_length: 0.08,
    video_number: "348",
    size: 487546,
    hints: "LnB_Pull_Behind_Shrug_1_hints",
    common_mistakes: "LnB_Pull_Behind_Shrug_1_common_mistakes",
    breathing: "LnB_Pull_Behind_Shrug_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Leaning Rear Delt Extension",
    is_free: false,
    primary_muscle: "Latissimus Dorsi, Rhomboids, Rear Shoulders, Teres Major, Teres Minor, Infraspinatus, Traps, Erector Spinae",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Middle Shoulders",
    subcategory_name: "back",
    tertiary_muscle: "Front Shoulders, Lower Back, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FLeaning%20Rear%20Delt%20Extension-0.jpg?alt=media&token=20f6d195-d94a-4ba3-b3a3-66ceb576c301",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2FLeaning%20Rear%20Delt%20Extension.mp4?alt=media&token=caafe065-0f1c-4b59-a672-dca0c0e2a178",
    video_id: "LnB_Leaning_Rear_Delt_Extension_1",
    video_length: 0.08,
    video_number: "349",
    size: 815085,
    hints: "LnB_Leaning_Rear_Delt_Extension_1_hints",
    common_mistakes: "LnB_Leaning_Rear_Delt_Extension_1_common_mistakes",
    breathing: "LnB_Leaning_Rear_Delt_Extension_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Rear Delt Fly",
    is_free: false,
    primary_muscle: "Traps, Rhomboids, Rear Shoulders, Erector Spinae",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Teres Major, Teres Minor, Infraspinatus",
    subcategory_name: "back",
    tertiary_muscle: "Latissimus Dorsi, Lower Back, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FRear%20Delt%20Fly-0.jpg?alt=media&token=fda6679a-12ef-475d-b1f8-9871b6148917",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2FRear%20Delt%20Fly.mp4?alt=media&token=40f86313-28e3-4dc0-b53b-106571b78655",
    video_id: "LnB_Rear_Delt_Fly_1",
    video_length: 0.08,
    video_number: "350",
    size: 1850464,
    hints: "LnB_Rear_Delt_Fly_1_hints",
    common_mistakes: "LnB_Rear_Delt_Fly_1_common_mistakes",
    breathing: "LnB_Rear_Delt_Fly_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Round Up Shrug",
    is_free: true,
    primary_muscle: "Traps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rhomboids, Teres Major, Teres Minor, Infraspinatus, Latissimus Dorsi, Shoulders",
    subcategory_name: "back",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FRound%20Up%20Shrug-0.jpg?alt=media&token=e6af6825-00a4-4d61-9058-870288efc602",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2FRound%20Up%20Shrug.mp4?alt=media&token=b5dc8295-57ce-4d1f-affa-429370481b0d",
    video_id: "LnB_Round_Up_Shrug_1",
    video_length: 0.08,
    video_number: "351",
    size: 1487154,
    hints: "LnB_Round_Up_Shrug_1_hints",
    common_mistakes: "LnB_Round_Up_Shrug_1_common_mistakes",
    breathing: "LnB_Round_Up_Shrug_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Half RDL & Close Row",
    is_free: false,
    primary_muscle: "Quads, Glutes, Erector Spinae, Lower Back, Traps, Rhomboids",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Hamstrings, Adductors, Latissimus Dorsi, Rear Shoulders, Teres Major, Teres Minor, Infraspinatus, Biceps",
    subcategory_name: "back",
    tertiary_muscle: "Core, Calves, Middle Shoulders, Front Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FHalf%20RDL%20%26%20Close%20Row-0.jpg?alt=media&token=e4fb90c0-e4d0-4626-82d3-42720115689a",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2FHalf%20RDL%20%26%20Close%20Row.mp4?alt=media&token=dd341483-c6db-4a98-b770-71363e50e5d8",
    video_id: "LnB_Half_RDL_Close_Row_1",
    video_length: 0.08,
    video_number: "352",
    size: 1599689,
    hints: "LnB_Half_RDL_Close_Row_1_hints",
    common_mistakes: "LnB_Half_RDL_Close_Row_1_common_mistakes",
    breathing: "LnB_Half_RDL_Close_Row_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Rear Shrug",
    is_free: false,
    primary_muscle: "Traps, Rhomboids",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rear Shoulders, Levator Scapulae",
    subcategory_name: "back",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FRear%20Shrug-0.jpg?alt=media&token=ef45a368-4747-4b8e-8ff8-e802c72b7f1d",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2FRear%20Shrug.mp4?alt=media&token=0b95304f-7c23-4410-bd6c-643e2266d543",
    video_id: "LnB_Rear_Shrug_1",
    video_length: 0.08,
    video_number: "353",
    size: 411423,
    hints: "LnB_Rear_Shrug_1_hints",
    common_mistakes: "LnB_Rear_Shrug_1_common_mistakes",
    breathing: "LnB_Rear_Shrug_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Side Into Rear Shrug",
    is_free: false,
    primary_muscle: "Traps, Rhomboids",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rear Shoulders, Levator Scapulae",
    subcategory_name: "back",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSide%20Into%20Rear%20Shrug-0.jpg?alt=media&token=359df90f-fda6-4c78-8ee8-784a159f8d9a",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2FSide%20Into%20Rear%20Shrug.mp4?alt=media&token=b92ba9f1-6ec8-4b0e-a898-bc566ea4b5aa",
    video_id: "LnB_Side_Into_Rear_Shrug_1",
    video_length: 0.08,
    video_number: "354",
    size: 1978522,
    hints: "LnB_Side_Into_Rear_Shrug_1_hints",
    common_mistakes: "LnB_Side_Into_Rear_Shrug_1_common_mistakes",
    breathing: "LnB_Side_Into_Rear_Shrug_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Close Row Into Delt Fly",
    is_free: false,
    primary_muscle: "Traps, Rhomboids, Rear Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Teres Major, Teres Minor, Latissimus Dorsi, Infraspinatus, Biceps, Erector Spinae",
    subcategory_name: "back",
    tertiary_muscle: "Middle Shoulders, Front Shoulders, Lower Back, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FClose%20Row%20Into%20Delt%20Fly-0.jpg?alt=media&token=afa8b691-5745-4fc9-bf1d-44acd89c8a44",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2FClose%20Row%20Into%20Delt%20Fly.mp4?alt=media&token=7752cf1d-0823-451c-aba7-f61a94e3d34a",
    video_id: "LnB_Close_Row_Into_Delt_Fly_1",
    video_length: 0.08,
    video_number: "355",
    size: 1183548,
    hints: "LnB_Close_Row_Into_Delt_Fly_1_hints",
    common_mistakes: "LnB_Close_Row_Into_Delt_Fly_1_common_mistakes",
    breathing: "LnB_Close_Row_Into_Delt_Fly_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Regular Row",
    is_free: false,
    primary_muscle: "Latissimus Dorsi, Rhomboids, Traps, Erector Spinae",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rear Shoulders, Teres Major, Teres Minor, Infraspinatus, Biceps",
    subcategory_name: "back",
    tertiary_muscle: "Lower Back, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FRegular%20Row-0.jpg?alt=media&token=dfd8cb9d-f867-404a-bec4-252e8664af4c",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2FRegular%20Row.mp4?alt=media&token=29ff0450-facc-4151-9469-f17eac711249",
    video_id: "LnB_Regular_Row_1",
    video_length: 0.08,
    video_number: "356",
    size: 923263,
    hints: "LnB_Regular_Row_1_hints",
    common_mistakes: "LnB_Regular_Row_1_common_mistakes",
    breathing: "LnB_Regular_Row_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Side Trap Raise",
    is_free: true,
    primary_muscle: "Latissimus Dorsi, Rhomboids, Traps, Erector Spinae",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rear Shoulders, Teres Major, Teres Minor, Infraspinatus, Biceps",
    subcategory_name: "back",
    tertiary_muscle: "Lower Back, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSide%20Trap%20Raise-0.jpg?alt=media&token=d637adf0-8308-4a31-84af-691b218938cc",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2FSide%20Trap%20Raise.mp4?alt=media&token=4b919db5-2fab-4e37-b55f-787c35641f6a",
    video_id: "LnB_Side_Trap_Raise_1",
    video_length: 0.08,
    video_number: "357",
    size: 941696,
    hints: "LnB_Side_Trap_Raise_1_hints",
    common_mistakes: "LnB_Side_Trap_Raise_1_common_mistakes",
    breathing: "LnB_Side_Trap_Raise_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Upright Prayer Row",
    is_free: false,
    primary_muscle: "Traps, Rhomboids",
    rep_range: "10-20",
    secondary_muscle: "Rear Shoulders, Levator Scapulae",
    subcategory_name: "back",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FUpright%20Prayer%20Row-0.jpg?alt=media&token=41369194-63ef-4534-bf8a-dff7f340a058",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2FUpright%20Prayer%20Row.mp4?alt=media&token=01698aab-2073-4c37-a5ab-6b5bcd5e7215",
    video_id: "LnB_Upright_Prayer_Row_1",
    video_length: 0.08,
    video_number: "358",
    size: 746635,
    hints: "LnB_Upright_Prayer_Row_1_hints",
    common_mistakes: "LnB_Upright_Prayer_Row_1_common_mistakes",
    breathing: "LnB_Upright_Prayer_Row_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Single Arm Chair Row",
    is_other_side: 0,
    is_free: false,
    primary_muscle: "Latissimus Dorsi, Rhomboids, Traps, Erector Spinae",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rear Shoulders, Teres Majors, Teres Minor, Infraspinatus, Biceps",
    subcategory_name: "back",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(L)%20Single%20Arm%20Chair%20Row-0.jpg?alt=media&token=52a76a88-416b-42a8-a78d-1f9d13a96762",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2F(L)%20Single%20Arm%20Chair%20Row.mp4?alt=media&token=481ce7ea-3f56-48ed-93a6-3c929e52e545",
    video_id: "LnB_Single_Arm_Chair_Row_1",
    video_length: 0.08,
    video_number: "359",
    size: 1838110,
    hints: "LnB_Single_Arm_Chair_Row_1_hints",
    common_mistakes: "LnB_Single_Arm_Chair_Row_1_common_mistakes",
    breathing: "LnB_Single_Arm_Chair_Row_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Single Arm Chair Row",
    is_other_side: 1,
    is_free: false,
    primary_muscle: "Latissimus Dorsi, Rhomboids, Traps, Erector Spinae",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rear Shoulders, Teres Majors, Teres Minor, Infraspinatus, Biceps",
    subcategory_name: "back",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(R)%20Single%20Arm%20Chair%20Row-0.jpg?alt=media&token=b9a2dd8b-781b-4284-86a1-92cf4385d047",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2F(R)%20Single%20Arm%20Chair%20Row.mp4?alt=media&token=4040527b-561a-4c74-89d5-e620491e25d1",
    video_id: "LnB_Single_Arm_Chair_Row_2",
    video_length: 0.08,
    video_number: "360",
    size: 1362772,
    hints: "LnB_Single_Arm_Chair_Row_2_hints",
    common_mistakes: "LnB_Single_Arm_Chair_Row_2_common_mistakes",
    breathing: "LnB_Single_Arm_Chair_Row_2_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Rhomboid Strengthening",
    is_free: false,
    primary_muscle: "Rhomboids, Rear Shoulders, Infraspinatus, Traps, Teres Major, Teres Minor",
    rep_range: "10-20",
    secondary_muscle: "Latissimus Dorsi",
    subcategory_name: "back",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FRhomboid%20Strengthening-0.jpg?alt=media&token=11845ddf-da8b-4d49-b83c-2c7a4a078c05",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fback%2FRhomboid%20Strengthening.mp4?alt=media&token=b8e41fc4-6b9f-42ac-b2eb-4f3a8123331c",
    video_id: "LnB_Rhomboid_Strengthening_1",
    video_length: 0.08,
    video_number: "361",
    size: 900949,
    hints: "LnB_Rhomboid_Strengthening_1_hints",
    common_mistakes: "LnB_Rhomboid_Strengthening_1_common_mistakes",
    breathing: "LnB_Rhomboid_Strengthening_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Lateral Raise Into Front Extension",
    is_free: false,
    primary_muscle: "Front Shoulders, Middle Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rear Shoulders, Traps, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FLateral%20Raise%20Into%20Front%20Extension-0.jpg?alt=media&token=8b20e0bc-a9f5-40d7-ad1a-187a5e929543",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FLateral%20Raise%20Into%20Front%20Extension.mp4?alt=media&token=eed5a2c6-7f96-42d8-91ce-433de28a2a71",
    video_id: "TnS_Lateral_Raise_Into_Front_Extension_2",
    video_length: 0.07,
    video_number: "362",
    size: 1693615,
    hints: "TnS_Lateral_Raise_Into_Front_Extension_2_hints",
    common_mistakes: "TnS_Lateral_Raise_Into_Front_Extension_2_common_mistakes",
    breathing: "TnS_Lateral_Raise_Into_Front_Extension_2_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Front Raise Into Reverse Grip Raise",
    is_free: false,
    primary_muscle: "Front Shoulders, Middle Shoulders",
    rep_range: "5-10",
    secondary_muscle: "Rear Shoulders, Traps, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FFront%20Raise%20Into%20Reverse%20Grip%20Raise-0.jpg?alt=media&token=4bb0d238-b9c0-42ef-b815-865606fc9b05",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FFront%20Raise%20Into%20Reverse%20Grip%20Raise.mp4?alt=media&token=62726d11-1d50-456a-85f6-c5de246fd942",
    video_id: "TnS_Front_Raise_Into_Reverse_Grip_Raise_2",
    video_length: 0.07,
    video_number: "363",
    size: 3057553,
    hints: "TnS_Front_Raise_Into_Reverse_Grip_Raise_2_hints",
    common_mistakes: "TnS_Front_Raise_Into_Reverse_Grip_Raise_2_common_mistakes",
    breathing: "TnS_Front_Raise_Into_Reverse_Grip_Raise_2_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Alternating Shoulder Press",
    is_other_side: 0,
    is_free: false,
    primary_muscle: "Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Chest, Traps Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(L)%20Alternating%20Shoulder%20Press-0.jpg?alt=media&token=5a946f07-fb94-4a70-836f-1484a85a33f6",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2F(L)%20Alternating%20Shoulder%20Press.mp4?alt=media&token=6d4c78a9-115e-4ef7-950c-b847c4041a3d",
    video_id: "TnS_Alternating_Shoulder_Press_1",
    video_length: 0.07,
    video_number: "364",
    size: 1550256,
    hints: "TnS_Alternating_Shoulder_Press_1_hints",
    common_mistakes: "TnS_Alternating_Shoulder_Press_1_common_mistakes",
    breathing: "TnS_Alternating_Shoulder_Press_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Alternating Shoulder Press",
    is_other_side: 1,
    is_free: false,
    primary_muscle: "Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Chest, Traps Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(R)%20Alternatiing%20Shoulder%20Press-0.jpg?alt=media&token=c2762c61-93d6-4a92-8098-2086ae03fd18",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2F(R)%20Alternatiing%20Shoulder%20Press.mp4?alt=media&token=48cfddac-8ede-465b-9fce-b39ff291a3ea",
    video_id: "TnS_Alternating_Shoulder_Press_2",
    video_length: 0.07,
    video_number: "365",
    size: 1747110,
    hints: "TnS_Alternating_Shoulder_Press_2_hints",
    common_mistakes: "TnS_Alternating_Shoulder_Press_2_common_mistakes",
    breathing: "TnS_Alternating_Shoulder_Press_2_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Standing Concentrated Side Raise",
    is_other_side: 0,
    is_free: false,
    primary_muscle: "Front Shoulders, Middle Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rear Shoulders, Traps, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(L)%20Standing%20Concentrated%20Side%20Raise-0.jpg?alt=media&token=785abfb8-ecef-45c2-86f4-ac66d755c7b0",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2F(L)%20Standing%20Concentrated%20Side%20Raise.mp4?alt=media&token=f2a76ba8-abbf-4ccc-9541-bf5cdcbaf27e",
    video_id: "TnS_Standing_Concentrated_Side_Raise_1",
    video_length: 0.07,
    video_number: "366",
    size: 2086832,
    hints: "TnS_Standing_Concentrated_Side_Raise_1_hints",
    common_mistakes: "TnS_Standing_Concentrated_Side_Raise_1_common_mistakes",
    breathing: "TnS_Standing_Concentrated_Side_Raise_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Standing Concentrated Side Raise",
    is_other_side: 1,
    is_free: false,
    primary_muscle: "Front Shoulders, Middle Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rear Shoulders, Traps, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(R)%20Standing%20Concentrated%20Side%20Raise-0.jpg?alt=media&token=92842064-5675-42e7-bb35-efc9e1248383",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2F(R)%20Standing%20Concentrated%20Side%20Raise.mp4?alt=media&token=21c23c27-f9c0-40fb-a3fa-634da4118368",
    video_id: "TnS_Standing_Concentrated_Side_Raise_2",
    video_length: 0.07,
    video_number: "367",
    size: 1492758,
    hints: "TnS_Standing_Concentrated_Side_Raise_2_hints",
    common_mistakes: "TnS_Standing_Concentrated_Side_Raise_2_common_mistakes",
    breathing: "TnS_Standing_Concentrated_Side_Raise_2_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Lateral Raise Into Shoulder Press",
    is_other_side: 0,
    is_free: false,
    primary_muscle: "Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Traps, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Chest, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(L)%20Lateral%20Raise%20Into%20Shoulder%20Press-0.jpg?alt=media&token=1091d554-8ef2-4b9e-a1a5-4e24132301a8",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2F(L)%20Lateral%20Raise%20Into%20Shoulder%20Press.mp4?alt=media&token=431b6af0-1401-414b-a5c8-8c85ed425106",
    video_id: "TnS_Lateral_Raise_Into_Shoulder_Press_1",
    video_length: 0.07,
    video_number: "368",
    size: 2229488,
    hints: "TnS_Lateral_Raise_Into_Shoulder_Press_1_hints",
    common_mistakes: "TnS_Lateral_Raise_Into_Shoulder_Press_1_common_mistakes",
    breathing: "TnS_Lateral_Raise_Into_Shoulder_Press_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Lateral Raise Into Shoulder Press",
    is_other_side: 1,
    is_free: false,
    primary_muscle: "Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Traps, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Chest, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(R)%20Lateral%20Raise%20Into%20Shoulder%20Press-0.jpg?alt=media&token=a6c8e489-6173-4f1d-b88d-dfa288373956",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2F(R)%20Lateral%20Raise%20Into%20Shoulder%20Press.mp4?alt=media&token=d90add66-9915-4d75-8b2b-1e987827e7ff",
    video_id: "TnS_Lateral_Raise_Into_Shoulder_Press_2",
    video_length: 0.07,
    video_number: "369",
    size: 2297346,
    hints: "TnS_Lateral_Raise_Into_Shoulder_Press_2_hints",
    common_mistakes: "TnS_Lateral_Raise_Into_Shoulder_Press_2_common_mistakes",
    breathing: "TnS_Lateral_Raise_Into_Shoulder_Press_2_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Close Grip Shoulder Press Into Shoulder Press",
    is_free: false,
    primary_muscle: "Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Chest, Traps, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FClose%20Grip%20Shoulder%20Press%20Into%20Shoulder%20Press-0.jpg?alt=media&token=aee9f49d-5823-4338-8262-36d2258a8592",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FClose%20Grip%20Shoulder%20Press%20Into%20Shoulder%20Press.mp4?alt=media&token=c765d7be-344d-4617-b28e-498d9ea548d0",
    video_id: "TnS_Close_Grip_Shoulder_Press_Into_Shoulder_Press_1",
    video_length: 0.07,
    video_number: "370",
    size: 2599677,
    hints: "TnS_Close_Grip_Shoulder_Press_Into_Shoulder_Press_1_hints",
    common_mistakes: "TnS_Close_Grip_Shoulder_Press_Into_Shoulder_Press_1_common_mistakes",
    breathing: "TnS_Close_Grip_Shoulder_Press_Into_Shoulder_Press_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Bear Pushup",
    is_free: false,
    primary_muscle: "Front Shoulders, Upper Chest, Middle Chest",
    rep_range: "8-20",
    secondary_muscle: "Middle Shoulders, Triceps, Core, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle:
      "Rear Shoulders, Lower Chest, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FBear%20Pushup-0.jpg?alt=media&token=1ec8d72d-7f54-4796-a153-e340ec7df8a3",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FBear%20Pushup.mp4?alt=media&token=fd60a92f-cbf9-44eb-b71c-f3d9bd6b9890",
    video_id: "TnS_Bear_Pushup_1",
    video_length: 0.07,
    video_number: "371",
    size: 1253367,
    hints: "TnS_Bear_Pushup_1_hints",
    common_mistakes: "TnS_Bear_Pushup_1_common_mistakes",
    breathing: "TnS_Bear_Pushup_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Overhead Flys",
    is_free: false,
    primary_muscle: "Front Shoulders, Middle Shoulders, Traps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Serratus Anterior, Rhomboids, Teres Major, Teres Minor, Infraspinatus",
    subcategory_name: "shoulders",
    tertiary_muscle: "Rear Shoulders, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FOverhead%20Flys-0.jpg?alt=media&token=2f76cd3a-b775-4457-93b9-692b495d82df",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FOverhead%20Flys.mp4?alt=media&token=439bb9b7-5355-4c87-9bed-bf1ce90b6db7",
    video_id: "TnS_Overhead_Flys_1",
    video_length: 0.07,
    video_number: "372",
    size: 3370877,
    hints: "TnS_Overhead_Flys_1_hints",
    common_mistakes: "TnS_Overhead_Flys_1_common_mistakes",
    breathing: "TnS_Overhead_Flys_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Triple Alternating Hammer Shoulder Press",
    is_free: false,
    primary_muscle: "Front Shoulders",
    rep_range: "5-10",
    secondary_muscle: "Chest, Traps, Middle Shoulders, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Rear Shoulders, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FTriple%20Alternating%20Hammer%20Shoulder%20Press-0.jpg?alt=media&token=c1b686b0-dbb2-4c01-bbc1-e043e10b7f34",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FTriple%20Alternating%20Hammer%20Shoulder%20Press.mp4?alt=media&token=a4884c5f-2196-4162-b931-e680ee3c7929",
    video_id: "TnS_Triple_Alternating_Hammer_Shoulder_Press_1",
    video_length: 0.07,
    video_number: "373",
    size: 2721069,
    hints: "TnS_Triple_Alternating_Hammer_Shoulder_Press_1_hints",
    common_mistakes: "TnS_Triple_Alternating_Hammer_Shoulder_Press_1_common_mistakes",
    breathing: "TnS_Triple_Alternating_Hammer_Shoulder_Press_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Wide Upright Row",
    is_free: false,
    primary_muscle: "Traps, Rhomboids, Middle Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Front Shoulders, Rear Shoulders, Teres Minor, Infraspinatus, Biceps",
    subcategory_name: "shoulders",
    tertiary_muscle: "Teres Major",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FWide%20Upright%20Row-0.jpg?alt=media&token=29d4ee94-d8f9-45c0-b924-52e62ebbd285",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FWide%20Upright%20Row.mp4?alt=media&token=f7ac94c7-093b-409c-ae69-3862660fa3ba",
    video_id: "TnS_Wide_Upright_Row_1",
    video_length: 0.07,
    video_number: "374",
    size: 1899689,
    hints: "TnS_Wide_Upright_Row_1_hints",
    common_mistakes: "TnS_Wide_Upright_Row_1_common_mistakes",
    breathing: "TnS_Wide_Upright_Row_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Front To Side Rotations",
    is_free: true,
    primary_muscle: "Front Shoulders, Middle Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Upper Chest, Traps, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Rear Shoulders, Core, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FFront%20To%20Side%20Rotations-0.jpg?alt=media&token=3d9cd6e3-4e8a-4ae2-bb56-ae3b210b71db",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FFront%20To%20Side%20Rotations.mp4?alt=media&token=6390f294-7bd5-4344-8b22-e4ed31edd6f1",
    video_id: "TnS_Front_To_Side_Rotations_1",
    video_length: 0.07,
    video_number: "375",
    size: 2798269,
    hints: "TnS_Front_To_Side_Rotations_1_hints",
    common_mistakes: "TnS_Front_To_Side_Rotations_1_common_mistakes",
    breathing: "TnS_Front_To_Side_Rotations_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Dumbbell Punches",
    is_free: false,
    primary_muscle: "Front Shoulders, Middle Shoulders Serratus Anterior",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Upper Chest",
    subcategory_name: "shoulders",
    tertiary_muscle: "Rear Shoulders, Traps, Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FDumbbell%20Punches%20-0.jpg?alt=media&token=57b46118-6d86-4451-b294-06bd4c5716cc",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FDumbbell%20Punches%20.mp4?alt=media&token=206c9122-5dee-475c-ba8d-1531ac39680d",
    video_id: "TnS_Dumbbell_Punches_1",
    video_length: 0.07,
    video_number: "376",
    size: 2820606,
    hints: "TnS_Dumbbell_Punches_1_hints",
    common_mistakes: "TnS_Dumbbell_Punches_1_common_mistakes",
    breathing: "TnS_Dumbbell_Punches_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Shoulder Press Into Hammer Shoulder Press",
    is_free: false,
    primary_muscle: "Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Chest, Traps, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FShoulder%20Press%20Into%20Hammer%20Shoulder%20Press-0.jpg?alt=media&token=c30de6b1-410b-4fb5-8c48-bf0bd1bfbd51",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FShoulder%20Press%20Into%20Hammer%20Shoulder%20Press.mp4?alt=media&token=ec908a75-ffe2-434a-9993-bfc7fe81429e",
    video_id: "TnS_Shoulder_Press_Into_Hammer_Shoulder_Press_1",
    video_length: 0.07,
    video_number: "377",
    size: 2028762,
    hints: "TnS_Shoulder_Press_Into_Hammer_Shoulder_Press_1_hints",
    common_mistakes: "TnS_Shoulder_Press_Into_Hammer_Shoulder_Press_1_common_mistakes",
    breathing: "TnS_Shoulder_Press_Into_Hammer_Shoulder_Press_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Rear Delt Openers",
    is_free: false,
    primary_muscle: "Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Front Shoulders, Middle Shoulders, Serratus Anterior, Traps, Infraspinatus, Rhomboids,Teres Major, Teres Minor",
    subcategory_name: "shoulders",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FRear%20Delt%20Openers-0.jpg?alt=media&token=c65900ba-ec7b-4f83-94bb-7d119ed858b8",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FRear%20Delt%20Openers.mp4?alt=media&token=b10964d5-c91c-4d32-a28e-3c08c85c0871",
    video_id: "TnS_Rear_Delt_Openers_1",
    video_length: 0.07,
    video_number: "378",
    size: 1323115,
    hints: "TnS_Rear_Delt_Openers_1_hints",
    common_mistakes: "TnS_Rear_Delt_Openers_1_common_mistakes",
    breathing: "TnS_Rear_Delt_Openers_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Seated Upright Row",
    is_free: false,
    primary_muscle: "Traps, Rhomboids, Middle Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Front Shoulders, Rear Shoulders, Teres Minor, Infraspinatus, Biceps",
    subcategory_name: "shoulders",
    tertiary_muscle: "Teres Major",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSeated%20Upright%20Row-0.jpg?alt=media&token=ea1078b0-3d82-4182-bfcb-6200c059c344",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FSeated%20Upright%20Row.mp4?alt=media&token=5be28aeb-12b7-4895-90a7-0836ee08a3b2",
    video_id: "TnS_Seated_Upright_Row_1",
    video_length: 0.07,
    video_number: "379",
    size: 1604979,
    hints: "TnS_Seated_Upright_Row_1_hints",
    common_mistakes: "TnS_Seated_Upright_Row_1_common_mistakes",
    breathing: "TnS_Seated_Upright_Row_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Front Twist Raise",
    is_free: false,
    primary_muscle: "Traps, Rhomboids, Middle Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Front Shoulders, Rear Shoulders, Teres Minor, Infraspinatus, Biceps",
    subcategory_name: "shoulders",
    tertiary_muscle: "Teres Major",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FFront%20Twist%20Raise-0.jpg?alt=media&token=9640d832-832d-44c1-955a-3b39b04340fd",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FFront%20Twist%20Raise.mp4?alt=media&token=5e101fd0-8b2f-4491-a8d4-46945c3ad922",
    video_id: "TnS_Front_Twist_Raise_1",
    video_length: 0.07,
    video_number: "380",
    size: 2339416,
    hints: "TnS_Front_Twist_Raise_1_hints",
    common_mistakes: "TnS_Front_Twist_Raise_1_common_mistakes",
    breathing: "TnS_Front_Twist_Raise_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Front Raise Into Rear Delt Fly",
    is_free: false,
    primary_muscle: "Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Upper Chest, Serratus Anterior, Infraspinatus, Teres Major, Teres Minor, Rhomboids, Traps",
    subcategory_name: "shoulders",
    tertiary_muscle: "Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FFront%20Raise%20Into%20Rear%20Delt%20Fly-0.jpg?alt=media&token=40c36ac4-d7d0-4924-845b-4564e29bdd69",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FFront%20Raise%20Into%20Rear%20Delt%20Fly.mp4?alt=media&token=06f4d5cd-0136-4589-b02c-800003d8a3ae",
    video_id: "TnS_Front_Raise_Into_Rear_Delt_Fly_1",
    video_length: 0.07,
    video_number: "381",
    size: 1110361,
    hints: "TnS_Front_Raise_Into_Rear_Delt_Fly_1_hints",
    common_mistakes: "TnS_Front_Raise_Into_Rear_Delt_Fly_1_common_mistakes",
    breathing: "TnS_Front_Raise_Into_Rear_Delt_Fly_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Tomahawk Raise",
    is_free: false,
    primary_muscle: "Front Shoulders, Middle Shoulders",
    rep_range: "10-20",
    secondary_muscle: "Traps",
    subcategory_name: "shoulders",
    tertiary_muscle: "Rear Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FTomahawk%20Raise-0.jpg?alt=media&token=a5c22fda-d5c5-4f6a-8f0a-918b769c1bf3",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FTomahawk%20Raise.mp4?alt=media&token=929270fc-5de5-4481-9a24-47554fdc9a56",
    video_id: "TnS_Tomahawk_Raise_1",
    video_length: 0.07,
    video_number: "382",
    size: 2535040,
    hints: "TnS_Tomahawk_Raise_1_hints",
    common_mistakes: "TnS_Tomahawk_Raise_1_common_mistakes",
    breathing: "TnS_Tomahawk_Raise_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Reverse Front Raise Into Overhead Fly",
    is_free: false,
    primary_muscle: "Shoulders, Traps",
    rep_range: "5-10",
    secondary_muscle: "Serratus Anterior, Rhomboids, Teres Major, Teres Minor, Infraspinatus, Upper Chest",
    subcategory_name: "shoulders",
    tertiary_muscle: "Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FReverse%20Front%20Raise%20Into%20Overhead%20Fly-0.jpg?alt=media&token=899f16c2-d63e-47ab-8a52-0c8a02156a70",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FReverse%20Front%20Raise%20Into%20Overhead%20Fly.mp4?alt=media&token=1de9e89e-1d7c-476c-8aa3-9af189c9522a",
    video_id: "TnS_Reverse_Front_Raise_Into_Overhead_Fly_1",
    video_length: 0.07,
    video_number: "383",
    size: 2659336,
    hints: "TnS_Reverse_Front_Raise_Into_Overhead_Fly_1_hints",
    common_mistakes: "TnS_Reverse_Front_Raise_Into_Overhead_Fly_1_common_mistakes",
    breathing: "TnS_Reverse_Front_Raise_Into_Overhead_Fly_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Scissors",
    is_free: false,
    primary_muscle: "Shoulders",
    rep_range: "10-20",
    secondary_muscle: "Traps, Serratus Anterior, Rhomboids, Teres Major, Teres Minor, Infraspinatus",
    subcategory_name: "shoulders",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FScissors-0.jpg?alt=media&token=99932a95-31ee-4932-9c26-293bfc6e2ff1",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FScissors.mp4?alt=media&token=d0804e28-d031-4cb2-b8cb-fe58722cb99f",
    video_id: "TnS_Scissors_1",
    video_length: 0.07,
    video_number: "384",
    size: 1391996,
    hints: "TnS_Scissors_1_hints",
    common_mistakes: "TnS_Scissors_1_common_mistakes",
    breathing: "TnS_Scissors_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Close Grip Hammer Front Raise Into Lateral Raise",
    is_free: false,
    primary_muscle: "Front Shoulders, Middle Shoulders, Traps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Serratus Anterior, Upper Chest",
    subcategory_name: "shoulders",
    tertiary_muscle: "Core, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FClose%20Grip%20Hammer%20Front%20Raise%20Into%20Lateral%20Raise-0.jpg?alt=media&token=e90a131b-aa95-4cf7-90dc-4a5c4ee06dbe",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FClose%20Grip%20Hammer%20Front%20Raise%20Into%20Lateral%20Raise.mp4?alt=media&token=022f19f3-4029-46c7-8c87-b53c99408223",
    video_id: "TnS_Close_Grip_Hammer_Front_Raise_Into_Lateral_Raise_1",
    video_length: 0.07,
    video_number: "385",
    size: 2254373,
    hints: "TnS_Close_Grip_Hammer_Front_Raise_Into_Lateral_Raise_1_hints",
    common_mistakes: "TnS_Close_Grip_Hammer_Front_Raise_Into_Lateral_Raise_1_common_mistakes",
    breathing: "TnS_Close_Grip_Hammer_Front_Raise_Into_Lateral_Raise_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Single Hammer Front Raise Into Hammer Front Raise",
    is_free: false,
    primary_muscle: "Front Shoulders, Middle Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Upper Chest, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Rear Shoulders, Traps, Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSingle%20Hammer%20Front%20Raise%20Into%20Hammer%20Front%20Raise-0.jpg?alt=media&token=fe14161d-5beb-483f-9506-d1565a26098b",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FSingle%20Hammer%20Front%20Raise%20Into%20Hammer%20Front%20Raise.mp4?alt=media&token=c745100a-a3a0-4296-92ef-7700252424ef",
    video_id: "TnS_Single_Hammer_Front_Raise_Into_Hammer_Front_Raise_1",
    video_length: 0.07,
    video_number: "386",
    size: 2052483,
    hints: "TnS_Single_Hammer_Front_Raise_Into_Hammer_Front_Raise_1_hints",
    common_mistakes: "TnS_Single_Hammer_Front_Raise_Into_Hammer_Front_Raise_1_common_mistakes",
    breathing: "TnS_Single_Hammer_Front_Raise_Into_Hammer_Front_Raise_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Front Dumbbell Extension Into Shoulder Press",
    is_free: false,
    primary_muscle: "Front Shoulders, Middle Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Upper Chest, Serratus Anterior, Traps, Teres Major, Teres Minor, Infraspinatus, Rhomboids",
    subcategory_name: "shoulders",
    tertiary_muscle: "Rear Shoulders, Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FFront%20Dumbbell%20Extension%20Into%20Shoulder%20Press-0.jpg?alt=media&token=0de79377-d7bf-4b7a-b657-424ef11e5b7c",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FFront%20Dumbbell%20Extension%20Into%20Shoulder%20Press.mp4?alt=media&token=9c079764-a696-4adc-a664-6f084c298f1b",
    video_id: "TnS_Front_Dumbbell_Extension_Into_Shoulder_Press_1",
    video_length: 0.07,
    video_number: "387",
    size: 1564402,
    hints: "TnS_Front_Dumbbell_Extension_Into_Shoulder_Press_1_hints",
    common_mistakes: "TnS_Front_Dumbbell_Extension_Into_Shoulder_Press_1_common_mistakes",
    breathing: "TnS_Front_Dumbbell_Extension_Into_Shoulder_Press_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Steering Wheel",
    is_free: false,
    primary_muscle: "Front Shoulders, Middle Shoulders",
    rep_range: "10-20",
    secondary_muscle: "Rear Shoulders, Upper Chest, Serratus Anterior, Traps, Teres Major, Teres Minor, Infraspinatus, Rhomboids",
    subcategory_name: "shoulders",
    tertiary_muscle: "Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSteering%20Wheel-0.jpg?alt=media&token=c1955bba-bef2-403f-84bc-92df5eac1d70",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FSteering%20Wheel.mp4?alt=media&token=9d979a2e-21e4-42e6-9160-d263e5a923d6",
    video_id: "TnS_Steering_Wheel_1",
    video_length: 0.07,
    video_number: "388",
    size: 1848597,
    hints: "TnS_Steering_Wheel_1_hints",
    common_mistakes: "TnS_Steering_Wheel_1_common_mistakes",
    breathing: "TnS_Steering_Wheel_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Seated Close Grip Front Raise Into Close Grip Shoulder Press",
    is_free: false,
    primary_muscle: "Front Shoulders",
    rep_range: "5-10",
    secondary_muscle: "Upper Chest, Traps, Middle Shoulders, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Rear Shoulders, Middle Chest, Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSeated%20Close%20Grip%20Front%20Raise%20Into%20Close%20Grip%20Shoulder%20Press-0.jpg?alt=media&token=ba568624-1036-4287-9629-b5818689fec4",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FSeated%20Close%20Grip%20Front%20Raise%20Into%20Close%20Grip%20Shoulder%20Press.mp4?alt=media&token=317a6729-1b4f-44ed-a304-0ef662053c90",
    video_id: "TnS_Seated_Close_Grip_Front_Raise_Into_Close_Grip_Shoulder_Press_1",
    video_length: 0.07,
    video_number: "389",
    size: 2786464,
    hints: "TnS_Seated_Close_Grip_Front_Raise_Into_Close_Grip_Shoulder_Press_1_hints",
    common_mistakes: "TnS_Seated_Close_Grip_Front_Raise_Into_Close_Grip_Shoulder_Press_1_common_mistakes",
    breathing: "TnS_Seated_Close_Grip_Front_Raise_Into_Close_Grip_Shoulder_Press_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Lateral Raise Into Front Hammer Raise",
    is_free: false,
    primary_muscle: "Front Shoulders, Middle Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Upper Chest, Serratus Anterior, Traps, Rear Shoulders",
    subcategory_name: "shoulders",
    tertiary_muscle: "Core, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FLateral%20Raise%20Into%20Front%20Hammer%20Raise-0.jpg?alt=media&token=7c2d3041-53fd-4a91-a9d8-32138f6ccf84",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FLateral%20Raise%20Into%20Front%20Hammer%20Raise.mp4?alt=media&token=c3b578fd-4ad2-4076-a30b-dcb02ce0bd47",
    video_id: "TnS_Lateral_Raise_Into_Front_Hammer_Raise_1",
    video_length: 0.07,
    video_number: "390",
    size: 1785482,
    hints: "TnS_Lateral_Raise_Into_Front_Hammer_Raise_1_hints",
    common_mistakes: "TnS_Lateral_Raise_Into_Front_Hammer_Raise_1_common_mistakes",
    breathing: "TnS_Lateral_Raise_Into_Front_Hammer_Raise_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Overhand Shoulder Fly",
    is_free: false,
    primary_muscle: "Middle Shoulders, Rear Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Front Shoulders, Traps, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FOverhand%20Shoulder%20Fly-0.jpg?alt=media&token=a36d71a6-0362-45d1-90bb-162b74a0117e",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FOverhand%20Shoulder%20Fly.mp4?alt=media&token=fd28b480-b533-4e02-96a8-593b82a7238f",
    video_id: "TnS_Overhand_Shoulder_Fly_1",
    video_length: 0.07,
    video_number: "391",
    size: 2356007,
    hints: "TnS_Overhand_Shoulder_Fly_1_hints",
    common_mistakes: "TnS_Overhand_Shoulder_Fly_1_common_mistakes",
    breathing: "TnS_Overhand_Shoulder_Fly_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Hammer Front Raise Alternating Hold",
    is_free: false,
    primary_muscle: "Front Shoulders, Middle Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Upper Chest, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Rear Shoulders, Traps, Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FHammer%20Front%20Raise%20Alternating%20Hold-0.jpg?alt=media&token=15f23df5-707e-42b6-b9d0-f1859065c253",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FHammer%20Front%20Raise%20Alternating%20Hold.mp4?alt=media&token=88799680-d401-4216-aed6-890d90528e65",
    video_id: "TnS_Hammer_Front_Raise_Alternating_Hold_1",
    video_length: 0.07,
    video_number: "392",
    size: 3260178,
    hints: "TnS_Hammer_Front_Raise_Alternating_Hold_1_hints",
    common_mistakes: "TnS_Hammer_Front_Raise_Alternating_Hold_1_common_mistakes",
    breathing: "TnS_Hammer_Front_Raise_Alternating_Hold_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Hammer Front Raise",
    is_free: true,
    primary_muscle: "Front Shoulders, Middle Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Upper Chest, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Rear Shoulders, Traps, Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FHammer%20Front%20Raise-0.jpg?alt=media&token=588685d4-9d78-48d5-9c69-a0422585f52c",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FHammer%20Front%20Raise.mp4?alt=media&token=28715688-3744-4fa1-a76b-8bc28b24365d",
    video_id: "TnS_Hammer_Front_Raise_1",
    video_length: 0.07,
    video_number: "393",
    size: 2093220,
    hints: "TnS_Hammer_Front_Raise_1_hints",
    common_mistakes: "TnS_Hammer_Front_Raise_1_common_mistakes",
    breathing: "TnS_Hammer_Front_Raise_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Reverse Shoulder Fly",
    is_free: false,
    primary_muscle: "Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Traps, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FReverse%20Shoulder%20Fly-0.jpg?alt=media&token=2b6485cd-d383-4c63-b22c-ee69325cecda",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FReverse%20Shoulder%20Fly.mp4?alt=media&token=b3db3b26-a4fd-447d-b275-ff4b4849a7a6",
    video_id: "TnS_Reverse_Shoulder_Fly_1",
    video_length: 0.07,
    video_number: "394",
    size: 1606900,
    hints: "TnS_Reverse_Shoulder_Fly_1_hints",
    common_mistakes: "TnS_Reverse_Shoulder_Fly_1_common_mistakes",
    breathing: "TnS_Reverse_Shoulder_Fly_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Hammer Front Raise Into Chest Fly",
    is_free: false,
    primary_muscle: "Front Shoulders, Middle Shoulders, Upper Chest, Middle Chest",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Rear Shoulders, Traps, Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FHammer%20Front%20Raise%20Into%20Chest%20Fly-0.jpg?alt=media&token=5a3137b7-7819-4c9e-a5d7-3685e9c7c3b1",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FHammer%20Front%20Raise%20Into%20Chest%20Fly.mp4?alt=media&token=8c2a6269-bf93-4140-94a9-ecfd08f062a2",
    video_id: "TnS_Hammer_Front_Raise_Into_Chest_Fly_1",
    video_length: 0.07,
    video_number: "395",
    size: 2542503,
    hints: "TnS_Hammer_Front_Raise_Into_Chest_Fly_1_hints",
    common_mistakes: "TnS_Hammer_Front_Raise_Into_Chest_Fly_1_common_mistakes",
    breathing: "TnS_Hammer_Front_Raise_Into_Chest_Fly_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Arnold Press Into Hammer Shoulder Press",
    is_free: false,
    primary_muscle: "Front Shoulders, Middle Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Upper Chest, Traps, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Middle Chest, Rear Shoulders, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FArnold%20Press%20Into%20Hammer%20Shoulder%20Press-0.jpg?alt=media&token=9cf712d7-3d1f-47bc-af20-5b9c16bd4eb8",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FArnold%20Press%20Into%20Hammer%20Shoulder%20Press.mp4?alt=media&token=5636755a-1747-4420-b3d4-730ca5765235",
    video_id: "TnS_Arnold_Press_Into_Hammer_Shoulder_Press_1",
    video_length: 0.07,
    video_number: "396",
    size: 2107577,
    hints: "TnS_Arnold_Press_Into_Hammer_Shoulder_Press_1_hints",
    common_mistakes: "TnS_Arnold_Press_Into_Hammer_Shoulder_Press_1_common_mistakes",
    breathing: "TnS_Arnold_Press_Into_Hammer_Shoulder_Press_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "90 Degree Fly Into Hammer Shoulder Press",
    is_free: false,
    primary_muscle: "Front Shoulders, Middle Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Upper Chest, Traps, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Middle Chest, Rear Shoulders, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F90%20Degree%20Fly%20Into%20Hammer%20Shoulder%20Press-0.jpg?alt=media&token=7063ce8b-6693-487e-b034-53bec1474590",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2F90%20Degree%20Fly%20Into%20Hammer%20Shoulder%20Press.mp4?alt=media&token=5c9035d9-db62-4e53-a11f-79b8edbe15ff",
    video_id: "TnS_90_Degree_Fly_Into_Hammer_Shoulder_Press_1",
    video_length: 0.07,
    video_number: "397",
    size: 2706927,
    hints: "TnS_90_Degree_Fly_Into_Hammer_Shoulder_Press_1_hints",
    common_mistakes: "TnS_90_Degree_Fly_Into_Hammer_Shoulder_Press_1_common_mistakes",
    breathing: "TnS_90_Degree_Fly_Into_Hammer_Shoulder_Press_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Reverse Hand Raise",
    is_free: false,
    primary_muscle: "Front Shoulders, Middle Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Traps",
    subcategory_name: "shoulders",
    tertiary_muscle: "Rear Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FReverse%20Hand%20Raise-0.jpg?alt=media&token=9f944b93-56f4-400c-9a8c-e8d55b23f31a",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FReverse%20Hand%20Raise.mp4?alt=media&token=a421e84b-fc9c-43e0-92db-2353c4a34e21",
    video_id: "TnS_Reverse_Hand_Raise_1",
    video_length: 0.07,
    video_number: "398",
    size: 2259419,
    hints: "TnS_Reverse_Hand_Raise_1_hints",
    common_mistakes: "TnS_Reverse_Hand_Raise_1_common_mistakes",
    breathing: "TnS_Reverse_Hand_Raise_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Seated Rear Delt Openers Into Shoulder Press",
    is_free: false,
    primary_muscle: "Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Serratus Anterior, Traps, Infraspinatus, Rhomboids,Teres Major, Teres Minor",
    subcategory_name: "shoulders",
    tertiary_muscle: "Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSeated%20Rear%20Delt%20Openers%20Into%20Shoulder%20Press-0.jpg?alt=media&token=527233b0-5e7a-4890-951f-738cf4f935cd",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FSeated%20Rear%20Delt%20Openers%20Into%20Shoulder%20Press.mp4?alt=media&token=88fdf30b-7bca-462d-bf74-0c3f675567a9",
    video_id: "TnS_Seated_Rear_Delt_Openers_Into_Shoulder_Press_1",
    video_length: 0.07,
    video_number: "399",
    size: 2263768,
    hints: "TnS_Seated_Rear_Delt_Openers_Into_Shoulder_Press_1_hints",
    common_mistakes: "TnS_Seated_Rear_Delt_Openers_Into_Shoulder_Press_1_common_mistakes",
    breathing: "TnS_Seated_Rear_Delt_Openers_Into_Shoulder_Press_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Single Dumbbell Alternating Side Front Raise",
    is_free: false,
    primary_muscle: "Front Shoulders, Middle Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Upper Chest, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Rear Shoulders, Traps, Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSingle%20Dumbbell%20Alternating%20Side%20Front%20Raise-0.jpg?alt=media&token=2eb30f70-c786-4a70-81da-df4923c2abea",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FSingle%20Dumbbell%20Alternating%20Side%20Front%20Raise.mp4?alt=media&token=924040ad-b6ce-4f80-bd5d-52f9ab7569d7",
    video_id: "TnS_Single_Dumbbell_Alternating_Side_Front_Raise_1",
    video_length: 0.07,
    video_number: "400",
    size: 2115926,
    hints: "TnS_Single_Dumbbell_Alternating_Side_Front_Raise_1_hints",
    common_mistakes: "TnS_Single_Dumbbell_Alternating_Side_Front_Raise_1_common_mistakes",
    breathing: "TnS_Single_Dumbbell_Alternating_Side_Front_Raise_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Hammer Front Raise Into Upright Row",
    is_free: false,
    primary_muscle: "Front Shoulders, Middle Shoulders, Traps, Rhomboids",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rear Shoulders, Upper Chest, Serratus Anterior, Teres Minor, Infraspinatus, Biceps",
    subcategory_name: "shoulders",
    tertiary_muscle: "Core, Teres Major",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FHammer%20Front%20Raise%20Into%20Upright%20Row-0.jpg?alt=media&token=0f455dd5-7da7-4d8a-835b-c28966e411a9",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FHammer%20Front%20Raise%20Into%20Upright%20Row.mp4?alt=media&token=6e703266-faea-4214-845f-ae8d15a4ecbb",
    video_id: "TnS_Hammer_Front_Raise_Into_Upright_Row_1",
    video_length: 0.07,
    video_number: "401",
    size: 2405193,
    hints: "TnS_Hammer_Front_Raise_Into_Upright_Row_1_hints",
    common_mistakes: "TnS_Hammer_Front_Raise_Into_Upright_Row_1_common_mistakes",
    breathing: "TnS_Hammer_Front_Raise_Into_Upright_Row_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Back Dumbbell Raise Into Lateral Raise",
    is_free: true,
    primary_muscle: "Front Shoulders, Middle Shoulders, Traps, Rhomboids",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rear Shoulders, Upper Chest, Serratus Anterior, Teres Minor, Infraspinatus, Biceps",
    subcategory_name: "shoulders",
    tertiary_muscle: "Core, Teres Major",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FBack%20Dumbbell%20Raise%20Into%20Lateral%20Raise-0.jpg?alt=media&token=cbc265b0-ec5f-4ae0-bdb0-29eb81b97abc",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FBack%20Dumbbell%20Raise%20Into%20Lateral%20Raise.mp4?alt=media&token=3d2a3fd5-745f-462b-87c5-2c2af9a57e6c",
    video_id: "TnS_Back_Dumbbell_Raise_Into_Lateral_Raise_1",
    video_length: 0.07,
    video_number: "402",
    size: 2378036,
    hints: "TnS_Back_Dumbbell_Raise_Into_Lateral_Raise_1_hints",
    common_mistakes: "TnS_Back_Dumbbell_Raise_Into_Lateral_Raise_1_common_mistakes",
    breathing: "TnS_Back_Dumbbell_Raise_Into_Lateral_Raise_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Shoulder Pullover",
    is_free: false,
    primary_muscle: "Front Shoulders, Latissimus Dorsi",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rhomboids, Rear Shoulders, Teres Major, Teres Minor, Infraspinatus, Chest, Traps",
    subcategory_name: "shoulders",
    tertiary_muscle: "Middle Shoulders, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FShoulder%20Pullover-0.jpg?alt=media&token=68c95444-96be-45d9-8707-db431912bffa",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FShoulder%20Pullover.mp4?alt=media&token=94811d2f-2c8e-405d-ac5d-9795af7e3325",
    video_id: "TnS_Shoulder_Pullover_1",
    video_length: 0.07,
    video_number: "403",
    size: 2424488,
    hints: "TnS_Shoulder_Pullover_1_hints",
    common_mistakes: "TnS_Shoulder_Pullover_1_common_mistakes",
    breathing: "TnS_Shoulder_Pullover_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Shoulder Press Into Slow Negative Front Raise",
    is_free: true,
    primary_muscle: "Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Serratus Anterior, Traps, Rhomboids, Infraspinatus, Teres Minor",
    subcategory_name: "shoulders",
    tertiary_muscle: "Upper Chest, Middle Chest, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FShoulder%20Press%20Into%20Slow%20Negative%20Front%20Raise-0.jpg?alt=media&token=7655e349-1d53-47c0-ad97-49f4bdbf5875",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FShoulder%20Press%20Into%20Slow%20Negative%20Front%20Raise.mp4?alt=media&token=657d802d-cbc3-4614-842c-2e27253a81bc",
    video_id: "TnS_Shoulder_Press_Into_Slow_Negative_Front_Raise_1",
    video_length: 0.07,
    video_number: "404",
    size: 2450469,
    hints: "TnS_Shoulder_Press_Into_Slow_Negative_Front_Raise_1_hints",
    common_mistakes: "TnS_Shoulder_Press_Into_Slow_Negative_Front_Raise_1_common_mistakes",
    breathing: "TnS_Shoulder_Press_Into_Slow_Negative_Front_Raise_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Hammer Opposite Side Front Raise Into Rotating Lateral Raise",
    is_free: false,
    primary_muscle: "Shoulders",
    rep_range: "5-10",
    secondary_muscle: "Upper Chest, Traps, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Middle Chest, Latissimus Dorsi, Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FHammer%20Opposite%20Side%20Front%20Raise%20Into%20Rotating%20Lateral%20Raise-0.jpg?alt=media&token=a50b855c-4c92-4f0b-8026-75e80c5f9e4e",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FHammer%20Opposite%20Side%20Front%20Raise%20Into%20Rotating%20Lateral%20Raise.mp4?alt=media&token=69c280e9-5051-4685-9d1b-fa35ed7a24d2",
    video_id: "TnS_Hammer_Opposite_Side_Front_Raise_Into_Rotating_Lateral_Raise_1",
    video_length: 0.07,
    video_number: "405",
    size: 3057075,
    hints: "TnS_Hammer_Opposite_Side_Front_Raise_Into_Rotating_Lateral_Raise_1_hints",
    common_mistakes: "TnS_Hammer_Opposite_Side_Front_Raise_Into_Rotating_Lateral_Raise_1_common_mistakes",
    breathing: "TnS_Hammer_Opposite_Side_Front_Raise_Into_Rotating_Lateral_Raise_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Hammer Shoulder Press Into Shrug",
    is_free: false,
    primary_muscle: "Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Levator Scapulae, Infraspinatus, Teres Minor, Serratus Anterior. Upper Chest",
    subcategory_name: "shoulders",
    tertiary_muscle: "Latissimus Dorsi, Middle Chest",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FHammer%20Shoulder%20Press%20Into%20Shrug-0.jpg?alt=media&token=071868e4-ee8a-4c42-9a47-de03b9587d30",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FHammer%20Shoulder%20Press%20Into%20Shrug.mp4?alt=media&token=79eedc2b-9b55-48f9-b82e-9f0e9e40c1a5",
    video_id: "TnS_Hammer_Shoulder_Press_Into_Shrug_1",
    video_length: 0.07,
    video_number: "406",
    size: 2278854,
    hints: "TnS_Hammer_Shoulder_Press_Into_Shrug_1_hints",
    common_mistakes: "TnS_Hammer_Shoulder_Press_Into_Shrug_1_common_mistakes",
    breathing: "TnS_Hammer_Shoulder_Press_Into_Shrug_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Rotating Front Raise",
    is_free: false,
    primary_muscle: "Front Shoulders, Middle Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Upper Chest, Serratus Anterior, Rear Shoulders, Traps",
    subcategory_name: "shoulders",
    tertiary_muscle: "Latissimus Dorsi, Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FRotating%20Front%20Raise-0.jpg?alt=media&token=4131788e-bb25-49e2-9465-90f7fe5c63ac",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FRotating%20Front%20Raise.mp4?alt=media&token=4c91c268-9e46-428b-a2a0-6cbf34334224",
    video_id: "TnS_Rotating_Front_Raise_1",
    video_length: 0.07,
    video_number: "407",
    size: 2667299,
    hints: "TnS_Rotating_Front_Raise_1_hints",
    common_mistakes: "TnS_Rotating_Front_Raise_1_common_mistakes",
    breathing: "TnS_Rotating_Front_Raise_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Shoulder Press Into Arnold Press",
    is_free: false,
    primary_muscle: "Shoulders",
    rep_range: "5-10",
    secondary_muscle: "Upper Chest, Traps, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Middle Chest, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FShoulder%20Press%20Into%20Arnold%20Press-0.jpg?alt=media&token=d21e825a-6eb1-4feb-ba9c-963ce5b654f2",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FShoulder%20Press%20Into%20Arnold%20Press.mp4?alt=media&token=01037bd4-5500-4a0c-b937-69a7e178193c",
    video_id: "TnS_Shoulder_Press_Into_Arnold_Press_1",
    video_length: 0.07,
    video_number: "408",
    size: 2421417,
    hints: "TnS_Shoulder_Press_Into_Arnold_Press_1_hints",
    common_mistakes: "TnS_Shoulder_Press_Into_Arnold_Press_1_common_mistakes",
    breathing: "TnS_Shoulder_Press_Into_Arnold_Press_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Upright Row Into Wide Upright Row",
    is_free: false,
    primary_muscle: "Traps, Rhomboids, Middle Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Front Shoulders, Rear Shoulders, Teres Minor, Infraspinatus, Biceps",
    subcategory_name: "shoulders",
    tertiary_muscle: "Teres Major",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FUpright%20Row%20Into%20Wide%20Upright%20Row-0.jpg?alt=media&token=a740b1b2-5c7d-42f8-90c7-adb0ee40d264",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FUpright%20Row%20Into%20Wide%20Upright%20Row.mp4?alt=media&token=2cdb485f-884c-49e8-979d-42d8475d980c",
    video_id: "TnS_Upright_Row_Into_Wide_Upright_Row_1",
    video_length: 0.07,
    video_number: "409",
    size: 1977564,
    hints: "TnS_Upright_Row_Into_Wide_Upright_Row_1_hints",
    common_mistakes: "TnS_Upright_Row_Into_Wide_Upright_Row_1_common_mistakes",
    breathing: "TnS_Upright_Row_Into_Wide_Upright_Row_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Seated Reverse Shoulder Press Into Shoulder Press",
    is_free: false,
    primary_muscle: "Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Upper Chest, Traps, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Middle Chest, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSeated%20Reverse%20Shoulder%20Press%20Into%20Shoulder%20Press-0.jpg?alt=media&token=2cde9fff-9d72-4ff5-8e3f-fff6da5f9f54",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FSeated%20Reverse%20Shoulder%20Press%20Into%20Shoulder%20Press.mp4?alt=media&token=dc5f5a80-f00e-4513-8d37-83b8a38adc4a",
    video_id: "TnS_Seated_Reverse_Shoulder_Press_Into_Shoulder_Press_1",
    video_length: 0.07,
    video_number: "410",
    size: 1937461,
    hints: "TnS_Seated_Reverse_Shoulder_Press_Into_Shoulder_Press_1_hints",
    common_mistakes: "TnS_Seated_Reverse_Shoulder_Press_Into_Shoulder_Press_1_common_mistakes",
    breathing: "TnS_Seated_Reverse_Shoulder_Press_Into_Shoulder_Press_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Deep Overhead Fly",
    is_free: false,
    primary_muscle: "Front Shoulders, Middle Shoulders, Traps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rhomboids, Teres Major, Teres Minor, Infraspinatus, Latissimus Dorsi",
    subcategory_name: "shoulders",
    tertiary_muscle: "Rear Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FDeep%20Overhead%20Fly-0.jpg?alt=media&token=084b894f-e97d-4610-a85c-ffa66de9c254",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FDeep%20Overhead%20Fly.mp4?alt=media&token=e574d289-cb89-4180-b12c-0dd469c7d8d3",
    video_id: "TnS_Deep_Overhead_Fly_1",
    video_length: 0.07,
    video_number: "411",
    size: 4769344,
    hints: "TnS_Deep_Overhead_Fly_1_hints",
    common_mistakes: "TnS_Deep_Overhead_Fly_1_common_mistakes",
    breathing: "TnS_Deep_Overhead_Fly_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Chest Fly Into Reverse Close Grip Front Raise",
    is_free: false,
    primary_muscle: "Front Shoulders, Middle Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Upper Chest, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Rear Shoulders,Traps, Core, Middle Chest",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FChest%20Fly%20Into%20Reverse%20Close%20Grip%20Front%20Raise%20-0.jpg?alt=media&token=8d955595-812c-4a30-886b-7dc6371393b4",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FChest%20Fly%20Into%20Reverse%20Close%20Grip%20Front%20Raise%20.mp4?alt=media&token=a5a7a481-03ec-4d27-8454-02c7410c860a",
    video_id: "TnS_Chest_Fly_Into_Reverse_Close_Grip_Front_Raise_1",
    video_length: 0.07,
    video_number: "412",
    size: 2922814,
    hints: "TnS_Chest_Fly_Into_Reverse_Close_Grip_Front_Raise_1_hints",
    common_mistakes: "TnS_Chest_Fly_Into_Reverse_Close_Grip_Front_Raise_1_common_mistakes",
    breathing: "TnS_Chest_Fly_Into_Reverse_Close_Grip_Front_Raise_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Dumbbell Archer",
    is_free: false,
    primary_muscle: "Front Shoulders, Middle Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Upper Chest, Traps, Serratus Anterior, Rear Shoulders",
    subcategory_name: "shoulders",
    tertiary_muscle: "Core, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FDumbbell%20Archer-0.jpg?alt=media&token=eeb41910-359b-41dd-a275-f3d383139ec6",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FDumbbell%20Archer.mp4?alt=media&token=2cda0bd0-d8a2-4fbf-9e32-be47016c340a",
    video_id: "TnS_Dumbbell_Archer_1",
    video_length: 0.07,
    video_number: "413",
    size: 2266708,
    hints: "TnS_Dumbbell_Archer_1_hints",
    common_mistakes: "TnS_Dumbbell_Archer_1_common_mistakes",
    breathing: "TnS_Dumbbell_Archer_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Lateral Raise Into Reverse Shrug",
    is_free: false,
    primary_muscle: "Middle Shoulders, Traps, Rhomboids",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Front Shoulders, Rear Shoulders, Levator Scapulae, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FLateral%20Raise%20Into%20Reverse%20Shrug-0.jpg?alt=media&token=250fa7d9-4da1-4e5f-877a-5e79e600c60e",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FLateral%20Raise%20Into%20Reverse%20Shrug.mp4?alt=media&token=7257c0b7-c819-4246-8e17-dcdb93e0f88b",
    video_id: "TnS_Lateral_Raise_Into_Reverse_Shrug_1",
    video_length: 0.07,
    video_number: "414",
    size: 2019901,
    hints: "TnS_Lateral_Raise_Into_Reverse_Shrug_1_hints",
    common_mistakes: "TnS_Lateral_Raise_Into_Reverse_Shrug_1_common_mistakes",
    breathing: "TnS_Lateral_Raise_Into_Reverse_Shrug_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Close Grip Side Extension",
    is_free: false,
    primary_muscle: "Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Serratus Anterior, Traps, Rhomboids, Infraspinatus, Teres Minor",
    subcategory_name: "shoulders",
    tertiary_muscle: "Teres Major",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FClose%20Grip%20Side%20Extension-0.jpg?alt=media&token=0c01aba1-e16a-43a8-9c6b-9db1d1c698e4",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FClose%20Grip%20Side%20Extension.mp4?alt=media&token=5b218f10-0f97-40ea-919e-5fd0722c3c92",
    video_id: "TnS_Close_Grip_Side_Extension_1",
    video_length: 0.07,
    video_number: "415",
    size: 1323406,
    hints: "TnS_Close_Grip_Side_Extension_1_hints",
    common_mistakes: "TnS_Close_Grip_Side_Extension_1_common_mistakes",
    breathing: "TnS_Close_Grip_Side_Extension_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Hammer Rear Delt Fly",
    is_free: false,
    primary_muscle: "Rear Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Middle Shoulders, Traps, Rhomboids, Infraspinatus, Teres Minor",
    subcategory_name: "shoulders",
    tertiary_muscle: "Front Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FHammer%20Rear%20Delt%20Fly-0.jpg?alt=media&token=cae24dc4-ac4d-4a1c-adbf-2222e93da3f5",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FHammer%20Rear%20Delt%20Fly.mp4?alt=media&token=d2252781-50b8-483e-a30b-1ff4781be01b",
    video_id: "TnS_Hammer_Rear_Delt_Fly_1",
    video_length: 0.07,
    video_number: "416",
    size: 2088387,
    hints: "TnS_Hammer_Rear_Delt_Fly_1_hints",
    common_mistakes: "TnS_Hammer_Rear_Delt_Fly_1_common_mistakes",
    breathing: "TnS_Hammer_Rear_Delt_Fly_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Close Grip Side Extension Into Shoulder Press",
    is_free: false,
    primary_muscle: "Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Traps, Chest, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FClose%20Grip%20Side%20Extension%20Into%20Shoulder%20Press-0.jpg?alt=media&token=01ecfb13-48af-4d18-b543-a24769f66b08",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FClose%20Grip%20Side%20Extension%20Into%20Shoulder%20Press.mp4?alt=media&token=f09a0851-90cc-4879-a66e-b8e6c0833a31",
    video_id: "TnS_Close_Grip_Side_Extension_Into_Shoulder_Press_1",
    video_length: 0.07,
    video_number: "417",
    size: 2037537,
    hints: "TnS_Close_Grip_Side_Extension_Into_Shoulder_Press_1_hints",
    common_mistakes: "TnS_Close_Grip_Side_Extension_Into_Shoulder_Press_1_common_mistakes",
    breathing: "TnS_Close_Grip_Side_Extension_Into_Shoulder_Press_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Hammer Rear Delt Raise Into Hammer Shoulder Press",
    is_free: false,
    primary_muscle: "Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Traps, Rhomboids, Infraspinatus, Teres Minor, Upper Chest",
    subcategory_name: "shoulders",
    tertiary_muscle: "Middle Chest, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FHammer%20Rear%20Delt%20Raise%20Into%20Hammer%20Shoulder%20Press-0.jpg?alt=media&token=2561751c-5bd0-443b-95ae-986e5f6fff71",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FHammer%20Rear%20Delt%20Raise%20Into%20Hammer%20Shoulder%20Press.mp4?alt=media&token=86bd554f-f9c4-4676-b867-67ebcab677cd",
    video_id: "TnS_Hammer_Rear_Delt_Raise_Into_Hammer_Shoulder_Press_1",
    video_length: 0.07,
    video_number: "418",
    size: 1979976,
    hints: "TnS_Hammer_Rear_Delt_Raise_Into_Hammer_Shoulder_Press_1_hints",
    common_mistakes: "TnS_Hammer_Rear_Delt_Raise_Into_Hammer_Shoulder_Press_1_common_mistakes",
    breathing: "TnS_Hammer_Rear_Delt_Raise_Into_Hammer_Shoulder_Press_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Close Grip Front Extension Into Reverse Shoulder Press",
    is_free: false,
    primary_muscle: "Front Shoulders, Middle Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Upper Chest, Traps, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Rear Shoulders, Latissimus Dorsi, Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FClose%20Grip%20Front%20Extension%20Into%20Reverse%20Shoulder%20Press-0.jpg?alt=media&token=fc8be8f1-0289-4806-a298-df45ebe39d71",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FClose%20Grip%20Front%20Extension%20Into%20Reverse%20Shoulder%20Press.mp4?alt=media&token=4d7ab629-f130-4c8d-b079-c277007c274a",
    video_id: "TnS_Close_Grip_Front_Extension_Into_Reverse_Shoulder_Press_1",
    video_length: 0.07,
    video_number: "419",
    size: 1829461,
    hints: "TnS_Close_Grip_Front_Extension_Into_Reverse_Shoulder_Press_1_hints",
    common_mistakes: "TnS_Close_Grip_Front_Extension_Into_Reverse_Shoulder_Press_1_common_mistakes",
    breathing: "TnS_Close_Grip_Front_Extension_Into_Reverse_Shoulder_Press_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Wide Upright Row Into Shoulder Press",
    is_free: false,
    primary_muscle: "Shoulders, Traps, Rhomboids",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Teres Minor, Infraspinatus, Biceps, Upper Chest",
    subcategory_name: "shoulders",
    tertiary_muscle: "Teres Minor, Latissimus Dorsi, Middle Chest",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FWide%20Upright%20Row%20Into%20Shoulder%20Press-0.jpg?alt=media&token=c1cc3c41-8af8-4b29-8948-d58ce5e10e56",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FWide%20Upright%20Row%20Into%20Shoulder%20Press.mp4?alt=media&token=55994bc3-4f2a-4fc4-843f-32749a41df51",
    video_id: "TnS_Wide_Upright_Row_Into_Shoulder_Press_1",
    video_length: 0.07,
    video_number: "420",
    size: 1895776,
    hints: "TnS_Wide_Upright_Row_Into_Shoulder_Press_1_hints",
    common_mistakes: "TnS_Wide_Upright_Row_Into_Shoulder_Press_1_common_mistakes",
    breathing: "TnS_Wide_Upright_Row_Into_Shoulder_Press_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Shoulder Press Into Single Shoulder Press",
    is_free: false,
    primary_muscle: "Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Serratus Anterior, Upper Chest",
    subcategory_name: "shoulders",
    tertiary_muscle: "Traps, Latissimus Dorsi, Middle Chest",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FShoulder%20Press%20Into%20Single%20Shoulder%20Press-0.jpg?alt=media&token=daa8d60f-784b-4ea3-b80d-be107c5a8860",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FShoulder%20Press%20Into%20Single%20Shoulder%20Press.mp4?alt=media&token=76018305-c668-4f6b-a094-db6bb6bc3aff",
    video_id: "TnS_Shoulder_Press_Into_Single_Shoulder_Press_1",
    video_length: 0.07,
    video_number: "421",
    size: 1770780,
    hints: "TnS_Shoulder_Press_Into_Single_Shoulder_Press_1_hints",
    common_mistakes: "TnS_Shoulder_Press_Into_Single_Shoulder_Press_1_common_mistakes",
    breathing: "TnS_Shoulder_Press_Into_Single_Shoulder_Press_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Prayer Pushes",
    is_free: false,
    primary_muscle: "Front Shoulders, Middle Shoulders",
    rep_range: "10-20",
    secondary_muscle: "Traps",
    subcategory_name: "shoulders",
    tertiary_muscle: "Rear Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FPrayer%20Pushes-0.jpg?alt=media&token=feab0dca-dbd5-47e5-b34f-650603b5b478",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FPrayer%20Pushes.mp4?alt=media&token=11829ca6-dc2c-4add-ab4e-e9e3e34ebbc7",
    video_id: "TnS_Prayer_Pushes_1",
    video_length: 0.07,
    video_number: "422",
    size: 2157809,
    hints: "TnS_Prayer_Pushes_1_hints",
    common_mistakes: "TnS_Prayer_Pushes_1_common_mistakes",
    breathing: "TnS_Prayer_Pushes_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Seated Hammer Delt Raise",
    is_free: false,
    primary_muscle: "Rear Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Middle Shoulders, Traps, Rhomboids, Infraspinatus, Teres Minor",
    subcategory_name: "shoulders",
    tertiary_muscle: "Front Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSeated%20Hammer%20Delt%20Raise-0.jpg?alt=media&token=2c9b0024-0ed8-4956-8572-bbbfb1ea4cdf",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FSeated%20Hammer%20Delt%20Raise.mp4?alt=media&token=75ecf8ff-c27d-4450-bc32-89b8e45655fe",
    video_id: "TnS_Seated_Hammer_Delt_Raise_1",
    video_length: 0.07,
    video_number: "423",
    size: 2956824,
    hints: "TnS_Seated_Hammer_Delt_Raise_1_hints",
    common_mistakes: "TnS_Seated_Hammer_Delt_Raise_1_common_mistakes",
    breathing: "TnS_Seated_Hammer_Delt_Raise_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Lateral Raise Into Front Raise",
    is_free: false,
    primary_muscle: "Front Shoulders, Middle Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rear Shoulders, Upper Chest, Serratus Anterior, Traps",
    subcategory_name: "shoulders",
    tertiary_muscle: "Core, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FLateral%20Raise%20Into%20Front%20Raise-0.jpg?alt=media&token=61dafa97-eb2f-4fdc-b5cb-09b05e9239ba",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FLateral%20Raise%20Into%20Front%20Raise.mp4?alt=media&token=51e6e0f1-abd0-4a67-8f06-a2c503bc4862",
    video_id: "TnS_Lateral_Raise_Into_Front_Raise_1",
    video_length: 0.07,
    video_number: "424",
    size: 1541481,
    hints: "TnS_Lateral_Raise_Into_Front_Raise_1_hints",
    common_mistakes: "TnS_Lateral_Raise_Into_Front_Raise_1_common_mistakes",
    breathing: "TnS_Lateral_Raise_Into_Front_Raise_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Behind Back Lateral Raise",
    is_free: true,
    primary_muscle: "Middle Shoulders, Rear Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Front Shoulders, Traps, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FBehind%20Back%20Lateral%20Raise-0.jpg?alt=media&token=1d9abf32-2eed-4685-b7de-fd7a23b07fd3",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FBehind%20Back%20Lateral%20Raise.mp4?alt=media&token=50ef7e30-3924-4784-92f7-733bde02ea3d",
    video_id: "TnS_Behind_Back_Lateral_Raise_1",
    video_length: 0.07,
    video_number: "425",
    size: 1562878,
    hints: "TnS_Behind_Back_Lateral_Raise_1_hints",
    common_mistakes: "TnS_Behind_Back_Lateral_Raise_1_common_mistakes",
    breathing: "TnS_Behind_Back_Lateral_Raise_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Single Dumbbell Shoulder Press",
    is_free: false,
    primary_muscle: "Front Shoulders, Middle Shoulders,Traps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Upper Chest, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Middle Chest, Rear Shoulders, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSingle%20Dumbbell%20Shoulder%20Press-0.jpg?alt=media&token=31f252d1-987b-4c7d-8d8a-0ecaf213da1d",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FSingle%20Dumbbell%20Shoulder%20Press.mp4?alt=media&token=0193c1eb-74c3-4bc1-835d-3c44dc5a58cf",
    video_id: "TnS_Single_Dumbbell_Shoulder_Press_1",
    video_length: 0.07,
    video_number: "426",
    size: 2410376,
    hints: "TnS_Single_Dumbbell_Shoulder_Press_1_hints",
    common_mistakes: "TnS_Single_Dumbbell_Shoulder_Press_1_common_mistakes",
    breathing: "TnS_Single_Dumbbell_Shoulder_Press_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Close Grip Upright Row Into Front Extension",
    is_free: false,
    primary_muscle: "Front Shoulders, Middle Shoulders, Traps, Rhomboids",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rear Shoulders, Teres Minor, Infraspinatus, Biceps, Upper Chest",
    subcategory_name: "shoulders",
    tertiary_muscle: "Teres Major, Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FClose%20Grip%20Upright%20Row%20Into%20Front%20Extension-0.jpg?alt=media&token=381fb01e-4ce4-432b-9070-0e885451436f",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FClose%20Grip%20Upright%20Row%20Into%20Front%20Extension.mp4?alt=media&token=aa97b1e5-cad1-44ed-b048-03e8097084fd",
    video_id: "TnS_Close_Grip_Upright_Row_Into_Front_Extension_1",
    video_length: 0.07,
    video_number: "427",
    size: 1555510,
    hints: "TnS_Close_Grip_Upright_Row_Into_Front_Extension_1_hints",
    common_mistakes: "TnS_Close_Grip_Upright_Row_Into_Front_Extension_1_common_mistakes",
    breathing: "TnS_Close_Grip_Upright_Row_Into_Front_Extension_1_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Close Grip Shoulder Press",
    is_free: false,
    primary_muscle: "Front Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Upper Chest, Traps, Middle Shoulders, Serratus Anterior",
    subcategory_name: "shoulders",
    tertiary_muscle: "Rear Shoulders, Middle Chest, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FClose%20Grip%20Shoulder%20Press-0.jpg?alt=media&token=13cd8fba-0f63-42bb-83a5-c8b4b11362af",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fshoulders%2FClose%20Grip%20Shoulder%20Press.mp4?alt=media&token=8b56eeb3-4b6d-4d68-8fc5-9c4635e896a3",
    video_id: "TnS_Close_Grip_Shoulder_Press_1",
    video_length: 0.07,
    video_number: "428",
    size: 2886385,
    hints: "TnS_Close_Grip_Shoulder_Press_1_hints",
    common_mistakes: "TnS_Close_Grip_Shoulder_Press_1_common_mistakes",
    breathing: "TnS_Close_Grip_Shoulder_Press_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Hammer Curl Into 90 Degree Raise",
    is_other_side: 0,
    is_free: false,
    primary_muscle: "Biceps, Brachialis, Middle Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Forearm, Front Shoulders, Rear Shoulders, Traps, Serratus Anterior",
    subcategory_name: "total body",
    tertiary_muscle: "Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(L)%20Hammer%20Curl%20Into%2090%20Degree%20Raise-0.jpg?alt=media&token=9e69f63b-7f84-402a-975c-03d253c79428",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2F(L)%20Hammer%20Curl%20Into%2090%20Degree%20Raise.mp4?alt=media&token=faf198f4-fd11-42bb-b265-fa898617c1df",
    video_id: "TnS_Hammer_Curl_Into_90_Degree_Raise_1",
    video_length: 0.07,
    video_number: "429",
    size: 1490769,
    hints: "TnS_Hammer_Curl_Into_90_Degree_Raise_1_hints",
    common_mistakes: "TnS_Hammer_Curl_Into_90_Degree_Raise_1_common_mistakes",
    breathing: "TnS_Hammer_Curl_Into_90_Degree_Raise_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Hammer Curl Into 90 Degree Raise",
    is_other_side: 1,
    is_free: false,
    primary_muscle: "Biceps, Brachialis, Middle Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Forearm, Front Shoulders, Rear Shoulders, Traps, Serratus Anterior",
    subcategory_name: "total body",
    tertiary_muscle: "Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(R)%20Hammer%20Curl%20%26%2090%20Degree%20Raise-0.jpg?alt=media&token=4a51c11c-4e2f-4853-bb85-f59d7fc660cf",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2F(R)%20Hammer%20Curl%20%26%2090%20Degree%20Raise.mp4?alt=media&token=1b10a414-36b1-4469-9ac4-67c69f4d6b6c",
    video_id: "TnS_Hammer_Curl_Into_90_Degree_Raise_2",
    video_length: 0.07,
    video_number: "740",
    size: 1490769,
    hints: "TnS_Hammer_Curl_Into_90_Degree_Raise_2_hints",
    common_mistakes: "TnS_Hammer_Curl_Into_90_Degree_Raise_2_common_mistakes",
    breathing: "TnS_Hammer_Curl_Into_90_Degree_Raise_2_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Double Hammer Curl Into Double Lateral Raise",
    is_free: false,
    primary_muscle: "Biceps, Brachialis, Middle Shoulders",
    rep_range: "5-10",
    secondary_muscle: "Forearm, Front Shoulders, Rear Shoulders, Traps, Serratus Anterior",
    subcategory_name: "total body",
    tertiary_muscle: "Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FDouble%20Hammer%20Curl%20Into%20Double%20Lateral%20Raise-0.jpg?alt=media&token=eae1d07e-ba99-4214-a825-e129103057f9",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FDouble%20Hammer%20Curl%20Into%20Double%20Lateral%20Raise.mp4?alt=media&token=8beb7f41-92c6-4fe2-912d-39b89f6e261e",
    video_id: "TnS_Double_Hammer_Curl_Into_Double_Lateral_Raise_1",
    video_length: 0.07,
    video_number: "430",
    size: 3198263,
    hints: "TnS_Double_Hammer_Curl_Into_Double_Lateral_Raise_1_hints",
    common_mistakes: "TnS_Double_Hammer_Curl_Into_Double_Lateral_Raise_1_common_mistakes",
    breathing: "TnS_Double_Hammer_Curl_Into_Double_Lateral_Raise_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Reverse Shoulder Press & Squat",
    is_free: true,
    primary_muscle: "Front Shoulders, Quads, Glutes",
    rep_range: "5-10",
    secondary_muscle: "Chest, Traps, Middle Shoulders, Serratus Anterior, Hamstrings, Adductors, Erector Spinae",
    subcategory_name: "total body",
    tertiary_muscle: "Rear Shoulders, Latissimus Dorsi, Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FReverse%20Shoulder%20Press%20%26%20Squat-0.jpg?alt=media&token=dfb7973d-153c-4c03-aded-625343d9f56e",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FReverse%20Shoulder%20Press%20%26%20Squat.mp4?alt=media&token=91415814-e02a-41b0-81b9-a1748b761643",
    video_id: "TnS_Reverse_Shoulder_Press_Squat_1",
    video_length: 0.07,
    video_number: "431",
    size: 2925322,
    hints: "TnS_Reverse_Shoulder_Press_Squat_1_hints",
    common_mistakes: "TnS_Reverse_Shoulder_Press_Squat_1_common_mistakes",
    breathing: "TnS_Reverse_Shoulder_Press_Squat_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Curl Into Flys",
    is_free: false,
    primary_muscle: "Front Shoulders, Quads, Glutes",
    rep_range: "5-10",
    secondary_muscle: "Chest, Traps, Middle Shoulders, Serratus Anterior, Hamstrings, Adductors, Erector Spinae",
    subcategory_name: "total body",
    tertiary_muscle: "Rear Shoulders, Latissimus Dorsi, Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FCurl%20Into%20Flys-0.jpg?alt=media&token=3f0faf46-f14a-4d86-b743-2e4509a71962",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FCurl%20Into%20Flys.mp4?alt=media&token=bbb9c2fd-437f-4639-8426-71f90ac080d3",
    video_id: "TnS_Curl_Into_Flys_1",
    video_length: 0.07,
    video_number: "432",
    size: 2780955,
    hints: "TnS_Curl_Into_Flys_1_hints",
    common_mistakes: "TnS_Curl_Into_Flys_1_common_mistakes",
    breathing: "TnS_Curl_Into_Flys_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Rows Into Hammer Curls",
    is_free: false,
    primary_muscle: "Latissimus Dorsi, Rhomboids, Traps, Erector Spinae, Biceps, Brachialis",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rear Shoulders, Teres Major, Teres Minor, Infraspinatus, Forearm",
    subcategory_name: "total body",
    tertiary_muscle: "Lower Back, Lower Body, Front Shoulders, Middle Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FRows%20Into%20Hammer%20Curls-0.jpg?alt=media&token=cfc5a81f-8310-40ab-b846-a87d97cfba95",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FRows%20Into%20Hammer%20Curls.mp4?alt=media&token=8781fcdf-fd4c-490d-8c27-f213c786ecec",
    video_id: "TnS_Rows_Into_Hammer_Curls_1",
    video_length: 0.07,
    video_number: "433",
    size: 1348842,
    hints: "TnS_Rows_Into_Hammer_Curls_1_hints",
    common_mistakes: "TnS_Rows_Into_Hammer_Curls_1_common_mistakes",
    breathing: "TnS_Rows_Into_Hammer_Curls_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Side Raise Into Tricep Extension",
    is_free: false,
    primary_muscle: "Rear Shoulders, Middle Shoulders, Traps, Rhomboids, Triceps",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Levator Scapulae, Front Shoulders, Core",
    subcategory_name: "total body",
    tertiary_muscle: "Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSide%20Raise%20Into%20Tricep%20Extension-0.jpg?alt=media&token=75a11c24-c6a2-4de2-a4ec-81a69acb578a",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FSide%20Raise%20Into%20Tricep%20Extension.mp4?alt=media&token=b511ea84-cdfe-4b32-ae99-5d859aca8918",
    video_id: "TnS_Side_Raise_Into_Tricep_Extension_1",
    video_length: 0.07,
    video_number: "434",
    size: 1711792,
    hints: "TnS_Side_Raise_Into_Tricep_Extension_1_hints",
    common_mistakes: "TnS_Side_Raise_Into_Tricep_Extension_1_common_mistakes",
    breathing: "TnS_Side_Raise_Into_Tricep_Extension_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Tricep Press Into Shoulder Press",
    is_free: false,
    primary_muscle: "Triceps, Shoulders",
    rep_range: "5-10",
    secondary_muscle: "Forearm, Serratus Anterior",
    subcategory_name: "total body",
    tertiary_muscle: "Traps, Chest, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FTricep%20Press%20Into%20Shoulder%20Press-0.jpg?alt=media&token=9ebc2166-b01e-4ea9-a0f5-f926aad9ecf5",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FTricep%20Press%20Into%20Shoulder%20Press.mp4?alt=media&token=2f0ce255-3bee-4bd0-a1f5-29a74b22c010",
    video_id: "TnS_Tricep_Press_Into_Shoulder_Press_1",
    video_length: 0.07,
    video_number: "435",
    size: 2556447,
    hints: "TnS_Tricep_Press_Into_Shoulder_Press_1_hints",
    common_mistakes: "TnS_Tricep_Press_Into_Shoulder_Press_1_common_mistakes",
    breathing: "TnS_Tricep_Press_Into_Shoulder_Press_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Squatting Side Crunch",
    is_free: false,
    primary_muscle: "Core, Lower Body",
    rep_range: "10-25",
    secondary_muscle: "Latissimus Dorsi",
    subcategory_name: "total body",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSquatting%20Side%20Crunch-0.jpg?alt=media&token=181eb8a8-c9ce-4a72-b890-ca39fc9a2940",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FSquatting%20Side%20Crunch.mp4?alt=media&token=a9ce5115-6b3e-4856-bba8-7e5c54c6e504",
    video_id: "TnS_Squatting_Side_Crunch_1",
    video_length: 0.07,
    video_number: "436",
    size: 1479373,
    hints: "TnS_Squatting_Side_Crunch_1_hints",
    common_mistakes: "TnS_Squatting_Side_Crunch_1_common_mistakes",
    breathing: "TnS_Squatting_Side_Crunch_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Triple Chest Press Into Pull Over",
    is_free: false,
    primary_muscle: "Middle Chest, Latissimus Dorsi",
    rep_range: "4-8",
    secondary_muscle:
      "Upper Chest, Lower Chest, Shoulders Core, Serratus Anterior, Triceps, Rhomboids, Teres Major, Teres Minor, Infraspinatus, Traps",
    subcategory_name: "total body",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FTriple%20Chest%20Press%20Into%20Pull%20Over-0.jpg?alt=media&token=144b020a-c3bd-4dc5-b109-4aefea026a37",
    time: "75 seconds",
    time_to_play: 1.25,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FTriple%20Chest%20Press%20Into%20Pull%20Over.mp4?alt=media&token=4f11fd3b-8c43-4318-97a4-d2d9dd4713a4",
    video_id: "TnS_Triple_Chest_Press_Into_Pull_Over_1",
    video_length: 0.07,
    video_number: "437",
    size: 5071794,
    hints: "TnS_Triple_Chest_Press_Into_Pull_Over_1_hints",
    common_mistakes: "TnS_Triple_Chest_Press_Into_Pull_Over_1_common_mistakes",
    breathing: "TnS_Triple_Chest_Press_Into_Pull_Over_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Triple Chest Press Into Tate Press",
    is_free: false,
    primary_muscle: "Middle Chest, Triceps",
    rep_range: "5-10",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Serratus Anterior, Forearm",
    subcategory_name: "total body",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FTriple%20Chest%20Press%20Into%20Tate%20Press-0.jpg?alt=media&token=36291c14-d148-47c8-9660-ec1b1c4a3390",
    time: "75 seconds",
    time_to_play: 1.25,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FTriple%20Chest%20Press%20Into%20Tate%20Press.mp4?alt=media&token=e3ffafca-d3fa-4ac8-8847-0314b4928519",
    video_id: "TnS_Triple_Chest_Press_Into_Tate_Press_1",
    video_length: 0.07,
    video_number: "438",
    size: 3800595,
    hints: "TnS_Triple_Chest_Press_Into_Tate_Press_1_hints",
    common_mistakes: "TnS_Triple_Chest_Press_Into_Tate_Press_1_common_mistakes",
    breathing: "TnS_Triple_Chest_Press_Into_Tate_Press_1_breathing",
  },
  {
    category_id: 3,
    category_name: "forearms",
    name: "Turning Curl Hold",
    is_free: true,
    primary_muscle: "Biceps, Forearm",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Brachialis",
    subcategory_name: "forearms",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FTurning%20Curl%20Hold-0.jpg?alt=media&token=5e101fe0-bbbe-45f5-a19e-64046c8d634d",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FTurning%20Curl%20Hold.mp4?alt=media&token=c05acc32-cd63-442b-9a30-05e70c6a7bf7",
    video_id: "TnS_Turning_Curl_Hold_1",
    video_length: 0.07,
    video_number: "439",
    size: 681540,
    hints: "TnS_Turning_Curl_Hold_1_hints",
    common_mistakes: "TnS_Turning_Curl_Hold_1_common_mistakes",
    breathing: "TnS_Turning_Curl_Hold_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Chest Fly & Pull Over",
    is_free: false,
    primary_muscle: "Middle Chest, Latissimus Dorsi",
    rep_range: "5-10",
    secondary_muscle:
      "Upper Chest, Lower Chest, Shoulders, Triceps, Core, Serratus Anterior, Rhomboids, Teres Major, Teres Minor, Infraspinatus, Traps",
    subcategory_name: "total body",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FChest%20Fly%20%26%20Pull%20Over-0.jpg?alt=media&token=0eeb6f03-ef41-4c63-9aef-73be4859e16d",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FChest%20Fly%20%26%20Pull%20Over.mp4?alt=media&token=591d8567-3f85-40ba-9d00-09353251b4de",
    video_id: "TnS_Chest_Fly_Pull_Over_1",
    video_length: 0.07,
    video_number: "441",
    size: 3823043,
    hints: "TnS_Chest_Fly_Pull_Over_1_hints",
    common_mistakes: "TnS_Chest_Fly_Pull_Over_1_common_mistakes",
    breathing: "TnS_Chest_Fly_Pull_Over_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Hammer Curl Into Tricep Kickback",
    is_free: false,
    primary_muscle: "Biceps, Brachialis, Triceps",
    rep_range: "5-10",
    secondary_muscle: "Forearm, Rear Shoulders",
    subcategory_name: "total body",
    tertiary_muscle: "Shoulders. Latissimus Dorsi, Traps, Rhomboids",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FHammer%20Curl%20Into%20Tricep%20Kickback-0.jpg?alt=media&token=d8ad0506-672b-4257-bf4a-d8701f80a6d6",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FHammer%20Curl%20Into%20Tricep%20Kickback.mp4?alt=media&token=04bae736-3d90-48f6-80ca-f5e09aba45fa",
    video_id: "TnS_Hammer_Curl_Into_Tricep_Kickback_1",
    video_length: 0.07,
    video_number: "442",
    size: 3057636,
    hints: "TnS_Hammer_Curl_Into_Tricep_Kickback_1_hints",
    common_mistakes: "TnS_Hammer_Curl_Into_Tricep_Kickback_1_common_mistakes",
    breathing: "TnS_Hammer_Curl_Into_Tricep_Kickback_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Push Press Into Sumo Squat",
    is_free: false,
    primary_muscle: "Front Shoulders, Quads, Glutes, Gracilis",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Chest, Traps, Middle Shoulders, Serratus Anterior, Hamstrings, Adductors",
    subcategory_name: "total body",
    tertiary_muscle: "Rear Shoulders, Latissimus Dorsi, Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FPush%20Press%20Into%20Sumo%20Squat-0.jpg?alt=media&token=4146c5a3-996c-46d8-8ada-bc2ddecd3d48",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FPush%20Press%20Into%20Sumo%20Squat.mp4?alt=media&token=6b40f044-5c23-485c-aab0-2ceb27f68648",
    video_id: "TnS_Push_Press_Into_Sumo_Squat_1",
    video_length: 0.07,
    video_number: "443",
    size: 1609401,
    hints: "TnS_Push_Press_Into_Sumo_Squat_1_hints",
    common_mistakes: "TnS_Push_Press_Into_Sumo_Squat_1_common_mistakes",
    breathing: "TnS_Push_Press_Into_Sumo_Squat_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Single Hammer Front Raise Into Squat",
    is_free: false,
    primary_muscle: "Front Shoulders, Quads, Glutes",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Upper Chest, Middle Shoulders, Serratus Anterior, Hamstrings, Adductors, Erector Spinae",
    subcategory_name: "total body",
    tertiary_muscle: "Rear Shoulders, Traps, Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSingle%20Hammer%20Front%20Raise%20Into%20Squat-0.jpg?alt=media&token=0bf7a35c-17ad-4149-8617-b362bdf577d3",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FSingle%20Hammer%20Front%20Raise%20Into%20Squat.mp4?alt=media&token=84ccfb50-cb93-452f-b4c8-553011de7a48",
    video_id: "TnS_Single_Hammer_Front_Raise_Into_Squat_1",
    video_length: 0.07,
    video_number: "444",
    size: 2422993,
    hints: "TnS_Single_Hammer_Front_Raise_Into_Squat_1_hints",
    common_mistakes: "TnS_Single_Hammer_Front_Raise_Into_Squat_1_common_mistakes",
    breathing: "TnS_Single_Hammer_Front_Raise_Into_Squat_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Push Press Into Squat",
    is_free: false,
    primary_muscle: "Front Shoulders, Quads, Glutes, Gracilis",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Chest, Traps, Middle Shoulders, Serratus Anterior, Hamstrings, Adductors",
    subcategory_name: "total body",
    tertiary_muscle: "Rear Shoulders, Latissimus Dorsi, Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FPush%20Press%20Into%20Squat-0.jpg?alt=media&token=8cc4c3ab-4090-41b2-b1ef-80d47ea7fe36",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FPush%20Press%20Into%20Squat.mp4?alt=media&token=f8f442c1-dbf9-4f28-b10c-afd693614555",
    video_id: "TnS_Push_Press_Into_Squat_1",
    video_length: 0.07,
    video_number: "445",
    size: 2199882,
    hints: "TnS_Push_Press_Into_Squat_1_hints",
    common_mistakes: "TnS_Push_Press_Into_Squat_1_common_mistakes",
    breathing: "TnS_Push_Press_Into_Squat_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Front Lunge Into Upright Row",
    is_free: false,
    primary_muscle: "Quads, Hamstrings, Glutes, Traps, Rhomboids, Middle Shoulders",
    rep_range: "5-10",
    secondary_muscle: "Adductors, Front Shoulders, Rear Shoulders, Teres Minor, Infraspinatus, Biceps",
    subcategory_name: "total body",
    tertiary_muscle: "Core, Calves, Teres Major",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FFront%20Lunge%20Into%20Upright%20Row-0.jpg?alt=media&token=634e7381-2b91-4228-b22b-65d00ac1e470",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FFront%20Lunge%20Into%20Upright%20Row.mp4?alt=media&token=2f6efd23-e040-4328-9f15-0c42060ea9df",
    video_id: "TnS_Front_Lunge_Into_Upright_Row_1",
    video_length: 0.07,
    video_number: "446",
    size: 3723379,
    hints: "TnS_Front_Lunge_Into_Upright_Row_1_hints",
    common_mistakes: "TnS_Front_Lunge_Into_Upright_Row_1_common_mistakes",
    breathing: "TnS_Front_Lunge_Into_Upright_Row_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Front Raise Into Triple Tricep Extension",
    is_free: false,
    primary_muscle: "Front Shoulders, Triceps",
    rep_range: "5-10",
    secondary_muscle: "Upper Chest, Middle Shoulders, Rear Shoulders, Serratus Anterior, Forearm",
    subcategory_name: "total body",
    tertiary_muscle: "Traps, Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FFront%20Raise%20Into%20Triple%20Tricep%20Extension-0.jpg?alt=media&token=96e208b8-13d2-4d7a-be20-0da0c9224253",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FFront%20Raise%20Into%20Triple%20Tricep%20Extension.mp4?alt=media&token=61e60891-9d98-44df-8109-edf4afc64096",
    video_id: "TnS_Front_Raise_Into_Triple_Tricep_Extension_1",
    video_length: 0.07,
    video_number: "447",
    size: 3232501,
    hints: "TnS_Front_Raise_Into_Triple_Tricep_Extension_1_hints",
    common_mistakes: "TnS_Front_Raise_Into_Triple_Tricep_Extension_1_common_mistakes",
    breathing: "TnS_Front_Raise_Into_Triple_Tricep_Extension_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Farmers Walk",
    is_free: false,
    primary_muscle: "Quads, Glutes, Calves Latissimus Dorsi, Core, Erector Spinae, Traps, Hamstrings",
    rep_range: "Time Based",
    secondary_muscle: "Adductors, Levator Scapulae, Rhomboids, Teres Major, Teres Minor",
    subcategory_name: "total body",
    tertiary_muscle: "Infraspinatus, Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FFarmers%20Walk-0.jpg?alt=media&token=7de312fb-4bb4-4129-944a-de19868018a7",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FFarmers%20Walk.mp4?alt=media&token=ca3c0169-b927-45a4-b4a7-aae15f9472c8",
    video_id: "TnS_Farmers_Walk_1",
    video_length: 0.07,
    video_number: "448",
    size: 2976256,
    hints: "TnS_Farmers_Walk_1_hints",
    common_mistakes: "TnS_Farmers_Walk_1_common_mistakes",
    breathing: "TnS_Farmers_Walk_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Front Hammer Curl Into Hammer Back Fly",
    is_free: false,
    primary_muscle: "Biceps, Brachialis, Traps, Rhomboids, Rear Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Front Shoulders, Forearm, Teres Major, Teres Minor, Infraspinatus",
    subcategory_name: "total body",
    tertiary_muscle: "Middle Shoulders, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FFront%20Hammer%20Curl%20Into%20Hammer%20Back%20Fly-0.jpg?alt=media&token=5952894e-2585-4bf4-bfa1-ce5f3adc4136",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FFront%20Hammer%20Curl%20Into%20Hammer%20Back%20Fly.mp4?alt=media&token=4409bef9-bff0-4791-80ba-01fb30711007",
    video_id: "TnS_Front_Hammer_Curl_Into_Hammer_Back_Fly_1",
    video_length: 0.07,
    video_number: "449",
    size: 2650963,
    hints: "TnS_Front_Hammer_Curl_Into_Hammer_Back_Fly_1_hints",
    common_mistakes: "TnS_Front_Hammer_Curl_Into_Hammer_Back_Fly_1_common_mistakes",
    breathing: "TnS_Front_Hammer_Curl_Into_Hammer_Back_Fly_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Hammer Front Raise Into Double Hammer Curl",
    is_free: false,
    primary_muscle: "Front Shoulders, Biceps, Brachialis",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Upper Chest, Middle Shoulders, Serratus Anterior, Forearm",
    subcategory_name: "total body",
    tertiary_muscle: "Rear Shoulders, Traps, Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FHammer%20Front%20Raise%20Into%20Double%20Hammer%20Curl-0.jpg?alt=media&token=b73909e8-4608-4ac4-b8ff-b9c919618c04",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FHammer%20Front%20Raise%20Into%20Double%20Hammer%20Curl.mp4?alt=media&token=b4a789c0-d972-42a0-b654-d2f805b452c0",
    video_id: "TnS_Hammer_Front_Raise_Into_Double_Hammer_Curl_1",
    video_length: 0.07,
    video_number: "450",
    size: 2637906,
    hints: "TnS_Hammer_Front_Raise_Into_Double_Hammer_Curl_1_hints",
    common_mistakes: "TnS_Hammer_Front_Raise_Into_Double_Hammer_Curl_1_common_mistakes",
    breathing: "TnS_Hammer_Front_Raise_Into_Double_Hammer_Curl_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Curl Into Front Raise",
    is_free: false,
    primary_muscle: "Biceps, Front Shoulders",
    rep_range: "5-10",
    secondary_muscle: "Brachialis, Forearm, Upper Chest, Middle Shoulders, Serratus Anterior",
    subcategory_name: "total body",
    tertiary_muscle: "Rear Shoulders, Traps, Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FCurl%20Into%20Front%20Raise-0.jpg?alt=media&token=5f3953b1-02ae-4209-9550-a822305af81d",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FCurl%20Into%20Front%20Raise.mp4?alt=media&token=74357c51-684e-4244-985f-9ba92f524f25",
    video_id: "TnS_Curl_Into_Front_Raise_1",
    video_length: 0.07,
    video_number: "451",
    size: 2602346,
    hints: "TnS_Curl_Into_Front_Raise_1_hints",
    common_mistakes: "TnS_Curl_Into_Front_Raise_1_common_mistakes",
    breathing: "TnS_Curl_Into_Front_Raise_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Wide Tricep Kickback Into Shrug",
    is_free: false,
    primary_muscle: "Triceps, Traps, Rhomboids",
    rep_range: "5-10",
    secondary_muscle: "Rear Shoulders, Levator Scapulae, Teres Major, Teres Minor",
    subcategory_name: "total body",
    tertiary_muscle: "Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FCurl%20Into%20Front%20Raise-0.jpg?alt=media&token=5f3953b1-02ae-4209-9550-a822305af81d",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FWide%20Tricep%20Kickback%20Into%20Shrug.mp4?alt=media&token=642aa954-66c9-4f21-b288-314e13b9a77f",
    video_id: "TnS_Wide_Tricep_Kickback_Into_Shrug_1",
    video_length: 0.07,
    video_number: "452",
    size: 2656171,
    hints: "TnS_Wide_Tricep_Kickback_Into_Shrug_1_hints",
    common_mistakes: "TnS_Wide_Tricep_Kickback_Into_Shrug_1_common_mistakes",
    breathing: "TnS_Wide_Tricep_Kickback_Into_Shrug_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Reverse Curl Into Reverse Row",
    is_free: false,
    primary_muscle: "Biceps (Long Head), Brachialis, Forearm, Latissimus Dorsi, Rhomboids, Traps, Erector Spinae",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Biceps (Short Head), Rear Shoulders, Teres Major, Teres Minor, Infraspinatus",
    subcategory_name: "total body",
    tertiary_muscle: "Front Shoulders, Middle Shoulders, Lower Back, Lower Body",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FReverse%20Curl%20Into%20Reverse%20Row-0.jpg?alt=media&token=f573079e-b862-4d5c-9e2b-13fd88a6ab07",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FReverse%20Curl%20Into%20Reverse%20Row.mp4?alt=media&token=ab29c853-4cfe-4e02-9982-d1c3ba342e81",
    video_id: "TnS_Reverse_Curl_Into_Reverse_Row_1",
    video_length: 0.07,
    video_number: "453",
    size: 2722978,
    hints: "TnS_Reverse_Curl_Into_Reverse_Row_1_hints",
    common_mistakes: "TnS_Reverse_Curl_Into_Reverse_Row_1_common_mistakes",
    breathing: "TnS_Reverse_Curl_Into_Reverse_Row_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Curl Into Side Row",
    is_free: true,
    primary_muscle: "Biceps, Traps, Latissimus Dorsi, Rhomboids, Teres Major, Teres Minor",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Brachialis, Forearm, Erector Spinae, Infraspinatus, Middle Shoulders, Rear Shoulders",
    subcategory_name: "total body",
    tertiary_muscle: "Front Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FCurl%20Into%20Side%20Row-0.jpg?alt=media&token=64110b04-c27c-43c5-839a-0c79f31b1273",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FCurl%20Into%20Side%20Row.mp4?alt=media&token=b7df0fcc-0abd-4c7c-906a-449c5852656f",
    video_id: "TnS_Curl_Into_Side_Row_1",
    video_length: 0.07,
    video_number: "454",
    size: 2057156,
    hints: "TnS_Curl_Into_Side_Row_1_hints",
    common_mistakes: "TnS_Curl_Into_Side_Row_1_common_mistakes",
    breathing: "TnS_Curl_Into_Side_Row_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Straight Curl Into Side Raise",
    is_free: false,
    primary_muscle: "Biceps, Middle Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Brachialis, Forearm, Front Shoulders, Rear Shoulders, Traps, Serratus Anterior",
    subcategory_name: "total body",
    tertiary_muscle: "Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FStraight%20Curl%20Into%20Side%20Raise-0.jpg?alt=media&token=8e06a9b4-df43-4d62-9318-588df55e8843",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FStraight%20Curl%20Into%20Side%20Raise.mp4?alt=media&token=96c4b81d-02a8-4049-911a-1f1eb3a7d99b",
    video_id: "TnS_Straight_Curl_Into_Side_Raise_1",
    video_length: 0.07,
    video_number: "455",
    size: 2615717,
    hints: "TnS_Straight_Curl_Into_Side_Raise_1_hints",
    common_mistakes: "TnS_Straight_Curl_Into_Side_Raise_1_common_mistakes",
    breathing: "TnS_Straight_Curl_Into_Side_Raise_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Wide Curl Into Front To Side Raise",
    is_free: false,
    primary_muscle: "Quads, Glutes",
    rep_range: "Hypertrophy/Strength : 8-12 Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Hamstrings, Adductors, Shoulders, Teres Major, Teres Minor, Rhomboids",
    subcategory_name: "total body",
    tertiary_muscle: "Core, Calves, Infraspinatus, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FWide%20Curl%20Into%20Front%20To%20Side%20Raise-0.jpg?alt=media&token=3f0f0e53-47c4-45a6-819a-a25890738470",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FWide%20Curl%20Into%20Front%20To%20Side%20Raise.mp4?alt=media&token=e9abd454-9a93-4e61-ab74-960278f69876",
    video_id: "TnS_Wide_Curl_Into_Front_To_Side_Raise_1",
    video_length: 0.07,
    video_number: "456",
    size: 882197,
    hints: "TnS_Wide_Curl_Into_Front_To_Side_Raise_1_hints",
    common_mistakes: "TnS_Wide_Curl_Into_Front_To_Side_Raise_1_common_mistakes",
    breathing: "TnS_Wide_Curl_Into_Front_To_Side_Raise_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Double Out Curl Into Double Side Raise",
    is_free: false,
    primary_muscle: "Biceps (Short Head), Middle Shoulders",
    rep_range: "5-10",
    secondary_muscle: "Biceps (Long Head), Brachialis, Forearm, Front Shoulders, Rear Shoulders, Traps, Serratus Anterior",
    subcategory_name: "total body",
    tertiary_muscle: "Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FDouble%20Out%20Curl%20Into%20Double%20Side%20Raise-0.jpg?alt=media&token=ef9e89b8-d823-41e8-a3b8-1c236e50ee33",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FDouble%20Out%20Curl%20Into%20Double%20Side%20Raise.mp4?alt=media&token=16589362-d2d3-4065-9f75-556929ad0598",
    video_id: "TnS_Double_Out_Curl_Into_Double_Side_Raise_1",
    video_length: 0.07,
    video_number: "457",
    size: 4272750,
    hints: "TnS_Double_Out_Curl_Into_Double_Side_Raise_1_hints",
    common_mistakes: "TnS_Double_Out_Curl_Into_Double_Side_Raise_1_common_mistakes",
    breathing: "TnS_Double_Out_Curl_Into_Double_Side_Raise_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Wide Upright Row Into Shoulder Press",
    is_free: false,
    primary_muscle: "Traps, Rhomboids, Middle Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Front Shoulders, Rear Shoulders, Rhomboids, Teres Minor, Infraspinatus, Biceps, Serratus Anterior",
    subcategory_name: "total body",
    tertiary_muscle: "Teres Major, Chest Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FWide%20Upright%20Row%20Into%20Shoulder%20Press-0.jpg?alt=media&token=9b12c512-72f9-4ccf-bfc4-bd58a3cb07b8",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FWide%20Upright%20Row%20Into%20Shoulder%20Press.mp4?alt=media&token=5cb62706-fe26-45d9-b22f-9b24f7b4f866",
    video_id: "TnS_Wide_Upright_Row_Into_Shoulder_Press_2",
    video_length: 0.07,
    video_number: "458",
    size: 2622136,
    hints: "TnS_Wide_Upright_Row_Into_Shoulder_Press_2_hints",
    common_mistakes: "TnS_Wide_Upright_Row_Into_Shoulder_Press_2_common_mistakes",
    breathing: "TnS_Wide_Upright_Row_Into_Shoulder_Press_2_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Squat Dumbbell Raise",
    is_free: true,
    primary_muscle: "Quads, Glutes, Traps, Front Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle:
      "Hamstrings, Adductors, Upper Chest, Middle Shoulders, Rear Shoulders Serratus Anterior, Rhomboids, Erector Spinae, Teres Major, Teres Minor",
    subcategory_name: "total body",
    tertiary_muscle: "Core, Calves, Infraspinatus. Latissimus Dorsi, Lower Back",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSquat%20Dumbbell%20Raise-0.jpg?alt=media&token=776dd5b7-e89e-4c2c-b474-1c285852f08b",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FSquat%20Dumbbell%20Raise.mp4?alt=media&token=2bbb07e1-63e6-46dc-a8b3-6b744d94e241",
    video_id: "TnS_Squat_Dumbbell_Raise_1",
    video_length: 0.07,
    video_number: "459",
    size: 1293007,
    hints: "TnS_Squat_Dumbbell_Raise_1_hints",
    common_mistakes: "TnS_Squat_Dumbbell_Raise_1_common_mistakes",
    breathing: "TnS_Squat_Dumbbell_Raise_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Hammer Raise Into Triple Tricep Extension",
    is_free: false,
    primary_muscle: "Traps, Front Shoulders, Triceps",
    rep_range: "5-10",
    secondary_muscle:
      "Upper Chest, Middle Shoulders, Rear Shoulders Serratus Anterior, Rhomboids, Erector Spinae, Teres Major, Teres Minor, Forearm",
    subcategory_name: "total body",
    tertiary_muscle: "Core, Infraspinatus. Latissimus Dorsi, Lower Back",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F%20Hammer%20Raise%20Into%20Triple%20Tricep%20Extension-0.jpg?alt=media&token=b0491128-9e57-464b-ba40-d51a59e9d33d",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2F%20Hammer%20Raise%20Into%20Triple%20Tricep%20Extension.mp4?alt=media&token=c09e31e7-df7b-4ef1-b1d3-414a81dac729",
    video_id: "TnS_Hammer_Raise_Into_Triple_Tricep_Extension_1",
    video_length: 0.07,
    video_number: "460",
    size: 2988563,
    hints: "TnS_Hammer_Raise_Into_Triple_Tricep_Extension_1_hints",
    common_mistakes: "TnS_Hammer_Raise_Into_Triple_Tricep_Extension_1_common_mistakes",
    breathing: "TnS_Hammer_Raise_Into_Triple_Tricep_Extension_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Fly Into Skull Crusher",
    is_free: false,
    primary_muscle: "Middle Chest, Triceps",
    rep_range: "5-10",
    secondary_muscle: "Upper Chest, Lower Chest, Shoulders, Core, Serratus Anterior, Forearm",
    subcategory_name: "total body",
    tertiary_muscle:
      "Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FFly%20Into%20Skull%20Crusher-0.jpg?alt=media&token=3a238525-2457-432a-a6ff-60df6d798503",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FFly%20Into%20Skull%20Crusher.mp4?alt=media&token=4c2ef01d-4c88-411d-a652-e7dee60ce0d0",
    video_id: "TnS_Fly_Into_Skull_Crusher_1",
    video_length: 0.07,
    video_number: "461",
    size: 2568511,
    hints: "TnS_Fly_Into_Skull_Crusher_1_hints",
    common_mistakes: "TnS_Fly_Into_Skull_Crusher_1_common_mistakes",
    breathing: "TnS_Fly_Into_Skull_Crusher_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Side Raise Into Front Lunge",
    is_free: false,
    primary_muscle: "Middle Shoulders, Quads, Hamstrings, Glutes",
    rep_range: "5-10",
    secondary_muscle: "Front Shoulders, Rear Shoulders, Traps, Serratus Anterior, Adductors",
    subcategory_name: "total body",
    tertiary_muscle: "Latissimus Dorsi, Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSide%20Raise%20Into%20Front%20Lunge%20(0).jpeg?alt=media&token=c2204f14-c742-4432-91e1-0606c47db48c",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FSide%20Raise%20Into%20Front%20Lunge.mp4?alt=media&token=94f20031-24c0-46a8-9488-225ed3c5aca0",
    video_id: "TnS_Side_Raise_Into_Front_Lunge_1",
    video_length: 0.07,
    video_number: "462",
    size: 2568511,
    hints: "TnS_Side_Raise_Into_Front_Lunge_1_hints",
    common_mistakes: "TnS_Side_Raise_Into_Front_Lunge_1_common_mistakes",
    breathing: "TnS_Side_Raise_Into_Front_Lunge_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Single Double Curl Into Double Tricep Kickback",
    is_other_side: 0,
    is_free: false,
    primary_muscle: "Biceps, Triceps",
    rep_range: "5-10",
    secondary_muscle: "Brachialis, Forearm, Rear Shoulders",
    subcategory_name: "total body",
    tertiary_muscle: "Latissimus Dorsi, Traps, Rhomboids, Front Shoulders, Middle Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(L)%20Single%20Double%20Curl%20Into%20Double%20Tricep%20Kickback-0.jpg?alt=media&token=cf657d0a-6e11-4d39-b5d7-5681662f5b8b",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2F(L)%20Single%20Double%20Curl%20Into%20Double%20Tricep%20Kickback.mp4?alt=media&token=a535c68f-35dc-4f22-b689-130a35e27d50",
    video_id: "TnS_Single_Double_Curl_Into_Double_Tricep_Kickback_1",
    video_length: 0.07,
    video_number: "463",
    size: 3326078,
    hints: "TnS_Single_Double_Curl_Into_Double_Tricep_Kickback_1_hints",
    common_mistakes: "TnS_Single_Double_Curl_Into_Double_Tricep_Kickback_1_common_mistakes",
    breathing: "TnS_Single_Double_Curl_Into_Double_Tricep_Kickback_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Single Double Curl Into Double Tricep Kickback",
    is_other_side: 1,
    is_free: false,
    primary_muscle: "Biceps, Triceps",
    rep_range: "5-10",
    secondary_muscle: "Brachialis, Forearm, Rear Shoulders",
    subcategory_name: "total body",
    tertiary_muscle: "Latissimus Dorsi, Traps, Rhomboids, Front Shoulders, Middle Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(R)%20Single%20Double%20Curl%20Into%20Double%20Tricep%20Kickback-0.jpg?alt=media&token=50bb9e55-75eb-4cb8-b095-88fcff899ba1",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2F(R)%20Single%20Double%20Curl%20Into%20Double%20Tricep%20Kickback.mp4?alt=media&token=9c6d68a5-ff63-4e64-aa9c-aaaf433290ac",
    video_id: "TnS_Single_Double_Curl_Into_Double_Tricep_Kickback_2",
    video_length: 0.07,
    video_number: "464",
    size: 3299005,
    hints: "TnS_Single_Double_Curl_Into_Double_Tricep_Kickback_2_hints",
    common_mistakes: "TnS_Single_Double_Curl_Into_Double_Tricep_Kickback_2_common_mistakes",
    breathing: "TnS_Single_Double_Curl_Into_Double_Tricep_Kickback_2_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Double RDL Into Double Squat",
    is_free: false,
    primary_muscle: "Quads, Glutes, Erector Spinae, Rear Shoulders",
    rep_range: "4-8",
    secondary_muscle:
      "Hamstrings, Adductors, Traps, Lower Back, Latissimus Dorsi, Erector Spinae, Middle Shoulders, Rhomboids, Infraspinatus, Teres Minor",
    subcategory_name: "total body",
    tertiary_muscle: "Core, Calves, Front Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FDouble%20RDL%20Into%20Double%20Squat-0.jpg?alt=media&token=846ae523-0a90-482e-8582-84559fd0eb38",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FDouble%20RDL%20Into%20Double%20Squat.mp4?alt=media&token=2235b26a-1b03-4a2d-b6c7-2cd876b21b0a",
    video_id: "TnS_Double_RDL_Into_Double_Squat_1",
    video_length: 0.07,
    video_number: "465",
    size: 5312836,
    hints: "TnS_Double_RDL_Into_Double_Squat_1_hints",
    common_mistakes: "TnS_Double_RDL_Into_Double_Squat_1_common_mistakes",
    breathing: "TnS_Double_RDL_Into_Double_Squat_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Reverse Shrug Into Side Raise",
    is_free: false,
    primary_muscle: "Traps, Rhomboids, Middle Shoulders, Rear Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Front Shoulders, Levator Scapulae, Traps, Serratus Anterior",
    subcategory_name: "total body",
    tertiary_muscle: "Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FReverse%20Shrug%20Into%20Side%20Raise-0.jpg?alt=media&token=d94f6f76-cbae-4692-b519-1aabffe6171f",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FReverse%20Shrug%20Into%20Side%20Raise.mp4?alt=media&token=c1e0a411-bd3a-49c7-a22c-d74c70e412f9",
    video_id: "TnS_Reverse_Shrug_Into_Side_Raise_1",
    video_length: 0.07,
    video_number: "466",
    size: 1889676,
    hints: "TnS_Reverse_Shrug_Into_Side_Raise_1_hints",
    common_mistakes: "TnS_Reverse_Shrug_Into_Side_Raise_1_common_mistakes",
    breathing: "TnS_Reverse_Shrug_Into_Side_Raise_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Double Squat Into Shoulder Press",
    is_free: true,
    primary_muscle: "Quads, Glutes Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Hamstrings, Adductors, Erector Spinae, Serratus Anterior",
    subcategory_name: "total body",
    tertiary_muscle: "Core, Calves, Traps, Chest, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FDouble%20Squat%20Into%20Shoulder%20Press-0.jpg?alt=media&token=566863ad-39c6-4542-bb92-3891f4168b5a",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FDouble%20Squat%20Into%20Shoulder%20Press.mp4?alt=media&token=d21c5403-d3e8-4c97-8c05-2d4079980bae",
    video_id: "TnS_Double_Squat_Into_Shoulder_Press_1",
    video_length: 0.07,
    video_number: "467",
    size: 1508182,
    hints: "TnS_Double_Squat_Into_Shoulder_Press_1_hints",
    common_mistakes: "TnS_Double_Squat_Into_Shoulder_Press_1_common_mistakes",
    breathing: "TnS_Double_Squat_Into_Shoulder_Press_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Lunge With Hammer Front Raise",
    is_free: false,
    primary_muscle: "Quads, Hamstrings, Glutes, Front Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Adductors, Upper Chest, Middle Shoulders, Serratus Anterior",
    subcategory_name: "total body",
    tertiary_muscle: "Core, Calves, Rear Shoulders, Traps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FLunge%20With%20Hammer%20Front%20Raise-0.jpg?alt=media&token=25d077df-6770-4b81-b43a-ef887e1c625d",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FLunge%20With%20Hammer%20Front%20Raise.mp4?alt=media&token=934328f3-8ab1-45b9-a3fa-fd16e4bab286",
    video_id: "TnS_Lunge_With_Hammer_Front_Raise_1",
    video_length: 0.07,
    video_number: "468",
    size: 2859291,
    hints: "TnS_Lunge_With_Hammer_Front_Raise_1_hints",
    common_mistakes: "TnS_Lunge_With_Hammer_Front_Raise_1_common_mistakes",
    breathing: "TnS_Lunge_With_Hammer_Front_Raise_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Seated Out Bicep Curl Into Shoulder Press",
    is_free: false,
    primary_muscle: "Biceps (Short Head), Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Biceps (Long Head), Brachialis, Forearm, Serratus Anterior",
    subcategory_name: "total body",
    tertiary_muscle: "Traps, Chest, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSeated%20Out%20Bicep%20Curl%20Into%20Shoulder%20Press-0.jpg?alt=media&token=1a7eea9a-7d4b-4698-92ef-beb3cc7ecc92",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FSeated%20Out%20Bicep%20Curl%20Into%20Shoulder%20Press.mp4?alt=media&token=db020876-b866-400c-953c-3a6e7c24039c",
    video_id: "TnS_Seated_Out_Bicep_Curl_Into_Shoulder_Press_1",
    video_length: 0.07,
    video_number: "469",
    size: 2515984,
    hints: "TnS_Seated_Out_Bicep_Curl_Into_Shoulder_Press_1_hints",
    common_mistakes: "TnS_Seated_Out_Bicep_Curl_Into_Shoulder_Press_1_common_mistakes",
    breathing: "TnS_Seated_Out_Bicep_Curl_Into_Shoulder_Press_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "High Pull Lateral Raise From Floor",
    is_free: true,
    primary_muscle: "Quads, Glutes, Middle Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle:
      "Hamstrings, Adductors, Erector Spinae, Front Shoulders, Rear Shoulders, Traps, Serratus Anterior, Rhomboids, Teres Major, Teres Minor, Infraspinatus",
    subcategory_name: "total body",
    tertiary_muscle: "Core, Calves, Latissimus Dorsi, Lower Back",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FHigh%20Pull%20Lateral%20Raise%20From%20Floor-0.jpg?alt=media&token=2ee7008b-c3f8-4b1b-9b20-21380420d16a",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FHigh%20Pull%20Lateral%20Raise%20From%20Floor.mp4?alt=media&token=c125895b-b72e-4c18-b7f2-238a9cc6402a",
    video_id: "TnS_High_Pull_Lateral_Raise_From_Floor_1",
    video_length: 0.07,
    video_number: "470",
    size: 1198477,
    hints: "TnS_High_Pull_Lateral_Raise_From_Floor_1_hints",
    common_mistakes: "TnS_High_Pull_Lateral_Raise_From_Floor_1_common_mistakes",
    breathing: "TnS_High_Pull_Lateral_Raise_From_Floor_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Seated Double Out Curl Into Double Lateral Raise",
    is_free: false,
    primary_muscle: "Biceps (Short Head), Middle Shoulders",
    rep_range: "4-8",
    secondary_muscle: "Biceps (Long Head), Brachialis, Forearm, Front Shoulders, Rear Shoulders, Traps, Serratus Anterior",
    subcategory_name: "total body",
    tertiary_muscle: "Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSeated%20Double%20Out%20Curl%20Into%20Double%20Lateral%20Raise-0.jpg?alt=media&token=8b262f4b-0226-4e5e-b15e-2aa86a3b8d91",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FSeated%20Double%20Out%20Curl%20Into%20Double%20Lateral%20Raise.mp4?alt=media&token=ce0986c2-36c6-4350-bcfa-caff11e08eb4",
    video_id: "TnS_Seated_Double_Out_Curl_Into_Double_Lateral_Raise_1",
    video_length: 0.07,
    video_number: "471",
    size: 3761985,
    hints: "TnS_Seated_Double_Out_Curl_Into_Double_Lateral_Raise_1_hints",
    common_mistakes: "TnS_Seated_Double_Out_Curl_Into_Double_Lateral_Raise_1_common_mistakes",
    breathing: "TnS_Seated_Double_Out_Curl_Into_Double_Lateral_Raise_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Hammer Curl Into Side Row",
    is_free: false,
    primary_muscle: "Biceps, Brachialis, Traps, Latissimus Dorsi, Rhomboids, Teres Major, Teres Minor",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Forearm, Erector Spinae, Infraspinatus, Middle Shoulders, Rear Shoulders",
    subcategory_name: "total body",
    tertiary_muscle: "Front Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FHammer%20Curl%20Into%20Side%20Row-0.jpg?alt=media&token=9b6b3dd1-4b2c-4ad0-9dbc-2dd8de4ff3af",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FHammer%20Curl%20Into%20Side%20Row.mp4?alt=media&token=f8de27dd-d068-457d-ba30-91eef14179f0",
    video_id: "TnS_Hammer_Curl_Into_Side_Row_1",
    video_length: 0.07,
    video_number: "472",
    size: 1688684,
    hints: "TnS_Hammer_Curl_Into_Side_Row_1_hints",
    common_mistakes: "TnS_Hammer_Curl_Into_Side_Row_1_common_mistakes",
    breathing: "TnS_Hammer_Curl_Into_Side_Row_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Curl Into Reverse Shrug",
    is_free: false,
    primary_muscle: "Biceps, Traps, Rhomboids",
    rep_range: "5-10",
    secondary_muscle: "Brachialis, Forearm, Rear Shoulders, Levator Scapulae",
    subcategory_name: "total body",
    tertiary_muscle: "Front Shoulders, Middle Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FCurl%20Into%20Reverse%20Shrug-0.jpg?alt=media&token=766b54df-b3cf-4b6e-814c-0ba2c2cd7982",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FCurl%20Into%20Reverse%20Shrug.mp4?alt=media&token=a87b119a-17a8-409c-8187-3dbc6e7eba13",
    video_id: "TnS_Curl_Into_Reverse_Shrug_1",
    video_length: 0.07,
    video_number: "473",
    size: 3272800,
    hints: "TnS_Curl_Into_Reverse_Shrug_1_hints",
    common_mistakes: "TnS_Curl_Into_Reverse_Shrug_1_common_mistakes",
    breathing: "TnS_Curl_Into_Reverse_Shrug_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Shoulder Press Into Squat",
    is_free: false,
    primary_muscle: "Shoulders, Quads, Glutes",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Serratus Anterior, Hamstrings, Adductors, Erector Spinae",
    subcategory_name: "total body",
    tertiary_muscle: "Traps, Chest, Latissimus Dorsi, Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FShoulder%20Press%20Into%20Squat-0.jpg?alt=media&token=7fc71377-9b88-4ed9-94f6-d58fc08c06ff",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FShoulder%20Press%20Into%20Squat.mp4?alt=media&token=4d998670-135b-477e-b7df-13d569b16e54",
    video_id: "TnS_Shoulder_Press_Into_Squat_1",
    video_length: 0.07,
    video_number: "474",
    size: 1611068,
    hints: "TnS_Shoulder_Press_Into_Squat_1_hints",
    common_mistakes: "TnS_Shoulder_Press_Into_Squat_1_common_mistakes",
    breathing: "TnS_Shoulder_Press_Into_Squat_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Alternating Shoulder Press Into Squat",
    is_free: false,
    primary_muscle: "Shoulders, Quads, Glutes",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Serratus Anterior, Hamstrings, Adductors, Erector Spinae",
    subcategory_name: "total body",
    tertiary_muscle: "Traps, Chest, Latissimus Dorsi, Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FAlternating%20Shoulder%20Press%20Into%20Squat-0.jpg?alt=media&token=dd10393f-f866-43b5-9902-62ea4f50d045",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FAlternating%20Shoulder%20Press%20Into%20Squat.mp4?alt=media&token=87b4b985-51ba-43cc-a1b4-28b557cadd7f",
    video_id: "TnS_Alternating_Shoulder_Press_Into_Squat_1",
    video_length: 0.07,
    video_number: "475",
    size: 1739366,
    hints: "TnS_Alternating_Shoulder_Press_Into_Squat_1_hints",
    common_mistakes: "TnS_Alternating_Shoulder_Press_Into_Squat_1_common_mistakes",
    breathing: "TnS_Alternating_Shoulder_Press_Into_Squat_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Double Straight Curl Into Double Shrug",
    is_free: false,
    primary_muscle: "Biceps, Traps, Rhomboids",
    rep_range: "5-10",
    secondary_muscle: "Brachialis, Forearm, Rear Shoulders, Levator Scapulae",
    subcategory_name: "total body",
    tertiary_muscle: "Front Shoulders, Middle Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FDouble%20Straight%20Curl%20Into%20Double%20Shrug-0.jpg?alt=media&token=c83443d0-f56f-4f84-8fdb-4533583d11df",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FDouble%20Straight%20Curl%20Into%20Double%20Shrug.mp4?alt=media&token=c8317680-0ab6-4ee1-9e45-d83ec6b97165",
    video_id: "TnS_Double_Straight_Curl_Into_Double_Shrug_1",
    video_length: 0.07,
    video_number: "476",
    size: 4023819,
    hints: "TnS_Double_Straight_Curl_Into_Double_Shrug_1_hints",
    common_mistakes: "TnS_Double_Straight_Curl_Into_Double_Shrug_1_common_mistakes",
    breathing: "TnS_Double_Straight_Curl_Into_Double_Shrug_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Squat Into Front Extension",
    is_free: false,
    primary_muscle: "Quads, Glutes, Front Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Hamstrings, Adductors, Erector Spinae, Upper Chest, Middle Shoulders, Serratus Anterior",
    subcategory_name: "total body",
    tertiary_muscle: "Core, Calves, Rear Shoulders, Traps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSquat%20Into%20Front%20Extension-0.jpg?alt=media&token=418f2733-2c49-4cb5-9624-ad99486742a8",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FSquat%20Into%20Front%20Extension.mp4?alt=media&token=89d50c89-5527-42fb-a860-6392e610d37e",
    video_id: "TnS_Squat_Into_Front_Extension_1",
    video_length: 0.07,
    video_number: "477",
    size: 1567067,
    hints: "TnS_Squat_Into_Front_Extension_1_hints",
    common_mistakes: "TnS_Squat_Into_Front_Extension_1_common_mistakes",
    breathing: "TnS_Squat_Into_Front_Extension_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Front Lunge Into Hammer Curl",
    is_free: false,
    primary_muscle: "Quads, Hamstrings, Glutes, Biceps, Brachialis",
    rep_range: "5-10",
    secondary_muscle: "Adductors, Forearm",
    subcategory_name: "total body",
    tertiary_muscle: "Core, Calves, Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FFront%20Lunge%20Into%20Hammer%20Curl-0.jpg?alt=media&token=ffcaa5f1-69c1-4efe-b2d3-17aebdb704e4",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FFront%20Lunge%20Into%20Hammer%20Curl.mp4?alt=media&token=01c9a943-e792-4dda-8348-b8f748af2185",
    video_id: "TnS_Front_Lunge_Into_Hammer_Curl_1",
    video_length: 0.07,
    video_number: "478",
    size: 3933680,
    hints: "TnS_Front_Lunge_Into_Hammer_Curl_1_hints",
    common_mistakes: "TnS_Front_Lunge_Into_Hammer_Curl_1_common_mistakes",
    breathing: "TnS_Front_Lunge_Into_Hammer_Curl_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Side Wrist Curl Into Hammer Curl",
    is_free: false,
    primary_muscle: "Forearm, Biceps, Brachialis",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "",
    subcategory_name: "total body",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSide%20Wrist%20Curl%20Into%20Hammer%20Curl-0.jpg?alt=media&token=28301404-ec33-4975-a0ed-d43bec92028c",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FSide%20Wrist%20Curl%20Into%20Hammer%20Curl.mp4?alt=media&token=f57be6c2-9541-47e3-897c-e042b64e1495",
    video_id: "TnS_Side_Wrist_Curl_Into_Hammer_Curl_1",
    video_length: 0.07,
    video_number: "479",
    size: 1886261,
    hints: "TnS_Side_Wrist_Curl_Into_Hammer_Curl_1_hints",
    common_mistakes: "TnS_Side_Wrist_Curl_Into_Hammer_Curl_1_common_mistakes",
    breathing: "TnS_Side_Wrist_Curl_Into_Hammer_Curl_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Hammer Curl Into Front Extension",
    is_free: false,
    primary_muscle: "Biceps, Brachialis, Front Shoulders, Middle Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Forearm, Upper Chest, Serratus Anterior",
    subcategory_name: "total body",
    tertiary_muscle: "Rear Shoulders, Traps, Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FHammer%20Curl%20Into%20Front%20Extension-0.jpg?alt=media&token=faec6db2-d565-4af3-b761-ad969c260724",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FHammer%20Curl%20Into%20Front%20Extension.mp4?alt=media&token=c4c9eb0b-ccce-4c02-9f34-8318d7e16c10",
    video_id: "TnS_Hammer_Curl_Into_Front_Extension_1",
    video_length: 0.07,
    video_number: "480",
    size: 2211177,
    hints: "TnS_Hammer_Curl_Into_Front_Extension_1_hints",
    common_mistakes: "TnS_Hammer_Curl_Into_Front_Extension_1_common_mistakes",
    breathing: "TnS_Hammer_Curl_Into_Front_Extension_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Straight Curl Into Reverse Shoulder Press",
    is_free: true,
    primary_muscle: "Biceps, Front Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Brachialis, Forearm, Chest, Traps, Middle Shoulders, Serratus Anterior",
    subcategory_name: "total body",
    tertiary_muscle: "Rear Shoulders, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FStraight%20Curl%20Into%20Reverse%20Shoulder%20Press-0.jpg?alt=media&token=935b786d-ab3c-47e0-86e6-55081b7fe610",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FStraight%20Curl%20Into%20Reverse%20Shoulder%20Press.mp4?alt=media&token=1be08746-1dd2-4a1f-ac2b-72a96abb8d18",
    video_id: "TnS_Straight_Curl_Into_Reverse_Shoulder_Press_1",
    video_length: 0.07,
    video_number: "481",
    size: 1838999,
    hints: "TnS_Straight_Curl_Into_Reverse_Shoulder_Press_1_hints",
    common_mistakes: "TnS_Straight_Curl_Into_Reverse_Shoulder_Press_1_common_mistakes",
    breathing: "TnS_Straight_Curl_Into_Reverse_Shoulder_Press_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Wide Curl Into Shoulder Press",
    is_free: true,
    primary_muscle: "Biceps (Short Head), Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Biceps (Long Head), Brachialis, Forearm, Serratus Anterior",
    subcategory_name: "total body",
    tertiary_muscle: "Traps, Chest, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FWide%20Curl%20Into%20Shoulder%20Press-0.jpg?alt=media&token=e5f76f57-d9b5-4079-bd8d-82ab04880d47",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FWide%20Curl%20Into%20Shoulder%20Press.mp4?alt=media&token=dd34262c-9be0-465c-b03c-ffa2bccda5cd",
    video_id: "TnS_Wide_Curl_Into_Shoulder_Press_1",
    video_length: 0.07,
    video_number: "482",
    size: 2314991,
    hints: "TnS_Wide_Curl_Into_Shoulder_Press_1_hints",
    common_mistakes: "TnS_Wide_Curl_Into_Shoulder_Press_1_common_mistakes",
    breathing: "TnS_Wide_Curl_Into_Shoulder_Press_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Close Grip Hammer Curl Into Side Extension",
    is_free: false,
    primary_muscle: "Biceps, Brachialis, Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Forearm, Serratus Anterior",
    subcategory_name: "total body",
    tertiary_muscle: "Traps, Chest, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FWide%20Curl%20Into%20Shoulder%20Press-0.jpg?alt=media&token=e5f76f57-d9b5-4079-bd8d-82ab04880d47",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FClose%20Grip%20Hammer%20Curl%20Into%20Side%20Extension.mp4?alt=media&token=dff3936d-473b-4542-b0e2-b35854d00925",
    video_id: "TnS_Close_Grip_Hammer_Curl_Into_Side_Extension_1",
    video_length: 0.07,
    video_number: "483",
    size: 2288401,
    hints: "TnS_Close_Grip_Hammer_Curl_Into_Side_Extension_1_hints",
    common_mistakes: "TnS_Close_Grip_Hammer_Curl_Into_Side_Extension_1_common_mistakes",
    breathing: "TnS_Close_Grip_Hammer_Curl_Into_Side_Extension_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Close Grip Hammer Curl Into Single Front Extension",
    is_free: false,
    primary_muscle: "Biceps, Brachialis, Front Shoulder",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Forearm, Middle Shoulders, Serratus Anterior",
    subcategory_name: "total body",
    tertiary_muscle: "Rear Shoulders, Traps, Chest, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FClose%20Grip%20Hammer%20Curl%20Into%20Single%20Front%20Extension-0.jpg?alt=media&token=23d239df-140f-494c-a3c9-1b8263bd6653",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FClose%20Grip%20Hammer%20Curl%20Into%20Single%20Front%20Extension.mp4?alt=media&token=b590562c-3878-4bd8-826f-1103406686a6",
    video_id: "TnS_Close_Grip_Hammer_Curl_Into_Single_Front_Extension_1",
    video_length: 0.07,
    video_number: "484",
    size: 2243980,
    hints: "TnS_Close_Grip_Hammer_Curl_Into_Single_Front_Extension_1_hints",
    common_mistakes: "TnS_Close_Grip_Hammer_Curl_Into_Single_Front_Extension_1_common_mistakes",
    breathing: "TnS_Close_Grip_Hammer_Curl_Into_Single_Front_Extension_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Wide Upright Row Into Front Extension",
    is_free: false,
    primary_muscle: "Traps, Rhomboids, Front Shoulders, Middle Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Rear Shoulders, Teres Minor, Infraspinatus, Biceps, Upper Chest, Serratus Anterior",
    subcategory_name: "total body",
    tertiary_muscle: "Teres Major, Core",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FWide%20Upright%20Row%20Into%20Front%20Extension-0.jpg?alt=media&token=7ce1ab78-49e8-448a-bd48-5dcfdb1379ee",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FWide%20Upright%20Row%20Into%20Front%20Extension.mp4?alt=media&token=6e00c0b2-43d9-4323-a1d5-eaa9b3824301",
    video_id: "TnS_Wide_Upright_Row_Into_Front_Extension_1",
    video_length: 0.07,
    video_number: "485",
    size: 2139058,
    hints: "TnS_Wide_Upright_Row_Into_Front_Extension_1_hints",
    common_mistakes: "TnS_Wide_Upright_Row_Into_Front_Extension_1_common_mistakes",
    breathing: "TnS_Wide_Upright_Row_Into_Front_Extension_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Chest Fly Into Front Raise",
    is_free: false,
    primary_muscle: "Upper Chest, Middle Chest, Front Shoulders",
    rep_range: "5-10",
    secondary_muscle: "Serratus Anterior, Core, Middle Shoulders",
    subcategory_name: "total body",
    tertiary_muscle: "Rear Shoulders, Lower Chest, Traps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FChest%20Fly%20Into%20Front%20Raise-0.jpg?alt=media&token=3e7b4f3e-b1bb-4751-bf44-bd89e72eb09a",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FChest%20Fly%20Into%20Front%20Raise.mp4?alt=media&token=21192d28-f52a-4d48-b6cc-aa7bb40cc674",
    video_id: "TnS_Chest_Fly_Into_Front_Raise_1",
    video_length: 0.07,
    video_number: "486",
    size: 4100710,
    hints: "TnS_Chest_Fly_Into_Front_Raise_1_hints",
    common_mistakes: "TnS_Chest_Fly_Into_Front_Raise_1_common_mistakes",
    breathing: "TnS_Chest_Fly_Into_Front_Raise_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Triple Shoulder Press Into Triple Tricep Extension",
    is_free: false,
    primary_muscle: "Shoulders, Triceps",
    rep_range: "5-10",
    secondary_muscle: "Serratus Anterior, Forearm",
    subcategory_name: "total body",
    tertiary_muscle: "Traps, Chest, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FTriple%20Shoulder%20Press%20Into%20Triple%20Tricep%20Extension-0.jpg?alt=media&token=2ed5a40e-543e-4939-9ea0-6d4f9f723d0d",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FTriple%20Shoulder%20Press%20Into%20Triple%20Tricep%20Extension.mp4?alt=media&token=98876e06-74f9-4963-b276-335058e7b3f5",
    video_id: "TnS_Triple_Shoulder_Press_Into_Triple_Tricep_Extension_1",
    video_length: 0.07,
    video_number: "487",
    size: 4241147,
    hints: "TnS_Triple_Shoulder_Press_Into_Triple_Tricep_Extension_1_hints",
    common_mistakes: "TnS_Triple_Shoulder_Press_Into_Triple_Tricep_Extension_1_common_mistakes",
    breathing: "TnS_Triple_Shoulder_Press_Into_Triple_Tricep_Extension_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Seated Double Close Hammer Curl Into Double Tricep Extension",
    is_free: false,
    primary_muscle: "Biceps, Brachialis, Triceps",
    rep_range: "4-8",
    secondary_muscle: "Forearm, Shoulders, Traps, Rhomboids",
    subcategory_name: "total body",
    tertiary_muscle: "Erector Spinae, Teres, Major, Teres Minor, Infraspinatus, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSeated%20Double%20Close%20Hammer%20Curl%20Into%20Double%20Tricep%20Extension-0.jpg?alt=media&token=7bb96984-021c-4dad-b444-2589140641d4",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FSeated%20Double%20Close%20Hammer%20Curl%20Into%20Double%20Tricep%20Extension.mp4?alt=media&token=db1668bf-efc3-4bd4-8a55-6bf253faffbf",
    video_id: "TnS_Seated_Double_Close_Hammer_Curl_Into_Double_Tricep_Extension_1",
    video_length: 0.07,
    video_number: "488",
    size: 4492047,
    hints: "TnS_Seated_Double_Close_Hammer_Curl_Into_Double_Tricep_Extension_1_hints",
    common_mistakes: "TnS_Seated_Double_Close_Hammer_Curl_Into_Double_Tricep_Extension_1_common_mistakes",
    breathing: "TnS_Seated_Double_Close_Hammer_Curl_Into_Double_Tricep_Extension_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Close Grip Hammer Curl Into Front Extension",
    is_free: false,
    primary_muscle: "Biceps, Brachialis, Front Shoulder",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Forearm, Middle Shoulders, Serratus Anterior",
    subcategory_name: "total body",
    tertiary_muscle: "Rear Shoulders, Traps, Chest, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FClose%20Grip%20Hammer%20Curl%20Into%20Front%20Extension-0.jpg?alt=media&token=e8a99fa2-5793-4514-bc0a-60ea813df5b8",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FClose%20Grip%20Hammer%20Curl%20Into%20Front%20Extension.mp4?alt=media&token=bf5ec118-0d73-4ca8-a1ec-3f851a82f46c",
    video_id: "TnS_Close_Grip_Hammer_Curl_Into_Front_Extension_1",
    video_length: 0.07,
    video_number: "489",
    size: 1157727,
    hints: "TnS_Close_Grip_Hammer_Curl_Into_Front_Extension_1_hints",
    common_mistakes: "TnS_Close_Grip_Hammer_Curl_Into_Front_Extension_1_common_mistakes",
    breathing: "TnS_Close_Grip_Hammer_Curl_Into_Front_Extension_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Close Grip Hammer Curl Into Close Grip Shoulder Press",
    is_free: false,
    primary_muscle: "Biceps, Brachialis, Front Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Forearm, Chest, Traps, Middle Shoulders, Serratus Anterior",
    subcategory_name: "total body",
    tertiary_muscle: "Rear Shoulders, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FClose%20Grip%20Hammer%20Curl%20Into%20Close%20Grip%20Shoulder%20Press-0.jpg?alt=media&token=926005dc-78c2-40e8-95d8-652c62b29e84",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FClose%20Grip%20Hammer%20Curl%20Into%20Close%20Grip%20Shoulder%20Press.mp4?alt=media&token=f4846aa6-21e3-4e46-a7ec-a244cb25639e",
    video_id: "TnS_Close_Grip_Hammer_Curl_Into_Close_Grip_Shoulder_Press_1",
    video_length: 0.07,
    video_number: "490",
    size: 2474233,
    hints: "TnS_Close_Grip_Hammer_Curl_Into_Close_Grip_Shoulder_Press_1_hints",
    common_mistakes: "TnS_Close_Grip_Hammer_Curl_Into_Close_Grip_Shoulder_Press_1_common_mistakes",
    breathing: "TnS_Close_Grip_Hammer_Curl_Into_Close_Grip_Shoulder_Press_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Curl Into Chest Fly",
    is_free: false,
    primary_muscle: "Biceps, Upper Chest, Middle Chest, Front Shoulders",
    rep_range: "5-10",
    secondary_muscle: "Brachialis, Forearm, Serratus Anterior, Core, Middle Shoulders",
    subcategory_name: "total body",
    tertiary_muscle: "Rear Shoulders, Lower Chest",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FCurl%20Into%20Chest%20Fly-0.jpg?alt=media&token=4d82d709-20cb-4f25-afe3-6cd82d342f11",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FCurl%20Into%20Chest%20Fly.mp4?alt=media&token=c21abe25-f68b-4db9-8d04-4023e2d9c84c",
    video_id: "TnS_Curl_Into_Chest_Fly_1",
    video_length: 0.07,
    video_number: "491",
    size: 2503837,
    hints: "TnS_Curl_Into_Chest_Fly_1_hints",
    common_mistakes: "TnS_Curl_Into_Chest_Fly_1_common_mistakes",
    breathing: "TnS_Curl_Into_Chest_Fly_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Upright Row Into Squat",
    is_free: true,
    primary_muscle: "Traps, Rhomboids, Middle Shoulders, Quads, Glutes, Adductors",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Front Shoulders, Rear Shoulders, Teres Minor, Infraspinatus, Biceps, Hamstrings, Erector Spinae",
    subcategory_name: "total body",
    tertiary_muscle: "Teres Major, Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FUpright%20Row%20Into%20Squat-0.jpg?alt=media&token=4736bdd9-5ccf-4296-b429-544094e9f181",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FUpright%20Row%20Into%20Squat.mp4?alt=media&token=e81bfdd1-cd18-4511-9913-db71d07378f8",
    video_id: "TnS_Upright_Row_Into_Squat_1",
    video_length: 0.07,
    video_number: "492",
    size: 1806667,
    hints: "TnS_Upright_Row_Into_Squat_1_hints",
    common_mistakes: "TnS_Upright_Row_Into_Squat_1_common_mistakes",
    breathing: "TnS_Upright_Row_Into_Squat_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Double Hammer Curl Into Hammer Rear Delt Raise",
    is_free: true,
    primary_muscle: "Biceps, Brachialis, Rear Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Forearm, Middle Shoulders, Traps, Rhomboids, Infraspinatus, Teres Minor",
    subcategory_name: "total body",
    tertiary_muscle: "Front Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FDouble%20Hammer%20Curl%20Into%20Hammer%20Rear%20Delt%20Raise-0.jpg?alt=media&token=7bada210-d7f5-498f-923e-ad82d91c23e4",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FDouble%20Hammer%20Curl%20Into%20Hammer%20Rear%20Delt%20Raise.mp4?alt=media&token=15986097-6039-4737-aecf-6cc1d6e422d8",
    video_id: "TnS_Double_Hammer_Curl_Into_Hammer_Rear_Delt_Raise_1",
    video_length: 0.07,
    video_number: "493",
    size: 2633534,
    hints: "TnS_Double_Hammer_Curl_Into_Hammer_Rear_Delt_Raise_1_hints",
    common_mistakes: "TnS_Double_Hammer_Curl_Into_Hammer_Rear_Delt_Raise_1_common_mistakes",
    breathing: "TnS_Double_Hammer_Curl_Into_Hammer_Rear_Delt_Raise_1_breathing",
  },
  {
    category_id: 3,
    category_name: "forearms",
    name: "Side Wrist Curl",
    is_free: true,
    primary_muscle: "Forearm",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "",
    subcategory_name: "forearms",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSide%20Wrist%20Curl-0.jpg?alt=media&token=569ac63d-6d55-4986-9916-446cc19912cc",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FSide%20Wrist%20Curl.mp4?alt=media&token=d2bc32b3-96e0-4cc4-8a5d-df130789a7d8",
    video_id: "TnS_Side_Wrist_Curl_1",
    video_length: 0.07,
    video_number: "494",
    size: 717365,
    hints: "TnS_Side_Wrist_Curl_1_hints",
    common_mistakes: "TnS_Side_Wrist_Curl_1_common_mistakes",
    breathing: "TnS_Side_Wrist_Curl_1_breathing",
  },
  {
    category_id: 3,
    category_name: "forearms",
    name: "Front Wrist Curl",
    is_free: true,
    primary_muscle: "Forearm",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "",
    subcategory_name: "forearms",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FFront%20Wrist%20Curl-0.jpg?alt=media&token=33672c40-3ab9-4ae0-9e11-c45450cfee3a",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FFront%20Wrist%20Curl.mp4?alt=media&token=d31909db-72d4-4739-8234-75f615c4a17c",
    video_id: "TnS_Front_Wrist_Curl_1",
    video_length: 0.07,
    video_number: "495",
    size: 894721,
    hints: "TnS_Front_Wrist_Curl_1_hints",
    common_mistakes: "TnS_Front_Wrist_Curl_1_common_mistakes",
    breathing: "TnS_Front_Wrist_Curl_1_breathing",
  },
  {
    category_id: 3,
    category_name: "forearms",
    name: "Wide Wrist Curl",
    is_free: true,
    primary_muscle: "Forearm",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "",
    subcategory_name: "forearms",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FWide%20Wrist%20Curl-0.jpg?alt=media&token=18eef649-dc96-41a3-9079-81dfe371f858",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FWide%20Wrist%20Curl.mp4?alt=media&token=885cd8ce-d41e-4258-a611-24bdf8081fe8",
    video_id: "TnS_Wide_Wrist_Curl_1",
    video_length: 0.07,
    video_number: "496",
    size: 574298,
    hints: "TnS_Wide_Wrist_Curl_1_hints",
    common_mistakes: "TnS_Wide_Wrist_Curl_1_common_mistakes",
    breathing: "TnS_Wide_Wrist_Curl_1_breathing",
  },
  {
    category_id: 3,
    category_name: "forearms",
    name: "Rear Front Rotations",
    is_free: true,
    primary_muscle: "Forearm",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "",
    subcategory_name: "forearms",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FRear%20Front%20Rotations-0.jpg?alt=media&token=180926b4-7acc-459f-838a-cef36ee9394b",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FRear%20Front%20Rotations.mp4?alt=media&token=4a73071f-3629-40d0-9a0c-55c719e69bad",
    video_id: "TnS_Rear_Front_Rotations_1",
    video_length: 0.07,
    video_number: "497",
    size: 591669,
    hints: "TnS_Rear_Front_Rotations_1_hints",
    common_mistakes: "TnS_Rear_Front_Rotations_1_common_mistakes",
    breathing: "TnS_Rear_Front_Rotations_1_breathing",
  },
  {
    category_id: 3,
    category_name: "forearms",
    name: "Forward Reverse Wrist Curl",
    is_free: true,
    primary_muscle: "Forearm",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "",
    subcategory_name: "forearms",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FForward%20Reverse%20Wrist%20Curl-0.jpg?alt=media&token=c6241047-ce99-46b7-80a4-66fc35559a6a",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FForward%20Reverse%20Wrist%20Curl.mp4?alt=media&token=7eab774a-baa2-4d42-9b7e-323ef23d0d4c",
    video_id: "TnS_Forward_Reverse_Wrist_Curl_1",
    video_length: 0.07,
    video_number: "498",
    size: 544022,
    hints: "TnS_Forward_Reverse_Wrist_Curl_1_hints",
    common_mistakes: "TnS_Forward_Reverse_Wrist_Curl_1_common_mistakes",
    breathing: "TnS_Forward_Reverse_Wrist_Curl_1_breathing",
  },
  {
    category_id: 3,
    category_name: "forearms",
    name: "Behind Single Dumbbell Wrist Curl",
    is_free: true,
    primary_muscle: "Forearm",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "",
    subcategory_name: "forearms",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FBehind%20Single%20Dumbbell%20Wrist%20Curl-0.jpg?alt=media&token=79b1b3e9-d8c3-4118-ad6b-fba9f2a6c2f6",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FBehind%20Single%20Dumbbell%20Wrist%20Curl.mp4?alt=media&token=bcc17fe9-b47e-4a72-870a-3c845e317809",
    video_id: "TnS_Behind_Single_Dumbbell_Wrist_Curl_1",
    video_length: 0.07,
    video_number: "499",
    size: 702763,
    hints: "TnS_Behind_Single_Dumbbell_Wrist_Curl_1_hints",
    common_mistakes: "TnS_Behind_Single_Dumbbell_Wrist_Curl_1_common_mistakes",
    breathing: "TnS_Behind_Single_Dumbbell_Wrist_Curl_1_breathing",
  },
  {
    category_id: 3,
    category_name: "forearms",
    name: "Frontal Rotation",
    is_free: true,
    primary_muscle: "Forearm",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "",
    subcategory_name: "forearms",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FFrontal%20Rotation-0.jpg?alt=media&token=494d38cd-87e4-4012-9d49-47ccc4dbf56e",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FFrontal%20Rotation.mp4?alt=media&token=af805925-75b9-4592-8718-8f985ad5aa98",
    video_id: "TnS_Frontal_Rotation_1",
    video_length: 0.07,
    video_number: "500",
    size: 838941,
    hints: "TnS_Frontal_Rotation_1_hints",
    common_mistakes: "TnS_Frontal_Rotation_1_common_mistakes",
    breathing: "TnS_Frontal_Rotation_1_breathing",
  },
  {
    category_id: 3,
    category_name: "forearms",
    name: "Hammer Curl Holds",
    is_free: true,
    primary_muscle: "Forearm",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Biceps, Brachialis",
    subcategory_name: "forearms",
    tertiary_muscle: "Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FHammer%20Curl%20Holds-0.jpg?alt=media&token=7ab5c5a0-3d7e-4702-ab6a-ec5498d2869b",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FHammer%20Curl%20Holds.mp4?alt=media&token=b6962ce8-4925-488d-9e70-2fe476b65500",
    video_id: "TnS_Hammer_Curl_Holds_1",
    video_length: 0.07,
    video_number: "501",
    size: 1325616,
    hints: "TnS_Hammer_Curl_Holds_1_hints",
    common_mistakes: "TnS_Hammer_Curl_Holds_1_common_mistakes",
    breathing: "TnS_Hammer_Curl_Holds_1_breathing",
  },
  {
    category_id: 3,
    category_name: "forearms",
    name: "Seated Rear Ends",
    is_free: true,
    primary_muscle: "Forearm",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Front Shoulders, Middle Shoulders",
    subcategory_name: "forearms",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSeated%20Rear%20Ends-0.jpg?alt=media&token=f8e2466a-5bcb-4ecf-8b1e-13a120eee659",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "Chair Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FSeated%20Rear%20Ends.mp4?alt=media&token=f84b1b94-bff6-40a9-88c3-2f6585e39365",
    video_id: "TnS_Seated_Rear_Ends_1",
    video_length: 0.07,
    video_number: "502",
    size: 1076181,
    hints: "TnS_Seated_Rear_Ends_1_hints",
    common_mistakes: "TnS_Seated_Rear_Ends_1_common_mistakes",
    breathing: "TnS_Seated_Rear_Ends_1_breathing",
  },
  {
    category_id: 3,
    category_name: "forearms",
    name: "Rocker Rotations",
    is_free: true,
    primary_muscle: "Forearm",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "",
    subcategory_name: "forearms",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FRocker%20Rotations-0.jpg?alt=media&token=880fd5fe-1833-47ed-93cd-65a094114a42",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FRocker%20Rotations.mp4?alt=media&token=95270e04-d1e5-4215-b45b-30823f72da7a",
    video_id: "TnS_Rocker_Rotations_1",
    video_length: 0.07,
    video_number: "503",
    size: 690320,
    hints: "TnS_Rocker_Rotations_1_hints",
    common_mistakes: "TnS_Rocker_Rotations_1_common_mistakes",
    breathing: "TnS_Rocker_Rotations_1_breathing",
  },
  {
    category_id: 3,
    category_name: "forearms",
    name: "Inner Wrist Curls",
    is_free: true,
    primary_muscle: "Forearm",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Middle Shoulders",
    subcategory_name: "forearms",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FInner%20Wrist%20Curls-0.jpg?alt=media&token=c33a81e2-6bef-46d6-9a54-a439426fc612",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FInner%20Wrist%20Curls.mp4?alt=media&token=c4016081-a986-4c41-97e0-3584ca03ee49",
    video_id: "TnS_Inner_Wrist_Curls_1",
    video_length: 0.07,
    video_number: "504",
    size: 854625,
    hints: "TnS_Inner_Wrist_Curls_1_hints",
    common_mistakes: "TnS_Inner_Wrist_Curls_1_common_mistakes",
    breathing: "TnS_Inner_Wrist_Curls_1_breathing",
  },
  {
    category_id: 3,
    category_name: "forearms",
    name: "Reverse Wrist Curl",
    is_free: true,
    primary_muscle: "Forearm",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "",
    subcategory_name: "forearms",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FReverse%20Wrist%20Curl-0.jpg?alt=media&token=7214c526-3a8d-44cc-9bb1-aeeb4ae6697d",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FReverse%20Wrist%20Curl.mp4?alt=media&token=2069b659-a8d7-49d9-bd03-fe3106620d69",
    video_id: "TnS_Reverse_Wrist_Curl_1",
    video_length: 0.07,
    video_number: "505",
    size: 831282,
    hints: "TnS_Reverse_Wrist_Curl_1_hints",
    common_mistakes: "TnS_Reverse_Wrist_Curl_1_common_mistakes",
    breathing: "TnS_Reverse_Wrist_Curl_1_breathing",
  },
  {
    category_id: 3,
    category_name: "total body",
    name: "Goblet Grip Squat",
    is_free: false,
    primary_muscle: "Quads, Glutes",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Hamstrings, Adductors, Shoulders, Teres Major, Teres Minor, Rhomboids",
    subcategory_name: "total body",
    tertiary_muscle: "Core, Calves, Infraspinatus, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FGoblet%20Grip%20Squat-0.jpg?alt=media&token=e8ca2660-68dc-4721-a0d3-7b62f8020eba",
    time: "45 seconds",
    time_to_play: 0.75,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FGoblet%20Grip%20Squat.mp4?alt=media&token=94a01dc1-1c2d-4f36-9af4-bbad2b37d592",
    video_id: "TnS_Goblet_Grip_Squat_1",
    video_length: 0.07,
    video_number: "506",
    size: 882197,
    hints: "TnS_Goblet_Grip_Squat_1_hints",
    common_mistakes: "TnS_Goblet_Grip_Squat_1_common_mistakes",
    breathing: "TnS_Goblet_Grip_Squat_1_breathing",
  },
  {
    category_id: 6,
    category_name: "cardio",
    name: "Front Kick",
    is_other_side: 0,
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Core",
    subcategory_name: "cardio",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(L)%20Front%20Kick-0.jpg?alt=media&token=144e7838-105d-46c1-b8e1-8643b96baffa",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fregular-cardio%2F(L)%20Front%20Kick.mp4?alt=media&token=d5a7f1dd-59b9-452d-a156-ee5ebee1d3b3",
    video_id: "C_Front_Kick_1",
    video_length: 0.07,
    video_number: "508",
    size: 1709183,
    hints: "C_Front_Kick_1_hints",
    common_mistakes: "C_Front_Kick_1_common_mistakes",
    breathing: "C_Front_Kick_1_breathing",
  },
  {
    category_id: 6,
    category_name: "cardio",
    name: "Front Kick",
    is_other_side: 1,
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Core",
    subcategory_name: "cardio",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(R)%20Front%20Kick-0.jpg?alt=media&token=d485fae2-a051-4c9c-9abd-d038781532b5",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fregular-cardio%2F(R)%20Front%20Kick.mp4?alt=media&token=ed26848e-4f95-4ca5-b301-ba2062bc38ca",
    video_id: "C_Front_Kick_2",
    video_length: 0.07,
    video_number: "509",
    size: 1742957,
    hints: "C_Front_Kick_2_hints",
    common_mistakes: "C_Front_Kick_2_common_mistakes",
    breathing: "C_Front_Kick_2_breathing",
  },
  {
    category_id: 6,
    category_name: "cardio",
    name: "One Leg Side Hops",
    is_free: true,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Core, Shoulders",
    subcategory_name: "cardio",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FOne%20Leg%20Side%20Hops-0.jpg?alt=media&token=f4a515b2-4e0e-4669-9fbc-3e5c58c07435",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fregular-cardio%2FOne%20Leg%20Side%20Hops.mp4?alt=media&token=c268ca96-cc2c-4faa-929c-7a3c527eef02",
    video_id: "C_One_Leg_Side_Hops_1",
    video_length: 0.07,
    video_number: "510",
    size: 2787320,
    hints: "C_One_Leg_Side_Hops_1_hints",
    common_mistakes: "C_One_Leg_Side_Hops_1_common_mistakes",
    breathing: "C_One_Leg_Side_Hops_1_breathing",
  },
  {
    category_id: 6,
    category_name: "cardio",
    name: "Ankle Taps Into Explosive Jumps",
    is_free: true,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Core, Shoulders",
    subcategory_name: "cardio",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FAnkle%20Taps%20Into%20Explosive%20Jumps-0.jpg?alt=media&token=6d5870aa-fcb4-4c99-bb65-b662584b11d5",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fregular-cardio%2FAnkle%20Taps%20Into%20Explosive%20Jumps.mp4?alt=media&token=93969863-872a-4c4a-aa75-0630e8175e6e",
    video_id: "C_Ankle_Taps_Into_Explosive_Jumps_1",
    video_length: 0.07,
    video_number: "511",
    size: 2649855,
    hints: "C_Ankle_Taps_Into_Explosive_Jumps_1_hints",
    common_mistakes: "C_Ankle_Taps_Into_Explosive_Jumps_1_common_mistakes",
    breathing: "C_Ankle_Taps_Into_Explosive_Jumps_1_breathing",
  },
  {
    category_id: 6,
    category_name: "cardio",
    name: "Beginner Burpees",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Chest, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "cardio",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FBeginner%20Burpees-0.jpg?alt=media&token=5b88e3a1-dd1f-412a-aab6-c29de1d36409",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fregular-cardio%2FBeginner%20Burpees.mp4?alt=media&token=c708f9aa-0981-4f84-bd2a-a65f199d3ba5",
    video_id: "C_Beginner_Burpees_1",
    video_length: 0.07,
    video_number: "512",
    size: 2280691,
    hints: "C_Beginner_Burpees_1_hints",
    common_mistakes: "C_Beginner_Burpees_1_common_mistakes",
    breathing: "C_Beginner_Burpees_1_breathing",
  },
  {
    category_id: 6,
    category_name: "cardio",
    name: "Crossing Punch",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "cardio",
    tertiary_muscle: "Chest",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FCrossing%20Punch-0.jpg?alt=media&token=41874cba-650b-4ada-85d7-03d6f10441fa",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fregular-cardio%2FCrossing%20Punch.mp4?alt=media&token=084467ac-abce-4d26-8ef9-3d1abef7c702",
    video_id: "C_Crossing_Punch_1",
    video_length: 0.07,
    video_number: "513",
    size: 2261815,
    hints: "C_Crossing_Punch_1_hints",
    common_mistakes: "C_Crossing_Punch_1_common_mistakes",
    breathing: "C_Crossing_Punch_1_breathing",
  },
  {
    category_id: 6,
    category_name: "cardio",
    name: "Hop Scotch",
    is_free: true,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Core",
    subcategory_name: "cardio",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FHop%20Scotch-0.jpg?alt=media&token=1b917345-94f6-49d8-bcb6-621db8355772",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fregular-cardio%2FHop%20Scotch.mp4?alt=media&token=b6266a6c-24ea-4caa-85a2-ebbd780d7eb3",
    video_id: "C_Hop_Scotch_1",
    video_length: 0.07,
    video_number: "514",
    size: 3978464,
    hints: "C_Hop_Scotch_1_hints",
    common_mistakes: "C_Hop_Scotch_1_common_mistakes",
    breathing: "C_Hop_Scotch_1_breathing",
  },
  {
    category_id: 6,
    category_name: "cardio",
    name: "Knee Down Lunge Runner",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "cardio",
    tertiary_muscle: "Chest",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FKnee%20Down%20Lunge%20Runner-0.jpg?alt=media&token=cef2b0da-a988-416c-b200-1460be494f49",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fregular-cardio%2FKnee%20Down%20Lunge%20Runner.mp4?alt=media&token=71e040c5-3ed4-4941-b823-94f7ed54eb1c",
    video_id: "C_Knee_Down_Lunge_Runner_1",
    video_length: 0.07,
    video_number: "515",
    size: 1425702,
    hints: "C_Knee_Down_Lunge_Runner_1_hints",
    common_mistakes: "C_Knee_Down_Lunge_Runner_1_common_mistakes",
    breathing: "C_Knee_Down_Lunge_Runner_1_breathing",
  },
  {
    category_id: 6,
    category_name: "cardio",
    name: "Knees",
    is_free: true,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "cardio",
    tertiary_muscle: "Chest",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FKnees-0.jpg?alt=media&token=ba18630c-3c35-4aee-affb-6e1d1b385316",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fregular-cardio%2FKnees.mp4?alt=media&token=091b49a6-6b4e-49e3-ba68-aa1facb0cc93",
    video_id: "C_Knees_1",
    video_length: 0.07,
    video_number: "516",
    size: 5099132,
    hints: "C_Knees_1_hints",
    common_mistakes: "C_Knees_1_common_mistakes",
    breathing: "C_Knees_1_breathing",
  },
  {
    category_id: 6,
    category_name: "cardio",
    name: "Low Heel Tap",
    is_free: true,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Core",
    subcategory_name: "cardio",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FLow%20Heel%20Tap-0.jpg?alt=media&token=b1814385-7cea-42d5-9116-5126625d1f9e",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fregular-cardio%2FLow%20Heel%20Tap.mp4?alt=media&token=ff669dd9-0184-4f8a-bf97-257d2793b00f",
    video_id: "C_Low_Heel_Tap_1",
    video_length: 0.07,
    video_number: "517",
    size: 2284077,
    hints: "C_Low_Heel_Tap_1_hints",
    common_mistakes: "C_Low_Heel_Tap_1_common_mistakes",
    breathing: "C_Low_Heel_Tap_1_breathing",
  },
  {
    category_id: 6,
    category_name: "cardio",
    name: "Low Runner",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "cardio",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FLow%20Runner-0.jpg?alt=media&token=4d78cb75-3d84-4f75-a064-d14d7996a1bf",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fregular-cardio%2FLow%20Runner.mp4?alt=media&token=22dbf8fb-02b6-4807-bc0f-7ef3c4e73645",
    video_id: "C_Low_Runner_1",
    video_length: 0.07,
    video_number: "518",
    size: 1426200,
    hints: "C_Low_Runner_1_hints",
    common_mistakes: "C_Low_Runner_1_common_mistakes",
    breathing: "C_Low_Runner_1_breathing",
  },
  {
    category_id: 6,
    category_name: "cardio",
    name: "March & Clap",
    is_free: true,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "cardio",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FMarch%20%26%20Clap-0.jpg?alt=media&token=6747f0a1-dd1f-432c-b31b-29673564521e",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fregular-cardio%2FMarch%20%26%20Clap.mp4?alt=media&token=18943e19-6a4d-4726-a1d3-15c971aebf37",
    video_id: "C_March_Clap_1",
    video_length: 0.07,
    video_number: "519",
    size: 2618515,
    hints: "C_March_Clap_1_hints",
    common_mistakes: "C_March_Clap_1_common_mistakes",
    breathing: "C_March_Clap_1_breathing",
  },
  {
    category_id: 6,
    category_name: "cardio",
    name: "March In Place",
    is_free: true,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Core",
    subcategory_name: "cardio",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FMarch%20In%20Place-0.jpg?alt=media&token=34b903d0-cf53-4150-a8e0-76c500ad0436",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fregular-cardio%2FMarch%20In%20Place.mp4?alt=media&token=642673ab-317d-4d99-87fc-08cdcc4504e8",
    video_id: "C_March_In_Place_1",
    video_length: 0.07,
    video_number: "520",
    size: 2218248,
    hints: "C_March_In_Place_1_hints",
    common_mistakes: "C_March_In_Place_1_common_mistakes",
    breathing: "C_March_In_Place_1_breathing",
  },
  {
    category_id: 6,
    category_name: "cardio",
    name: "Modified Jumping Jack",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "cardio",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FModified%20Jumping%20Jack-0.jpg?alt=media&token=7a41d006-79df-49c8-811b-51805ca5acf3",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fregular-cardio%2FModified%20Jumping%20Jack.mp4?alt=media&token=a5de98a7-75d6-443f-8af5-687fc73fc2fe",
    video_id: "C_Modified_Jumping_Jack_1",
    video_length: 0.07,
    video_number: "521",
    size: 2668775,
    hints: "C_Modified_Jumping_Jack_1_hints",
    common_mistakes: "C_Modified_Jumping_Jack_1_common_mistakes",
    breathing: "C_Modified_Jumping_Jack_1_breathing",
  },
  {
    category_id: 6,
    category_name: "cardio",
    name: "Pivoting Upper Cuts",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "cardio",
    tertiary_muscle: "Chest",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FPivoting%20Upper%20Cuts-0.jpg?alt=media&token=47041990-1477-4cb3-bca0-2f157e4d6ffe",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fregular-cardio%2FPivoting%20Upper%20Cuts.mp4?alt=media&token=db52e572-de73-4dfa-a841-1404735bd2a9",
    video_id: "C_Pivoting_Upper_Cuts_1",
    video_length: 0.07,
    video_number: "522",
    size: 1848317,
    hints: "C_Pivoting_Upper_Cuts_1_hints",
    common_mistakes: "C_Pivoting_Upper_Cuts_1_common_mistakes",
    breathing: "C_Pivoting_Upper_Cuts_1_breathing",
  },
  {
    category_id: 6,
    category_name: "cardio",
    name: "Punches",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "cardio",
    tertiary_muscle: "Chest",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FPunches-0.jpg?alt=media&token=6f7caf28-546e-41a0-ba1f-4363b408c0be",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fregular-cardio%2FPunches.mp4?alt=media&token=60ad385a-d3d7-42b9-a01a-3823c126821f",
    video_id: "C_Punches_1",
    video_length: 0.07,
    video_number: "523",
    size: 1297560,
    hints: "C_Punches_1_hints",
    common_mistakes: "C_Punches_1_common_mistakes",
    breathing: "C_Punches_1_breathing",
  },
  {
    category_id: 6,
    category_name: "cardio",
    name: "Rotational Chop",
    is_free: true,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "cardio",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FRotational%20Chop-0.jpg?alt=media&token=742a4bfa-da65-4e8e-b278-4b413c1a1fd8",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fregular-cardio%2FRotational%20Chop.mp4?alt=media&token=4b897e68-4bc2-4abe-bfb8-34f9bd602539",
    video_id: "C_Rotational_Chop_1",
    video_length: 0.07,
    video_number: "524",
    size: 2946465,
    hints: "C_Rotational_Chop_1_hints",
    common_mistakes: "C_Rotational_Chop_1_common_mistakes",
    breathing: "C_Rotational_Chop_1_breathing",
  },
  {
    category_id: 6,
    category_name: "cardio",
    name: "Shuffle",
    is_free: true,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Core",
    subcategory_name: "cardio",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FShuffle-0.jpg?alt=media&token=068e9aec-d618-4e60-89ec-ac78a86dd712",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fregular-cardio%2FShuffle.mp4?alt=media&token=7dc80834-4459-49f3-958f-2be29309132d",
    video_id: "C_Shuffle_1",
    video_length: 0.07,
    video_number: "525",
    size: 1466764,
    hints: "C_Shuffle_1_hints",
    common_mistakes: "C_Shuffle_1_common_mistakes",
    breathing: "C_Shuffle_1_breathing",
  },
  {
    category_id: 6,
    category_name: "cardio",
    name: "Soccer Ball Juggle",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Core, Shoulders",
    subcategory_name: "cardio",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSoccer%20Ball%20Juggle-0.jpg?alt=media&token=b9d01545-4a4e-4ed5-a314-420d39e4b041",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fregular-cardio%2FSoccer%20Ball%20Juggle.mp4?alt=media&token=f2a27bc5-0b11-4faf-baeb-4ee9da6b362d",
    video_id: "C_Soccer_Ball_Juggle_1",
    video_length: 0.07,
    video_number: "526",
    size: 3051760,
    hints: "C_Soccer_Ball_Juggle_1_hints",
    common_mistakes: "C_Soccer_Ball_Juggle_1_common_mistakes",
    breathing: "C_Soccer_Ball_Juggle_1_breathing",
  },
  {
    category_id: 6,
    category_name: "cardio",
    name: "Squat Shuffle",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Core, Shoulders",
    subcategory_name: "cardio",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSquat%20Shuffle-0.jpg?alt=media&token=48d48adf-0b2f-43fb-b2af-0d9eab8deec0",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fregular-cardio%2FSquat%20Shuffle.mp4?alt=media&token=172429d6-8e10-4a58-8ce2-6c3b77ed255e",
    video_id: "C_Squat_Shuffle_1",
    video_length: 0.07,
    video_number: "527",
    size: 2017306,
    hints: "C_Squat_Shuffle_1_hints",
    common_mistakes: "C_Squat_Shuffle_1_common_mistakes",
    breathing: "C_Squat_Shuffle_1_breathing",
  },
  {
    category_id: 6,
    category_name: "cardio",
    name: "Squat Sprinters",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "cardio",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSquat%20Sprinters-0.jpg?alt=media&token=44f5a296-fd96-4bb8-b6e1-8f1fab7eca20",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fregular-cardio%2FSquat%20Sprinters.mp4?alt=media&token=c19643b8-49b0-4ac0-8958-798f02e6c1df",
    video_id: "C_Squat_Sprinters_1",
    video_length: 0.07,
    video_number: "528",
    size: 1636145,
    hints: "C_Squat_Sprinters_1_hints",
    common_mistakes: "C_Squat_Sprinters_1_common_mistakes",
    breathing: "C_Squat_Sprinters_1_breathing",
  },
  {
    category_id: 6,
    category_name: "cardio",
    name: "Standing Ab Twist",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "cardio",
    tertiary_muscle: "Chest",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FStanding%20Ab%20Twist-0.jpg?alt=media&token=e3841f3a-6161-4f87-9f9c-5057ac8f921e",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fregular-cardio%2FStanding%20Ab%20Twist.mp4?alt=media&token=bb911971-5111-4453-ae78-52f16cd65821",
    video_id: "C_Standing_Ab_Twist_1",
    video_length: 0.07,
    video_number: "529",
    size: 3055022,
    hints: "C_Standing_Ab_Twist_1_hints",
    common_mistakes: "C_Standing_Ab_Twist_1_common_mistakes",
    breathing: "C_Standing_Ab_Twist_1_breathing",
  },
  {
    category_id: 6,
    category_name: "cardio",
    name: "Standing Side Crunch",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "cardio",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FStanding%20Side%20Crunch-0.jpg?alt=media&token=31ea6d1b-9087-4fe4-9e5a-8f12a99d587e",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fregular-cardio%2FStanding%20Side%20Crunch.mp4?alt=media&token=419c638e-e761-4446-a787-2734970f5484",
    video_id: "C_Standing_Side_Crunch_1",
    video_length: 0.07,
    video_number: "530",
    size: 2438170,
    hints: "C_Standing_Side_Crunch_1_hints",
    common_mistakes: "C_Standing_Side_Crunch_1_common_mistakes",
    breathing: "C_Standing_Side_Crunch_1_breathing",
  },
  {
    category_id: 6,
    category_name: "cardio",
    name: "Step down X Vert Jump",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "cardio",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FStep%20down%20X%20Vert%20Jump-0.jpg?alt=media&token=bbe8b75e-ca3d-43a7-82c0-8f69f7943594",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fregular-cardio%2FStep%20down%20X%20Vert%20Jump.mp4?alt=media&token=254f309d-8992-4d31-b460-2405741d194d",
    video_id: "C_Step_down_X_Vert_Jump_1",
    video_length: 0.07,
    video_number: "531",
    size: 1974904,
    hints: "C_Step_down_X_Vert_Jump_1_hints",
    common_mistakes: "C_Step_down_X_Vert_Jump_1_common_mistakes",
    breathing: "C_Step_down_X_Vert_Jump_1_breathing",
  },
  {
    category_id: 6,
    category_name: "cardio",
    name: "Toe Slaps",
    is_free: true,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "cardio",
    tertiary_muscle: "Chest",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FToe%20Slaps-0.jpg?alt=media&token=d0ac52e7-663d-455f-9b10-2a037a6386d3",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fregular-cardio%2FToe%20Slaps.mp4?alt=media&token=1c0a9bdf-5978-4b9f-94e8-362f981620b8",
    video_id: "C_Toe_Slaps_1",
    video_length: 0.07,
    video_number: "532",
    size: 2036478,
    hints: "C_Toe_Slaps_1_hints",
    common_mistakes: "C_Toe_Slaps_1_common_mistakes",
    breathing: "C_Toe_Slaps_1_breathing",
  },
  {
    category_id: 6,
    category_name: "cardio",
    name: "Walking High Knees",
    is_free: true,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "cardio",
    tertiary_muscle: "Chest",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FWalking%20High%20Knees-0.jpg?alt=media&token=5cdc7c09-97b4-4224-8e49-417993424258",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fregular-cardio%2FWalking%20High%20Knees.mp4?alt=media&token=6f65f458-889f-4143-a2f7-dc1fa964baee",
    video_id: "C_Walking_High_Knees_1",
    video_length: 0.07,
    video_number: "533",
    size: 3290894,
    hints: "C_Walking_High_Knees_1_hints",
    common_mistakes: "C_Walking_High_Knees_1_common_mistakes",
    breathing: "C_Walking_High_Knees_1_breathing",
  },
  {
    category_id: 6,
    category_name: "cardio",
    name: "Woodchopper",
    is_free: true,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "cardio",
    tertiary_muscle: "Chest",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FWoodchopper-0.jpg?alt=media&token=2497f6d1-fd72-4ed2-9ae5-340ffcb35b5a",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fregular-cardio%2FWoodchopper.mp4?alt=media&token=e28226f6-d1b3-4d24-b4b3-88caa07b99d1",
    video_id: "C_Woodchopper_1",
    video_length: 0.07,
    video_number: "534",
    size: 2358802,
    hints: "C_Woodchopper_1_hints",
    common_mistakes: "C_Woodchopper_1_common_mistakes",
    breathing: "C_Woodchopper_1_breathing",
  },
  {
    category_id: 6,
    category_name: "cardio",
    name: "Alternating Opposite Toe Tap",
    is_free: true,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Core, Shoulders",
    subcategory_name: "cardio",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FAlternating%20Opposite%20Toe%20Tap-0.jpg?alt=media&token=77911438-dc3d-4ace-833d-43a0e8a0536d",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fregular-cardio%2FAlternating%20Opposite%20Toe%20Tap.mp4?alt=media&token=7cca6dd4-9f4c-4187-9933-2ce5aff742d6",
    video_id: "C_Alternating_Opposite_Toe_Tap_1",
    video_length: 0.07,
    video_number: "534",
    size: 1166158,
    hints: "C_Alternating_Opposite_Toe_Tap_1_hints",
    common_mistakes: "C_Alternating_Opposite_Toe_Tap_1_common_mistakes",
    breathing: "C_Alternating_Opposite_Toe_Tap_1_breathing",
  },
  {
    category_id: 6,
    category_name: "cardio",
    name: "In Toe Tap",
    is_free: true,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Core",
    subcategory_name: "cardio",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FIn%20Toe%20Tap-0.jpg?alt=media&token=698b6b1a-fbd6-4ee4-af4e-7dbc3c8e0134",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fregular-cardio%2FIn%20Toe%20Tap.mp4?alt=media&token=e570a990-1355-44f6-8db8-3408a13fe33c",
    video_id: "C_In_Toe_Tap_1",
    video_length: 0.07,
    video_number: "535",
    size: 2881850,
    hints: "C_In_Toe_Tap_1_hints",
    common_mistakes: "C_In_Toe_Tap_1_common_mistakes",
    breathing: "C_In_Toe_Tap_1_breathing",
  },
  {
    category_id: 7,
    category_name: "hiit",
    name: "180 Jump",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Core, Shoulders",
    subcategory_name: "hiit",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F180%20Jump-0.jpg?alt=media&token=e3bae770-464d-4d35-bf58-66ab6c1de224",
    time: "20 seconds",
    time_to_play: 0.3333333333333333,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fhiit%2F180%20Jump.mp4?alt=media&token=a36b42e7-d775-4979-b72f-a4687ccb31fd",
    video_id: "h_180_Jump_1",
    video_length: 0.07,
    video_number: "536",
    size: 1318784,
    hints: "h_180_Jump_1_hints",
    common_mistakes: "h_180_Jump_1_common_mistakes",
    breathing: "h_180_Jump_1_breathing",
  },
  {
    category_id: 7,
    category_name: "hiit",
    name: "Alternating Knee Tap",
    is_free: true,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "hiit",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FAlternating%20Knee%20Tap-0.jpg?alt=media&token=53b7ae0c-15bd-4de5-8159-09a9d91d1a8b",
    time: "20 seconds",
    time_to_play: 0.3333333333333333,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fhiit%2FAlternating%20Knee%20Tap.mp4?alt=media&token=07ca2701-8b18-4b6b-ac19-36a5cb007242",
    video_id: "h_Alternating_Knee_Tap_1",
    video_length: 0.07,
    video_number: "537",
    size: 1906760,
    hints: "h_Alternating_Knee_Tap_1_hints",
    common_mistakes: "h_Alternating_Knee_Tap_1_common_mistakes",
    breathing: "h_Alternating_Knee_Tap_1_breathing",
  },
  {
    category_id: 7,
    category_name: "hiit",
    name: "Alternating Knee To Elbow",
    is_free: true,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "hiit",
    tertiary_muscle: "Chest",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FAlternating%20Knee%20To%20Elbow-0.jpg?alt=media&token=91607a40-a9ee-483c-94dc-5760cadf14d7",
    time: "20 seconds",
    time_to_play: 0.3333333333333333,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fhiit%2FAlternating%20Knee%20To%20Elbow.mp4?alt=media&token=90a4b138-48a8-47c2-aff7-479ab738f24b",
    video_id: "h_Alternating_Knee_To_Elbow_1",
    video_length: 0.07,
    video_number: "538",
    size: 3099447,
    hints: "h_Alternating_Knee_To_Elbow_1_hints",
    common_mistakes: "h_Alternating_Knee_To_Elbow_1_common_mistakes",
    breathing: "h_Alternating_Knee_To_Elbow_1_breathing",
  },
  {
    category_id: 7,
    category_name: "hiit",
    name: "Alternating Toe Touch & Hop",
    is_free: true,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "hiit",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FAlternating%20Toe%20Touch%20%26%20Hop-0.jpg?alt=media&token=d093448e-c208-42e5-85f8-84100ef38b0d",
    time: "20 seconds",
    time_to_play: 0.3333333333333333,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fhiit%2FAlternating%20Toe%20Touch%20%26%20Hop.mp4?alt=media&token=16b4907f-e541-49a1-97e1-b46d35ff9092",
    video_id: "h_Alternating_Toe_Touch_Hop_1",
    video_length: 0.07,
    video_number: "539",
    size: 1450792,
    hints: "h_Alternating_Toe_Touch_Hop_1_hints",
    common_mistakes: "h_Alternating_Toe_Touch_Hop_1_common_mistakes",
    breathing: "h_Alternating_Toe_Touch_Hop_1_breathing",
  },
  {
    category_id: 7,
    category_name: "hiit",
    name: "Alternating Halo Slam",
    is_free: true,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Chest, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "hiit",
    tertiary_muscle: "Chest",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FAlternating%20Halo%20Slam-0.jpg?alt=media&token=a5d9e8d4-f93c-44b5-a35c-c0805ca1f9bf",
    time: "20 seconds",
    time_to_play: 0.3333333333333333,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fhiit%2FAlternating%20Halo%20Slam.mp4?alt=media&token=91af26d1-8c6e-4fe0-ab30-e4e257d8f519",
    video_id: "h_Alternating_Halo_Slam_1",
    video_length: 0.07,
    video_number: "540",
    size: 2996975,
    hints: "h_Alternating_Halo_Slam_1_hints",
    common_mistakes: "h_Alternating_Halo_Slam_1_common_mistakes",
    breathing: "h_Alternating_Halo_Slam_1_breathing",
  },
  {
    category_id: 7,
    category_name: "hiit",
    name: "Broad Jump & Back Pedal",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "hiit",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FBroad%20Jump%20%26%20Back%20Pedal-0.jpg?alt=media&token=a861e1c3-4022-433b-98da-4773808a7680",
    time: "20 seconds",
    time_to_play: 0.3333333333333333,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fhiit%2FBroad%20Jump%20%26%20Back%20Pedal.mp4?alt=media&token=9020d9b9-8114-4c96-a02a-2cf7c2c50286",
    video_id: "h_Broad_Jump_Back_Pedal_1",
    video_length: 0.07,
    video_number: "541",
    size: 1548611,
    hints: "h_Broad_Jump_Back_Pedal_1_hints",
    common_mistakes: "h_Broad_Jump_Back_Pedal_1_common_mistakes",
    breathing: "h_Broad_Jump_Back_Pedal_1_breathing",
  },
  {
    category_id: 7,
    category_name: "hiit",
    name: "Clap Jacks",
    is_free: true,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "hiit",
    tertiary_muscle: "Chest",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FClap%20Jacks-0.jpg?alt=media&token=d97ec9a0-70f4-4dc1-84ee-bbd1f241a4b5",
    time: "20 seconds",
    time_to_play: 0.3333333333333333,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fhiit%2FClap%20Jacks.mp4?alt=media&token=c9214251-7b5a-40e6-bf85-b9652418b306",
    video_id: "h_Clap_Jacks_1",
    video_length: 0.07,
    video_number: "542",
    size: 1864035,
    hints: "h_Clap_Jacks_1_hints",
    common_mistakes: "h_Clap_Jacks_1_common_mistakes",
    breathing: "h_Clap_Jacks_1_breathing",
  },
  {
    category_id: 7,
    category_name: "hiit",
    name: "Cross Body Jack",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "hiit",
    tertiary_muscle: "Chest",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FCross%20Body%20Jack-0.jpg?alt=media&token=33cbb19c-1ce4-4434-89b4-7a3130225ad4",
    time: "20 seconds",
    time_to_play: 0.3333333333333333,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fhiit%2FCross%20Body%20Jack.mp4?alt=media&token=3c5d3bdb-bcdc-4ab4-a5b0-a9b92f704c5e",
    video_id: "h_Cross_Body_Jack_1",
    video_length: 0.07,
    video_number: "543",
    size: 2111344,
    hints: "h_Cross_Body_Jack_1_hints",
    common_mistakes: "h_Cross_Body_Jack_1_common_mistakes",
    breathing: "h_Cross_Body_Jack_1_breathing",
  },
  {
    category_id: 7,
    category_name: "hiit",
    name: "Cross Country Run",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "hiit",
    tertiary_muscle: "Chest",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FCross%20Country%20Run-0.jpg?alt=media&token=5f268474-0162-440b-9800-73a1b156c1b8",
    time: "20 seconds",
    time_to_play: 0.3333333333333333,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fhiit%2FCross%20Country%20Run.mp4?alt=media&token=6c7382e5-ba50-400d-87c5-e65c44a6a51f",
    video_id: "h_Cross_Country_Run_1",
    video_length: 0.07,
    video_number: "544",
    size: 2717287,
    hints: "h_Cross_Country_Run_1_hints",
    common_mistakes: "h_Cross_Country_Run_1_common_mistakes",
    breathing: "h_Cross_Country_Run_1_breathing",
  },
  {
    category_id: 7,
    category_name: "hiit",
    name: "Front To Back Hop",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "hiit",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FFront%20To%20Back%20Hop-0.jpg?alt=media&token=d694c7d2-e477-4b21-a1ef-82700d44fafb",
    time: "20 seconds",
    time_to_play: 0.3333333333333333,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fhiit%2FFront%20To%20Back%20Hop.mp4?alt=media&token=d93edaa1-0e4a-41e5-b82c-19772b7d6a78",
    video_id: "h_Front_To_Back_Hop_1",
    video_length: 0.07,
    video_number: "545",
    size: 2141031,
    hints: "h_Front_To_Back_Hop_1_hints",
    common_mistakes: "h_Front_To_Back_Hop_1_common_mistakes",
    breathing: "h_Front_To_Back_Hop_1_breathing",
  },
  {
    category_id: 7,
    category_name: "hiit",
    name: "High Heel",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "hiit",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FHigh%20Heel-0.jpg?alt=media&token=410cbbd0-1d07-4a69-b1b1-2fd701a5acc3",
    time: "20 seconds",
    time_to_play: 0.3333333333333333,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fhiit%2FHigh%20Heel.mp4?alt=media&token=370926a4-fab3-4c54-8c60-735c418f2c09",
    video_id: "h_High_Heel_1",
    video_length: 0.07,
    video_number: "546",
    size: 2208924,
    hints: "h_High_Heel_1_hints",
    common_mistakes: "h_High_Heel_1_common_mistakes",
    breathing: "h_High_Heel_1_breathing",
  },
  {
    category_id: 7,
    category_name: "hiit",
    name: "High Jumper",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "hiit",
    tertiary_muscle: "Chest",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FHigh%20Jumper-0.jpg?alt=media&token=ebd1c7dd-cbe5-4af8-8cb6-a996693eedb6",
    time: "20 seconds",
    time_to_play: 0.3333333333333333,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fhiit%2FHigh%20Jumper.mp4?alt=media&token=3e783708-b840-410d-b77b-323070086ebd",
    video_id: "h_High_Jumper_1",
    video_length: 0.07,
    video_number: "547",
    size: 1580514,
    hints: "h_High_Jumper_1_hints",
    common_mistakes: "h_High_Jumper_1_common_mistakes",
    breathing: "h_High_Jumper_1_breathing",
  },
  {
    category_id: 7,
    category_name: "hiit",
    name: "Hurdle Steps Alternating",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "hiit",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FHurdle%20Steps%20Alternating-0.jpg?alt=media&token=b2befe23-16bb-4a54-93df-b9a7317b0e75",
    time: "20 seconds",
    time_to_play: 0.3333333333333333,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fhiit%2FHurdle%20Steps%20Alternating.mp4?alt=media&token=d3881e39-2edf-4b23-bbce-33f813094ca0",
    video_id: "h_Hurdle_Steps_Alternating_1",
    video_length: 0.07,
    video_number: "548",
    size: 1438623,
    hints: "h_Hurdle_Steps_Alternating_1_hints",
    common_mistakes: "h_Hurdle_Steps_Alternating_1_common_mistakes",
    breathing: "h_Hurdle_Steps_Alternating_1_breathing",
  },
  {
    category_id: 7,
    category_name: "hiit",
    name: "In & Outs",
    is_free: true,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "hiit",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FIn%20%26%20Outs-0.jpg?alt=media&token=5928b79a-b850-40fb-bff2-a18bce36a58b",
    time: "20 seconds",
    time_to_play: 0.3333333333333333,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fhiit%2FIn%20%26%20Outs.mp4?alt=media&token=35ca41b5-0546-4cb0-a425-f65051347417",
    video_id: "h_In_Outs_1",
    video_length: 0.07,
    video_number: "549",
    size: 1595671,
    hints: "h_In_Outs_1_hints",
    common_mistakes: "h_In_Outs_1_common_mistakes",
    breathing: "h_In_Outs_1_breathing",
  },
  {
    category_id: 7,
    category_name: "hiit",
    name: "Jumping Jack",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "hiit",
    tertiary_muscle: "Chest",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FJumping%20Jack-0.jpg?alt=media&token=8175617e-50eb-43c5-9cc1-79d092181fd7",
    time: "20 seconds",
    time_to_play: 0.3333333333333333,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fhiit%2FJumping%20Jack.mp4?alt=media&token=2785613a-cdcd-4826-8362-28058c41dc3b",
    video_id: "h_Jumping_Jack_1",
    video_length: 0.07,
    video_number: "550",
    size: 1656792,
    hints: "h_Jumping_Jack_1_hints",
    common_mistakes: "h_Jumping_Jack_1_common_mistakes",
    breathing: "h_Jumping_Jack_1_breathing",
  },
  {
    category_id: 7,
    category_name: "hiit",
    name: "Jumping Jack & Knee Jump",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "hiit",
    tertiary_muscle: "Chest",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FJumping%20Jack%20%26%20Knee%20Jump-0.jpg?alt=media&token=8eadd6f5-3e80-4ae6-99f5-96eb16b34995",
    time: "20 seconds",
    time_to_play: 0.3333333333333333,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fhiit%2FJumping%20Jack%20%26%20Knee%20Jump.mp4?alt=media&token=2e4e72ad-694b-4938-87b2-107aa2351cda",
    video_id: "h_Jumping_Jack_Knee_Jump_1",
    video_length: 0.07,
    video_number: "551",
    size: 1551835,
    hints: "h_Jumping_Jack_Knee_Jump_1_hints",
    common_mistakes: "h_Jumping_Jack_Knee_Jump_1_common_mistakes",
    breathing: "h_Jumping_Jack_Knee_Jump_1_breathing",
  },
  {
    category_id: 7,
    category_name: "hiit",
    name: "Jumping Jacks & Squat",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "hiit",
    tertiary_muscle: "Chest",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FJumping%20Jacks%20%26%20Squat-0.jpg?alt=media&token=b9167932-4883-4cfd-970e-6163671c7bb0",
    time: "20 seconds",
    time_to_play: 0.3333333333333333,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fhiit%2FJumping%20Jacks%20%26%20Squat.mp4?alt=media&token=dc856e91-ce6a-440a-981a-ef50ef9c5d2b",
    video_id: "h_Jumping_Jacks_Squat_1",
    video_length: 0.07,
    video_number: "552",
    size: 1359109,
    hints: "h_Jumping_Jacks_Squat_1_hints",
    common_mistakes: "h_Jumping_Jacks_Squat_1_common_mistakes",
    breathing: "h_Jumping_Jacks_Squat_1_breathing",
  },
  {
    category_id: 7,
    category_name: "hiit",
    name: "Butt Kickers",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Core",
    subcategory_name: "hiit",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FButt%20Kickers-0.jpg?alt=media&token=458c8756-cc70-4ae6-85d7-fb6f39d143b7",
    time: "20 seconds",
    time_to_play: 0.3333333333333333,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fhiit%2FButt%20Kickers.mp4?alt=media&token=6bed5054-a000-4a0a-9cb0-e7e435ab6e08",
    video_id: "h_Butt_Kickers_1",
    video_length: 0.07,
    video_number: "553",
    size: 1728692,
    hints: "h_Butt_Kickers_1_hints",
    common_mistakes: "h_Butt_Kickers_1_common_mistakes",
    breathing: "h_Butt_Kickers_1_breathing",
  },
  {
    category_id: 7,
    category_name: "hiit",
    name: "Lateral High Knee March",
    is_free: true,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "hiit",
    tertiary_muscle: "Chest",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FLateral%20High%20Knee%20March-0.jpg?alt=media&token=0b123b1d-0ac3-436d-9813-39b45ced262e",
    time: "20 seconds",
    time_to_play: 0.3333333333333333,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fhiit%2FLateral%20High%20Knee%20March.mp4?alt=media&token=f5b70f21-de40-4f50-9765-42bb23dd3468",
    video_id: "h_Lateral_High_Knee_March_1",
    video_length: 0.07,
    video_number: "554",
    size: 2236230,
    hints: "h_Lateral_High_Knee_March_1_hints",
    common_mistakes: "h_Lateral_High_Knee_March_1_common_mistakes",
    breathing: "h_Lateral_High_Knee_March_1_breathing",
  },
  {
    category_id: 7,
    category_name: "hiit",
    name: "Lateral Sprint In Place",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "hiit",
    tertiary_muscle: "Chest",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FLateral%20Sprint%20In%20Place-0.jpg?alt=media&token=87eba70e-f455-4e4b-8676-dcb2dbff95a0",
    time: "20 seconds",
    time_to_play: 0.3333333333333333,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fhiit%2FLateral%20Sprint%20In%20Place.mp4?alt=media&token=f1bcfaf1-a806-4f69-850c-91ac9e940497",
    video_id: "h_Lateral_Sprint_In_Place_1",
    video_length: 0.07,
    video_number: "555",
    size: 2087037,
    hints: "h_Lateral_Sprint_In_Place_1_hints",
    common_mistakes: "h_Lateral_Sprint_In_Place_1_common_mistakes",
    breathing: "h_Lateral_Sprint_In_Place_1_breathing",
  },
  {
    category_id: 7,
    category_name: "hiit",
    name: "Low Jacks",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "hiit",
    tertiary_muscle: "Chest",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FLow%20Jacks-0.jpg?alt=media&token=33594910-c6d4-4cfb-ae70-75ddfc3e49e2",
    time: "20 seconds",
    time_to_play: 0.3333333333333333,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fhiit%2FLow%20Jacks.mp4?alt=media&token=5deaad81-b189-4d0a-9c96-33c756a47337",
    video_id: "h_Low_Jacks_1",
    video_length: 0.07,
    video_number: "556",
    size: 1204119,
    hints: "h_Low_Jacks_1_hints",
    common_mistakes: "h_Low_Jacks_1_common_mistakes",
    breathing: "h_Low_Jacks_1_breathing",
  },
  {
    category_id: 7,
    category_name: "hiit",
    name: "Outward Heel Taps",
    is_free: true,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "hiit",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FOutward%20Heel%20Taps-0.jpg?alt=media&token=ce00ceeb-8136-49fa-b437-9527f0ba8da8",
    time: "20 seconds",
    time_to_play: 0.3333333333333333,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fhiit%2FOutward%20Heel%20Taps.mp4?alt=media&token=7f23ad09-5c3f-44f1-9a33-e150cc929696",
    video_id: "h_Outward_Heel_Taps_1",
    video_length: 0.07,
    video_number: "557",
    size: 1413102,
    hints: "h_Outward_Heel_Taps_1_hints",
    common_mistakes: "h_Outward_Heel_Taps_1_common_mistakes",
    breathing: "h_Outward_Heel_Taps_1_breathing",
  },
  {
    category_id: 7,
    category_name: "hiit",
    name: "Predator Jacks",
    is_free: true,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "hiit",
    tertiary_muscle: "Chest",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FPredator%20Jacks-0.jpg?alt=media&token=0af79cb8-2aa6-49f9-a12a-a94abd38cd25",
    time: "20 seconds",
    time_to_play: 0.3333333333333333,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fhiit%2FPredator%20Jacks.mp4?alt=media&token=c0c327f5-5d5f-4803-bd39-00a58a7c6294",
    video_id: "h_Predator_Jacks_1",
    video_length: 0.07,
    video_number: "558",
    size: 3577466,
    hints: "h_Predator_Jacks_1_hints",
    common_mistakes: "h_Predator_Jacks_1_common_mistakes",
    breathing: "h_Predator_Jacks_1_breathing",
  },
  {
    category_id: 7,
    category_name: "hiit",
    name: "Scissor Switch",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "hiit",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FScissor%20Switch-0.jpg?alt=media&token=ac24259d-d9fd-495a-8e46-e7006e64bd97",
    time: "20 seconds",
    time_to_play: 0.3333333333333333,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fhiit%2FScissor%20Switch.mp4?alt=media&token=4a5b38a5-8c03-4a87-a091-de2b52e7f483",
    video_id: "h_Scissor_Switch_1",
    video_length: 0.07,
    video_number: "559",
    size: 2758571,
    hints: "h_Scissor_Switch_1_hints",
    common_mistakes: "h_Scissor_Switch_1_common_mistakes",
    breathing: "h_Scissor_Switch_1_breathing",
  },
  {
    category_id: 7,
    category_name: "hiit",
    name: "Seal Jacks",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "hiit",
    tertiary_muscle: "Chest",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSeal%20Jacks-0.jpg?alt=media&token=9908dfbc-dcbd-4b2a-b01e-c745d1c9ecff",
    time: "20 seconds",
    time_to_play: 0.3333333333333333,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fhiit%2FSeal%20Jacks.mp4?alt=media&token=1ef91f4f-4050-4c6d-9884-70bf8011f07d",
    video_id: "h_Seal_Jacks_1",
    video_length: 0.07,
    video_number: "560",
    size: 1545289,
    hints: "h_Seal_Jacks_1_hints",
    common_mistakes: "h_Seal_Jacks_1_common_mistakes",
    breathing: "h_Seal_Jacks_1_breathing",
  },
  {
    category_id: 7,
    category_name: "hiit",
    name: "Side Hops",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "hiit",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSide%20Hops-0.jpg?alt=media&token=eb998aee-d35e-46a1-b138-c904b7ecb5d7",
    time: "20 seconds",
    time_to_play: 0.3333333333333333,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fhiit%2FSide%20Hops.mp4?alt=media&token=ceb68e6b-c142-41e3-b70b-3fc0881cbbd2",
    video_id: "h_Side_Hops_1",
    video_length: 0.07,
    video_number: "561",
    size: 1891422,
    hints: "h_Side_Hops_1_hints",
    common_mistakes: "h_Side_Hops_1_common_mistakes",
    breathing: "h_Side_Hops_1_breathing",
  },
  {
    category_id: 7,
    category_name: "hiit",
    name: "Side To Side Hop",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "hiit",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSide%20To%20Side%20Hop-0.jpg?alt=media&token=71036b35-7fb6-4ea0-a224-76422053fe7b",
    time: "20 seconds",
    time_to_play: 0.3333333333333333,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fhiit%2FSide%20To%20Side%20Hop.mp4?alt=media&token=b55fc1eb-1daf-4f32-b6b7-715d5e04edfb",
    video_id: "h_Side_To_Side_Hop_1",
    video_length: 0.07,
    video_number: "562",
    size: 1581022,
    hints: "h_Side_To_Side_Hop_1_hints",
    common_mistakes: "h_Side_To_Side_Hop_1_common_mistakes",
    breathing: "h_Side_To_Side_Hop_1_breathing",
  },
  {
    category_id: 7,
    category_name: "hiit",
    name: "Sides & Front Squat Hop",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "hiit",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSides%20%26%20Front%20Squat%20Hop-0.jpg?alt=media&token=d9385d18-8a75-4d9c-86f7-84fdb81619de",
    time: "20 seconds",
    time_to_play: 0.3333333333333333,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fhiit%2FSides%20%26%20Front%20Squat%20Hop.mp4?alt=media&token=da428a03-bdae-4e40-bbb6-210fa5d76efd",
    video_id: "h_Sides_Front_Squat_Hop_1",
    video_length: 0.07,
    video_number: "563",
    size: 1725504,
    hints: "h_Sides_Front_Squat_Hop_1_hints",
    common_mistakes: "h_Sides_Front_Squat_Hop_1_common_mistakes",
    breathing: "h_Sides_Front_Squat_Hop_1_breathing",
  },
  {
    category_id: 7,
    category_name: "hiit",
    name: "Skater",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "hiit",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSkater-0.jpg?alt=media&token=2084ab07-334c-4025-93b5-379ce6852787",
    time: "20 seconds",
    time_to_play: 0.3333333333333333,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fhiit%2FSkater.mp4?alt=media&token=6ce2afb6-c5ea-4dce-8440-6a06baf89b69",
    video_id: "h_Skater_1",
    video_length: 0.07,
    video_number: "564",
    size: 3934709,
    hints: "h_Skater_1_hints",
    common_mistakes: "h_Skater_1_common_mistakes",
    breathing: "h_Skater_1_breathing",
  },
  {
    category_id: 7,
    category_name: "hiit",
    name: "Small Scissor Switch",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "hiit",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSmall%20Scissor%20Switch-0.jpg?alt=media&token=67cbb7e5-cb81-43a3-9d77-a32442834518",
    time: "20 seconds",
    time_to_play: 0.3333333333333333,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fhiit%2FSmall%20Scissor%20Switch.mp4?alt=media&token=9ac1dece-d9a5-4309-9d17-ffc70dfc2aa4",
    video_id: "h_Small_Scissor_Switch_1",
    video_length: 0.07,
    video_number: "565",
    size: 2045517,
    hints: "h_Small_Scissor_Switch_1_hints",
    common_mistakes: "h_Small_Scissor_Switch_1_common_mistakes",
    breathing: "h_Small_Scissor_Switch_1_breathing",
  },
  {
    category_id: 7,
    category_name: "hiit",
    name: "Squat Jacks",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "hiit",
    tertiary_muscle: "Chest",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSquat%20Jacks-0.jpg?alt=media&token=18de5bf0-c04f-44be-9ce6-c80b31a9d41a",
    time: "20 seconds",
    time_to_play: 0.3333333333333333,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fhiit%2FSquat%20Jacks.mp4?alt=media&token=79ec2e6b-28fe-45b6-871a-b2c3340fd863",
    video_id: "h_Squat_Jacks_1",
    video_length: 0.07,
    video_number: "566",
    size: 927398,
    hints: "h_Squat_Jacks_1_hints",
    common_mistakes: "h_Squat_Jacks_1_common_mistakes",
    breathing: "h_Squat_Jacks_1_breathing",
  },
  {
    category_id: 7,
    category_name: "hiit",
    name: "Staggered Squat Jump",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "hiit",
    tertiary_muscle: "Chest",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FStaggered%20Squat%20Jump-0.jpg?alt=media&token=f669f006-6cd1-47a3-bd23-fbccb1f3e420",
    time: "20 seconds",
    time_to_play: 0.3333333333333333,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fhiit%2FStaggered%20Squat%20Jump.mp4?alt=media&token=cd67431e-6f37-48f9-ada2-1c3f1158dabe",
    video_id: "h_Staggered_Squat_Jump_1",
    video_length: 0.07,
    video_number: "567",
    size: 3320357,
    hints: "h_Staggered_Squat_Jump_1_hints",
    common_mistakes: "h_Staggered_Squat_Jump_1_common_mistakes",
    breathing: "h_Staggered_Squat_Jump_1_breathing",
  },
  {
    category_id: 7,
    category_name: "hiit",
    name: "Standing Knee Tuck",
    is_free: true,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "hiit",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FStanding%20Knee%20Tuck-0.jpg?alt=media&token=fc7f786f-ec67-413d-b2b3-c05bbdfdf491",
    time: "20 seconds",
    time_to_play: 0.3333333333333333,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fhiit%2FStanding%20Knee%20Tuck.mp4?alt=media&token=2c1e231e-7fa0-4eba-8812-6927e105bb0d",
    video_id: "h_Standing_Knee_Tuck_1",
    video_length: 0.07,
    video_number: "568",
    size: 1405029,
    hints: "h_Standing_Knee_Tuck_1_hints",
    common_mistakes: "h_Standing_Knee_Tuck_1_common_mistakes",
    breathing: "h_Standing_Knee_Tuck_1_breathing",
  },
  {
    category_id: 7,
    category_name: "hiit",
    name: "Standing Mountain Climbers",
    is_free: true,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "hiit",
    tertiary_muscle: "Chest",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FStanding%20Mountain%20Climbers%20-0.jpg?alt=media&token=26079660-499e-438b-bb95-134a6a326c78",
    time: "20 seconds",
    time_to_play: 0.3333333333333333,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fhiit%2FStanding%20Mountain%20Climbers%20.mp4?alt=media&token=cf743be9-7020-4221-9aeb-24a69dbde7f6",
    video_id: "h_Standing_Mountain_Climbers_1",
    video_length: 0.07,
    video_number: "569",
    size: 2016189,
    hints: "h_Standing_Mountain_Climbers_1_hints",
    common_mistakes: "h_Standing_Mountain_Climbers_1_common_mistakes",
    breathing: "h_Standing_Mountain_Climbers_1_breathing",
  },
  {
    category_id: 7,
    category_name: "hiit",
    name: "Star Jump",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "hiit",
    tertiary_muscle: "Chest",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FStar%20Jump-0.jpg?alt=media&token=35346a44-4de7-4881-8a9b-f259ad2983c8",
    time: "20 seconds",
    time_to_play: 0.3333333333333333,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fhiit%2FStar%20Jump.mp4?alt=media&token=dba791eb-e48a-449f-a2b7-72f564d7612a",
    video_id: "h_Star_Jump_1",
    video_length: 0.07,
    video_number: "570",
    size: 2075767,
    hints: "h_Star_Jump_1_hints",
    common_mistakes: "h_Star_Jump_1_common_mistakes",
    breathing: "h_Star_Jump_1_breathing",
  },
  {
    category_id: 7,
    category_name: "hiit",
    name: "Super Skater Jump",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "hiit",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSuper%20Skater%20Jump-0.jpg?alt=media&token=b7562f51-b6de-4b07-b3d3-4fac472690ac",
    time: "20 seconds",
    time_to_play: 0.3333333333333333,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fhiit%2FSuper%20Skater%20Jump.mp4?alt=media&token=3a2dd503-3f11-4c2d-8cea-fd59f0497ae1",
    video_id: "h_Super_Skater_Jump_1",
    video_length: 0.07,
    video_number: "571",
    size: 3150566,
    hints: "h_Super_Skater_Jump_1_hints",
    common_mistakes: "h_Super_Skater_Jump_1_common_mistakes",
    breathing: "h_Super_Skater_Jump_1_breathing",
  },
  {
    category_id: 7,
    category_name: "hiit",
    name: "Switch Kick",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "hiit",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSwitch%20Kick-0.jpg?alt=media&token=1596da49-deb5-4df3-8f9b-e5f6e6d75b96",
    time: "20 seconds",
    time_to_play: 0.3333333333333333,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fhiit%2FSwitch%20Kick.mp4?alt=media&token=d778846b-f77e-4ccc-aa83-b4f31d146230",
    video_id: "x",
    video_length: 0.07,
    video_number: "572",
    size: 1488489,
    hints: "x_hints",
    common_mistakes: "x_common_mistakes",
    breathing: "x_breathing",
  },
  {
    category_id: 7,
    category_name: "hiit",
    name: "X Drill Alternate Feet",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "hiit",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FX%20Drill%20Alternate%20Feet-0.jpg?alt=media&token=90ff7d4d-80fa-473b-81b7-afde0e2c5500",
    time: "20 seconds",
    time_to_play: 0.3333333333333333,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fhiit%2FX%20Drill%20Alternate%20Feet.mp4?alt=media&token=30ca36e5-dae3-49e1-bce4-d0eb5bc5a8df",
    video_id: "h_X_Drill_Alternate_Feet_1",
    video_length: 0.07,
    video_number: "573",
    size: 1668560,
    hints: "h_X_Drill_Alternate_Feet_1_hints",
    common_mistakes: "h_X_Drill_Alternate_Feet_1_common_mistakes",
    breathing: "h_X_Drill_Alternate_Feet_1_breathing",
  },
  {
    category_id: 7,
    category_name: "hiit",
    name: "Double Fast Feet",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "hiit",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FDouble%20Fast%20Feet-0.jpg?alt=media&token=fde9ea88-d4fc-4884-9bdd-d19e0e268fa6",
    time: "20 seconds",
    time_to_play: 0.3333333333333333,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fhiit%2FDouble%20Fast%20Feet.mp4?alt=media&token=895307eb-e927-4a63-bf67-b3a47531a064",
    video_id: "h_Double_Fast_Feet_1",
    video_length: 0.07,
    video_number: "574",
    size: 1097697,
    hints: "h_Double_Fast_Feet_1_hints",
    common_mistakes: "h_Double_Fast_Feet_1_common_mistakes",
    breathing: "h_Double_Fast_Feet_1_breathing",
  },
  {
    category_id: 7,
    category_name: "hiit",
    name: "Halo Slam",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "hiit",
    tertiary_muscle: "Chest",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FHalo%20Slam-0.jpg?alt=media&token=34dac3e3-d603-46ac-b6ab-bf0565723038",
    time: "20 seconds",
    time_to_play: 0.3333333333333333,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fhiit%2FHalo%20Slam.mp4?alt=media&token=bf6f061a-e474-4dc7-ad46-531eb9eeea7f",
    video_id: "h_Halo_Slam_1",
    video_length: 0.07,
    video_number: "575",
    size: 2606893,
    hints: "h_Halo_Slam_1_hints",
    common_mistakes: "h_Halo_Slam_1_common_mistakes",
    breathing: "h_Halo_Slam_1_breathing",
  },
  {
    category_id: 7,
    category_name: "hiit",
    name: "Pogo Jump",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "hiit",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FPogo%20Jump-0.jpg?alt=media&token=573d65aa-4183-441b-a317-c330833ecfcd",
    time: "20 seconds",
    time_to_play: 0.3333333333333333,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fhiit%2FPogo%20Jump.mp4?alt=media&token=77096289-6b40-4a21-ae54-c85cd299e939",
    video_id: "h_Pogo_Jump_1",
    video_length: 0.07,
    video_number: "576",
    size: 1612842,
    hints: "h_Pogo_Jump_1_hints",
    common_mistakes: "h_Pogo_Jump_1_common_mistakes",
    breathing: "h_Pogo_Jump_1_breathing",
  },
  {
    category_id: 7,
    category_name: "hiit",
    name: "Quick Feet",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "hiit",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FQuick%20Feet-0.jpg?alt=media&token=ae2cc571-351a-445a-9a67-f91e5a55b578",
    time: "20 seconds",
    time_to_play: 0.3333333333333333,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fhiit%2FQuick%20Feet.mp4?alt=media&token=a198465e-d782-442a-a7af-6673f9efb21f",
    video_id: "h_Quick_Feet_1",
    video_length: 0.07,
    video_number: "577",
    size: 1445814,
    hints: "h_Quick_Feet_1_hints",
    common_mistakes: "h_Quick_Feet_1_common_mistakes",
    breathing: "h_Quick_Feet_1_breathing",
  },
  {
    category_id: 7,
    category_name: "hiit",
    name: "Running In Place",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "hiit",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FRunning%20In%20Place-0.jpg?alt=media&token=7ef881a4-f24a-4a78-9e1d-bb12d1b3e3ce",
    time: "20 seconds",
    time_to_play: 0.3333333333333333,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fhiit%2FRunning%20In%20Place.mp4?alt=media&token=2e956d84-48d9-4dad-8fe4-4392572117ea",
    video_id: "h_Running_In_Place_1",
    video_length: 0.07,
    video_number: "578",
    size: 1655058,
    hints: "h_Running_In_Place_1_hints",
    common_mistakes: "h_Running_In_Place_1_common_mistakes",
    breathing: "h_Running_In_Place_1_breathing",
  },
  {
    category_id: 7,
    category_name: "hiit",
    name: "Side Sweep",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "hiit",
    tertiary_muscle: "Chest",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSide%20Sweep-0.jpg?alt=media&token=d47d32e1-596f-439a-8bc0-80951757aec2",
    time: "20 seconds",
    time_to_play: 0.3333333333333333,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fhiit%2FSide%20Sweep.mp4?alt=media&token=2b5d1d5b-fbef-41fd-9b77-2ab270165b58",
    video_id: "h_Side_Sweep_1",
    video_length: 0.07,
    video_number: "579",
    size: 2283200,
    hints: "h_Side_Sweep_1_hints",
    common_mistakes: "h_Side_Sweep_1_common_mistakes",
    breathing: "h_Side_Sweep_1_breathing",
  },
  {
    category_id: 7,
    category_name: "hiit",
    name: "Skier Swing",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "hiit",
    tertiary_muscle: "Chest",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSkier%20Swing-0.jpg?alt=media&token=def59c6d-520c-4c48-ad70-a31ccea70eed",
    time: "20 seconds",
    time_to_play: 0.3333333333333333,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fhiit%2FSkier%20Swing.mp4?alt=media&token=fc170055-7bf6-4559-bb1a-97805863a112",
    video_id: "h_Skier_Swing_1",
    video_length: 0.07,
    video_number: "580",
    size: 1498014,
    hints: "h_Skier_Swing_1_hints",
    common_mistakes: "h_Skier_Swing_1_common_mistakes",
    breathing: "h_Skier_Swing_1_breathing",
  },
  {
    category_id: 7,
    category_name: "hiit",
    name: "Slam",
    is_free: false,
    primary_muscle: "Cardiovascular System",
    rep_range: "Time Based",
    secondary_muscle:
      "Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae",
    subcategory_name: "hiit",
    tertiary_muscle: "Chest",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSlam-0.jpg?alt=media&token=ec4fb982-51a4-4654-a8cf-74bf8ee249bf",
    time: "20 seconds",
    time_to_play: 0.3333333333333333,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fhiit%2FSlam.mp4?alt=media&token=7499c1d7-d256-4f5b-be01-788077bb59ff",
    video_id: "h_Slam_1",
    video_length: 0.07,
    video_number: "581",
    size: 2069707,
    hints: "h_Slam_1_hints",
    common_mistakes: "h_Slam_1_common_mistakes",
    breathing: "h_Slam_1_breathing",
  },
  {
    category_id: 10,
    category_name: "planks",
    name: "Side Plank Lifts",
    is_other_side: 0,
    is_free: false,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Lower Back",
    subcategory_name: "planks",
    tertiary_muscle: "Rhomboids, Latissimus Dorsi, Traps, Triceps, Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(L)%20Side%20Plank%20Lifts-0.jpg?alt=media&token=941eed9f-f496-40d2-812b-10ed309c1056",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fplank%2F(L)%20Side%20Plank%20Lifts.mp4?alt=media&token=3d294920-5800-4f30-96e7-ed940846fc86",
    video_id: "pl_Side_Plank_Lifts_1",
    video_length: 0.07,
    video_number: "582",
    size: 704806,
    hints: "pl_Side_Plank_Lifts_1_hints",
    common_mistakes: "pl_Side_Plank_Lifts_1_common_mistakes",
    breathing: "pl_Side_Plank_Lifts_1_breathing",
  },
  {
    category_id: 10,
    category_name: "planks",
    name: "Side Plank Lifts",
    is_other_side: 1,
    is_free: false,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Lower Back",
    subcategory_name: "planks",
    tertiary_muscle: "Rhomboids, Latissimus Dorsi, Traps, Triceps, Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(R)%20Side%20Plank%20Lifts-0.jpg?alt=media&token=f89d5c28-d0f8-4b4f-ace9-b63a5532c638",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fplank%2F(R)%20Side%20Plank%20Lifts.mp4?alt=media&token=81fe0c21-4cbe-45e5-bad1-023aba391ca9",
    video_id: "pl_Side_Plank_Lifts_2",
    video_length: 0.07,
    video_number: "583",
    size: 781233,
    hints: "pl_Side_Plank_Lifts_2_hints",
    common_mistakes: "pl_Side_Plank_Lifts_2_common_mistakes",
    breathing: "pl_Side_Plank_Lifts_2_breathing",
  },
  {
    category_id: 10,
    category_name: "planks",
    name: "Roll Plank",
    is_free: false,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Lower Back",
    subcategory_name: "planks",
    tertiary_muscle: "Rhomboids, Latissimus Dorsi, Traps, Triceps, Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FRoll%20Plank-0.jpg?alt=media&token=0065216f-879e-461c-843f-feb190faf568",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fplank%2FRoll%20Plank.mp4?alt=media&token=140fe5c3-6ded-4d0b-8a08-26c01ebf624d",
    video_id: "pl_Roll_Plank_1",
    video_length: 0.07,
    video_number: "584",
    size: 2337366,
    hints: "pl_Roll_Plank_1_hints",
    common_mistakes: "pl_Roll_Plank_1_common_mistakes",
    breathing: "pl_Roll_Plank_1_breathing",
  },
  {
    category_id: 10,
    category_name: "planks",
    name: "Marching Planks",
    is_free: false,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Lower Back",
    subcategory_name: "planks",
    tertiary_muscle: "Rhomboids, Latissimus Dorsi, Traps, Triceps, Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FMarching%20Planks-0.jpg?alt=media&token=4be7a4e7-12fc-4f4c-99aa-1b291035a13e",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fplank%2FMarching%20Planks.mp4?alt=media&token=0f5aed6c-972c-4c8d-ac97-bc82e3a4b637",
    video_id: "pl_Marching_Planks_1",
    video_length: 0.07,
    video_number: "585",
    size: 1474068,
    hints: "pl_Marching_Planks_1_hints",
    common_mistakes: "pl_Marching_Planks_1_common_mistakes",
    breathing: "pl_Marching_Planks_1_breathing",
  },
  {
    category_id: 10,
    category_name: "planks",
    name: "Walk The Plank",
    is_free: true,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Lower Back",
    subcategory_name: "planks",
    tertiary_muscle: "Rhomboids, Latissimus Dorsi, Traps, Triceps, Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FWalk%20The%20Plank-0.jpg?alt=media&token=c8b22ace-beee-4ce6-bc52-a6ffdec62d92",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fplank%2FWalk%20The%20Plank.mp4?alt=media&token=7772df39-73a8-4632-b71b-0db822d179c8",
    video_id: "pl_Walk_The_Plank_1",
    video_length: 0.07,
    video_number: "586",
    size: 2996795,
    hints: "pl_Walk_The_Plank_1_hints",
    common_mistakes: "pl_Walk_The_Plank_1_common_mistakes",
    breathing: "pl_Walk_The_Plank_1_breathing",
  },
  {
    category_id: 10,
    category_name: "planks",
    name: "Plank Hip Twist",
    is_free: false,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Lower Back",
    subcategory_name: "planks",
    tertiary_muscle: "Rhomboids, Latissimus Dorsi, Traps, Triceps, Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FPlank%20Hip%20Twist-0.jpg?alt=media&token=9c43d382-f0ec-47df-b995-5a48002be1ca",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fplank%2FPlank%20Hip%20Twist.mp4?alt=media&token=2da95e59-07eb-40d9-a41d-12151b2f7542",
    video_id: "pl_Plank_Hip_Twist_1",
    video_length: 0.07,
    video_number: "587",
    size: 2346769,
    hints: "pl_Plank_Hip_Twist_1_hints",
    common_mistakes: "pl_Plank_Hip_Twist_1_common_mistakes",
    breathing: "pl_Plank_Hip_Twist_1_breathing",
  },
  {
    category_id: 10,
    category_name: "planks",
    name: "Reverse Plank",
    is_free: true,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Glutes, Hamstrings, Middle Shoulders, Lower Back",
    subcategory_name: "planks",
    tertiary_muscle: "Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FReverse%20Plank-0.jpg?alt=media&token=71301b9a-2bd9-4d85-a03c-c12ec07679ac",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fplank%2FReverse%20Plank.mp4?alt=media&token=3b4a112e-592a-4fd4-9bf6-7323e0c78cb9",
    video_id: "pl_Reverse_Plank_1",
    video_length: 0.07,
    video_number: "588",
    size: 512151,
    hints: "pl_Reverse_Plank_1_hints",
    common_mistakes: "pl_Reverse_Plank_1_common_mistakes",
    breathing: "pl_Reverse_Plank_1_breathing",
  },
  {
    category_id: 10,
    category_name: "planks",
    name: "High Plank",
    is_free: true,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Lower Back",
    subcategory_name: "planks",
    tertiary_muscle: "Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FHigh%20Plank-0.jpg?alt=media&token=47a0856c-7533-4225-9da3-2c81df2b5c7d",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fplank%2FHigh%20Plank.mp4?alt=media&token=532d9396-659a-484a-8a9f-480926a0d523",
    video_id: "pl_High_Plank_1",
    video_length: 0.07,
    video_number: "589",
    size: 354337,
    hints: "pl_High_Plank_1_hints",
    common_mistakes: "pl_High_Plank_1_common_mistakes",
    breathing: "pl_High_Plank_1_breathing",
  },
  {
    category_id: 10,
    category_name: "planks",
    name: "Cross Knee Planks",
    is_free: true,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Lower Back",
    subcategory_name: "planks",
    tertiary_muscle: "Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FCross%20Knee%20Planks-0.jpg?alt=media&token=c7b471ca-cbb8-40ec-aded-176fb8124398",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fplank%2FCross%20Knee%20Planks.mp4?alt=media&token=c37aed80-2e29-4356-83a3-dde368b440b2",
    video_id: "pl_Cross_Knee_Planks_1",
    video_length: 0.07,
    video_number: "590",
    size: 2306886,
    hints: "pl_Cross_Knee_Planks_1_hints",
    common_mistakes: "pl_Cross_Knee_Planks_1_common_mistakes",
    breathing: "pl_Cross_Knee_Planks_1_breathing",
  },
  {
    category_id: 10,
    category_name: "planks",
    name: "Tuck Planks Same Side",
    is_free: true,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Lower Back",
    subcategory_name: "planks",
    tertiary_muscle: "Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FTuck%20Planks%20Same%20Side-0.jpg?alt=media&token=af14c1a9-8fe3-4f10-9986-43497d084579",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fplank%2FTuck%20Planks%20Same%20Side.mp4?alt=media&token=28383e00-dd04-4538-8543-8aec9ac14434",
    video_id: "pl_Tuck_Planks_Same_Side_1",
    video_length: 0.07,
    video_number: "591",
    size: 2636867,
    hints: "pl_Tuck_Planks_Same_Side_1_hints",
    common_mistakes: "pl_Tuck_Planks_Same_Side_1_common_mistakes",
    breathing: "pl_Tuck_Planks_Same_Side_1_breathing",
  },
  {
    category_id: 10,
    category_name: "planks",
    name: "Drunken Mountain Climbers",
    is_free: false,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Lower Back",
    subcategory_name: "planks",
    tertiary_muscle: "Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FDrunken%20Mountain%20Climbers-0.jpg?alt=media&token=614ad5fa-89be-49c5-93f0-e5a31857a866",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fplank%2FDrunken%20Mountain%20Climbers.mp4?alt=media&token=c16b379b-0f5e-40ae-ab89-1c046d533e7e",
    video_id: "pl_Drunken_Mountain_Climbers_1",
    video_length: 0.07,
    video_number: "592",
    size: 1931929,
    hints: "pl_Drunken_Mountain_Climbers_1_hints",
    common_mistakes: "pl_Drunken_Mountain_Climbers_1_common_mistakes",
    breathing: "pl_Drunken_Mountain_Climbers_1_breathing",
  },
  {
    category_id: 10,
    category_name: "planks",
    name: "Sidewalk Planks",
    is_free: false,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Lower Back",
    subcategory_name: "planks",
    tertiary_muscle: "Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSidewalk%20Planks-0.jpg?alt=media&token=e2aff5df-06df-4d4f-a25c-6b8901a9a3b8",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fplank%2FSidewalk%20Planks.mp4?alt=media&token=5e8be4ae-f88d-4720-a6fa-2cc58d0114a4",
    video_id: "pl_Sidewalk_Planks_1",
    video_length: 0.07,
    video_number: "593",
    size: 6063113,
    hints: "pl_Sidewalk_Planks_1_hints",
    common_mistakes: "pl_Sidewalk_Planks_1_common_mistakes",
    breathing: "pl_Sidewalk_Planks_1_breathing",
  },
  {
    category_id: 10,
    category_name: "planks",
    name: "Tuck Planks Opposite Side",
    is_free: false,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Lower Back",
    subcategory_name: "planks",
    tertiary_muscle: "Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FTuck%20Planks%20Opposite%20Side-0.jpg?alt=media&token=2dbe4042-754c-4549-9ab4-39dbe50fc787",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fplank%2FTuck%20Planks%20Opposite%20Side.mp4?alt=media&token=9daf82dd-fd29-44d2-a331-1912b77dd978",
    video_id: "pl_Tuck_Planks_Opposite_Side_1",
    video_length: 0.07,
    video_number: "594",
    size: 1915737,
    hints: "pl_Tuck_Planks_Opposite_Side_1_hints",
    common_mistakes: "pl_Tuck_Planks_Opposite_Side_1_common_mistakes",
    breathing: "pl_Tuck_Planks_Opposite_Side_1_breathing",
  },
  {
    category_id: 10,
    category_name: "planks",
    name: "Low Side Plank Twists",
    is_other_side: 0,
    is_free: false,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Lower Back",
    subcategory_name: "planks",
    tertiary_muscle: "Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(L)%20Low%20Side%20Plank%20Twists-0.jpg?alt=media&token=36f68072-cc5d-41ce-9741-72e03a90a7ad",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fplank%2F(L)%20Low%20Side%20Plank%20Twists.mp4?alt=media&token=41fc9524-c043-4eb6-bc50-cd1ce76b47f1",
    video_id: "pl_Low_Side_Plank_Twists_1",
    video_length: 0.07,
    video_number: "595",
    size: 921340,
    hints: "pl_Low_Side_Plank_Twists_1_hints",
    common_mistakes: "pl_Low_Side_Plank_Twists_1_common_mistakes",
    breathing: "pl_Low_Side_Plank_Twists_1_breathing",
  },
  {
    category_id: 10,
    category_name: "planks",
    name: "Low Side Plank Twists",
    is_other_side: 1,
    is_free: false,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Lower Back",
    subcategory_name: "planks",
    tertiary_muscle: "Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(R)%20Low%20Side%20Plank%20Twists-0.jpg?alt=media&token=913912a9-834c-4f22-9364-4f6d9cc8e024",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fplank%2F(R)%20Low%20Side%20Plank%20Twists.mp4?alt=media&token=0b7c74f5-a9b2-4363-8801-b9614b362ded",
    video_id: "pl_Low_Side_Plank_Twists_2",
    video_length: 0.07,
    video_number: "596",
    size: 989948,
    hints: "pl_Low_Side_Plank_Twists_2_hints",
    common_mistakes: "pl_Low_Side_Plank_Twists_2_common_mistakes",
    breathing: "pl_Low_Side_Plank_Twists_2_breathing",
  },
  {
    category_id: 10,
    category_name: "planks",
    name: "One Arm Plank",
    is_other_side: 0,
    is_free: false,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Lower Back",
    subcategory_name: "planks",
    tertiary_muscle: "Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(L)%20One%20Arm%20Plank-0.jpg?alt=media&token=8ea4dd6a-6a4d-44b1-994b-d63029bcecc2",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fplank%2F(L)%20One%20Arm%20Plank.mp4?alt=media&token=1c4344e4-bd80-4ccd-9fe8-30e67594e308",
    video_id: "pl_One_Arm_Plank_1",
    video_length: 0.07,
    video_number: "597",
    size: 514744,
    hints: "pl_One_Arm_Plank_1_hints",
    common_mistakes: "pl_One_Arm_Plank_1_common_mistakes",
    breathing: "pl_One_Arm_Plank_1_breathing",
  },
  {
    category_id: 10,
    category_name: "planks",
    name: "One Arm Plank",
    is_other_side: 1,
    is_free: false,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Lower Back",
    subcategory_name: "planks",
    tertiary_muscle: "Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(R)%20One%20Arm%20Plank-0.jpg?alt=media&token=fd125ca7-0558-4fe1-be72-3b76c1bc7d37",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fplank%2F(R)%20One%20Arm%20Plank.mp4?alt=media&token=5e4007cf-c694-4041-989c-85a2f11b1f9a",
    video_id: "pl_One_Arm_Plank_2",
    video_length: 0.07,
    video_number: "598",
    size: 386554,
    hints: "pl_One_Arm_Plank_2_hints",
    common_mistakes: "pl_One_Arm_Plank_2_common_mistakes",
    breathing: "pl_One_Arm_Plank_2_breathing",
  },
  {
    category_id: 10,
    category_name: "planks",
    name: "Single Arm High Plank",
    is_other_side: 0,
    is_free: false,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Lower Back",
    subcategory_name: "planks",
    tertiary_muscle: "Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(L)%20Single%20Arm%20High%20Plank-0.jpg?alt=media&token=e85de40c-f405-45a7-8557-91e109861b71",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fplank%2F(L)%20Single%20Arm%20High%20Plank.mp4?alt=media&token=7b0beee5-71cf-4a2c-b64b-b3c4d5f9dfc1",
    video_id: "pl_Single_Arm_High_Plank_1",
    video_length: 0.07,
    video_number: "599",
    size: 670676,
    hints: "pl_Single_Arm_High_Plank_1_hints",
    common_mistakes: "pl_Single_Arm_High_Plank_1_common_mistakes",
    breathing: "pl_Single_Arm_High_Plank_1_breathing",
  },
  {
    category_id: 10,
    category_name: "planks",
    name: "Single Arm High Plank",
    is_other_side: 1,
    is_free: false,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Lower Back",
    subcategory_name: "planks",
    tertiary_muscle: "Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(R)%20Single%20Arm%20High%20Plank-0.jpg?alt=media&token=78c84369-497e-4906-b800-6e85305fee24",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fplank%2F(R)%20Single%20Arm%20High%20Plank.mp4?alt=media&token=bf79faea-ec15-437a-a0a3-268983923b2d",
    video_id: "pl_Single_Arm_High_Plank_2",
    video_length: 0.07,
    video_number: "600",
    size: 755759,
    hints: "pl_Single_Arm_High_Plank_2_hints",
    common_mistakes: "pl_Single_Arm_High_Plank_2_common_mistakes",
    breathing: "pl_Single_Arm_High_Plank_2_breathing",
  },
  {
    category_id: 10,
    category_name: "planks",
    name: "Bear Plank",
    is_free: false,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Lower Back",
    subcategory_name: "planks",
    tertiary_muscle: "Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FBear%20Plank-0.jpg?alt=media&token=19ae827b-b54c-4b34-881e-71f30c6b35b3",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fplank%2FBear%20Plank.mp4?alt=media&token=045d0a62-e46d-4d0b-945a-17a0826f81eb",
    video_id: "pl_Bear_Plank_1",
    video_length: 0.07,
    video_number: "601",
    size: 349503,
    hints: "pl_Bear_Plank_1_hints",
    common_mistakes: "pl_Bear_Plank_1_common_mistakes",
    breathing: "pl_Bear_Plank_1_breathing",
  },
  {
    category_id: 10,
    category_name: "planks",
    name: "Body Saw",
    is_free: false,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Lower Back",
    subcategory_name: "planks",
    tertiary_muscle: "Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FBody%20Saw-0.jpg?alt=media&token=d4c5f632-b040-40a1-b0a6-999b29f68b5d",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fplank%2FBody%20Saw.mp4?alt=media&token=9396d638-c29f-4ad2-9b15-eed523c0edda",
    video_id: "pl_Body_Saw_1",
    video_length: 0.07,
    video_number: "602",
    size: 1340549,
    hints: "pl_Body_Saw_1_hints",
    common_mistakes: "pl_Body_Saw_1_common_mistakes",
    breathing: "pl_Body_Saw_1_breathing",
  },
  {
    category_id: 10,
    category_name: "planks",
    name: "In & Out",
    is_free: true,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Lower Back",
    subcategory_name: "planks",
    tertiary_muscle: "Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FIn%20%26%20Out-0.jpg?alt=media&token=08fed7aa-ae27-4031-9523-43609194db9e",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fplank%2FIn%20%26%20Out.mp4?alt=media&token=1831b72b-bb27-448a-bb56-0b6590fa89a3",
    video_id: "pl_In_Out_1",
    video_length: 0.07,
    video_number: "603",
    size: 1291705,
    hints: "pl_In_Out_1_hints",
    common_mistakes: "pl_In_Out_1_common_mistakes",
    breathing: "pl_In_Out_1_breathing",
  },
  {
    category_id: 10,
    category_name: "planks",
    name: "Chair Plank",
    is_free: false,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Lower Back",
    subcategory_name: "planks",
    tertiary_muscle: "Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FChair%20Plank-0.jpg?alt=media&token=c9ce2300-afb6-46fe-b8bc-36ab4ed7aa93",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "Chair",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fplank%2FChair%20Plank.mp4?alt=media&token=d9548be0-f1c3-4a3b-bfa6-8e58a1a9f97c",
    video_id: "pl_Chair_Plank_1",
    video_length: 0.07,
    video_number: "604",
    size: 461853,
    hints: "pl_Chair_Plank_1_hints",
    common_mistakes: "pl_Chair_Plank_1_common_mistakes",
    breathing: "pl_Chair_Plank_1_breathing",
  },
  {
    category_id: 10,
    category_name: "planks",
    name: "Shoulder Tap High Plank",
    is_free: true,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Lower Back",
    subcategory_name: "planks",
    tertiary_muscle: "Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FShoulder%20Tap%20High%20Plank-0.jpg?alt=media&token=7abe0e0f-4204-4e7c-bb7a-8049996c9dd5",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fplank%2FShoulder%20Tap%20High%20Plank.mp4?alt=media&token=f9aeb6b9-cdfe-4da2-95bb-6c6da69c4f43",
    video_id: "pl_Shoulder_Tap_High_Plank_1",
    video_length: 0.07,
    video_number: "605",
    size: 1159604,
    hints: "pl_Shoulder_Tap_High_Plank_1_hints",
    common_mistakes: "pl_Shoulder_Tap_High_Plank_1_common_mistakes",
    breathing: "pl_Shoulder_Tap_High_Plank_1_breathing",
  },
  {
    category_id: 10,
    category_name: "planks",
    name: "High Plank Opposite Knee Touches",
    is_free: false,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Lower Back",
    subcategory_name: "planks",
    tertiary_muscle: "Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FHigh%20Plank%20Opposite%20Knee%20Touches-0.jpg?alt=media&token=d6450754-cf2a-4a83-84cc-511ae150c4a1",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fplank%2FHigh%20Plank%20Opposite%20Knee%20Touches.mp4?alt=media&token=49768e3b-5cea-40f1-b504-a5a51b0cc6a0",
    video_id: "pl_High_Plank_Opposite_Knee_Touches_1",
    video_length: 0.07,
    video_number: "606",
    size: 2743716,
    hints: "pl_High_Plank_Opposite_Knee_Touches_1_hints",
    common_mistakes: "pl_High_Plank_Opposite_Knee_Touches_1_common_mistakes",
    breathing: "pl_High_Plank_Opposite_Knee_Touches_1_breathing",
  },
  {
    category_id: 10,
    category_name: "planks",
    name: "Chair High Plank",
    is_free: false,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Lower Back",
    subcategory_name: "planks",
    tertiary_muscle: "Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FChair%20High%20Plank-0.jpg?alt=media&token=012d716f-2578-4ad1-b139-6c6b81470858",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "Chair",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fplank%2FChair%20High%20Plank.mp4?alt=media&token=1b281988-6fed-4810-9daa-a37c3804276c",
    video_id: "pl_Chair_High_Plank_1",
    video_length: 0.07,
    video_number: "607",
    size: 461347,
    hints: "pl_Chair_High_Plank_1_hints",
    common_mistakes: "pl_Chair_High_Plank_1_common_mistakes",
    breathing: "pl_Chair_High_Plank_1_breathing",
  },
  {
    category_id: 10,
    category_name: "planks",
    name: "Low Plank Arm Reaches",
    is_free: true,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Lower Back",
    subcategory_name: "planks",
    tertiary_muscle: "Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FLow%20Plank%20Arm%20Reaches-0.jpg?alt=media&token=07f573ac-ee94-4ad0-bd29-871c98281b20",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fplank%2FLow%20Plank%20Arm%20Reaches.mp4?alt=media&token=3421476f-ae89-4b9e-814f-98020c7a2d25",
    video_id: "pl_Low_Plank_Arm_Reaches_1",
    video_length: 0.07,
    video_number: "608",
    size: 1799694,
    hints: "pl_Low_Plank_Arm_Reaches_1_hints",
    common_mistakes: "pl_Low_Plank_Arm_Reaches_1_common_mistakes",
    breathing: "pl_Low_Plank_Arm_Reaches_1_breathing",
  },
  {
    category_id: 10,
    category_name: "planks",
    name: "High Plank Opposite Hip Tap",
    is_free: false,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Lower Back",
    subcategory_name: "planks",
    tertiary_muscle: "Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FHigh%20Plank%20Opposite%20Hip%20Tap-0.jpg?alt=media&token=8bcdf507-d8dd-4c17-b7cf-183c287d4998",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fplank%2FHigh%20Plank%20Opposite%20Hip%20Tap.mp4?alt=media&token=d3c9b9b7-8d4c-4c8c-a112-55fab5708396",
    video_id: "pl_High_Plank_Opposite_Hip_Tap_1",
    video_length: 0.07,
    video_number: "609",
    size: 889032,
    hints: "pl_High_Plank_Opposite_Hip_Tap_1_hints",
    common_mistakes: "pl_High_Plank_Opposite_Hip_Tap_1_common_mistakes",
    breathing: "pl_High_Plank_Opposite_Hip_Tap_1_breathing",
  },
  {
    category_id: 10,
    category_name: "planks",
    name: "Sidewalk High Plank",
    is_free: false,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Lower Back",
    subcategory_name: "planks",
    tertiary_muscle: "Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSidewalk%20High%20Plank-0.jpg?alt=media&token=108a9829-deff-48de-a239-90a7b1c3259e",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fplank%2FSidewalk%20High%20Plank.mp4?alt=media&token=4a0d9675-380b-4da7-9642-504ea68b182b",
    video_id: "pl_Sidewalk_High_Plank_1",
    video_length: 0.07,
    video_number: "610",
    size: 5323387,
    hints: "pl_Sidewalk_High_Plank_1_hints",
    common_mistakes: "pl_Sidewalk_High_Plank_1_common_mistakes",
    breathing: "pl_Sidewalk_High_Plank_1_breathing",
  },
  {
    category_id: 10,
    category_name: "planks",
    name: "Bear Plank Shoulder Taps",
    is_free: false,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Lower Back",
    subcategory_name: "planks",
    tertiary_muscle: "Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FBear%20Plank%20Shoulder%20Taps-0.jpg?alt=media&token=6c5ef28b-ada7-4c75-b415-a453d31a94c2",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fplank%2FBear%20Plank%20Shoulder%20Taps.mp4?alt=media&token=95850d78-3776-49c3-936d-021df11be48d",
    video_id: "pl_Bear_Plank_Shoulder_Taps_1",
    video_length: 0.07,
    video_number: "611",
    size: 1044870,
    hints: "pl_Bear_Plank_Shoulder_Taps_1_hints",
    common_mistakes: "pl_Bear_Plank_Shoulder_Taps_1_common_mistakes",
    breathing: "pl_Bear_Plank_Shoulder_Taps_1_breathing",
  },
  {
    category_id: 10,
    category_name: "planks",
    name: "Knees On Ground Plank Hold",
    is_free: false,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Lower Back",
    subcategory_name: "planks",
    tertiary_muscle: "Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FKnees%20On%20Ground%20Plank%20Hold-0.jpg?alt=media&token=89e6bb70-c15c-462f-8bdb-a0d4a93c745e",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fplank%2FKnees%20On%20Ground%20Plank%20Hold.mp4?alt=media&token=0f95e4ff-3a5d-4ca6-9b0d-d4bf256bee03",
    video_id: "pl_Knees_On_Ground_Plank_Hold_1",
    video_length: 0.07,
    video_number: "612",
    size: 696695,
    hints: "pl_Knees_On_Ground_Plank_Hold_1_hints",
    common_mistakes: "pl_Knees_On_Ground_Plank_Hold_1_common_mistakes",
    breathing: "pl_Knees_On_Ground_Plank_Hold_1_breathing",
  },
  {
    category_id: 10,
    category_name: "planks",
    name: "Rollers",
    is_free: false,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Lower Back",
    subcategory_name: "planks",
    tertiary_muscle: "Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FRollers-0.jpg?alt=media&token=294158ce-a6bb-4fc0-b459-88ed12702f0d",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fplank%2FRollers.mp4?alt=media&token=f63350a1-c552-4004-b2d3-647fcae030c0",
    video_id: "pl_Rollers_1",
    video_length: 0.07,
    video_number: "613",
    size: 2943453,
    hints: "pl_Rollers_1_hints",
    common_mistakes: "pl_Rollers_1_common_mistakes",
    breathing: "pl_Rollers_1_breathing",
  },
  {
    category_id: 10,
    category_name: "planks",
    name: "Loaded Beast To High Plank",
    is_free: false,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Lower Back",
    subcategory_name: "planks",
    tertiary_muscle: "Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FLoaded%20Beast%20To%20High%20Plank-0.jpg?alt=media&token=2775c616-6b26-4e08-a6df-62a90fa7d676",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fplank%2FLoaded%20Beast%20To%20High%20Plank.mp4?alt=media&token=bfebfb8f-ae3d-429f-8672-b22db2b938cc",
    video_id: "pl_Loaded_Beast_To_High_Plank_1",
    video_length: 0.07,
    video_number: "614",
    size: 1656487,
    hints: "pl_Loaded_Beast_To_High_Plank_1_hints",
    common_mistakes: "pl_Loaded_Beast_To_High_Plank_1_common_mistakes",
    breathing: "pl_Loaded_Beast_To_High_Plank_1_breathing",
  },
  {
    category_id: 10,
    category_name: "planks",
    name: "High Plank Jacks",
    is_free: false,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Lower Back",
    subcategory_name: "planks",
    tertiary_muscle: "Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FHigh%20Plank%20Jacks-0.jpg?alt=media&token=ae4ab913-2094-4c6f-baf0-c529db7ad9c9",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fplank%2FHigh%20Plank%20Jacks.mp4?alt=media&token=b3cffd9d-482b-46de-af2b-4b3ee9c2014d",
    video_id: "pl_High_Plank_Jacks_1",
    video_length: 0.07,
    video_number: "615",
    size: 1406803,
    hints: "pl_High_Plank_Jacks_1_hints",
    common_mistakes: "pl_High_Plank_Jacks_1_common_mistakes",
    breathing: "pl_High_Plank_Jacks_1_breathing",
  },
  {
    category_id: 10,
    category_name: "planks",
    name: "Hip Raise Planks",
    is_free: false,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Lower Back",
    subcategory_name: "planks",
    tertiary_muscle: "Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FHip%20Raise%20Planks-0.jpg?alt=media&token=88caa2b2-a419-4eee-94bb-779e16c6f973",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fplank%2FHip%20Raise%20Planks.mp4?alt=media&token=6b27bd1a-2a3a-44b6-bb10-444c6c3662d6",
    video_id: "pl_Hip_Raise_Planks_1",
    video_length: 0.07,
    video_number: "616",
    size: 2321289,
    hints: "pl_Hip_Raise_Planks_1_hints",
    common_mistakes: "pl_Hip_Raise_Planks_1_common_mistakes",
    breathing: "pl_Hip_Raise_Planks_1_breathing",
  },
  {
    category_id: 10,
    category_name: "planks",
    name: "Plank Jack Taps",
    is_free: true,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Lower Back",
    subcategory_name: "planks",
    tertiary_muscle: "Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FPlank%20Jack%20Taps-0.jpg?alt=media&token=ad9d4d38-cc8d-4544-82b6-f9e28144dcde",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fplank%2FPlank%20Jack%20Taps.mp4?alt=media&token=24697138-47f1-4af4-bc94-71b6e09fa031",
    video_id: "pl_Plank_Jack_Taps_1",
    video_length: 0.07,
    video_number: "617",
    size: 1326058,
    hints: "pl_Plank_Jack_Taps_1_hints",
    common_mistakes: "pl_Plank_Jack_Taps_1_common_mistakes",
    breathing: "pl_Plank_Jack_Taps_1_breathing",
  },
  {
    category_id: 10,
    category_name: "planks",
    name: "Kneeling Walk Outs",
    is_free: true,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Lower Back",
    subcategory_name: "planks",
    tertiary_muscle: "Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FKneeling%20Walk%20Outs-0.jpg?alt=media&token=15bb25af-3e8e-443e-97e2-864a0831d6ae",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fplank%2FKneeling%20Walk%20Outs.mp4?alt=media&token=2e4138ae-6169-4a5c-b11c-095eafbaeacb",
    video_id: "pl_Kneeling_Walk_Outs_1",
    video_length: 0.07,
    video_number: "618",
    size: 2439064,
    hints: "pl_Kneeling_Walk_Outs_1_hints",
    common_mistakes: "pl_Kneeling_Walk_Outs_1_common_mistakes",
    breathing: "pl_Kneeling_Walk_Outs_1_breathing",
  },
  {
    category_id: 10,
    category_name: "planks",
    name: "Up Downs",
    is_free: true,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Lower Back",
    subcategory_name: "planks",
    tertiary_muscle: "Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FUp%20Downs-0.jpg?alt=media&token=6fb12535-8528-4737-b0a3-345dc89b421f",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fplank%2FUp%20Downs.mp4?alt=media&token=9a9a925d-3a9a-4c77-b2f6-988e7b09f37c",
    video_id: "pl_Up_Downs_1",
    video_length: 0.07,
    video_number: "619",
    size: 3527723,
    hints: "pl_Up_Downs_1_hints",
    common_mistakes: "pl_Up_Downs_1_common_mistakes",
    breathing: "pl_Up_Downs_1_breathing",
  },
  {
    category_id: 10,
    category_name: "planks",
    name: "Mountain Climbers",
    is_free: false,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Lower Back",
    subcategory_name: "planks",
    tertiary_muscle: "Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FMountain%20Climbers-0.jpg?alt=media&token=7aae4e38-cbfc-49cb-acb1-291dd1bc2a8d",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fplank%2FMountain%20Climbers.mp4?alt=media&token=5964acd8-5153-47fe-91d7-74fd946bdb51",
    video_id: "pl_Mountain_Climbers_1",
    video_length: 0.07,
    video_number: "620",
    size: 1719189,
    hints: "pl_Mountain_Climbers_1_hints",
    common_mistakes: "pl_Mountain_Climbers_1_common_mistakes",
    breathing: "pl_Mountain_Climbers_1_breathing",
  },
  {
    category_id: 10,
    category_name: "planks",
    name: "High Plank Leg Lifts",
    is_free: false,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Lower Back",
    subcategory_name: "planks",
    tertiary_muscle: "Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FHigh%20Plank%20Leg%20Lifts-0.jpg?alt=media&token=a7eb568d-dcfb-4258-bdc4-c5ef0c2ad7c1",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fplank%2FHigh%20Plank%20Leg%20Lifts.mp4?alt=media&token=86d06236-1b7a-4e30-912e-37ab5bd1d31b",
    video_id: "pl_High_Plank_Leg_Lifts_1",
    video_length: 0.07,
    video_number: "621",
    size: 1451359,
    hints: "pl_High_Plank_Leg_Lifts_1_hints",
    common_mistakes: "pl_High_Plank_Leg_Lifts_1_common_mistakes",
    breathing: "pl_High_Plank_Leg_Lifts_1_breathing",
  },
  {
    category_id: 10,
    category_name: "planks",
    name: "Plank Walk Out",
    is_free: false,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Lower Back",
    subcategory_name: "planks",
    tertiary_muscle: "Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FPlank%20Walk%20Out-0.jpg?alt=media&token=1d5f0a99-4755-4043-a330-95f54b0cfc22",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fplank%2FPlank%20Walk%20Out.mp4?alt=media&token=99653692-344d-413f-b7cd-7472c66e9e82",
    video_id: "pl_Plank_Walk_Out_1",
    video_length: 0.07,
    video_number: "622",
    size: 3960552,
    hints: "pl_Plank_Walk_Out_1_hints",
    common_mistakes: "pl_Plank_Walk_Out_1_common_mistakes",
    breathing: "pl_Plank_Walk_Out_1_breathing",
  },
  {
    category_id: 10,
    category_name: "planks",
    name: "High Plank Knee Drops",
    is_free: false,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Lower Back",
    subcategory_name: "planks",
    tertiary_muscle: "Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FHigh%20Plank%20Knee%20Drops-0.jpg?alt=media&token=fc219c78-4b55-462d-99f6-26eef601c8cd",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fplank%2FHigh%20Plank%20Knee%20Drops.mp4?alt=media&token=8d5beeed-5d14-4c39-a0ad-6cd52ad99141",
    video_id: "pl_High_Plank_Knee_Drops_1",
    video_length: 0.07,
    video_number: "623",
    size: 1223735,
    hints: "pl_High_Plank_Knee_Drops_1_hints",
    common_mistakes: "pl_High_Plank_Knee_Drops_1_common_mistakes",
    breathing: "pl_High_Plank_Knee_Drops_1_breathing",
  },
  {
    category_id: 10,
    category_name: "planks",
    name: "Wide Grip Mountain Climbers",
    is_free: false,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Lower Back",
    subcategory_name: "planks",
    tertiary_muscle: "Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FWide%20Grip%20Mountain%20Climbers%20-0.jpg?alt=media&token=136fe01d-1aff-466e-857b-c104d0654afc",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fplank%2FWide%20Grip%20Mountain%20Climbers%20.mp4?alt=media&token=54f41559-0a8c-442b-949c-a13c26fd85ef",
    video_id: "pl_Wide_Grip_Mountain_Climbers_1",
    video_length: 0.07,
    video_number: "624",
    size: 1817503,
    hints: "pl_Wide_Grip_Mountain_Climbers_1_hints",
    common_mistakes: "pl_Wide_Grip_Mountain_Climbers_1_common_mistakes",
    breathing: "pl_Wide_Grip_Mountain_Climbers_1_breathing",
  },
  {
    category_id: 10,
    category_name: "planks",
    name: "X Plank",
    is_free: false,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Lower Back",
    subcategory_name: "planks",
    tertiary_muscle: "Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FX%20Plank-0.jpg?alt=media&token=46863fd5-c11f-4283-a318-47e3ca2d28cb",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fplank%2FX%20Plank.mp4?alt=media&token=d6c5db4e-98bd-49f3-87d1-6277d5b84004",
    video_id: "pl_X_Plank_1",
    video_length: 0.07,
    video_number: "625",
    size: 567494,
    hints: "pl_X_Plank_1_hints",
    common_mistakes: "pl_X_Plank_1_common_mistakes",
    breathing: "pl_X_Plank_1_breathing",
  },
  {
    category_id: 10,
    category_name: "planks",
    name: "Explosive",
    is_free: false,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Lower Back",
    subcategory_name: "planks",
    tertiary_muscle: "Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FExplosive-0.jpg?alt=media&token=f1766d79-1348-4571-aa26-413338365ece",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fplank%2FExplosive.mp4?alt=media&token=ae2b3aab-f0e7-4a13-b6e2-20a9dc36c1f4",
    video_id: "pl_Explosive_1",
    video_length: 0.07,
    video_number: "626",
    size: 1288175,
    hints: "pl_Explosive_1_hints",
    common_mistakes: "pl_Explosive_1_common_mistakes",
    breathing: "pl_Explosive_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Leg Swing",
    is_other_side: 0,
    is_free: false,
    muscle_category: ["legs"],
    primary_muscle: "Quads, Glutes, Hamstrings,  Hip Flexors",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(L)%20Leg%20Swing-0.jpg?alt=media&token=3a0894de-5820-4b3e-9159-8e529b4fd1fe",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2F(L)%20Leg%20Swing.mp4?alt=media&token=bf445954-c17b-403f-8d03-14f5e61539db",
    video_id: "lst_Leg_Swing_1",
    video_length: 0.07,
    video_number: "627",
    size: 1506125,
    hints: "lst_Leg_Swing_1_hints",
    common_mistakes: "lst_Leg_Swing_1_common_mistakes",
    breathing: "lst_Leg_Swing_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Leg Swing",
    is_other_side: 1,
    is_free: false,
    muscle_category: ["legs"],
    primary_muscle: "Quads, Glutes, Hamstrings,  Hip Flexors",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(R)%20Leg%20Swing-0.jpg?alt=media&token=110980ef-a44f-4f36-b921-808c133b30c5",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2F(R)%20Leg%20Swing.mp4?alt=media&token=47686593-b79d-4a19-adef-14c9531f20fc",
    video_id: "lst_Leg_Swing_2",
    video_length: 0.07,
    video_number: "628",
    size: 1784305,
    hints: "lst_Leg_Swing_2_hints",
    common_mistakes: "lst_Leg_Swing_2_common_mistakes",
    breathing: "lst_Leg_Swing_2_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Leg Swing Side To Side",
    is_other_side: 0,
    is_free: false,
    muscle_category: ["legs"],
    primary_muscle: "Adductors, Glutes, Hip Flexors",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(L)%20Leg%20Swing%20Side%20To%20Side-0.jpg?alt=media&token=bd77f70b-6585-437b-94a2-4585ba6bc844",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2F(L)%20Leg%20Swing%20Side%20To%20Side.mp4?alt=media&token=d3c9c7bc-532f-4a4a-a046-0f97cd57f1ff",
    video_id: "lst_Leg_Swing_Side_To_Side_1",
    video_length: 0.07,
    video_number: "629",
    size: 2168710,
    hints: "lst_Leg_Swing_Side_To_Side_1_hints",
    common_mistakes: "lst_Leg_Swing_Side_To_Side_1_common_mistakes",
    breathing: "lst_Leg_Swing_Side_To_Side_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Leg Swing Side To Side",
    is_other_side: 1,
    is_free: false,
    muscle_category: ["legs"],
    primary_muscle: "Adductors, Glutes, Hip Flexors",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(R)%20Leg%20Swing%20Side%20To%20Side-0.jpg?alt=media&token=65e0856c-a664-4b60-86f1-5a6282e540d9",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2F(R)%20Leg%20Swing%20Side%20To%20Side.mp4?alt=media&token=cc9ff6f8-e112-4979-ba86-67cab83d7860",
    video_id: "lst_Leg_Swing_Side_To_Side_2",
    video_length: 0.07,
    video_number: "630",
    size: 1972156,
    hints: "lst_Leg_Swing_Side_To_Side_2_hints",
    common_mistakes: "lst_Leg_Swing_Side_To_Side_2_common_mistakes",
    breathing: "lst_Leg_Swing_Side_To_Side_2_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Lizard",
    is_other_side: 0,
    is_free: false,
    muscle_category: ["legs"],
    primary_muscle: "Quad, Glutes, Hamstrings, Hip Flexors",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(L)%20Lizard-0.jpg?alt=media&token=29a39fbf-36df-4ede-90cf-e1754db0644c",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2F(L)%20Lizard.mp4?alt=media&token=2ee88caa-a75f-4be9-b655-4bb86e7d6a54",
    video_id: "lst_Lizard_1",
    video_length: 0.07,
    video_number: "631",
    size: 164524,
    hints: "lst_Lizard_1_hints",
    common_mistakes: "lst_Lizard_1_common_mistakes",
    breathing: "lst_Lizard_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Lizard",
    is_other_side: 1,
    is_free: false,
    muscle_category: ["legs"],
    primary_muscle: "Quad, Glutes, Hamstrings, Hip Flexors",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(R)%20Lizard-0.jpg?alt=media&token=251b655b-24b6-44ae-9d3e-b02538c83a19",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2F(R)%20Lizard.mp4?alt=media&token=4c4f0cfb-0791-4582-9ac4-91cc3ce0850e",
    video_id: "lst_Lizard_2",
    video_length: 0.07,
    video_number: "632",
    size: 150214,
    hints: "lst_Lizard_2_hints",
    common_mistakes: "lst_Lizard_2_common_mistakes",
    breathing: "lst_Lizard_2_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Pigeon Variation",
    is_other_side: 0,
    is_free: false,
    muscle_category: ["legs"],
    primary_muscle: "Glutes, Hip Flexors",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(L)%20Pigeon%20Variation-0.jpg?alt=media&token=296a0f31-4650-4828-94fb-258ccd02a0d8",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2F(L)%20Pigeon%20Variation.mp4?alt=media&token=3b42333d-e0ea-403a-80fd-9417752bdd9f",
    video_id: "lst_Pigeon_Variation_1",
    video_length: 0.07,
    video_number: "633",
    size: 1033214,
    hints: "lst_Pigeon_Variation_1_hints",
    common_mistakes: "lst_Pigeon_Variation_1_common_mistakes",
    breathing: "lst_Pigeon_Variation_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Pigeon Variation",
    is_other_side: 1,
    is_free: false,
    muscle_category: ["legs"],
    primary_muscle: "Glutes, Hip Flexors",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(R)%20Pigeon%20Variation-0.jpg?alt=media&token=91d76536-ff39-447e-adb3-6e1b28b25aa6",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2F(R)%20Pigeon%20Variation.mp4?alt=media&token=b75b2805-197d-4f6e-a3b3-17597d96f002",
    video_id: "lst_Pigeon_Variation_2",
    video_length: 0.07,
    video_number: "634",
    size: 954471,
    hints: "lst_Pigeon_Variation_2_hints",
    common_mistakes: "lst_Pigeon_Variation_2_common_mistakes",
    breathing: "lst_Pigeon_Variation_2_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Single Leg Seated Hamstring Stretch",
    is_other_side: 0,
    is_free: false,
    muscle_category: ["legs"],
    primary_muscle: "Hamstring",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(L)%20Single%20Leg%20Seated%20Hamstring%20Stretch-0.jpg?alt=media&token=122917fa-c016-4dcb-afbc-0c0c21dd8a87",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2F(L)%20Single%20Leg%20Seated%20Hamstring%20Stretch.mp4?alt=media&token=b93a9a25-26da-47b8-86ea-2f4d6a88f442",
    video_id: "lst_Single_Leg_Seated_Hamstring_Stretch_1",
    video_length: 0.07,
    video_number: "635",
    size: 1563850,
    hints: "lst_Single_Leg_Seated_Hamstring_Stretch_1_hints",
    common_mistakes: "lst_Single_Leg_Seated_Hamstring_Stretch_1_common_mistakes",
    breathing: "lst_Single_Leg_Seated_Hamstring_Stretch_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Single Leg Seated Hamstring Stretch",
    is_other_side: 1,
    is_free: false,
    muscle_category: ["legs"],
    primary_muscle: "Hamstring",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F(R)%20Single%20Leg%20Seated%20Hamstring%20Stretch-0.jpg?alt=media&token=154e277b-cc59-4fde-8fb1-ada5023160fa",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2F(R)%20Single%20Leg%20Seated%20Hamstring%20Stretch.mp4?alt=media&token=7a34d058-8c55-40e4-962d-bb880c091c8e",
    video_id: "lst_Single_Leg_Seated_Hamstring_Stretch_2",
    video_length: 0.07,
    video_number: "636",
    size: 1766716,
    hints: "lst_Single_Leg_Seated_Hamstring_Stretch_2_hints",
    common_mistakes: "lst_Single_Leg_Seated_Hamstring_Stretch_2_common_mistakes",
    breathing: "lst_Single_Leg_Seated_Hamstring_Stretch_2_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Alternating Knee Tucks",
    is_free: true,
    muscle_category: ["legs"],
    primary_muscle: "Glutes",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FAlternating%20Knee%20Tucks-0.jpg?alt=media&token=4629e563-8e4c-417a-b69b-f138889a13be",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FAlternating%20Knee%20Tucks.mp4?alt=media&token=bef4ec92-c7e8-4355-969a-3b144921e547",
    video_id: "lst_Alternating_Knee_Tucks_1",
    video_length: 0.07,
    video_number: "638",
    size: 4033334,
    hints: "lst_Alternating_Knee_Tucks_1_hints",
    common_mistakes: "lst_Alternating_Knee_Tucks_1_common_mistakes",
    breathing: "lst_Alternating_Knee_Tucks_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Ankle Circles",
    is_free: false,
    muscle_category: ["legs"],
    primary_muscle: "Ankles, Calves",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FAnkle%20Circles-0.jpg?alt=media&token=e2b3989a-5fd9-4c18-84b0-510a57ef9d95",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FAnkle%20Circles.mp4?alt=media&token=303f5497-1d77-4b05-922a-bfe979ffd79a",
    video_id: "lst_Ankle_Circles_1",
    video_length: 0.07,
    video_number: "639",
    size: 4781547,
    hints: "lst_Ankle_Circles_1_hints",
    common_mistakes: "lst_Ankle_Circles_1_common_mistakes",
    breathing: "lst_Ankle_Circles_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Back & Forth Hamstring Stretch",
    is_free: true,
    muscle_category: ["legs"],
    primary_muscle: "Hamstrings",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FBack%20%26%20Forth%20Hamstring%20Stretch-0.jpg?alt=media&token=e8783f4b-35f4-4d26-bdbd-8d4bb7c65d57",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FBack%20%26%20Forth%20Hamstring%20Stretch.mp4?alt=media&token=a8bb9758-c3a0-49b9-a470-c9949e480269",
    video_id: "lst_Back_Forth_Hamstring_Stretch_1",
    video_length: 0.07,
    video_number: "640",
    size: 2000334,
    hints: "lst_Back_Forth_Hamstring_Stretch_1_hints",
    common_mistakes: "lst_Back_Forth_Hamstring_Stretch_1_common_mistakes",
    breathing: "lst_Back_Forth_Hamstring_Stretch_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Backward Stretch",
    is_free: false,
    muscle_category: ["legs"],
    primary_muscle: "Quads, Serratus Anterior",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FBackward%20Stretch-0.jpg?alt=media&token=4d8a8872-633f-43d1-8d71-4a097f2f0f57",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FBackward%20Stretch.mp4?alt=media&token=dcb1107a-a872-4b2f-b4b4-8f1642d8dc75",
    video_id: "lst_Backward_Stretch_1",
    video_length: 0.07,
    video_number: "641",
    size: 875917,
    hints: "lst_Backward_Stretch_1_hints",
    common_mistakes: "lst_Backward_Stretch_1_common_mistakes",
    breathing: "lst_Backward_Stretch_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Bending Windmill Stretch",
    is_free: true,
    muscle_category: ["legs"],
    primary_muscle: "Hamstrings",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FBending%20Windmill%20Stretch-0.jpg?alt=media&token=a979e7cc-60a1-4f3f-9d25-9978cddef891",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FBending%20Windmill%20Stretch.mp4?alt=media&token=417a0b33-37bd-4de9-98f8-8c9d8e2fedf9",
    video_id: "lst_Bending_Windmill_Stretch_1",
    video_length: 0.07,
    video_number: "642",
    size: 3449808,
    hints: "lst_Bending_Windmill_Stretch_1_hints",
    common_mistakes: "lst_Bending_Windmill_Stretch_1_common_mistakes",
    breathing: "lst_Bending_Windmill_Stretch_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Butterfly Stretch",
    is_free: true,
    muscle_category: ["legs"],
    primary_muscle: "Adductors,  Hip Flexors",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FButterfly%20Stretch-0.jpg?alt=media&token=ca92c94b-189d-41cf-a98f-c5679b01b76a",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FButterfly%20Stretch.mp4?alt=media&token=013a34c8-dea2-423d-a168-90185aef687f",
    video_id: "lst_Butterfly_Stretch_1",
    video_length: 0.07,
    video_number: "643",
    size: 1031461,
    hints: "lst_Butterfly_Stretch_1_hints",
    common_mistakes: "lst_Butterfly_Stretch_1_common_mistakes",
    breathing: "lst_Butterfly_Stretch_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Calf Stretch",
    is_free: true,
    muscle_category: ["legs"],
    primary_muscle: "Calves",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FCalf%20Stretch-0.jpg?alt=media&token=c10a4268-1d84-4272-bf8b-784ccfde2622",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FCalf%20Stretch.mp4?alt=media&token=9ab0050d-8f52-4df8-9238-e807d7d369b0",
    video_id: "lst_Calf_Stretch_1",
    video_length: 0.07,
    video_number: "644",
    size: 3942299,
    hints: "lst_Calf_Stretch_1_hints",
    common_mistakes: "lst_Calf_Stretch_1_common_mistakes",
    breathing: "lst_Calf_Stretch_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Calf Stretch Assisted",
    is_free: false,
    muscle_category: ["legs"],
    primary_muscle: "Calves",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FCalf%20Stretch%20Assisted-0.jpg?alt=media&token=eeebf4c4-4b0d-4747-919b-dcfd08162064",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FCalf%20Stretch%20Assisted.mp4?alt=media&token=c633e802-4e2e-4c8f-a6ad-615905048251",
    video_id: "lst_Calf_Stretch_Assisted_1",
    video_length: 0.07,
    video_number: "645",
    size: 4286308,
    hints: "lst_Calf_Stretch_Assisted_1_hints",
    common_mistakes: "lst_Calf_Stretch_Assisted_1_common_mistakes",
    breathing: "lst_Calf_Stretch_Assisted_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Chair Hamstring Stretch",
    is_free: false,
    muscle_category: ["legs"],
    primary_muscle: "Hamstrings",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FChair%20Hamstring%20Stretch%20-0.jpg?alt=media&token=443e7a42-d87e-4ffc-8508-45e68e98bf09",
    time: "75 seconds",
    time_to_play: 1.25,
    tools: "Chair",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FChair%20Hamstring%20Stretch%20.mp4?alt=media&token=93168cd2-0ed7-4d99-bbe1-c56aa1f4dd2c",
    video_id: "lst_Chair_Hamstring_Stretch_1",
    video_length: 0.07,
    video_number: "646",
    size: 6365647,
    hints: "lst_Chair_Hamstring_Stretch_1_hints",
    common_mistakes: "lst_Chair_Hamstring_Stretch_1_common_mistakes",
    breathing: "lst_Chair_Hamstring_Stretch_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Chair Lunge Stretch",
    is_free: true,
    muscle_category: ["legs"],
    primary_muscle: "Glutes, Hip Flexors",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FChair%20Lunge%20Stretch-0.jpg?alt=media&token=62489a14-7425-4735-8247-3024729b8835",
    time: "75 seconds",
    time_to_play: 1.25,
    tools: "Chair",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FChair%20Lunge%20Stretch.mp4?alt=media&token=7dbcc30e-88dc-42f8-8e4b-497c5c3ee567",
    video_id: "lst_Chair_Lunge_Stretch_1",
    video_length: 0.07,
    video_number: "648",
    size: 7834138,
    hints: "lst_Chair_Lunge_Stretch_1_hints",
    common_mistakes: "lst_Chair_Lunge_Stretch_1_common_mistakes",
    breathing: "lst_Chair_Lunge_Stretch_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Child's Pose",
    is_free: false,
    muscle_category: ["legs"],
    primary_muscle: "Adductors, Lower Back, Glutes, Serratus Anterior",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FChild's%20Pose-0.jpg?alt=media&token=e1f49717-189e-46f8-8339-c5c837802aaa",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FChild's%20Pose.mp4?alt=media&token=db28ef3d-aea1-43b6-bc59-06438528504c",
    video_id: "lst_Child_pose_1",
    video_length: 0.07,
    video_number: "649",
    size: 802755,
    hints: "lst_Child_pose_1_hints",
    common_mistakes: "lst_Child_pose_1_common_mistakes",
    breathing: "lst_Child_pose_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Cross Body Glute Pull",
    is_free: false,
    muscle_category: ["legs"],
    primary_muscle: "Glutes, Hip Flexors",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FCross%20Body%20Glute%20Pull-0.jpg?alt=media&token=05907d9c-136d-4156-8d43-76ff8fbc69a9",
    time: "90 seconds",
    time_to_play: 1.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FCross%20Body%20Glute%20Pull.mp4?alt=media&token=1f453e53-0242-4a24-a9d7-5548d4625011",
    video_id: "lst_Cross_Body_Glute_Pull_1",
    video_length: 0.07,
    video_number: "650",
    size: 7449642,
    hints: "lst_Cross_Body_Glute_Pull_1_hints",
    common_mistakes: "lst_Cross_Body_Glute_Pull_1_common_mistakes",
    breathing: "lst_Cross_Body_Glute_Pull_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Deep Lunge",
    is_free: true,
    muscle_category: ["legs"],
    primary_muscle: "Hamstrings, Adductors, Glutes, Hip Flexors",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FDeep%20Lunge-0.jpg?alt=media&token=3d5baf87-3a4a-43aa-9350-7bae2874b0a5",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FDeep%20Lunge.mp4?alt=media&token=228042df-3888-4217-9ebf-ce79a0248232",
    video_id: "lst_Deep_Lunge_1",
    video_length: 0.07,
    video_number: "651",
    size: 4836970,
    hints: "lst_Deep_Lunge_1_hints",
    common_mistakes: "lst_Deep_Lunge_1_common_mistakes",
    breathing: "lst_Deep_Lunge_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Dynamic Downward Dog",
    is_free: false,
    muscle_category: ["legs"],
    primary_muscle: "Hamstrings, Calves",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FDynamic%20Downward%20Dog-0.jpg?alt=media&token=53e36b3b-5583-4557-9b9b-bb3e9c831322",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FDynamic%20Downward%20Dog.mp4?alt=media&token=a407ace3-4be1-49e6-9058-a340cc07161c",
    video_id: "lst_Dynamic_Downward_Dog_1",
    video_length: 0.07,
    video_number: "652",
    size: 2021845,
    hints: "lst_Dynamic_Downward_Dog_1_hints",
    common_mistakes: "lst_Dynamic_Downward_Dog_1_common_mistakes",
    breathing: "lst_Dynamic_Downward_Dog_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Frog Pose Rocks",
    is_free: false,
    muscle_category: ["legs"],
    primary_muscle: "Adductors, Hip Flexors",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FFrog%20Pose%20Rocks-0.jpg?alt=media&token=f2063460-ceb1-4f22-b531-8bef88b01352",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FFrog%20Pose%20Rocks.mp4?alt=media&token=c41d99e4-937e-4b45-9e5b-b815bc83faac",
    video_id: "lst_Frog_Pose_Rocks_1",
    video_length: 0.07,
    video_number: "653",
    size: 1582383,
    hints: "lst_Frog_Pose_Rocks_1_hints",
    common_mistakes: "lst_Frog_Pose_Rocks_1_common_mistakes",
    breathing: "lst_Frog_Pose_Rocks_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Frog Variation Rocks",
    is_free: false,
    muscle_category: ["legs"],
    primary_muscle: "Adductors, Hip Flexors",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FFrog%20Variation%20Rocks-0.jpg?alt=media&token=42c09bc8-3710-4b3e-a98d-0d9bf8c05930",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FFrog%20Variation%20Rocks.mp4?alt=media&token=776886b6-cea4-4b3d-bc8f-4a49ed601d33",
    video_id: "lst_Frog_Variation_Rocks_1",
    video_length: 0.07,
    video_number: "654",
    size: 1178093,
    hints: "lst_Frog_Variation_Rocks_1_hints",
    common_mistakes: "lst_Frog_Variation_Rocks_1_common_mistakes",
    breathing: "lst_Frog_Variation_Rocks_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Glute Bridge",
    is_free: false,
    muscle_category: ["legs"],
    primary_muscle: "Adductors , Glutes, Hamstrings",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FGlute%20Bridge-0.jpg?alt=media&token=d119ef29-6077-4514-a950-cb29c27e8e43",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FGlute%20Bridge.mp4?alt=media&token=7927e5a8-d9a9-47b2-a867-bedbcce0fe35",
    video_id: "lst_Glute_Bridge_1",
    video_length: 0.07,
    video_number: "655",
    size: 1709461,
    hints: "lst_Glute_Bridge_1_hints",
    common_mistakes: "lst_Glute_Bridge_1_common_mistakes",
    breathing: "lst_Glute_Bridge_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Hanging Hamstring Stretch",
    is_free: false,
    muscle_category: ["legs"],
    primary_muscle: "Hamstrings",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FHanging%20Hamstring%20Stretch-0.jpg?alt=media&token=b181189a-92bd-4cc2-b617-836f3e5a8535",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FHanging%20Hamstring%20Stretch.mp4?alt=media&token=70ae88cd-ac22-4ea4-93b5-2811704c9c1c",
    video_id: "lst_Hanging_Hamstring_Stretch_1",
    video_length: 0.07,
    video_number: "656",
    size: 1355453,
    hints: "lst_Hanging_Hamstring_Stretch_1_hints",
    common_mistakes: "lst_Hanging_Hamstring_Stretch_1_common_mistakes",
    breathing: "lst_Hanging_Hamstring_Stretch_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Hip Circles",
    is_free: false,
    muscle_category: ["legs"],
    primary_muscle: "Hip Flexors",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FHip%20Circles-0.jpg?alt=media&token=543ea344-7d2a-4449-8b4f-362f2d80fe8e",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FHip%20Circles.mp4?alt=media&token=c7dd2740-b19a-4462-b28d-1df63771a015",
    video_id: "lst_Hip_Circles_1",
    video_length: 0.07,
    video_number: "657",
    size: 2003097,
    hints: "lst_Hip_Circles_1_hints",
    common_mistakes: "lst_Hip_Circles_1_common_mistakes",
    breathing: "lst_Hip_Circles_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Hip Flexor Stretch",
    is_free: false,
    muscle_category: ["legs"],
    primary_muscle: "Hip Flexors, Quads",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FHip%20Flexor%20Stretch-0.jpg?alt=media&token=5a80ffd4-bef5-4352-b6c5-e08886e27135",
    time: "75 seconds",
    time_to_play: 1.25,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FHip%20Flexor%20Stretch.mp4?alt=media&token=9848861f-deeb-430b-82e3-0929b8784517",
    video_id: "lst_Hip_Flexor_Stretch_1",
    video_length: 0.07,
    video_number: "658",
    size: 5569931,
    hints: "lst_Hip_Flexor_Stretch_1_hints",
    common_mistakes: "lst_Hip_Flexor_Stretch_1_common_mistakes",
    breathing: "lst_Hip_Flexor_Stretch_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Knee & Ankle Rotations",
    is_free: false,
    muscle_category: ["legs"],
    primary_muscle: "Knees, Ankles",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FKnee%20%26%20Ankle%20Rotations-0.jpg?alt=media&token=8261d664-39c3-4667-b84a-0686ae956b26",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FKnee%20%26%20Ankle%20Rotations.mp4?alt=media&token=d87974d3-2afa-4ff9-a2be-af78a17d99d8",
    video_id: "lst_Knee_Ankle_Rotations_1",
    video_length: 0.07,
    video_number: "659",
    size: 2879834,
    hints: "lst_Knee_Ankle_Rotations_1_hints",
    common_mistakes: "lst_Knee_Ankle_Rotations_1_common_mistakes",
    breathing: "lst_Knee_Ankle_Rotations_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Knee To Chest",
    is_free: true,
    muscle_category: ["legs"],
    primary_muscle: "Glutes, Hamstrings, Hip Flexors",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FKnee%20To%20Chest%20-0.jpg?alt=media&token=447b2d30-6374-44f1-8ee2-41e4be8195ad",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FKnee%20To%20Chest%20.mp4?alt=media&token=0d2933a2-18fc-4784-9985-2f9ce66739b0",
    video_id: "lst_Knee_To_Chest_1",
    video_length: 0.07,
    video_number: "660",
    size: 4770106,
    hints: "lst_Knee_To_Chest_1_hints",
    common_mistakes: "lst_Knee_To_Chest_1_common_mistakes",
    breathing: "lst_Knee_To_Chest_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Leg Pull",
    is_free: false,
    muscle_category: ["legs"],
    primary_muscle: "Hamstrings",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FLeg%20Pull-0.jpg?alt=media&token=192a47d3-8fa1-4ad2-8c78-52478ca1e6d0",
    time: "75 seconds",
    time_to_play: 1.25,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FLeg%20Pull.mp4?alt=media&token=b928a6dc-ef62-42c0-bfb4-bdddcc369c00",
    video_id: "lst_Leg_Pull_1",
    video_length: 0.07,
    video_number: "661",
    size: 5155180,
    hints: "lst_Leg_Pull_1_hints",
    common_mistakes: "lst_Leg_Pull_1_common_mistakes",
    breathing: "lst_Leg_Pull_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Leg Tuck Up",
    is_free: false,
    muscle_category: ["legs"],
    primary_muscle: "Glutes",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FLeg%20Tuck%20Up-0.jpg?alt=media&token=f1b3bdae-a6bc-404a-b5fa-dbc622ca7636",
    time: "75 seconds",
    time_to_play: 1.25,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FLeg%20Tuck%20Up.mp4?alt=media&token=67b8febd-71bf-4746-9259-9ea12ba51845",
    video_id: "lst_Leg_Tuck_Up_1",
    video_length: 0.07,
    video_number: "662",
    size: 888754,
    hints: "lst_Leg_Tuck_Up_1_hints",
    common_mistakes: "lst_Leg_Tuck_Up_1_common_mistakes",
    breathing: "lst_Leg_Tuck_Up_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Lunge Rotation Stretch",
    is_free: false,
    muscle_category: ["legs"],
    primary_muscle: "Glutes, Hip Flexors, Adductors, Spinal, Serratus Anterior",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FLunge%20Rotation%20Stretch-0.jpg?alt=media&token=b4ce904c-8b34-4598-b000-c7db08b71a02",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FLunge%20Rotation%20Stretch.mp4?alt=media&token=3ac6e098-7e9c-4864-a4aa-07f3c2844ed2",
    video_id: "lst_Lunge_Rotation_Stretch_1",
    video_length: 0.07,
    video_number: "663",
    size: 5276739,
    hints: "lst_Lunge_Rotation_Stretch_1_hints",
    common_mistakes: "lst_Lunge_Rotation_Stretch_1_common_mistakes",
    breathing: "lst_Lunge_Rotation_Stretch_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Lying Figure 4 Stretch",
    is_free: false,
    muscle_category: ["legs"],
    primary_muscle: "Glutes",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FLying%20Figure%204%20Stretch-0.jpg?alt=media&token=474852df-8d4a-4b2c-8e80-a590c8986951",
    time: "90 seconds",
    time_to_play: 1.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FLying%20Figure%204%20Stretch.mp4?alt=media&token=98164ca6-bd09-46cb-a9d4-adecf6a72b3f",
    video_id: "lst_Lying_Figure_4_Stretch_1",
    video_length: 0.07,
    video_number: "664",
    size: 10019849,
    hints: "lst_Lying_Figure_4_Stretch_1_hints",
    common_mistakes: "lst_Lying_Figure_4_Stretch_1_common_mistakes",
    breathing: "lst_Lying_Figure_4_Stretch_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Lying On Stomach Quad Stretch",
    is_free: false,
    muscle_category: ["legs"],
    primary_muscle: "Quads",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FLying%20On%20Stomach%20Quad%20Stretch-0.jpg?alt=media&token=45121d45-596c-4ce0-90c5-1cf5a5ec6161",
    time: "75 seconds",
    time_to_play: 1.25,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FLying%20On%20Stomach%20Quad%20Stretch.mp4?alt=media&token=265a8a68-6c0e-4791-a6f2-f46f0ef92ce6",
    video_id: "lst_Lying_On_Stomach_Quad_Stretch_1",
    video_length: 0.07,
    video_number: "665",
    size: 5275193,
    hints: "lst_Lying_On_Stomach_Quad_Stretch_1_hints",
    common_mistakes: "lst_Lying_On_Stomach_Quad_Stretch_1_common_mistakes",
    breathing: "lst_Lying_On_Stomach_Quad_Stretch_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Lying Quad Stretch",
    is_free: false,
    muscle_category: ["legs"],
    primary_muscle: "Quads",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FLying%20Quad%20Stretch-0.jpg?alt=media&token=308616a6-438b-44b9-88ca-86a8880c97f3",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FLying%20Quad%20Stretch.mp4?alt=media&token=8f42c708-75d1-40ec-a6f4-b74a3355b5be",
    video_id: "lst_Lying_Quad_Stretch_1",
    video_length: 0.07,
    video_number: "666",
    size: 9058679,
    hints: "lst_Lying_Quad_Stretch_1_hints",
    common_mistakes: "lst_Lying_Quad_Stretch_1_common_mistakes",
    breathing: "lst_Lying_Quad_Stretch_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Quadricep Stretch",
    is_free: true,
    muscle_category: ["legs"],
    primary_muscle: "Quads",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FQuadricep%20Stretch-0.jpg?alt=media&token=b7312f18-c49e-4a69-858c-d4209b3177cc",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FQuadricep%20Stretch.mp4?alt=media&token=37399be6-f0f1-4f65-861c-85ebf92e0f52",
    video_id: "lst_Quadricep_Stretch_1",
    video_length: 0.07,
    video_number: "667",
    size: 4470813,
    hints: "lst_Quadricep_Stretch_1_hints",
    common_mistakes: "lst_Quadricep_Stretch_1_common_mistakes",
    breathing: "lst_Quadricep_Stretch_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Saddle",
    is_free: false,
    muscle_category: ["legs"],
    primary_muscle: "Quads, Serratus Anterior, Hip Flexors",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSaddle-0.jpg?alt=media&token=d344e81a-bce5-4e1d-a6e1-3d4cdc44bb4a",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FSaddle.mp4?alt=media&token=fd213d3d-1c12-4c08-867f-d970a31634ac",
    video_id: "lst_Saddle_1",
    video_length: 0.07,
    video_number: "668",
    size: 574606,
    hints: "lst_Saddle_1_hints",
    common_mistakes: "lst_Saddle_1_common_mistakes",
    breathing: "lst_Saddle_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Seated Cross Leg Stretch",
    is_free: false,
    muscle_category: ["legs"],
    primary_muscle: "Glutes",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSeated%20Cross%20Leg%20Stretch-0.jpg?alt=media&token=ab62b8a6-33e9-44e1-9d19-bfd449172090",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FSeated%20Cross%20Leg%20Stretch.mp4?alt=media&token=28064a12-1450-40fb-92c3-4487081a0e55",
    video_id: "lst_Seated_Cross_Leg_Stretch_1",
    video_length: 0.07,
    video_number: "670",
    size: 2521143,
    hints: "lst_Seated_Cross_Leg_Stretch_1_hints",
    common_mistakes: "lst_Seated_Cross_Leg_Stretch_1_common_mistakes",
    breathing: "lst_Seated_Cross_Leg_Stretch_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Seated Hamstring Stretch",
    is_free: false,
    muscle_category: ["legs"],
    primary_muscle: "Hamstrings",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSeated%20Hamstring%20Stretch-0.jpg?alt=media&token=125f970d-f703-4406-bff6-caa10e772bf7",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FSeated%20Hamstring%20Stretch.mp4?alt=media&token=26ecfa15-8221-4448-be36-f72c5d0fbf73",
    video_id: "lst_Seated_Hamstring_Stretch_1",
    video_length: 0.07,
    video_number: "671",
    size: 1810937,
    hints: "lst_Seated_Hamstring_Stretch_1_hints",
    common_mistakes: "lst_Seated_Hamstring_Stretch_1_common_mistakes",
    breathing: "lst_Seated_Hamstring_Stretch_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Seated Hamstring Stretches",
    is_free: false,
    muscle_category: ["legs"],
    primary_muscle: "Hamstrings",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSeated%20Hamstring%20Stretches-0.jpg?alt=media&token=f23f04b8-af57-4976-b6a1-6d9322be6a4d",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FSeated%20Hamstring%20Stretches.mp4?alt=media&token=5bee1f54-9dea-4ec3-b956-bd9fb11a2f7a",
    video_id: "lst_Seated_Hamstring_Stretches_1",
    video_length: 0.07,
    video_number: "672",
    size: 4538285,
    hints: "lst_Seated_Hamstring_Stretches_1_hints",
    common_mistakes: "lst_Seated_Hamstring_Stretches_1_common_mistakes",
    breathing: "lst_Seated_Hamstring_Stretches_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Seated Knee Over Tuck",
    is_free: false,
    muscle_category: ["legs"],
    primary_muscle: "Glutes",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSeated%20Knee%20Over%20Tuck-0.jpg?alt=media&token=7b775357-fa73-4af2-9871-e0ab60887e1c",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FSeated%20Knee%20Over%20Tuck.mp4?alt=media&token=7c8bfc38-2f4a-4108-8abd-b58b09fbb07f",
    video_id: "lst_Seated_Knee_Over_Tuck_1",
    video_length: 0.07,
    video_number: "673",
    size: 5787876,
    hints: "lst_Seated_Knee_Over_Tuck_1_hints",
    common_mistakes: "lst_Seated_Knee_Over_Tuck_1_common_mistakes",
    breathing: "lst_Seated_Knee_Over_Tuck_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Seated Knee To Chest",
    is_free: false,
    muscle_category: ["legs"],
    primary_muscle: "Glutes",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSeated%20Knee%20To%20Chest-0.jpg?alt=media&token=54ef2aa4-00ec-43f6-a2ae-26115b795a0c",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FSeated%20Knee%20To%20Chest.mp4?alt=media&token=3c157b51-cf1b-4be2-817a-1fd213e5aaf6",
    video_id: "lst_Seated_Knee_To_Chest_1",
    video_length: 0.07,
    video_number: "674",
    size: 1945853,
    hints: "lst_Seated_Knee_To_Chest_1_hints",
    common_mistakes: "lst_Seated_Knee_To_Chest_1_common_mistakes",
    breathing: "lst_Seated_Knee_To_Chest_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Seated Straddle",
    is_free: false,
    muscle_category: ["legs"],
    primary_muscle: "Adductors, Hip Flexors, Glutes",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSeated%20Straddle-0.jpg?alt=media&token=31b4f796-6f72-4db2-9d0f-1d455a85ea94",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FSeated%20Straddle.mp4?alt=media&token=907cf03d-b242-4a60-975b-25201df180be",
    video_id: "lst_Seated_Straddle_1",
    video_length: 0.07,
    video_number: "675",
    size: 1776270,
    hints: "lst_Seated_Straddle_1_hints",
    common_mistakes: "lst_Seated_Straddle_1_common_mistakes",
    breathing: "lst_Seated_Straddle_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Side Lunge Stretch",
    is_free: true,
    muscle_category: ["legs"],
    primary_muscle: "Adductors, Hip Flexors",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSide%20Lunge%20Stretch-0.jpg?alt=media&token=c4452583-8e9e-4dd4-a9c4-b6d44507263f",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FSide%20Lunge%20Stretch.mp4?alt=media&token=3de7506b-5fb7-4785-88af-202447167fe3",
    video_id: "lst_Side_Lunge_Stretch_1",
    video_length: 0.07,
    video_number: "676",
    size: 4181143,
    hints: "lst_Side_Lunge_Stretch_1_hints",
    common_mistakes: "lst_Side_Lunge_Stretch_1_common_mistakes",
    breathing: "lst_Side_Lunge_Stretch_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Side To Side Squat Pose",
    is_free: true,
    muscle_category: ["legs"],
    primary_muscle: "Hip Flexors, Adductors",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSide%20To%20Side%20Squat%20Pose-0.jpg?alt=media&token=7d640bf6-c6a1-4f24-8e2b-cdcd54f7f579",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FSide%20To%20Side%20Squat%20Pose.mp4?alt=media&token=76752980-27f2-4298-bf63-de99b9888f57",
    video_id: "lst_Side_To_Side_Squat_Pose_1",
    video_length: 0.07,
    video_number: "677",
    size: 1460676,
    hints: "lst_Side_To_Side_Squat_Pose_1_hints",
    common_mistakes: "lst_Side_To_Side_Squat_Pose_1_common_mistakes",
    breathing: "lst_Side_To_Side_Squat_Pose_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Single Leg Hamstring",
    is_free: false,
    muscle_category: ["legs"],
    primary_muscle: "Hamstrings, Hip Flexors",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSingle%20Leg%20Hamstring-0.jpg?alt=media&token=05435445-8123-4c05-921d-dba80a282ad6",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FSingle%20Leg%20Hamstring.mp4?alt=media&token=0b6ad5b2-f52c-4ee5-9c26-0414c4aebb0c",
    video_id: "lst_Single_Leg_Hamstring_1",
    video_length: 0.07,
    video_number: "678",
    size: 5023675,
    hints: "lst_Single_Leg_Hamstring_1_hints",
    common_mistakes: "lst_Single_Leg_Hamstring_1_common_mistakes",
    breathing: "lst_Single_Leg_Hamstring_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Squat Pose",
    is_free: false,
    muscle_category: ["legs"],
    primary_muscle: "Hip Flexors, Adductors",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSquat%20Pose-0.jpg?alt=media&token=0a06d53d-f595-4728-9498-83809c422234",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FSquat%20Pose.mp4?alt=media&token=bfb44f77-c5c3-4cfc-b44c-2a8acba8f431",
    video_id: "lst_Squat_Pose_1",
    video_length: 0.07,
    video_number: "679",
    size: 953103,
    hints: "lst_Squat_Pose_1_hints",
    common_mistakes: "lst_Squat_Pose_1_common_mistakes",
    breathing: "lst_Squat_Pose_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Standing Feet Together Reach",
    is_free: true,
    muscle_category: ["legs"],
    primary_muscle: "Hamstrings",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FStanding%20Feet%20Together%20Reach-0.jpg?alt=media&token=d009e22e-314d-4679-8ec3-8018ebd8772c",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FStanding%20Feet%20Together%20Reach.mp4?alt=media&token=4314f35a-7998-484b-9f35-2a01975da663",
    video_id: "lst_Standing_Feet_Together_Reach_1",
    video_length: 0.07,
    video_number: "680",
    size: 3211716,
    hints: "lst_Standing_Feet_Together_Reach_1_hints",
    common_mistakes: "lst_Standing_Feet_Together_Reach_1_common_mistakes",
    breathing: "lst_Standing_Feet_Together_Reach_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Standing Legs Spread Middle",
    is_free: false,
    muscle_category: ["legs"],
    primary_muscle: "Hamstrings",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FStanding%20Legs%20Spread%20Middle%20-0.jpg?alt=media&token=1c952ead-31c7-4488-a20c-d609375b2cce",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FStanding%20Legs%20Spread%20Middle%20.mp4?alt=media&token=9db55707-f385-4621-a787-b6223753c4e8",
    video_id: "lst_Standing_Legs_Spread_Middle_1",
    video_length: 0.07,
    video_number: "681",
    size: 3785720,
    hints: "lst_Standing_Legs_Spread_Middle_1_hints",
    common_mistakes: "lst_Standing_Legs_Spread_Middle_1_common_mistakes",
    breathing: "lst_Standing_Legs_Spread_Middle_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Straddle",
    is_free: false,
    muscle_category: ["legs"],
    primary_muscle: "Hip Flexors, Adductors",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FStraddle-0.jpg?alt=media&token=ab4ddd1e-c8d2-4255-99bc-78cdf22316a3",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FStraddle.mp4?alt=media&token=a4b28ae3-9e2c-4673-b986-1e7e6f189ba7",
    video_id: "lst_Straddle_1",
    video_length: 0.07,
    video_number: "682",
    size: 2400501,
    hints: "lst_Straddle_1_hints",
    common_mistakes: "lst_Straddle_1_common_mistakes",
    breathing: "lst_Straddle_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Straddle Alternating Toe Hold",
    is_free: false,
    muscle_category: ["legs"],
    primary_muscle: "Hip Flexors, Hamstrings, Adductors",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FStraddle%20Alternating%20Toe%20Hold-0.jpg?alt=media&token=8a7351de-2a4f-4b28-a7da-0bc1ec628621",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FStraddle%20Alternating%20Toe%20Hold.mp4?alt=media&token=99244f56-55ff-4997-82d5-28f81f11695c",
    video_id: "lst_Straddle_Alternating_Toe_Hold_1",
    video_length: 0.07,
    video_number: "683",
    size: 5699110,
    hints: "lst_Straddle_Alternating_Toe_Hold_1_hints",
    common_mistakes: "lst_Straddle_Alternating_Toe_Hold_1_common_mistakes",
    breathing: "lst_Straddle_Alternating_Toe_Hold_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Sumo Stretch",
    is_free: true,
    muscle_category: ["legs"],
    primary_muscle: "Hip Flexors, Adductors",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSumo%20Stretch-0.jpg?alt=media&token=185324f7-2b5a-4d1c-9014-c8f7dcec257d",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FSumo%20Stretch.mp4?alt=media&token=31e19eaa-e0bf-4423-968d-320c4be3e82d",
    video_id: "lst_Sumo_Stretch_1",
    video_length: 0.07,
    video_number: "684",
    size: 2384879,
    hints: "lst_Sumo_Stretch_1_hints",
    common_mistakes: "lst_Sumo_Stretch_1_common_mistakes",
    breathing: "lst_Sumo_Stretch_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Toe Pointer",
    is_free: false,
    muscle_category: ["legs"],
    primary_muscle: "Ankle, Calves",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FToe%20Pointer-0.jpg?alt=media&token=681be0b9-6b81-4f40-a19d-8b52b081c20e",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FToe%20Pointer.mp4?alt=media&token=8ef67f59-60e9-40a0-92be-753b5290b585",
    video_id: "lst_Toe_Pointer_1",
    video_length: 0.07,
    video_number: "685",
    size: 4146028,
    hints: "lst_Toe_Pointer_1_hints",
    common_mistakes: "lst_Toe_Pointer_1_common_mistakes",
    breathing: "lst_Toe_Pointer_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "Triangle Forward Bend",
    is_free: false,
    muscle_category: ["legs"],
    primary_muscle: "Hamstrings",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FTriangle%20Forward%20Bend-0.jpg?alt=media&token=91093c10-a8ad-4b57-98ae-4506626e1e13",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FTriangle%20Forward%20Bend.mp4?alt=media&token=c7b62f29-d09c-41d2-b4d2-3752f9e39f12",
    video_id: "lst_Triangle_Forward_Bend_1",
    video_length: 0.07,
    video_number: "686",
    size: 7224364,
    hints: "lst_Triangle_Forward_Bend_1_hints",
    common_mistakes: "lst_Triangle_Forward_Bend_1_common_mistakes",
    breathing: "lst_Triangle_Forward_Bend_1_breathing",
  },
  {
    category_id: 8,
    category_name: "lower body stretch",
    name: "90/90 Hip Rotation",
    is_free: false,
    muscle_category: ["legs"],
    primary_muscle: "Adductors, Glutes, Hip Flexors",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "lower body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2F90%3A90%20Hip%20Rotation-0.jpg?alt=media&token=bc45afa9-fcf4-4a2c-8c1a-2299dc73afe7",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2F90%3A90%20Hip%20Rotation.mp4?alt=media&token=a07fad8b-d470-41c0-b573-4c680e742cb6",
    video_id: "lst_90_90_Hip_Rotation_1",
    video_length: 0.07,
    video_number: "687",
    size: 2922365,
    hints: "lst_90_90_Hip_Rotation_1_hints",
    common_mistakes: "lst_90_90_Hip_Rotation_1_common_mistakes",
    breathing: "lst_90_90_Hip_Rotation_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Neck Extension Backward",
    is_free: false,
    primary_muscle: "Front Neck",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FNeck%20Extension%20Backward-0.jpg?alt=media&token=b29dd476-f179-417d-897f-1206ceb46536",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FNeck%20Extension%20Backward.mp4?alt=media&token=fd3a8afa-9783-4c7d-aaf5-9d0d33cc18cd",
    video_id: "lst_Neck_Extension_Backward_1",
    video_length: 0.07,
    video_number: "688",
    size: 1666899,
    hints: "lst_Neck_Extension_Backward_1_hints",
    common_mistakes: "lst_Neck_Extension_Backward_1_common_mistakes",
    breathing: "lst_Neck_Extension_Backward_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Neck Flex Forward",
    is_free: false,
    primary_muscle: "Back Neck",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FNeck%20Flex%20Forward-0.jpg?alt=media&token=5d771191-c3f4-46e3-9ee3-865fac95eb4e",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FNeck%20Flex%20Forward.mp4?alt=media&token=3447169d-48d2-4928-a68d-da106115c430",
    video_id: "lst_Neck_Flex_Forward_1",
    video_length: 0.07,
    video_number: "689",
    size: 1860927,
    hints: "lst_Neck_Flex_Forward_1_hints",
    common_mistakes: "lst_Neck_Flex_Forward_1_common_mistakes",
    breathing: "lst_Neck_Flex_Forward_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Neck Rotation Stretch",
    is_free: false,
    primary_muscle: "Side Neck",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FNeck%20Rotation%20Stretch-0.jpg?alt=media&token=7a25a4a5-a154-4b57-aa80-ea10d6a700fd",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FNeck%20Rotation%20Stretch.mp4?alt=media&token=855c3dfd-17ca-4484-a485-a55535543a13",
    video_id: "lst_Neck_Rotation_Stretch_1",
    video_length: 0.07,
    video_number: "690",
    size: 2108220,
    hints: "lst_Neck_Rotation_Stretch_1_hints",
    common_mistakes: "lst_Neck_Rotation_Stretch_1_common_mistakes",
    breathing: "lst_Neck_Rotation_Stretch_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Wrist Circles",
    is_free: false,
    primary_muscle: "Wrists",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FWrist%20Circles-0.jpg?alt=media&token=1bc5976a-a0fc-43e5-a1ac-d5760c3334dc",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FWrist%20Circles.mp4?alt=media&token=3a83cb44-6e4a-47f6-9a65-674242308c1a",
    video_id: "lst_Wrist_Circles_1",
    video_length: 0.07,
    video_number: "691",
    size: 4337017,
    hints: "lst_Wrist_Circles_1_hints",
    common_mistakes: "lst_Wrist_Circles_1_common_mistakes",
    breathing: "lst_Wrist_Circles_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Arms Behind Pull Apart",
    is_free: true,
    muscle_category: ["chest"],
    primary_muscle: "Chest, Front Shoulder, Serratus Anterior",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FArms%20Behind%20Pull%20Apart-0.jpg?alt=media&token=a1fb8a90-d2dc-4c33-84a4-6b469c911afd",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FArms%20Behind%20Pull%20Apart.mp4?alt=media&token=3fc6c08a-06a0-4e38-93b9-13a44508e3dd",
    video_id: "lst_Arms_Behind_Pull_Apart_1",
    video_length: 0.07,
    video_number: "692",
    size: 1360404,
    hints: "lst_Arms_Behind_Pull_Apart_1_hints",
    common_mistakes: "lst_Arms_Behind_Pull_Apart_1_common_mistakes",
    breathing: "lst_Arms_Behind_Pull_Apart_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Back Slaps",
    is_free: true,
    muscle_category: ["chest"],
    primary_muscle: "Chest, Shoulders, Rhomboids, Traps, Erector Spinae, Teres Major, Teres Minor, Infraspinatus, Serratus Anterior",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FBack%20Slaps-0.jpg?alt=media&token=a8fa9aa3-f53f-4505-bbac-a11748604518",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FBack%20Slaps.mp4?alt=media&token=f8c2fda4-0091-4905-bdb3-a9b6a32c8660",
    video_id: "lst_Back_Slaps_1",
    video_length: 0.07,
    video_number: "693",
    size: 3819241,
    hints: "lst_Back_Slaps_1_hints",
    common_mistakes: "lst_Back_Slaps_1_common_mistakes",
    breathing: "lst_Back_Slaps_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Child Pose With Pec Twist",
    is_free: false,
    muscle_category: ["chest"],
    primary_muscle: "Chest, Front Shoulder, Serratus Anterior",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FChild%20Pose%20With%20Pec%20Twist-0.jpg?alt=media&token=d4a0c539-95d4-498c-86b1-5a7dffcb54e6",
    time: "75 seconds",
    time_to_play: 1.25,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FChild%20Pose%20With%20Pec%20Twist.mp4?alt=media&token=e189c42f-cc50-4835-8a68-fe7fd4595b64",
    video_id: "lst_Child_Pose_With_Pec_Twist_1",
    video_length: 0.07,
    video_number: "694",
    size: 4391554,
    hints: "lst_Child_Pose_With_Pec_Twist_1_hints",
    common_mistakes: "lst_Child_Pose_With_Pec_Twist_1_common_mistakes",
    breathing: "lst_Child_Pose_With_Pec_Twist_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Kneeling Openers",
    is_free: false,
    muscle_category: ["chest"],
    primary_muscle: "Spinal, Chest, Shoulders, Serratus Anterior",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FKneeling%20Openers-0.jpg?alt=media&token=7021de20-89c2-433b-b1b2-d4b1782c8742",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FKneeling%20Openers.mp4?alt=media&token=9ccbf8da-a642-493e-9aab-4ffb488ed0b5",
    video_id: "lst_Kneeling_Openers_1",
    video_length: 0.07,
    video_number: "695",
    size: 3771124,
    hints: "lst_Kneeling_Openers_1_hints",
    common_mistakes: "lst_Kneeling_Openers_1_common_mistakes",
    breathing: "lst_Kneeling_Openers_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Twisted Cross",
    is_free: false,
    muscle_category: ["chest"],
    primary_muscle: "Front Shoulders, Chest, Serratus Anterior",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FTwisted%20Cross-0.jpg?alt=media&token=9a991e35-796b-4b67-97e6-6fe744842cb2",
    time: "75 seconds",
    time_to_play: 1.25,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FTwisted%20Cross.mp4?alt=media&token=9cc3568f-e56f-43b7-9bd8-4ad5e02a954d",
    video_id: "lst_Twisted_Cross_1",
    video_length: 0.07,
    video_number: "696",
    size: 6557998,
    hints: "lst_Twisted_Cross_1_hints",
    common_mistakes: "lst_Twisted_Cross_1_common_mistakes",
    breathing: "lst_Twisted_Cross_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Wall Pectoral Stretch",
    is_free: true,
    muscle_category: ["chest"],
    primary_muscle: "Front Shoulders, Chest, Serratus Anterior",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FWall%20Pectoral%20Stretch-0.jpg?alt=media&token=dedbba6b-5a2d-4bb3-879e-a2740c9b4e6c",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Wall",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FWall%20Pectoral%20Stretch.mp4?alt=media&token=3e98868b-ac0c-4134-9369-3c341b9d49b1",
    video_id: "lst_Wall_Pectoral_Stretch_1",
    video_length: 0.07,
    video_number: "697",
    size: 5116791,
    hints: "lst_Wall_Pectoral_Stretch_1_hints",
    common_mistakes: "lst_Wall_Pectoral_Stretch_1_common_mistakes",
    breathing: "lst_Wall_Pectoral_Stretch_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Y Arm Openers",
    is_free: true,
    muscle_category: ["chest"],
    primary_muscle: "Shoulders, Serratus Anterior, Chest, Latissimus Dorsi",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FY%20Arm%20Openers-0.jpg?alt=media&token=09daa8d8-f752-49ff-950a-c60c77f59359",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FY%20Arm%20Openers.mp4?alt=media&token=794e5251-898f-48ca-bacd-9bf5830bede8",
    video_id: "lst_Y_Arm_Openers_1",
    video_length: 0.07,
    video_number: "698",
    size: 2969356,
    hints: "lst_Y_Arm_Openers_1_hints",
    common_mistakes: "lst_Y_Arm_Openers_1_common_mistakes",
    breathing: "lst_Y_Arm_Openers_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Kneeling Wrist",
    is_free: false,
    muscle_category: ["biceps"],
    primary_muscle: "Wrists, Biceps",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FKneeling%20Wrist%20-0.jpg?alt=media&token=03dffff3-a4c9-4389-921e-92d35d9cff98",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FKneeling%20Wrist%20.mp4?alt=media&token=e4f9fe35-e80f-40b3-befc-f6a7af66a54f",
    video_id: "lst_Kneeling_Wrist_1",
    video_length: 0.07,
    video_number: "699",
    size: 2620610,
    hints: "lst_Kneeling_Wrist_1_hints",
    common_mistakes: "lst_Kneeling_Wrist_1_common_mistakes",
    breathing: "lst_Kneeling_Wrist_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Arms Out Fingers & Forearm Stretch",
    is_free: true,
    muscle_category: ["biceps", "forearms"],
    primary_muscle: "Forearm, Biceps",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FArms%20Out%20Fingers%20%26%20Forearm%20Stretch-0.jpg?alt=media&token=a7bb547a-59b4-4b2a-b3f4-66d7c1b71179",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FArms%20Out%20Fingers%20%26%20Forearm%20Stretch.mp4?alt=media&token=d19e5e62-0884-40ea-a837-e9e020170950",
    video_id: "lst_Arms_Out_Fingers_Forearm_Stretch_1",
    video_length: 0.07,
    video_number: "700",
    size: 1665003,
    hints: "lst_Arms_Out_Fingers_Forearm_Stretch_1_hints",
    common_mistakes: "lst_Arms_Out_Fingers_Forearm_Stretch_1_common_mistakes",
    breathing: "lst_Arms_Out_Fingers_Forearm_Stretch_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Pronated Fingers Down Pull",
    is_free: true,
    muscle_category: ["biceps"],
    primary_muscle: "Wrist, Biceps",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FPronated%20Fingers%20Down%20Pull-0.jpg?alt=media&token=4f52619f-40d6-4ce9-bf58-5480b144c22c",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FPronated%20Fingers%20Down%20Pull.mp4?alt=media&token=325cf914-3575-4167-9d03-6ffc3f93167d",
    video_id: "lst_Pronated_Fingers_Down_Pull_1",
    video_length: 0.07,
    video_number: "702",
    size: 4016746,
    hints: "lst_Pronated_Fingers_Down_Pull_1_hints",
    common_mistakes: "lst_Pronated_Fingers_Down_Pull_1_common_mistakes",
    breathing: "lst_Pronated_Fingers_Down_Pull_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Pronated Finger Up Pull",
    is_free: true,
    muscle_category: ["biceps", "forearms"],
    primary_muscle: "Wrist, Forearm",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FPronated%20Finger%20Up%20Pull-0.jpg?alt=media&token=968d9c38-cbbf-4448-85b7-8647cdaff2c6",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FPronated%20Finger%20Up%20Pull.mp4?alt=media&token=830d1b79-0d67-4044-961a-a1795675ace4",
    video_id: "lst_Pronated_Finger_Up_Pull_1",
    video_length: 0.07,
    video_number: "703",
    size: 4649237,
    hints: "lst_Pronated_Finger_Up_Pull_1_hints",
    common_mistakes: "lst_Pronated_Finger_Up_Pull_1_common_mistakes",
    breathing: "lst_Pronated_Finger_Up_Pull_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Supinated Fingers Down Pull",
    is_free: true,
    muscle_category: ["biceps", "forearms"],
    primary_muscle: "Wrist, Forearm, Biceps",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSupinated%20Fingers%20Down%20Pull-0.jpg?alt=media&token=6c463597-b734-4426-bab0-a0c2e37e3469",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FSupinated%20Fingers%20Down%20Pull.mp4?alt=media&token=66bde434-1c27-4aa1-bb27-d0ce8ad710ab",
    video_id: "lst_Supinated_Fingers_Down_Pull_1",
    video_length: 0.07,
    video_number: "704",
    size: 4220549,
    hints: "lst_Supinated_Fingers_Down_Pull_1_hints",
    common_mistakes: "lst_Supinated_Fingers_Down_Pull_1_common_mistakes",
    breathing: "lst_Supinated_Fingers_Down_Pull_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Arm Circles Backward",
    is_free: false,
    muscle_category: ["shoulders"],
    primary_muscle: "Shoulders",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FArm%20Circles%20Backward-0.jpg?alt=media&token=7f1678fd-e7a5-4e0a-953b-e59f2568fa84",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FArm%20Circles%20Backward.mp4?alt=media&token=60e67b4e-baa9-464f-82a4-22e8bae4c0c0",
    video_id: "lst_Arm_Circles_Backward_1",
    video_length: 0.07,
    video_number: "705",
    size: 930537,
    hints: "lst_Arm_Circles_Backward_1_hints",
    common_mistakes: "lst_Arm_Circles_Backward_1_common_mistakes",
    breathing: "lst_Arm_Circles_Backward_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Arm Circles Forward",
    is_free: false,
    muscle_category: ["shoulders"],
    primary_muscle: "Shoulders",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FArm%20Circles%20Forward-0.jpg?alt=media&token=138bf504-cd34-4075-8e6f-55cd91378f52",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FArm%20Circles%20Forward.mp4?alt=media&token=431ef852-3c6d-4354-814d-1ae2b062b99d",
    video_id: "lst_Arm_Circles_Forward_1",
    video_length: 0.07,
    video_number: "706",
    size: 992934,
    hints: "lst_Arm_Circles_Forward_1_hints",
    common_mistakes: "lst_Arm_Circles_Forward_1_common_mistakes",
    breathing: "lst_Arm_Circles_Forward_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Arm Downward Across",
    is_free: true,
    muscle_category: ["shoulders"],
    primary_muscle: "Middle Shoulders, Rear Shoulders",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FArm%20Downward%20Across-0.jpg?alt=media&token=68126a20-e716-4dff-a1c5-110565faf9d6",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FArm%20Downward%20Across.mp4?alt=media&token=db4b225b-80e0-4361-91c7-cee5c622912a",
    video_id: "lst_Arm_Downward_Across_1",
    video_length: 0.07,
    video_number: "707",
    size: 4913331,
    hints: "lst_Arm_Downward_Across_1_hints",
    common_mistakes: "lst_Arm_Downward_Across_1_common_mistakes",
    breathing: "lst_Arm_Downward_Across_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Arm Upward Across",
    is_free: true,
    muscle_category: ["shoulders"],
    primary_muscle: "Middle Shoulders, Rear Shoulders, Serratus Anterior",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FArm%20Upward%20Across-0.jpg?alt=media&token=f4c09137-31d4-4d28-9166-7e10eb237586",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FArm%20Upward%20Across.mp4?alt=media&token=a811c207-7151-45c6-8fce-292c31bf05ff",
    video_id: "lst_Arm_Upward_Across_1",
    video_length: 0.07,
    video_number: "708",
    size: 4222375,
    hints: "lst_Arm_Upward_Across_1_hints",
    common_mistakes: "lst_Arm_Upward_Across_1_common_mistakes",
    breathing: "lst_Arm_Upward_Across_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Back Scratch",
    is_free: true,
    muscle_category: ["shoulders"],
    primary_muscle: "Middle Shoulders, Rear Shoulders",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FBack%20Scratch%20-0.jpg?alt=media&token=f986aff4-752e-4ef1-9c96-9f128c99d6e4",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FBack%20Scratch%20.mp4?alt=media&token=21346873-3a80-4de8-a4aa-5ae6c83b3849",
    video_id: "lst_Back_Scratch_1",
    video_length: 0.07,
    video_number: "709",
    size: 3229727,
    hints: "lst_Back_Scratch_1_hints",
    common_mistakes: "lst_Back_Scratch_1_common_mistakes",
    breathing: "lst_Back_Scratch_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Body Weight Empty Can",
    is_free: true,
    muscle_category: ["shoulders"],
    primary_muscle: "Shoulders, Serratus Anterior",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FBody%20Weight%20Empty%20Can-0.jpg?alt=media&token=5d1d0441-9f71-4cdc-ad85-b96ecc54e31c",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FBody%20Weight%20Empty%20Can.mp4?alt=media&token=7cc2656b-eae9-41a3-b9e8-d612f20a1260",
    video_id: "lst_Body_Weight_Empty_Can_1",
    video_length: 0.07,
    video_number: "710",
    size: 2693142,
    hints: "lst_Body_Weight_Empty_Can_1_hints",
    common_mistakes: "lst_Body_Weight_Empty_Can_1_common_mistakes",
    breathing: "lst_Body_Weight_Empty_Can_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Hands On Back Forward Lean",
    is_free: false,
    muscle_category: ["shoulders"],
    primary_muscle: "Shoulders, Serratus Anterior",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FHands%20On%20Back%20Forward%20Lean-0.jpg?alt=media&token=63771670-ffe2-4217-b675-73af37cb2b55",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FHands%20On%20Back%20Forward%20Lean.mp4?alt=media&token=48bbb2ba-b710-46f1-bd11-73cd818a142b",
    video_id: "lst_Hands_On_Back_Forward_Lean_1",
    video_length: 0.07,
    video_number: "711",
    size: 1205150,
    hints: "lst_Hands_On_Back_Forward_Lean_1_hints",
    common_mistakes: "lst_Hands_On_Back_Forward_Lean_1_common_mistakes",
    breathing: "lst_Hands_On_Back_Forward_Lean_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Kneeling Over Arm Side Lean",
    is_free: false,
    muscle_category: ["shoulders"],
    primary_muscle: "Shoulders, Latissimus Dorsi, Spinal, Serratus Anterior",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FKneeling%20Over%20Arm%20Side%20Lean-0.jpg?alt=media&token=07c7502f-f9e0-4419-9bed-970051108a72",
    time: "75 seconds",
    time_to_play: 1.25,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FKneeling%20Over%20Arm%20Side%20Lean.mp4?alt=media&token=2f0399a4-df32-4664-81ea-8c2dfba8f09b",
    video_id: "lst_Kneeling_Over_Arm_Side_Lean_1",
    video_length: 0.07,
    video_number: "712",
    size: 5164996,
    hints: "lst_Kneeling_Over_Arm_Side_Lean_1_hints",
    common_mistakes: "lst_Kneeling_Over_Arm_Side_Lean_1_common_mistakes",
    breathing: "lst_Kneeling_Over_Arm_Side_Lean_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Palm Down Shoulder Pulse",
    is_free: false,
    muscle_category: ["shoulders"],
    primary_muscle: "Shoulders, Chest, Serratus Anterior",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FPalm%20Down%20Shoulder%20Pulse-0.jpg?alt=media&token=3ec7f9f1-ec90-4fb7-8bdb-833e3e982f63",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FPalm%20Down%20Shoulder%20Pulse.mp4?alt=media&token=2170e851-6714-4da7-b141-a6d5b5c1ca4d",
    video_id: "lst_Palm_Down_Shoulder_Pulse_1",
    video_length: 0.07,
    video_number: "713",
    size: 1474410,
    hints: "lst_Palm_Down_Shoulder_Pulse_1_hints",
    common_mistakes: "lst_Palm_Down_Shoulder_Pulse_1_common_mistakes",
    breathing: "lst_Palm_Down_Shoulder_Pulse_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Pendulum Swings",
    is_free: false,
    muscle_category: ["shoulders"],
    primary_muscle: "Shoulders",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FPendulum%20Swings-0.jpg?alt=media&token=6f7aa3b2-9cdc-4667-bcbb-84fc8b627448",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Chair",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FPendulum%20Swings.mp4?alt=media&token=ee2fe732-c0ac-4948-b11d-774b03172550",
    video_id: "lst_Pendulum_Swings_1",
    video_length: 0.07,
    video_number: "714",
    size: 3851481,
    hints: "lst_Pendulum_Swings_1_hints",
    common_mistakes: "lst_Pendulum_Swings_1_common_mistakes",
    breathing: "lst_Pendulum_Swings_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Shoulder Extension Reaches",
    is_free: false,
    muscle_category: ["shoulders"],
    primary_muscle: "Serratus Anterior, Shoulders",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FShoulder%20Extension%20Reaches-0.jpg?alt=media&token=0026a521-8e99-422b-a769-db4185e1acb9",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FShoulder%20Extension%20Reaches.mp4?alt=media&token=3e10037d-605f-4f4b-9ad1-915384dc0e57",
    video_id: "lst_Shoulder_Extension_Reaches_1",
    video_length: 0.07,
    video_number: "715",
    size: 2853655,
    hints: "lst_Shoulder_Extension_Reaches_1_hints",
    common_mistakes: "lst_Shoulder_Extension_Reaches_1_common_mistakes",
    breathing: "lst_Shoulder_Extension_Reaches_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Shoulder Roll",
    is_free: false,
    muscle_category: ["shoulders"],
    primary_muscle: "Shoulders",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FShoulder%20Roll-0.jpg?alt=media&token=f5b3fb94-0e66-4474-ba4b-806fb4661a4f",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FShoulder%20Roll.mp4?alt=media&token=72294ebe-36bc-41ef-a721-a770778a18e2",
    video_id: "lst_Shoulder_Roll_1",
    video_length: 0.07,
    video_number: "716",
    size: 2736865,
    hints: "lst_Shoulder_Roll_1_hints",
    common_mistakes: "lst_Shoulder_Roll_1_common_mistakes",
    breathing: "lst_Shoulder_Roll_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Shoulder Sawing",
    is_free: false,
    muscle_category: ["shoulders"],
    primary_muscle: "Shoulders",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FShoulder%20Sawing-0.jpg?alt=media&token=fbdfc14f-99b5-4283-ba71-2483e64e6000",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FShoulder%20Sawing.mp4?alt=media&token=6ad84702-4a6a-4946-bf42-325666134f92",
    video_id: "lst_Shoulder_Sawing_1",
    video_length: 0.07,
    video_number: "717",
    size: 926355,
    hints: "lst_Shoulder_Sawing_1_hints",
    common_mistakes: "lst_Shoulder_Sawing_1_common_mistakes",
    breathing: "lst_Shoulder_Sawing_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Cat & Cow Sequence",
    is_free: true,
    muscle_category: ["planks", "abs"],
    primary_muscle: "Spine, Core, Serratus Anterior",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FCat%20%26%20Cow%20Sequence%20-0.jpg?alt=media&token=c646edcf-07eb-46c1-b5e0-5bde82be3100",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FCat%20%26%20Cow%20Sequence%20.mp4?alt=media&token=8cb3b51c-e439-4e82-8ee4-4dee41f6b89b",
    video_id: "lst_Cat_Cow_Sequence_1",
    video_length: 0.07,
    video_number: "718",
    size: 1953154,
    hints: "lst_Cat_Cow_Sequence_1_hints",
    common_mistakes: "lst_Cat_Cow_Sequence_1_common_mistakes",
    breathing: "lst_Cat_Cow_Sequence_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Cobra Stretch",
    is_free: true,
    muscle_category: ["planks", "abs"],
    primary_muscle: "Core, Serratus Anterior",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FCobra%20Stretch-0.jpg?alt=media&token=2a0d87cb-bc1a-4828-89e0-cfc8c9e710f7",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FCobra%20Stretch.mp4?alt=media&token=43102d5c-01a1-4b89-aafc-e3571b72ad6b",
    video_id: "lst_Cobra_Stretch_1",
    video_length: 0.07,
    video_number: "719",
    size: 4484128,
    hints: "lst_Cobra_Stretch_1_hints",
    common_mistakes: "lst_Cobra_Stretch_1_common_mistakes",
    breathing: "lst_Cobra_Stretch_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Cobra Stretch Side To Side",
    is_free: true,
    muscle_category: ["planks", "abs"],
    primary_muscle: "Core, Serratus Anterior",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FCobra%20Stretch%20Side%20To%20Side-0.jpg?alt=media&token=adb25728-efca-4912-8beb-71b5ea510e2b",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FCobra%20Stretch%20Side%20To%20Side.mp4?alt=media&token=a23049de-931b-48e3-a64d-eb7dccc636ac",
    video_id: "lst_Cobra_Stretch_Side_To_Side_1",
    video_length: 0.07,
    video_number: "720",
    size: 2606672,
    hints: "lst_Cobra_Stretch_Side_To_Side_1_hints",
    common_mistakes: "lst_Cobra_Stretch_Side_To_Side_1_common_mistakes",
    breathing: "lst_Cobra_Stretch_Side_To_Side_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Overhead Arm Pull",
    is_free: true,
    muscle_category: ["triceps"],
    primary_muscle: "Triceps, Latissimus Dorsi, Serratus Anterior",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FOverhead%20Arm%20Pull-0.jpg?alt=media&token=6239d53d-a0fc-4062-ae4a-376465a794ac",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FOverhead%20Arm%20Pull.mp4?alt=media&token=5081c477-8adb-418d-8616-cb17fbc92184",
    video_id: "lst_Overhead_Arm_Pull_1",
    video_length: 0.07,
    video_number: "721",
    size: 4607935,
    hints: "lst_Overhead_Arm_Pull_1_hints",
    common_mistakes: "lst_Overhead_Arm_Pull_1_common_mistakes",
    breathing: "lst_Overhead_Arm_Pull_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Lying Arm Lean Back",
    is_free: false,
    muscle_category: ["triceps"],
    primary_muscle: "Latissimus Dorsi, Serratus Anterior, Triceps",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FLying%20Arm%20Lean%20Back-0.jpg?alt=media&token=0c77ea1e-143d-4574-b01a-48474e22a189",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FLying%20Arm%20Lean%20Back.mp4?alt=media&token=f07e74d5-dfd9-43ec-b5c2-093ef7d319bd",
    video_id: "lst_Lying_Arm_Lean_Back_1",
    video_length: 0.07,
    video_number: "722",
    size: 4002527,
    hints: "lst_Lying_Arm_Lean_Back_1_hints",
    common_mistakes: "lst_Lying_Arm_Lean_Back_1_common_mistakes",
    breathing: "lst_Lying_Arm_Lean_Back_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Standing Arm Pull Lean",
    is_free: true,
    muscle_category: ["triceps"],
    primary_muscle: "Latissimus Dorsi, Serratus Anterior, Triceps",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FStanding%20Arm%20Pull%20Lean-0.jpg?alt=media&token=86f88e3a-2be4-444e-8024-f39809060453",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FStanding%20Arm%20Pull%20Lean.mp4?alt=media&token=5d339815-f6c2-4d7b-ad0f-d2b3994072a9",
    video_id: "lst_Standing_Arm_Pull_Lean_1",
    video_length: 0.07,
    video_number: "723",
    size: 4454463,
    hints: "lst_Standing_Arm_Pull_Lean_1_hints",
    common_mistakes: "lst_Standing_Arm_Pull_Lean_1_common_mistakes",
    breathing: "lst_Standing_Arm_Pull_Lean_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Side Bend Stretch",
    is_free: true,
    muscle_category: ["triceps"],
    primary_muscle: "Latissimus Dorsi, Serratus Anterior, Triceps",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSide%20Bend%20Stretch-0.jpg?alt=media&token=f436c220-8464-46a7-b468-259b5b1ca1b0",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FSide%20Bend%20Stretch.mp4?alt=media&token=b3753c42-dcab-4df7-a3df-8c3427d06821",
    video_id: "lst_Side_Bend_Stretch_1",
    video_length: 0.07,
    video_number: "724",
    size: 3473419,
    hints: "lst_Side_Bend_Stretch_1_hints",
    common_mistakes: "lst_Side_Bend_Stretch_1_common_mistakes",
    breathing: "lst_Side_Bend_Stretch_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Reach Across",
    is_free: true,
    muscle_category: ["triceps"],
    primary_muscle: "Middle Shoulders, Rear Shoulders, Serratus Anterior, Triceps",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FReach%20Across-0.jpg?alt=media&token=a65e8224-6246-48c5-87c8-4b8b04b7a5c5",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FReach%20Across.mp4?alt=media&token=83c27225-7d99-43b7-89fd-d3b15872cc09",
    video_id: "lst_Reach_Across_1",
    video_length: 0.07,
    video_number: "725",
    size: 4163510,
    hints: "lst_Reach_Across_1_hints",
    common_mistakes: "lst_Reach_Across_1_common_mistakes",
    breathing: "lst_Reach_Across_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Across Side Reach",
    is_free: false,
    muscle_category: ["back"],
    primary_muscle: "Latissimus Dorsi, Serratus Anterior",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FAcross%20Side%20Reach-0.jpg?alt=media&token=ffcc2b75-3c69-46db-8c2f-3ff574018a95",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FAcross%20Side%20Reach.mp4?alt=media&token=a63aeeda-29e2-4d3d-b0e6-5450a20e5241",
    video_id: "lst_Across_Side_Reach_1",
    video_length: 0.07,
    video_number: "726",
    size: 5845179,
    hints: "lst_Across_Side_Reach_1_hints",
    common_mistakes: "lst_Across_Side_Reach_1_common_mistakes",
    breathing: "lst_Across_Side_Reach_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Arms Behind Head Push",
    is_free: false,
    muscle_category: ["back"],
    primary_muscle: "Traps, Serratus Anterior",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FArms%20Behind%20Head%20Push-0.jpg?alt=media&token=c53a5cb7-e809-4c7b-9d85-216eaead74e1",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FArms%20Behind%20Head%20Push.mp4?alt=media&token=0f010051-7430-45eb-90a1-99d0f3af30d6",
    video_id: "lst_Arms_Behind_Head_Push_1",
    video_length: 0.07,
    video_number: "727",
    size: 1377127,
    hints: "lst_Arms_Behind_Head_Push_1_hints",
    common_mistakes: "lst_Arms_Behind_Head_Push_1_common_mistakes",
    breathing: "lst_Arms_Behind_Head_Push_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Around The World",
    is_free: false,
    muscle_category: ["back"],
    primary_muscle: "Spine",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FAround%20The%20World-0.jpg?alt=media&token=02c05e35-1939-4257-85bb-f56c7e5cd9e5",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FAround%20The%20World.mp4?alt=media&token=2b77f12f-4be9-41de-aa65-d8385c1c656e",
    video_id: "lst_Around_The_World_1",
    video_length: 0.07,
    video_number: "728",
    size: 3525195,
    hints: "lst_Around_The_World_1_hints",
    common_mistakes: "lst_Around_The_World_1_common_mistakes",
    breathing: "lst_Around_The_World_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Child Pose With Lat Focus",
    is_free: false,
    muscle_category: ["back"],
    primary_muscle: "Latissimus Dorsi, Serratus Anterior",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FChild%20Pose%20With%20Lat%20Focus-0.jpg?alt=media&token=9609c7d6-5f8a-4d4e-b185-5cffaa54d94b",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FChild%20Pose%20With%20Lat%20Focus.mp4?alt=media&token=d2390c7f-38f3-4ec4-9b07-44eea57278f9",
    video_id: "lst_Child_Pose_With_Lat_Focus_1",
    video_length: 0.07,
    video_number: "729",
    size: 743569,
    hints: "lst_Child_Pose_With_Lat_Focus_1_hints",
    common_mistakes: "lst_Child_Pose_With_Lat_Focus_1_common_mistakes",
    breathing: "lst_Child_Pose_With_Lat_Focus_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Forward Head Roll",
    is_free: true,
    muscle_category: ["back"],
    primary_muscle: "Traps",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FForward%20Head%20Roll-0.jpg?alt=media&token=22d8e332-3721-4105-8bb5-fec6bd278b72",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FForward%20Head%20Roll.mp4?alt=media&token=e21b1d1f-3b56-4962-a1e3-080cb8357db4",
    video_id: "lst_Forward_Head_Roll_1",
    video_length: 0.07,
    video_number: "730",
    size: 2489614,
    hints: "lst_Forward_Head_Roll_1_hints",
    common_mistakes: "lst_Forward_Head_Roll_1_common_mistakes",
    breathing: "lst_Forward_Head_Roll_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Head Tilt",
    is_free: false,
    muscle_category: ["back"],
    primary_muscle: "Traps",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FHead%20Tilt-0.jpg?alt=media&token=7c5e9a8b-f753-450a-900d-825fd709701a",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FHead%20Tilt.mp4?alt=media&token=337545b7-f0c0-4ace-9ce5-9a9b84b9967c",
    video_id: "lst_Head_Tilt_1",
    video_length: 0.07,
    video_number: "731",
    size: 1469125,
    hints: "lst_Head_Tilt_1_hints",
    common_mistakes: "lst_Head_Tilt_1_common_mistakes",
    breathing: "lst_Head_Tilt_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Kneeling Back Stretch",
    is_free: false,
    muscle_category: ["back"],
    primary_muscle: "Spinal, Hip Flexors, Serratus Anterior",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FKneeling%20Back%20Stretch-0.jpg?alt=media&token=b6135eb7-a59d-493e-86af-77f1f961b3c5",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FKneeling%20Back%20Stretch.mp4?alt=media&token=395a39df-ba15-42bc-819d-c6d395bf4816",
    video_id: "lst_Kneeling_Back_Stretch_1",
    video_length: 0.07,
    video_number: "732",
    size: 6135262,
    hints: "lst_Kneeling_Back_Stretch_1_hints",
    common_mistakes: "lst_Kneeling_Back_Stretch_1_common_mistakes",
    breathing: "lst_Kneeling_Back_Stretch_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Lower Back Stretch",
    is_free: false,
    muscle_category: ["back"],
    primary_muscle: "Lower Back, Spinal",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FLower%20Back%20Stretch-0.jpg?alt=media&token=90b93994-d6cc-46b0-8395-80b9df5a8f6b",
    time: "75 seconds",
    time_to_play: 1.25,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FLower%20Back%20Stretch.mp4?alt=media&token=1b772003-51d6-4258-a534-8345f2fe18bd",
    video_id: "lst_Lower_Back_Stretch_1",
    video_length: 0.07,
    video_number: "733",
    size: 6879674,
    hints: "lst_Lower_Back_Stretch_1_hints",
    common_mistakes: "lst_Lower_Back_Stretch_1_common_mistakes",
    breathing: "lst_Lower_Back_Stretch_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Overhead Stretch",
    is_free: false,
    muscle_category: ["back"],
    primary_muscle: "Latissimus Dorsi, Serratus Anterior",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FOverhead%20Stretch-0.jpg?alt=media&token=855cdf1c-5941-463d-961a-101b01ab146e",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Wall",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FOverhead%20Stretch.mp4?alt=media&token=01831b34-40f2-4620-a127-3c08fc49b2da",
    video_id: "lst_Overhead_Stretch_1",
    video_length: 0.07,
    video_number: "734",
    size: 1731854,
    hints: "lst_Overhead_Stretch_1_hints",
    common_mistakes: "lst_Overhead_Stretch_1_common_mistakes",
    breathing: "lst_Overhead_Stretch_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Side Bend",
    is_free: true,
    muscle_category: ["back"],
    primary_muscle: "Latissimus Dorsi, Serratus Anterior",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSide%20Bend-0.jpg?alt=media&token=27771690-647e-48a0-b42e-19e537ea4aba",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FSide%20Bend.mp4?alt=media&token=f6e6788d-2ab1-44cf-82ef-de4e03672d8c",
    video_id: "lst_Side_Bend_1",
    video_length: 0.07,
    video_number: "735",
    size: 4757046,
    hints: "lst_Side_Bend_1_hints",
    common_mistakes: "lst_Side_Bend_1_common_mistakes",
    breathing: "lst_Side_Bend_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Spinal Twist Stretch",
    is_free: false,
    muscle_category: ["back"],
    primary_muscle: "Spinal",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSpinal%20Twist%20Stretch-0.jpg?alt=media&token=5665899a-aeb2-4933-9263-35d4e9138646",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FSpinal%20Twist%20Stretch.mp4?alt=media&token=a250f297-b3b9-44cc-87ee-7b68811bce7d",
    video_id: "lst_Spinal_Twist_Stretch_1",
    video_length: 0.07,
    video_number: "736",
    size: 4931637,
    hints: "lst_Spinal_Twist_Stretch_1_hints",
    common_mistakes: "lst_Spinal_Twist_Stretch_1_common_mistakes",
    breathing: "lst_Spinal_Twist_Stretch_1_breathing",
  },
  {
    category_id: 9,
    category_name: "upper body stretch",
    name: "Torso Twist",
    is_free: true,
    muscle_category: ["back"],
    primary_muscle: "Spine",
    rep_range: "Time Based",
    secondary_muscle: "",
    subcategory_name: "upper body stretch",
    tertiary_muscle: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FTorso%20Twist-0.jpg?alt=media&token=b6e4b03a-0dc4-4c82-9e49-9287c9f062ff",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FTorso%20Twist.mp4?alt=media&token=f411b577-13af-4034-a6c9-c105e9daf122",
    video_id: "lst_Torso_Twist_1",
    video_length: 0.07,
    video_number: "737",
    size: 2611647,
    hints: "lst_Torso_Twist_1_hints",
    common_mistakes: "lst_Torso_Twist_1_common_mistakes",
    breathing: "lst_Torso_Twist_1_breathing",
  },
  {
    category_id: 4,
    category_name: "legs & back",
    name: "Sumo Squat Into Side To Side Lunge",
    is_free: true,
    primary_muscle: "Quads, Glutes, Adductors, Hip Flexors",
    rep_range: "8-20",
    secondary_muscle: "Hamstrings, Erector Spinae",
    subcategory_name: "legs",
    tertiary_muscle: "Core, Calves",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FSumo%20Squat%20Into%20Side%20To%20Side%20Lunge-0.jpg?alt=media&token=12bd9f32-1f94-4dc3-bc08-1e0f0c25feaf",
    time: "60 seconds",
    time_to_play: 1,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Flegs%2FSumo%20Squat%20Into%20Side%20To%20Side%20Lunge.mp4?alt=media&token=c43332f0-fa59-4557-be5a-a9464348eca5",
    video_id: "LnB_Sumo_Squat_Into_Side_To_Side_Lunge",
    video_length: 0.07,
    video_number: "738",
    size: 3276879,
    hints: "LnB_Sumo_Squat_Into_Side_To_Side_Lunge_hints",
    common_mistakes: "LnB_Sumo_Squat_Into_Side_To_Side_Lunge_common_mistakes",
    breathing: "LnB_Sumo_Squat_Into_Side_To_Side_Lunge_breathing",
  },
  {
    category_id: 10,
    category_name: "planks",
    name: "Plank Ups",
    is_free: true,
    primary_muscle: "Core",
    rep_range: "Time Based",
    secondary_muscle: "Lower Body, Lower Back",
    subcategory_name: "planks",
    tertiary_muscle: "Rhomboids, Latissimus Dorsi, Traps, Triceps, Shoulders",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fresize_thumbnails%2FPlank_Ups-0.jpg?alt=media&token=b9132bf7-f412-44a5-882b-32ccf3f0217a",
    time: "30 seconds",
    time_to_play: 0.5,
    tools: "No Tools",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fplank%2FPlank%20Ups.mp4?alt=media&token=932fd034-a5fd-4c5f-a5d3-66a51242c21c",
    video_id: "pl_Plank_Ups",
    video_length: 0.05,
    video_number: "739",
    size: 1670453,
    hints: "pl_Plank_Ups_hints",
    common_mistakes: "pl_Plank_Ups_common_mistakes",
    breathing: "pl_Plank_Ups_breathing",
  },
  {
    category_id: 3,
    category_name: "triceps & shoulders",
    name: "Scarecrows",
    is_free: false,
    primary_muscle: "Shoulders",
    rep_range: "Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16",
    secondary_muscle: "Serratus Anterior, Traps, Rhomboids, Infraspinatus, Teres Minor",
    subcategory_name: "shoulders",
    tertiary_muscle: "Upper Chest, Middle Chest, Latissimus Dorsi",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-th%2FTnS_Scarecrows_27.jpg?alt=media&token=7655e349-1d53-47c0-ad97-49f4bdbf5875",
    time: "60 seconds",
    time_to_play: 1,
    tools: "Dumbbells",
    url: "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FTnS_Scarecrows_27.mp4?alt=media&token=657d802d-cbc3-4614-842c-2e27253a81bc",
    video_id: "Scarecrows",
    video_length: 0.07,
    video_number: "404",
    size: 2450469,
    hints: "Scarecrows_hints",
    common_mistakes: "Scarecrows_common_mistakes",
    breathing: "Scarecrows_breathing",
  },
];

export const ALL_AUDIOS_INFO = {
  audio5: "/assets/audios/5_4_3_2_1.mp3",
  audio3: "/assets/audios/3_2_1.mp3",
  otherSide: "/assets/audios/Other_Side.mp3",
  getReady: "/assets/audios/Get_ready.mp3",
  beginIn: "/assets/audios/Begin_in.mp3",
  ninetySecondRest: "/assets/audios/90_Seconds_Rest.mp3",
  sixtySecondRest: "/assets/audios/60_Seconds_Rest.mp3",
  nextExerciseIs: "/assets/audios/Next_Exercise_is.mp3",
  nextRest: "/assets/audios/Next_Rest_T.mp3",
  greatWorkout: "/assets/audios/Great_Work.mp3",
};

export const ALL_AUDIOS_INFO_NATIVE = {
  audio5: "assets/audios/5_4_3_2_1.mp3",
  audio3: "assets/audios/3_2_1.mp3",
  otherSide: "assets/audios/Other_Side.mp3",
  getReady: "assets/audios/Get_ready.mp3",
  beginIn: "assets/audios/Begin_in.mp3",
  ninetySecondRest: "assets/audios/90_Seconds_Rest.mp3",
  sixtySecondRest: "assets/audios/60_Seconds_Rest.mp3",
  nextExerciseIs: "assets/audios/Next_Exercise_is.mp3",
  nextRest: "assets/audios/Next_Rest_T.mp3",
  greatWorkout: "assets/audios/Great_Work.mp3",
};

export const ALL_ONBOARDING_KEYS = {
  gender: OnboardingPageKeys.gender,
  // metric: OnboardingPageKeys.metric,
  desiredResults: OnboardingPageKeys.desiredResults,
  userBody: OnboardingPageKeys.userBody,
  goalBody: OnboardingPageKeys.goalBody,
  bodyFat: OnboardingPageKeys.bodyFat,

  difficulty: OnboardingPageKeys.difficulty,

  daysCount: OnboardingPageKeys.daysCount,
  equipment: OnboardingPageKeys.equipment,

  currentUserWeight: OnboardingPageKeys.currentUserWeight,
  goalWeight: OnboardingPageKeys.goalWeight,
  age: OnboardingPageKeys.age,
  height: OnboardingPageKeys.height,
  // days: OnboardingPageKeys.days,
  processPlan: OnboardingPageKeys.processPlan,
};

/*export const ALL_ONBOARDING_BGS = {
  gender: OnboardingBG.gender,
  metric: OnboardingBG.metric,
  difficulty: OnboardingBG.difficulty,
  currentUserWeight: OnboardingBG.currentUserWeight,
  goalWeight: OnboardingBG.goalWeight,
  age: OnboardingBG.age,
  height: OnboardingBG.height,
  days: OnboardingBG.days,
  daysCount: OnboardingBG.daysCount,
  desiredResults: OnboardingBG.desiredResults,
  processPlan: OnboardingBG.processPlan,
};*/

export const WorkoutDaysAndMuscles = {
  "Overall Health & Wellness": {
    1: {
      // 1 day a week, for 8 weeks
      0: ["total body"],
      1: ["cardio"],
      2: ["upper body stretch, lower body stretch"],
      3: ["total body"],
      4: ["hiit"],
      5: ["upper body stretch, lower body stretch"],
      6: ["total body"],
      7: ["hiit"],
    },
    2: {
      0: ["total body", "cardio"],
      1: ["upper body stretch, lower body stretch", "chest, biceps"],
      2: ["hiit", "triceps, shoulders"],
      3: ["upper body stretch, lower body stretch", "legs, back"],
      4: ["hiit", "total body"],
      5: ["upper body stretch, lower body stretch", "total body"],
      6: ["hiit", "total body"],
      7: ["upper body stretch, lower body stretch", "total body"],
    },
    3: {
      0: ["total body", "cardio", "upper body stretch, lower body stretch"],
      1: ["chest, biceps", "hiit", "upper body stretch"],
      2: ["triceps, shoulders", "cardio", "upper body stretch, lower body stretch"],
      3: ["legs, back", "hiit", "lower body stretch"],
      4: ["total body", "cardio", "upper body stretch, lower body stretch"],
      5: ["total body", "hiit", "upper body stretch, lower body stretch"],
      6: ["total body", "hiit", "upper body stretch, lower body stretch"],
      7: ["total body", "hiit", "upper body stretch, lower body stretch"],
    },
    4: {
      0: ["total body", "cardio", "lower body stretch", "abs"],
      1: ["chest, biceps", "upper body stretch", "triceps, shoulders", "hiit"],
      2: ["total body", "lower body stretch", "planks", "hiit"],
      3: ["legs, back", "upper body stretch", "chest, biceps", "cardio"],
      4: ["abs, planks", "total body", "upper body stretch, lower body stretch", "hiit"],
      5: ["cardio", "triceps, shoulders", "upper body stretch, lower body stretch", "hiit"],
      6: ["abs, planks", "total body", "upper body stretch, lower body stretch", "hiit"],
      7: ["cardio", "upper body stretch, lower body stretch", "total body", "hiit"],
    },
    5: {
      0: ["total body", "cardio", "lower body stretch", "abs", "hiit"],
      1: ["chest, biceps", "upper body stretch", "triceps, shoulders", "planks", "cardio"],
      2: ["total body", "cardio", "lower body stretch", "legs, back", "hiit"],
      3: ["chest, biceps", "upper body stretch", "abs", "triceps, shoulders", "cardio"],
      4: ["abs, planks", "total body", "upper body stretch, lower body stretch", "hiit", "legs, back"],
      5: ["chest, biceps", "cardio", "total body", "upper body stretch, lower body stretch", "hiit"],
      6: ["triceps, shoulders", "abs, planks", "total body", "upper body stretch, lower body stretch", "hiit"],
      7: ["legs, back", "cardio", "upper body stretch, lower body stretch", "total body", "hiit"],
    },
    6: {
      0: ["total body", "cardio", "lower body stretch", "abs", "chest, biceps", "hiit"],
      1: ["triceps, shoulders", "upper body stretch", "legs", "back", "planks", "cardio"],
      2: ["total body", "cardio", "lower body stretch", "abs", "chest, biceps", "hiit"],
      3: ["triceps, shoulders", "upper body stretch", "planks", "legs, back", "cardio", "chest, biceps"],
      4: ["abs, planks", "cardio", "total body", "upper body stretch, lower body stretch", "hiit", "triceps, shoulders"],
      5: ["legs, back", "abs, planks", "cardio", "total body", "upper body stretch, lower body stretch", "hiit"],
      6: ["chest, biceps", "cardio", "total body", "upper body stretch, lower body stretch", "hiit", "triceps, shoulders"],
      7: ["legs, back", "abs, planks", "cardio", "upper body stretch, lower body stretch", "total body", "hiit"],
    },
    7: {
      0: ["total body", "cardio", "lower body stretch", "abs", "chest", "biceps", "hiit"],
      1: ["triceps", "shoulders", "upper body stretch", "legs", "back", "planks", "cardio"],
      2: ["total body", "cardio", "lower body stretch", "abs", "chest", "biceps", "hiit"],
      3: ["triceps", "shoulders", "upper body stretch", "planks", "legs", "back", "cardio"],
      4: ["chest, biceps", "abs, planks", "cardio", "total body", "upper body stretch, lower body stretch", "hiit", "triceps, shoulders"],
      5: ["legs, back", "chest, biceps", "abs, planks", "cardio", "total body", "upper body stretch, lower body stretch", "hiit"],
      6: ["triceps, shoulders", "abs, planks", "cardio", "total body", "upper body stretch, lower body stretch", "hiit", "chest, biceps"],
      7: ["legs, back", "abs, planks", "cardio", "upper body stretch, lower body stretch", "total body", "hiit", "triceps, shoulders"],
    },
  },
  "Build Muscle": {
    1: {
      // 1 day a week, for 8 weeks
      0: ["total body"],
      1: ["chest, biceps"],
      2: ["triceps, shoulders"],
      3: ["abs, planks"],
      4: ["legs, back"],
      5: ["total body"],
      6: ["abs, planks"],
      7: ["total body"],
    },
    2: {
      0: ["total body", "abs"],
      1: ["shoulders, triceps", "legs, back"],
      2: ["chest, biceps", "abs, planks"],
      3: ["legs, shoulders", "triceps, back"],
      4: ["abs", "chest, biceps"],
      5: ["shoulders, triceps", "legs, back"],
      6: ["chest, biceps", "back, shoulders"],
      7: ["abs, planks", "total body"],
    },
    3: {
      0: ["chest, biceps", "legs, triceps", "shoulders, back"],
      1: ["abs, planks", "total body", "biceps, triceps"],
      2: ["chest, back", "legs, shoulders", "abs"],
      3: ["chest, biceps", "shoulders, triceps", "legs, back"],
      4: ["chest, biceps", "abs, planks", "legs, shoulders"],
      5: ["triceps, back", "abs", "chest, biceps"],
      6: ["shoulders, triceps", "legs, back", "chest, triceps"],
      7: ["back, shoulders", "biceps", "total body"],
    },
    4: {
      0: ["chest, biceps", "abs", "triceps, shoulders", "legs, back"],
      1: ["chest, biceps", "abs, planks", "triceps, shoulders", "legs, back"],
      2: ["chest, biceps", "legs, triceps", "shoulders, back", "abs, planks"],
      3: ["total body", "triceps, biceps", "chest, back", "legs, shoulders"],
      4: ["abs", "chest, biceps", "shoulders, triceps", "legs, back"],
      5: ["chest, biceps", "abs, planks", "legs, shoulders", "triceps, back"],
      6: ["abs", "chest, biceps", "shoulders, triceps", "legs, back"],
      7: ["chest, triceps", "back, shoulders", "biceps", "total body"],
    },
    5: {
      0: ["chest, biceps", "abs", "triceps, shoulders", "legs", "back"],
      1: ["chest, biceps", "planks", "triceps, shoulders", "legs, back", "abs"],
      2: ["chest, biceps", "shoulders", "legs, back", "triceps", "abs, planks"],
      3: ["total body", "biceps, triceps", "chest", "shoulders, back", "legs"],
      4: ["chest, biceps", "abs", "shoulders, triceps", "legs, back", "planks"],
      5: ["chest, biceps", "abs, planks", "legs, shoulders", "triceps, back", "abs"],
      6: ["chest, biceps", "abs", "shoulders, triceps", "legs, back", "planks"],
      7: ["chest", "back, shoulders", "triceps, biceps", "abs", "total body"],
    },
    6: {
      0: ["chest", "biceps", "abs", "triceps, shoulders", "legs", "back"],
      1: ["chest, biceps", "planks", "triceps", "shoulders", "abs", "legs, back"],
      2: ["chest, biceps", "abs, planks", "shoulders", "legs, back", "triceps", "abs"],
      3: ["total body", "biceps", "abs", "chest", "shoulders, triceps", "legs, back"],
      4: ["chest, biceps", "abs", "shoulders, triceps", "back", "planks", "legs"],
      5: ["chest, biceps", "abs, planks", "shoulders", "triceps, back", "abs", "legs"],
      6: ["chest, biceps", "abs", "shoulders", "triceps", "legs, back", "planks"],
      7: ["chest", "back", "shoulders", "triceps, biceps", "abs", "total body"],
    },
    7: {
      0: ["chest", "biceps", "abs", "triceps", "shoulders", "legs", "back"],
      1: ["chest, biceps", "planks", "triceps", "shoulders", "abs", "legs", "back"],
      2: ["chest, biceps", "abs, planks", "shoulders", "legs", "back", "triceps", "abs"],
      3: ["total body", "biceps", "abs", "chest", "shoulders, triceps", "legs", "back"],
      4: ["chest", "biceps", "abs", "shoulders, triceps", "back", "planks", "legs"],
      5: ["chest, biceps", "abs, planks", "shoulders", "triceps", "back", "abs", "legs"],
      6: ["chest", "biceps", "abs", "shoulders", "triceps", "legs, back", "planks"],
      7: ["chest", "back", "shoulders", "triceps", "biceps", "abs", "total body"],
    },
  },
  "Lose Weight": {
    1: {
      // 1 day a week, for 8 weeks
      0: ["cardio"],
      1: ["hiit"],
      2: ["cardio"],
      3: ["hiit"],
      4: ["cardio"],
      5: ["hiit"],
      6: ["hiit"],
      7: ["hiit"],
    },
    2: {
      0: ["cardio", "cardio"],
      1: ["hiit", "cardio"],
      2: ["hiit", "cardio"],
      3: ["hiit", "cardio"],
      4: ["hiit", "hiit"],
      5: ["cardio", "hiit"],
      6: ["hiit", "hiit"],
      7: ["hiit", "hiit"],
    },
    3: {
      0: ["cardio", "cardio", "cardio"],
      1: ["hiit", "cardio", "hiit"],
      2: ["cardio", "hiit", "cardio"],
      3: ["hiit", "cardio", "hiit"],
      4: ["cardio", "hiit", "hiit"],
      5: ["cardio", "hiit", "hiit"],
      6: ["cardio", "hiit", "hiit"],
      7: ["hiit", "hiit", "hiit"],
    },
    4: {
      0: ["cardio", "cardio", "hiit", "cardio"],
      1: ["hiit", "cardio", "hiit", "cardio"],
      2: ["hiit", "cardio", "hiit", "cardio"],
      3: ["hiit", "cardio", "hiit", "cardio"],
      4: ["hiit", "cardio", "hiit", "hiit"],
      5: ["cardio", "hiit", "hiit", "cardio"],
      6: ["hiit", "cardio", "hiit", "hiit"],
      7: ["hiit", "hiit", "hiit", "hiit"],
    },
    5: {
      0: ["cardio", "hiit", "cardio", "hiit", "cardio"],
      1: ["hiit", "cardio", "hiit", "cardio", "hiit"],
      2: ["cardio", "hiit", "cardio", "hiit", "cardio"],
      3: ["hiit", "cardio", "hiit", "cardio", "hiit"],
      4: ["cardio", "hiit", "cardio", "hiit", "hiit"],
      5: ["cardio", "hiit", "hiit", "cardio", "hiit"],
      6: ["hiit", "hiit", "cardio", "hiit", "hiit"],
      7: ["hiit", "hiit", "hiit", "hiit", "hiit"],
    },
    6: {
      0: ["cardio", "hiit", "cardio", "hiit", "cardio", "hiit"],
      1: ["cardio", "hiit", "cardio", "hiit", "cardio", "hiit"],
      2: ["cardio", "hiit", "cardio", "hiit", "cardio", "hiit"],
      3: ["cardio", "hiit", "cardio", "hiit", "cardio", "hiit"],
      4: ["cardio", "hiit", "cardio", "hiit", "hiit", "cardio"],
      5: ["hiit", "cardio", "hiit", "hiit", "cardio", "hiit"],
      6: ["hiit", "hiit", "hiit", "cardio", "hiit", "hiit"],
      7: ["hiit", "hiit", "hiit", "hiit", "hiit", "hiit"],
    },
    7: {
      0: ["cardio", "hiit", "cardio", "hiit", "cardio", "hiit", "cardio"],
      1: ["hiit", "cardio", "hiit", "cardio", "hiit", "cardio", "hiit"],
      2: ["cardio", "hiit", "cardio", "hiit", "cardio", "hiit", "cardio"],
      3: ["hiit", "cardio", "hiit", "cardio", "hiit", "cardio", "hiit"],
      4: ["cardio", "hiit", "hiit", "cardio", "hiit", "hiit", "cardio"],
      5: ["hiit", "hiit", "cardio", "hiit", "hiit", "cardio", "hiit"],
      6: ["hiit", "hiit", "hiit", "cardio", "hiit", "hiit", "hiit"],
      7: ["hiit", "hiit", "hiit", "hiit", "hiit", "hiit", "hiit"],
    },
  },
  "6 Pack": {
    1: {
      // 1 day a week, for 8 weeks
      0: ["abs"],
      1: ["cardio"],
      2: ["planks"],
      3: ["hiit"],
      4: ["abs, planks"],
      5: ["hiit"],
      6: ["abs, planks"],
      7: ["hiit"],
    },
    2: {
      0: ["abs", "cardio"],
      1: ["hiit", "cardio"],
      2: ["planks", "hiit"],
      3: ["cardio", "hiit"],
      4: ["abs", "cardio"],
      5: ["hiit", "hiit"],
      6: ["planks", "hiit"],
      7: ["hiit", "abs, planks"],
    },
    3: {
      0: ["abs", "cardio", "hiit"],
      1: ["cardio", "planks", "hiit"],
      2: ["cardio", "hiit", "abs"],
      3: ["cardio", "hiit", "cardio"],
      4: ["planks", "hiit", "cardio"],
      5: ["hiit", "abs, planks", "cardio"],
      6: ["hiit", "hiit", "abs, planks"],
      7: ["hiit", "hiit", "abs, planks"],
    },
    4: {
      0: ["abs", "cardio", "hiit", "cardio"],
      1: ["planks", "hiit", "cardio", "hiit"],
      2: ["abs", "cardio", "hiit", "cardio"],
      3: ["planks", "hiit", "cardio", "hiit"],
      4: ["abs, planks", "cardio", "hiit", "hiit"],
      5: ["abs, planks", "hiit", "cardio", "hiit"],
      6: ["abs, planks", "hiit", "hiit", "hiit"],
      7: ["abs, planks", "cardio", "hiit", "abs, planks"],
    },
    5: {
      0: ["abs", "cardio", "hiit", "cardio", "planks"],
      1: ["hiit", "cardio", "hiit", "abs", "cardio"],
      2: ["hiit", "cardio", "planks", "hiit", "cardio"],
      3: ["hiit", "abs, planks", "cardio", "hiit", "hiit"],
      4: ["abs, planks", "hiit", "cardio", "hiit", "abs, planks"],
      5: ["hiit", "hiit", "hiit", "abs, planks", "cardio"],
      6: ["hiit", "hiit", "abs, planks", "hiit", "hiit"],
      7: ["hiit", "abs, planks", "hiit", "hiit", "abs, planks"],
    },
    6: {
      0: ["abs", "cardio", "hiit", "cardio", "planks", "hiit"],
      1: ["cardio", "hiit", "abs", "cardio", "hiit", "cardio"],
      2: ["planks", "hiit", "cardio", "hiit", "abs, planks", "cardio"],
      3: ["hiit", "hiit", "abs, planks", "hiit", "cardio", "hiit"],
      4: ["abs, planks", "hiit", "hiit", "hiit", "abs, planks", "cardio"],
      5: ["hiit", "hiit", "abs, planks", "hiit", "hiit", "hiit"],
      6: ["abs, planks", "hiit", "hiit", "hiit", "abs", "hiit"],
      7: ["hiit", "hiit", "abs, planks", "hiit", "hiit", "abs, planks"],
    },
    7: {
      0: ["abs", "cardio", "hiit", "cardio", "planks", "hiit", "cardio"],
      1: ["hiit", "abs", "cardio", "hiit", "cardio", "planks", "hiit"],
      2: ["cardio", "hiit", "abs, planks", "cardio", "hiit", "cardio", "abs"],
      3: ["hiit", "hiit", "cardio", "abs, planks", "hiit", "hiit", "cardio"],
      4: ["planks", "hiit", "hiit", "hiit", "abs, planks", "cardio", "hiit"],
      5: ["hiit", "abs, planks", "hiit", "hiit", "hiit", "abs, planks", "hiit"],
      6: ["hiit", "hiit", "abs", "hiit", "hiit", "hiit", "planks"],
      7: ["hiit", "hiit", "hiit", "abs, planks", "hiit", "hiit", "abs, planks"],
    },
  },
  Flexibility: {
    1: {
      // 1 day a week, for 8 weeks
      0: ["upper body stretch"],
      1: ["lower body stretch"],
      2: ["upper body stretch, lower body stretch"],
      3: ["upper body stretch"],
      4: ["lower body stretch"],
      5: ["upper body stretch, lower body stretch"],
      6: ["upper body stretch"],
      7: ["lower body stretch"],
    },
    2: {
      0: ["upper body stretch", "lower body stretch"],
      1: ["upper body stretch", "lower body stretch"],
      2: ["upper body stretch", "lower body stretch, upper body stretch"],
      3: ["lower body stretch", "upper body stretch"],
      4: ["upper body stretch, lower body stretch", "lower body stretch"],
      5: ["upper body stretch", "lower body stretch, upper body stretch"],
      6: ["lower body stretch", "upper body stretch"],
      7: ["upper body stretch, lower body stretch", "lower body stretch"],
    },
    3: {
      0: ["upper body stretch", "lower body stretch", "upper body stretch"],
      1: ["lower body stretch", "upper body stretch", "lower body stretch"],
      2: ["upper body stretch", "lower body stretch", "upper body stretch"],
      3: ["lower body stretch", "upper body stretch", "lower body stretch"],
      4: ["upper body stretch", "upper body stretch, lower body stretch", "lower body stretch"],
      5: ["upper body stretch", "upper body stretch, lower body stretch", "lower body stretch"],
      6: ["upper body stretch", "upper body stretch, lower body stretch", "lower body stretch"],
      7: ["upper body stretch", "upper body stretch, lower body stretch", "lower body stretch"],
    },
    4: {
      0: ["upper body stretch", "lower body stretch", "upper body stretch", "lower body stretch"],
      1: ["upper body stretch", "lower body stretch", "upper body stretch", "lower body stretch"],
      2: ["upper body stretch", "lower body stretch", "upper body stretch", "lower body stretch"],
      3: ["upper body stretch", "upper body stretch, lower body stretch", "lower body stretch", "upper body stretch"],
      4: ["upper body stretch, lower body stretch", "lower body stretch", "upper body stretch", "lower body stretch, upper body stretch"],
      5: ["lower body stretch", "upper body stretch", "upper body stretch, lower body stretch", "lower body stretch"],
      6: ["upper body stretch", "upper body stretch, lower body stretch", "lower body stretch", "upper body stretch"],
      7: ["upper body stretch, lower body stretch", "lower body stretch", "lower body stretch, upper body stretch", "upper body stretch"],
    },
    5: {
      0: ["upper body stretch", "lower body stretch", "upper body stretch", "lower body stretch", "upper body stretch"],
      1: ["lower body stretch", "upper body stretch", "lower body stretch", "upper body stretch", "lower body stretch"],
      2: ["upper body stretch", "lower body stretch", "upper body stretch", "upper body stretch, lower body stretch", "lower body stretch"],
      3: [
        "upper body stretch",
        "upper body stretch, lower body stretch",
        "lower body stretch",
        "upper body stretch",
        "lower body stretch, upper body stretch",
      ],
      4: ["lower body stretch", "upper body stretch", "upper body stretch, lower body stretch", "lower body stretch", "upper body stretch"],
      5: [
        "upper body stretch, lower body stretch",
        "lower body stretch",
        "upper body stretch",
        "upper body stretch, lower body stretch",
        "lower body stretch",
      ],

      6: [
        "upper body stretch, lower body stretch",
        "lower body stretch",
        "upper body stretch, lower body stretch",
        "upper body stretch",
        "lower body stretch, upper body stretch",
      ],
      7: [
        "upper body stretch",
        "upper body stretch, lower body stretch",
        "lower body stretch",
        "lower body stretch, upper body stretch",
        "upper body stretch",
      ],
    },
    6: {
      0: [
        "upper body stretch",
        "lower body stretch",
        "upper body stretch",
        "lower body stretch",
        "upper body stretch",
        "lower body stretch",
      ],
      1: [
        "upper body stretch",
        "lower body stretch",
        "upper body stretch",
        "lower body stretch",
        "upper body stretch",
        "lower body stretch",
      ],
      2: [
        "upper body stretch",
        "upper body stretch, lower body stretch",
        "lower body stretch",
        "upper body stretch",
        "upper body stretch, lower body stretch",
        "lower body stretch",
      ],
      3: [
        "upper body stretch",
        "upper body stretch, lower body stretch",
        "lower body stretch",
        "upper body stretch",
        "upper body stretch, lower body stretch",
        "lower body stretch",
      ],
      4: [
        "upper body stretch",
        "upper body stretch, lower body stretch",
        "lower body stretch",
        "upper body stretch",
        "upper body stretch, lower body stretch",
        "lower body stretch",
      ],
      5: [
        "lower body stretch, upper body stretch",
        "upper body stretch",
        "upper body stretch, lower body stretch",
        "lower body stretch",
        "lower body stretch, upper body stretch",
        "upper body stretch",
      ],

      6: [
        "lower body stretch, upper body stretch",
        "lower body stretch",
        "upper body stretch, lower body stretch",
        "upper body stretch",
        "lower body stretch, upper body stretch",
        "lower body stretch",
      ],
      7: [
        "upper body stretch, lower body stretch",
        "lower body stretch, upper body stretch",
        "upper body stretch",
        "lower body stretch, upper body stretch",
        "upper body stretch, lower body stretch",
        "lower body stretch",
      ],
    },
    7: {
      0: [
        "upper body stretch",
        "lower body stretch",
        "upper body stretch",
        "lower body stretch",
        "upper body stretch",
        "lower body stretch",
        "upper body stretch",
      ],
      1: [
        "lower body stretch",
        "upper body stretch",
        "lower body stretch",
        "upper body stretch",
        "lower body stretch",
        "upper body stretch",
        "lower body stretch, upper body stretch",
      ],
      2: [
        "lower body stretch",
        "upper body stretch",
        "upper body stretch, lower body stretch",
        "lower body stretch",
        "upper body stretch",
        "lower body stretch, upper body stretch",
        "lower body stretch",
      ],
      3: [
        "upper body stretch",
        "upper body stretch, lower body stretch",
        "lower body stretch",
        "upper body stretch",
        "lower body stretch, upper body stretch",
        "lower body stretch",
        "upper body stretch",
      ],
      4: [
        "lower body stretch, upper body stretch",
        "upper body stretch",
        "upper body stretch, lower body stretch",
        "lower body stretch",
        "lower body stretch, upper body stretch",
        "upper body stretch",
        "upper body stretch, lower body stretch",
      ],
      5: [
        "upper body stretch",
        "lower body stretch, upper body stretch",
        "lower body stretch",
        "upper body stretch, lower body stretch",
        "upper body stretch",
        "lower body stretch, upper body stretch",
        "lower body stretch",
      ],
      6: [
        "lower body stretch, upper body stretch",
        "upper body stretch, lower body stretch",
        "upper body stretch",
        "lower body stretch, upper body stretch",
        "upper body stretch, lower body stretch",
        "lower body stretch",
        "upper body stretch, lower body stretch",
      ],
      7: [
        "lower body stretch, upper body stretch",
        "upper body stretch",
        "lower body stretch, upper body stretch",
        "upper body stretch, lower body stretch",
        "lower body stretch",
        "upper body stretch, lower body stretch",
        "lower body stretch, upper body stretch",
      ],
    },
  },
};

export const SALE_OPTIONS = [
  {
    title: "Never Do The Same Workout Twice",
    description:
      "Mathematically, you have a better chance of getting struck by lightning than to ever repeat the same workout twice in the same order. Let's take our chest category alone for an example we have 81 total chest workouts, and on beginner level you will do 5 workouts. Permutation of 81 exercises while performing 5 at a time is equal to 81x80x79x78x77= 3,074,591,520 unique ways. (This claim holds true for all categories besides Cardio & HIIT)",
    icon: "/assets/sales/never_same.svg",
  },
  {
    title: "Hide Ads",
    description: "Enjoy a completely ad-free experience",
    icon: "/assets/sales/hide_ad.svg",
  },
];

export const CHOOSE_PLANS = {
  "Overall Health & Wellness": "Get Unlimited Access To Your Health & Wellness Plan!",
  "Build Muscle": "Get Unlimited Access To Your Muscle Building Plan!",
  "Lose Weight": "Get Unlimited Access To Your Weight Loss Plan!",
  "6 Pack": "Get Unlimited Access To Your 6 Pack Plan!",
  Flexibility: "Get Unlimited Access To Your Flexibility Plan!",
};

export const WEB_CHOOSE_PLANS = {
  "Overall Health & Wellness": "get_access_health_wellness_mobile",
  "Build Muscle": "get_access_muscle_building_mobile",
  "Lose Weight": "get_access_weight_loss_mobile",
  "6 Pack": "get_access_six_pack_mobile",
  Flexibility: "get_access_flexibility_mobile",
};

export const SALES_VIDEO_JOE = //"KbVYgSTBfCI"; //"assets/sales/videos/overall_wellness_flexibility.mp4";
  // "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fsales%2Foverall_wellness_flexibility.mp4?alt=media&token=14a01268-9056-4627-8d11-d386a4cab8c3";
  "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fsales%2Foverall_health_flexibility_new.mp4?alt=media&token=2b023135-1bff-48c9-ae7b-0ee4c541622c";
export const SALES_VIDEO_THUMBNAIL_JOE = "assets/images/sales_video_thumbnail_joe.jpg";

export const SALES_VIDEO_GAVIN = //"B9sWaG3SJBw"; // "assets/sales/videos/build_muscle.mp4";
  // "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fsales%2Fbuild_muscle.mp4?alt=media&token=820bc8d5-fa50-4929-a0ec-32e8ce3393ab";
  "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fsales%2Fbuild_muscle_new.mp4?alt=media&token=e0476d73-0579-4071-b0ff-2b8dbac08d2f";
export const SALES_VIDEO_THUMBNAIL_GAVIN = "assets/images/sales_video_thumbnail_gavin.jpg";

export const SALES_VIDEO_ANURAG = //"jihTewz7sQs"; //"assets/sales/videos/weight_loss_six_pack.mp4";
  // "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fsales%2Fweight_loss_six_pack.mp4?alt=media&token=373b40be-76aa-4783-b526-d77638c9b34b";
  "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fsales%2Fweight_loss_six_pack_new.mp4?alt=media&token=b3d84002-a794-4ef2-8342-460f2b7235a2";
export const SALES_VIDEO_THUMBNAIL_ANURAG = "assets/images/sales_video_thumbnail_anurag.jpg";

export const OLD_SUB_OPTIONS: string[] = [
  "com.tv.halfyearlynewwithad",

  "com.tv.yearlynew",
  "com.tv.halfyearlynew",
  "com.tv.permonthnew",
  "com.tv.yearlynewwithad",
  "com.tv.permonthnewwithad",
  "com.tv.1monthad",
  "com.tv.onemonthads",
  "com.tv.year",
  "com.tv.halfyear",
  "com.tv.monthnoads",
  "com.tv.yearads",
  "com.tv.halfyearads",
  "com.tv.monthads",
  "com.tv.yearlynoads",
  "com.tv.halfyearlynoads",
  "com.tv.monthlynoads",
];

export const SALE_72_SUB_OPTION_NO_ADS: SubOption = {
  time: "12",
  subPeriod: "yearly",
  period: "months",
  name: AndroidSubscriptionProducts["com.tv.yearlynoads72"],
  finalCost: "2.49",
  //finalCost: "2.50",
  cost: "29.99",
  //cost:"29.99",
  discount: 72,
  label: "Limited time offer",
  checked: false,
};

// export const SALE_85_SUB_OPTION_NO_ADS: SubOption = {
//   time: "1",
//   subPeriod: "monthly",
//   period: "month",
//   name: AndroidSubscriptionProducts["com.tv.onemonth"],
//   finalCost: "2.99",
//   cost: "2.99",
//   discount: 85,
//   label: "Limited time offer",
//   checked: false,
// };

export const SUB_OPTIONS_NO_ADS: SubOption[] = [
  {
    checked: false,
    label: "BEST VALUE",
    name: AndroidSubscriptionProducts["com.tv.12monthlb"],
    iosName: "com.tv.12monthlifebuddy",
    period: "months",
    time: "12",
    subPeriod: "yearly",
    finalCost: "4.17",
    cost: "49.99",
    discount: 58,
    currencyCode: "USD",
    pricingTable: yearly,

    //name: AndroidSubscriptionProducts["com.tv.year.lowprice"],
    //finalCost: "2.50",
    //cost:"29.99",
  },
  {
    time: "6",
    subPeriod: "monthly",
    period: "months",
    name: AndroidSubscriptionProducts["com.tv.6monthlb"],
    iosName: "com.tv.6monthlifebuddy",
    label: "MOST POPULAR",
    checked: true,
    finalCost: "5.83",
    cost: "34.99",
    discount: 42,
    currencyCode: "USD",
    pricingTable: sixmonthly,

    //time: "3",
    //name: AndroidSubscriptionProducts["com.tv.threemonthlynoads.lowprice"],
    //finalCost: 7.66,
  },
  {
    time: "1",
    subPeriod: "monthly",
    period: "month",
    name: AndroidSubscriptionProducts["com.tv.monthlb"],
    iosName: "com.tv.monthlifebuddy",
    checked: false,
    finalCost: "",
    cost: "9.99",
    discount: 0,
    currencyCode: "USD",
    pricingTable: monthly,
    //name: AndroidSubscriptionProducts["com.tv.month.lowprice"],
    //cost: "12.99",
  },
];

export const SUB_OPTIONS_WITH_ADS: SubOption[] = [
  {
    time: "12",
    subPeriod: "yearly",
    period: "months",
    name: AndroidSubscriptionProducts["com.tv.yearlyads"],
    //name: AndroidSubscriptionProducts["com.tv.yearlyads.lowprice"],
    //finalCost: "2.25",
    finalCost: "4.66",
    cost: "55.99",
    //cost: "26.99",
    discount: 53,
    //discount: 77,
    label: "BEST VALUE",
    checked: false,
  },
  {
    //time: "3",
    time: "6",
    subPeriod: "monthly",
    period: "months",
    name: AndroidSubscriptionProducts["com.tv.halfyearlyads"],
    //name: AndroidSubscriptionProducts["com.tv.threemonthlywithads.lowprice"],
    /*finalCost: "6.33",
    cost: "18.99",
    discount: 36,*/
    finalCost: "5.99",
    cost: "35.99",
    discount: 40,
    label: "MOST POPULAR",
    checked: true,
  },
  {
    time: "1",
    subPeriod: "monthly",
    period: "month",
    name: AndroidSubscriptionProducts["com.tv.monthads"],
    finalCost: "",
    cost: "9.99",
    discount: 0,
    checked: false,
  },
];
export const userDesiredResultsData = [
  {
    title: "Build Muscle",
    description: "highly_efficiently_build_muscle",
    id: 1,
    thumbnail: SALES_VIDEO_THUMBNAIL_GAVIN,
    salesVideo: SALES_VIDEO_GAVIN,
    videoTitle: "Gavin Gained 16 Pounds",
    videoDescription: "In just 112 days, our muscle-building program helped Gavin gain an impressive 16 pounds.",
    // goalVideo: "assets/goals/goal_videos/buildmuscle.mp4",
    goalVideo:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fvideos%2FCnB_Military_Push_Ups_10.mp4?alt=media&token=ed0c92e6-6120-4474-ae3d-ed3c6d67eb3e",
    usersJoined: "1.7M+ users joined",

    avatars: ["avatar4.jpg", "avatar5.jpg", "avatar6.jpg"],
    goalThumbnail: "assets/goals/thumbnails/build_muscle.jpg",
  },
  {
    title: "Overall Health & Wellness",
    description: "gain_strength",
    id: 0,
    thumbnail: SALES_VIDEO_THUMBNAIL_JOE,
    salesVideo: SALES_VIDEO_JOE,
    videoTitle: "Joe's Insane Success Story",
    videoDescription:
      "In just 56 days, Joe achieved incredible muscle growth, lost weight, improved his flexibility, and enhanced his cardiovascular health using our wellness program.",
    // goalVideo: "https://drive.google.com/uc?export=download&id=1dSINIC2nH4C_H3hUCrt2czECXSdjoLYK", //"assets/goals/goal_videos/overallhealthwellness.mp4",
    goalVideo:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Ftotal-body%2FHigh%20Pull%20Lateral%20Raise%20From%20Floor.mp4?alt=media&token=c125895b-b72e-4c18-b7f2-238a9cc6402a",
    usersJoined: "1M+ users joined",
    avatars: ["avatar1.jpg", "avatar2.jpg", "avatar3.jpg"],
    goalThumbnail: "assets/goals/thumbnails/overall_health_wellness.jpg",
  },
  {
    title: "Lose Weight",
    description: "achieve_your_weight_loss_goals",
    id: 2,
    thumbnail: SALES_VIDEO_THUMBNAIL_ANURAG,
    salesVideo: SALES_VIDEO_ANURAG,
    videoTitle: "Anurag Lost 26.5 Pounds",
    videoDescription: "In just 112 days, our weight loss program helped Anurag lose an impressive 26.5 pounds.",
    // goalVideo: "assets/goals/goal_videos/loseweight.mp4",
    goalVideo:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fregular-cardio%2FMarch%20%26%20Clap.mp4?alt=media&token=18943e19-6a4d-4726-a1d3-15c971aebf37",
    usersJoined: "700K+ users joined",
    avatars: ["avatar7.jpg", "avatar8.jpg", "avatar9.jpg"],
    goalThumbnail: "assets/goals/thumbnails/lose_weight.jpg",
  },
  {
    title: "6 Pack",
    description: "to_maximize_your_6_pack_results",
    id: 3,
    thumbnail: SALES_VIDEO_THUMBNAIL_ANURAG,
    salesVideo: SALES_VIDEO_ANURAG,
    videoTitle: "Anurag Lost 26.5 Pounds",
    videoDescription:
      "In merely 112 days, Anurag shed an astonishing 26.5 pounds and sculpted a remarkable six-pack, all thanks to our specialized program.",
    // goalVideo: "assets/goals/goal_videos/6pack.mp4",
    goalVideo:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fabs%2FThe%20Kobe%20Crunch.mp4?alt=media&token=c65da558-9ac2-42af-8304-94774a2a9f32",
    usersJoined: "230K+ users joined",
    avatars: ["avatar10.jpg", "avatar11.jpg", "avatar12.jpg"],
    goalThumbnail: "assets/goals/thumbnails/6_pack.jpg",
  },
  {
    title: "Flexibility",
    description: "improving_your_flexibility_has_numerous_benefits",
    id: 4,
    thumbnail: SALES_VIDEO_THUMBNAIL_JOE,
    salesVideo: SALES_VIDEO_JOE,
    videoTitle: "Joe's Insane Success Story",
    videoDescription:
      "In just 56 days, Joe significantly increased his flexibility, which strengthened his muscles and joints, while also helping to alleviate stress and tension.",
    // goalVideo: "assets/goals/goal_videos/flexibility.mp4",
    goalVideo:
      "https://firebasestorage.googleapis.com/v0/b/lifebuddy-3a29a.appspot.com/o/assets%2Fnew-workouts%2Fstretching%2FDeep%20Lunge.mp4?alt=media&token=228042df-3888-4217-9ebf-ce79a0248232",
    usersJoined: "70K+ users joined",
    avatars: ["avatar13.jpg", "avatar14.jpg", "avatar15.jpg"],
    goalThumbnail: "assets/goals/thumbnails/flexibility.jpg",
  },
];

export const RECCOMMENDED_ITEMS: RecItem[] = [
  {
    id: "homeworkout",
    icon: "assets/home-workout-icon.png",
    title: "Home Workout",
    description: "Stay fit on the go! Download our app for quick, effective no-equipment workouts.",
    bundleId: Capacitor.getPlatform() == "ios" ? "homeworkout://" : "com.lifebuddy.homeworkout",
    iosLink: "https://apps.apple.com/us/app/home-workout-fitness-no-tools/id6471418680?ls=1",
  },
];
