import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Capacitor } from "@capacitor/core";
import { NavController } from "@ionic/angular";
import { Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { User } from "../models/user";
import { AuthService } from "../services/auth.service";
import { NetworkService } from "../services/network.service";

@Injectable({
  providedIn: "root",
})
export class AutoLoginWebGuard {
  constructor(private authService: AuthService, private network: NetworkService, private navCtrl: NavController) {}

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (Capacitor.getPlatform() != "web") {
      return true;
    }

    return this.network.status.pipe(
      switchMap((status) => {
        return this.authService.userService.getUserFromLocalStorage();
      }),
      map((user: User) => {
        if (user && user.uid) {
          this.navCtrl.navigateRoot("/download-app").then((e) => {});
          return false;
        }
        return true;
      })
    );
  }
}
