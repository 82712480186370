import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getRemoteConfig } from "firebase/remote-config";

export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyAS9TfPRnUPMcOKa8PrBYg0kSI29ZmEn7w",
    authDomain: "lifebuddy-3a29a.firebaseapp.com",
    databaseURL: "https://lifebuddy-3a29a.firebaseio.com",
    projectId: "lifebuddy-3a29a",
    storageBucket: "lifebuddy-3a29a.appspot.com",
    messagingSenderId: "113204568203",
    appId: "1:113204568203:web:c09363aa38a31a94cef48e",
    measurementId: "G-K1B9YWZ8NG",
  },
  stripeApiKey: "pk_live_51LLwsxJHiIokgvzXiFdA12bDhc1tJ2ccl9tikXXblKPxKMUvGMbLmVd7NRryPMW68q0ZCBZHOTEaPsuPvhBiOKvL00JjjyY7nu",
};

const app = initializeApp(environment.firebase);

export const analytics = getAnalytics(app);
export const db = getFirestore(app);

export const remoteConfig = getRemoteConfig(app);

remoteConfig.defaultConfig = {
  sale: "",
  freemium: false,
  test_freemium: false,
  newUserSale: `{"active":true,"duration":"2:38:52","id":"halfoff","defaultPercent":50}`,
  test_sale: "",
  freemium_countdown: 144000,
  test_freemium_countdown: 144000,
};
