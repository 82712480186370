<div *ngIf="waitingLayer" class="waiting-layer">
  <div class="waiting-layer-spinner"></div>
  <p>{{ "Loading" | translate }}...</p>
</div>
<div *ngIf="!isWeb" class="header-container">
  <ion-button
    mode="ios"
    (click)="dismiss()"
    style="
      position: absolute;
      z-index: 10;
      background: rgba(0, 0, 0, 0.25);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 42px;
      height: 42px;
      border-radius: 100px;
      font-size: 14px;
      --padding-end: 0px;
      --padding-start: 0px;
      margin-left: 10px;
    "
    fill="clear"
    color="light"
  >
    <ion-icon slot="icon-only" name="close"></ion-icon>
  </ion-button>
</div>
<ion-content *ngIf="!isWeb">
  <ng-container *ngTemplateOutlet="saleModalTemplate"></ng-container>
</ion-content>
<div *ngIf="isWeb" id="sale-web-container" class="web-container">
  <ng-container *ngTemplateOutlet="saleModalTemplate"></ng-container>
</div>

<ng-template #saleModalTemplate>
  <div id="pull-area" [class.isWeb]="isWeb">
    <div
      class="img-bg-wrap"
      [class.isWeb]="isWeb"
      inViewport
      [inViewportOptions]="{ threshold: [0] }"
      (inViewportAction)="onInViewport($event)"
    >
      <div
        class="img-bg"
        [style.background-image]="
          'linear-gradient(rgb(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, ' + (isWeb ? 'rgb(51 56 99)' : 'rgb(248, 248, 248)') + '100%)'
        "
        style="display: flex"
      >
        <ion-spinner style="margin: auto; z-index: -1"></ion-spinner>
      </div>
      <video
        poster="assets/sales/paywall_poster.png"
        webkit-playsinline
        [autoplay]="true"
        playsinline
        [muted]="true"
        [loop]="true"
        style="width: 100%"
        [preload]="'auto'"
      >
        <source *ngIf="isAndroid || isWeb" [src]="'assets/sales/paywall_video.mp4'" type="video/mp4" />
      </video>
    </div>
    <div class="user-detail-container ion-margin-vertical" [style]="isWeb ? 'margin-bottom: 0px' : ''">
      <div
        style="
          font-weight: 700; font-size: 26px; padding: 0.5rem 0.5rem 1rem; text-align: center; line-height: 33.5px'
        "
        [style.color]="isWeb ? 'white' : '#000'"
      >
        {{ CHOOSE_PLANS[userPlan] | translate }}
      </div>
    </div>
  </div>

  <div class="subscription">
    <div *ngIf="isWeb" class="sub-header-slides" style="padding-top: 0">
      <swiper #screenshotSlider [config]="screenshotSlideOpts" class="slide-main screenshots" style="padding-bottom: 64px">
        <ng-template class="screenshot-slide-inner" swiperSlide *ngFor="let i of [1, 2, 3, 4]">
          <img style="width: 200px; pointer-events: none" [src]="'assets/sales/app_screenshot_' + i + '.png'" />
        </ng-template>
      </swiper>
    </div>
    <div *ngIf="!isWeb" class="sub-options">
      <ng-container *ngFor="let sub of subOptions; let i = index">
        <div class="sub-wrap" [class.sub-wrap-selected]="sub.checked" [class.fade-in-top]="sub.checked">
          <div [class.sub-label-active]="sub.checked" [class.fade-in-top]="sub.checked" class="sub-label" *ngIf="sub.label && sub.checked">
            {{ sub.label | translate }}
          </div>
          <div (click)="selectSub(sub, i)" class="sub" [class.sub-selected]="sub.checked" [style.padding]="sub.hasTrial ? '8px 6px' : ''">
            <div *ngIf="!sub?.hasTrial">
              <div class="time">{{ sub.time }}</div>
              <div class="period">{{ sub.period | translate }}</div>
            </div>
            <div *ngIf="sub?.hasTrial">
              <div [style.opacity]="sub.checked ? '1' : '0.6'">🔥</div>
              <div class="period">Trial</div>
              <div class="period" style="font-size: 10px">
                {{ currencySymbol }} {{ sub.trialCost }} - first {{ sub.period | translate }} then {{ currencySymbol }} {{ sub.cost }}
                {{ sub.subPeriod | translate }}
              </div>
            </div>

            <div class="line"></div>

            <div>
              <div *ngIf="!sub?.hasTrial" class="final-cost">{{ currencySymbol }} {{ sub.cost | number }}</div>
              <div class="discount" *ngIf="sub.discount && sub.checked && !sub?.hasTrial">
                <span>{{ "save" | translate }}</span> {{ sub.discount + "%" }}
              </div>
              <div class="cost" *ngIf="sub.finalCost && !sub?.hasTrial">
                {{ currencySymbol }} {{ sub.finalCost | number }}/{{ "mo" | translate }}
              </div>
              <div *ngIf="sub?.hasTrial" class="final-cost" style="margin-bottom: 0px">
                {{ currencySymbol }} {{ sub.trialCost | number }}
              </div>
              <div *ngIf="sub.hasTrial" class="final-cost">first {{ sub.period | translate }}</div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <div *ngIf="!isWeb" style="display: flex; flex-direction: column; align-items: center">
      <ion-button expand="full" mode="ios" class="subscription-restore animate_pulse" fill="clear" (click)="purchase()">
        {{ (canFreeTrial ? "TRY FREE FOR 3 DAYS" : "continue") | translate }}
      </ion-button>
      <!-- <div class="text main">{{ "TRY FREE CANCEL BEFORE 3 DAYS IF YOU DON'T LOVE IT" | translate }}</div> -->
      <div (click)="subscriptionService.trueCheckSubscription('restore')" style="text-decoration: underline; color: #969696">
        {{ "restore_purchase" | translate }}
      </div>
    </div>

    <div style="gap: 1.5rem; display: flex; flex-direction: column; align-items: center; padding: 4rem 1rem 2rem">
      <div
        style="text-align: center; font-size: 20px; font-style: normal; font-weight: 900; line-height: 27.5px"
        [style.color]="isWeb ? 'white' : '#000'"
      >
        {{ "Used by over 3.5M, personalized for you" | translate }}
      </div>
      <div style="display: flex; align-items: center; gap: 8px">
        <div
          style="text-align: center; font-size: 36px; font-style: normal; font-weight: 900; line-height: 27.5px"
          [style.color]="isWeb ? 'white' : '#000'"
        >
          {{ "4.8" | translate }}
        </div>
        <ion-icon name="star" style="color: #fed500; font-size: 32px"></ion-icon>
        <div
          style="text-align: center; font-size: 16px; font-style: normal; font-weight: 400; line-height: 27.5px"
          [style.color]="isWeb ? 'white' : '#000'"
        >
          {{ "20,000+ Reviews" | translate }}
        </div>
      </div>

      <ion-grid>
        <ion-row style="justify-content: space-between; align-items: flex-start; gap: 1rem 0.5rem">
          <ion-col size="5.75" class="review-bubble" [class.isWeb]="isWeb">
            <div style="font-size: 14px; font-style: normal; font-weight: 700; line-height: 27.5px">John W.</div>
            <div style="display: flex; gap: 2px">
              <ion-icon name="star" style="color: #fed500; font-size: 14px"></ion-icon>
              <ion-icon name="star" style="color: #fed500; font-size: 14px"></ion-icon>
              <ion-icon name="star" style="color: #fed500; font-size: 14px"></ion-icon>
              <ion-icon name="star" style="color: #fed500; font-size: 14px"></ion-icon>
              <ion-icon name="star" style="color: #fed500; font-size: 14px"></ion-icon>
            </div>
            <div style="font-size: 14px; font-style: normal; font-weight: 400; line-height: 20px; padding-top: 12px">
              If only I had stumbled upon LifeBuddy a bit sooner! It's truly been worth every penny. Hands down, it's the best workout app I
              have ever used.
            </div>
          </ion-col>
          <ion-col size="5.75" class="review-bubble" [class.isWeb]="isWeb">
            <div style="font-size: 14px; font-style: normal; font-weight: 700; line-height: 27.5px">Kevin B.</div>
            <div style="display: flex; gap: 2px">
              <ion-icon name="star" style="color: #fed500; font-size: 14px"></ion-icon>
              <ion-icon name="star" style="color: #fed500; font-size: 14px"></ion-icon>
              <ion-icon name="star" style="color: #fed500; font-size: 14px"></ion-icon>
              <ion-icon name="star" style="color: #fed500; font-size: 14px"></ion-icon>
              <ion-icon name="star" style="color: #fed500; font-size: 14px"></ion-icon>
            </div>
            <div style="font-size: 14px; font-style: normal; font-weight: 400; line-height: 20px; padding-top: 12px">
              Incredibly effective and enjoyable workouts. LifeBuddy has truly inspired a newfound enthusiasm for exercise in me.
            </div>
          </ion-col>
          <ion-col size="5.75" class="review-bubble" [class.isWeb]="isWeb">
            <div style="font-size: 14px; font-style: normal; font-weight: 700; line-height: 27.5px">Paul A.</div>
            <div style="display: flex; gap: 2px">
              <ion-icon name="star" style="color: #fed500; font-size: 14px"></ion-icon>
              <ion-icon name="star" style="color: #fed500; font-size: 14px"></ion-icon>
              <ion-icon name="star" style="color: #fed500; font-size: 14px"></ion-icon>
              <ion-icon name="star" style="color: #fed500; font-size: 14px"></ion-icon>
              <ion-icon name="star" style="color: #fed500; font-size: 14px"></ion-icon>
            </div>
            <div style="font-size: 14px; font-style: normal; font-weight: 400; line-height: 20px; padding-top: 12px">
              It's totally worth the money! These workouts really kicked my muscles into gear and I love how I can mix in different kinds of
              equipment!
            </div>
          </ion-col>
          <ion-col size="5.75" class="review-bubble" [class.isWeb]="isWeb">
            <div style="font-size: 14px; font-style: normal; font-weight: 700; line-height: 27.5px">Tyler T.</div>
            <div style="display: flex; gap: 2px">
              <ion-icon name="star" style="color: #fed500; font-size: 14px"></ion-icon>
              <ion-icon name="star" style="color: #fed500; font-size: 14px"></ion-icon>
              <ion-icon name="star" style="color: #fed500; font-size: 14px"></ion-icon>
              <ion-icon name="star" style="color: #fed500; font-size: 14px"></ion-icon>
              <ion-icon name="star" style="color: #fed500; font-size: 14px"></ion-icon>
            </div>
            <div style="font-size: 14px; font-style: normal; font-weight: 400; line-height: 20px; padding-top: 12px">
              Purchasing LifeBuddy has truly changed my lifestyle for the better. It's simply unrivaled when it comes to fitness apps.
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>

    <div class="ion-padding">
      <ion-item [lines]="'none'" style="--background: transparent; --padding-start: 0px" [style.--color]="isWeb ? 'white' : '#000'">
        <ion-icon slot="start" [src]="'assets/newUI/icons/check_circle_orange.svg'" style="font-size: 32px"></ion-icon>
        <ion-label style="white-space: normal">{{ "10-30 minute workouts with over 750 exercises." | translate }}</ion-label>
      </ion-item>
      <ion-item [lines]="'none'" style="--background: transparent; --padding-start: 0px" [style.--color]="isWeb ? 'white' : '#000'">
        <ion-icon slot="start" [src]="'assets/newUI/icons/check_circle_orange.svg'" style="font-size: 32px"></ion-icon>
        <ion-label style="white-space: normal">{{ "Best dumbbell workouts you can do from anywhere." | translate }}</ion-label>
      </ion-item>
      <ion-item [lines]="'none'" style="--background: transparent; --padding-start: 0px" [style.--color]="isWeb ? 'white' : '#000'">
        <ion-icon slot="start" [src]="'assets/newUI/icons/check_circle_orange.svg'" style="font-size: 32px"></ion-icon>
        <ion-label style="white-space: normal">{{ "Experience a new fitness challenge with each workout." | translate }}</ion-label>
      </ion-item>
    </div>

    <div style="padding: 2rem 1rem">
      <div>
        <img [src]="'assets/sales/workout-banner.png'" style="pointer-events: none" />

        <ion-text class="about" [class.isWeb]="isWeb">
          <span style="font-weight: 500">{{ "Greetings my workout buddys!" | translate }}</span>
          <br />
          {{ "my_name_is" | translate }}
        </ion-text>
      </div>

      <div class="sub-header-slides" [class.isWeb]="isWeb">
        <div class="slide-title" [style.color]="isWeb ? 'white' : '#000'">{{ "Frequently asked questions" | translate }}</div>
        <swiper #slider [config]="slideOpts" class="slide-main">
          <ng-template class="slide-inner" swiperSlide *ngFor="let option of saleOptions; let i = index">
            <div class="number-bubble">{{ i + 1 }}</div>
            <div class="sale-card">
              <div>
                <h3 class="title">{{ option.title | translate }}</h3>
                <p class="description">{{ option.description | translate }}</p>
              </div>
            </div>
          </ng-template>
        </swiper>
      </div>
    </div>

    <div class="subscription">
      <div style="display: flex; flex-direction: column; gap: 1rem; padding: 1rem 0rem 1rem">
        <div class="header-video-wrap">
          <img
            *ngIf="!isPlayed"
            [src]="salesThumbnail"
            style="position: absolute; z-index: 10; pointer-events: none"
            class="header-video"
          />
          <video
            (fullscreenchange)="screenChange($event)"
            #salesVideo
            id="salesVideo"
            class="header-video"
            (ended)="videoEnded()"
            [controls]="isPlayed"
            playsinline
            controlsList="nodownload noplaybackrate"
            disablepictureinpicture
            [poster]="salesThumbnail"
          >
            <source [src]="saleVideoUrl" type="video/mp4" />
          </video>
          <div *ngIf="!isPlayed" class="play-button" (click)="playVideo()">
            <ion-icon color="light" name="play"></ion-icon>
          </div>
        </div>
        <div style="padding: 0rem 1rem 1rem; display: flex; flex-direction: column; gap: 8px">
          <div
            style="text-align: center; font-size: 20px; font-style: normal; font-weight: 700; line-height: 27.5px"
            [style.color]="isWeb ? 'white' : '#000'"
          >
            {{ "Real people, Real results" | translate }}
          </div>
          <div
            style="text-align: center; font-size: 15px; font-style: normal; font-weight: 400; line-height: 21.5px"
            [style.color]="isWeb ? 'lightgrey' : '#5c5c5c'"
          >
            {{ salesVideoText?.description | weight : userServ.getSyncUserOnce()?.metric | translate }}
          </div>
        </div>
      </div>
      <div class="sub-options" [class.isWeb]="isWeb">
        <ng-container *ngFor="let sub of subOptions; let i = index">
          <div (click)="selectSub(sub, i)" class="sub-wrap" [class.sub-wrap-selected]="sub.checked" [class.fade-in-top]="sub.checked">
            <div
              [class.sub-label-active]="sub.checked"
              [class.fade-in-top]="sub.checked"
              class="sub-label"
              *ngIf="sub.label && sub.checked"
            >
              {{ sub.label | translate }}
            </div>
            <div class="sub" [class.sub-selected]="sub.checked" [style.padding]="sub.hasTrial ? '8px 6px' : ''">
              <div *ngIf="!sub?.hasTrial">
                <div class="time">{{ sub.time }}</div>
                <div class="period">{{ sub.period | translate }}</div>
              </div>
              <div *ngIf="sub?.hasTrial">
                <div [style.opacity]="sub.checked ? '1' : '0.6'">🔥</div>
                <div class="period">Trial</div>
                <div class="period" style="font-size: 10px">
                  $ {{ sub.trialCost }} - first {{ sub.period | translate }} then $ {{ sub.cost }} {{ sub.subPeriod | translate }}
                </div>
              </div>

              <div class="line"></div>

              <div>
                <div *ngIf="!sub?.hasTrial" class="final-cost">
                  {{ currencySymbol }}{{ sub.cost | number : "1." + (sub.cost.includes(".") ? "2-2" : "0-0") }}
                </div>
                <div class="discount" *ngIf="sub.discount && sub.checked && !sub?.hasTrial">
                  <span>{{ "save" | translate }}</span> {{ sub.discount + "%" }}
                </div>
                <div class="cost" *ngIf="sub.finalCost && !sub?.hasTrial">
                  {{ currencySymbol }} {{ sub.finalCost | number : "1." + (sub.finalCost.includes(".") ? "2-2" : "0-0") }}/{{
                    "mo" | translate
                  }}
                </div>
                <div *ngIf="sub?.hasTrial" class="final-cost" style="margin-bottom: 0px">
                  {{ currencySymbol }} {{ sub.trialCost | number }}
                </div>
                <div *ngIf="sub.hasTrial" class="final-cost">first {{ sub.period | translate }}</div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <div style="display: flex; flex-direction: column; align-items: center">
        <ion-button expand="full" mode="ios" class="subscription-restore animate_pulse" fill="clear" (click)="purchase()">
          {{ (canFreeTrial ? "TRY FREE FOR 3 DAYS" : "continue") | translate }}
        </ion-button>
        <div
          *ngIf="!isWeb"
          (click)="subscriptionService.trueCheckSubscription('restore')"
          style="text-decoration: underline; color: #969696"
        >
          {{ "restore_purchase" | translate }}
        </div>
      </div>

      <div *ngIf="isWeb" style="display: flex; justify-content: center">
        <p class="footer-sub-title subscription-bottext">
          By continuing, you agree that if you don't cancel at least 24 hours prior to the end of the current period subscription, you will
          automatically be charged the full price of {{ selectedSub.cost | number : "1.0-2" }} {{ selectedSub.currencyCode }} every
          {{ selectedSub.time }} {{ selectedSub.period }} until you cancel in settings. By continuing, I agree with
          <span class="bold bottom-text" style="text-decoration: underline" (click)="openTerms()">Terms of Use</span>,
          <span class="bold bottom-text" style="text-decoration: underline" (click)="openPrivacyPolicy()">Privacy Policy</span>,
          <span class="bold bottom-text" style="text-decoration: underline" (click)="openSubscription()">Subscription Terms</span>.
        </p>
      </div>
    </div>
  </div>

  <div class="footer">
    <p *ngIf="!isWeb" class="subscription-bottext">
      <span class="footer-sub-title">Recurring billing. Cancel anytime. Renews at full price.</span> <br />
      Your payment will be charged to your {{ isAndroid ? "Google Play" : "App Store" }} account, and your subscription will auto-renew for
      the same package length at <span class="bold">{{ isAndroid ? "full" : "same" }} price</span> until you cancel in your
      {{ isAndroid ? "Google Play Store" : "App Store" }} settings. By continuing, you agree to our terms and the auto-renewal.
    </p>
    <p *ngIf="isWeb" class="subscription-bottext guaranteed-text">
      Guaranteed
      <span style="color: var(--ion-color-orange)">Safe</span>
      Checkout <ion-icon style="font-size: 24px" [src]="'assets/newUI/icons/shield.svg'"></ion-icon>
    </p>
    <div *ngIf="isWeb" style="display: flex; justify-content: center; padding: 4px 1rem; width: 100%">
      <div style="max-width: 560px; display: flex; justify-content: space-between; width: 100%; align-items: center">
        <img style="width: 50px" [src]="'assets/newUI/icons/visa.png'" />
        <img style="width: 50px" [src]="'assets/newUI/icons/mastercard.png'" />
        <img style="width: 50px" [src]="'assets/newUI/icons/amex.png'" />
        <img style="width: 50px" [src]="'assets/newUI/icons/ssl.png'" />
      </div>
    </div>
    <p *ngIf="!isWeb" class="sub-menu">
      <span class="bold bottom-text" style="text-decoration: underline" (click)="openTerms()">Terms of Use</span>
      <span
        >&nbsp;&nbsp;&nbsp;&nbsp;
        <div class="separator"></div>
        &nbsp;&nbsp;&nbsp;&nbsp;</span
      >
      <span class="bold bottom-text" style="text-decoration: underline" (click)="openPrivacyPolicy()">Privacy Policy</span>
      <span
        >&nbsp;&nbsp;&nbsp;&nbsp;
        <div class="separator"></div>
        &nbsp;&nbsp;&nbsp;&nbsp;</span
      >
      <span class="bold bottom-text" style="text-decoration: underline" (click)="openSubscription()">Subscription Terms</span>
    </p>
  </div>
</ng-template>

<app-payment id="paymentModal" #paymentModal [selectedSub]="selectedSub" [customClass]="'zIndex30000'"></app-payment>
