<bottom-sheet #bottomSheetRadio [theme]="theme" [noScroll]="true">
  <div class="modal-select-container" [ngClass]="theme">
    <div
      class="radio-select-wrap"
      style="
        overflow: scroll;
        padding: 0 20px;
        max-height: calc(var(--vh, 100vh) - 24px - 152px - 54px - var(--ion-safe-area-top) - var(--ion-safe-area-bottom));
      "
    >
      <p class="modal-header">{{ title | translate }}</p>

      <div *ngIf="isRadio" class="list">
        <div class="select-item" *ngFor="let item of options">
          <label class="container">
            <span class="title">{{ item.name | translate }}</span>
            <input type="radio" [(ngModel)]="selectedItem" [value]="item.value" name="radio" />
            <span class="checkmark"></span>
          </label>
        </div>
        <div *ngIf="options.length > 3" style="height: 2rem"></div>
      </div>

      <div *ngIf="!isRadio" class="list">
        <div class="select-item" *ngFor="let item of options">
          <label class="container">
            <span class="title">{{ item.name | translate }}</span>
            <input type="checkbox" [(ngModel)]="item.isChecked" [value]="item.value" name="checkbox" />
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
    </div>

    <div class="modal-btn-container" [class.shadow]="options?.length > 3">
      <ion-button expand="full" mode="ios" class="btn-cancel" (click)="cancel()" fill="clear" style="font-size: 17px">
        {{ "cancel" | translate }}
      </ion-button>

      <ion-button expand="full" mode="ios" class="btn-submit" (click)="submit()" fill="clear" style="font-size: 17px">
        {{ "select" | translate }}
      </ion-button>
    </div>
  </div>
</bottom-sheet>
