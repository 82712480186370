import { Injectable } from "@angular/core";
import { Capacitor } from "@capacitor/core";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { Platform } from "@ionic/angular";
import { BehaviorSubject } from "rxjs";
import { Video } from "../models/video";
import { LoggerService } from "./logger.service";
import { SqlService } from "./sql.service";
@Injectable({
  providedIn: "root",
})
export class FileManagerService {
  public cancelDownloadObs = new BehaviorSubject<boolean>(false);

  categoryProgress = new BehaviorSubject<{ total: number; current: number }>({ total: 0, current: 0 });

  constructor(private platform: Platform, private sqlService: SqlService, private loggerService: LoggerService) {}

  private download(url: string, fileNamePath: string) {
    if (this.cancelDownloadObs.value) {
      return Promise.resolve("");
    }
    return new Promise<string>((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.onload = async (event) => {
        const blob = xhr.response;
        const writeFileResp = await Filesystem.writeFile({
          directory: Directory.Data,
          path: fileNamePath,
          data: await this.blobToBase64(blob),
          recursive: true,
        });
        const finalPhotoUri = await Filesystem.getUri({
          directory: Directory.Data,
          path: fileNamePath,
        });
        let photoPath = Capacitor.convertFileSrc(finalPhotoUri.uri);
        console.log("Uri's: ", finalPhotoUri.uri, writeFileResp.uri, photoPath);
        resolve(photoPath);
      };
      xhr.onerror = async (error) => {
        console.error("Error: ", error);
        reject();
      };
      xhr.open("GET", url);
      xhr.send();
    });
  }

  private async deleteVideo(videoItem: Video) {
    if (!this.platform.is("cordova")) {
      this.categoryProgress.next({ ...this.categoryProgress.value, current: this.categoryProgress.value.current++ });
      return Promise.resolve();
    }
    if (videoItem && videoItem.url) {
      const fileVodeosPath = "content/" + videoItem.video_id + ".mp4";
      await Filesystem.deleteFile({ directory: Directory.Data, path: fileVodeosPath }).catch((err) => {
        console.error("Error: ", err);
      });
      await this.sqlService
        .updateVideoInfoByVideoId(videoItem.video_id, "file_video_url", "")
        .then((res) => {
          console.log("Res: ", res);
        })
        .catch((err) => {
          console.error(`Error resetting file_video_url (${videoItem.video_id}): `, err);
        });
      this.categoryProgress.next({ ...this.categoryProgress.value, current: this.categoryProgress.value.current + 1 });
    }
    return Promise.resolve();
  }

  private async deleteThumbnail(videoItem: Video) {
    if (!this.platform.is("cordova")) {
      this.categoryProgress.next({ ...this.categoryProgress.value, current: this.categoryProgress.value.current++ });
      return Promise.resolve();
    }
    if (videoItem && videoItem.thumbnail) {
      const fileThumbsPath = "content/" + videoItem.video_id + ".png";
      await Filesystem.deleteFile({ directory: Directory.Data, path: fileThumbsPath }).catch((err) => {
        console.error("Error: ", err);
      });
      await this.sqlService
        .updateVideoInfoByVideoId(videoItem.video_id, "file_thumbnail_url", "")
        .then((res) => {
          console.log("Res: ", res);
        })
        .catch((err) => {
          console.error(`Error resetting file_thumbnail_url (${videoItem.video_id}): `, err);
        });
      this.categoryProgress.next({ ...this.categoryProgress.value, current: this.categoryProgress.value.current + 1 });
    }
    return Promise.resolve();
  }

  cancelDownload(categoryNameList: Array<string>) {
    this.cancelDownloadObs.next(true);
    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        let initPromise: Promise<any> = Promise.resolve();

        categoryNameList.forEach((e) => {
          initPromise = initPromise.then((_) => this.deleteVideoCategory(e.toLowerCase()));
        });
        await initPromise.catch((error) => {
          console.log(error);
        });
        resolve(true);
      }, 500);
    });
  }

  async deleteVideoCategory(category: string) {
    return this.sqlService
      .getAllVideosInfo()
      .then((response: Array<Video>) => {
        let initPromise: any = Promise.resolve();
        response.forEach((item) => {
          if (item.subcategory_name === category || (category === "abs" && item.category_name === category)) {
            initPromise = initPromise.then((_) => {
              const promises = [];
              promises.push(this.deleteThumbnail(item));
              promises.push(this.deleteVideo(item));
              return Promise.all(promises);
            });
          }
        });
        return initPromise;
      })
      .then((e) => this.sqlService.getAllVideosInfo());
  }

  async downloadVideoCategory(category: string) {
    return this.sqlService
      .getAllVideosInfo()
      .then((response: Array<Video>) => {
        const downloadList = [];

        response.forEach((item) => {
          if (item.subcategory_name === category || (category === "abs" && item.category_name === category)) {
            downloadList.push(item);
          }
        });

        const chunks = downloadList.reduce((resultArray, item, index) => {
          const chunkIndex = Math.floor(index / 5);
          if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [];
          }
          resultArray[chunkIndex].push(item);
          return resultArray;
        }, []);

        let initPromise = Promise.resolve();

        chunks.forEach((list) => {
          initPromise = initPromise.then((_) => {
            if (this.cancelDownloadObs.value) {
              return Promise.resolve<any>(true);
            }
            const promises = [];
            list.forEach((item) => {
              promises.push(this.downloadVideoIfNotExist(item));
              promises.push(this.downloadThumbnailIfNotExist(item));
            });
            return Promise.all(promises);
          });
        });
        return initPromise;
      })
      .then((e) => {
        return this.sqlService.getAllVideosInfo();
      });
  }

  async downloadThumbnailIfNotExist(videoItem: Video) {
    if (this.cancelDownloadObs.value) {
      return Promise.resolve();
    }
    if (!this.platform.is("cordova")) {
      this.categoryProgress.next({ ...this.categoryProgress.value, current: this.categoryProgress.value.current++ });
      return Promise.resolve();
    }
    console.log("download thumbnails: ", videoItem.video_id, videoItem.file_thumbnail_url);
    const filePath = "content/" + videoItem.video_id + ".png";
    return Filesystem.readdir({ directory: Directory.Data, path: "content" })
      .then((response) => {
        return response;
      })
      .catch((error: { message: string }) => {
        if (
          error.message == "The file “content” couldn’t be opened because there is no such file." ||
          error.message == "Directory does not exist"
        ) {
          return Filesystem.mkdir({ directory: Directory.Data, path: "content", recursive: true }).catch((err) => {
            console.error("Error: ", err);
            return "Directory Exists";
          });
        }
        throw error;
      })
      .then((response) => {
        if (!response) {
          return;
        }
        return this.checkFileIsExist(filePath);
      })
      .then((response) => {
        if (response || this.cancelDownloadObs.value) {
          return;
        }
        return this.download(videoItem.thumbnail, filePath);
      })
      .then((response) => {
        console.log("download thumbnails status: ", videoItem.video_id, response);
        if (response && !this.cancelDownloadObs.value) {
          this.sqlService.updateVideoInfoByVideoId(videoItem.video_id, "file_thumbnail_url", response);
        }
        this.categoryProgress.next({ ...this.categoryProgress.value, current: this.categoryProgress.value.current + 1 });
      })
      .catch((error) => {
        if (error.code === 3) {
          return;
        }
        throw error;
      });
  }

  async downloadVideoIfNotExist(videoItem: Video) {
    if (this.cancelDownloadObs.value) {
      return Promise.resolve();
    }
    if (!this.platform.is("cordova")) {
      this.categoryProgress.next({ ...this.categoryProgress.value, current: this.categoryProgress.value.current + 1 });
      return Promise.resolve();
    }
    console.log("download videos: ", videoItem.video_id, videoItem.file_video_url);
    const filePath = "content/" + videoItem.video_id + ".mp4";
    return Filesystem.readdir({ directory: Directory.Data, path: "content" })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (
          error.message == "The file “content” couldn’t be opened because there is no such file." ||
          error.message == "Directory does not exist"
        ) {
          return Filesystem.mkdir({ directory: Directory.Data, path: "content", recursive: true }).catch((err) => {
            console.error("Error: ", err);
            return "Directory Exists";
          });
        }
        throw error;
      })
      .then((response) => {
        if (!response) {
          return;
        }
        return this.checkFileIsExist(filePath);
      })
      .then((response) => {
        if (response || this.cancelDownloadObs.value) {
          return;
        }
        return this.download(videoItem.url, filePath);
      })
      .then((response) => {
        if (response && !this.cancelDownloadObs.value) {
          this.sqlService.updateVideoInfoByVideoId(videoItem.video_id, "file_video_url", response);
        }
        this.categoryProgress.next({ ...this.categoryProgress.value, current: this.categoryProgress.value.current + 1 });
      });
  }

  async saveProgressImage(fileName: string, file: Blob) {
    if (!this.platform.is("cordova")) {
      return Promise.resolve();
    }
    return Filesystem.writeFile({
      directory: Directory.Data,
      path: "content/" + fileName + ".jpeg",
      data: await this.blobToBase64(file),
      recursive: true,
    });
  }

  async checkWeightProgressImage(list: Array<string>) {
    if (!this.platform.is("cordova")) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(true);
        }, 1000);
      });
    }
    const promises = [];
    list.forEach((filePath) => {
      promises.push(
        new Promise(async (resolve, reject) => {
          const response = await this._checkFileIsExist("content/", filePath);
          if (response && typeof response != "boolean") {
            resolve({ path: filePath, status: true, fullPath: response.uri });
          } else {
            resolve({ path: filePath, status: false, fullPath: "" });
          }
        })
      );
    });

    return Promise.all(promises);
  }

  async getAllFilesWhichStartWith(startPath) {
    if (!this.platform.is("cordova")) {
      return Promise.resolve([]);
    }

    const listDir = await Filesystem.readdir({ directory: Directory.Data, path: "content" });
    return listDir.files.filter((item) => item.name.startsWith(startPath));
  }

  async deleteWeightProgressPhoto(fileName: string) {
    return Filesystem.deleteFile({ directory: Directory.Data, path: "content/" + fileName });
  }

  downloadWeightProgressPhotos(items) {
    items.forEach((item) => {
      const filePath = "content/" + item.fileName;
      return this.download(item.url, filePath)
        .then((entry) => {
          console.log("downloadWeightProgressPhoto::", entry);
        })
        .catch((error) => {
          if (error.code === 4) {
            return;
          }
          this.loggerService.logError(error);
        });
    });
  }

  async checkFileIsExist(fileUrl: string) {
    if (!this.platform.is("cordova")) {
      return Promise.resolve(false);
    }
    const sqlPath = fileUrl.substring(0, fileUrl.lastIndexOf("/") + 1);
    const fullPath = "content/";
    const name = fileUrl.substring(fileUrl.lastIndexOf("/") + 1, fileUrl.length);
    return this._checkFileIsExist(fullPath, name).then(async (response) => {
      if (!response && this.platform.is("ios")) {
        try {
          const res = await this._checkFileIsExist(sqlPath, name);

          if (res) {
            return await Filesystem.copy({ from: sqlPath + name, to: fullPath + name, toDirectory: Directory.Data });
          }
        } catch (error) {
          return false;
        }
      }
      return response;
    });
  }

  private _checkFileIsExist(path, name) {
    return Filesystem.readFile({ directory: Directory.Data, path: path + name })
      .then(async (response) => {
        let ret: any = response.data;
        try {
          const resp = await Filesystem.getUri({ directory: Directory.Data, path: path + name });
          ret = resp.uri;
        } catch (err) {
          console.error("Error getting uri: ", err);
        }
        return { uri: ret };
      })
      .catch((error) => {
        console.error("Error getting file: ", error.message, path + name);
        // if (error.message == `The file "${name}" couldn’t be opened because there is no such file.`) {
        return false;
        // }
        // throw error;
      });
  }

  private async blobToBase64(blob: Blob): Promise<string> {
    const reader = new FileReader();
    reader.readAsDataURL(blob);

    return new Promise((resolve, reject) => {
      reader.onloadend = () => resolve(reader.result.toString());
    });
  }
}
