<bottom-sheet #bottomSheet [class]="customClass" [theme]="'white'" [payment]="true">
  <div
    style="
      padding: 0rem;
      gap: 1.5rem;
      min-width: 356px;
      min-height: calc(var(--vh, 100vh) - 54px - var(--ion-safe-area-top) + var(--ion-safe-area-bottom));
      max-height: calc(var(--vh, 100vh) - 54px - var(--ion-safe-area-top) + var(--ion-safe-area-bottom));
      overflow: scroll;
    "
  >
    <ng-container *ngIf="step == 1">
      <div id="checkout" style="width: 100%">
        <!-- Checkout will insert the payment form here -->
      </div>
    </ng-container>
  </div>
</bottom-sheet>
