<ion-app>
  <ion-menu side="end" contentId="main-content" [swipeGesture]="false">
    <ion-header class="ion-no-border">
      <ion-toolbar>
        <ion-title mode="md">{{ "Menu" | translate }}</ion-title>
        <ion-buttons slot="end">
          <ion-menu-button>
            <ion-icon name="close" color="white"></ion-icon>
          </ion-menu-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <div>
        <ion-list mode="ios">
          <ion-list-header style="padding-bottom: 0.5rem">
            <ion-label color="white" style="margin-top: 0px">{{ "Account" | translate }}</ion-label>
          </ion-list-header>
          <div *ngIf="user?.uid" style="padding: 0; display: flex; flex-direction: column; gap: 8px">
            <ion-menu-toggle>
              <ion-item class="setting-item" lines="none" [button]="true" [detail]="true" (click)="openModal(bottomSheetLogout)">
                <ion-icon slot="start" style="color: white" [src]="'assets/newUI/icons/log-out.svg'"></ion-icon>
                <ion-label>{{ "Log Out" | translate }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
            <bottom-sheet #bottomSheetLogout>
              <div class="modal-select-container">
                <p class="modal-header" style="text-align: center">{{ "logout_question" | translate }}</p>
                <div class="modal-btn-container">
                  <ion-button expand="full" mode="ios" class="btn-cancel" (click)="hideModal(bottomSheetLogout)" fill="clear">
                    {{ "no" | translate }}
                  </ion-button>

                  <ion-button expand="full" mode="ios" class="btn-submit" (click)="logout(bottomSheetLogout)" fill="clear">
                    {{ "yes" | translate }}
                  </ion-button>
                </div>
              </div>
            </bottom-sheet>
          </div>

          <div *ngIf="!user?.uid" style="padding: 0; display: flex; flex-direction: column; gap: 8px">
            <ion-menu-toggle>
              <ion-item class="setting-item" lines="none" [button]="true" [detail]="true" (click)="openLogin()">
                <ion-icon slot="start" style="color: white" [src]="'assets/newUI/icons/log-in.svg'"></ion-icon>
                <ion-label>{{ "login" | translate }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </div>
        </ion-list>

        <ion-list mode="ios" lines="none">
          <ion-list-header style="padding-bottom: 0.5rem">
            <ion-label color="white">{{ "settings" | translate }}</ion-label>
          </ion-list-header>
          <div style="padding: 0; display: flex; flex-direction: column; gap: 8px">
            <ion-menu-toggle *ngIf="user?.stripeId">
              <ion-item class="setting-item" lines="none" detail="true" [button]="true" (click)="manageSubscription()">
                <ion-icon slot="start" [src]="'assets/newUI/manage-subscription.svg'"></ion-icon>

                <ion-label> {{ "manage_subscription" | translate }} </ion-label>
              </ion-item>
            </ion-menu-toggle>

            <ion-menu-toggle>
              <ion-item class="setting-item" lines="none" [button]="true" [detail]="true" (click)="openLanguageModal(selectLanguageModal)">
                <ion-icon slot="start" [src]="'assets/newUI/icons/language.svg'"></ion-icon>
                <ion-label>{{ "language" | translate }}</ion-label>
                <ion-label slot="end" style="text-transform: capitalize; text-align: right">
                  {{ displayLanguage || "Select Language" | translate }}
                </ion-label>
                <app-radio-select #selectLanguageModal (modalSelect)="modalSelectLanguage($event)"></app-radio-select>
              </ion-item>
            </ion-menu-toggle>
          </div>
        </ion-list>

        <ion-list mode="ios">
          <ion-list-header style="padding-bottom: 0.5rem">
            <ion-label color="white">{{ "Support" | translate }}</ion-label>
          </ion-list-header>
          <div style="padding: 0; display: flex; flex-direction: column; gap: 8px">
            <ion-menu-toggle>
              <ion-item class="setting-item" lines="none" [button]="true" [detail]="true" (click)="mailTo()">
                <ion-icon slot="start" style="color: white" [src]="'assets/newUI/icons/feedback_support.svg'"></ion-icon>
                <ion-label>{{ "feedback_support" | translate }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
            <bottom-sheet #bottomSheetLogout>
              <div class="modal-select-container">
                <p class="modal-header" style="text-align: center">{{ "logout_question" | translate }}</p>
                <div class="modal-btn-container">
                  <ion-button expand="full" mode="ios" class="btn-cancel" (click)="hideModal(bottomSheetLogout)" fill="clear">
                    {{ "no" | translate }}
                  </ion-button>

                  <ion-button expand="full" mode="ios" class="btn-submit" (click)="logout(bottomSheetLogout)" fill="clear">
                    {{ "yes" | translate }}
                  </ion-button>
                </div>
              </div>
            </bottom-sheet>
          </div>
        </ion-list>

        <ion-list mode="ios">
          <ion-list-header style="padding-bottom: 0.5rem">
            <ion-label color="white">{{ "visit_us" | translate }}</ion-label>
          </ion-list-header>
          <div style="padding: 0; display: flex; flex-direction: column; gap: 8px">
            <ion-item
              class="setting-item"
              lines="none"
              [button]="true"
              [detail]="true"
              href="https://www.facebook.com/lifebuddymobileapp/"
              target="_blank"
            >
              <ion-icon slot="start" style="color: white" [src]="'assets/newUI/icons/facebook.svg'"></ion-icon>
              <ion-label>{{ "Facebook" | translate }}</ion-label>
            </ion-item>

            <ion-item
              class="setting-item"
              lines="none"
              [button]="true"
              [detail]="true"
              href="https://www.instagram.com/lifebuddy.app/"
              target="_blank"
            >
              <ion-icon slot="start" style="color: white" [src]="'assets/newUI/icons/instagram.svg'"></ion-icon>
              <ion-label>{{ "Instagram" | translate }}</ion-label>
            </ion-item>
            <ion-item
              class="setting-item"
              lines="none"
              [button]="true"
              [detail]="true"
              href="https://twitter.com/lifebuddy_app"
              target="_blank"
            >
              <ion-icon slot="start" style="color: white" [src]="'assets/newUI/icons/twitter-x.svg'"></ion-icon>
              <ion-label>{{ "Twitter" | translate }}</ion-label>
            </ion-item>
            <ion-item
              class="setting-item"
              lines="none"
              [button]="true"
              [detail]="true"
              href="https://lifebuddymobileapp.com/"
              target="_blank"
            >
              <ion-icon slot="start" style="color: white" [src]="'assets/newUI/icons/website.svg'"></ion-icon>
              <ion-label>{{ "Website" | translate }}</ion-label>
            </ion-item>
            <ion-item
              class="setting-item"
              lines="none"
              [button]="true"
              [detail]="true"
              href="https://www.facebook.com/groups/LifeBuddyCommunity"
              target="_blank"
            >
              <ion-icon slot="start" style="color: white" [src]="'assets/newUI/icons/community.svg'"></ion-icon>
              <ion-label>{{ "Facebook Group" | translate }}</ion-label>
            </ion-item>
          </div>
        </ion-list>

        <ion-list mode="ios">
          <ion-list-header style="padding-bottom: 0.5rem">
            <ion-label color="white">Legal</ion-label>
          </ion-list-header>
          <div style="padding: 0; display: flex; flex-direction: column; gap: 8px">
            <ion-menu-toggle>
              <ion-item class="setting-item" lines="none" [button]="true" routerLink="/terms-and-conditions" routerDirection="forward">
                <ion-icon slot="start" [src]="'assets/newUI/icons/terms.svg'"></ion-icon>
                <ion-label>{{ "Terms And Conditions" | translate }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
            <ion-menu-toggle>
              <ion-item class="setting-item" lines="none" [button]="true" routerLink="/privacy-policy" routerDirection="forward">
                <ion-icon slot="start" [src]="'assets/newUI/icons/privacy.svg'"></ion-icon>
                <ion-label>{{ "Privacy Policy" | translate }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </div>
        </ion-list>
      </div>

      <div style="display: flex; justify-content: start; color: white; padding-top: 1rem">v{{ version }}</div>
    </ion-content>
  </ion-menu>
  <ion-router-outlet id="main-content" [swipeGesture]="false"></ion-router-outlet>
  <app-review id="bottomSheetReview2" #bottomSheetReview2></app-review>
</ion-app>
