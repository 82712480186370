import { Injectable } from "@angular/core";

import { DEFAULT_LANGUAGE } from "../constants";
import { Adapter } from "../models/adapter";
import { User } from "../models/user";

@Injectable({
  providedIn: "root",
})
export class UserAdapter implements Adapter<User> {
  adapt(item: any): User {
    return {
      ...item,
      uid: item && item.uid ? item.uid : null,
      countdownEnd: item && item.countdownEnd ? item.countdownEnd : null,
      email: item && item.email ? item.email : null,
      username: item && item.username ? item.username : null,
      image: item && item.image ? item.image : null,
      status: item && item.status ? item.status : null,
      gender: item && item.gender ? item.gender : null,
      age: item && item.age ? +item.age : null,
      language: item && item.language ? item.language : DEFAULT_LANGUAGE,
      registrationIsCompleted: item ? !!item.registrationIsCompleted : false,
      planIsCompleted: item ? !!item.planIsCompleted : false,
      height: {
        cm: item && item.height && item.height.cm ? +item.height.cm : null,
        inch: item && item.height && item.height.inch ? +item.height.inch : null,
        feet: item && item.height && item.height.feet ? +item.height.feet : null,
      },
      stripeId: item?.stripeId ?? "",
      weight: {
        kg: item && item.weight && item.weight.kg ? +item.weight.kg : null,
        pound: item && item.weight && item.weight.pound ? +item.weight.pound : null,
      },
      difficulty: item && item.difficulty ? item.difficulty : null,

      subscription:
        item && item.subscription
          ? {
              transitionId: item.subscription.transitionId,
              planName: item.subscription.planName,
              isSubscribed: item.subscription.isSubscribed,
              hasSubscribed: item.subscription?.hasSubscribed ? item.subscription.hasSubscribed : false,
              date: item.subscription.date
                ? {
                    start: item.subscription.date.start,
                    end: item.subscription.date.end,
                  }
                : null,
            }
          : null,
      subscriptionMeta: item && item.subscriptionMeta ? item.subscriptionMeta : null,
      exerciseInfo:
        item && item.exerciseInfo
          ? {
              allTime: item.exerciseInfo.allTime
                ? {
                    workoutCount: +item.exerciseInfo.allTime.workoutCount,
                    caloriesBurned: +item.exerciseInfo.allTime.caloriesBurned,
                    workoutTime: +item.exerciseInfo.allTime.workoutTime,
                  }
                : null,
              daily: item.exerciseInfo.daily
                ? {
                    workoutCount: +item.exerciseInfo.daily.workoutCount,
                    caloriesBurned: +item.exerciseInfo.daily.caloriesBurned,
                    workoutTime: +item.exerciseInfo.daily.workoutTime,
                  }
                : null,
              weekly: item.exerciseInfo.weekly
                ? {
                    workoutCount: +item.exerciseInfo.weekly.workoutCount,
                    caloriesBurned: +item.exerciseInfo.weekly.caloriesBurned,
                    workoutTime: +item.exerciseInfo.weekly.workoutTime,
                  }
                : null,
              monthly: item.exerciseInfo.monthly
                ? {
                    workoutCount: +item.exerciseInfo.monthly.workoutCount,
                    caloriesBurned: +item.exerciseInfo.monthly.caloriesBurned,
                    workoutTime: +item.exerciseInfo.monthly.workoutTime,
                  }
                : null,

              lastExerciseTime: item.exerciseInfo.lastExerciseTime || null,
              firstExerciseTime: item.exerciseInfo.firstExerciseTime || null,
              completedWeeklyGoals: item.exerciseInfo.completedWeeklyGoals,
              resetTime: item.exerciseInfo.resetTime,
            }
          : null,
    } as User;
  }
}
