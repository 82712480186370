import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Capacitor } from "@capacitor/core";
import { StatusBar, Style } from "@capacitor/status-bar";
import { IonSlides, ModalController, Platform } from "@ionic/angular";
import { Subscription } from "rxjs";
import { TranslateConfigService } from "src/app/services/translate-config.service";
import { SubscriptionService } from "../../services/subscription.service";

@Component({
  selector: "app-subscription-terms",
  templateUrl: "./subscription-terms.page.html",
  styleUrls: ["./subscription-terms.page.scss"],
})
export class SubscriptionTermsPage implements OnInit, OnDestroy {
  @Input() modal = false;
  @ViewChild(IonSlides) slider: IonSlides;
  currentPlatform: "any" | "web" | "ios" | "android";
  slideOpts: any;
  saleOptions: Array<any> = [
    {
      title: "Can I access all features?",
      description: "Yes, you can access all features including 750+ workouts you can do from home.",
      condition: "any",
    },
    {
      title: "How do I cancel the trial?",
      description: "Google Play -> Profile Icon -> Payments & Subscriptions -> Subscriptions -> App Logo -> Cancel Subscription",
      condition: "android",
    },
    {
      title: "How do I cancel the trial?",
      description: "App Store -> Profile Icon -> Your Name -> Subscriptions -> App Logo -> Cancel Subscription",
      condition: "ios",
    },
    {
      title: "How do I cancel the trial?",
      description: "unsubscribe_steps_web",
      condition: "web",
    },
    {
      title: "Will I be charged when canceling?",
      description:
        "No, you can cancel anytime during the trial, and you won't be charged any fees if you cancel 24 hours before the trial ends.",
      condition: "any",
    },
  ];

  canFreeTrial = true;
  backButtonRef: Subscription;

  language: any;

  constructor(
    private modalCtrl: ModalController,
    private platform: Platform,
    private subscriptionService: SubscriptionService,
    private translateConfigServ: TranslateConfigService
  ) {
    this.language = this.translateConfigServ.getCurrentLang();

    this.slideOpts = {
      speed: 300,
      spaceBetween: 20,
      pagination: false,
      slidesPerView: "auto",
    };
    if (Capacitor.getPlatform() == "web") {
      this.currentPlatform = "web";
    } else if (this.platform.is("ios")) {
      this.currentPlatform = "ios";
    } else if (this.platform.is("android")) {
      this.currentPlatform = "android";
    } else {
      this.currentPlatform = "any";
    }
    let removeList = [];
    for (let index = 0; index < this.saleOptions.length; index++) {
      const element = this.saleOptions[index];
      if (element.condition != "any") {
        if (element.condition != this.currentPlatform) {
          removeList.push(index);
        }
      }
    }
    for (let index2 = removeList.length - 1; index2 >= 0; index2--) {
      const element = removeList[index2];
      this.saleOptions.splice(element, 1);
    }

    this.backButtonRef = this.platform.backButton.subscribeWithPriority(200, () => {
      this.goBack();
    });
  }

  async ionViewWillEnter() {
    if (this.platform.is("capacitor")) {
      await StatusBar.setStyle({ style: Style.Light });
    }
  }

  async ionViewWillLeave() {
    if (this.platform.is("capacitor")) {
      await StatusBar.setStyle({ style: Style.Dark });
    }
  }

  ngOnDestroy(): void {
    this.backButtonRef.unsubscribe();
  }

  ngOnInit() {
    this.subscriptionService.checkCanFreeTrial().then((canFreeTrial) => {
      this.canFreeTrial = canFreeTrial;
    });
  }

  goBack() {
    this.modalCtrl.dismiss();
  }
}
