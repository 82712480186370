import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Capacitor } from "@capacitor/core";
import { NavController } from "@ionic/angular";
import { Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { User } from "../models/user";
import { AuthService } from "../services/auth.service";
import { NetworkService } from "../services/network.service";
import { OnboardingService } from "../services/onboarding.service";
import { WeightProgressService } from "../services/weight-progress.service";

@Injectable({
  providedIn: "root",
})
export class AutoLoginGuard {
  constructor(
    private weightProgressService: WeightProgressService,
    private onboardingService: OnboardingService,
    private authService: AuthService,
    private network: NetworkService,
    private navCtrl: NavController
  ) {}

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.network.status.pipe(
      switchMap((status) => {
        console.log("AutoLoginGuard::NetWork status", status);
        return this.authService.userService.getUserFromLocalStorage();
      }),
      map((user: User) => {
        console.log("AutoLoginGuard::User", user);
        // this.authService.userService.setSyncImage({});

        if (user && user.uid) {
          if (Capacitor.getPlatform() === "web") {
            this.navCtrl.navigateRoot("/download-app").then((e) => {});
            return false;
          }

          if (user.registrationIsCompleted && user.planIsCompleted) {
            this.navCtrl.navigateRoot("/home/plan").then((e) => {});
          } else {
            this.onboardingService.resetOnboardingPageKeys();
            this.onboardingService.isCustomePlan = false;
            this.navCtrl.navigateRoot("/onboarding").then((e) => {});
          }
          // this.authService.updateSentryScope(user);
          this.authService.userService
            .getSyncUser()
            .toPromise()
            .then((usr) => {
              if (usr && usr.uid) {
                this.weightProgressService.downloadWeightProgressPhotos();
              }
            });
          return false;
        }
        this.navCtrl.navigateRoot("/onboarding"); // TODO: landing
        return false;
      })
    );
  }
}
