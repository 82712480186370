<ion-header mode="ios"></ion-header>
<ion-content fullscreen="true" class="ion-text-center">
  <div class="header-container">
    <ion-toolbar mode="ios">
      <!-- <ion-title color="light">{{"sign_up" | translate}}</ion-title> -->
      <ion-buttons slot="start" style="height: 100%">
        <ion-button *ngIf="!isWeb" mode="ios" (click)="close(false)">
          <ion-icon src="assets/newUI/icons/backBtn.svg"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </div>

  <div class="content-container">
    <!-- <img class="logo" draggable="false" src="assets/newUI/appLogo.png" /> -->
    <form [formGroup]="authForm">
      <div class="grid-container">
        <h2 style="color: #fff; text-align: center; font-size: 24px; font-weight: 700; line-height: 42px; padding: 0 1rem 3rem">
          <span *ngIf="!isAnonymous">{{pageTitle | translate}}</span>
          <span *ngIf="isAnonymous">{{"link_account" | translate}}</span>
        </h2>
        <ng-container *ngIf="!isAnonymous">
          <div *ngIf="isWeb" class="grid-row">
            <div class="field-group" inViewport [inViewportOptions]="{threshold: [0]}" [class.disabled]="email != ''">
              <input
                [readOnly]="email != ''"
                autocomplete="off"
                required
                id="webEmail"
                formControlName="email"
                #webEmailTab
                type="email"
                class="field-control"
              />
              <label class="field-label" [class.active-field-control]="authForm.get('email').value" for="email"
                >{{"email" | translate}}
              </label>
            </div>
          </div>

          <div class="grid-row">
            <div class="field-group" inViewport [inViewportOptions]="{threshold: [0]}">
              <input
                autocomplete="off"
                required
                formControlName="firstName"
                (keyup.enter)="lastNameTab.focus()"
                class="field-control"
                type="text"
                id="firstName"
                class="field-control"
              />
              <label class="field-label" [class.active-field-control]="authForm.get('firstName').value" for="firstName">
                {{"first_name" | translate}}
              </label>
            </div>
          </div>

          <div class="grid-row">
            <div class="field-group" inViewport [inViewportOptions]="{threshold: [0]}">
              <input
                autocomplete="off"
                required
                id="lastName"
                formControlName="lastName"
                #lastNameTab
                (keyup.enter)="isWeb ? passwordTab.focus() : emailTab?.focus()"
                type="text"
                class="field-control"
              />
              <label class="field-label" [class.active-field-control]="authForm.get('lastName').value" for="lastName">
                {{"last_name" | translate}}
              </label>
            </div>
          </div>
        </ng-container>

        <div class="grid-row" [style.display]="isWeb ? 'none' : ''">
          <div class="field-group" inViewport [inViewportOptions]="{threshold: [0]}">
            <input
              [readOnly]="email != ''"
              autocomplete="off"
              required
              id="email"
              formControlName="email"
              (keyup.enter)="passwordTab.focus()"
              #emailTab
              type="email"
              class="field-control"
            />
            <label class="field-label" [class.active-field-control]="authForm.get('email').value" for="email"
              >{{"email" | translate}}
            </label>
          </div>
        </div>

        <div class="grid-row">
          <div class="field-group" inViewport [inViewportOptions]="{threshold: [0]}">
            <input
              autocomplete="off"
              required
              id="password"
              formControlName="password"
              (keyup.enter)="confirmTab.focus()"
              #passwordTab
              [type]="showHidePassword ? 'password' : 'text'"
              class="field-control"
            />
            <label class="field-label" [class.active-field-control]="authForm.get('password').value" for="password">
              {{"password" | translate}}
            </label>

            <ion-icon
              class="field-icon"
              (click)="toggleShowHidePassword()"
              [src]="showHidePassword ? 'assets/newUI/icons/close-eye.svg' : 'assets/newUI/icons/open-eye.svg'"
            ></ion-icon>
          </div>
        </div>

        <div class="grid-row">
          <div class="field-group" inViewport [inViewportOptions]="{threshold: [0]}">
            <input
              autocomplete="off"
              required
              id="confirmPassword"
              formControlName="confirmPassword"
              #confirmTab
              (keyup.enter)="onSubmit()"
              [type]="showHidePassword ? 'password' : 'text'"
              class="field-control"
            />
            <label class="field-label" [class.active-field-control]="authForm.get('confirmPassword').value" for="confirmPassword">
              {{"confirm_password" | translate}}
            </label>
          </div>
        </div>
      </div>
      <div class="grid-row" style="padding: 1rem">
        <ion-button class="submit" (click)="onSubmit()" expand="full" mode="ios" fill="clear">
          {{(isAnonymous ? "link_account" : "sign_up") | translate}}
        </ion-button>
      </div>
    </form>
  </div>
</ion-content>
