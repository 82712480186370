import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Capacitor } from "@capacitor/core";
import { LoadingController, ModalController, NavController, Platform } from "@ionic/angular";
import { Subscription } from "rxjs";
import { AnalyticsService } from "src/app/services/analytics.service";
import { HelperService } from "src/app/services/helper.service";
import { TranslateConfigService } from "src/app/services/translate-config.service";
import { AuthService } from "../../services/auth.service";
import { NotificationService } from "../../services/notification.service";
import { ForgotPasswordPage } from "../forgot-password/forgot-password.page";

@Component({
  selector: "app-login-with-email",
  templateUrl: "./login-with-email.page.html",
  styleUrls: ["./login-with-email.page.scss"],
})
export class LoginWithEmailPage implements OnChanges {
  @Input() routeHome = true;
  @Input() email = "";
  @Input() pageTitle = "Sign in to your existing account";

  isWeb = Capacitor.getPlatform() == "web";

  authForm: UntypedFormGroup;
  showHidePassword: boolean;

  language: any;

  backBtnSub: Subscription;

  constructor(
    private platform: Platform,
    private navCtrl: NavController,
    private loadingController: LoadingController,
    private authService: AuthService,
    private notificationService: NotificationService,
    private helperServ: HelperService,
    private translateConfigService: TranslateConfigService,
    private analyticsServ: AnalyticsService,
    private modalCtrl: ModalController,
    private cdRef: ChangeDetectorRef
  ) {
    this.language = this.translateConfigService.getCurrentLang();

    this.showHidePassword = true;
    this.authForm = new UntypedFormGroup({
      email: new UntypedFormControl({ value: this.email, disabled: this.email != "" }, [
        Validators.required,
        // tslint:disable-next-line:max-line-length
        Validators.pattern(
          /[a-z0-9!#$%&'*+/=?^_{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
        ),
      ]),
      password: new UntypedFormControl("", [Validators.required, Validators.minLength(6)]),
    });

    this.backBtnSub = this.platform.backButton.subscribeWithPriority(301, (processNextHandler) => {
      this.backBtnSub.unsubscribe();
      this.close();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.email && changes.email.currentValue && this.authForm) {
      this.authForm.get("email").setValue(changes.email.currentValue);
      this.authForm.get("email").disable();
      this.cdRef.detectChanges();
    }
  }

  ionViewWillEnter() {
    document.getElementById("email").focus();
    // this.keyboard.hideFormAccessoryBar(true);
  }

  ionViewWillLeave() {
    this.authForm.reset();
    this.backBtnSub.unsubscribe();
    // this.keyboard.hideFormAccessoryBar(false);
  }

  private validation(data: { email: string; password: string }) {
    if (data.email === "") {
      return "Please enter email address";
    }
    // tslint:disable-next-line:max-line-length
    const regex =
      /[a-z0-9!#$%&'*+/=?^_{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    const re = new RegExp(regex);
    if (!re.test(data.email)) {
      return "Please enter correct email address";
    }
    if (data.password === "") {
      return "Please enter password";
    }
    if (data.password.length < 6) {
      return "Password must be at least 6 character long.";
    }
    return null;
  }

  toggleShowHidePassword() {
    this.showHidePassword = !this.showHidePassword;
  }

  getControls(name: string) {
    return this.authForm.get(name);
  }

  async onSubmit(passwordRef: HTMLInputElement, emailRef: HTMLInputElement) {
    const data = this.authForm.value;

    if (passwordRef && passwordRef.value && !data.password) {
      this.authForm.patchValue({
        password: passwordRef.value,
      });
      data.password = passwordRef.value;
    }
    if (emailRef && emailRef.value && !data.email) {
      this.authForm.patchValue({
        email: emailRef.value,
      });
      data.email = emailRef.value;
    }

    const response = this.validation(data);

    if (response) {
      this.notificationService.showError(response);
      return;
    }

    const loading = await this.loadingController.create();
    await loading.present();

    const res = await this.authService.login(data.email, data.password).catch((error) => {
      console.error("Error: ", error);
      let message = this.helperServ.mapErrorCode(error);
      this.notificationService.showError(message);
    });

    if (res) {
      if (this.routeHome) {
        const path = Capacitor.getPlatform() == "web" ? "/download-app" : "/home/plan";
        await this.navCtrl.navigateRoot(path);
      }
      await this.close(res);
      this.analyticsServ.logEvent("login_page_finished", { auth_method: "email", onboarding: false, signup: false });
    } else {
      this.analyticsServ.logEvent("login_page_finished", { auth_method: "email", onboarding: true, signup: false });
    }

    await this.loadingController.dismiss();
  }

  async openForgotPassword() {
    if (this.email) {
      await this.authService.forgotPassword(this.email);
    } else {
      const modal = await this.modalCtrl.create({ component: ForgotPasswordPage });
      await modal.present();
    }
  }

  async close(data?: any) {
    if (!this.isWeb || (this.isWeb && (await this.modalCtrl.getTop()))) {
      return this.modalCtrl.dismiss(data);
    }
  }
}
