import { AfterViewInit, Component, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalController, Platform } from "@ionic/angular";
import * as moment from "moment";
import { DndDropEvent } from "ngx-drag-drop";
import { AuthComponent } from "src/app/modals/auth/auth.component";
import { AchievementService } from "src/app/services/achievement.service";
import { HelperService } from "src/app/services/helper.service";
import { SubscriptionService } from "src/app/services/subscription.service";
import { TranslateConfigService } from "src/app/services/translate-config.service";
import { WeightProgressService } from "src/app/services/weight-progress.service";
import { OnboardingService } from "../../services/onboarding.service";
import { PlanService } from "../../services/plan.service";
import { UserService } from "../../services/user.service";
import { BottomSheetComponent, SheetStates } from "../../shared/bottom-sheet/bottom-sheet-component";

@Component({
  selector: "app-plan-constructor",
  templateUrl: "./plan-constructor.page.html",
  styleUrls: ["./plan-constructor.page.scss"],
})
export class PlanConstructorPage implements AfterViewInit {
  @ViewChild("authModal") authModal: AuthComponent;

  language: any;

  bottomSheetRef: any;
  workoutTypeList: Array<any>;
  weekDays: Array<any>;
  originWeekDays: Array<any>;
  backButtonRef: any;
  showRemoveBtn: string;
  infoString: string;

  loading: boolean = false;
  loadingPlan = false;

  workoutFontSizes: string[] = [];
  workoutSelectedFontSizes: string[] = [];

  constructor(
    private platform: Platform,
    private route: ActivatedRoute,
    private onboardingService: OnboardingService,
    private router: Router,
    private userService: UserService,
    private planService: PlanService,
    private subServ: SubscriptionService,
    private helperServ: HelperService,
    private achServ: AchievementService,
    private weightProgressServ: WeightProgressService,
    private translateConfigServ: TranslateConfigService,
    private modalCtrl: ModalController
  ) {
    this.language = this.translateConfigServ.getCurrentLang();

    this.platform.ready().then(() => {
      this.init();
    });
  }

  goBack(success: boolean = false) {
    if (!success) {
      this.router.navigate([], {
        queryParams: { slide: "end", avoidInit: true },
      });
    }
    return this.modalCtrl.dismiss();
  }

  ionViewWillEnter() {
    this.backButtonRef = this.platform.backButton.subscribeWithPriority(100, () => {
      this.goBack();
    });
  }

  ionViewWillLeave() {
    if (this.backButtonRef) {
      this.backButtonRef.unsubscribe();
    }
    this.showRemoveBtn = "";
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.adjustFontSize("workout-name").then((fontSizes) => {
        this.workoutFontSizes = fontSizes;
      });
    }, 0);
    setTimeout(() => {
      this.adjustFontSize("week-day-p").then((fontSizes) => {});
    }, 0);
  }

  onInViewportChange(event, header: HTMLElement) {
    if (!event) {
      header.classList.add("enabled");
    } else {
      header.classList.remove("enabled");
    }
  }

  init() {
    this.workoutTypeList = [
      { name: "Chest", value: "chest" },
      { name: "Biceps", value: "biceps" },
      { name: "Abs", value: "abs" },
      { name: "Triceps", value: "triceps" },
      { name: "Shoulders", value: "shoulders" },
      { name: "Legs", value: "legs" },
      { name: "Back", value: "back" },
      { name: "Forearms", value: "forearms" },
      { name: "Total Body", value: "total body" },
      { name: "Cardio", value: "cardio" },
      { name: "HIIT", value: "hiit" },
      { name: "Planks", value: "planks" },
      { name: "Upper Body Stretch", value: "upper body stretch" },
      { name: "Lower Body Stretch", value: "lower body stretch" },
    ];

    if (this.platform.width() < 369) {
      this.originWeekDays = [
        { id: 0, name: "sun", data: [{ name: "Rest", value: "rest" }] },
        { id: 1, name: "mon", data: [{ name: "Rest", value: "rest" }] },
        { id: 2, name: "tue", data: [{ name: "Rest", value: "rest" }] },
        { id: 3, name: "wed", data: [{ name: "Rest", value: "rest" }] },
        { id: 4, name: "thu", data: [{ name: "Rest", value: "rest" }] },
        { id: 5, name: "fri", data: [{ name: "Rest", value: "rest" }] },
        { id: 6, name: "sat", data: [{ name: "Rest", value: "rest" }] },
      ];
    } else {
      this.originWeekDays = [
        { id: 0, name: "Sunday", data: [{ name: "Rest", value: "rest" }] },
        { id: 1, name: "Monday", data: [{ name: "Rest", value: "rest" }] },
        { id: 2, name: "Tuesday", data: [{ name: "Rest", value: "rest" }] },
        { id: 3, name: "Wednesday", data: [{ name: "Rest", value: "rest" }] },
        { id: 4, name: "Thursday", data: [{ name: "Rest", value: "rest" }] },
        { id: 5, name: "Friday", data: [{ name: "Rest", value: "rest" }] },
        { id: 6, name: "Saturday", data: [{ name: "Rest", value: "rest" }] },
      ];
    }
    this.weekDays = JSON.parse(JSON.stringify(this.originWeekDays));
  }

  resetPlan(bottomSheet: BottomSheetComponent) {
    this.bottomSheetRef = bottomSheet;
    bottomSheet.setState(SheetStates.Closed);
    this.weekDays = JSON.parse(JSON.stringify(this.originWeekDays));
  }

  hideModal(bottomSheet: BottomSheetComponent) {
    this.bottomSheetRef = bottomSheet;
    bottomSheet.setState(SheetStates.Closed);
    this.loading = false;
  }

  openModal(bottomSheet: BottomSheetComponent) {
    this.bottomSheetRef = bottomSheet;
    setTimeout(() => {
      bottomSheet.setState(SheetStates.Opened);
    }, 150);
  }

  async submit(bottomSheet: BottomSheetComponent, bottomSheetInfo: BottomSheetComponent, event?: boolean) {
    let isSelectedWorkout = false;
    this.weekDays.forEach((day) => {
      if (day.data[0].name !== "Rest") {
        isSelectedWorkout = true;
      }
    });
    if (!isSelectedWorkout) {
      this.bottomSheetRef = bottomSheetInfo;
      this.infoString = "You haven't selected any workout";
      setTimeout(() => {
        bottomSheetInfo.setState(SheetStates.Opened);
      }, 150);
      return;
    }

    if (event) {
      await this.delay(300);
    }

    const user = this.userService.getSyncUserOnce();
    if (!user?.uid) {
      this.authModal.openModal();
      return;
    }
    this.loading = true;

    const showAlert = user.plan && user.plan.endWorkoutPlanDate ? moment(user.plan.endWorkoutPlanDate).isAfter(moment(), "day") : false;

    if (showAlert) {
      this.bottomSheetRef = bottomSheet;
      setTimeout(() => {
        bottomSheet.setState(SheetStates.Opened);
      }, 150);
      return;
    }
    this.updatePlan(bottomSheet);
  }

  async updatePlan(bottomSheet: BottomSheetComponent) {
    this.loadingPlan = true;
    bottomSheet.setState(SheetStates.Closed);
    this.bottomSheetRef = bottomSheet;
    await this.helperServ.presentLoader();

    const pages = this.onboardingService.getOnboardingPageKeys();
    const formValue = this.onboardingService.getOnboardingForm().value;

    if (!pages || !Object.keys(pages).length || pages.currentUserWeight) {
      await this.weightProgressServ.setInitialUserWeight(formValue.currentUserWeight);
    }

    this.onboardingService.setWeekdays(this.weekDays);

    await this.onboardingService.setPlan();
    await this.achServ.updateCustomPlanAchievement();

    if (this.weekDays.some((e) => e.isMerge)) {
      await this.achServ.updateMergerAchievement();
    }

    await this.delay(500);
    this.onboardingService.resetOnboardingForm();
    await this.helperServ.dismissLoader();
    await this.router.navigate(["home", "plan"], {
      replaceUrl: true,
    });
    await this.delay(1000);
    await this.goBack(true);
  }

  combineUncombineWorkout(day, bottomSheet: BottomSheetComponent) {
    const weekDayIndex = this.weekDays.findIndex((e) => e.name === day.name);
    if (this.weekDays[weekDayIndex].data.findIndex((e) => e.name === "Total Body") !== -1) {
      this.infoString = "Sorry, Total Body cannot merge with any other workout";
      this.bottomSheetRef = bottomSheet;
      setTimeout(() => {
        bottomSheet.setState(SheetStates.Opened);
      }, 150);
      return;
    } else if (this.weekDays[weekDayIndex].data.findIndex((e) => e.name === "Cardio") !== -1) {
      this.infoString = "Sorry, Cardio cannot merge with any other workout";
      this.bottomSheetRef = bottomSheet;
      setTimeout(() => {
        bottomSheet.setState(SheetStates.Opened);
      }, 150);
      return;
    } else if (this.weekDays[weekDayIndex].data.findIndex((e) => e.name === "HIIT") !== -1) {
      this.infoString = "Sorry, HIIT cannot merge with any other workout";
      this.bottomSheetRef = bottomSheet;
      setTimeout(() => {
        bottomSheet.setState(SheetStates.Opened);
      }, 150);
      return;
    } else if (
      this.weekDays[weekDayIndex].data.findIndex((e) => e.name === "Upper Body Stretch") !== -1 &&
      this.weekDays[weekDayIndex].data.findIndex((e) => e.name === "Lower Body Stretch") === -1
    ) {
      this.infoString = "Sorry, Upper Body Stretch cannot merge with any other workout besides Lower Body Stretch";
      this.bottomSheetRef = bottomSheet;
      setTimeout(() => {
        bottomSheet.setState(SheetStates.Opened);
      }, 150);
      return;
    } else if (
      this.weekDays[weekDayIndex].data.findIndex((e) => e.name === "Lower Body Stretch") !== -1 &&
      this.weekDays[weekDayIndex].data.findIndex((e) => e.name === "Upper Body Stretch") === -1
    ) {
      this.infoString = "Sorry, Lower Body Stretch cannot merge with any other workout besides Upper Body Stretch";
      this.bottomSheetRef = bottomSheet;
      setTimeout(() => {
        bottomSheet.setState(SheetStates.Opened);
      }, 150);
      return;
    }

    if (this.weekDays[weekDayIndex].data[0].name === this.weekDays[weekDayIndex].data[2].name) {
      this.infoString = "Sorry, you cannot combine 2 identical workouts";
      this.bottomSheetRef = bottomSheet;
      setTimeout(() => {
        bottomSheet.setState(SheetStates.Opened);
      }, 150);
      return;
    }
    this.weekDays[weekDayIndex].isMerge = !this.weekDays[weekDayIndex].isMerge;
  }

  onDragStart(event: DragEvent) {
    this.showRemoveBtn = "show";
  }

  onDragEnd(event: DragEvent) {
    this.showRemoveBtn = "hide";
  }

  onDragged(event: DndDropEvent, weekDay: { name: string; data: Array<any> }, index: number) {
    const weekDayIndex = this.weekDays.findIndex((e) => e.name === weekDay.name);
    if (this.weekDays[weekDayIndex].data.length > 1) {
      const newItem = index === 0 ? this.weekDays[weekDayIndex].data[2] : this.weekDays[weekDayIndex].data[0];
      this.weekDays[weekDayIndex].data = [newItem];
      this.weekDays[weekDayIndex].isMerge = false;
    } else {
      this.weekDays[weekDayIndex].data = [{ name: "Rest", value: "rest" }];
      this.weekDays[weekDayIndex].isMerge = false;
    }
  }

  onDropWorkout(event: DndDropEvent, weekDay: { name: string; data: Array<any> }, index: number) {
    const weekDayIndex = this.weekDays.findIndex((e) => e.name === weekDay.name);

    this.weekDays[weekDayIndex].isMerge = false;

    if (this.weekDays[weekDayIndex].data[0].name === "Rest") {
      this.weekDays[weekDayIndex].data[index] = event.data;
      this.adjustFontSize("workout-selected").then((fontSizes) => {
        this.workoutSelectedFontSizes = fontSizes;
      });
      return;
    }

    if (weekDay.data.length < 2) {
      this.weekDays[weekDayIndex].data.push({ isMergeIcon: true });
      this.weekDays[weekDayIndex].data.push(event.data);
    } else {
      this.weekDays[weekDayIndex].data[index] = event.data;
    }

    this.adjustFontSize("workout-selected").then((fontSizes) => {
      this.workoutSelectedFontSizes = fontSizes;
    });
  }

  async adjustFontSize(classSelector: string) {
    return new Promise<string[]>(async (resolve, reject) => {
      const fontSizes = [];
      const promises = [];
      const elems = document.getElementsByClassName(classSelector) as HTMLCollectionOf<HTMLParagraphElement>;

      for (let i = 0; i < elems.length; i++) {
        let baseSize = +elems[i].style.fontSize.slice(0, -2);
        const promise: Promise<void> = new Promise((res, rej) => {
          const fontSizeInterval = setInterval(() => {
            try {
              const textLength = elems[i].offsetHeight;
              const offsetHeight = elems[i].parentElement.offsetHeight;
              if (textLength >= offsetHeight) {
                if (baseSize > 3) {
                  baseSize--;
                  elems[i].style.fontSize = `${baseSize}px`;
                  fontSizes[i] = `${baseSize}px`;
                } else {
                  res();
                  clearInterval(fontSizeInterval);
                }
              } else {
                res();
                clearInterval(fontSizeInterval);
              }
            } catch (e) {
              res();
              clearInterval(fontSizeInterval);
            }
          }, 25);
        });
        promises.push(promise);
      }

      await Promise.all(promises);
      resolve(fontSizes);
    });
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
