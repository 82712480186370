import { getCurrencySymbol } from "@angular/common";
import { AfterContentInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Capacitor } from "@capacitor/core";
import { StatusBar, Style } from "@capacitor/status-bar";
import { ScreenOrientation } from "@ionic-native/screen-orientation";
import { ModalController, Platform } from "@ionic/angular";
import { Subscription } from "rxjs";
import {
  CHOOSE_PLANS,
  SALES_VIDEO_JOE,
  SALES_VIDEO_THUMBNAIL_JOE,
  SUB_OPTIONS_NO_ADS,
  SUB_OPTIONS_WITH_ADS,
  WEB_CHOOSE_PLANS,
  userDesiredResultsData,
} from "src/app/constants";
import { PaymentComponent } from "src/app/modals/payment/payment.component";
import { SubOption } from "src/app/models/sale";
import { PrivacyPolicyPage } from "src/app/pages/privacy-policy/privacy-policy.page";
import { RemoteConfigSale } from "src/app/pages/profile/profile.page";
// import { RemoteConfigSale } from "src/app/pages/sales/sales.page";
import { SubscriptionTermsPage } from "src/app/pages/subscription-terms/subscription-terms.page";
import { TermsAndConditionsPage } from "src/app/pages/terms-and-conditions/terms-and-conditions.page";
import { WeightPipe } from "src/app/pipes/weight.pipe";
import { AnalyticsService } from "src/app/services/analytics.service";
import { OnboardingService } from "src/app/services/onboarding.service";
import { SubscriptionService } from "src/app/services/subscription.service";
import { TranslateConfigService } from "src/app/services/translate-config.service";
import { UserService } from "src/app/services/user.service";
import SwiperCore, { EffectCoverflow, FreeMode, Lazy, Navigation, Pagination, SwiperOptions } from "swiper";

SwiperCore.use([EffectCoverflow, Lazy, Pagination, Navigation, FreeMode]);

interface PriceTable {
  country: string;
  currencyCode: string;
  price: number;
  tax: string | number;
}

@Component({
  selector: "app-sale-modal",
  templateUrl: "./sale-modal.component.html",
  styleUrls: ["./sale-modal.component.scss"],
})
export class SaleModalComponent implements OnInit, AfterContentInit, OnDestroy {
  @ViewChild("salesVideo", { static: true }) salesVideoRef: ElementRef;
  salesVideo: HTMLVideoElement;

  @ViewChild("paymentModal") paymentModal: PaymentComponent;

  // @ViewChild(IonSlides) slider: IonSlides;

  // @Input() plan;
  @Input() exerciseInfo;
  @Input() workouts;
  @Input() parent = "onboarding";

  isWeb = Capacitor.getPlatform() == "web";

  userPlan: string = "";

  CHOOSE_PLANS = this.isWeb ? WEB_CHOOSE_PLANS : CHOOSE_PLANS;

  language: any;

  public waitingLayer: boolean = false;

  isAndroid: boolean;
  waitingChecker: any; //NodeJS.Timeout;

  subOptions: Array<SubOption> = [];

  saleConfig: RemoteConfigSale;
  selectedSub: SubOption;

  isFreemium = false;

  canFreeTrial = true;

  subscriptions: Subscription[] = [];

  slideOpts: SwiperOptions = {
    speed: 300,
    pagination: false,
    slidesPerView: "auto",
    freeMode: {
      enabled: this.isWeb && this.platform.is("desktop"),
      sticky: this.isWeb && this.platform.is("desktop"),
    },
  };

  screenshotSlideOpts: SwiperOptions = {
    slidesPerView: "auto",
    spaceBetween: 20,
    lazy: {
      enabled: true,
      loadPrevNext: true,
      loadPrevNextAmount: 5,
      loadOnTransitionStart: true,
    },
    preloadImages: false,
    watchSlidesProgress: true,
    centeredSlides: true,
    effect: "coverflow",
    coverflowEffect: { scale: 0.9, rotate: 0, slideShadows: true },
    loop: true,
    pagination: true,
    navigation: true,
  };

  saleOptions: Array<any> = [
    {
      title: "What makes your app different? How do I know if it will work for me?",
      description: "workout_program_explanation",
      condition: "any",
    },
    {
      title: "equipment",
      description: `You have chosen our "Overall Health & Wellness" program, you will need a few sets of dumbbells (ranging between ${this.weightPipe.transform(
        "5 to 30 lbs",
        this.userServ.getSyncUserOnce()?.metric || "us",
        true
      )}). While a yoga mat or soft floor is suggested for certain exercises, it\'s not a requirement. The use of an armless chair is optional and can be turned on or off in your profile settings.`,
      condition: "Overall Health & Wellness",
    },
    {
      title: "equipment",
      description: `You have chosen our "Build Muscle" program, you will need a few sets of dumbbells (ranging between ${this.weightPipe.transform(
        "5 to 30 lbs",
        this.userServ.getSyncUserOnce()?.metric || "us",
        true
      )}). While a yoga mat or soft floor is suggested for certain exercises, it\'s not a requirement. The use of an armless chair is optional and can be turned on or off in your profile settings.`,
      condition: "Build Muscle",
    },
    {
      title: "equipment",
      description: 'You have chosen our "Lose Weight" program and for this program you will need no equipment.',
      condition: "Lose Weight",
    },
    {
      title: "equipment",
      description:
        "You have chosen our \"6 Pack\", no equipment is required. While a yoga mat or soft ground is recommended for certain exercises, it's not essential. You also have the option to incorporate an armless chair into your routine by heading to profile, but again, it's completely optional.",
      condition: "6 Pack",
    },
    {
      title: "equipment",
      description: `You have chosen our "Flexibility", no equipment is required. While a yoga mat or soft ground is recommended for certain exercises, it's not essential. You also have the option to incorporate an armless chair into your routine by heading to profile, but again, it's completely optional.`,
      condition: "Flexibility",
    },
    {
      title: "diet",
      description:
        "You will see results either way, but with eating properly according to your goal, body type and current stats will definitely provide you the best results possible. For more details check out our Macro Calculator section and click on the information button.",
      condition: "any",
    },
    {
      title: "beginner_workouts",
      description:
        "My workouts are scalable to any fitness level. Plus, with easy-to-follow video demonstration on each and every exercise, you'll never be left wondering how to do a move!",
      condition: "any",
    },
    {
      title: "gym_membership",
      description:
        "A gym is not required, all of our workouts are designed to give you a gym quality workout from home in a fraction of the time.",
      condition: "any",
    },
    {
      title: "not_free",
      description:
        "This is the only way for us to make improvements in terms of adding more features, improving existing features, fixing bugs, designing, and developing. It helps create & maintain a better app that supports people from all over the world to achieve their fitness goals & dreams.",
      condition: "any",
    },
  ];

  saleVideoUrl: any;
  salesThumbnail: string;
  salesVideoText: { title: string; description: string };
  isPlayed = false;

  isFullScreen = false;

  orientationSub: Subscription;
  iapSub: Subscription;

  currencySymbol = "$";

  constructor(
    private platform: Platform,
    private modalCtrl: ModalController,
    public subscriptionService: SubscriptionService,
    private onboardingServ: OnboardingService,
    private translateConfigServ: TranslateConfigService,
    public userServ: UserService,
    private weightPipe: WeightPipe,
    private sanitizer: DomSanitizer,
    private analyticsServ: AnalyticsService
  ) {
    this.language = this.translateConfigServ.getCurrentLang();

    this.isAndroid = this.platform.is("android");

    this.subscriptions.push(
      this.subscriptionService.observeInAppProducts().subscribe((products) => {
        this.subOptions = [];
        products.forEach((option) => {
          const subOption = { ...option };
          if (subOption.time == "6") {
            this.selectedSub = subOption;
          }
          this.subOptions.push({ ...subOption, checked: subOption.time == "6" });
        });

        this.currencySymbol = getCurrencySymbol(
          this.subOptions[0].currencyCode ? this.subOptions[0].currencyCode : "USD",
          "narrow",
          this.language
        );
      })
    );

    const user = this.userServ.getSyncUserOnce();
    console.log("User: ", user);
    this.userPlan = user?.desiredResults;

    if (Capacitor.getPlatform() == "web" && !this.userPlan) {
      this.userPlan = this.onboardingServ.getControl("desiredResults")?.value;
    }

    let removeList = [];
    for (let index = 0; index < this.saleOptions.length; index++) {
      const element = this.saleOptions[index];
      if (element.condition != "any") {
        if (element.condition != this.userPlan) {
          removeList.push(index);
        }
      }
    }
    for (let index2 = removeList.length - 1; index2 >= 0; index2--) {
      const element = removeList[index2];
      this.saleOptions.splice(element, 1);
    }

    userDesiredResultsData.forEach((goal) => {
      if (goal.title == this.userPlan) {
        this.saleVideoUrl = this.sanitizer.bypassSecurityTrustUrl(goal.salesVideo);
        this.salesThumbnail = goal.thumbnail;
        this.salesVideoText = {
          title: goal.videoTitle,
          description: goal.videoDescription,
        };
      }
    });

    if (!this.saleVideoUrl) {
      this.saleVideoUrl = this.sanitizer.bypassSecurityTrustUrl(SALES_VIDEO_JOE);
    }

    if (!this.salesThumbnail) {
      this.salesThumbnail = SALES_VIDEO_THUMBNAIL_JOE;
    }

    this.orientationSub = ScreenOrientation.onChange().subscribe(async (event) => {
      if (this.platform.is("capacitor")) {
        if (ScreenOrientation.type.includes(ScreenOrientation.ORIENTATIONS.LANDSCAPE)) {
          await StatusBar.hide();
        } else {
          await StatusBar.show();
        }
      }
    });
  }

  async ngOnInit() {
    await this.analyticsServ.logEvent("sale_modal_opened", {});
    this.canFreeTrial = await this.subscriptionService.checkCanFreeTrial();

    this.subscriptions.push(
      this.platform.backButton.subscribeWithPriority(101, () => {
        if (this.parent == "subscription") {
          if (this.isFullScreen) {
            document.exitFullscreen();
            return;
          } else {
            this.dismiss();
          }
        }
        // Disabled
        return;
      })
    );

    this.isFreemium = this.subscriptionService.isFreemium();
  }

  ionViewWillEnter() {
    if (!this.platform.is("capacitor")) {
      return;
    }
    StatusBar.setStyle({ style: Style.Dark });
  }

  ionViewWillLeave() {
    if (!this.platform.is("capacitor")) {
      return;
    }

    StatusBar.setStyle({ style: Style.Dark });
  }

  ngAfterContentInit(): void {
    this.salesVideo = this.salesVideoRef?.nativeElement;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.orientationSub?.unsubscribe();
  }

  selectSub(sub, index) {
    const currIndex = this.subOptions.findIndex((op) => op.checked);
    if (currIndex !== -1 && currIndex !== index) {
      this.subOptions[currIndex].checked = !this.subOptions[currIndex].checked;
      this.subOptions[index].checked = !this.subOptions[index].checked;
    }
    this.selectedSub = sub;
  }

  async purchase() {
    if (this.isWeb) {
      this.paymentModal.openModal();
    } else {
      this.waitingChecker = setInterval(() => {
        if (this.subscriptionService.waitingStatus == false) {
          this.waitingLayer = false;
          console.log(`#### Waiting satus :: 1 :: ${this.subscriptionService.waitingStatus}`);
          clearInterval(this.waitingChecker);
        } else if (this.subscriptionService.waitingStatus == true) {
          this.waitingLayer = true;
        } else {
          console.log(`#### Waiting satus :: 3 :: ${this.subscriptionService.waitingStatus}`);
        }
      }, 500);

      const selectedSub = this.subOptions.find((op) => op.checked);
      console.log(`#### Subscription: ${JSON.stringify(selectedSub)}`);

      this.subscriptionService.makePurchase(selectedSub.name);
    }
  }

  async openTerms() {
    const modal = await this.modalCtrl.create({
      component: TermsAndConditionsPage,
      componentProps: { modal: true },
    });
    await modal.present();
  }

  async dismiss() {
    if (this.parent != "subscription") {
      await this.onboardingServ.setPlan();

      setTimeout(async () => {
        await this.modalCtrl.dismiss();
      }, 500);
    } else {
      await this.modalCtrl.dismiss();
    }
  }

  async openSubscription() {
    const modal = await this.modalCtrl.create({
      component: SubscriptionTermsPage,
      componentProps: { modal: true },
    });

    await modal.present();
  }

  async openPrivacyPolicy() {
    const modal = await this.modalCtrl.create({
      component: PrivacyPolicyPage,
      componentProps: { modal: true },
    });
    await modal.present();
  }

  noAdsChange(ev) {
    return ev.detail.checked ? this.prepareAdsOptions() : this.prepareNoAdsOptions();
  }

  prepareAdsOptions() {
    for (let i = 0; i < this.subOptions.length; i++) {
      const sub = this.subOptions[i];
      console.log("SUB OPTIONS", SUB_OPTIONS_NO_ADS);
      switch (sub.name) {
        case "com.tv.12monthlb":
          sub.name = SUB_OPTIONS_WITH_ADS[i].name;
          sub.finalCost = SUB_OPTIONS_WITH_ADS[i].finalCost;
          sub.cost = SUB_OPTIONS_WITH_ADS[i].cost;
          sub.discount = SUB_OPTIONS_WITH_ADS[i].discount;
          break;

        case "com.tv.6monthlb":
          sub.name = SUB_OPTIONS_WITH_ADS[i].name;
          sub.finalCost = SUB_OPTIONS_WITH_ADS[i].finalCost;
          sub.cost = SUB_OPTIONS_WITH_ADS[i].cost;
          sub.discount = SUB_OPTIONS_WITH_ADS[i].discount;
          break;

        case "com.tv.monthlb":
          sub.name = SUB_OPTIONS_WITH_ADS[i].name;
          sub.finalCost = SUB_OPTIONS_WITH_ADS[i].finalCost;
          sub.cost = SUB_OPTIONS_WITH_ADS[i].cost;
          sub.discount = SUB_OPTIONS_WITH_ADS[i].discount;
          break;

        default:
          break;
      }
    }

    console.log("Sub options", this.subOptions, SUB_OPTIONS_NO_ADS);
  }

  prepareNoAdsOptions() {
    for (let i = 0; i < this.subOptions.length; i++) {
      const sub = this.subOptions[i];
      console.log("Sub: ", this.subOptions[i]);
      switch (sub.name) {
        case "com.tv.yearlyads":
          //case "com.tv.yearlyads.lowprice":
          sub.name = SUB_OPTIONS_NO_ADS[i].name;
          sub.finalCost = SUB_OPTIONS_NO_ADS[i].finalCost;
          sub.cost = SUB_OPTIONS_NO_ADS[i].cost;
          sub.discount = SUB_OPTIONS_NO_ADS[i].discount;
          break;

        case "com.tv.halfyearlyads":
          //case "com.tv.threemonthlywithads.lowprice":
          sub.name = SUB_OPTIONS_NO_ADS[i].name;
          sub.finalCost = SUB_OPTIONS_NO_ADS[i].finalCost;
          sub.cost = SUB_OPTIONS_NO_ADS[i].cost;
          sub.discount = SUB_OPTIONS_NO_ADS[i].discount;
          break;

        case "com.tv.monthads":
          sub.name = SUB_OPTIONS_NO_ADS[i].name;
          sub.finalCost = SUB_OPTIONS_NO_ADS[i].finalCost;
          sub.cost = SUB_OPTIONS_NO_ADS[i].cost;
          sub.discount = SUB_OPTIONS_NO_ADS[i].discount;
          break;

        default:
          console.log("Well shoot man");
          break;
      }
      console.log("I'm confused here", this.subOptions);
    }

    console.log("Sub options here", this.subOptions);
  }

  async playVideo() {
    this.isPlayed = true;
    if (this.isWeb) {
      const vid = document.getElementById("salesVideo") as HTMLVideoElement;
      await vid.play();
    } else {
      await this.salesVideo.play();
    }
  }

  videoEnded() {
    this.isPlayed = false;
  }
  screenChange(ev): void {
    if (!this.platform.is("hybrid")) {
      return;
    }

    this.isFullScreen = ev.target.webkitDisplayingFullscreen;

    if (this.isFullScreen) {
      this.lockToLandscape();
    } else {
      this.lockToPortrait();
    }
  }

  private async lockToPortrait(): Promise<void> {
    await ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT);
  }

  private async lockToLandscape(): Promise<void> {
    await ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.LANDSCAPE);
  }

  onInViewport({ target, visible }: { target: Element; visible: boolean }) {
    if (!this.platform.is("capacitor")) {
      return;
    }
    if (visible) {
      StatusBar.setStyle({ style: Style.Dark });
    } else {
      StatusBar.setStyle({ style: Style.Light });
    }
  }
}
