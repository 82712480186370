import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AppLauncher } from "@capacitor/app-launcher";
import { Capacitor } from "@capacitor/core";
import { AppAvailability } from "@ionic-native/app-availability/ngx";
import { AnimationController, LoadingController, ModalController, Platform, ToastController } from "@ionic/angular";
import { TranslatePipe } from "@ngx-translate/core";
import { CupertinoPane, CupertinoSettings } from "cupertino-pane";
import { FirebaseError } from "firebase/app";
import { Achievement } from "src/app/constants/achievements";
import { SaleModalComponent } from "../components/sale-modal/sale-modal.component";
import { RECCOMMENDED_ITEMS } from "../constants";
import { PlanConstructorPage } from "../pages/plan-constructor/plan-constructor.page";
import { TranslateConfigService } from "./translate-config.service";
import { UserService } from "./user.service";
export interface RecItem {
  id: string;
  title: string;
  description: string;
  iosLink: string;
  icon: string;
  bundleId: string;
}
@Injectable({
  providedIn: "root",
})
export class HelperService {
  toasts: { pane: CupertinoPane; toast: HTMLIonToastElement }[] = [];

  language: any;
  constructor(
    private animationCtrl: AnimationController,
    private modalCtrl: ModalController,
    private toastCtrl: ToastController,
    private router: Router,
    private translateConfigService: TranslateConfigService,
    private translatePipe: TranslatePipe,
    private loadingController: LoadingController,
    private platform: Platform,
    private appAvailability: AppAvailability,
    private userServ: UserService
  ) {
    this.language = this.translateConfigService.getCurrentLang();
  }

  async presentPlanConstructor() {
    const modal = await this.modalCtrl.create({
      component: PlanConstructorPage,
      componentProps: {},
      leaveAnimation: this.leaveAnimation,
      enterAnimation: this.enterAnimation,
      cssClass: ["no-enter-animation"],
    });
    await modal.present();
    return modal.onWillDismiss();
  }

  async presentLoader() {
    const loading = await this.loadingController.create();
    return loading.present();
  }

  async dismissLoader() {
    return this.loadingController.dismiss();
  }

  sendBugEmail() {
    const emailBody = `Hello, \n\nPlease fill out the details below\n\nPlatform: ${Capacitor.getPlatform()}\nDescription of the issue:\nSteps to reproduce the issue:\n\nAlso, please attach a screenshot and/or a screen recording.`;
    const emailRecipient = "support@lifebuddymobileapp.com";
    const emailSubject = "LifeBuddy Support Request";
    window.open(`mailto:${emailRecipient}?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`);
  }

  sendSupportEmail() {
    const emailBody = ``;
    const emailRecipient = "support@lifebuddymobileapp.com";
    const emailSubject = `LifeBuddy (${Capacitor.getPlatform()}) Support Request`;
    window.open(`mailto:${emailRecipient}?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`);
  }

  // async presentSalesModal(goal: string, modalId = 2) {
  //   switch (Number(modalId)) {
  //     case 2:
  //       this.presentSalesModalTwo(goal);
  //       break;
  //     case 3:
  //       this.presentSalesModalThree(goal);
  //       break;
  //   }
  // }

  async presentSalesModalOne(parent: "constructor" | "lifebuddy" | "subscription") {
    const modal = await this.modalCtrl.create({
      component: SaleModalComponent,
      componentProps: { parent },
      leaveAnimation: this.leaveAnimation,
      enterAnimation: this.enterAnimation,
      cssClass: ["no-enter-animation"],
    });
    await modal.present();
    if (parent === "subscription") {
      return modal.onWillDismiss();
    } else {
      return;
    }
  }

  // async presentSalesModalTwo(goal, plan?, exerciseInfo?, workouts?) {
  //   const modal = await this.modalCtrl.create({
  //     id: "saleModalTwo",
  //     component: SaleModalTwoComponent,
  //     componentProps: { goal, plan, exerciseInfo, workouts },
  //     leaveAnimation: this.leaveAnimation,
  //     enterAnimation: this.enterAnimation,
  //     cssClass: ["no-enter-animation"],
  //   });
  //   await modal.present();
  // }

  // async presentSalesModalThree(goal, plan?, exerciseInfo?, workouts?) {
  //   const modal = await this.modalCtrl.create({
  //     id: "saleModalTwo",
  //     component: SaleModalThreeComponent,
  //     componentProps: { goal, plan, exerciseInfo, workouts },
  //     leaveAnimation: this.leaveAnimation,
  //     enterAnimation: this.enterAnimation,
  //     cssClass: ["no-enter-animation"],
  //   });
  //   await modal.present();
  // }

  enterAnimation = (baseEl: any) => {
    const root = baseEl.shadowRoot;

    const backdropAnimation = this.animationCtrl
      .create()
      .addElement(root.querySelector("ion-backdrop")!)
      .fromTo("opacity", "0.01", "var(--backdrop-opacity)");

    const wrapperAnimation = this.animationCtrl
      .create()
      .addElement(root.querySelector(".modal-wrapper")!)
      .keyframes([{ opacity: "0.01" }, { opacity: "0.99" }]);

    return this.animationCtrl
      .create()
      .addElement(baseEl)
      .easing("ease-out")
      .duration(250)
      .addAnimation([backdropAnimation, wrapperAnimation]);
  };

  leaveAnimation = (baseEl: any) => {
    return this.enterAnimation(baseEl).direction("reverse");
  };

  // async presentAchievementToast(ach: Achievement) {
  //   const toast = await this.toastCtrl.create({
  //     id: Math.round(Math.random() * 1000).toString(),
  //     header: ach.title,
  //     message: ach.description,
  //     position: "top",
  //     duration: 5000,
  //     cssClass: "achievement-toast",
  //     mode: "md",
  //     buttons: [
  //       {
  //         icon: "assets/achievements/" + ach.id + ".svg",
  //         side: "start",
  //         cssClass: "achievement-toast-icon",
  //       },
  //     ],
  //   });

  //   // this.toasts.push(toast);

  //   if (this.toasts.length == 1) {
  //     await toast.present();
  //   }

  //   toast.onDidDismiss().then(async (res) => {
  //     this.toasts.splice(0, 1);
  //     if (this.toasts.length > 0) {
  //       this.toasts[0]?.present();
  //     }
  //   });
  // }

  async presentAchievementToast(ach: Achievement) {
    const toastDuration = 5000;
    const id = "achievement-toast-" + Math.round(Math.random() * 1000).toString();
    const toast = await this.toastCtrl.create({
      id: id,
      header: this.translatePipe.transform(ach.title),
      message: this.translatePipe.transform(ach.description),
      position: "top",
      duration: toastDuration,
      mode: "md",
      buttons: [
        {
          icon: "assets/achievements/" + ach.id + ".svg",
          side: "start",
          cssClass: "achievement-toast-icon",
        },
      ],
    });

    toast.classList.remove("overlay-hidden");
    toast.style.minHeight = "inherit";

    let style = document.createElement("style");
    style.textContent = `
    .pane {
      opacity: 1 !important;
      pointer-events: none;
    }
    `;
    toast.shadowRoot.querySelector(".toast-wrapper").appendChild(style); //.classList.add("pane");
    toast.shadowRoot.querySelector(".toast-wrapper").classList.add("pane");

    toast.onclick = async () => {
      this.router.navigate(["/achievements"]);
      await this.toasts[0].pane.destroy({ animate: true });
    };

    let settings: CupertinoSettings = {
      inverse: true,
      breaks: {
        top: { enabled: true, bounce: true },
      },
      fitHeight: true,
      topperOverflow: false,
      bottomClose: true,
      showDraggable: false,
      upperThanTop: true,
      cssClass: "notification",
      events: {
        onDidDismiss: async () => {
          if (this.toasts.length == 0) return;

          const toastEle = document.getElementById(this.toasts[0].toast.id);
          toastEle.parentElement.removeChild(toastEle);

          this.toasts.splice(0, 1);

          if (this.toasts.length > 0) {
            this.toasts[0].toast.classList.add("achievement-toast");
            await this.toasts[0].pane.present({ animate: true });

            setTimeout(async () => {
              if (this.toasts.length > 0 && !this.toasts[0].pane.isHidden()) {
                await this.toasts[0].pane.destroy({ animate: true });
              }
            }, toastDuration);
          }
        },
        onWillDismiss: async () => {
          const toastEle = document.getElementById(this.toasts[0].toast.id);
          toastEle.style.pointerEvents = "none";
          this.toasts[0].pane.disableDrag();
        },
      },
    };

    const newPane = new CupertinoPane(toast, settings);
    this.toasts.push({ pane: newPane, toast: toast });
    console.log("Toasts: ", this.toasts, ach.title);
    if (this.toasts.length == 1) {
      toast.classList.add("achievement-toast");
      await newPane.present({ animate: true });

      setTimeout(async () => {
        if (this.toasts.length > 0 && !this.toasts[0].pane.isHidden()) {
          await newPane.destroy({ animate: true });
        }
      }, toastDuration);
    }
  }

  mapErrorCode(error: FirebaseError) {
    if (error.code === "auth/user-not-found") {
      return "This email has not been registered. Please finish the series of questions to create an account";
    } else if (error.code === "auth/email-already-in-use") {
      return "An account already exists with this email address. Please sign in";
    } else if (error.code === "auth/credential-already-in-use") {
      return "An account already exists with this email address. Please sign in";
    } else if (error.code === "auth/wrong-password") {
      return "Wrong password";
    } else if (error.code === "auth/user-disabled") {
      return "User disabled";
    } else if (error.code === "auth/too-many-requests") {
      return "Too many requests";
    } else if (error.code === "auth/network-request-failed") {
      return "Network request failed";
    } else if (error.code === "auth/operation-not-allowed") {
      return "Operation not allowed";
    } else if (error.code === "auth/invalid-credential") {
      return "Invalid credential";
    } else if (error.code === "auth/internal-error") {
      return "Something went wrong. Please try again later.";
    } else if (error.code === "auth/invalid-password") {
      return "Invalid password";
    } else if (error.code === "auth/invalid-email") {
      return "Invalid email address";
    } else {
      return error.message;
    }
  }

  mapCategoryDescription(category: string) {
    category = category.split(", ").sort().join(", ");
    return CategoryDescriptions[category];
  }

  getRecItem() {
    const user = this.userServ.getSyncUserOnce();
    if (!user[RECCOMMENDED_ITEMS[0].id]) {
      return RECCOMMENDED_ITEMS[0];
    } else {
      return null;
    }
  }

  hasClosed(recItem: RecItem) {
    const user = this.userServ.getSyncUserOnce();
    if (user) {
      this.userServ.setSyncUser({ ...user, [recItem.id]: true });
    }
  }

  openRecItem(recItem: RecItem) {
    this.appAvailability.check(recItem.bundleId).then(
      (yes: boolean) => this.opener(recItem.bundleId),
      (no: boolean) => this.downloader(recItem)
    );
  }

  async opener(app: string) {
    await AppLauncher.openUrl({
      url: `${app}`,
    });
  }

  downloader(item: RecItem) {
    if (this.platform.is("ios")) {
      window.open(item.iosLink, "_system");
    } else if (this.platform.is("android")) {
      window.open(`market://details?id=${item.bundleId}`, "_system");
    }
  }
}

enum CategoryDescriptions {
  "chest" = "Elevate your strength and carve an impressive physique with this comprehensive chest workout",
  "biceps, chest" = "Amplify your upper body strength and sculpt your physique with this dynamic chest and biceps workout",
  "abs, chest" = "Transform your body with this intensive chest and abs workout, boosting power and defining shape",
  "chest, triceps" = "Sculpt your upper body and amplify your strength with this potent chest and triceps workout",
  "chest, shoulders" = "Fortify your upper body strength and aesthetics with this dynamic chest and shoulders workout",
  "chest, legs" = "Empower your strength and balance with this invigorating chest and legs workout",
  "back, chest" = "Supercharge your power and posture with this robust chest and back workout",
  "chest, planks" = "Empower your core and upper body strength with this vigorous chest and planks workout",

  "biceps" = "Carve powerful arms with this focused biceps workout, the cornerstone of upper body strength",
  "abs, biceps" = "Sculpt defined arms and a strong core with this dynamic biceps and abs workout",
  "biceps, triceps" = "Forge robust arms with this balanced biceps and triceps workout, the ultimate duo for upper body strength",
  "biceps, shoulders" = "Achieve superior arm strength and shoulder definition with this intense biceps and shoulders workout",
  "biceps, legs" = "Forge robust arms and powerful legs with this compelling biceps and legs workout",
  "back, biceps" = "Sculpt formidable arms and a strong back with this dynamic biceps and back workout",
  "biceps, planks" = "Strengthen your arms and core with this efficient biceps and planks workout",

  "abs" = "Craft a powerful core with this intense abs workout, your foundation for total body strength",
  "abs, triceps" = "Fortify your core and sculpt your arms with this dynamic abs and triceps workout",
  "abs, shoulders" = "Enhance core stability and shoulder strength with this effective abs and shoulders workout",
  "abs, legs" = "Strengthen your core and power up your legs with this vigorous abs and legs workout",
  "abs, back" = "Build a solid core and a powerful back with this dynamic abs and back workout",
  "abs, planks" = "Tone your core to perfection and boost stability with this efficient abs and plank workout",

  "triceps" = "Chisel your arms with this targeted triceps workout, the key to robust upper body strength",
  "shoulders, triceps" = "Sculpt powerful arms and broad shoulders with this potent triceps and shoulders workout",
  "legs, triceps" = "Fortify your arms and legs with this dynamic triceps and legs workout for comprehensive strength",
  "back, triceps" = "Sculpt powerful arms and a strong back with this intensive triceps and back workout",
  "planks, triceps" = "Strengthen your arms and core with this balanced triceps and planks workout",

  "shoulders" = "Elevate your upper body strength and posture with this targeted shoulders workout",
  "legs, shoulders" = "Fortify your posture and power up your legs with this dynamic shoulders and legs workout",
  "back, shoulders" = "Widen your upper body and strengthen your back with this potent shoulders and back workout",
  "planks, shoulders" = "Enhance upper body strength and core stability with this challenging shoulders and planks workout",

  "legs" = "Ignite your testosterone naturally, power up your physique, and burn more calories with this intense legs workout",
  "back, legs" = "Boost strength, metabolism, and daily performance with this dynamic legs and back workout",
  "legs, planks" = "Stimulate testosterone, fortify strength and core with this dynamic legs and planks workout",

  "back" = "Enhance overall strength, improve posture, and boost metabolism with this powerful back workout",
  "back, planks" = "Amplify your strength, improve posture, and fortify core stability with this dynamic back and planks workout",

  "planks" = "Fortify your core, enhance stability, and improve overall fitness with this focused planks workout",

  "forearms" = "Build impressive forearms that command attention with targeted exercises that enhance both muscle definition and practical strength",

  "abs, forearms" = "Elevate your fitness game with a dual-focus workout designed to chisel your abs and amplify your forearm strength, enhancing both appearance and functionality",
  "back, forearms" = "Achieve a commanding presence with exercises that strengthen your back and increase forearm endurance, perfect for all around fitness",
  "biceps, forearms" = "Transform your arms into showcases of strength with a workout that intensifies muscle definition in your forearms and biceps",
  "chest, forearms" = "Forge a visually striking and functionally powerful upper body with exercises tailored for your forearms and chest, boosting endurance and appearance",
  "forearms, legs" = "Sculpt strong forearms and toned legs for a well defined, athletic physique that commands attention",
  "forearms, planks" = "Elevate your body conditioning with a strategic combination of planks that tighten and tone your core and forearm workouts that increase muscular endurance and power",
  "forearms, shoulders" = "Forge a visually striking and functionally superior upper body with exercises that enhance your forearms and sculpt your shoulders",
  "forearms, triceps" = "Build unmatched upper arm strength with a targeted workout that enhances your forearms and triceps, improving both muscle definition and appearance",

  "total body" = "Amplify your overall fitness, boost strength, balance, and physique with this comprehensive total body workout",

  "cardio" = "Enhance your stamina, burn calories, and boost heart health with this invigorating cardio workout",

  "hiit" = "Maximize calorie burn, enhance endurance, and boost metabolism with this high-intensity HIIT workout",

  "upper body stretch" = "Improve flexibility, enhance recovery, reduce tension, and promote upper body health with this calming stretch routine",

  "lower body stretch" = "Enhance flexibility, speed up recovery, and alleviate lower body tension with this restorative stretch routine",

  "lower body stretch, upper body stretch" = "Improve flexibility, boost recovery, and reduce full body tension with this rejuvenating stretch routine",
}
