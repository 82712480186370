import { Injectable } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import * as moment from "moment";

import { BehaviorSubject, Observable, Subject } from "rxjs";

import { Capacitor } from "@capacitor/core";
import { Platform } from "@ionic/angular";
import { User } from "../models/user";
import { AchievementService } from "./achievement.service";
import { AnalyticsService } from "./analytics.service";
import { HelperService } from "./helper.service";
import { PlanService } from "./plan.service";
import { UserService } from "./user.service";

@Injectable()
export class OnboardingService {
  private onboardingForm: UntypedFormGroup;

  private slideMoveSubject$: Subject<boolean>;
  public slideMove$: Observable<boolean>;

  private onboardingParamsSubject$: BehaviorSubject<any>;
  public onboardingParams$: Observable<any>;

  private loadPlanSubject$: BehaviorSubject<boolean>;
  public loadPlan$: Observable<boolean>;

  private finalPlanSubject$: BehaviorSubject<boolean>;
  public finalPlan$: Observable<boolean>;

  public isCustomePlan: boolean;
  public onboardingFormValues: any;

  toolbar: "show" | "hide" | "init";
  private weekDays: any[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private userService: UserService,
    private router: Router,
    private planServ: PlanService,
    private achServ: AchievementService,
    private platform: Platform,
    private analyticsServ: AnalyticsService,
    private helperServ: HelperService
  ) {
    this.slideMoveSubject$ = new Subject();
    this.slideMove$ = this.slideMoveSubject$.asObservable();

    this.onboardingParamsSubject$ = new BehaviorSubject({});
    this.onboardingParams$ = this.onboardingParamsSubject$.asObservable();

    this.finalPlanSubject$ = new BehaviorSubject(false);
    this.finalPlan$ = this.finalPlanSubject$.asObservable();

    this.loadPlanSubject$ = new BehaviorSubject(false);
    this.loadPlan$ = this.loadPlanSubject$.asObservable();
    this.onboardingForm = this.fb.group({});
  }

  setFormControlValue(controlName: string, value: any) {
    this.onboardingForm.get(controlName).setValue(value);
    if (Capacitor.getPlatform() == "web") {
      this.setOnboardingStatus(controlName, value);
      if (controlName != "processPlan") {
        this.setOnboardingStatus("processPlan", false);
      }
    }
    console.log("set control value: ", controlName, value);
  }

  setOnboardingStatus(controlName: string, value: any) {
    const onboarding_status = JSON.parse(localStorage.getItem("onboarding_status") ?? "{}");
    if (onboarding_status) {
      localStorage.setItem("onboarding_status", JSON.stringify({ ...onboarding_status, [controlName]: value, last_updated: Date.now() }));
    } else {
      localStorage.setItem("onboarding_status", JSON.stringify({ [controlName]: value, last_updated: Date.now() }));
    }
  }

  updateUserModel() {
    const formValue = this.onboardingForm.value;

    const user = this.userService.getSyncUserOnce();
    this.userService.setSyncUser({
      currentUserWeight: formValue.currentUserWeight || user?.currentUserWeight,
      userBody: formValue.userBody || user?.userBody,
      goalBody: formValue.goalBody || user?.goalBody,
      bodyFat: formValue?.bodyFat ?? user?.bodyFat,
      difficulty: formValue.difficulty || user?.difficulty,
      goalWeight: formValue.goalWeight || user?.goalWeight,
      height: formValue.height || user?.height,
      age: formValue.age || user?.age,
      gender: formValue.gender || user?.gender,
      metric: formValue.metric,
      desiredResults: formValue.desiredResults,
      equipment: formValue.equipment,
    } as User);
  }

  isFinalPlan(value) {
    this.finalPlanSubject$.next(value);
  }

  createOnboardingForm(user): UntypedFormGroup {
    if (Capacitor.getPlatform() == "web") {
      const onboarding_status = JSON.parse(localStorage.getItem("onboarding_status") ?? "{}");
      if (onboarding_status) {
        user = { ...user, ...onboarding_status };
      }
    }

    return this.initOnboardingForm(user);
  }

  getControl(control: string): AbstractControl {
    return this.onboardingForm.get(control);
  }

  getFormGroup(group: string): UntypedFormGroup {
    return this.onboardingForm.get(group) as UntypedFormGroup;
  }

  getOnboardingForm(): UntypedFormGroup {
    return this.onboardingForm;
  }

  initOnboardingForm(user: User): UntypedFormGroup {
    this.onboardingForm = this.fb.group({
      gender: [user && user.gender ? user.gender : null],
      difficulty: [Capacitor.getPlatform() == "web" ? user?.difficulty : null],
      metric: [user && user.metric ? user.metric : "us"],
      currentUserWeight: this.fb.group({
        weightKg: [user && user.currentUserWeight ? user.currentUserWeight.weightKg : null],
        weightPound: [user && user.currentUserWeight ? user.currentUserWeight.weightPound : null],
      }),
      goalWeight: this.fb.group({
        kg: [user && user.goalWeight ? user.goalWeight.kg : null],
        pound: [user && user.goalWeight ? user.goalWeight.pound : null],
      }),
      age: [user ? user.age : null],
      height: this.fb.group({
        feet: [user && user.height ? user.height.feet : null],
        inch: [user && user.height ? user.height.inch : null],
        cm: [user && user.height ? user.height.cm : null],
      }),
      // days: [null],
      whatDays: [Capacitor.getPlatform() == "web" ? (user as any)?.whatDays : []],
      desiredResults: [Capacitor.getPlatform() == "web" ? user?.desiredResults : null],
      goalBody: [Capacitor.getPlatform() == "web" ? user.goalBody : null],
      userBody: [user && user.userBody ? user.userBody : null],
      bodyFat: [Capacitor.getPlatform() == "web" && user?.bodyFat ? user?.bodyFat : null],
      equipment: [Capacitor.getPlatform() == "web" && user?.equipment ? user?.equipment : []],
    });

    return this.onboardingForm;
  }

  async goToPlanConstructor() {
    const form = this.onboardingForm.value;
    const user = this.userService.getSyncUserOnce();

    this.userService.setSyncUser({
      desiredResults: form.desiredResults,
      difficulty: form.difficulty,
      goalWeight: form.goalWeight,
      currentUserWeight: form.currentUserWeight || user.currentUserWeight,
    } as User);
    return this.helperServ.presentPlanConstructor();
  }

  changeSlide(moveSlide: boolean): void {
    this.slideMoveSubject$.next(moveSlide);
  }

  setOnboardingPageKeys(params): void {
    this.onboardingParamsSubject$.next(params);
  }

  getOnboardingPageKeys() {
    return this.onboardingParamsSubject$.value;
  }

  resetOnboardingPageKeys() {
    this.onboardingParamsSubject$.next({});
  }

  getPickerSelectedColumn(value: number) {
    console.log("getPickerSelectedColumn::value", value);

    if (!value) {
      return 0;
    }

    return Number(value.toString().split(".")[1] ?? 0);
  }

  allowLoadingPlan(load: boolean): void {
    this.loadPlanSubject$.next(load);
  }

  clearOnboardingForm() {
    this.onboardingForm.reset();
  }

  resetOnboardingForm() {
    this.allowLoadingPlan(false);
    this.clearOnboardingForm();
  }

  async setPlan() {
    const user = this.userService.getSyncUserOnce();
    if (this.weekDays?.length > 0) {
      // Custom Plan
      const whatDays = [];
      const workoutList = [];
      const weekDayNumber = moment().weekday();

      this.weekDays.forEach((day) => {
        if (day.data[0].name !== "Rest") {
          whatDays.push(day.data[0].value);
        }
        if (day.isMerge) {
          workoutList.push([`${day.data[0].value}, ${day.data[2].value}`.toLowerCase()]);
        } else {
          workoutList.push((day.data as Array<any>).filter((e) => !e.isMergeIcon).map((e) => e.value));
        }
      });

      const wEnd = workoutList.slice(0, weekDayNumber);
      const wStart = workoutList.slice(weekDayNumber);

      const formValue = {
        whatDays,
        days: whatDays?.length,
        desiredResults: user.desiredResults,
        difficulty: user.difficulty,
        goalWeight: user.goalWeight,
        workoutList: wStart.concat(wEnd),
        currentUserWeight: user.currentUserWeight,
      };

      const plan = await this.planServ.createPlanFromConstructor(formValue);

      const workouts = plan.workoutPlan;

      delete plan.workoutPlan;

      user.exerciseInfo.resetTime = moment().add(1, "week").hour(0).minute(0).second(0).toDate().getTime();
      user.exerciseInfo.completedWeeklyGoals = 0;

      await this.userService.setSyncUser({
        plan,
        difficulty: plan.difficulty,
        goalWeight: plan.goalWeight,
        registrationIsCompleted: true,
        planIsCompleted: true,
        exerciseInfo: user.exerciseInfo,
        uid: user.uid,
        equipment: user.equipment,
        platform: this.platform.platforms(),
      } as User);
      await this.planServ.setUserWorkouts(workouts);

      await this.analyticsServ.setUserProperty("PLAN_TYPE", "custom");
      await this.analyticsServ.setUserProperty("GOAL", formValue.desiredResults);

      setTimeout(() => {
        this.resetOnboardingForm();
        this.isFinalPlan(false);
        this.weekDays = [];
      }, 500);
    } else if (this.onboardingForm.value) {
      // Lifebuddy plan
      let formValue = this.onboardingForm.value;
      // if (!this.onboardingForm.value?.days) {
      //   formValue = this.onboardingFormValues;
      // }

      console.log("setPlan::formValue", formValue, this.onboardingForm.value);

      const plan = await this.planServ.createPlan(formValue);
      const workouts = plan.workoutPlan.reduce((prev, curr) => {
        prev[curr.workoutId] = curr;
        return prev;
      }, {});

      delete plan.workoutPlan;

      const syncUser = {
        plan,
        registrationIsCompleted: true,
        planIsCompleted: true,
        exerciseInfo: {
          ...user.exerciseInfo,
          resetTime: moment().add(1, "week").hour(0).minute(0).second(0).toDate().getTime(),
          completedWeeklyGoals: 0,
        },
        uid: user.uid,
        equipment: user.equipment,
        platform: this.platform.platforms(),
      } as User;
      if (Capacitor.getPlatform() == "web") {
        return {
          syncUser: {
            ...syncUser,
            currentUserWeight: formValue.currentUserWeight || user.currentUserWeight,
            userBody: formValue.userBody || user.userBody,
            goalBody: formValue.goalBody || user.goalBody,
            bodyFat: formValue?.bodyFat ?? user?.bodyFat,
            difficulty: formValue.difficulty || user.difficulty,
            goalWeight: formValue.goalWeight || user.goalWeight,
            height: formValue.height || user.height,
            age: formValue.age || user.age,
            gender: formValue.gender || user.gender,
            metric: formValue.metric,
            desiredResults: formValue.desiredResults,
            equipment: formValue.equipment,
          },
          workouts,
        };
      }
      await this.userService.setSyncUser(syncUser);

      await this.planServ.setUserWorkouts(workouts);

      await this.analyticsServ.setUserProperty("PLAN_TYPE", "lifebuddy");
      await this.analyticsServ.setUserProperty("GOAL", formValue.desiredResults);

      setTimeout(() => {
        this.resetOnboardingForm();
        this.isFinalPlan(false);
      }, 500);
    }
    await this.achServ.updateWeightLossAchievements(user?.currentUserWeight?.startWeightPound - user.currentUserWeight.weightPound);
  }

  setWeekdays(weekDays: any[]) {
    this.weekDays = weekDays;
  }
}
