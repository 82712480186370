import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";

import { RoundProgressModule } from "angular-svg-round-progressbar";

import { TranslateModule } from "@ngx-translate/core";
import { AuthComponent } from "../modals/auth/auth.component";
import { DifficultyLevelComponent } from "../modals/difficulty-level/difficulty-level.component";
import { DownloadDeleteComponent } from "../modals/download-delete/download-delete.component";
import { PaymentComponent } from "../modals/payment/payment.component";
import { RadioSelectComponent } from "../modals/radio-select/radio-select.component";
import { ReviewComponent } from "../modals/review/review.component";
import { WorkoutSelectComponent } from "../modals/workout-select/workout-select.component";
import { BottomSheetModule } from "./bottom-sheet/bottom-sheet.module";
import { GlobalModule } from "./global.module";

@NgModule({
  imports: [CommonModule, IonicModule, FormsModule, BottomSheetModule, RoundProgressModule, GlobalModule, TranslateModule],
  exports: [
    RadioSelectComponent,
    DownloadDeleteComponent,
    RoundProgressModule,
    DifficultyLevelComponent,
    WorkoutSelectComponent,
    ReviewComponent,
    AuthComponent,
    PaymentComponent,
  ],
  declarations: [
    RadioSelectComponent,
    DownloadDeleteComponent,
    DifficultyLevelComponent,
    WorkoutSelectComponent,
    ReviewComponent,
    AuthComponent,
    PaymentComponent,
  ],
})
export class ModalsModule {}
