<ion-content fullscreen="true">
  <div class="header-container">
    <ion-toolbar mode="ios">
      <ion-buttons slot="start" (click)="goBack()" mode="ios">
        <ion-back-button
          text
          style="pointer-events: none; width: 48px; height: 48px"
          mode="ios"
          icon="assets/newUI/icons/backBtnBlack.svg"
          defaultHref="/home/profile"
        ></ion-back-button>
      </ion-buttons>

      <ion-title color="light">Privacy Policy</ion-title>
    </ion-toolbar>
  </div>

  <div class="content-container">
    <div class="ion-text-center ion-margin-bottom">
      <img draggable="false" src="assets/images/app_logo.png" class="logo" />
    </div>

    <div class="header-text">LifeBuddy Privacy Statement and Policy</div>
    <div class="sub-text">
      <p>
        This Privacy Policy informs users of the LifeBuddy Website, other LifeBuddy controlled or operated websites (“Websites”), and
        LifeBuddy mobile applications (“Apps”) about how LifeBuddy LLC (“LifeBuddy” or “we”) gathers and uses (i) personal information
        submitted to LifeBuddy and (ii) aggregated information collected from the Websites, Apps and LifeBuddy Services (as defined below).
        This Privacy Policy applies to the Websites and Apps and any other LifeBuddy owned websites, domains, services, applications and
        products (“LifeBuddy Services”).
      </p>

      <p>
        We may modify or amend this Privacy Policy from time to time. If we make material changes, as determined by us, in the way in which
        personal information is collected, used or transferred, we will notify you of these changes by email and by posting a modified
        version of this Privacy Policy on our website and by seeking appropriate user consents. Your provision of such consent or, if such
        consent is not required, your continued use of LifeBuddy Services following any changes means that you accept those changes. Even
        when we make changes, however, personal information collected by us will be treated in accordance with the version of the Privacy
        Policy in effect at the time the personal information was collected, unless we obtain your consent otherwise.
      </p>

      <u>Collection of Personal Information</u>

      <p>
        LifeBuddy collects and uses information about you to manage and enhance your use and experience of the Websites and Apps and
        LifeBuddy Services. LifeBuddy DOES NOT SHARE INFORMATION COLLECTED FROM YOU THAT COULD PERSONALLY IDENTIFY YOU (“Personally
        Identifiable Information”) WITH ANY THIRD PARTY UNLESS YOU SPECIFICALLY CONSENT TO SUCH SHARING. We may share information that we
        collect with third parties consistent with this Privacy Policy, but such information will be aggregated and/or modified so as not to
        enable the identification of any user.
      </p>

      <p>
        We may also use and allow third parties to track your browsing history profile. Such tracking will allow LifeBuddy to offer you
        specifically tailored services and products with appropriate user consent, where necessary (both of LifeBuddy and its third party
        partners). No personally identifiable information will be shared with any third party without your consent.
      </p>

      <p>In addition to the above personal information, we collect standard information about LifeBuddy users such as:</p>

      <ul style="list-style-type: disc">
        <li>
          “Personal Diet Data”, including, birthdate, height and weight, sex, specific details of the foods and drinks that you consume, and
          your exercise.
        </li>
        <li>Email address and LifeBuddy Password.</li>
        <li>IP addresses, browser type and your operating system.</li>
        <li>Pages visited on the Websites referring and exit pages, and the dates and times of the visits.</li>
        <li>
          Financial information, such as your credit/debit card number or other billing information for purchases and product upgrades.
        </li>
        <li>
          Any additional information relating to you and your use of the Websites, Apps or LifeBuddy Services that you provide to use
          directly through the Websites, Apps or LifeBuddy Services.
        </li>
        <li>Location data and other information about devices used to access and interact with the Websites or App.</li>
        <li>Information collected from promotions with third party companies.</li>
      </ul>

      <u>Supplemental Information</u>

      <p>Your information may also be supplemented with additional information from other sources, including publicly available sources.</p>

      <u>Use of Personal Information</u>

      <p>
        We will treat the information we collect as confidential. We will not share information that can be used on its own or with other
        information to identify, contact, or you without your consent. We may share information collected from you that does not identify
        you or an individual with third parties, including advertisers and potential business partners. For example, we may disclose the
        total number of pounds lost by all LifeBuddy users, the average weight loss of LifeBuddy users, or generalized information about
        diet and exercise habits based on aggregated statistics developed from collected data.
      </p>

      <p>We may also use share data that does not identify a user:</p>

      <ul style="list-style-type: disc">
        <li>To fulfill the services and products you request from us.</li>
        <li>
          To operate and improve the Websites, Apps and LifeBuddy Services available to you through LifeBuddy, including developing new
          features and products.
        </li>
        <li>To communicate with you, respond to your requests and manage our relationship.</li>
        <li>To personalize the products and services provide to you.</li>
        <li>Measure performance of the products and LifeBuddy Services.</li>
        <li>
          To send you marketing notices including promotions of our products and services. You can opt-out of communications from LifeBuddy
          at any time (see control of personal information below).
        </li>
        <li>
          For internal purposes, which may require the transfer of such information to other LifeBuddy affiliates for administrative
          purposes, for technical maintenance purposes, the marketing and promotion of products and services that might be of interest to
          you.
        </li>
        <li>
          We may disclose your personal information when we reasonably believe that it is required by law, a duly authorized court order, or
          legal process and to protect and defend the rights and interests of LifeBuddy, its affiliates, suppliers, or users. If we are
          required to disclose your information, we will attempt to provide you with advance notice, unless we are prohibited from doing so
          by law.
        </li>
        <li>
          In the event LifeBuddy goes through a business transition, such as a merger, being acquired by another company, or selling a
          portion of its assets, users' personal information and Personal Diet Data will, in most instances, be part of the assets
          transferred. We will require as a term of such transition that the entity acquiring users’ personal information and Personal Diet
          Data treat such information in accordance with this Privacy Policy as amended from time to time.
        </li>
      </ul>

      <p>
        In some circumstances we may use third party contractors to perform these services on our behalf. In such circumstances, we will
        only provide the contractor with the information needed to perform the services (for example, name and address if a contractor is
        delivering a product to you), and all contractors will be required to agree to confidentiality obligations with respect to your
        personal information.
      </p>

      <u> Control of Personal information and Opt-Out Options </u>

      <p>
        All users can confirm, change, or update personal information you have provided to us by contacting LifeBuddy at
        <a (click)="mailTo()">support@lifebuddymobileapp.com</a>
        .
      </p>
      <p>
        If you want to opt-out of communications from LifeBuddy, you can do so by contacting LifeBuddy at
        <a (click)="mailTo()">support@lifebuddymobileapp.com</a>
        . If you change your mind, you can opt-in again by contacting LifeBuddy at
        <a (click)="mailTo()">support@lifebuddymobileapp.com</a>
        . When you opt-out of promotional communications from us, you may still receive service messages (such as an order confirmation) and
        legally mandated notices. Users that are residents of the European Union have additional rights with respect to personal
        information, as explained below.
      </p>

      <u>Security of the Information We Collect</u>

      <p>
        We take commercially reasonable precautions in an effort to protect your information against security breaches. For example, we
        store personal information and Personal Diet Information using industry-standard security designed to protect the security and
        integrity of your personal information. However, this is not a guarantee that your information may not be accessed, disclosed,
        altered, or destroyed. By using the Websites, Apps and LifeBuddy Services, you acknowledge that you understand and agree to assume
        these risks.
      </p>

      <u>Protection of Children's Privacy</u>

      <p>
        We do not knowingly collect any personal information from children. If you are under 18, you are not permitted to use the Websites
        or App. Consistent with the Children's Online Privacy Protection Act (“COPPA”) and the General Data Protection Regulation, the
        Websites and App are not intended for use by anyone under the age of 18. LifeBuddy does not knowingly collect personal information
        from children through this Web Site.
      </p>

      <p>
        If you are a parent with concerns about children’s privacy issues in conjunction with the use of this Websites, Apps, or LifeBuddy
        Services, please contact LifeBuddy at
        <a (click)="mailTo()">support@lifebuddymobileapp.com</a>
        . LifeBuddy encourages parents to discuss this Privacy Policy and the dangers of providing personal information on-line.
      </p>

      <u>Use of Cookies and Tracking Technology</u>

      <p>
        <u>Cookies.</u>
        When you visit the Websites, a cookie may be saved on your computer (if your computer accepts cookies), and if you return to the
        Websites the cookie may be read. A “cookie,” is a small text file placed on the user's hard drive to track usage of the Websites and
        collect basic information, which may include certain of your preferences.
      </p>

      <p>
        We may use cookies to gain knowledge of Internet usage, improve content and advertisements, collect the site visitation data
        described above and in some cases to deliver specialized content. Cookies also help to prevent you from having to register or
        re-enter your information every time you visit the Web Site in a streamlined fashion. Some of our advertisers and partners
        occasionally use cookies as well. LifeBuddy does not have any control over the cookies used by advertisers and partners.
      </p>

      <p>
        If you would like to avoid cookies, your web browser may have an option that enables you to disable or not accept cookies. It should
        be noted, however, that should you disable or not accept cookies, portions of the Web Site may not properly function.
      </p>

      <p>
        <u>Web Beacons.</u>
        We may also use web beacons, clear gif technology, or action tags as mechanisms to collect aggregate site visitation data by
        tracking how users navigate to and through our Websites. We may also use this technology to track the delivery of HTML email
        messages. Data collected through this mechanism is not linked to personal information. A web beacon is an electronic image and
        string of code that is imbedded in a web page or email which allows us to track web page views/hits or the opening of an email
        containing a beacon. Web beacon tracking by LifeBuddy does not identify the name or email address of the web user or mail recipient.
      </p>

      <p>
        <u>Mobile Analytics.</u>
        In addition, LifeBuddy or our partners may use analytics technology to track anonymous traffic data about use of the Apps. Such
        partners have the right to retain and use the anonymous traffic data collected by through the analytics service from users of the
        Websites or the Apps.
      </p>

      <u>Links to Other Websites</u>
      <p>
        LifeBuddy Services may contain links to third-party websites. Please be aware that we are not responsible for the privacy practices
        of these other sites, and we encourage you to review the policies of each site you use. We cannot control the actions of such third
        parties or be held responsible for their use of any information that you provide directly to them.
      </p>

      <u> Legal Bases for Processing Your Information </u>

      <p>We rely on the following legal grounds to process your personal information:</p>

      <ul style="list-style-type: disc">
        <li>
          Performance of a contract – If you choose to make a purchase through the LifeBuddy Services, we need to collect and use your
          personal information to perform our agreement with you.
        </li>
        <li>
          Consent – We may use or disclose some of your personal information as described in this Privacy Policy subject to your consent.
        </li>
        <li>
          Legitimate interests – We may use your personal information for our legitimate interests to improve our products and services.
          Consistent with our legitimate interests and any choices that we offer or consents that may be required under applicable laws, we
          may use technical information as described in this Privacy Policy and use personal information for our marketing purposes.
        </li>
      </ul>

      <u>Transfer and Processing of Data</u>

      <p>
        For users within the United States, we process data in data centers located in the United States. We have adopted reasonable
        physical, technical, and organizational safeguards against accidental, unauthorized, or unlawful destruction, loss, alteration,
        disclosure, access, use, or processing of user data in our possession. We comply with state and federal laws governing the
        protection of personal information.
      </p>

      <p>
        For users within the European Union, we transfer data from the European Union to data centers located in the United States for
        processing. The European Commission has determined that the law of the United States does not provide a level of protection adequate
        to satisfy the requirements of Regulation 2016/679 of the European Parliament and of the Council of April 27, 2016 on the protection
        of natural persons with regard to the processing of personal data and free movement of such data, known as the General Data
        Protection Regulation (“GDPR”). As to users residing in the EU, however, we extend the rights provided for by the GDPR – as detailed
        below – including the imposition of required safeguards with respect to accidental, unauthorized or unlawful destruction, loss,
        alteration, disclosure, access, use or processing of data. Transfers of EU resident data from the EU to the United States from third
        parties (such as third party payment processors) are conducted in accordance with appropriate transfer mechanisms under the GDPR,
        principally contractual clauses approved by the European Commission.
      </p>

      <p>If you are a resident of the EU, you have the right to:</p>

      <ul style="list-style-type: disc">
        <li>
          request an accounting of all personal information that we possess that pertains to you in an electronically portable format (e.g.,
          electronic copies of information attached to an email).
        </li>
        <li>request that we change any personal information that pertains to you.</li>
        <li>request that we delete any personal information that pertains to you.</li>
        <li>fully or partially withdraw your consent to the collection, processing, and/or transfer of your personal information.</li>
      </ul>

      <p>
        To request an accounting of your personal information, a change to your personal information, deletion of your personal information,
        or to withdraw your consent to the collection, processing, and/or transfer of your personal information, contact LifeBuddy at
        <a (click)="mailTo()">support@lifebuddymobileapp.com</a>
        . Once we have received notification that you withdraw your consent, we will no longer process your information for the purpose(s)
        to which you originally consented unless there are compelling legitimate grounds for further processing which override your
        interests, rights and freedoms or for the establishment, exercise or defense of legal claims.
      </p>

      <p>
        If you believe that we have misused your personal data, you also have a right to lodge a complaint with a national Data Protection
        Authority. Each European Union member nation has established its own Data Protection Authority; you can find out about the Data
        Protection Authority in your country here:
        http://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm.
      </p>

      <u>Data Retention and Deletion</u>

      <p>
        We will only retain your personal information for as long as necessary to fulfill the purposes for which it was collected and
        processed, including for the purposes of satisfying any legal, regulatory, accounting or reporting requirements. In some
        circumstances, we may anonymize your personal information so that it can no longer be associated with you, in which case it is no
        longer personal information.
      </p>

      <p>
        It is our policy to retain personal information for 6 months once such personal information is no longer necessary to deliver the
        LifeBuddy Services and to delete such personal information thereafter. This means that, if you close your account with us, we will
        delete personal information associated with your account after 6 months. Regarding other types of information we collect as
        described in this policy, it is our policy to retain such personal information for 6 months and to delete such personal information
        thereafter.
      </p>

      <u>Do Not Track Disclosure</u>
      <p>
        LifeBuddy does not track its customers over time and across third party websites to provide targeted advertising and therefore does
        not respond to Do Not Track (DNT) signals. However, some third party sites do keep track of your browsing activities when they serve
        you content, which enables them to tailor what they present to you. If you are visiting such sites, your browser may be able to set
        the DNT signal so that third parties (particularly advertisers) know you do not want to be tracked.
      </p>

      <p>
        Third parties that have content embedded on the LifeBuddy Services may set cookies on a user's browser and/or obtain information
        about the fact that a web browser visited the Websites from a certain IP address. Third parties cannot collect any other personally
        identifiable information from the Websites unless you provide it to them directly.
      </p>

      <u>Contact Information</u>
      <p>
        We welcome your input on our Privacy Policy. If you have any questions or suggestions regarding our Privacy Policy, please contact
        LifeBuddy at
        <a (click)="mailTo()">support@lifebuddymobileapp.com</a>
        .
      </p>
    </div>
  </div>
</ion-content>
