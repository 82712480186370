import { Injectable } from "@angular/core";
import { FCM } from "@capacitor-community/fcm";
import { PushNotifications } from "@capacitor/push-notifications";
import { Platform } from "@ionic/angular";
import { HelperService } from "./helper.service";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class PushNotificationService {
  listening = false;

  constructor(private platform: Platform, private helperServ: HelperService, private userServ: UserService) {}

  async addListeners() {
    if (!this.platform.is("hybrid")) {
      return;
    }

    await PushNotifications.addListener("registration", (token) => {
      console.info("Registration token: ", token.value); // Android Winner
      FCM.getToken()
        .then((r) => {
          console.log("FCM Token: ", r.token); // Looks like this is iOS winner, but still not working
        })
        .catch((err) => console.error(err));
    });

    // await PushNotifications.addListener("registrationError", (err) => {
    //   console.error("Registration error: ", err.error);
    // });

    // await PushNotifications.addListener("pushNotificationReceived", (notification) => {
    //   console.log("Push notification received: ", notification);
    // });

    // await PushNotifications.addListener("pushNotificationActionPerformed", (notification) => {
    //   console.log("Push notification action performed", notification.actionId, notification.inputValue);
    //   if (notification.notification.data?.saleModal) {
    //     this.helperServ.presentSalesModal(this.userServ.getSyncUserOnce().desiredResults, notification.notification.data?.saleModal);
    //   }
    // });

    this.listening = true;
  }

  async registerNotifications() {
    if (!this.platform.is("hybrid")) {
      return;
    }

    if (!this.listening) {
      await this.addListeners();
    }

    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === "prompt") {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== "granted") {
      return;
    }

    await PushNotifications.register();
    // await FCM.setAutoInit({ enabled: true });
  }
}
