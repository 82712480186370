import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
// import * as firebase from "firebase";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { logEvent, setCurrentScreen, setUserProperties } from "firebase/analytics";
import { analytics } from "src/environments/environment";
@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  constructor(private platform: Platform) {}

  async logEvent(name: string, event: object) {
    if (this.platform.is("hybrid")) {
      try {
        FirebaseAnalytics.logEvent({
          name,
          params: event,
        });
        if (this.platform.is("android")) {
          logEvent(analytics, name, event);
        }
      } catch (error) {
        // this.loggerService.logError(error);
      }
    }
  }

  async setCurrentScreen(name: string) {
    if (this.platform.is("hybrid")) {
      try {
        if (name?.includes("training-category")) {
          name = "/training-category?category=" + name.split("?")[1].split("=")[1].split("&")[0];
        }
        FirebaseAnalytics.setScreenName({ screenName: name });
        if (this.platform.is("android")) {
          setCurrentScreen(analytics, name);
        }
      } catch (error) {
        // this.loggerService.logError(error);
      }
    }
  }

  async setUserProperty(name: string, value: string) {
    if (this.platform.is("hybrid")) {
      try {
        FirebaseAnalytics.setUserProperty({
          name,
          value,
        });
        if (this.platform.is("android")) {
          setUserProperties(analytics, { [name]: value });
        }
      } catch (error) {
        // this.loggerService.logError(error);
      }
    }
  }
}
