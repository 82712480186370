export interface Achievement {
  id: string;
  order: number;
  title: string;
  description: string;
  target: number;

  completed?: boolean;
  currentProgress?: number;
  parentId?: string;
  categories?: string[];
  lastLogin?: string;
}

export const ACHIEVEMENTS: Achievement[] = [
  {
    id: "self_portrait",
    order: 0,
    title: "Self-Portrait",
    description: "Nice avatar!",
    target: 1,
  },
  {
    id: "full_day",
    order: 1,
    title: "Full Day",
    description: "Wow! 24 full hours of Exercises!",
    target: 24,
  },
  // {
  //   id: "follow_me",
  //   order: 2,
  //   title: "Follow Me",
  //   description: "You have shared a post workout screen on Instagram!",
  //   target: 1,
  // },
  // {
  //   id: "like",
  //   order: 3,
  //   title: "Like!",
  //   description: "You have shared a post workout screen on Facebook!",
  //   target: 1,
  // },
  // {
  //   id: "tweet",
  //   order: 4,
  //   title: "Tweet!",
  //   description: "You have shared a post workout screen on Twitter!",
  //   target: 1,
  // },
  // {
  //   id: "snap",
  //   order: 5,
  //   title: "Snap",
  //   description: "You have shared a post workout screen on Snapchat!",
  //   target: 1,
  // },
  {
    id: "happy_anniversary",
    order: 6,
    title: "Happy Anniversary",
    description: "You have been an active member for 1 year!",
    target: 1,
    parentId: "anniversary",
  },
  {
    id: "2nd_anniversary",
    order: 7,
    title: "2nd Anniversary",
    description: "You have been an active member for 2 years!",
    target: 2,
    parentId: "anniversary",
  },
  {
    id: "3rd_anniversary",
    order: 8,
    title: "3rd Anniversary",
    description: "You have been an active member for 3 years!",
    target: 3,
    parentId: "anniversary",
  },
  {
    id: "4th_anniversary",
    order: 9,
    title: "4th Anniversary",
    description: "You have been an active member for 4 years!",
    target: 4,
    parentId: "anniversary",
  },
  {
    id: "5th_anniversary",
    order: 10,
    title: "5th Anniversary",
    description: "You have been an active member for 5 years!",
    target: 5,
    parentId: "anniversary",
  },
  {
    id: "devoted",
    order: 11,
    title: "Devoted",
    description: "You logged in 7 days in a row!",
    target: 7,
    parentId: "logged_in",
  },
  {
    id: "fanatic",
    order: 12,
    title: "Fanatic",
    description: "You logged in 30 days in a row!",
    target: 30,
    parentId: "logged_in",
  },
  {
    id: "trophy_hunter",
    order: 13,
    title: "Trophy Hunter",
    description: "You received 10 Trophies!",
    target: 10,
  },
  // {
  //   title: "Bring a Friend",
  //   description: "Thanks for inviting a friend!",
  //   order: 14,
  //   id: "bring_a_friend",
  //   target: 1,
  // },
  // {
  //   title: "VIP Referrer",
  //   description: "A friend you referred just upgraded… Thanks!",
  //   order: 15,
  //   id: "vip_referrer",
  // },
  {
    title: "Merger",
    description: "You merged your first workout!",
    order: 16,
    id: "merger",
    target: 1,
  },
  // {
  //   title: "Friend Me",
  //   description: "You connected your Facebook account!",
  //   order: 17,
  //   id: "friend_me",
  //   target: 1,
  // },
  {
    title: "Creator",
    description: "You have created your first plan!",
    order: 18,
    id: "creator",
    target: 1,
  },
  {
    title: "Beast Mode",
    description: "You have completed a full 8 week plan on advanced difficulty! ",
    order: 19,
    id: "beast_mode",
    target: 1,
  },
  {
    title: "Note To Self",
    description: "You have set a workout reminder!",
    order: 20,
    id: "note_to_self",
    target: 1,
  },
  {
    title: "No Rest For The Wicked",
    description: "You skipped 30 rest days!",
    order: 21,
    id: "no_rest_for_the_wicked",
    target: 30,
  },
  {
    title: "Picture Day",
    description: "You uploaded your first progress photo!",
    order: 22,
    id: "picture_day",
    target: 1,
  },
  {
    title: "Your First Rodeo",
    description: "You have completed your 1st workout session",
    order: 23,
    id: "your_first_rodeo",
    target: 1,
    parentId: "workout_sessions",
  },
  {
    title: "Eager Beaver",
    description: "You have completed 10 workout sessions!",
    order: 24,
    id: "eager_beaver",
    target: 10,
    parentId: "workout_sessions",
  },
  {
    title: "Staunch",
    description: "You have completed 50 workout sessions!",
    order: 25,
    id: "staunch",
    target: 50,
    parentId: "workout_sessions",
  },
  {
    title: "Fierce",
    description: "You have completed 100 workout sessions!",
    order: 26,
    id: "fierce",
    target: 100,
    parentId: "workout_sessions",
  },
  {
    title: "Persistent",
    description: "You have completed 200 workout sessions!",
    order: 27,
    id: "persistent",
    target: 200,
    parentId: "workout_sessions",
  },
  {
    title: "Animal",
    description: "You have completed 300 workout sessions!",
    order: 28,
    id: "animal",
    target: 300,
    parentId: "workout_sessions",
  },
  {
    title: "Beast",
    description: "You have completed 400 workout sessions!",
    order: 29,
    id: "beast",
    target: 400,
    parentId: "workout_sessions",
  },
  {
    title: "Unstoppable",
    description: "You have completed 500 workout sessions!",
    order: 30,
    id: "unstoppable",
    target: 500,
    parentId: "workout_sessions",
  },
  {
    title: "Driven",
    description: "You have completed 600 workout sessions!",
    order: 31,
    id: "driven",
    target: 600,
    parentId: "workout_sessions",
  },
  {
    title: "Unswerving",
    description: "You have completed 700 workout sessions!",
    order: 32,
    id: "unswerving",
    target: 700,
    parentId: "workout_sessions",
  },
  {
    title: "Machine",
    description: "You have completed 800 workout sessions!",
    order: 33,
    id: "machine",
    target: 800,
    parentId: "workout_sessions",
  },
  {
    title: "Fireball",
    description: "You have completed 900 workout sessions!",
    order: 34,
    id: "fireball",
    target: 900,
    parentId: "workout_sessions",
  },
  {
    title: "True-Blue",
    description: "You have completed 1000 workout sessions!",
    order: 35,
    id: "true_blue",
    target: 1000,
    parentId: "workout_sessions",
  },
  {
    title: "New You",
    description: "You have completed your first 8 week plan!",
    order: 36,
    id: "new_you",
    target: 1,
    parentId: "8_week_plan",
  },
  {
    title: "You Are On Fire",
    description: "You have completed your second 8 week plan!",
    order: 37,
    id: "you_are_on_fire",
    target: 2,
    parentId: "8_week_plan",
  },
  {
    title: "Rampage",
    description: "You have completed your third 8 week plan!",
    order: 38,
    id: "rampage",
    target: 3,
    parentId: "8_week_plan",
  },
  {
    title: "Out Of This World",
    description: "You have completed your forth 8 week plan!",
    order: 39,
    id: "out_of_this_world",
    target: 4,
    parentId: "8_week_plan",
  },
  {
    title: "5 Star",
    description: "You have completed your fifth 8 week plan!",
    order: 40,
    id: "5_star",
    target: 5,
    parentId: "8_week_plan",
  },
  {
    title: "Transformer",
    description: "You have completed your sixth 8 week plan!",
    order: 41,
    id: "transformer",
    target: 6,
    parentId: "8_week_plan",
  },
  {
    title: "True Warrior",
    description: "You have completed your seventh 8 week plan!",
    order: 42,
    id: "true_warrior",
    target: 7,
    parentId: "8_week_plan",
  },
  {
    title: "Shooting Star",
    description: "You have completed your eighth 8 week plan!",
    order: 43,
    id: "shooting_star",
    target: 8,
    parentId: "8_week_plan",
  },
  {
    title: "Dynamite",
    description: "You have completed your ninth 8 week plan!",
    order: 44,
    id: "dynamite",
    target: 9,
    parentId: "8_week_plan",
  },
  {
    title: "Savage",
    description: "You have completed your tenth 8 week plan!",
    order: 45,
    id: "savage",
    target: 10,
    parentId: "8_week_plan",
  },
  {
    title: "Heart Of A Lion",
    description: "You have completed your eleventh 8 week plan!",
    order: 46,
    id: "heart_of_a_lion",
    target: 11,
    parentId: "8_week_plan",
  },
  {
    title: "Aesthetic King",
    description: "You have completed your twelfth 8 week plan!",
    order: 47,
    id: "aesthetic_king",
    target: 12,
    parentId: "8_week_plan",
  },
  {
    title: "6 Pack Master",
    description: "You have completed 100 Ab workouts!",
    order: 48,
    id: "6_pack_master",
    target: 100,
    parentId: "workout_category",
    categories: ["abs"],
  },
  {
    title: "Why You Looking At My Chest",
    description: "You have completed 100 Chest workouts!",
    order: 49,
    id: "why_you_looking_at_my_chest",
    target: 100,
    parentId: "workout_category",
    categories: ["chest"],
  },
  {
    title: "Reached The Peak",
    description: "You have completed 100 Bicep workouts!",
    order: 50,
    id: "reached_the_peak",
    target: 100,
    parentId: "workout_category",
    categories: ["biceps"],
  },
  {
    title: "Horseshoe",
    description: "You have completed 100 Tricep workouts!",
    order: 51,
    id: "horseshoe",
    target: 100,
    parentId: "workout_category",
    categories: ["triceps"],
  },
  {
    title: "Boulder Shoulders",
    description: "You have completed 100 Shoulder workouts!",
    order: 52,
    id: "boulder_shoulders",
    target: 100,
    parentId: "workout_category",
    categories: ["shoulders"],
  },
  {
    title: "Steel Back",
    description: "You have completed 100 Back workouts!",
    order: 53,
    id: "steel_back",
    target: 100,
    parentId: "workout_category",
    categories: ["back"],
  },
  {
    title: "Solid Foundation",
    description: "You have completed 100 Legs workouts!",
    order: 54,
    id: "solid_foundation",
    target: 100,
    parentId: "workout_category",
    categories: ["legs"],
  },
  {
    title: "Hit Them All",
    description: "You have completed 100 Total Body workouts!",
    order: 55,
    id: "hit_them_all",
    target: 100,
    parentId: "workout_category",
    categories: ["total body"],
  },
  {
    title: "Rock Hard Core",
    description: "You have completed 100 Plank workouts!",
    order: 56,
    id: "rock_hard_core",
    target: 100,
    parentId: "workout_category",
    categories: ["planks"],
  },
  {
    title: "Hello Wellness",
    description: "You have completed 100 Upper Body Stretch workouts!",
    order: 57,
    id: "hello_wellness",
    target: 100,
    parentId: "workout_category",
    categories: ["upper body stretch"],
  },
  {
    title: "Hello Flexibility",
    description: "You have completed 100 Lower Body Stretch workouts!",
    order: 58,
    id: "hello_flexibility",
    target: 100,
    parentId: "workout_category",
    categories: ["lower body stretch"],
  },
  {
    title: "Goodbye Stiffness",
    description: "You have completed 100 Full Body Stretch workouts!",
    order: 59,
    id: "goodbye_stiffness",
    target: 100,
    parentId: "workout_category",
    categories: ["upper body stretch, lower body stretch", "lower body stretch, upper body stretch"],
  },
  {
    title: "Healthy You",
    description: "You have completed 100 Cardio workouts!",
    order: 60,
    id: "healthy_you",
    target: 100,
    parentId: "workout_category",
    categories: ["cardio"],
  },
  {
    title: "Just For The Health Of It",
    description: "You have completed 100 HIIT workouts!",
    order: 61,
    id: "just_for_the_health_of_it",
    target: 100,
    parentId: "workout_category",
    categories: ["hiit"],
  },
  {
    title: "First Step",
    description: "You lost 5 pounds!",
    order: 62,
    id: "first_step",
    target: 5,
    parentId: "weight",
  },
  {
    title: "Half Way",
    description: "You lost 10 pounds!",
    order: 63,
    id: "half_way",
    target: 10,
    parentId: "weight",
  },
  {
    title: "Almost There",
    description: "You lost 15 pounds!",
    order: 64,
    id: "almost_there",
    target: 15,
    parentId: "weight",
  },
  {
    title: "Mission Accomplished",
    description: "You lost 20 pounds!",
    order: 65,
    id: "mission_accomplished",
    target: 20,
    parentId: "weight",
  },
  {
    title: "Hot Stuff",
    description: "You burned 10,000 calories!",
    order: 66,
    id: "hot_stuff",
    parentId: "calories",
    target: 10000,
  },
  {
    title: "Up In Smoke",
    description: "You burned 50,000 calories!",
    order: 67,
    id: "up_in_smoke",
    parentId: "calories",
    target: 50000,
  },
  {
    title: "Pound Crusher",
    description: "You burned 100,000 calories!",
    order: 68,
    id: "pound_crusher",
    parentId: "calories",
    target: 100000,
  },
  {
    title: "Hour After Hour",
    description: "You have worked out 1,000 minutes!",
    order: 69,
    id: "hour_after_hour",
    parentId: "minutes",
    target: 1000,
  },
  {
    title: "Veteran",
    description: "You have worked out 5,000 minutes!",
    order: 70,
    id: "veteran",
    parentId: "minutes",
    target: 5000,
  },
  {
    title: "Many Moons",
    description: "You have worked out 10,000 minutes!",
    order: 71,
    id: "many_moons",
    parentId: "minutes",
    target: 10000,
  },
];
