<ion-header mode="ios"></ion-header>
<ion-content fullscreen="true" class="ion-text-center">
  <div class="header-container">
    <ion-toolbar mode="ios">
      <!-- <ion-title color="light">{{"forgot_password" | translate}}</ion-title> -->
      <ion-buttons slot="start" style="height: 100%">
        <ion-button fill="clear" mode="ios" (click)="dismiss()">
          <ion-icon slot="icon-only" [src]="'assets/newUI/icons/backBtn.svg'"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </div>

  <div class="content-container">
    <!-- <img class="logo" draggable="false" src="assets/newUI/appLogo.png" />

    <div class="instraction-text">
      {{"We just need your registered email address to send you password reset instructions." | translate}}
    </div> -->

    <form #form="ngForm" style="display: flex; flex-direction: column; gap: 4rem; padding: 2rem 0">
      <h2 style="color: #fff; text-align: center; font-size: 24px; font-weight: 700; line-height: 42px">
        {{"Forgot your password?" | translate}}
      </h2>
      <div class="grid-container">
        <div class="grid-row">
          <div class="field-group" inViewport [inViewportOptions]="{threshold: [0]}">
            <input
              required
              autocomplete="off"
              id="email"
              ngModel
              required
              pattern="/[a-z0-9!#$%&'*+/=?^_{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/"
              name="email"
              (keyup.enter)="onSubmit(form)"
              type="email"
              class="field-control"
            />
            <label class="field-label" [class.active-field-control]="form.value.email" for="email"> {{"email" | translate}} </label>
          </div>
        </div>

        <!-- <div class="grid-row">
          <ion-button  class="submit" (click)="onSubmit(form)" expand="full" mode="ios" fill="clear"> {{"submit" | translate}} </ion-button>
        </div> -->
      </div>
    </form>
    <div class="grid-row" style="padding: 1rem">
      <ion-button class="submit" (click)="onSubmit(form)" expand="full" mode="ios" fill="clear">
        {{"reset password" | translate}}
      </ion-button>
    </div>
  </div>
</ion-content>
