<ion-content style="pointer-events: none">
  <img [src]="'assets/splash.png'" class="background-splash animate_fadeIn" />
  <ion-item [lines]="'none'" class="animate_fadeInRight">
    <ion-thumbnail slot="start">
      <img [src]="'assets/icon.png'" />
    </ion-thumbnail>
    LifeBuddy
  </ion-item>

  <div class="quote">
    <h2 style="font-weight: 700" id="quote-body" class="animate_fadeInDown">{{quote?.body}}</h2>
    <p style="font-style: italic; font-size: 14px" class="animate_fadeInRight_delay">- {{quote?.author}}</p>
  </div>
</ion-content>
