import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AppComponent } from "./app.component";
import { AuthGuard } from "./guards/auth.guard";
import { AutoLoginWebGuard } from "./guards/auto-login-web.guard";
import { AutoLoginGuard } from "./guards/auto-login.guard";

const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    component: AppComponent,
    canActivate: [AutoLoginGuard],
  },
  {
    path: "sign-up",
    loadChildren: () => import("./pages/sign-up/sign-up.module").then((m) => m.SignUpPageModule),
  },
  // {
  //   path: "login",
  //   loadChildren: () => import("./pages/login/login.module").then((m) => m.LoginPageModule),
  // },
  {
    path: "login-with-email",
    loadChildren: () => import("./pages/login-with-email/login-with-email.module").then((m) => m.LoginWithEmailPageModule),
  },
  {
    path: "forgot-password",
    loadChildren: () => import("./pages/forgot-password/forgot-password.module").then((m) => m.ForgotPasswordPageModule),
  },

  {
    path: "privacy-policy",
    loadChildren: () => import("./pages/privacy-policy/privacy-policy.module").then((m) => m.PrivacyPolicyPageModule),
  },
  {
    path: "terms-and-conditions",
    loadChildren: () => import("./pages/terms-and-conditions/terms-and-conditions.module").then((m) => m.TermsAndConditionsPageModule),
  },

  {
    path: "home",
    loadChildren: () => import("./pages/home/home.module").then((m) => m.HomePageModule),
    canActivate: [AuthGuard],
  },
  // {
  //   path: "plan-completed",
  //   loadChildren: () => import("./pages/plan-completed/plan-completed.module").then((m) => m.PlanCompletedPageModule),
  //   canActivate: [AuthGuard],
  // },
  // {
  //   path: "setting",
  //   loadChildren: () => import("./pages/setting/setting.module").then((m) => m.SettingPageModule),
  //   canActivate: [AuthGuard],
  // },
  {
    path: "search",
    loadChildren: () => import("./pages/search/search.module").then((m) => m.SearchPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "training-category",
    loadChildren: () => import("./pages/training-category/training-category.module").then((m) => m.TrainingCategoryPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "playlist",
    loadChildren: () => import("./pages/playlist/playlist.module").then((m) => m.PlaylistPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "edit-profile",
    loadChildren: () => import("./pages/edit-profile/edit-profile.module").then((m) => m.EditProfilePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "reminder",
    loadChildren: () => import("./pages/reminder/reminder.module").then((m) => m.ReminderPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "weight-progress",
    loadChildren: () => import("./pages/weight-progress/weight-progress.module").then((m) => m.WeightProgressPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "onboarding",
    loadChildren: () => import("./pages/onboarding/onboarding.module").then((m) => m.OnboardingPageModule),
    canActivate: [AutoLoginWebGuard],
  },
  {
    path: "onboarding/plan-constructor",
    loadChildren: () => import("./pages/plan-constructor/plan-constructor.module").then((m) => m.PlanConstructorPageModule),
    // canActivate: [AuthGuard],
  },
  {
    path: "splash",
    loadChildren: () => import("./pages/splash/splash.module").then((m) => m.SplashPageModule),
  },
  {
    path: "subscription-terms",
    loadChildren: () => import("./pages/subscription-terms/subscription-terms.module").then((m) => m.SubscriptionTermsPageModule),
  },
  // {
  //   path: "our-story",
  //   loadChildren: () => import("./pages/our-story/our-story.module").then((m) => m.OurStoryPageModule),
  // },
  {
    path: "achievements",
    loadChildren: () => import("./pages/achievements/achievements.module").then((m) => m.AchievementsPageModule),
  },
  {
    path: "integrations",
    loadChildren: () => import("./pages/integrations/integrations.module").then((m) => m.IntegrationsPageModule),
  },
  {
    path: "landing",
    loadChildren: () => import("./pages/landing/landing.module").then((m) => m.LandingPageModule),
  },
  {
    path: "thank-you",
    loadChildren: () => import("./pages/thank-you/thank-you.module").then((m) => m.ThankYouPageModule),
  },
  {
    path: "download-app",
    loadChildren: () => import("./pages/download-app/download-app.module").then((m) => m.DownloadAppPageModule),
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
