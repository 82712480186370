import { Component, ViewChild } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Capacitor } from "@capacitor/core";
import { Device } from "@capacitor/device";
import { Keyboard, KeyboardResize } from "@capacitor/keyboard";
import { StatusBar, Style } from "@capacitor/status-bar";
import { Insomnia } from "@ionic-native/insomnia/ngx";
import { ScreenOrientation } from "@ionic-native/screen-orientation";
import { AnimationController, ModalController, Platform } from "@ionic/angular";
import { SafeArea } from "capacitor-plugin-safe-area";
import { fetchAndActivate, getValue } from "firebase/remote-config";
import packageJSON from "package.json";
import { filter } from "rxjs/operators";
import { environment, remoteConfig } from "src/environments/environment";
import { LANGUAGE_LIST } from "./constants";
import { RadioSelectComponent } from "./modals/radio-select/radio-select.component";
import { ReviewComponent } from "./modals/review/review.component";
import { User } from "./models/user";
import { LoginWithEmailPage } from "./pages/login-with-email/login-with-email.page";
import { SplashPage } from "./pages/splash/splash.page";
import { AnalyticsService } from "./services/analytics.service";
import { AuthService } from "./services/auth.service";
import { HelperService } from "./services/helper.service";
import { NetworkService } from "./services/network.service";
import { NotificationService } from "./services/notification.service";
import { SubscriptionService } from "./services/subscription.service";
import { TranslateConfigService } from "./services/translate-config.service";
import { UserService } from "./services/user.service";
import { WeightProgressService } from "./services/weight-progress.service";
import { BottomSheetComponent, SheetStates } from "./shared/bottom-sheet/bottom-sheet-component";
@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  @ViewChild("bottomSheetReview2") review: ReviewComponent;
  firestoreSub: boolean | null = false;

  version = packageJSON.version;

  user: User;

  language: string;
  displayLanguage: string;

  innerHeightInterval: NodeJS.Timeout;
  currentHeight: number;

  bottomSheetRef: any;
  constructor(
    private platform: Platform,
    private router: Router,
    private insomnia: Insomnia,
    private analyticsService: AnalyticsService,
    private modalCtrl: ModalController,
    private animationCtrl: AnimationController,
    private weightProgressService: WeightProgressService,
    private userServ: UserService,
    private networkServ: NetworkService,
    private authServ: AuthService,
    private helperServ: HelperService,
    private notificationService: NotificationService,
    private translateConfigService: TranslateConfigService,
    private subServ: SubscriptionService
  ) {
    this.initializeApp();

    this.language = this.translateConfigService.getCurrentLang();
    const foundIndex = LANGUAGE_LIST.findIndex((item) => item.value === this.language);
    if (foundIndex != -1) {
      this.displayLanguage = LANGUAGE_LIST[foundIndex].name;
    }
  }

  async initializeApp() {
    this.platform.ready().then(async () => {
      if (this.platform.is("hybrid")) {
        this.subServ.initStore();
        if (this.platform.is("android")) {
          await StatusBar.setBackgroundColor({ color: "#FF000000" });

          await StatusBar.setStyle({ style: Style.Dark });

          SafeArea.getSafeAreaInsets().then(({ insets }) => {
            const style = document.documentElement.style;
            Object.keys(insets).forEach((key) => {
              style.setProperty(`--ion-safe-area-${key}`, insets[key] + "px");
            });
          });
        } else if (this.platform.is("ios")) {
          await Keyboard.setAccessoryBarVisible({ isVisible: true });
          await StatusBar.setStyle({ style: Style.Dark });

          Keyboard.setResizeMode({ mode: KeyboardResize.None }).catch((err) => {
            console.error("Keyboard resize error: ", err);
          });
          document.documentElement.style.setProperty("--vh", window.outerHeight + "px");
        }
        if (this.platform.is("capacitor")) {
          Keyboard.addListener("keyboardWillShow", (ev) => {
            document.documentElement.style.setProperty("--vh", window.outerHeight - ev.keyboardHeight + "px");
          }).catch((err) => {
            console.error("Keyboard will show error: ", err);
          });
          Keyboard.addListener("keyboardDidShow", (ev) => {
            document.documentElement.style.setProperty("--vh", window.outerHeight - ev.keyboardHeight + "px");
          }).catch((err) => {
            console.error("Keyboard did show error: ", err);
          });

          Keyboard.addListener("keyboardWillHide", () => {
            document.documentElement.style.setProperty("--vh", window.outerHeight + "px");
          }).catch((err) => {
            console.error("Keyboard will hide error: ", err);
          });
          Keyboard.addListener("keyboardDidHide", () => {
            document.documentElement.style.setProperty("--vh", window.outerHeight + "px");
          }).catch((err) => {
            console.error("Keyboard did hide error: ", err);
          });
        }

        await ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT);
        this.showFakeSplash();
      }

      if (Capacitor.getPlatform() == "web") {
        sessionStorage.setItem("payment", "false");
        this.currentHeight = window.innerHeight;
        document.documentElement.style.setProperty("--vh", window.innerHeight + "px");

        console.log("Inner Height: ", window.innerHeight);

        window.addEventListener("resize", this.setInnerHeight);

        window.addEventListener("focus", this.setInnerHeight);
        window.addEventListener("blur", this.setInnerHeight);
      }

      this.userServ.getSyncUser().subscribe((syncUser) => {
        this.user = syncUser;
        if (syncUser && syncUser.uid && !this.firestoreSub) {
          this.firestoreSub = true;
          this.userServ.getFirestoreUser("", (user: User) => {
            if (
              user.uid &&
              user.email &&
              (user.uid != this.user.uid ||
                user.email != this.user.email ||
                user.countdownEnd != this.user.countdownEnd ||
                user.image != this.user.image)
            ) {
              this.userServ.setSyncUser(user);
              this.weightProgressService.getCurrentUserWeightProgress();
            }
          });
        }
      });

      this.initAnalitics();

      this.initRemoteConfig();
      // Probably only used for keeping workout video from dimming or locking on playlist page
      if (this.platform.is("hybrid")) {
        this.insomnia.keepAwake().then(
          () => console.log("AppComponent::initializeApp()::insomnia.keepAwake()", "success"),
          (error) => console.log("AppComponent::initializeApp()::insomnia.keepAwake()", error)
        );
      }
    });
  }

  setInnerHeight(ev: FocusEvent) {
    if (sessionStorage.getItem("payment") === "true") {
      return;
    }

    console.log("set Inner Height Ev: ", ev);
    if (this.innerHeightInterval) {
      clearInterval(this.innerHeightInterval);
      this.innerHeightInterval = null;
    }
    let maxIterations = 20;

    this.innerHeightInterval = setInterval(() => {
      if (window.innerHeight != this.currentHeight) {
        document.documentElement.style.setProperty("--vh", window.innerHeight + "px");
        this.currentHeight = window.innerHeight;
        clearInterval(this.innerHeightInterval);
        this.innerHeightInterval = null;
      }

      maxIterations--;

      if (maxIterations <= 0) {
        clearInterval(this.innerHeightInterval);
        this.innerHeightInterval = null;
      }
    }, 50);
  }

  async initAnalitics() {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.analyticsService.setCurrentScreen(event.url);
    });
    const device = await Device.getId().catch((err) => {
      console.error("Error getting device id", err);
      return { identifier: "unknown" };
    });
    if (this.platform.is("hybrid")) {
      const deviceInfo = await Device.getInfo();
      this.analyticsService.setUserProperty("DEVICE_UUID", device.identifier);
      this.analyticsService.setUserProperty("DEVICE_PLATFORM", deviceInfo.platform);
      this.analyticsService.setUserProperty("DEVICE_MODEL", deviceInfo.model);
      this.analyticsService.setUserProperty("DEVICE_OS_VERSION", deviceInfo.osVersion);
    }
  }

  async initRemoteConfig() {
    fetchAndActivate(remoteConfig).catch((err) => {
      console.error("Error fetching & activating remote config", err);
    });
  }

  private async showFakeSplash() {
    const splash = await this.modalCtrl.create({
      id: "splash",
      component: SplashPage,
      leaveAnimation: this.leaveAnimation,
      enterAnimation: this.noAnimation,
      cssClass: ["no-enter-animation"],
    });
    await splash.present();
    splash.onDidDismiss().then((res) => {
      setTimeout(() => {
        this.checkShouldReview();
      }, 2000);
    });
  }

  noAnimation = (baseEl: any) => {
    const root = baseEl.shadowRoot;

    const wrapperAnimation = this.animationCtrl
      .create()
      .addElement(root.querySelector(".modal-wrapper")!)
      .keyframes([{ opacity: "0.99" }, { opacity: "0.99" }]);

    return this.animationCtrl.create().addElement(baseEl).easing("ease-out").duration(10).addAnimation([wrapperAnimation]);
  };

  enterAnimation = (baseEl: any) => {
    const root = baseEl.shadowRoot;

    const backdropAnimation = this.animationCtrl
      .create()
      .addElement(root.querySelector("ion-backdrop")!)
      .fromTo("opacity", "0.01", "var(--backdrop-opacity)");

    const wrapperAnimation = this.animationCtrl
      .create()
      .addElement(root.querySelector(".modal-wrapper")!)
      .keyframes([
        { offset: 0, opacity: "0" },
        { offset: 1, opacity: "0.99" },
      ]);

    return this.animationCtrl
      .create()
      .addElement(baseEl)
      .easing("ease-out")
      .duration(250)
      .addAnimation([backdropAnimation, wrapperAnimation]);
  };

  leaveAnimation = (baseEl: any) => {
    return this.enterAnimation(baseEl).direction("reverse");
  };

  checkShouldReview() {
    const user = this.userServ.getSyncUserOnce();
    const isFreemium = getValue(remoteConfig, environment.production ? "freemium" : "test_freemium");
    const subFreemium = isFreemium?.asBoolean() || !this.userServ.countdownEnded();

    if (
      location.pathname.includes("home") &&
      user?.exerciseInfo?.allTime?.workoutCount > 0 &&
      this.networkServ?.getStatusOnce() &&
      !user.hasReviewed &&
      (user?.subscription?.isSubscribed || subFreemium || (!subFreemium && !this.userServ.countdownEnded()))
    ) {
      this.review.openModal();
    }
  }

  async manageSubscription() {
    await this.helperServ.presentLoader();
    const response = await fetch(
      environment.production
        ? "https://us-central1-lifebuddy-3a29a.cloudfunctions.net/createportallink"
        : "http://127.0.0.1:5001/lifebuddy-3a29a/us-central1/createportallink",
      {
        method: "POST",
        body: JSON.stringify({
          uid: this.authServ.getAuthUser()?.uid,
          returnUrl: location.href,
          locale: this.language,
        }),
      }
    ).catch((e) => {
      console.error("Error: ", e);
      return null;
    });

    await this.helperServ.dismissLoader();
    const responseData = await response.json();
    if (responseData.session) {
      window.open(responseData.session.url, "_blank");
    } else {
      await this.notificationService.showError("There was an error getting your billing portal, please contact support");
    }
  }

  openModal(bottomSheet: BottomSheetComponent) {
    this.bottomSheetRef = bottomSheet;
    setTimeout(() => {
      bottomSheet.setState(SheetStates.Opened);
    }, 150);
  }

  hideModal(bottomSheet: BottomSheetComponent) {
    this.bottomSheetRef = bottomSheet;
    bottomSheet.setState(SheetStates.Closed);
  }

  async logout(bottomSheet: BottomSheetComponent) {
    await bottomSheet.setState(SheetStates.Closed);
    this.bottomSheetRef = bottomSheet;
    this.authServ.logout();
  }

  mailTo() {
    this.helperServ.sendSupportEmail();
  }

  async openLogin() {
    const modal = await this.modalCtrl.create({
      component: LoginWithEmailPage,
      componentProps: {
        routeHome: false,
      },
    });

    await modal.present();

    modal.onDidDismiss().then(async (res) => {
      if (res.data) {
        console.log(res.data, this.user);
        this.router.navigate(["download-app"]);
      }
    });
  }

  async openLanguageModal(bottomSheet: RadioSelectComponent) {
    this.bottomSheetRef = bottomSheet.bottomSheetRadio;
    const options = [...LANGUAGE_LIST].map((e) => {
      return { name: e.name, value: e.name };
    });
    bottomSheet.openModal(this.displayLanguage, options, "Select Language");
  }

  modalSelectLanguage(language) {
    const foundIndex = LANGUAGE_LIST.findIndex((e) => e.name === language);

    if (foundIndex != -1 && language) {
      this.userServ.setSyncUser({
        language,
      } as User);
      this.displayLanguage = language;
      this.translateConfigService.setLanguage(LANGUAGE_LIST[foundIndex].value);
      this.language = LANGUAGE_LIST[foundIndex].value;
      this.analyticsService.setUserProperty("LANGUAGE", LANGUAGE_LIST[foundIndex].name);
    }
  }
}
