import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class TranslateConfigService {
  private currentLang: BehaviorSubject<string> = new BehaviorSubject<string>("en");
  $currentLang = this.currentLang.asObservable();

  constructor(private translate: TranslateService) {
    this.translate.setDefaultLang("en");
    const lang = localStorage.getItem("lang");
    if (!lang || lang == null || lang == "null" || lang == "") {
      this.setLanguage("en");
    } else {
      this.setLanguage(lang);
    }
  }

  setLanguage(setLang: string) {
    this.translate.use(setLang);
    localStorage.setItem("lang", setLang);
    this.currentLang.next(setLang);
  }

  getCurrentLang() {
    return this.currentLang.value;
  }
}
