import { AfterViewInit, Component, OnInit } from "@angular/core";
import { SplashScreen } from "@capacitor/splash-screen";
import { ModalController, Platform } from "@ionic/angular";
import { QUOTES, Quote } from "src/app/constants/quote";

@Component({
  selector: "app-splash",
  templateUrl: "./splash.page.html",
  styleUrls: ["./splash.page.scss"],
})
export class SplashPage implements OnInit, AfterViewInit {
  quote: Quote;

  constructor(public modalCtrl: ModalController, private platform: Platform) {}

  ngOnInit(): void {
    const randomNumber = Math.floor(Math.random() * QUOTES.length);
    this.quote = QUOTES[randomNumber];
  }

  ngAfterViewInit(): void {
    this.hideSplash();
  }

  hideSplash() {
    const quoteLength = this.quote.body.split(" ").join("").length;

    setTimeout(
      () => {
        this.modalCtrl.dismiss({}, "", "splash");
      },
      Math.max(Math.min(Number(1875) + quoteLength * 50, 5250)),
      4500
    );

    setTimeout(() => {
      SplashScreen.hide();
    }, 1000);
  }
}
